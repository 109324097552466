<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
       <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" title="Close Case" (click)="gotoSummary()">
              <span aria-hidden="true" style="color: red;">&times;</span>
          </button>
          </div>
          <ul class="nav nav-pills iris-gst-display-flex" role="tablist" id="pills-tab">
            <li class="nav-item" style="margin-right:6px;">
              <a class="nav-link" (click)="tabChanged('tab1')" [ngClass]="{'disabled':!tabDisableFlag.tab1, 'active':selectedTab === 'tab1'}" id="Enquiry-tab" data-toggle="tab" role="tab" aria-controls="enquiry-1" aria-selected="true">Enquiry Proceeding</a>
            </li>
            <li class="nav-item" style="margin-right:6px;">
              <a class="nav-link" (click)="tabChanged('tab2')" [ngClass]="{'disabled':!tabDisableFlag.tab2, 'active':selectedTab === 'tab2'}" id="Intimation-tab" data-toggle="tab" role="tab" aria-controls="intimation-1" aria-selected="true">Intimation Order</a>
            </li>
            <li class="nav-item" style="margin-right:6px;">
              <a class="nav-link" (click)="tabChanged('tab3')" [ngClass]="{'disabled':!tabDisableFlag.tab3, 'active':selectedTab === 'tab3'}" id="assessment-tab" data-toggle="tab" role="tab" aria-controls="assessment-1" aria-selected="true">Assessment</a>
            </li>
            <li class="nav-item" style="margin-right:6px;">
              <a class="nav-link" (click)="tabChanged('tab4')" [ngClass]="{'disabled':!tabDisableFlag.tab4, 'active':selectedTab === 'tab4'}" id="Commissioner-tab" data-toggle="tab"  role="tab" aria-controls="Commissioner" aria-selected="true">Commissioner (A) /DRP</a>
            </li>
            <li class="nav-item" style="margin-right:6px;">
              <a class="nav-link" (click)="tabChanged('tab5')" [ngClass]="{'disabled':!tabDisableFlag.tab5, 'active':selectedTab === 'tab5'}" id="AppelTrib-tab" data-toggle="tab"  role="tab" aria-controls="Appellate-3" aria-selected="true">Appellate Tribunal</a>
            </li>
            <li class="nav-item" style="margin-right:6px;">
              <a class="nav-link" (click)="tabChanged('tab6')" [ngClass]="{'disabled':!tabDisableFlag.tab6, 'active':selectedTab === 'tab6'}" id="HC-tab" data-toggle="tab"  role="tab" aria-controls="hC-1" aria-selected="true">High Court</a>
            </li>
            <li class="nav-item" style="margin-right:6px;">
                <a class="nav-link" (click)="tabChanged('tab7')" [ngClass]="{'disabled':!tabDisableFlag.tab7, 'active':selectedTab === 'tab7'}" id="SC-tab" data-toggle="tab"  role="tab" aria-controls="SC-1" aria-selected="true">Supreme Court</a>
            </li>
            <!-- <li class="nav-item" style="margin-right:6px;">
                <a class="nav-link" (click)="tabChanged('tab8')" [ngClass]="{'disabled':!tabDisableFlag.tab8, 'active':selectedTab === 'tab8'}" id="Procecution-tab" data-toggle="tab"  role="tab" aria-controls="Procecution" aria-selected="true"style="margin-top: 10px;padding-right: 52px;">Procecution</a>
           </li> -->
          </ul>
          <div class="tab-content">
         <div class="tab-pane fade show active" id="notice-1" role="tabpanel" aria-labelledby="notice-tab">
            <app-enquiry-proceeding [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab1'" (callTabFunction)="tabChanged('tab4')"(moveToHC)="tabChanged('tab6',null,true)" ></app-enquiry-proceeding>
         </div> 

            <div class="tab-pane fade show active" id="adjudication-1" role="tabpanel" aria-labelledby="adjudication-tab">
            <app-intimation-order [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab2'"  (callTabFunction)="tabChanged('tab4')"(moveToHC)="tabChanged('tab6',null,true)"></app-intimation-order>
            </div>

            <div class="tab-pane fade show active" id="assessment-1" role="tabpanel" aria-labelledby="assessment-tab">
              <app-assessment [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab3'" (callTabFunction)="tabChanged('tab4')"(moveToHC)="tabChanged('tab6',null,true)"></app-assessment>
            </div>
             <div class="tab-pane fade show active" id="Commissioner" role="tabpanel" aria-labelledby="Commissioner-tab">
                <app-dt-commissioner-auth [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab4'"  (callTabFunction)="tabChanged('tab5')"   (moveToHC)="tabChanged('tab6',null,true)"  (moveToSC)="tabChanged('tab6')"></app-dt-commissioner-auth>
            </div>

             <div class="tab-pane fade show active" id="Appellate-3" role="tabpanel" aria-labelledby="AppelTrib-tab">
               <app-dt-appellate-tribe [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)"  (saveCaseId)="saveCaseId($event)"  [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab5'" (callTabFunction)="tabChanged('tab6')"></app-dt-appellate-tribe>
            </div> 

            <div class="tab-pane fade show active" id="hC-1" role="tabpanel" aria-labelledby="HC-tab">
               <app-dt-hc [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" [selectedCaseId]="selectedCaseId" (saveCaseId)="saveCaseId($event)" *ngIf="selectedTab === 'tab6'"  (callTabFunction)="tabChanged('tab7')"></app-dt-hc>
            </div>
            <div class="tab-pane fade show active" id="SC-1" role="tabpanel" aria-labelledby="SC-tab">
              <app-dt-sc (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab7'"></app-dt-sc>
            </div>   

          </div>
        </div>
      </div>
    </div>
            </div>
       </div>

    </div>
 </div>

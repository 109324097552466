export enum DirectTaxStage {
  Enquiry = 'Enquiry Proceedings',
  Intimation = 'Intimation',
  Assessment = 'Assessment',
  Commissioner = 'Commissioner(A) /DRP',
  Tribunal = 'Appellate Tribunal',
  HighCourt = 'High Court',
  SupremeCourt = 'Supreme Court'
}

export enum DTForumMap {
  Enquiry = 'ENQUIRY PROCEEDING',
  Intimation = 'INTIMATION ORDER',
  Assessment = 'ASSESSMENT',
  Commissioner = 'COMMISSIONER',
  Tribunal = 'APPELLATE TRIBUNAL',
  HighCourt = 'HIGH COURT',
  SupremeCourt = 'SUPREME COURT'
}

export const dtForumType = {
  [DTForumMap.Enquiry]: 'dtenqproc',
  [DTForumMap.Intimation]: 'dtintimation',
  [DTForumMap.Assessment]: 'dtassessment',
  [DTForumMap.Commissioner]: 'dtcommissioner',
  [DTForumMap.Tribunal]: 'dtitat',
  [DTForumMap.HighCourt]: 'dthc',
  [DTForumMap.SupremeCourt]: 'dtsc'
};

export const dtForumTabs = {
  [DTForumMap.Enquiry]: 'tab1',
  [DTForumMap.Intimation]: 'tab2',
  [DTForumMap.Assessment]: 'tab3',
  [DTForumMap.Commissioner]: 'tab4',
  [DTForumMap.Tribunal]: 'tab5',
  [DTForumMap.HighCourt]: 'tab6',
  [DTForumMap.SupremeCourt]: 'tab7'
};

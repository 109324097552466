import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaxCreateCaseModalComponent } from 'src/app/shared/components/tax-create-case-modal/tax-create-case-modal.component';
import { TaxServiceService } from '../service/tax-service.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { DT_TABS } from 'src/app/enums/direct-tax.enum';
import { Summarycount } from 'src/app/models/direct-tax.interface';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { DEMAND_AMT_OPTIONS, DT_FILTER_STATUS, DT_Financial_Years, DT_ISSUE_DROPDOWN, ISSUE_GST, PREGST_ISSUE_DROPDOWN, STATE_LIST, TAB_TYPE, badgeMapInvoice, } from 'src/app/shared/constant';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { DeleteModalComponent } from 'src/app/shared/components/delete-modal/delete-modal.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { DatePipe } from '@angular/common';
import { cloneDeep } from 'lodash-es';
import { ExcelService } from 'src/app/shared/services/excel.service';
import _ from 'lodash';
import { MasterService } from 'src/app/businessManagement/services/master.service';
export const SUMMARY_SUM_CACHE = 'SUMMARY_SUM_CACHE'; // used to store in session storage


export enum ENQUIRY_SORT {
  CASE_ID = 'CaseId',
  PAN = 'Pan',
  PAN_Name = 'Pan name',
  A_Y = 'A.Y',
  CASE_LABEL = 'Case Label',
  UPDATE_DATE = 'Update Date',
  STATUS = 'Status',
}
export enum INTIMATION_SORT {
  CASE_ID = 'CaseId',
  PAN = 'Pan',
  PAN_Name = 'Pan name',
  A_Y = 'A.Y',
  CASE_LABEL = 'Case Label',
  ADDN_INCOME = 'Income',
  DEMAND = 'Demaand',
  UPDATE_DATE = 'Update Date',
  STATUS = 'Status',
}

export enum ASSESSMENT_SORT {
  CASE_ID = 'CaseId',
  PAN = 'Pan',
  PAN_Name = 'Pan name',
  A_Y = 'A.Y',
  CASE_LABEL = 'Case Label',
  ADDN_INCOME = 'Income',
  DEMAND = 'Demaand',
  UPDATE_DATE = 'Update Date',
  STATUS = 'Status',
}

export enum COMMISSIONER_STAGE {
  CASE_ID = 'CaseId',
  PAN = 'Pan',
  PAN_Name = 'Pan name',
  A_Y = 'A.Y',
  CASE_LABEL = 'Case Label',
  ADDN_INCOME = 'Income',
  DEMAND = 'Demaand',
  UPDATE_DATE = 'Update Date',
  STATUS = 'Status',
}

export enum APPELLATET_STAGE {
  CASE_ID = 'CaseId',
  PAN = 'Pan',
  PAN_Name = 'Pan name',
  A_Y = 'A.Y',
  CASE_LABEL = 'Case Label',
  ADDN_INCOME = 'Income',
  DEMAND = 'Demaand',
  UPDATE_DATE = 'Update Date',
  STATUS = 'Status',
}

export enum HIGHCOURT_STAGE {
  CASE_ID = 'CaseId',
  PAN = 'Pan',
  PAN_Name = 'Pan name',
  A_Y = 'A.Y',
  CASE_LABEL = 'Case Label',
  ADDN_INCOME = 'Income',
  DEMAND = 'Demaand',
  UPDATE_DATE = 'Update Date',
  STATUS = 'Status',
}

export enum SUPREMECOURT_STAGE {
  CASE_ID = 'CaseId',
  PAN = 'Pan',
  PAN_Name = 'Pan name',
  A_Y = 'A.Y',
  CASE_LABEL = 'Case Label',
  ADDN_INCOME = 'Income',
  DEMAND = 'Demaand',
  UPDATE_DATE = 'Update Date',
  STATUS = 'Status',
}

@Component({
  selector: 'app-direct-tax-summary',
  templateUrl: './direct-tax-summary.component.html',
  styleUrls: ['./direct-tax-summary.component.css']
})

export class DirectTaxSummaryComponent implements OnInit {

  enquirySort = ENQUIRY_SORT;
  intimationSort = INTIMATION_SORT;
  assesmentSort = ASSESSMENT_SORT;
  apalitigationSort = COMMISSIONER_STAGE;
  aptlitigationSort = APPELLATET_STAGE;
  hcLitigationSort = HIGHCOURT_STAGE;
  scLitigationSort = SUPREMECOURT_STAGE;

  data: any[] = [];
  caseCount: Summarycount[] = []
  tabs = DT_TABS;
  contextData: any;
  selectedTab: any;

  tabType: { key: string; value: string; }[];
  navContext: any;
  pan: any;
  companyId: any;
  fileUrl: any;

  cntDtEnqproc: any = 0;
  cntDTtIntimation: any = 0;
  cntDtAssessment: any = 0;
  cntDtCommissioner: any = 0;
  cntDtItat: any = 0;
  cntDtHC: any = 0;
  cntDteSC: any = 0;
  totalCase: any = 0;
  opneCase: any = 0;

  cntDtEnqprocOp: any = 0;
  cntDtIntimationOp: any = 0;
  cntDtAssessmentOp: any = 0;
  cntDtCommissionerOp: any = 0;
  cntDtItatOp: any = 0;
  cntDtHCOp: any = 0;
  cntDtSCOp: any = 0;
  selectedCompanyId: any;
  isFilterDivOpen: boolean = false;
  filterObj: any = {};
  roleName: any
  statusData: any
  formtype: any
  criteriaArray: any;
  isFilterhighlited: boolean = false;
  showHeader: boolean = false;
  showHeader1: boolean = false;
  showStatus: boolean = true;
  showPop: boolean = false;
  checkIcon: boolean = false;
  isSubmitted = false;
  page: number = 0;

  pageSection1: number = 1;
  pageSection2: number = 1;
  pageSection3: number = 1;
  pageSection4: number = 1;
  pageSection5: number = 1;
  pageSection6: number = 1;
  pageSection7: number = 1;


  perPageLimit = 10;
  dtPageLimitSection1 = 10;
  dtPageLimitSection2 = 10;
  dtPageLimitSection3 = 10;
  dtPageLimitSection4 = 10;
  dtPageLimitSection5 = 10;
  dtPageLimitSection6 = 10;
  dtPageLimitSection7 = 10;

  tableSize: number = 10;
  filterType: string = 'Assesment Year';
  badgesList!: any[];
  badgesMap: any = badgeMapInvoice;
  badgeColorArray = ['border-success', 'border-danger', 'border-warning', 'border-dark', 'border-success', 'border-danger', 'border-warning', 'border-dark', 'border-success']
  textColorArray = ['text-success', 'text-danger', 'text-warning', 'text-dark', 'text-success', 'text-danger', 'text-warning', 'text-dark', 'text-success']
  queryData: any;
  activeTab: any;
  filterForm!: UntypedFormGroup;
  criteria: any = [];
  criteriaObje: any = {};
  filterPayload: any = {};
  isApplyFilter!: boolean;
  filteredQueryData: any;
  consultantList: any;
  selectedGstin: any;
  nextWeekDt: any;
  nextMonthDt: any;
  nxtDtdd1: any;
  nxtDtdd2: any;
  nxtMonthdd1: any;
  caseId: any;
  nxtMonthdd2: any;
  userRoleLs: any;
  @Input() isDtIssue: any;
  currentYear: any;
  demandAmtOptions = DEMAND_AMT_OPTIONS;
  filterStatus = DT_FILTER_STATUS;
  filterAssesmentYear = DT_Financial_Years;
  amtQuery: any = {};
  leagalRegNum: any;
  selectedUserId: any;
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'key',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 3,
    allowSearchFilter: true
  };
  division: any;

  SumofEnquiry: any = [];
  sumofIntimation: any = [];
  sumofAssessment: any = [];
  sumofCommissioner: any = [];
  sumofItat: any = [];
  sumofHc: any = [];
  sumofSc: any = [];



  directTaxSort = ENQUIRY_SORT
  sorting: { caseId: boolean, state: boolean, actType: boolean, caseLabel: boolean, periodFromDate: boolean, periodToDate: boolean, status: boolean, totOfTotal: boolean } = { caseId: false, state: false, actType: false, caseLabel: false, periodFromDate: false, periodToDate: false, status: false, totOfTotal: false };
  tableSection1Config = {
    itemsPerPage: this.dtPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'tableSection1Config',
  };


  tableSection2Config = {
    itemsPerPage: this.dtPageLimitSection2,
    currentPage: this.pageSection2,
    totalItems: 0,
    id: 'tableSection2Config'
  };
  tableSection3Config = {
    itemsPerPage: this.dtPageLimitSection3,
    currentPage: this.pageSection3,
    totalItems: 0,
    id: 'tableSection3Config'
  };
  tableSection4Config = {
    itemsPerPage: this.dtPageLimitSection4,
    currentPage: this.pageSection4,
    totalItems: 0,
    id: 'tableSection4Config'
  };
  tableSection5Config = {
    itemsPerPage: this.dtPageLimitSection5,
    currentPage: this.pageSection5,
    totalItems: 0,
    id: 'tableSection5Config'
  };
  tableSection6Config = {
    itemsPerPage: this.dtPageLimitSection6,
    currentPage: this.pageSection6,
    totalItems: 0,
    id: 'tableSection6Config',
  };
  tableSection7Config = {
    itemsPerPage: this.dtPageLimitSection7,
    currentPage: this.pageSection7,
    totalItems: 0,
    id: 'tableSection7Config',
  };


  constructor(
    private router: Router,
    private modalService: NgbModal,
    private taxService: TaxServiceService,
    private getterSetter: GetterSetterService,
    private toaster: ToasterService,
    private commonServ: CommonService,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private masterService : MasterService
  ) {
    this.tabType = TAB_TYPE;
  }

  ngOnInit() {
    this.navContext = this.commonServ.getNavContext();
    this.selectedCompanyId = localStorage.getItem('selectedId');
    this.selectedUserId = this.getterSetter.getSetUserId();
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.setDate();
    this.callAllFunction();
    if (filters) {
      this.applyFilter(true);
    }
    let countModel: any = {}
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = this.criteria
    this.taxService.DtCaseCount(countModel).subscribe((response: any) => {
      this.tableSection1Config.totalItems = response.response[0].totCaseCnt;
      this.tableSection2Config.totalItems = response.response[1].totCaseCnt;
      this.tableSection3Config.totalItems = response.response[2].totCaseCnt;
      this.tableSection4Config.totalItems = response.response[3].totCaseCnt;
      this.tableSection5Config.totalItems = response.response[4].totCaseCnt;
      this.tableSection6Config.totalItems = response.response[5].totCaseCnt;
      this.tableSection7Config.totalItems = response.response[6].totCaseCnt;

      this.cntDtEnqproc = response.response[0].totCaseCnt;
      this.cntDTtIntimation = response.response[1].totCaseCnt;
      this.cntDtAssessment = response.response[2].totCaseCnt;
      this.cntDtCommissioner = response.response[3].totCaseCnt;
      this.cntDtItat = response.response[4].totCaseCnt;
      this.cntDtHC = response.response[5].totCaseCnt;
      this.cntDteSC = response.response[6].totCaseCnt;
      this.totalCase = response.response[7].totCaseCnt;

      this.cntDtEnqprocOp = response.response[0].openCaseCnt;
      this.cntDtIntimationOp = response.response[1].openCaseCnt;
      this.cntDtAssessmentOp = response.response[2].openCaseCnt;
      this.cntDtCommissionerOp = response.response[3].openCaseCnt;
      this.cntDtItatOp = response.response[4].openCaseCnt;
      this.cntDtHCOp = response.response[5].openCaseCnt;
      this.cntDtSCOp = response.response[6].openCaseCnt;
      this.opneCase = response.response[7].openCaseCnt;
    });

    this.amtQuery = {
      "p": "totOfTotal",
      "o": null,
      "v": null
    }
    this.isDtIssue = DT_ISSUE_DROPDOWN;
    this.selectedUserId = this.getterSetter.getSetUserId();
    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
    } else {
      this.roleName = localStorage.getItem('roleName');
    }
  }

  relopageRefresh() {
    if (this.router.url.includes('directTaxSummary')) {
      this.commonServ.reloadCurrentRoute();
    }
  }


  getCaseData(caseId: any, action: string, type: string, tab: string) {
    if (!tab) {
      tab = 'tab1';
    }
    if (caseId) {
      this.router.navigate(['/createDirectTaxCase', caseId, { action: action, type: type, tab: tab, }]);
    } else {
      this.router.navigate(['/createDirectTaxCase', { action: action, type: type, tab: tab, }]);
    }
  }


  createCase(action: string, type: string, tab: string) {
    const modalRef = this.modalService.open(TaxCreateCaseModalComponent, { size: 'lg', windowClass: 'modal-xl dtCreate-pop' });
    modalRef.componentInstance.tab = tab;
    modalRef.dismissed.subscribe((data) => {
      if (data === 'penalty-case') {
        this.router.navigate(['/createDirectTaxCase', { action: action, type: type, tab: tab, penalty: true }]);
      } else if (data === 'new') {
        this.router.navigate(['/createDirectTaxCase', { action: action, type: type, tab: tab, new: true }]);
      } else if (data === 'revision') {
        this.router.navigate(['/createDirectTaxCase', { action: action, type: type, tab: tab, revision: true }]);
      } else if (data === 'existing-case') {
        this.router.navigate(['/createDirectTaxCase', { action: action, type: type, tab: tab, memorandum: true }]);
      } else if (data === 'Appealpenalty-Case') {
        this.router.navigate(['/createDirectTaxCase', { action: action, type: type, tab: tab, Appealpenalty: true }]);
      } else if (data === 'WritTax-Case') {
        this.router.navigate(['/createDirectTaxCase', { action: action, type: type, tab: tab, writTax: true }]);
      }

    });
  }

  deleteForum: any
  submitForm() {
    this.isSubmitted = true;
    let model: any = {}
    let caseIds: any = [];
    if (this.deleteForum == 'current-forum') {
      caseIds.push(this.caseIdData);
      model.caseIds = caseIds;
      model.pan = this.navContext.pan;
      model.deleteCurrentForumData = true;
    } else {
      caseIds.push(this.caseIdData);
      model.caseIds = caseIds;
      model.pan = this.navContext.pan;
      model.deleteCurrentForumData = false;
    }
    if (this.formtype === 'dtenqproc') {
      this.taxService.deleteEnqproceeding(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "This case deleted successfully");

      });
    } else if (this.formtype === 'dtintimation') {
      this.taxService.deleteIntorder(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "This case deleted successfully");
      });
    }
    else if (this.formtype === 'dtassessment') {
      this.taxService.deleteAssessment(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "This case deleted successfully");
      });
    }
    else if (this.formtype === 'dtcommissioner') {
      this.taxService.deleteCommissioner(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "This case deleted successfully");
      });
    }
    else if (this.formtype === 'dtitat') {
      this.taxService.deleteItat(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "This case deleted successfully");
      });
    }
    else if (this.formtype === 'dthc') {
      this.taxService.deleteHc(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "This case deleted successfully");
      });
    }
    else if (this.formtype === 'dtsc') {
      this.taxService.deleteSc(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "This case deleted successfully");
      });
    }
    this.relopageRefresh();
    this.modalService.dismissAll();
  }
  deleteCurrentForumData: boolean = false
  caseIdData: any
  openDetailPop(content: any, type: string, caseId: any,) {
    this.formtype = type;
    this.caseIdData = caseId
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-xl, dtDelete-pop' });
  }


  openModal(content: any) {
    this.filterType = 'Assesment Year';
    this.modalService.open(content, { windowClass: 'dtfilterDelete-pop' });
  }

  clearAll() {
    this.filterForm.reset();
  }

  callAllFunction() {
    const filters = localStorage.getItem('filters');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.initFilterForm(savedFilters);
    this.getDtSumm('dtenqproc');
    this.consultantData();
    this.personResponsibleData();
    // this.panData();
  }
  personResponsibleData() {
    let model: any = {};
    model.companyId = this.selectedCompanyId;
    this.taxService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
      }
    });
  }

  panData() {
    let model = {
      uniqueCompanyId: localStorage.getItem('selectedId'),
      userMailId: this.selectedUserId
    }
    this.taxService.getPanList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  getFormControlVal(val: any) {
    return this.filterForm.controls[val].value;
  }

  generateFilterData(isGenerateBadge: boolean = true) {
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);
    const queryData: any = {
      caseId: this.getFormControlVal('caseId'),
      caseLabel: this.getFormControlVal('caseLabel'),
      consultant: this.getFormControlVal('consultant'),
      amtQuery: this.getFormControlVal('amtQuery'),
      amtQueryValue: this.getFormControlVal('amtQueryValue'),
      dd1: this.getFormControlVal('dd1'),
      dd2: this.getFormControlVal('dd2'),
      issue: this.getFormControlVal('issue'),
      kw: this.getFormControlVal('kw'),
      pf1: this.getFormControlVal('pf1'),
      pf2: this.getFormControlVal('pf2'),
      pt1: this.getFormControlVal('pt1'),
      pt2: this.getFormControlVal('pt2'),
      personResp: this.getFormControlVal('personResp'),
      Pan: this.getFormControlVal('pan'),
      st: this.getFormControlVal('st'),
      ay: this.getFormControlVal('ay'),
    };
    this.criteria = [];
    for (const key in queryData) {
      // const criteriaObje: any = {};
      this.criteriaObje = {};
      if (
        queryData[key] != null &&
        key != 'dd2' &&
        key != 'amtQueryValue' &&
        key != 'pf2' &&
        key != 'pt2'
      ) {
        this.criteriaObje.p = key;
        this.criteriaObje.v = queryData[key];

        if (
          key == 'dd1' ||
          key == 'dd2' ||
          key == 'pf1' ||
          key == 'pf2' ||
          key == 'pt1' ||
          key == 'pt2'
        ) {
          this.criteriaObje.o = 'between';
        } else if (key == 'totOfTotal') {
          if (queryData[key] === 'gt') {
            this.criteriaObje.o = 'gt';
          } else if (queryData[key] === 'gte') {
            this.criteriaObje.o = 'gte';
          } else if (queryData[key] === 'lt') {
            this.criteriaObje.o = 'lt';
          } else if (queryData[key] === 'lte') {
            this.criteriaObje.o = 'lte';
          } else if (queryData[key] === 'eq') {
            this.criteriaObje.o = 'eq';
          }
        } else if (key == 'st') {
          this.criteriaObje.o = 'in';
        }else if (key == 'ay'){
          this.criteriaObje.o = 'in';
        } 
         else if (key == 'personResp') {
          this.criteriaObje.o = 'eq';
        }
        else {
          this.criteriaObje.o = 'contains';
        }

        if (key == 'amtQuery') {
          this.criteriaObje.p = 'totOfTotal';
          if (queryData[key] === 'gt') {
            this.criteriaObje.o = 'gt';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'gte') {
            this.criteriaObje.o = 'gte';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'lt') {
            this.criteriaObje.o = 'lt';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'lte') {
            this.criteriaObje.o = 'lte';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'eq') {
            this.criteriaObje.o = 'eq';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          }
          this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
        } else if (key == 'dd1') {
          this.criteriaObje.p = 'dueDate';
          this.criteriaObje.v1 = this.getFormControlVal('dd1');
          this.criteriaObje.v2 = this.getFormControlVal('dd2');
          delete this.criteriaObje.v;
          delete this.criteria.v;
          delete this.criteria.p;
        } else if (key == 'pf1') {
          this.criteriaObje.p = 'itemList.periodFromDate';
          this.criteriaObje.v1 = this.getFormControlVal('pf1');
          this.criteriaObje.v2 = this.getFormControlVal('pf2');
          delete this.criteriaObje.v;
          delete this.criteria.p;
        } else if (key == 'pt1') {
          this.criteriaObje.p = 'itemList.periodToDate';
          this.criteriaObje.v1 = this.getFormControlVal('pt1');
          this.criteriaObje.v2 = this.getFormControlVal('pt2');
          delete this.criteriaObje.v;
          delete this.criteria.p;
        } else if (key == 'st') {
          this.criteriaObje.p = 'status';
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        } else if(key == 'ay'){
          this.criteriaObje.p = 'ay';
           let valuesArray = this.criteriaObje.v;
           let values = Array.isArray(valuesArray) ? valuesArray.join(', ') : valuesArray;
           this.criteriaObje.v = values;
        }
         else if (key == 'issue') {
          this.criteriaObje.p = 'issues.issue';
        } else if (key == 'kw') {
          this.criteriaObje.p = 'issues.keyword';
        } else if (key == 'personResp') {
          this.criteriaObje.p = 'personResp';
        } else if (key == 'pan') {
          this.criteriaObje.p = 'Pan';
        }
        this.criteria.push(this.criteriaObje);
      }
    }
    this.filteredQueryData = _.omitBy(queryData, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
    if (isGenerateBadge) {
      this.generateBadges(this.filteredQueryData);
    }
    return this.filteredQueryData;
  }
  initFilterForm(savedFilters: any) {
    if (savedFilters) {

    }
    this.filterForm = this.formBuilder.group({
      pf1: [savedFilters?.pf1 ? savedFilters.pf1 : null],
      pf2: [savedFilters?.pf2 ? savedFilters.pf2 : null],
      pt1: [savedFilters?.pt1 ? savedFilters.pt1 : null],
      pt2: [savedFilters?.pt2 ? savedFilters.pt2 : null],
      dd1: [savedFilters?.dd1 ? savedFilters.dd1 : null],
      dd2: [savedFilters?.dd2 ? savedFilters.dd2 : null],
      kw: [savedFilters?.kw ? savedFilters.kw : null],
      personResp: [savedFilters?.personResp ? savedFilters.personResp : null],
      pan: [savedFilters?.pan ? savedFilters.pan : null],
      ay: [savedFilters?.ay ? savedFilters.ay : null],
      st: [savedFilters?.st ? savedFilters.st : null],
      caseId: [savedFilters?.caseId ? savedFilters.caseId : null],
      caseLabel: [savedFilters?.caseLabel ? savedFilters.caseLabel : null],
      consultant: [savedFilters?.consultant ? savedFilters.consultant : null],
      amtQuery: [savedFilters?.amtQuery ? savedFilters.amtQuery : null],
      amtQueryValue: [
        savedFilters?.amtQueryValue ? savedFilters.amtQueryValue : null,
      ],
      issue: [savedFilters?.issue ? savedFilters.issue : null],
      act: [savedFilters?.act ? savedFilters.act : null],
      showAcc: [true],
    });
  }

  isFilterApplied(acctype: any) {
    this.activeTab = acctype;
    if (this.isApplyFilter) {
      // if (acctype == 'dtenqproc') {
      //   const payLoad = sessionStorage.getItem('Dt-Filter')!;
      //   const data = JSON.parse(payLoad);
      //   for (let index = 0; index < data.criterias.length; index++) {
      //     const element = data.criterias[index];
      //     if (element.p == 'dueDate') {
      //       element.p = 'dueDate';
      //     } else if (element.p == 'itemList.periodFromDate') {
      //       element.p = 'periodFromDate';
      //     } else if (element.p == 'itemList.periodToDate') {
      //       element.p = 'periodToDate';
      //     } else if (element.p == 'issue') {
      //       element.p = 'issue';
      //     } else if (element.p == 'keyword') {
      //       element.p = 'keyword';
      //     }
      //   }
      //   this.taxService.getDtSummaryEnqproceeding(data).subscribe((res: any) => {
      //     this.SumofEnquiry = res.response;
      //   });
      // } else
      if (acctype == 'dtintimation') {
        const payLoad = sessionStorage.getItem('Dt-Filter')!;
        const data = JSON.parse(payLoad);

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'itemList.periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'itemList.periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issue') {
            element.p = 'issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.taxService.getDtSummaryIntorder(data).subscribe((res: any) => {
          this.sumofIntimation = res.response;
        });
      } else if (acctype == 'dtassessment') {
        const payLoad = sessionStorage.getItem('Dt-Filter')!;
        const data = JSON.parse(payLoad);

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'itemList.periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'itemList.periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issue') {
            element.p = 'issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.taxService.getDtSummaryAssessment(data).subscribe((res: any) => {
          this.sumofAssessment = res.response;
        });
      } else if (acctype == 'dtcommissioner') {
        const payLoad = sessionStorage.getItem('Dt-Filter')!;
        const data = JSON.parse(payLoad);

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'itemList.periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'itemList.periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issue') {
            element.p = 'issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.taxService.getDtSummaryCommissioner(data).subscribe((res: any) => {
          this.sumofCommissioner = res.response;
        });
      } else if (acctype == 'dtitat') {
        const payLoad = sessionStorage.getItem('Dt-Filter')!;
        const data = JSON.parse(payLoad);

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'itemList.periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'itemList.periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issue') {
            element.p = 'issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.taxService.getDtSummaryItat(data).subscribe((res: any) => {
          this.sumofItat = res.response;
        });
      }
      else if (acctype == 'dthc') {
        const payLoad = sessionStorage.getItem('Dt-Filter')!;
        const data = JSON.parse(payLoad);

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'itemList.periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'itemList.periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issue') {
            element.p = 'issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.taxService.getDtSummaryHc(data).subscribe((res: any) => {
          this.sumofHc = res.response;
        });
      } else if (acctype == 'dtsc') {
        const payLoad = sessionStorage.getItem('Dt-Filter')!;
        const data = JSON.parse(payLoad);

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'itemList.periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'itemList.periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issue') {
            element.p = 'issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.taxService.getDtSummarySc(data).subscribe((res: any) => {
          this.sumofSc = res.response;
        });
      }
    } else {
      this.getDtSumm(acctype);
    }
  }

  getDtSumm(acctype: any) {
    const filters = sessionStorage.getItem('dt-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      this.applyFilter(false);
    }
    this.SumofEnquiry = [];
    let mainModel: any = {};
    if (acctype === 'dtenqproc') {
      this.SumofEnquiry = [];
      let model: any = {};
      let countModel: any = {};
      countModel.companyId = this.selectedCompanyId;
      this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
        this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
      });
      Object.assign(model, {
        page: this.tableSection1Config.currentPage == 1 ? 0 : this.tableSection1Config.currentPage - 1,
        size: this.tableSection1Config.itemsPerPage,
        sortfield: 'createdOn',
        sortdir: 'DESC',
        companyId: this.selectedCompanyId
      });
      this.taxService.getDtSummaryEnqproceeding(model).subscribe((response: any) => {
        if (response.status == 1) {
          this.SumofEnquiry = response.response;
          this.tableSection1Config.totalItems = this.SumofEnquiry;
        }
      })
    } else if (acctype === 'dtintimation') {
      this.sumofIntimation = [];
      let model: any = {};
      let countModel: any = {};
      countModel.companyId = this.selectedCompanyId;
      this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
        this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
      });
      Object.assign(model, {
        page: this.tableSection2Config.currentPage == 1 ? 0 : this.tableSection2Config.currentPage - 1,
        size: this.tableSection2Config.itemsPerPage,
        sortfield: 'createdOn',
        sortdir: 'DESC',
        companyId: this.selectedCompanyId
      });
      this.taxService.getDtSummaryIntorder(model).subscribe((response: any) => {
        if (response.status == 1) {
          this.sumofIntimation = response.response;
          this.tableSection2Config.totalItems = this.sumofIntimation;

        }
      });
    } else if (acctype === 'dtassessment') {
      this.sumofAssessment = [];
      let model: any = {};
      let countModel: any = {};
      const criteriaArray: any = [{
        "p": "status",
        "o": "nin",
        "v": "MOVED"
      }]
      countModel.criterias = criteriaArray;
      countModel.companyId = this.selectedCompanyId;
      this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
        this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
      });
      Object.assign(model, {
        page: this.tableSection3Config.currentPage == 1 ? 0 : this.tableSection3Config.currentPage - 1,
        size: this.tableSection3Config.itemsPerPage,
        sortfield: 'createdOn',
        sortdir: 'DESC',
        companyId: this.selectedCompanyId
      });
      this.taxService.getDtSummaryAssessment(model).subscribe((response: any) => {
        if (response.status == 1) {
          this.sumofAssessment = response.response;
          this.tableSection3Config.totalItems = this.sumofAssessment;

        }
      },
        (err) => {
          this.toaster.showError("User Role Based Access Restricted");
        }
      );
    } else if (acctype === 'dtcommissioner') {
      this.sumofCommissioner = [];
      let model: any = {};
      let countModel: any = {};
      countModel.companyId = this.selectedCompanyId;
      this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
        this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
      });
      Object.assign(model, {
        page: this.tableSection4Config.currentPage == 1 ? 0 : this.tableSection4Config.currentPage - 1,
        size: this.tableSection4Config.itemsPerPage,
        sortfield: 'createdOn',
        sortdir: 'DESC',
        companyId: this.selectedCompanyId
      });
      this.taxService.getDtSummaryCommissioner(model).subscribe((response: any) => {
        if (response.status == 1) {
          this.sumofCommissioner = response.response;
          this.tableSection4Config.totalItems = this.sumofCommissioner;
        }
      },
        (err) => {
          this.toaster.showError("User Role Based Access Restricted");
        }
      );
    } else if (acctype === 'dtitat') {
      this.sumofItat = [];
      let model: any = {};
      let countModel: any = {};
      countModel.companyId = this.selectedCompanyId;
      this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
        this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
      });
      Object.assign(model, {
        page: this.tableSection5Config.currentPage == 1 ? 0 : this.tableSection5Config.currentPage - 1,
        size: this.tableSection5Config.itemsPerPage,
        sortfield: 'createdOn',
        sortdir: 'DESC',
        companyId: this.selectedCompanyId
      });
      this.taxService.getDtSummaryItat(model).subscribe((response: any) => {
        if (response.status == 1) {
          this.sumofItat = response.response;
          this.tableSection5Config.totalItems = this.sumofItat;
        }
      },
        (err) => {
          this.toaster.showError("User Role Based Access Restricted");
        }
      );
    }
    else if (acctype === 'dthc') {
      this.sumofHc = [];
      let model: any = {};
      let countModel: any = {};
      countModel.companyId = this.selectedCompanyId;
      this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
        this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
      });
      Object.assign(model, {
        page: this.tableSection6Config.currentPage == 1 ? 0 : this.tableSection6Config.currentPage - 1,
        size: this.tableSection6Config.itemsPerPage,
        sortfield: 'createdOn',
        sortdir: 'DESC',
        companyId: this.selectedCompanyId
      });
      this.taxService.getDtSummaryHc(model).subscribe((response: any) => {
        if (response.status == 1) {
          this.sumofHc = response.response;
          this.tableSection6Config.totalItems = this.sumofHc;
        }
      });
    } else if (acctype === 'dtsc') {
      this.sumofSc = [];
      let model: any = {};
      let countModel: any = {};
      countModel.companyId = this.selectedCompanyId;
      this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
        this.tableSection7Config.totalItems = res.response[6].totCaseCnt;
      });
      Object.assign(model, {
        page: this.tableSection7Config.currentPage == 1 ? 0 : this.tableSection7Config.currentPage - 1,
        size: this.tableSection7Config.itemsPerPage,
        sortfield: 'createdOn',
        sortdir: 'DESC',
        companyId: this.selectedCompanyId
      });
      this.taxService.getDtSummarySc(model).subscribe((response: any) => {
        if (response.status == 1) {
          this.sumofSc = response.response;
          this.tableSection7Config.totalItems = this.sumofSc;
        }
      });
    } else {
      this.taxService.getDtSummaryEnqproceeding(mainModel).subscribe((response: any) => {
        if (response.status == 1) {
          this.SumofEnquiry = response.response;
        }
      });
    }

  }

  applyFilter(isAddNewFilter: boolean) {
    this.isApplyFilter = true;
    const filters = sessionStorage.getItem('dt-filters-Data');
    let savedFilters = null;
    this.badgesList = [];
    if (isAddNewFilter) {
      this.generateFilterData();
    } else {
      if (filters) {
        savedFilters = !!filters ? JSON.parse(filters) : null;
        for (let i = 0; i < savedFilters.criterias.length; i++) {
          const element = savedFilters.criterias[i];
          const filteredQueryData: any = {};
          filteredQueryData[element.p] = element.v;
          this.generateBadges(filteredQueryData);
          const value = element.v;
          const keyName = element.p;
          this.filterForm.get(keyName)?.setValue(value);
        }
      }
    }
    this.filterPayload = {
      page: 0,
      size: 10,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
    }

    sessionStorage.setItem('Dt-Filter', JSON.stringify(this.filterPayload));
    this.taxService.getDtSummaryEnqproceeding(this.filterPayload).subscribe((res: any) => {
      if (res.response == null) {
        this.SumofEnquiry = [];
      } else {
        this.SumofEnquiry = res.response;
      }
    });
    this.filterPayload = {
      companyId: this.selectedCompanyId,
      criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
    }
    // sessionStorage.setItem('Dt-Filter', JSON.stringify(this.filterPayload));
    this.taxService.DtCaseCount(this.filterPayload).subscribe((response: any) => {
      this.tableSection1Config.totalItems = response.response[0].totCaseCnt;
      this.tableSection2Config.totalItems = response.response[1].totCaseCnt;
      this.tableSection3Config.totalItems = response.response[2].totCaseCnt;
      this.tableSection4Config.totalItems = response.response[3].totCaseCnt;
      this.tableSection5Config.totalItems = response.response[4].totCaseCnt;
      this.tableSection6Config.totalItems = response.response[5].totCaseCnt;
      this.tableSection7Config.totalItems = response.response[6].totCaseCnt;

      this.cntDtEnqproc = response.response[0].totCaseCnt;
      this.cntDTtIntimation = response.response[1].totCaseCnt;
      this.cntDtAssessment = response.response[2].totCaseCnt;
      this.cntDtCommissioner = response.response[3].totCaseCnt;
      this.cntDtItat = response.response[4].totCaseCnt;
      this.cntDtHC = response.response[5].totCaseCnt;
      this.cntDteSC = response.response[6].totCaseCnt;
      this.totalCase = response.response[7].totCaseCnt;

      this.cntDtEnqprocOp = response.response[0].openCaseCnt;
      this.cntDtIntimationOp = response.response[1].openCaseCnt;
      this.cntDtAssessmentOp = response.response[2].openCaseCnt;
      this.cntDtCommissionerOp = response.response[3].openCaseCnt;
      this.cntDtItatOp = response.response[4].openCaseCnt;
      this.cntDtHCOp = response.response[5].openCaseCnt;
      this.cntDtSCOp = response.response[6].openCaseCnt;
      this.opneCase = response.response[7].openCaseCnt;
    });

    if (this.filterPayload) {
      sessionStorage.setItem('dt-filters-Data', JSON.stringify(this.filterPayload));

    } else {

    }
    this.modalService.dismissAll();
  }


  currentStatusFilter(status: any, type: any) {
    this.badgesList = [];
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);
    const badgeObjStatus: any = {};
    badgeObjStatus.key = 'status';
    badgeObjStatus.name = 'Status';
    badgeObjStatus.value = status;
    this.badgesList.push(badgeObjStatus);

    const criteriaData = [
      {
        p: badgeObjStatus.key,
        o: 'eq',
        v: badgeObjStatus.value,
      },
    ];
    if (!data.companyId) {
      data.companyId = this.selectedCompanyId;
    }
    this.filterPayload = {
      page: 0,
      size: 10,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: criteriaData,
    };

    if (type == 'dtenqproc') {
      this.taxService.getDtSummaryEnqproceeding(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.SumofEnquiry = [];
        } else {
          this.SumofEnquiry = res.response;
        }
      });
    } else if (type == 'dtintimation') {
      this.taxService.getDtSummaryIntorder(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.sumofIntimation = [];
        } else {
          this.sumofIntimation = res.response;
        }
      });
    }
    else if (type == 'dtassessment') {
      this.taxService.getDtSummaryAssessment(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.sumofAssessment = [];
        } else {
          this.sumofAssessment = res.response;
        }
      });
    } else if (type == 'dtcommissioner') {
      this.taxService.getDtSummaryCommissioner(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.sumofCommissioner = [];
        } else {
          this.sumofCommissioner = res.response;
        }
      });
    } else if (type == 'dtitat') {
      this.taxService.getDtSummaryItat(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.sumofItat = [];
        } else {
          this.sumofItat = res.response;
        }
      });
    } else if (type == 'dthc') {
      this.taxService.getDtSummaryHc(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.sumofHc = [];
        } else {
          this.sumofHc = res.response;
        }
      });
    } else if (type == 'dtsc') {
      this.taxService.getDtSummarySc(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.sumofSc = [];
        } else {
          this.sumofSc = res.response;
        }
      });
    }
  }
  generateBadges(queryData: any) {
    if (queryData != null) {
      if (this.badgesMap) {
        for (var key in this.badgesMap) {
          const badgeObj: any = {};
          if (queryData.hasOwnProperty(key)) {
            badgeObj.key = key;
            badgeObj.name = this.badgesMap[key];
            badgeObj.value = queryData[key];
            this.badgesList.push(badgeObj);
          }
        }
      }
    }
  }



  getEnqueryData(dtPageLimitSection1: any, section1page: number) {
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    this.SumofEnquiry = [];
    let model: any = {};
    let countModel: any = {};
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
      this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
    });
    Object.assign(model, {
      page: section1page == 1 ? 0 : section1page - 1,
      size: parseInt(dtPageLimitSection1),
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    this.taxService.getDtSummaryEnqproceeding(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.SumofEnquiry = response.response;
        this.tableSection1Config.totalItems = this.SumofEnquiry;
        this.tableSection1Config.itemsPerPage = dtPageLimitSection1;
        this.tableSection1Config.currentPage = model.page + 1;
        console.log(this.SumofEnquiry, "SumofEnquiry");
      }
    })
  }
  getIntimationData(dtPageLimitSection2: any, section2page: number) {
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    this.sumofIntimation = [];
    let model: any = {};
    let countModel: any = {};
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
      this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
    });
    Object.assign(model, {
      page: section2page == 1 ? 0 : section2page - 1,
      size: dtPageLimitSection2,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,      
      criterias: savedFilters ? savedFilters.criterias : []

    });
    this.taxService.getDtSummaryIntorder(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.sumofIntimation = response.response;
        this.tableSection2Config.totalItems = this.sumofIntimation;
        this.tableSection2Config.itemsPerPage = dtPageLimitSection2;
        this.tableSection2Config.currentPage = model.page + 1;
      }
    });
  }
  getAssessmentData(dtPageLimitSection3: any, section3page: number) {
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    this.sumofAssessment = [];
    let model: any = {};
    let countModel: any = {};
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
      this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
    });
    Object.assign(model, {
      page: section3page == 1 ? 0 : section3page - 1,
      size: dtPageLimitSection3,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    this.taxService.getDtSummaryAssessment(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.sumofAssessment = response.response;
        this.tableSection3Config.totalItems = this.sumofAssessment;
        this.tableSection3Config.itemsPerPage = dtPageLimitSection3;
        this.tableSection3Config.currentPage = model.page + 1;
      }
    });
  }

  getCommissionerData(dtPageLimitSection4: any, section4page: number) {
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    this.sumofCommissioner = [];
    let model: any = {};
    let countModel: any = {};
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
      this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
    });
    Object.assign(model, {
      page: section4page == 1 ? 0 : section4page - 1,
      size: dtPageLimitSection4,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    this.taxService.getDtSummaryCommissioner(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.sumofCommissioner = response.response;
        this.tableSection4Config.totalItems = this.sumofCommissioner;
        this.tableSection4Config.itemsPerPage = dtPageLimitSection4;
        this.tableSection4Config.currentPage = model.page + 1;
      }
    });
  }
  getItatData(dtPageLimitSection5: any, section5page: number) {
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    this.sumofItat = [];
    let model: any = {};
    let countModel: any = {};
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
      this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
    });
    Object.assign(model, {
      page: section5page == 1 ? 0 : section5page - 1,
      size: dtPageLimitSection5,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    this.taxService.getDtSummaryItat(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.sumofItat = response.response;
        this.tableSection5Config.totalItems = this.sumofItat;
        this.tableSection5Config.itemsPerPage = dtPageLimitSection5;
        this.tableSection5Config.currentPage = model.page + 1;
      }
    });
  }
  getHcData(dtPageLimitSection6: any, section6page: number) {
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    this.sumofHc = [];
    let model: any = {};
    let countModel: any = {};
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
      this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
    });
    Object.assign(model, {
      page: section6page == 1 ? 0 : section6page - 1,
      size: dtPageLimitSection6,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: savedFilters ? savedFilters.criterias : []

    });
    this.taxService.getDtSummaryHc(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.sumofHc = response.response;
        this.tableSection6Config.totalItems = this.sumofHc;
        this.tableSection6Config.itemsPerPage = dtPageLimitSection6;
        this.tableSection6Config.currentPage = model.page + 1;
      }
    });
  }
  getDTScData(dtPageLimitSection7: any, section7page: number) {
    const filters = sessionStorage.getItem("dt-filters-Data")
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    this.sumofSc = [];
    let model: any = {};
    let countModel: any = {};
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.taxService.DtCaseCount(countModel).subscribe((res: any) => {
      this.tableSection7Config.totalItems = res.response[6].totCaseCnt;
    });
    Object.assign(model, {
      page: section7page == 1 ? 0 : section7page - 1,
      size: dtPageLimitSection7,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    this.taxService.getDtSummarySc(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.sumofSc = response.response;
        this.tableSection7Config.totalItems = this.sumofSc;
        this.tableSection7Config.itemsPerPage = dtPageLimitSection7;
        this.tableSection7Config.currentPage = model.page + 1;
      }
    });
  }


  onTableDataChangeEnquery(pageNumber: number): void {
    this.tableSection1Config.currentPage = pageNumber;
    this.getEnqueryData(this.tableSection1Config.itemsPerPage, this.tableSection1Config.currentPage);
  }
  onTableDataChangeSection2(pageNumber: number) {
    this.tableSection2Config.currentPage = pageNumber;
    this.getIntimationData(this.tableSection2Config.itemsPerPage, this.tableSection2Config.currentPage);
  }
  onTableDataChangeSection3(pageNumber: number) {
    this.tableSection3Config.currentPage = pageNumber;
    this.getAssessmentData(this.tableSection3Config.itemsPerPage, this.tableSection3Config.currentPage);
  }
  onTableDataChangeSection4(pageNumber: number) {
    this.tableSection4Config.currentPage = pageNumber;
    this.getCommissionerData(this.tableSection4Config.itemsPerPage, this.tableSection4Config.currentPage);
  }
  onTableDataChangeSection5(pageNumber: number) {
    this.tableSection5Config.currentPage = pageNumber;
    this.getItatData(this.tableSection5Config.itemsPerPage, this.tableSection5Config.currentPage);
  }
  onTableDataChangeSection6(pageNumber: number) {
    this.tableSection6Config.currentPage = pageNumber;
    this.getHcData(this.tableSection6Config.itemsPerPage, this.tableSection6Config.currentPage);
  }
  onTableDataChangeSection7(pageNumber: number) {
    this.tableSection7Config.currentPage = pageNumber;
    this.getDTScData(this.tableSection7Config.itemsPerPage, this.tableSection7Config.currentPage);
  }

  reloadSummary() {
    this.getDtSumm('dtenqproc');
  }

  removeFilter(badge: any) {
    this.badgesList = this.badgesList.filter(e => e !== badge);
    switch (badge) {
      case 'Case Id':
        delete this.queryData['caseId'];
        this.filterForm.patchValue({
          caseId: null,
        });
        break;

      case 'Act':
        delete this.queryData['act'];
        this.filterForm.patchValue({
          act: null,
        });
        break;

      case 'Case Label':
        delete this.queryData['caseLabel'];
        this.filterForm.patchValue({
          caseLabel: null,
        });
        break;

      case 'Consultant':
        delete this.queryData['consultant'];
        this.filterForm.patchValue({
          consultant: null,
        });
        break;

      case 'Demand Amount':
        delete this.queryData['amtQuery'];
        delete this.queryData['amtQueryValue'];

        this.filterForm.patchValue({
          amtQuery: null,
          amtQueryValue: null,
        });
        break;

      case 'Due Date':
        delete this.queryData['dt1'];
        delete this.queryData['dt2'];

        this.filterForm.patchValue({
          dt1: null,
          dt2: null,
        });
        break;

      case 'Key words':
        delete this.queryData['kw'];

        this.filterForm.patchValue({
          kw: null,
        });
        break;

      case 'Period From':
        delete this.queryData['pf1'];
        delete this.queryData['pf2'];

        this.filterForm.patchValue({
          pf1: null,
          pf2: null,
        });
        break;

      case 'Period To':
        delete this.queryData['pt1'];
        delete this.queryData['pt2'];

        this.filterForm.patchValue({
          pt1: null,
          pt2: null,
        });
        break;

      case 'Person Responsible':
        delete this.queryData['personResp'];

        this.filterForm.patchValue({
          personResp: null
        });
        break;
      case 'Pan Id':
        delete this.queryData['pan'];

        this.filterForm.patchValue({
          pan: null
        });
        break;

      case 'Status':
        delete this.queryData['st'];

        this.filterForm.patchValue({
          st: null
        });
        break;
        case 'Assesment Year':

        delete this.queryData['ay'];

        this.filterForm.patchValue({
          ay: null
        });
        break;
    }
    const BadgArray = this.badgesList;

    const filterData: any = sessionStorage.getItem('Dt-Filter');
    const parseData = JSON.parse(filterData);
    const criteriaArray: any = parseData.criterias;

    let matchedObjects = criteriaArray.filter((obj1: any) => {
      return this.badgesList.some(obj2 => obj1.p === obj2.key && obj1.v === obj2.value);
    });
    const revisedFilter: any = (sessionStorage.getItem('dt-filters-Data'));
    const parseFilterData = JSON.parse(revisedFilter);
    parseData.criterias = matchedObjects;
    parseFilterData.criterias = matchedObjects;

    sessionStorage.setItem('Dt-Filter', JSON.stringify(parseData));
    sessionStorage.setItem('dt-filters-Data', JSON.stringify(parseFilterData));
    this.reloadSummary();
    this.clearAll();
  }

  nextWeek() {
    let today = new Date();
    this.nextWeekDt = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    this.nxtDtdd1 = today;
    this.nxtDtdd2 = this.nextWeekDt;
    this.filterForm.get('dd1')?.setValue(this.nxtDtdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtDtdd2);

  }
  nextMonth() {
    let today = new Date();
    this.nextMonthDt = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30);
    this.nxtMonthdd1 = today;
    this.nxtMonthdd2 = this.nextMonthDt;
    this.filterForm.get('dd1')?.setValue(this.nxtMonthdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtMonthdd2);

  }

  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  showHidePop() {
    this.showPop = !this.showPop;
    setTimeout(() => {               // set it to run again after ten minutes
      this.showPop = false;
      this.toaster.showError('Request is time out, please place new request after some time.');
    }, 600000);
  }
  generatedReport(option: string) {
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('dt-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]
    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.pan = '';
      model.reportType = 6;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? this.criteria : [];

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 6;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = (savedFilters && !criteriaArray) ? this.criteria : (!savedFilters && criteriaArray) ? criteriaArray : mergeCriteriaArray;

    }
    this.taxService.generateMasterReport(model).subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
    })

  }
  contingencyReport(option: string) {
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('dt-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]
    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.pan = '';
      model.reportType = 7;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? this.criteria : [];

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 7;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = (savedFilters && !criteriaArray) ? this.criteria : (!savedFilters && criteriaArray) ? criteriaArray : mergeCriteriaArray;

    }
    this.taxService.generateMasterReport(model).subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
    })

  }
  closeDiv() {
    this.showPop = false;
  }
  statusReport() {
    this.taxService.statusMasterReport().subscribe((response: any) => {
      this.statusData = response.response.status;
      if (this.statusData == 1) {
        this.showStatus = false;
        this.toaster.showSuccess('Request processed successfully');
      } else {
        this.toaster.showWarning('Request in progress');
      }
    })
  }
  downLoadMasterReport() {
    this.taxService.downLoadMasterReports().subscribe((response: any) => {
      const rootPan = sessionStorage.getItem('company')
      const date = this.commonServ.getCurrentDate()
      let fileName = `${rootPan}_DT_Report_${date}`
      if (this.showHeader) {
        fileName = `${rootPan}_PROV & CONT_DT_Report__${date}`
      }
      this.excelService.saveAsExcelFile(response, fileName, true)
      this.checkIcon = true;
      this.toaster.showSuccess('File downloaded successfully');
      this.relopageRefresh();
    })
  }
}


<div class="tab-pane" id="AppelAuth-1" role="tabpanel" aria-labelledby="AppelAuth-tab">
	<div class="d-flex justify-content-between">
		<div class="col-md-5 nopadding">
			<ul class="nav nav-tabs nav nav-tabs iris-gst-display-flex" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" id="AppelAuthp1-tab" data-toggle="tab" href="#AppelAuthp1-1" role="tab"
						aria-controls="AppelAuthp1-1" (click)="tabChanged('tab1')" aria-selected="true">Phase 1</a>
				</li>
				<li class="nav-item" [ngClass]="{'disabled': !isPhase2 && !(action == 'update')}">
					<a class="nav-link" id="AppelAuthp2-tab" data-toggle="tab" href="#AppelAuthp2-1" role="tab"
						aria-controls="AppelAuthp2-1" aria-selected="false" (click)="tabChanged('tab2')">Phase 2</a>
				</li>
				<li class="nav-item showBack" *ngIf="isRemandBackTabVisible">
					<a class="nav-link" id="AppelAuthp21-tab" data-toggle="tab" href="#AppelAuthp21-1" role="tab"
						aria-controls="AppelAuthp21-1" (click)="tabChanged('remadnBack')" aria-selected="false">Remand
						Back</a>
				</li>
				<li class="nav-item"
					[ngClass]="{'disabled': !phase2Form.value.submmissionDtls.subDate || getAckCopyLength() == 0 || form36Length || applGroundLocLength || stmtFactLength || applChallanFeesLength}">
					<a class="nav-link" id="AppelAuthp3-tab" data-toggle="tab" href="#AppelAuthp3-1" role="tab"
						aria-controls="AppelAuthp3-1" aria-selected="false" (click)="tabChanged('tab3')">Phase 3</a>
				</li>
				<!-- <li class="computationBtn">
            <a href="computation.html"><i class="mdi mdi-file-document-box clrOrange notifyIcon pr5"></i>Computation</a>
         </li> -->
			</ul>
		</div>
		<div class="col-md-3">
			<div class="textCenter pt10">
				<span class="infoVal fs16" *ngIf="isVisibleCaseId" title="Case ID">{{caseData.caseId}}</span>

			</div>
		</div>
		<div class="col-md-4">
			<div>
				<span class="updatedData" *ngIf="caseData">
					<span class="infoTitle">Last Updated By :</span>{{caseData.updatedBy}} <span
						class="infoVal mr5"></span>
					<span class="infoTitle">On:</span> {{caseData.updateDate}} <span class="infoVal"></span>
				</span>
			</div>
		</div>
	</div>
	<div class="tab-content nopadding">
		<div class="tab-pane fade show active" id="AppelAuthp1-1" role="tabpanel" aria-labelledby="AppelAuthp1-tab">
			<div class="card borderOrange">
				<div class="card-body ptl10 plr010">
					<div class="row">
						<div class="col-2" id="verticalTab9" *ngIf="!sidemenu">
							<ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="adjGI-tab-vertical" data-toggle="tab" href="#adjGI-2"
										role="tab" aria-controls="adjGI-2" aria-selected="true">
										General Info
									</a>
								</li>
								<li class="nav-item" [ngClass]="{'disabled': (!tab2Disable && !(action == 'update' ))}">
									<a class="nav-link" id="adjAo-tab-vertical" data-toggle="tab" href="#adjAo-2"
										role="tab" aria-controls="adjAo-2" aria-selected="false" #adjAoTab>
										Appealable Order
									</a>
								</li>

								<li class="nav-item" [ngClass]="{'disabled': (!tab3Disable && !(action == 'update' ))}">
									<a class="nav-link" id="adjitr-tab-vertical" data-toggle="tab" href="#adjitr-2"
										role="tab" aria-controls="adjitr-2" aria-selected="false" #itrTab>
										ITR Details
									</a>
								</li>
								<li class="nav-item" [ngClass]="{'disabled': (!tab4Disable && !(action == 'update' ))}">
									<a class="nav-link" id="adjissuea-tab-vertical" data-toggle="tab"
										href="#adjissuea-2" role="tab" aria-controls="adjissuea-2" aria-selected="false"
										#preDepositTab>
										Protest & Stay Details
									</a>
								</li>
								<li class="nav-item" [ngClass]="{'disabled': (!tab5Disable && !(action == 'update' ))}"
									*ngIf="isCreateBtn">
									<a class="btn btn-outline-primary w100"
										[ngClass]="{'disabled': (!tab5Disable && !(action == 'update' ))}"
										(click)="saveBtn()" #saveTab>
										Save </a>
								</li>
								<li class="nav-item" (click)="updatePhase2()" *ngIf="isUpdateBtn">
									<a class="btn btn-outline-primary w100"
										[ngClass]="{'disabled': (action == 'view')}"> Update </a>
								</li>
							</ul>
						</div>
						<div class="col-10 border " id="tabArea9" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
							<div class="tab-content tab-content-vertical">
								<div class="tab-pane fade show active" id="adjGI-2"
									[ngClass]="{'bgGray': (action == 'view')}" role="tabpanel"
									aria-labelledby="adjGI-tab-vertical">
									<fieldset [disabled]="action == 'view'">
										<div [formGroup]="generalInfo">
											<div class="row">
												<div class="col-md-12">
													<p class="textCenter">General Info</p>
													<span class="toggleTab" (click)="sidemenu=!sidemenu">
														<i class="mdi mdi mdi-chevron-double-left leftTIcon"
															*ngIf="!sidemenu"></i>
														<i class="mdi mdi-chevron-double-right rightTIcon"
															*ngIf="sidemenu"></i>
													</span>
												</div>
												<div class="col-md-3" *ngIf="isVisibleCaseId">
													<div class="form-group">
														<label>Case ID <span class="mandate"><i
																	class="fa fa-asterisk"></i></span></label>
														<input type="text" formControlName="caseId" class="form-control"
															id="caseId" readonly />
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<label>PAN</label>
														<input type="text" formControlName="pan" class="form-control"
															readonly id="pan" placeholder="" />
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<label>Legal PAN Name </label>
														<input type="text" formControlName="panName"
															class="form-control" readonly placeholder="panName" />
													</div>
												</div>
												<div class="col-md-3" [ngClass]="{'disabled':isDisabledSelect}">
													<div class="form-group">
														<label>Assessment Year
															<span class="mandate"><i class="fa fa-asterisk"></i></span>
														</label>
														<select class="form-control form-select sd" id="ay"
															formControlName="ay" (change)="getperiodToValue(ayPop)"
															[ngClass]="{errorBorder:isNextBtnClicked && !generalInfo.controls.ay.value }">
															<ng-container *ngFor="let obj of finyearData">
																<option [value]="obj">{{ obj }}</option>
															</ng-container>
														</select>
														<div
															*ngIf="(isNextBtnClicked && !generalInfo.controls.ay.value) || (isNextBtnClicked && generalInfo.controls.ay.value == 'null')">
															<span class="text-danger">Assessment Year Required</span>
														</div>
														<ng-template #ayPop let-modal>
															<div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
															  <button type="button" class="close" aria-label="Close"
																(click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
															  </button>
															</div>
															<div class="col-md-12" >
															  <div class="table-responsive">
																<p class="title-data">Following cases are already exist for same selected Assessment Year,<span style="font-weight: bold;">Do you want to still proceed?</span></p>
																<table style="margin-left: 5px;">
																  <thead>
																	<tr class="bg-primary text-dblue">
																	  <th class="sl-no-cla">Sr No</th>
																	  <th class="clas">Case id</th>
																	  <th>Case label</th>
																	  <th class="clas">Assessment Year</th>																	   
																	  <th>Forum</th>
																	  <th class="ac-no-clas">Total Demand(₹)</th>
																	</tr>
																  </thead>
																  <tbody>
																	<tr
																	  *ngFor="let obj of duplicateData  let i = index">
																	  <td class="sl-no-cla">{{i + 1}}</td>
																	  <td class="clas">{{obj.caseId}}</td>
																	  <td>{{obj.caseLabel}}</td>
																	  <td class="clas">{{obj.ay}}</td>
																	  <td>{{obj.forum}}</td>
																	  <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
																	</tr>
																  </tbody>
																</table>
															  </div>
															</div>
										  
															<div class="modal-footer" style="border: none; padding-top: 5px;" >
															  <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
															  (click)="modal.close('No click')">Yes</button>
																<button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
																(click)="goToSummary()">NO</button>
															</div>
														  </ng-template>
													</div>
												</div>
												<div class="col-md-3">
													<div class="form-group">
														<label>Financial Year</label>
														<input type="text" formControlName="finYear" readonly
															class="form-control" placeholder="" />
														<!-- <div *ngIf="isNextBtnClicked && !generalInfo.controls.finYear.value">
                                             <span class="text-danger">Financial Year Required</span>
                                          </div> -->
													</div>
												</div>

												<!-- <div class="col-md-3">
													<div class="form-group">
														<label>Consultant Name</label>
														<input type="text" formControlName="consultant" id="consultant"
															class="form-control" placeholder=""
															[ngClass]="{ errorBorder: generalInfo.controls.consultant.hasError('maxlength')}" />
													</div>
													<div class="text-danger"
														*ngIf="generalInfo.controls.consultant.touched &&  generalInfo.controls.consultant.hasError('maxlength')">
														Maximum of 100 characters
													</div>
												</div> -->

												<div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create' )">
													<div class="form-group">
													  <label for="exampleInputPassword1">Consultant</label>
												  
													  <!-- For Consultant Role: Create, View, and Update -->
													  <div *ngIf="roleName === 'Consultant' && action === 'create'">
														<input 
														  type="text" 
														  class="form-control" 
														  placeholder="Consultant Name" 
														  formControlName="consultant" 
														  [readOnly]="action === 'create'">
													  </div>
												  
													  <!-- For Non-Consultant Role -->
													  <div *ngIf="roleName !== 'Consultant'">
														<div *ngIf="isToggleConsultantDropdown && (action === 'update' || action === 'view')" class="input-group">
														  <input 
															type="text" 
															class="form-control" 
															placeholder="" 
															formControlName="consultant" 
															readonly>
														  <button 
															*ngIf="isToggleConsultantDropdown && action === 'update'" 
															class="btn btn-outline-secondary" 
															type="button" 
															(click)="onClickToggleConsultantDropdown('p1')">
															<i class="fa fa-pencil"></i>
														  </button>
														</div>
												  
														<select 
														  class="form-control form-select" 
														  id="consultant" 
														  *ngIf="!isToggleConsultantDropdown || action === 'create'" 
														  formControlName="consultant">
														  <option [value]="null">-- Select --</option>
														  <ng-container *ngFor="let obj of consultantList">
															<option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
														  </ng-container>
														</select>
													  </div>
													</div>
												 </div>
												 
												<div class="col-md-3">
													<div class="form-group">
														<label>Person responsible <span class="mandate"><i
																	class="fa fa-asterisk"></i></span></label>
																	
																	<div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
																		<input class="form-control" type="text" formControlName="personResp" readonly>
																		<button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
																		  <i class="fa fa-pencil"></i>
																		</button>
																	  </div>
													
																	  <select *ngIf="!isToggleDropdown || action==='create'" class="form-control form-select" id="personResp" formControlName="personResp" (change)="onClickToggleDropdown()"
																		[ngClass]="{ 'errorBorder': isNextBtnClicked && generalInfo.controls.personResp.errors }">
																		<option [value]="null">-- Select --</option>
																		<ng-container *ngFor="let obj of userRoleLs">
																		  <option [value]="obj.username">{{obj.username}}</option>
																		</ng-container>
																	  </select>
														<div
															*ngIf="isNextBtnClicked && !generalInfo.controls.personResp.value">
															<span class="text-danger">Person responsible Required</span>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group">
															<label>Case Label <span class="mandate"><i
																		class="fa fa-asterisk"></i></span><a
																	class="clrOrange infoIcon"
																	title="Please give a one line description to identify the case."><i
																		class="fa fa-info-circle"
																		style="margin-left: 3px"></i></a></label>
															<textarea class="form-control" id="exampleTextarea1"
																rows="4" formControlName="caseLabel"
																[ngClass]="{ errorBorder:(isNextBtnClicked && !generalInfo.controls.caseLabel.value) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125) }"></textarea>
														</div>
														<div
															*ngIf=" isNextBtnClicked &&  !generalInfo.controls.caseLabel.value">
															<span class="text-danger">Case Label Required</span>
														</div>
														<div
															*ngIf="isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5">
															<span class="text-danger">Please enter Min 5
																Characters</span>
														</div>
														<div
															*ngIf=" isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125">
															<span class="text-danger">Please Enter Max 125
																Characters</span>
														</div>
													</div>

													<div class="col-md-12">
														<div class="form-group">
															<label>Case Summary </label>
															<textarea class="form-control" id="caseSummary"
																formControlName="caseSummary" rows="4"
																[ngClass]="{ errorBorder: generalInfo.controls.caseSummary.hasError('maxlength')}">
															</textarea>
														</div>
														<div class="text-danger"
															*ngIf="generalInfo.controls.caseSummary.touched &&  generalInfo.controls.caseSummary.hasError('maxlength')">
															Maximum of 1000 characters
														</div>
													</div>

													<div class="col-md-12">
														<div class="form-group">
															<label>Internal Remarks </label>
															<textarea class="form-control" id="internalRemarks1"
																rows="4" formControlName="internalRemarks1"
																[ngClass]="{ errorBorder: generalInfo.controls.internalRemarks1.hasError('maxlength')}"></textarea>
														</div>
														<div class="text-danger"
															*ngIf="generalInfo.controls.internalRemarks1.touched &&  generalInfo.controls.internalRemarks1.hasError('maxlength')">
															Maximum of 1000 characters
														</div>
													</div>

												</div>
												<div class="col-md-12 mt15 mb-3">
													<button class="btn btn-outline-primary mr-1"
														(click)="submitgeneralInfo()">Next
													</button>
												</div>
											</div>
										</div>
									</fieldset>
								</div>

								<div class="tab-pane fade" id="adjAo-2" role="tabpanel"
									[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="adjAo-tab-vertical">
									<fieldset [disabled]="action == 'view'">
										<div class="row">
											<div class="col-md-12">
												<p class="textCenter">Appealable Order</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>
											<div [formGroup]="commAuthForm" class="row">
												<div class="col-md-3">
													<div class="form-group">
														<label>Order u/s <span class="mandate"><i
																	class="fa fa-asterisk"></i></span></label>
														<input type="text" formControlName="orderUs"
															class="form-control" id="orderUs"
															[ngClass]="{ errorBorder:(isAppealValid && !commAuthForm.controls.orderUs.value) || (isSubmitted && !commAuthForm.controls.orderUs.value) }">
													</div>
													<div
														*ngIf="(isAppealValid && !commAuthForm.controls.orderUs.value) || (isSubmitted && !commAuthForm.controls.orderUs.value)">
														<span class="text-danger">Order u/s Required</span>
													</div>
												</div>

												<div class="col-md-3">
													<div class="form-group">
														<label>Order Number/ DIN<span class="mandate"><i
																	class="fa fa-asterisk"></i></span> </label>
														<input type="text" class="form-control" placeholder=""
															id="orderNo1" formControlName="orderNo1"
															[ngClass]="{ errorBorder:(isAppealValid && !commAuthForm.controls.orderNo1.value) || (isSubmitted && !commAuthForm.controls.orderNo1.value) }">
													</div>
													<div
														*ngIf="(isAppealValid && !commAuthForm.controls.orderNo1.value) || (isSubmitted && !commAuthForm.controls.orderNo1.value)">
														<span class="text-danger">Order Number/ DIN Required</span>
													</div>
												</div>

												<div class="col-md-3">
													<div class="form-group">
														<label>Date of Order<span class="mandate"><i
																	class="fa fa-asterisk"></i></span> </label>
														<div id="orderDate1" class="input-group date datepicker">
															<p-calendar class="form-control"
																formControlName="orderDate1" [monthNavigator]="true"
																[yearNavigator]="true" [maxDate]="maxDate"
																yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy"
																[showIcon]="true" dataType="string"
																[ngClass]="{ errorBorder:(isAppealValid && !commAuthForm.controls.orderDate1.value) || (isSubmitted && !commAuthForm.controls.orderDate1.value) }">
															</p-calendar>
														</div>
													</div>
													<div
														*ngIf="(isAppealValid && !commAuthForm.controls.orderDate1.value) || (isSubmitted && !commAuthForm.controls.orderDate1.value)">
														<span class="text-danger">Date of Order Required</span>
													</div>
												</div>

												<div class="col-md-3">
													<div class="form-group">
														<label>Date of Receipt of Order<span class="mandate"><i
																	class="fa fa-asterisk"></i></span></label>
														<div id="orderReceiptDate1" class="input-group date datepicker">
															<p-calendar class="form-control"
																formControlName="orderReceiptDate1"
																[monthNavigator]="true" [yearNavigator]="true"
																yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy"
																[showIcon]="true" dataType="string" [maxDate]="maxDate"
																[ngClass]="{ errorBorder:(isAppealValid && !commAuthForm.controls.orderReceiptDate1.value) || (isSubmitted && !commAuthForm.controls.orderReceiptDate1.value) }">
															</p-calendar>
														</div>
													</div>
													<div
														*ngIf="(isAppealValid && !commAuthForm.controls.orderReceiptDate1.value) || (isSubmitted && !commAuthForm.controls.orderReceiptDate1.value)">
														<span class="text-danger">Date of Receipt of Order
															Required</span>
													</div>
												</div>

												<div class="col-md-3">
													<div class="form-group">
														<label>Mode of Receipt of Order </label>
														<select class="form-control form-select" id="orderReceiptMode1"
															formControlName="orderReceiptMode1">
															<option [value]="null"> -- Select --</option>
															<ng-container *ngFor=" let obj of orderReceiptMode">
																<option [value]="obj.value"> {{ obj.value}} </option>
															</ng-container>
														</select>
													</div>
												</div>

												<div class="col-md-3">
													<div class="form-group">
														<label>Order Passed By</label>
														<select class="form-control form-select" id="orderPassedBy1"
															formControlName="orderPassedBy1">
															<option [value]="null"> -- Select --</option>
															<ng-container *ngFor=" let obj of orderPassedBy">
																<option [value]="obj.value"> {{ obj.value}} </option>
															</ng-container>
														</select>
													</div>
												</div>

												<div class="col-md-3">
													<div class="form-group">
														<label>Due Date of Appeal<span class="mandate"><i
																	class="fa fa-asterisk"></i></span></label>
														<div id="iris-gst-filter-frmDate"
															class="input-group date datepicker">
															<p-calendar class="form-control"
																formControlName="appealDueDate" [monthNavigator]="true"
																[ngClass]="{'errorBorder':(isAppealValid && !commAuthForm.controls.appealDueDate.value) ||  (isSubmitted && !commAuthForm.controls.appealDueDate.value) }"
																[yearNavigator]="true" yearRange="2010:{{currentYear}}"
																dateFormat="dd-mm-yy" [showIcon]="true"
																dataType="string"></p-calendar>
														</div>
													</div>
													<div
														*ngIf="(isAppealValid && !commAuthForm.controls.appealDueDate.value) || (isSubmitted && !commAuthForm.controls.appealDueDate.value)">
														<span class="text-danger">Due Date of Appeal Required</span>
													</div>
												</div>

												<div class="col-md-3">
													<div class="form-group">
														<div class="form-group-Extension">
															<label>Internal Due Date</label>
															<div id="iris-gst-filter-frmDate"
																class="input-group date datepicker">
																<p-calendar class="form-control"
																	formControlName="internalDueDate"
																	[monthNavigator]="true" [yearNavigator]="true"
																	yearRange="2010:{{currentYear}}"
																	dateFormat="dd-mm-yy" [showIcon]="true"
																	dataType="string">
																</p-calendar>
															</div>
														</div>
													</div>
												</div>


												<div class="col-md-3">
													<div class="form-group">
														<label>Order Copy<span class="mandate"><i
																	class="fa fa-asterisk"></i></span></label>
														<input type="file" class="file-upload-default" #file>
														<div class="attachment-block"
															[ngClass]="{'errorBorder':(isAppealValid && getFormControlOrderLength() === 0)}">
															<span (click)="openNoticeLocPop(noticeCopyFormPop)"> <i
																	class="icon fa fa fa-paperclip" id="paperclip"
																	title="Attach file"></i></span><span
																*ngIf="getFormControlOrderLength() != 0">
																{{getFormControlOrderLength()}}
																Attachment</span>
														</div>

														<div
															*ngIf="(isAppealValid && getFormControlOrderLength() === 0)">
															<span class="text-danger">Order Copy Required</span>
														</div>

														<ng-template #noticeCopyFormPop let-modal>
															<div class="modal-header"
																style="border: none; padding-bottom: 0px;">
																<button type="button" class="close" aria-label="Close"
																	(click)="modal.dismiss('Cross click')"> <span
																		aria-hidden="true">×</span> </button>
															</div>
															<div class="col-md-12">
																<label> Attachment</label>
																<div class="table-responsive">
																	<table class="table table-bordered">
																		<thead>
																			<tr class="bgGred">
																				<th>Upload</th>
																				<th style="width:50%;">Remark (Max 100
																					Character allowed)
																				</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr *ngFor="let orderCopyFromGrp of getFormControlOrder(); let i = index"
																				[formGroup]="orderCopyFromGrp">
																				<td>
																					<div class="row">
																						<div class="col-md-9 nopadding">
																							<div class="form-group">
																								<input
																									*ngIf="orderCopyFromGrp.value.isCopyOfFormLocDocUploadedClicked"
																									type="file"
																									id="orderCopyLoc1({{i}}).fileLoc"
																									class="filetype form-control"
																									name="myfile"
																									(change)="onFileSelect($event,'notice')">
																								<p *ngIf="!orderCopyFromGrp.value.isCopyOfFormLocDocUploadedClicked || orderCopyFromGrp.value.fileLoc"
																									class="form-control pt10"
																									[title]="orderCopyFromGrp.value.fileName ? orderCopyFromGrp.value.fileName : ''">
																									{{getFileNameDta(orderCopyFromGrp.value.fileLoc)}}
																								</p>
																							</div>
																						</div>
																						<div class="col-md-3 nopadding">
																							<label>
																								<a class="downupIcon"
																									*ngIf="orderCopyFromGrp.value.fileLoc"
																									title="Download file"
																									(click)="download(orderCopyFromGrp.value.fileLoc)"><i
																										class="fa fa-download"></i></a>
																								<a class="downupIcon"
																									*ngIf="(!orderCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																									title="Upload file"
																									(click)="uploadOrderCopyFormLoc(i)"><i
																										class="fa fa-upload"></i></a>
																								<a class="downupIcon"
																									(click)="deleteUploadFile(i)"
																									*ngIf="(orderCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																										class="fa fa-trash"
																										aria-hidden="true"></i></a>
																							</label>
																						</div>
																					</div>
																				</td>
																				<td>
																					<input type="text"
																						class="form-control"
																						formControlName="remarks"
																						[title]="orderCopyFromGrp.value.remarks ? orderCopyFromGrp.value.remarks : ''">
																					<div
																						*ngIf="orderCopyFromGrp.value.remarks && orderCopyFromGrp.value.remarks.length > 100">
																						<span class="text-danger">Remark
																							Max character Length
																							100</span>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
															<div class="modal-footer"
																style="border: none; padding-top: 0px;">
																<button type="button" class="btn btn-outline-dark"
																	(click)="modal.close('Save click')">Save</button>
															</div>
														</ng-template>

													</div>
												</div>

												<div class="col-md-12">
													<div class="accordion accordion-solid-header" id="accordion-36"
														role="tablist">
														<div class="card border-bottom">
															<div class="card-header" role="tab" id="heading-36">
																<h6 class="mb-0">
																	<a data-toggle="collapse" href="#collapse-36"
																		aria-expanded="true"
																		aria-controls="collapse-36">
																		Amount Under Appeal
																	</a>
																</h6>
															</div>
															<div id="collapse-36" class="collapse show" role="tabpanel"
																aria-labelledby="heading-2" data-parent="#accordion-36">
																<div class="card-body">
																	<div class="row">
																		<div class="table-responsive">
																			<table class="table table-bordered">
																				<tr class="bgGred">
																					<th class="sl-no-cla" *ngIf="isUpdateBtn">Info</th>
																					<th style="min-width: 288px;">Issues
																						<span class="mandatetd"><i
																								class="fa fa-asterisk"
																								style="font-size: 7px; margin-left:0px;"></i></span>
																					</th>
																					<th>Keyword (Max 150 Character)</th>
																					<th>Risk <span class="mandatetd"><i
																								class="fa fa-asterisk"
																								style="font-size: 7px; margin-left:0px;"></i></span>
																					</th>
																					<th>Tag</th>
																					<th
																						*ngIf="!historyData.includes('PENALTY') ">
																						Addition of Income/ Disallowance
																						of Expenses (₹)</th>
																					<th
																						*ngIf="!historyData.includes('PENALTY') ">
																						Tax Rate (Inclusive of Surcharge
																						and Cess) </th>
																					<th
																						*ngIf="!historyData.includes('PENALTY') ">
																						Tax Impact (Inclusive of
																						Surcharge and Cess) (₹)
																					</th>
																					<th
																						*ngIf="!historyData.includes('PENALTY') ">
																						Interest (₹) </th>
																					<th>Penalty (₹) </th>
																					<th
																						*ngIf="!historyData.includes('PENALTY') ">
																						Total (₹)</th>
																					<th>Remark (Max 100 Character)</th>
																					<th>Add Provision Details </th>
																					<th class="w-80">Action</th>
																				</tr>
																				<tbody>
																					<ng-container
																						*ngIf="commAuthForm.get('issues')">
																						<tr *ngFor="let div of commAuthForm.get('issues')?.controls; let i=index"
																							[formGroup]="div"
																							(keydown.enter)="$event.preventDefault()">
																							<td  class="sl-no-cla" *ngIf="isUpdateBtn">
																								<p (click)="getissueData(issuePop,i)" class="pr5"><span
																								  class="badge-clo" title="Cases with similar issue"><i class="fa fa-info-circle"></i></span>
																								</p>
																							</td>
																							<td class="w250"
																							*ngIf="historyData.includes('PENALTY')">
																								<select
																									class="form-control form-select"
																									formControlName="issue"
																									[ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }"
																									[title]="div.value.issue ? div.value.issue : ''">
																									<optgroup
																										*ngFor='let grp of dataSourceIssue'
																										label="{{grp.group}}"
																										[ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
																										<option
																											*ngFor='let item of grp.items'
																											[value]="item.name"
																											title="{{item.name}}">
																											{{item.name}}
																										</option>
																									</optgroup>
																								</select>
																								<div
																									*ngIf="isSubmitted && div.get('issue')?.errors">
																									<span
																										class="text-danger"
																										*ngIf="div.get('issue')?.errors?.required ">Issues
																										Required
																									</span>
																								</div>
																							</td>
																							<td class="w250"
																							*ngIf="!historyData.includes('PENALTY')">
																								<select
																									class="form-control form-select"
																									formControlName="issue"
																									[ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }"
																									[title]="div.value.issue ? div.value.issue : ''">
																									<optgroup
																										*ngFor='let grp of dataSource'
																										label="{{grp.group}}"
																										[ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
																										<option
																											*ngFor='let item of grp.items'
																											[value]="item.name"
																											title="{{item.name}}">
																											{{item.name}}
																										</option>
																									</optgroup>
																								</select>
																								<div
																									*ngIf="isSubmitted && div.get('issue')?.errors">
																									<span
																										class="text-danger"
																										*ngIf="div.get('issue')?.errors?.required ">Issues
																										Required
																									</span>
																								</div>
																							</td>


																							<td><input type="text"
																									class="form-control"
																									id="keyword"
																									placeholder=""
																									formControlName="keyword"
																									[ngClass]="{ 'errorBorder': (div.controls.keyword.value &&  div.controls.keyword.value.length > 150)  }"
																									[title]="div.value.keyword ? div.value.keyword : ''">
																								<div
																									*ngIf="(div.controls.keyword.value &&  div.controls.keyword.value.length > 150)">
																									<span
																										class="text-danger"
																										*ngIf="(div.controls.keyword.value &&  div.controls.keyword.value.length > 150)">Max
																										Length 150
																										character
																									</span>
																								</div>
																							</td>

																							<td>
																								<select
																									class="form-control form-select"
																									id="riskParam"
																									formControlName="riskParam"
																									[title]="div.value.riskParam ? div.value.riskParam:''"
																									[ngClass]="{ 'errorBorder': isSubmitted && div.get('riskParam')?.errors}">
																									<!-- <option [value]="null">-- Select --</option> -->
																									<ng-container
																										*ngFor="let obj of riskParam">
																										<option
																											[value]="obj.value">
																											{{obj.value}}
																										</option>
																									</ng-container>
																								</select>
																								<div
																									*ngIf="isSubmitted && div.get('riskParam')?.errors">
																									<span
																										class="text-danger"
																										*ngIf="div.get('riskParam')?.errors?.required ">Risk
																										Required</span>
																								</div>
																							</td>
																							<td>
																								<div class="input-group">
																								<input type="text" class="form-control"
																								  *ngIf="isToggleSegmentDropdown && action==='update' || action==='view'" placeholder=""
																								  formControlName="tag" readonly>
																								<button *ngIf="isToggleSegmentDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleSegmentDropdown('p1')">
																								  <i class="fa fa-pencil"></i>
																								</button> 
																							  </div>
																								<select class="form-control form-select" id=""
																								*ngIf="!isToggleSegmentDropdown || action==='create'" formControlName="tag">
																								<option [value]="null">-- Select --</option>
																								<ng-container *ngFor="let obj of tagDataList">
																								  <option [value]="obj">{{obj}}</option>
																								</ng-container>
																							  </select>
																							  </td>

																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control align-right "
																									placeholder=""
																									id="addnOrDisOfExpensesF"
																									formControlName="addnOrDisOfExpensesF"
																									maxlength="19"
																									InrFormat
																									(input)="issueValueChange()"
																									maxlength="19"
																									(paste)="handlePaste($event)"
																									oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
																							</td>

																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<div
																									class="input-container">
																									<input type="text"
																										class="form-control input-field align-right"
																										placeholder=""
																										[ngClass]="{errorBorder : div.get('taxRateF')?.errors}"
																										formControlName="taxRateF"
																										id="taxRateF"
																										maxlength="19"
																										(blur)="handleDecimalPaste(i)"
																										(input)="issueValueChange();">
																									<i class="icon"
																										style="position: absolute; transform: translateY(-50%);">%</i>
																								</div>
																								<div
																									*ngIf="div.get('taxRateF')?.errors">
																									<span
																										class="text-danger"
																										*ngIf="div.get('taxRateF')?.errors?.pattern">
																										Please Enter 0
																										to 100</span>
																								</div>
																							</td>
																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control align-right "
																									placeholder=""
																									formControlName="taxImpactF"
																									maxlength="19"
																									InrFormat
																									(input)="issueValueChange()"
																									(paste)="handlePaste($event)"
																									oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
																							</td>
																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control align-right "
																									placeholder=""
																									formControlName="interestF"
																									maxlength="19"
																									InrFormat
																									[title]="div.value.interestF ? div.value.interestF : ''"
																									(input)="issueValueChange()"
																									(paste)="handlePaste($event)"
																									oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
																							</td>
																							<td> <input type="text"
																									class="form-control align-right "
																									placeholder=""
																									formControlName="penaltyF"
																									maxlengissueValueChangeth="19"
																									InrFormat
																									maxlength="19"
																									(input)="issueValueChange()"
																									(paste)="handlePaste($event)"
																									oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
																							</td>
																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control align-right "
																									placeholder=""
																									readonly
																									formControlName="totalF"
																									maxlength="19"
																									InrFormat
																									(input)="issueValueChange()">
																							</td>
																							<td><input type="text"
																									class="form-control"
																									placeholder=""
																									formControlName="remarkF"
																									[ngClass]="{errorBorder : div.get('remarkF')?.value && div.get('remarkF')?.value.length > 100}">
																								<div
																									*ngIf="div.get('remarkF')?.value && div.get('remarkF')?.value.length > 100">
																									<span
																										class="text-danger"
																										*ngIf="div.get('remarkF')?.value && div.get('remarkF')?.value.length > 100">Max
																										Length is 100
																										character
																									</span>
																								</div>
																							</td>
																							<td><span
																									(click)="openDetailPop(detailPop, i)"><i
																										class="icon  fa fa-file-text-o"></i></span>
																							</td>
																							<td class="w-80">
																								<a class=" addExtLetter"
																									*ngIf="(commAuthForm.get('issues').controls.length - 1) == i"
																									(click)="addIssueInv()"><i
																										class="fa fa-plus"
																										title="Add row"></i></a>
																								<a class="removeDiv"
																									data-toggle="modal"
																									data-target="#removeIssuePopup"
																									*ngIf="i !=0"
																									(click)="openRmvIssuePop(removeIssuePopup, i)"
																									style="margin-left: 15%;"><i
																										class="fa fa-minus"></i></a>
																							</td>
																							<ng-template
																								#removeIssuePopup
																								let-modal>
																								<div
																									class="modal-header">
																									<h4 class="modal-title"
																										id="modal-basic-title">
																										Remove Warning!
																									</h4>
																									<button
																										type="button"
																										class="close"
																										aria-label="Close"
																										(click)="modal.dismiss('Cross click')">
																										<span
																											aria-hidden="true">×</span>
																									</button>
																								</div>
																								<div class="modal-body">
																									<p>Are you sure, you
																										want to remove?
																									</p>
																								</div>
																								<div
																									class="modal-footer">
																									<button
																										type="button"
																										class="btn btn-outline-dark"
																										(click)="rmIssueInv(i)">Remove</button>
																								</div>
																							</ng-template>
																							<ng-template #detailPop
																								let-modal>
																								<div
																									class="modal-header">
																									<h4 class="modal-title"
																										id="modal-basic-title">
																										Details</h4>
																									<button
																										type="button"
																										class="close"
																										aria-label="Close"
																										(click)="modal.dismiss('Cross click')">
																										<span
																											aria-hidden="true">×</span>
																									</button>
																								</div>
																								<div class="modal-body">
																									<p><a class="pl5 addExtLetter"
																											title="Add More Detail"
																											(click)="addDetail(i)"><i
																												class="fa fa-plus"></i>
																											Add another
																											row to
																											update the
																											next
																											quarter
																											provision
																											entry</a>
																									</p>
																									<div
																										class="table-responsive autoScroll">
																										<table
																											class="table table-bordered">
																											<thead>
																												<tr
																													class="bg-primary text-dblue">
																													<th>Financial
																														Year
																													</th>
																													<th>Quarter
																													</th>
																													<th>Provision
																														(₹)
																													</th>
																													<th>Contingent
																														Liability
																														(₹)
																													</th>
																													<th>Other
																														Liability
																														(₹)
																													</th>
																													<th>Reason
																														for
																														change
																														in
																														the
																														Provision
																													</th>
																													<th>Reason
																														for
																														provision
																														not
																														made,
																														fully
																														or
																														partly
																														made
																													</th>
																													<th>Reason
																														for
																														Cont.
																														Liab.
																													</th>
																													<th>Reason
																														for
																														disclosure
																														in
																														Other
																														Liability
																													</th>
																													<th>Consider
																														in
																														CARO
																														Report
																													</th>
																													<th>Amount
																														of
																														CARO
																														(₹)
																													</th>
																													<th>Remove
																													</th>
																												</tr>
																											</thead>
																											<tbody>
																												<ng-container>
																													<tr *ngFor="let detailsGrp of getdetails(div); let k=index"
																														[formGroup]="detailsGrp"
																														(keydown.enter)="$event.preventDefault()">
																														<td>
																															<select
																																class="form-control form-select"
																																id="finYear"
																																formControlName="finYear">
																																<option
																																	[value]="null">
																																	--
																																	Select
																																	--
																																</option>
																																<ng-container
																																	*ngFor="let obj of finyearData">
																																	<option
																																		[value]="obj">
																																		{{obj}}
																																	</option>
																																</ng-container>
																															</select>
																														</td>

																														<td>
																															<select
																																class="form-control form-select"
																																id="quarter"
																																formControlName="quarter">
																																<option
																																	[value]="null">
																																	--Select--
																																</option>
																																<ng-container
																																	*ngFor="let obj of quarterList">
																																	<option
																																		[value]="obj.value">
																																		{{obj.value}}
																																	</option>
																																</ng-container>
																															</select>
																														</td>
																														<td><input
																																type="text"
																																class="form-control align-right"
																																formControlName="provAmtF"
																																id="tax"
																																placeholder=""
																																maxlength="19"
																																InrFormat>
																														</td>
																														<td><input
																																type="text"
																																class="form-control align-right"
																																formControlName="contLibAmtF"
																																id="tax"
																																placeholder=""
																																maxlength="19"
																																InrFormat>
																														</td>
																														<td><input
																																type="text"
																																class="form-control align-right"
																																formControlName="othLibF"
																																id="tax"
																																placeholder=""
																																maxlength="19"
																																InrFormat>
																														</td>
																														<td><input
																																type="text"
																																class="form-control"
																																id="tax"
																																placeholder=""
																																data-bs-toggle="tooltip"
																																data-bs-placement="top"
																																[title]="getdetails(div)[k].controls.provChangeReasonF.value ? getdetails(div)[k].controls.provChangeReasonF.value : ''"
																																formControlName="provChangeReasonF"
																																(click)="openTextBoxModal('Reason for change in the Provision', 'provChangeReasonF',div,k)">
																															<div
																																*ngIf="getdetails(div)[k].controls.provChangeReasonF.value && getdetails(div)[k].controls.provChangeReasonF.value.length > 150">
																																<span
																																	class="text-danger"
																																	*ngIf="getdetails(div)[k].controls.provChangeReasonF.value && getdetails(div)[k].controls.provChangeReasonF.value.length > 150">
																																	Max
																																	Length
																																	is
																																	150
																																	character</span>
																															</div>
																														</td>
																														<td><input
																																type="text"
																																class="form-control"
																																id="tax"
																																placeholder=""
																																data-bs-toggle="tooltip"
																																data-bs-placement="top"
																																[title]="getdetails(div)[k].controls.provNotMadeReasonF.value ? getdetails(div)[k].controls.provNotMadeReasonF.value : ''"
																																formControlName="provNotMadeReasonF"
																																(click)="openTextBoxModal('Reason for provision not made, fully or partly made', 'provNotMadeReasonF',div,k)">
																															<div
																																*ngIf="getdetails(div)[k].controls.provNotMadeReasonF.value && getdetails(div)[k].controls.provNotMadeReasonF.value.length > 150">
																																<span
																																	class="text-danger"
																																	*ngIf="getdetails(div)[k].controls.provNotMadeReasonF.value && getdetails(div)[k].controls.provNotMadeReasonF.value.length > 150">
																																	Max
																																	Length
																																	is
																																	150
																																	character</span>
																															</div>
																														</td>
																														<td><input
																																type="text"
																																class="form-control"
																																id="tax"
																																placeholder=""
																																data-bs-toggle="tooltip"
																																data-bs-placement="top"
																																maxlength="150"
																																[title]="getdetails(div)[k].controls.contLibReasonF.value ? getdetails(div)[k].controls.contLibReasonF.value : ''"
																																formControlName="contLibReasonF"
																																(click)="openTextBoxModal('Reason for Cont. Liab.', 'contLibReasonF',div,k)">
																															<div
																																*ngIf="getdetails(div)[k].controls.contLibReasonF.value && getdetails(div)[k].controls.contLibReasonF.value.length > 150">
																																<span
																																	class="text-danger"
																																	*ngIf="getdetails(div)[k].controls.contLibReasonF.value && getdetails(div)[k].controls.contLibReasonF.value.length > 150">
																																	Max
																																	Length
																																	is
																																	150
																																	character</span>
																															</div>
																														</td>
																														<td><input
																																type="text"
																																class="form-control"
																																id="tax"
																																placeholder=""
																																data-bs-toggle="tooltip"
																																data-bs-placement="top"
																																[title]="getdetails(div)[k].controls.othLibReasonF.value ? getdetails(div)[k].controls.othLibReasonF.value : ''"
																																formControlName="othLibReasonF"
																																(click)="openTextBoxModal('Reason for disclosure in Other Liability', 'othLibReasonF',div,k)">
																															<div
																																*ngIf="getdetails(div)[k].controls.othLibReasonF.value && getdetails(div)[k].controls.othLibReasonF.value.length > 150">
																																<span
																																	class="text-danger"
																																	*ngIf="getdetails(div)[k].controls.othLibReasonF.value && getdetails(div)[k].controls.othLibReasonF.value.length > 150">
																																	Max
																																	Length
																																	is
																																	150
																																	character</span>
																															</div>
																														</td>
																														<td>
																															<select
																																class="form-control form-select"
																																id="isConsInCaroF"
																																formControlName="isConsInCaroF">
																																<option
																																	[value]="null">
																																	--
																																	Select
																																	--
																																</option>
																																<ng-container
																																	*ngFor="let obj of yesNoList">
																																	<option
																																		[value]="obj.value">
																																		{{obj.label}}
																																	</option>
																																</ng-container>
																															</select>
																														</td>
																														<td>
																															<input
																																type="text"
																																class="form-control align-right"
																																formControlName="caroAmtF"
																																id="tax"
																																placeholder=""
																																maxlength="19"
																																InrFormat>
																															<div class="error-msg"
																																*ngIf="detailsGrp.value.isConsInCaroF =='true' && ((detailsGrp.value.caroAmtF === null) || (detailsGrp.value.caroAmtF == 0))">
																																If
																																"Consider
																																in
																																CARO
																																Report"
																																is
																																Yes
																																then
																																Amount
																																of
																																Caro
																																column
																																cannot
																																be
																																zero.
																															</div>
																															<div class="error-msg"
																																*ngIf="(detailsGrp.value.isConsInCaroF == 'false') && (detailsGrp.value.caroAmtF && (detailsGrp.value.caroAmtF != 0))">
																																If
																																"Consider
																																in
																																CARO
																																Report"
																																is
																																No
																																then
																																Amount
																																of
																																Caro
																																should
																																be
																																zero.
																															</div>
																														</td>
																														<td><a class="addExtLetter"
																																(click)="openRmvDetailPop(removeDetailPopup, i, k)"><i
																																	class="fa fa-minus"></i></a>
																														</td>
																														<ng-template
																															#removeDetailPopup
																															let-modal>
																															<div
																																class="modal-header">
																																<h4 class="modal-title"
																																	id="modal-basic-title">
																																	Remove
																																	Warning!
																																</h4>
																																<button
																																	type="button"
																																	class="close"
																																	aria-label="Close"
																																	(click)="modal.dismiss('Cross click')">
																																	<span
																																		aria-hidden="true">×</span>
																																</button>
																															</div>
																															<div
																																class="modal-body">
																																<p>Are
																																	you
																																	sure,
																																	you
																																	want
																																	to
																																	remove?
																																</p>
																															</div>
																															<div
																																class="modal-footer">
																																<button
																																	type="button"
																																	class="btn btn-outline-dark"
																																	(click)="rmDetail(i, k)">Remove</button>
																															</div>
																														</ng-template>
																													</tr>
																												</ng-container>
																											</tbody>
																										</table>
																									</div>
																								</div>
																								<div
																									class="modal-footer">
																									<button
																										type="button"
																										class="btn btn-outline-dark"
																										(click)="modal.close('Save click')">Save</button>
																								</div>
																							</ng-template>
																							<ng-template #issuePop let-modal>
																								<div class="modal-header">
																								  <button type="button" class="close" aria-label="Close"
																									(click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
																								  </button>
																								</div>
																								<div class="col-md-12" >
																								  <div class="table-responsive">
																									<p class="title-data">For your reference following latest 15 cases are exist in LMS with selected issue: <span style="font-weight: bold;">{{div.value.issue}}</span></p>
																									<div class="btn btn-outline-success btn-download btnRep" (click)="exportAsXLSX()" >
																									  <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
																									</div>
																									<table *ngIf="this.hideData">
																									  <thead >
																										<tr class="bgGred1">
																										  <th class="sl-no-cla">Sr No</th>
																										  <th><span>PAN</span>
																											<i class="fa fa-arrow-up cursor-pointer" 
																												aria-hidden="true" *ngIf="!sorting.panName"
																												(click)="SortingIssue(issueSorting.PAN)"></i>
																											<i class="fa fa-arrow-down cursor-pointer"
																												aria-hidden="true" *ngIf="sorting.panName"
																												(click)="SortingIssue(issueSorting.PAN)"></i>
																										  </th>
																										  <th><span>Case ID</span>
																											<i class="fa fa-arrow-up cursor-pointer"
																												aria-hidden="true" *ngIf="!sorting.caseId"
																												(click)="SortingIssue(issueSorting.CASE_ID)"></i>
																											<i class="fa fa-arrow-down cursor-pointer"
																												aria-hidden="true" *ngIf="sorting.caseId"
																												(click)="SortingIssue(issueSorting.CASE_ID)"></i>
																										</th>
																										  <th class="clas"><span>Case Label</span>
																											<i class="fa fa-arrow-up cursor-pointer"
																												aria-hidden="true" *ngIf="!sorting.caseLabel"
																												(click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
																											<i class="fa fa-arrow-down cursor-pointer"
																												aria-hidden="true" *ngIf="sorting.caseLabel"
																												(click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
																										  </th>
																										  <th ><span>Assessment Year</span>
																											<i class="fa fa-arrow-up cursor-pointer"
																											   aria-hidden="true"
																											   *ngIf="!sorting.ay"
																											   (click)="SortingIssue(issueSorting.AY)"></i>
																											<i class="fa fa-arrow-down cursor-pointer"
																											   aria-hidden="true"
																											   *ngIf="sorting.ay"
																											   (click)="SortingIssue(issueSorting.AY)"></i>
																										 </th>
																										  <th><span>Person Responsible</span>
																											<i class="fa fa-arrow-up cursor-pointer"
																												aria-hidden="true"
																												*ngIf="!sorting.personResponsible"
																												(click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
																											<i class="fa fa-arrow-down cursor-pointer"
																												aria-hidden="true"
																												*ngIf="sorting.personResponsible"
																												(click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
																										</th>
																										</tr>
																									  </thead>
																									  <tbody>
																										<tr
																										  *ngFor="let obj of issueRefData  let i = index">
																										  <td class="sl-no-cla">{{i + 1}}</td>
																										  <td >{{obj.panName}}</td>
																										  <td >{{obj.caseId}}</td>
																										  <td class="clas">{{obj.caseLabel}}</td>
																										  <td >{{obj.ay}}</td>
																										  <td>{{obj.personResponsible}}</td>
																										</tr>
																									  </tbody>
																									</table>
																									<div *ngIf="!this.hideData" style="font-size: large;text-align: center;font-weight: bold;">
																									  No data found.
																									</div>
																									
																								  </div>
																								</div>
																							 </ng-template>
																						</tr>
																					</ng-container>
																					<ng-container>
																						<tr>
																							<th *ngIf="isUpdateBtn" colspan="5"
																							class="total-titel">
																							Total</th>
																							<th *ngIf="!isUpdateBtn" colspan="4"
																							class="total-titel">
																							Total</th>
																						
																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control bold-font align-right"
																									formControlName="totOfAddnOrDisOfExpensesF"
																									id="tax"
																									placeholder=""
																									InrFormat
																									maxlength="19"
																									readonly>
																							</td>
																							<td *ngIf="!historyData.includes('PENALTY') "
																								colspan="1">&nbsp;</td>
																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control bold-font align-right"
																									formControlName="taxImpactFTotal"
																									id="tax"
																									placeholder=""
																									InrFormat
																									maxlength="19"
																									readonly>
																							</td>
																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control bold-font align-right"
																									formControlName="interestFTotal"
																									id="tax"
																									placeholder=""
																									InrFormat
																									maxlength="19"
																									readonly
																									*ngIf="!historyData.includes('PENALTY') ">
																							</td>
																							<td><input type="text"
																									class="form-control bold-font align-right"
																									formControlName="penaltyFTotal"
																									id="tax"
																									placeholder=""
																									InrFormat
																									maxlength="19"
																									readonly></td>
																							<td
																								*ngIf="!historyData.includes('PENALTY') ">
																								<input type="text"
																									class="form-control bold-font align-right"
																									formControlName="totOfTotal"
																									id="tax"
																									placeholder=""
																									InrFormat
																									maxlength="19"
																									readonly>
																							</td>
																							<td colspan="9"
																								*ngIf="!historyData.includes('PENALTY') ">
																								&nbsp;</td>
																							<td colspan="7"
																								*ngIf="caseType =='PENALTY'">
																								&nbsp;</td>
																						</tr>
																					</ng-container>
																				</tbody>
																			</table>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="col-md-12 mt15 mb-3">
													<button class="btn btn-outline-primary mr-1"
														(click)="submitOrderForm()">Next</button>
												</div>
											</div>
										</div>
									</fieldset>
								</div>

								<div class="tab-pane fade" id="adjitr-2" role="tabpanel"
									[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="adjitr-tab-vertical">
									<fieldset [disabled]="action == 'view'">
										<div class="row">
											<div class="col-md-12">
												<p class="textCenter">ITR Details</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>
											<div [formGroup]="iTRDetailsForm">
												<div class="col-md-12">
													<div class="row">
														<div class="col-md-12 table-responsive">
															<table class="table table-bordered">
																<tr class="bg-primary text-dblue">
																	<th style="min-width:315px;">Return Filed U/s</th>
																	<th>ITR Form Type</th>
																	<th>Ack Number</th>
																	<th>Date of Filing</th>
																	<th>Total Taxable Income (₹) </th>
																	<th>Total Tax Liability (₹)</th>
																	<th>Advance Tax(₹)</th>
																	<th>TDS (₹)</th>
																	<th>TCS (₹)</th>
																	<th>Self Assessment Tax (₹)</th>
																	<th>Total Tax Paid (₹)</th>
																	<th>(Refund)/ Demand (₹)</th>
																	<th class="w-80">Action</th>
																</tr>
																<ng-container formArrayName="itrDetails">
																	<ng-container
																		*ngIf="iTRDetailsForm.get('itrDetails')">
																		<tr *ngFor="let itrDetailsGrp of iTRDetailsForm.get('itrDetails')?.controls; let i=index"
																			[formGroup]="itrDetailsGrp">
																			<td>
																				<select class="form-control form-select"
																					id="status"
																					formControlName="returnFiledUs"
																					[title]="itrDetailsGrp.value.returnFiledUs ? itrDetailsGrp.value.returnFiledUs : ''">
																					<option [value]="null">-- Select --
																					</option>
																					<ng-container
																						*ngFor="let obj of returnFiledUs">
																						<option [value]="obj.value">
																							{{obj.value}}</option>
																					</ng-container>
																				</select>
																			</td>

																			<td>
																				<select class="form-control form-select"
																					id="status"
																					formControlName="itrForm"
																					[title]="itrDetailsGrp.value.itrForm ? itrDetailsGrp.value.itrForm : ''">
																					<option [value]="null">-- Select --
																					</option>
																					<ng-container
																						*ngFor="let obj of itrFormList">
																						<option [value]="obj.value">
																							{{obj.value}}</option>
																					</ng-container>
																				</select>
																			</td>

																			<td><input type="text" class="form-control"
																					placeholder=""
																					formControlName="ackNo"
																					maxlength="50"
																					[title]="itrDetailsGrp.value.ackNo ? itrDetailsGrp.value.ackNo : ''">
																			</td>
																			<td>
																				<div id="iris-gst-filter-frmDate"
																					class="input-group date datepicker">
																					<p-calendar appendTo="body"
																						class="form-control"
																						formControlName="filingDate"
																						name="filingDate"
																						[title]="itrDetailsGrp.value.filingDate ? itrDetailsGrp.value.filingDate : ''"
																						[monthNavigator]="true"
																						[yearNavigator]="true"
																						yearRange="2010:{{currentYear}}"
																						dateFormat="dd-mm-yy"
																						[showIcon]="true"
																						dataType="string"
																						[maxDate]="maxDate">
																					</p-calendar>
																				</div>
																			</td>
																			<td><input type="text"
																					class="form-control align-right "
																					placeholder="" InrFormat
																					formControlName="totTaxIncome"
																					[title]="itrDetailsGrp.value.totTaxIncome ? itrDetailsGrp.value.totTaxIncome : ''">
																			</td>
																			<td><input type="text"
																					class="form-control align-right "
																					placeholder="" InrFormat
																					formControlName="totTaxLiability"
																					maxlength="19"
																					(input)="itrDetailsPaidChanged()"
																					(paste)="handlePaste($event)"
																					[title]="itrDetailsGrp.value.totTaxLiability ? itrDetailsGrp.value.totTaxLiability : ''">
																			</td>
																			<td><input type="text" class="form-control align-right "
																				placeholder="" InrFormat formControlName="advanceTax"
																				(input)="itrDetailsPaidChanged()" maxlength="19"
																				(paste)="handlePaste($event)"
																				[title]="itrDetailsGrp.value.advanceTax ? itrDetailsGrp.value.advanceTax :''">
																			 </td>
																			 <td><input type="text" class="form-control align-right "
																				placeholder="" InrFormat formControlName="tdsAmount"
																				(input)="itrDetailsPaidChanged()" maxlength="19"
																				(paste)="handlePaste($event)"
																				[title]="itrDetailsGrp.value.tdsAmount ? itrDetailsGrp.value.tdsAmount :''">
																			 </td>
																			 <td><input type="text" class="form-control align-right "
																				placeholder="" InrFormat formControlName="tcsAmount"
																				(input)="itrDetailsPaidChanged()" maxlength="19"
																				(paste)="handlePaste($event)"
																				[title]="itrDetailsGrp.value.tcsAmount ? itrDetailsGrp.value.tcsAmount :''">
																			 </td>
																			 <td><input type="text" class="form-control align-right "
																				placeholder="" InrFormat formControlName="selfAssessmentAmount"
																				(input)="itrDetailsPaidChanged()" maxlength="19"
																				(paste)="handlePaste($event)"
																				[title]="itrDetailsGrp.value.selfAssessmentAmount ? itrDetailsGrp.value.selfAssessmentAmount :''">
																			 </td>

																			<td><input type="text"
																					class="form-control align-right "
																					placeholder="" InrFormat
																					formControlName="totTaxPaid"
																					maxlength="19"
																					(input)="itrDetailsPaidChanged()"
																					(paste)="handlePaste($event)"
																					[title]="itrDetailsGrp.value.totTaxPaid ? itrDetailsGrp.value.totTaxPaid : ''">
																			</td>
																			<td><input type="text"
																					class="form-control align-right "
																					placeholder="" InrFormat
																					formControlName="refundDemand"
																					maxlength="19"
																					(input)="itrDetailsPaidChanged()"
																					(paste)="handlePaste($event)"
																					[title]="itrDetailsGrp.value.refundDemand ? itrDetailsGrp.value.refundDemand : ''">
																			</td>
																			<td class="w-80">
																				<a class="addExtLetter"
																					*ngIf="(iTRDetailsForm.get('itrDetails').controls.length - 1) == i"
																					(click)="addItrDetails() "><i
																						class="fa fa-plus"></i></a>
																				<a class="addExtLetter" *ngIf="i != 0"
																					(click)="openItrDetailsPop(rmItrDetailsPop,i)"><i
																						class="fa fa-minus"></i></a>
																			</td>
																			<ng-template #rmItrDetailsPop let-modal>
																				<div class="modal-header">
																					<h4 class="modal-title"
																						id="modal-basic-title">Remove
																						Warning! </h4>
																					<button type="button" class="close"
																						aria-label="Close"
																						(click)="modal.dismiss('Cross click')">
																						<span
																							aria-hidden="true">×</span>
																					</button>
																				</div>
																				<div class="modal-body">
																					<p>Are you sure, you want to remove?
																					</p>
																				</div>
																				<div class="modal-footer">
																					<button type="button"
																						class="btn btn-outline-dark"
																						(click)="rmItrDetailsInv(i)">Remove</button>
																				</div>
																			</ng-template>
																		</tr>
																	</ng-container>
																</ng-container>
															</table>
														</div>
													</div>
												</div>
												<div class="col-md-12 mt15 mb-3">
													<button class="btn btn-outline-primary mr-1" type="submit"
														(click)="submitiTRDetailsForm()">Next</button>
												</div>
											</div>
										</div>
									</fieldset>
								</div>
								<div class="tab-pane fade" id="adjissuea-2" role="tabpanel"
									[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="adjissuea-tab-vertical">
									<fieldset [disabled]="action == 'view'">
										<div class="row">
											<div class="col-md-12">
												<p class="textCenter">Protest & Stay Details</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>
											<div [formGroup]="preDepositForm">
												<div class="col-md-12">
													<div class="accordion accordion-solid-header" id="accordion-63"
														role="tablist">
														<div class="card border-bottom">
															<div class="card-header" role="tab" id="heading-63">
																<h6 class="mb-0">
																	<a data-toggle="collapse" href="#collapse-632"
																		aria-expanded="true"
																		aria-controls="collapse-63">
																		Details of Amount Paid Under Protest
																	</a>
																</h6>
															</div>
															<div id="collapse-63" class="collapse show" role="tabpanel"
																aria-labelledby="heading-63"
																data-parent="#accordion-63">
																<div class="card-body">
																	<div class="row">
																		<div class="table-responsive">
																			<table class="table table-bordered">
																				<tr class="bg-primary text-dblue">
																					<th class="w-40">Sr. No.</th>
																					<th>CIN/ Ref. No. </th>
																					<th>Date of Payment</th>
																					<th>BSR Code</th>
																					<th>Tax (₹) </th>
																					<th>Interest (₹) </th>
																					<th>Total (₹)</th>
																					<th>Challan Copy</th>
																					<th class="w-80">Action</th>
																				</tr>
																				<ng-container
																					*ngIf=" preDepositForm.get('predepositDetails')">
																					<tr *ngFor="let predepositGrp of preDepositForm.get('predepositDetails').controls; let i=index"
																						[formGroup]="predepositGrp">
																						<td class="w-40">{{i+1}}</td>
																						<td><input type="text"
																								class="form-control"
																								placeholder=""
																								maxlength="50"
																								formControlName="cinRefNo"
																								[title]="predepositGrp.value.cinRefNo ? predepositGrp.value.cinRefNo : ''">
																						</td>
																						<td>
																							<div id="iris-gst-filter-frmDate"
																								class="input-group date datepicker">
																								<p-calendar
																									appendTo="body"
																									class="form-control"
																									formControlName="date"
																									name="date"
																									[title]="predepositGrp.value.date ? predepositGrp.value.date : ''"
																									[monthNavigator]="true"
																									[yearNavigator]="true"
																									yearRange="2010:{{currentYear}}"
																									dateFormat="dd-mm-yy"
																									[showIcon]="true"
																									dataType="string"
																									[maxDate]="maxDate">
																								</p-calendar>
																							</div>
																						</td>

																						<td><input type="text"
																								class="form-control"
																								placeholder=""
																								formControlName="bsrCode"
																								maxlength="50"
																								[title]="predepositGrp.value.bsrCode ? predepositGrp.value.bsrCode : ''">
																						</td>
																						<td><input type="text"
																								class="form-control align-right"
																								placeholder=""
																								formControlName="tax"
																								[title]="predepositGrp.value.tax ? predepositGrp.value.tax : ''"
																								InrFormat maxlength="19"
																								(input)="predepositChanged()"
																								(paste)="handlePaste($event)">
																						</td>
																						<td><input type="text"
																								class="form-control align-right"
																								placeholder=""
																								formControlName="interest"
																								[title]="predepositGrp.value.interest ? predepositGrp.value.interest : ''"
																								InrFormat maxlength="19"
																								(input)="predepositChanged()"
																								(paste)="handlePaste($event)">
																						</td>
																						<td><input type="text"
																								class="form-control align-right"
																								placeholder=""
																								formControlName="total"
																								[title]="predepositGrp.value.total ? predepositGrp.value.total : ''"
																								InrFormat maxlength="19"
																								(input)="predepositChanged()">
																						<td><span
																								(click)="openLibDetailsGrpPop(ChallanDetailsFormPop, i)"><i
																									class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i></span><span
																								*ngIf="getChallanLength(i) != 0">
																								{{getChallanLength(i)}}
																								Attachment</span></td>
																						<td class="w-80"><a
																								class=" addExtLetter"
																								*ngIf="(preDepositForm.get('predepositDetails').controls.length - 1 ) == i"
																								(click)="addPredepositDetails()"><i
																									class="fa fa-plus"></i></a>
																							<a class="addExtLetter"
																								*ngIf="i !=0"
																								(click)="openPredepositDetailsPop(rmvPredepositDetailsModalRef,i)"><i
																									class="fa fa-minus"></i></a>
																						</td>
																						<ng-template
																							#rmvPredepositDetailsModalRef
																							let-modal>
																							<div class="modal-header">
																								<h4 class="modal-title"
																									id="modal-basic-title">
																									Remove Warning!</h4>
																								<button type="button"
																									class="close"
																									aria-label="Close"
																									(click)="modal.dismiss('Cross click')">
																									<span
																										aria-hidden="true">×</span>
																								</button>
																							</div>
																							<div class="modal-body">
																								<p>Are you sure, you
																									want to remove?</p>
																							</div>
																							<div class="modal-footer">
																								<button type="button"
																									class="btn btn-outline-dark"
																									(click)="rmPredepositDetailsInv(i)">Remove</button>
																							</div>
																						</ng-template>
																						<ng-template
																							#ChallanDetailsFormPop
																							let-modal>
																							<div class="modal-header"
																								style="border: none; padding-bottom: 0px;">
																								<button type="button"
																									class="close"
																									aria-label="Close"
																									(click)="modal.dismiss('Cross click')">
																									<span
																										aria-hidden="true">×</span>
																								</button>
																							</div>
																							<div class="col-md-12">
																								<label>Attachment</label>
																								<div
																									class="table-responsive">
																									<table
																										class="table table-bordered">
																										<thead>
																											<tr
																												class="bgGred">
																												<th> Upload
																												</th>
																												<th
																													style="width:50%;">
																													Remark
																													(Max
																													100
																													Character
																													allowed)
																												</th>
																											</tr>
																										</thead>
																										<tbody>
																											<tr *ngFor="let getChallanFromGrp of getChallan(predepositGrp); let j=index"
																												[formGroup]="getChallanFromGrp">
																												<td>
																													<div
																														class="row">
																														<div
																															class="col-md-9 nopadding">
																															<div
																																class="form-group">
																																<input
																																	*ngIf="getChallanFromGrp.value.isdocLocUploadedClicked"
																																	type="file"
																																	id="predepositDetails({{i}}).challanCopyLoc({{j}}).fileLoc"
																																	class="filetype form-control"
																																	name="myfile"
																																	(change)="onFileSelect($event,'tax')">
																																<p *ngIf="!getChallanFromGrp.value.isdocLocUploadedClicked || getChallanFromGrp.value.fileLoc"
																																	class="form-control pt10">
																																	{{getFileNameDta(getChallanFromGrp.value.fileLoc)}}
																																</p>
																															</div>
																														</div>
																														<div
																															class="col-md-3 nopadding">
																															<label>
																																<a class="downupIcon"
																																	*ngIf="getChallanFromGrp.value.fileLoc"
																																	title="Download file"
																																	(click)="download(getChallanFromGrp.value.fileLoc)"><i
																																		class="fa fa-download"></i></a>
																																<a class="downupIcon"
																																	*ngIf="(!getChallanFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																																	title="Upload file"
																																	(click)="uploadChallan(i,j)"><i
																																		class="fa fa-upload"></i></a>
																																<a class="downupIcon"
																																	(click)="deleteChallanCopyFile(i,j)"
																																	*ngIf="(getChallanFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																		class="fa fa-trash"
																																		aria-hidden="true"></i></a>
																															</label>

																														</div>
																													</div>
																												</td>
																												<td>
																													<input
																														type="text"
																														class="form-control"
																														formControlName="remarks"
																														[title]="getChallanFromGrp.value.remarks ? getChallanFromGrp.value.remarks : ''">
																													<div
																														*ngIf="getChallanFromGrp.value.remarks && getChallanFromGrp.value.remarks.length > 100">
																														<span
																															class="text-danger">Remark
																															Max
																															character
																															Length
																															100</span>
																													</div>
																												</td>
																											</tr>
																										</tbody>
																									</table>
																								</div>
																							</div>
																							<div class="modal-footer"
																								style="border: none; padding-top: 0px;">
																								<button type="button"
																									class="btn btn-outline-dark"
																									(click)="modal.close('Save click')">Save</button>
																							</div>
																						</ng-template>
																					</tr>
																					<tr>
																						<th colspan="4"
																							class="total-titel">Total
																						</th>
																						<td
																							class="align-right bold-font">
																							{{predepositTaxTotal| INR:
																							0}}</td>
																						<td
																							class="align-right bold-font">
																							{{predepositInterestTotal|
																							INR: 0 }}</td>
																						<td
																							class="align-right bold-font">
																							{{predepositTotal |
																							INR: 0}}</td>
																					</tr>
																				</ng-container>
																			</table>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="col-md-12">
													<div class="accordion accordion-solid-header" id="accordion-85"
														role="tablist">
														<div class="card border-bottom">
															<div class="card-header" role="tab" id="heading-85">
																<h6 class="mb-0">
																	<a data-toggle="collapse" href="#collapse-858"
																		aria-expanded="true"
																		aria-controls="collapse-85">
																		Details of Stay Order
																	</a>
																</h6>
															</div>
															<div id="collapse-85" class="collapse show" role="tabpanel"
																aria-labelledby="heading-85"
																data-parent="#accordion-85">
																<div class="card-body">
																	<div class="row">
																		<div class="table-responsive">
																			<table class="table table-bordered">
																				<tr class="bg-primary text-dblue">
																					<th class="w-40">Sr. No.</th>
																					<th>Date of Application</th>
																					<th>Authority with whom application
																						filed</th>
																					<th>Application Status</th>
																					<th>Amount (₹) </th>
																					<th>Valid up to</th>
																					<th>Copy of Order</th>
																					<th class="w-80">Action</th>
																				</tr>
																				<ng-container
																					*ngIf="preDepositForm.get('stayOrder')">
																					<tr *ngFor="let stayOrdertGrp of preDepositForm.get('stayOrder').controls; let i=index"
																						[formGroup]="stayOrdertGrp">
																						<td class="w-40">{{i+1}}</td>
																						<td>
																							<div id="iris-gst-filter-frmDate"
																								class="input-group date datepicker">
																								<p-calendar
																									appendTo="body"
																									class="form-control"
																									formControlName="applicationDate"
																									name="applicationDate"
																									[title]="stayOrdertGrp.value.applicationDate ? stayOrdertGrp.value.applicationDate : ''"
																									[monthNavigator]="true"
																									[yearNavigator]="true"
																									yearRange="2010:{{currentYear}}"
																									dateFormat="dd-mm-yy"
																									[showIcon]="true"
																									dataType="string"
																									[maxDate]="maxDate">
																								</p-calendar>
																							</div>
																						</td>
																						<td>
																							<input type="text"
																								class="form-control"
																								placeholder=""
																								id="authority"
																								formControlName="authority"
																								maxlength="100"
																								[title]="stayOrdertGrp.value.authority ? stayOrdertGrp.value.authority : ''">
																						</td>
																						<td>
																							<select
																								class="form-control form-select"
																								id="applicationStatus"
																								formControlName="applicationStatus"
																								[title]="stayOrdertGrp.value.applicationStatus ? stayOrdertGrp.value.applicationStatus : ''">
																								<option [value]="null">
																									-- Select --
																								</option>
																								<ng-container
																									*ngFor="let obj of applStatusList">
																									<option
																										[value]="obj.value">
																										{{obj.value}}
																									</option>
																								</ng-container>
																							</select>
																						</td>
																						<td>
																							<input type="text"
																								class="form-control align-right"
																								placeholder=""
																								id="amount" InrFormat
																								formControlName="amount"
																								[title]="stayOrdertGrp.value.amount ? stayOrdertGrp.value.amount : ''">
																						</td>

																						<td>
																							<div id="iris-gst-filter-frmDate"
																								class="input-group date datepicker">
																								<p-calendar
																									appendTo="body"
																									class="form-control"
																									formControlName="validUptoDate"
																									name="validUptoDate"
																									[title]="stayOrdertGrp.value.validUptoDate ? stayOrdertGrp.value.validUptoDate : ''"
																									[monthNavigator]="true"
																									[yearNavigator]="true"
																									yearRange="2010:{{currentYear}}"
																									dateFormat="dd-mm-yy"
																									[showIcon]="true"
																									dataType="string">
																								</p-calendar>
																							</div>
																						</td>
																						<td><span
																								(click)="openStayGrpPop(orderCopyLocFormPop, i)"><i
																									class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i></span>
																							<span
																								*ngIf="getOrderCopyLength(i) != 0">
																								{{getOrderCopyLength(i)}}
																								Attachment</span>
																						</td>
																						<td class="w-80"><a
																								class=" addExtLetter"
																								*ngIf="(preDepositForm.get('stayOrder').controls.length - 1) == i"
																								(click)="addStay()"><i
																									class="fa fa-plus"></i></a>
																							<a class="addExtLetter"
																								*ngIf="i !=0"
																								(click)="openStayPop(rmvstayOrderRef,i)"><i
																									class="fa fa-minus"></i></a>
																						</td>
																						<ng-template #rmvstayOrderRef
																							let-modal>
																							<div class="modal-header">
																								<h4 class="modal-title"
																									id="modal-basic-title">
																									Remove Warning!</h4>
																								<button type="button"
																									class="close"
																									aria-label="Close"
																									(click)="modal.dismiss('Cross click')">
																									<span
																										aria-hidden="true">×</span>
																								</button>
																							</div>
																							<div class="modal-body">
																								<p>Are you sure, you
																									want to remove?</p>
																							</div>
																							<div class="modal-footer">
																								<button type="button"
																									class="btn btn-outline-dark"
																									(click)="rmStayInv(i)">Remove</button>
																							</div>
																						</ng-template>
																						<ng-template
																							#orderCopyLocFormPop
																							let-modal>
																							<div class="modal-header"
																								style="border: none; padding-bottom: 0px;">
																								<button type="button"
																									class="close"
																									aria-label="Close"
																									(click)="modal.dismiss('Cross click')">
																									<span
																										aria-hidden="true">×</span>
																								</button>
																							</div>
																							<div class="col-md-12">
																								<label>Attachment</label>
																								<div
																									class="table-responsive">
																									<table
																										class="table table-bordered">
																										<thead>
																											<tr
																												class="bgGred">
																												<th> Upload
																												</th>
																												<th
																													style="width:50%;">
																													Remark
																													(Max
																													100
																													Character
																													allowed)
																												</th>
																											</tr>
																										</thead>
																										<tbody>
																											<tr *ngFor="let OrderCopyFromGrp of getOrderCopy(stayOrdertGrp); let j=index"
																												[formGroup]="OrderCopyFromGrp">
																												<td>
																													<div
																														class="row">
																														<div
																															class="col-md-9 nopadding">
																															<div
																																class="form-group">
																																<input
																																	*ngIf="OrderCopyFromGrp.value.isdocLocUploadedClicked"
																																	type="file"
																																	id="stayOrder({{i}}).copyOfStayLoc({{j}}).fileLoc"
																																	class="filetype form-control"
																																	name="myfile"
																																	(change)="onFileSelect($event,'tax')">
																																<p *ngIf="!OrderCopyFromGrp.value.isdocLocUploadedClicked || OrderCopyFromGrp.value.fileLoc"
																																	class="form-control pt10">
																																	{{getFileNameDta(OrderCopyFromGrp.value.fileLoc)}}
																																</p>
																															</div>
																														</div>
																														<div
																															class="col-md-3 nopadding">
																															<label>
																																<a class="downupIcon"
																																	*ngIf="OrderCopyFromGrp.value.fileLoc"
																																	title="Download file"
																																	(click)="download(OrderCopyFromGrp.value.fileLoc)"><i
																																		class="fa fa-download"></i></a>
																																<a class="downupIcon"
																																	*ngIf="(!OrderCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																																	title="Upload file"
																																	(click)="uploadOrderCopy(i,j)"><i
																																		class="fa fa-upload"></i></a>
																																<a class="downupIcon"
																																	(click)="deleteOrderCopyFile(i,j)"
																																	*ngIf="(OrderCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																		class="fa fa-trash"
																																		aria-hidden="true"></i></a>
																															</label>

																														</div>
																													</div>
																												</td>
																												<td>
																													<input
																														type="text"
																														class="form-control"
																														formControlName="remarks"
																														[title]="OrderCopyFromGrp.value.remarks ? OrderCopyFromGrp.value.remarks : ''">
																													<div
																														*ngIf="OrderCopyFromGrp.value.remarks && OrderCopyFromGrp.value.remarks.length > 100">
																														<span
																															class="text-danger">Remark
																															Max
																															character
																															Length
																															100</span>
																													</div>
																												</td>
																											</tr>
																										</tbody>
																									</table>
																								</div>
																							</div>
																							<div class="modal-footer"
																								style="border: none; padding-top: 0px;">
																								<button type="button"
																									class="btn btn-outline-dark"
																									(click)="modal.close('Save click')">Save</button>
																							</div>
																						</ng-template>
																					</tr>
																				</ng-container>
																			</table>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="col-md-12 mt15"
													*ngIf="caseType !='PENALTY' && !isVisibleCaseId">
													<button class="btn btn-outline-primary mr-1" id="isNext"
														(click)="SaveForm('NEW_CASE')" #nextBtn>Save</button>
												</div>
												<div class="col-md-12 mt15"
													*ngIf="caseType !='PENALTY' && isVisibleCaseId">
													<button class="btn btn-outline-primary mr-1" id="isNext"
														(click)="updatePhase2()" #nextBtn>Update</button>
												</div>
												<div class="col-md-12 mt15 mb-3"
													*ngIf="caseType =='PENALTY' && !isVisibleCaseId">
													<button class="btn btn-outline-primary mr-1 savePhase1" id="isNext"
														(click)="SaveForm('PENALTY')" #nextBtn>Save</button>
												</div>
												<div class="col-md-12 mt15 mb-3"
													*ngIf="caseType =='PENALTY'  && isVisibleCaseId">
													<button class="btn btn-outline-primary mr-1 savePhase1" id="isNext"
														(click)="updatePhase2()" #nextBtn>Update</button>
												</div>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="tab-pane fade" id="AppelAuthp2-1" role="tabpanel" aria-labelledby="AppelAuthp2-tab">
			<div class="card borderOrange">
				<div class="card-body ptl10 plr010">
					<div class="row">
						<div class="col-2" id="verticalTab10" *ngIf="!sidemenu">
							<ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="aasub-tab-vertical" data-toggle="tab" href="#aasub-2"
										role="tab" aria-controls="aasub-2" aria-selected="true">
										Submission
									</a>
								</li>
								<li class="nav-item"
									[ngClass]="{'disabled' : !isHearingVisible || !phase2Form.value.submmissionDtls.subDate || getAckCopyLength() == 0 || form36Length || applGroundLocLength || stmtFactLength || applChallanFeesLength}">
									<!--[ngClass]="{'disabled' : !isHearingVisible}" -->
									<a class="nav-link" id="aahd-tab-vertical" data-toggle="tab" href="#aahd-2"
										role="tab" aria-controls="aahd-2" aria-selected="false" #hearing>
										Hearing / Video Conferencing
									</a>
								</li>
								<li class="nav-item"
									[ngClass]="{'disabled' : !isHearingVisible  || !phase2Form.value.submmissionDtls.subDate || getAckCopyLength() == 0 || form36Length || applGroundLocLength || stmtFactLength || applChallanFeesLength}">
									<a class="nav-link" id="aasd-tab-vertical" data-toggle="tab" href="#aasd-2"
										role="tab" aria-controls="aasd-2" aria-selected="false" #summon>
										Summon Details

									</a>
								</li>
								<li class="nav-item"
									[ngClass]="{'disabled' : !isHearingVisible  || !phase2Form.value.submmissionDtls.subDate || getAckCopyLength() == 0 || form36Length || applGroundLocLength || stmtFactLength || applChallanFeesLength}">
									<a class="nav-link" id="aatcc-tab-vertical" data-toggle="tab" href="#aatcc-2"
										role="tab" aria-controls="aatcc-2" aria-selected="false" #consultant>
										Consultant Charges

									</a>
								</li>
								<li class="nav-item"
									[ngClass]="{'disabled' : !isHearingVisible  || !phase2Form.value.submmissionDtls.subDate || getAckCopyLength() == 0 || form36Length || applGroundLocLength || stmtFactLength || applChallanFeesLength}">
									<a class="nav-link" id="aacon-tab-vertical" data-toggle="tab" href="#aacon-2"
										role="tab" aria-controls="ascon-2" aria-selected="false" #remandBack>
										Abeyance/ Remand Back

									</a>
								</li>
								<li class="nav-item">
									<a class="btn btn-outline-primary w100" (click)="updatePhase2()"
										[ngClass]="{'disabled': (action == 'view')}">
										Update

									</a>
								</li>
							</ul>
						</div>
						<div class="col-10 border" id="tabArea10" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
							<form [formGroup]="phase2Form">
								<div class="tab-content tab-content-vertical">

									<div class="tab-pane fade show active" id="aasub-2" role="tabpanel"
										aria-labelledby="aasub-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
										<fieldset [disabled]="action == 'view'">
											<div formGroupName="submmissionDtls">
												<div class="row">
													<div class="col-md-12">
														<p class="textCenter">Submission</p>
														<span class="toggleTab" (click)="sidemenu=!sidemenu">
															<i class="mdi mdi mdi-chevron-double-left leftTIcon"
																*ngIf="!sidemenu"></i>
															<i class="mdi mdi-chevron-double-right rightTIcon"
																*ngIf="sidemenu"></i>
														</span>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label>Acknowledgement Number <span class="mandate"><i
																		class="fa fa-asterisk"></i></span></label>
															<input type="text" formControlName="ackNo"
																[ngClass]="{errorBorder : isSubmmissionSubmitted && !phase2Form.value.submmissionDtls.ackNo || phase2Form.value.submmissionDtls.ackNo && phase2Form.value.submmissionDtls.ackNo.length > 50}"
																class="form-control">
															<div
																*ngIf="phase2Form.value.submmissionDtls.ackNo && phase2Form.value.submmissionDtls.ackNo.length > 50">
																<span class="text-danger">Acknowledgement Number Max
																	character Length
																	50</span>
															</div>
															<div
																*ngIf="isSubmmissionSubmitted && !phase2Form.value.submmissionDtls.ackNo">
																<span class="text-danger">Acknowledgement Number
																	Required</span>
															</div>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label>Date of Filing Appeal <span class="mandate"><i
																		class="fa fa-asterisk"></i></span>
															</label>
															<div id="dtReviseDue" class="input-group date datepicker">
																<p-calendar class="form-control" [monthNavigator]="true"
																	formControlName="subDate" [yearNavigator]="true"
																	yearRange="2010:{{currentYear}}"
																	dateFormat="dd-mm-yy" [showIcon]="true"
																	dataType="string" [maxDate]="maxDate"
																	[ngClass]="{errorBorder : isSubmmissionSubmitted && !phase2Form.value.submmissionDtls.subDate}">
																</p-calendar>
															</div>
														</div>
														<div
															*ngIf="isSubmmissionSubmitted && !phase2Form.value.submmissionDtls.subDate">
															<span class="text-danger">Date of Filing Appeal
																Required</span>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label>Mode of Appeal </label>
															<select class="form-control form-select" id="subMode"
																formControlName="subMode">
																<option [value]="null">-- Select --</option>
																<ng-container *ngFor="let obj of modeOfAppeal">
																	<option [value]="obj.value">{{ obj.label }}</option>
																</ng-container>
															</select>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label for="exampleInputPassword1">Copy of Acknowledgement
																<span class="mandate"><i
																		class="fa fa-asterisk"></i></span> </label>
															<input type="file" class="file-upload-default" #file>
															<div class="attachment-block"
																[ngClass]="{errorBorder : isSubmmissionSubmitted && getAckCopyLength() == 0}">
																<span (click)="openAckCopyLocPop(ackCopyFormPop)"> <i
																		class="icon fa fa fa-paperclip" id="paperclip"
																		title="Attach file"></i><span
																		*ngIf="getAckCopyLength() != 0">{{getAckCopyLength()}}</span><span
																		*ngIf="getAckCopyLength() != 0">
																		Attachment</span></span>
															</div>
														</div>
														<div *ngIf="isSubmmissionSubmitted && getAckCopyLength() == 0">
															<span class="text-danger">Copy of Acknowledgement
																required</span>
														</div>
													</div>

													<ng-template #ackCopyFormPop let-modal>
														<div class="modal-header"
															style="border: none; padding-bottom: 0px;">
															<button type="button" class="close" aria-label="Close"
																(click)="modal.dismiss('Cross click')"> <span
																	aria-hidden="true">×</span> </button>
														</div>
														<div class="col-md-12">
															<label> Attachment</label>
															<div class="table-responsive">
																<table class="table table-bordered">
																	<thead>
																		<tr class="bgGred">
																			<th>Upload</th>
																			<th style="width:50%;">Remark (Max 100
																				Character allowed)</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr *ngFor="let ackCopyLocGrp of getAckCopyLoc(); let i = index"
																			[formGroup]="ackCopyLocGrp">
																			<td>
																				<div class="row">
																					<div class="col-md-9 ">
																						<div class="form-group">
																							<input
																								*ngIf="ackCopyLocGrp.value.isAckCopyLocDocUploadedClicked"
																								type="file"
																								id="submmissionDtls.ackCopyLoc({{i}}).fileLoc"
																								class="filetype form-control"
																								name="myfile"
																								(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'submmissionDtls',second:'ackCopyLoc'})">
																							<p *ngIf="!ackCopyLocGrp.value.isAckCopyLocDocUploadedClicked || ackCopyLocGrp.value.fileLoc"
																								class="form-control pt10"
																								[title]="ackCopyLocGrp.value.fileName ? ackCopyLocGrp.value.fileName : ''">
																								{{getFileNameDta(ackCopyLocGrp.value.fileLoc)}}
																							</p>
																						</div>
																					</div>
																					<div class="col-md-3 nopadding">
																						<label>
																							<a class="downupIcon"
																								*ngIf="(ackCopyLocGrp.value.fileLoc)"
																								title="Download file"
																								(click)="download(ackCopyLocGrp.value.fileLoc)"><i
																									class="fa fa-download"></i></a>
																							<a class="downupIcon"
																								*ngIf="!(ackCopyLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																								title="Upload file"
																								(click)="uploadAckCopyFormLoc(i)"><i
																									class="fa fa-upload"></i></a>
																							<a class="downupIcon"
																								(click)="deleteAckCopyFormLoc(i)"
																								*ngIf="(ackCopyLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																									class="fa fa-trash"
																									aria-hidden="true"></i></a>
																						</label>
																					</div>
																				</div>
																			</td>
																			<td> <input type="text" class="form-control"
																					placeholder="" id="remarks"
																					formControlName="remarks"
																					[title]="ackCopyLocGrp.value.remarks ? ackCopyLocGrp.value.remarks : ''" />
																				<div
																					*ngIf="ackCopyLocGrp.value.remarks && ackCopyLocGrp.value.remarks.length > 100">
																					<span class="text-danger">Remark Max
																						character Length
																						100</span>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
														<div class="modal-footer"
															style="border: none; padding-top: 0px;">
															<button type="button" class="btn btn-outline-dark"
																(click)="modal.close('Save click')">Save</button>
														</div>
													</ng-template>

													<div class="col-md-3">
														<div class="form-group">
															<label>Appeal Number </label>
															<input type="text" formControlName="appealNo"
																class="form-control">
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label>Response Type <span class="mandate"><i
																		class="fa fa-asterisk"></i></span></label>
															<select class="form-control form-select" id="respType"
																formControlName="respType">
																<option [value]="null">-- Select --</option>
																<ng-container *ngFor="let obj of responseTyep">
																	<option [value]="obj.value">{{ obj.label }}</option>
																</ng-container>
															</select>
														</div>
													</div>
													<div class="col-md-12">
														<div class="accordion accordion-solid-header" id="accordion-66"
															role="tablist">
															<div class="card border-bottom">
																<div class="card-header" role="tab" id="heading-66">
																	<h6 class="mb-0">
																		<a data-toggle="collapse" href="#collapse-66"
																			aria-expanded="true"
																			aria-controls="collapse-66">
																			Appeal Attachments
																		</a>
																	</h6>
																</div>
																<div formGroupName="appealAttachments">
																	<div id="collapse-66" class="collapse show"
																		role="tabpanel" aria-labelledby="heading-66"
																		data-parent="#accordion-66">
																		<div class="card-body">
																			<div class="row">
																				<div class="table-responsive">
																					<table class="table table-bordered">
																						<thead class="bgGred">
																							<tr>
																								<!-- <th class="sl-no-cls">Sr.No.</th> -->
																								<th
																									style="min-width: 100px">
																									Copy of Form 35
																									<span
																										class="mandatetd"><i
																											class="fa fa-asterisk"
																											style="font-size: 7px; margin-left:0px;"></i></span>
																								</th>
																								<th
																									style="min-width: 210px">
																									Grounds of Appeal
																									<span
																										class="mandatetd"><i
																											class="fa fa-asterisk"
																											style="font-size: 7px; margin-left: 0px;"></i></span>
																								</th>
																								<th>Statement of
																									Facts<span
																										class="mandatetd"><i
																											class="fa fa-asterisk"
																											style="font-size: 7px; margin-left: 0px;"></i></span>
																								</th>
																								<th>Appeal Filing Fees
																									Challan <span
																										class="mandatetd"><i
																											class="fa fa-asterisk"
																											style="font-size: 7px; margin-left: 0px;"></i></span>
																								</th>
																								<th>Condonation of
																									Delay, If Any</th>
																								<th
																									style="min-width: 250px">
																									Additional Evidence
																									If Any</th>
																								<!-- <th class="sl-no-cls">Action</th> -->
																							</tr>
																						</thead>
																						<tbody>
																							<ng-container>
																								<tr
																									*ngFor="let attachment of getAppealAttachmentsControls().controls; let i = index">
																									<!-- <td class="w-40">{{i+1}}</td> -->
																									<td>
																										<div><span
																												(click)="openDetailModalPop(applFormLocPop, i)"><i
																													class="icon fa fa fa-paperclip"
																													id="paperclip"
																													title="Attach file"></i></span><span
																												*ngIf="getapplFormLocLength(i) !== 0">{{getapplFormLocLength(i)}}</span><span
																												*ngIf="getapplFormLocLength(i) !== 0">
																												Attachment</span>
																										</div><span
																											*ngIf="isSubmmissionSubmitted && getapplFormLocLength(i) == 0"
																											class="text-danger">Required</span>
																									</td>
																									<td>
																										<div><span
																												(click)="openDetailModalPop(applGroundLocPop, i)"><i
																													class="icon fa fa fa-paperclip"
																													id="paperclip"
																													title="Attach file"></i></span><span
																												*ngIf="getapplGroundLocLength(i) !== 0">{{getapplGroundLocLength(i)}}</span><span
																												*ngIf="getapplGroundLocLength(i) !== 0">
																												Attachment</span>
																										</div><span
																											*ngIf="isSubmmissionSubmitted && getapplGroundLocLength(i) == 0"
																											class="text-danger">Required</span>
																									</td>
																									<td>
																										<div><span
																												(click)="openDetailModalPop(stmtFactsLocPop, i)"><i
																													class="icon fa fa fa-paperclip"
																													id="paperclip"
																													title="Attach file"></i></span><span
																												*ngIf="getstmtFactsLocLength(i) !== 0">{{getstmtFactsLocLength(i)}}</span><span
																												*ngIf="getstmtFactsLocLength(i) !== 0">
																												Attachment</span>
																										</div><span
																											*ngIf="isSubmmissionSubmitted && getstmtFactsLocLength(i) == 0"
																											class="text-danger">Required</span>
																									</td>
																									<td>
																										<div><span
																												(click)="openDetailModalPop(applChallanFeesLocPop, i)"><i
																													class="icon fa fa fa-paperclip"
																													id="paperclip"
																													title="Attach file"></i></span><span
																												*ngIf="getapplChallanFeesLocLength(i) !== 0">{{getapplChallanFeesLocLength(i)}}</span><span
																												*ngIf="getapplChallanFeesLocLength(i) !== 0">
																												Attachment</span>
																										</div><span
																											*ngIf="isSubmmissionSubmitted && getapplChallanFeesLocLength(i) == 0"
																											class="text-danger">Required</span>
																									</td>
																									<td>
																										<div><span
																												(click)="openDetailModalPop(condonationOfDelayLocPop, i)"><i
																													class="icon fa fa fa-paperclip"
																													id="paperclip"
																													title="Attach file"></i></span><span
																												*ngIf="getcondonationOfDelayLocLength(i) !== 0">{{getcondonationOfDelayLocLength(i)}}</span><span
																												*ngIf="getcondonationOfDelayLocLength(i) !== 0">
																												Attachment</span>
																										</div>
																									</td>
																									<td>
																										<div><span
																												(click)="openDetailModalPop(addEvidLocPop, i)"><i
																													class="icon fa fa fa-paperclip"
																													id="paperclip"
																													title="Attach file"></i></span><span
																												*ngIf="getaddEvidLocLength(i) !== 0">{{getaddEvidLocLength(i)}}</span><span
																												*ngIf="getaddEvidLocLength(i) !== 0">
																												Attachment</span>
																										</div>
																									</td>
																									<!-- <td class="w-80">
                                                              <a class="addExtLetter" (click)="addAppealAttach()"><i class="fa fa-plus" title="Add row" ></i> </a>
                                                              <a *ngIf="i != 0" class="addExtLetter" (click)="removeAttach(i)"><i class="fa fa-minus"></i></a>

                                                          </td> -->
																									<ng-template
																										#applFormLocPop
																										let-modal>
																										<div class="modal-header"
																											style="border: none; padding-bottom: 0px;">
																											<button
																												type="button"
																												class="close"
																												aria-label="Close"
																												(click)="modal.dismiss('Cross click')">
																												<span
																													aria-hidden="true">×</span>
																											</button>
																										</div>
																										<div
																											class="col-md-12">
																											<label>
																												Attachment</label>
																											<div
																												class="table-responsive">
																												<table
																													class="table table-bordered">
																													<thead>
																														<tr
																															class="bgGred">
																															<th>Upload
																															</th>
																															<th
																																style="width:50%;">
																																Remark
																																(Max
																																100
																																Character
																																allowed)
																															</th>
																														</tr>
																													</thead>
																													<tbody>
																														<tr *ngFor="let form36Grp of getApplFormLocControls(i); let j = index"
																															[formGroup]="form36Grp">
																															<td>
																																<div
																																	class="row">
																																	<div
																																		class="col-md-9 ">
																																		<div
																																			class="form-group">
																																			<input
																																				*ngIf="form36Grp?.value?.isdocLocUploadedClicked"
																																				type="file"
																																				id="appealAttachments({{i}}).applFormLoc({{j}}).fileLoc"
																																				class="filetype form-control"
																																				name="myfile"
																																				(change)="onFileSelectUpload($event,j,{form:'phase2Form',first : 'submmissionDtls',second:'appealAttachments',secondIndex : i,third:'applFormLoc',thirdIndex : j})">
																																			<p *ngIf="!form36Grp?.value?.isdocLocUploadedClicked || form36Grp?.value.fileLoc"
																																				class="form-control pt10">
																																				{{getFileNameDta(form36Grp.value.fileLoc)}}
																																			</p>
																																		</div>
																																	</div>
																																	<div
																																		class="col-md-3 nopadding">
																																		<label
																																			for="exampleInputPassword1">
																																			<a class="downupIcon"
																																				*ngIf="form36Grp?.value.fileLoc"
																																				title="Download file"
																																				(click)="download(form36Grp.value.fileLoc)"><i
																																					class="fa fa-download"></i></a>
																																			<a class="downupIcon"
																																				*ngIf="!form36Grp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																				title="Upload file"
																																				(click)="upload36Form(i,j)"><i
																																					class="fa fa-upload"></i></a>
																																			<a class="downupIcon"
																																				(click)="delete36Form(i,j)"
																																				*ngIf="(form36Grp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																					class="fa fa-trash"
																																					aria-hidden="true"></i></a>
																																		</label>
																																	</div>
																																</div>
																															</td>
																															<td>
																																<input
																																	type="text"
																																	class="form-control"
																																	formControlName="remarks"
																																	[title]="form36Grp.value.remarks ? form36Grp.value.remarks : ''">
																																<div
																																	*ngIf="form36Grp.value.remarks && form36Grp.value.remarks.length > 100">
																																	<span
																																		class="text-danger">Remark
																																		Max
																																		character
																																		Length
																																		100</span>
																																</div>
																															</td>
																															<ng-template
																																#rmitrFPop
																																let-modal>
																																<div
																																	class="modal-header">
																																	<h4 class="modal-title"
																																		id="modal-basic-title">
																																		Remove
																																		Warning!
																																	</h4>
																																	<button
																																		type="button"
																																		class="close"
																																		aria-label="Close"
																																		(click)="modal.dismiss('Cross click')">
																																		<span
																																			aria-hidden="true">×</span>
																																	</button>
																																</div>
																																<div
																																	class="modal-body">
																																	<p>Are
																																		you
																																		sure,
																																		you
																																		want
																																		to
																																		remove?
																																	</p>
																																</div>
																																<div
																																	class="modal-footer">
																																	<button
																																		type="button"
																																		class="btn btn-outline-dark">Remove</button>
																																</div>
																															</ng-template>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</div>
																										<div class="modal-footer"
																											style="border: none; padding-top: 0px;">
																											<button
																												type="button"
																												class="btn btn-outline-dark"
																												(click)="modal.close('Save click')">Save</button>
																										</div>
																									</ng-template>
																									<ng-template
																										#applGroundLocPop
																										let-modal>
																										<div class="modal-header"
																											style="border: none; padding-bottom: 0px;">
																											<button
																												type="button"
																												class="close"
																												aria-label="Close"
																												(click)="modal.dismiss('Cross click')">
																												<span
																													aria-hidden="true">×</span>
																											</button>
																										</div>
																										<div
																											class="col-md-12">
																											<label>
																												Attachment</label>
																											<div
																												class="table-responsive">
																												<table
																													class="table table-bordered">
																													<thead>
																														<tr
																															class="bgGred">
																															<th>Upload
																															</th>
																															<th
																																style="width:50%;">
																																Remark
																																(Max
																																100
																																Character
																																allowed)
																															</th>
																														</tr>
																													</thead>
																													<tbody>
																														<tr *ngFor="let applGroundLocGrp of getApplGroundLocControls(i); let j = index"
																															[formGroup]="applGroundLocGrp">
																															<td>
																																<div
																																	class="row">
																																	<div
																																		class="col-md-9 ">
																																		<div
																																			class="form-group">
																																			<input
																																				*ngIf="applGroundLocGrp?.value?.isdocLocUploadedClicked"
																																				type="file"
																																				id="appealAttachments({{i}}).applGroundLoc({{j}}).fileLoc"
																																				class="filetype form-control"
																																				name="myfile"
																																				(change)="onFileSelectUpload($event,j,{form:'phase2Form',first : 'submmissionDtls',second:'appealAttachments',secondIndex : i,third:'applGroundLoc',thirdIndex : j})">
																																			<p *ngIf="!applGroundLocGrp?.value?.isdocLocUploadedClicked || applGroundLocGrp?.value.fileLoc"
																																				class="form-control pt10">
																																				{{getFileNameDta(applGroundLocGrp.value.fileLoc)}}
																																			</p>
																																		</div>
																																	</div>
																																	<div
																																		class="col-md-3 nopadding">
																																		<label
																																			for="exampleInputPassword1">
																																			<a class="downupIcon"
																																				*ngIf="applGroundLocGrp?.value.fileLoc"
																																				title="Download file"
																																				(click)="download(applGroundLocGrp.value.fileLoc)"><i
																																					class="fa fa-download"></i></a>
																																			<a class="downupIcon"
																																				*ngIf="!applGroundLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																				title="Upload file"
																																				(click)="uploadapplGroundLoc(i,j)"><i
																																					class="fa fa-upload"></i></a>
																																			<a class="downupIcon"
																																				(click)="deleteapplGroundLoc(i,j)"
																																				*ngIf="(applGroundLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																					class="fa fa-trash"
																																					aria-hidden="true"></i></a>
																																		</label>
																																	</div>
																																</div>
																															</td>
																															<td>
																																<input
																																	type="text"
																																	class="form-control"
																																	formControlName="remarks"
																																	[title]="applGroundLocGrp.value.remarks ? applGroundLocGrp.value.remarks : ''">
																																<div
																																	*ngIf="applGroundLocGrp.value.remarks && applGroundLocGrp.value.remarks.length > 100">
																																	<span
																																		class="text-danger">Remark
																																		Max
																																		character
																																		Length
																																		100</span>
																																</div>
																															</td>
																															<ng-template
																																#rmitrFPop
																																let-modal>
																																<div
																																	class="modal-header">
																																	<h4 class="modal-title"
																																		id="modal-basic-title">
																																		Remove
																																		Warning!
																																	</h4>
																																	<button
																																		type="button"
																																		class="close"
																																		aria-label="Close"
																																		(click)="modal.dismiss('Cross click')">
																																		<span
																																			aria-hidden="true">×</span>
																																	</button>
																																</div>
																																<div
																																	class="modal-body">
																																	<p>Are
																																		you
																																		sure,
																																		you
																																		want
																																		to
																																		remove?
																																	</p>
																																</div>
																																<div
																																	class="modal-footer">
																																	<button
																																		type="button"
																																		class="btn btn-outline-dark">Remove</button>
																																</div>
																															</ng-template>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</div>
																										<div class="modal-footer"
																											style="border: none; padding-top: 0px;">
																											<button
																												type="button"
																												class="btn btn-outline-dark"
																												(click)="modal.close('Save click')">Save</button>
																										</div>
																									</ng-template>
																									<ng-template
																										#stmtFactsLocPop
																										let-modal>
																										<div class="modal-header"
																											style="border: none; padding-bottom: 0px;">
																											<button
																												type="button"
																												class="close"
																												aria-label="Close"
																												(click)="modal.dismiss('Cross click')">
																												<span
																													aria-hidden="true">×</span>
																											</button>
																										</div>
																										<div
																											class="col-md-12">
																											<label>
																												Attachment</label>
																											<div
																												class="table-responsive">
																												<table
																													class="table table-bordered">
																													<thead>
																														<tr
																															class="bgGred">
																															<th>Upload
																															</th>
																															<th
																																style="width:50%;">
																																Remark
																																(Max
																																100
																																Character
																																allowed)
																															</th>
																														</tr>
																													</thead>
																													<tbody>
																														<tr *ngFor="let stmtFactLocGrp of getStmtFactsLocControls(i); let j = index"
																															[formGroup]="stmtFactLocGrp">
																															<td>
																																<div
																																	class="row">
																																	<div
																																		class="col-md-9 ">
																																		<div
																																			class="form-group">
																																			<input
																																				*ngIf="stmtFactLocGrp?.value?.isdocLocUploadedClicked"
																																				type="file"
																																				id="appealAttachments({{i}}).applGroundLoc({{j}}).fileLoc"
																																				class="filetype form-control"
																																				name="myfile"
																																				(change)="onFileSelectUpload($event,j,{form:'phase2Form',first : 'submmissionDtls',second:'appealAttachments',secondIndex : i,third:'stmtFactsLoc',thirdIndex : j})">
																																			<p *ngIf="!stmtFactLocGrp?.value?.isdocLocUploadedClicked || stmtFactLocGrp?.value.fileLoc"
																																				class="form-control pt10">
																																				{{getFileNameDta(stmtFactLocGrp.value.fileLoc)}}
																																			</p>
																																		</div>
																																	</div>
																																	<div
																																		class="col-md-3 nopadding">
																																		<label
																																			for="exampleInputPassword1">
																																			<a class="downupIcon"
																																				*ngIf="stmtFactLocGrp?.value.fileLoc"
																																				title="Download file"
																																				(click)="download(stmtFactLocGrp.value.fileLoc)"><i
																																					class="fa fa-download"></i></a>
																																			<a class="downupIcon"
																																				*ngIf="!stmtFactLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																				title="Upload file"
																																				(click)="uploadstmtFactsLoc(i,j)"><i
																																					class="fa fa-upload"></i></a>
																																			<a class="downupIcon"
																																				(click)="deletestmtFactsLoc(i,j)"
																																				*ngIf="(stmtFactLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																					class="fa fa-trash"
																																					aria-hidden="true"></i></a>
																																		</label>
																																	</div>
																																</div>
																															</td>
																															<td>
																																<input
																																	type="text"
																																	class="form-control"
																																	formControlName="remarks"
																																	[title]="stmtFactLocGrp.value.remarks ? stmtFactLocGrp.value.remarks : ''">
																																<div
																																	*ngIf="stmtFactLocGrp.value.remarks && stmtFactLocGrp.value.remarks.length > 100">
																																	<span
																																		class="text-danger">Remark
																																		Max
																																		character
																																		Length
																																		100</span>
																																</div>
																															</td>
																															<ng-template
																																#rmitrFPop
																																let-modal>
																																<div
																																	class="modal-header">
																																	<h4 class="modal-title"
																																		id="modal-basic-title">
																																		Remove
																																		Warning!
																																	</h4>
																																	<button
																																		type="button"
																																		class="close"
																																		aria-label="Close"
																																		(click)="modal.dismiss('Cross click')">
																																		<span
																																			aria-hidden="true">×</span>
																																	</button>
																																</div>
																																<div
																																	class="modal-body">
																																	<p>Are
																																		you
																																		sure,
																																		you
																																		want
																																		to
																																		remove?
																																	</p>
																																</div>
																																<div
																																	class="modal-footer">
																																	<button
																																		type="button"
																																		class="btn btn-outline-dark">Remove</button>
																																</div>
																															</ng-template>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</div>
																										<div class="modal-footer"
																											style="border: none; padding-top: 0px;">
																											<button
																												type="button"
																												class="btn btn-outline-dark"
																												(click)="modal.close('Save click')">Save</button>
																										</div>
																									</ng-template>
																									<ng-template
																										#applChallanFeesLocPop
																										let-modal>
																										<div class="modal-header"
																											style="border: none; padding-bottom: 0px;">
																											<button
																												type="button"
																												class="close"
																												aria-label="Close"
																												(click)="modal.dismiss('Cross click')">
																												<span
																													aria-hidden="true">×</span>
																											</button>
																										</div>
																										<div
																											class="col-md-12">
																											<label>
																												Attachment</label>
																											<div
																												class="table-responsive">
																												<table
																													class="table table-bordered">
																													<thead>
																														<tr
																															class="bgGred">
																															<th>Upload
																															</th>
																															<th
																																style="width:50%;">
																																Remark
																																(Max
																																100
																																Character
																																allowed)
																															</th>
																														</tr>
																													</thead>
																													<tbody>
																														<tr *ngFor="let appChallanLocGrp of getApplChallanFeesLocControls(i); let j = index"
																															[formGroup]="appChallanLocGrp">
																															<td>
																																<div
																																	class="row">
																																	<div
																																		class="col-md-9 ">
																																		<div
																																			class="form-group">
																																			<input
																																				*ngIf="appChallanLocGrp?.value?.isdocLocUploadedClicked"
																																				type="file"
																																				id="appealAttachments({{i}}).applGroundLoc({{j}}).fileLoc"
																																				class="filetype form-control"
																																				name="myfile"
																																				(change)="onFileSelectUpload($event,j,{form:'phase2Form',first : 'submmissionDtls',second:'appealAttachments',secondIndex : i,third:'applChallanFeesLoc',thirdIndex : j})">
																																			<p *ngIf="!appChallanLocGrp?.value?.isdocLocUploadedClicked || appChallanLocGrp?.value.fileLoc"
																																				class="form-control pt10">
																																				{{getFileNameDta(appChallanLocGrp.value.fileLoc)}}
																																			</p>
																																		</div>
																																	</div>
																																	<div
																																		class="col-md-3 nopadding">
																																		<label
																																			for="exampleInputPassword1">
																																			<a class="downupIcon"
																																				*ngIf="appChallanLocGrp?.value.fileLoc"
																																				title="Download file"
																																				(click)="download(appChallanLocGrp.value.fileLoc)"><i
																																					class="fa fa-download"></i></a>
																																			<a class="downupIcon"
																																				*ngIf="!appChallanLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																				title="Upload file"
																																				(click)="uploadapplChallanFeesLoc(i,j)"><i
																																					class="fa fa-upload"></i></a>
																																			<a class="downupIcon"
																																				(click)="deleteapplChallanFeesLoc(i,j)"
																																				*ngIf="(appChallanLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																					class="fa fa-trash"
																																					aria-hidden="true"></i></a>
																																		</label>
																																	</div>
																																</div>
																															</td>
																															<td>
																																<input
																																	type="text"
																																	class="form-control"
																																	formControlName="remarks"
																																	[title]="appChallanLocGrp.value.remarks ? appChallanLocGrp.value.remarks : ''">
																																<div
																																	*ngIf="appChallanLocGrp.value.remarks && appChallanLocGrp.value.remarks.length > 100">
																																	<span
																																		class="text-danger">Remark
																																		Max
																																		character
																																		Length
																																		100</span>
																																</div>
																															</td>
																															<ng-template
																																#rmitrFPop
																																let-modal>
																																<div
																																	class="modal-header">
																																	<h4 class="modal-title"
																																		id="modal-basic-title">
																																		Remove
																																		Warning!
																																	</h4>
																																	<button
																																		type="button"
																																		class="close"
																																		aria-label="Close"
																																		(click)="modal.dismiss('Cross click')">
																																		<span
																																			aria-hidden="true">×</span>
																																	</button>
																																</div>
																																<div
																																	class="modal-body">
																																	<p>Are
																																		you
																																		sure,
																																		you
																																		want
																																		to
																																		remove?
																																	</p>
																																</div>
																																<div
																																	class="modal-footer">
																																	<button
																																		type="button"
																																		class="btn btn-outline-dark">Remove</button>
																																</div>
																															</ng-template>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</div>
																										<div class="modal-footer"
																											style="border: none; padding-top: 0px;">
																											<button
																												type="button"
																												class="btn btn-outline-dark"
																												(click)="modal.close('Save click')">Save</button>
																										</div>
																									</ng-template>
																									<ng-template
																										#condonationOfDelayLocPop
																										let-modal>
																										<div class="modal-header"
																											style="border: none; padding-bottom: 0px;">
																											<button
																												type="button"
																												class="close"
																												aria-label="Close"
																												(click)="modal.dismiss('Cross click')">
																												<span
																													aria-hidden="true">×</span>
																											</button>
																										</div>
																										<div
																											class="col-md-12">
																											<label>
																												Attachment</label>
																											<div
																												class="table-responsive">
																												<table
																													class="table table-bordered">
																													<thead>
																														<tr
																															class="bgGred">
																															<th>Upload
																															</th>
																															<th
																																style="width:50%;">
																																Remark
																																(Max
																																100
																																Character
																																allowed)
																															</th>
																														</tr>
																													</thead>
																													<tbody>
																														<tr *ngFor="let condonationLocGrp of getCondonationOfDelayLocControls(i); let j = index"
																															[formGroup]="condonationLocGrp">
																															<td>
																																<div
																																	class="row">
																																	<div
																																		class="col-md-9 ">
																																		<div
																																			class="form-group">
																																			<input
																																				*ngIf="condonationLocGrp?.value?.isdocLocUploadedClicked"
																																				type="file"
																																				id="appealAttachments({{i}}).condonationOfDelayLoc({{j}}).fileLoc"
																																				class="filetype form-control"
																																				name="myfile"
																																				(change)="onFileSelectUpload($event,j,{form:'phase2Form',first : 'submmissionDtls',second:'appealAttachments',secondIndex : i,third:'condonationOfDelayLoc',thirdIndex : j})">
																																			<p *ngIf="!condonationLocGrp?.value?.isdocLocUploadedClicked || condonationLocGrp?.value.fileLoc"
																																				class="form-control pt10">
																																				{{getFileNameDta(condonationLocGrp.value.fileLoc)}}
																																			</p>
																																		</div>
																																	</div>
																																	<div
																																		class="col-md-3 nopadding">
																																		<label
																																			for="exampleInputPassword1">
																																			<a class="downupIcon"
																																				*ngIf="condonationLocGrp?.value.fileLoc"
																																				title="Download file"
																																				(click)="download(condonationLocGrp.value.fileLoc)"><i
																																					class="fa fa-download"></i></a>
																																			<a class="downupIcon"
																																				*ngIf="!condonationLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																				title="Upload file"
																																				(click)="uploadcondonationOfDelayLoc(i,j)"><i
																																					class="fa fa-upload"></i></a>
																																			<a class="downupIcon"
																																				(click)="deletecondonationOfDelayLoc(i,j)"
																																				*ngIf="(condonationLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																					class="fa fa-trash"
																																					aria-hidden="true"></i></a>
																																		</label>
																																	</div>
																																</div>
																															</td>
																															<td>
																																<input
																																	type="text"
																																	class="form-control"
																																	formControlName="remarks"
																																	[title]="condonationLocGrp.value.remarks ? condonationLocGrp.value.remarks : ''">
																																<div
																																	*ngIf="condonationLocGrp.value.remarks && condonationLocGrp.value.remarks.length > 100">
																																	<span
																																		class="text-danger">Remark
																																		Max
																																		character
																																		Length
																																		100</span>
																																</div>
																															</td>
																															<ng-template
																																#rmitrFPop
																																let-modal>
																																<div
																																	class="modal-header">
																																	<h4 class="modal-title"
																																		id="modal-basic-title">
																																		Remove
																																		Warning!
																																	</h4>
																																	<button
																																		type="button"
																																		class="close"
																																		aria-label="Close"
																																		(click)="modal.dismiss('Cross click')">
																																		<span
																																			aria-hidden="true">×</span>
																																	</button>
																																</div>
																																<div
																																	class="modal-body">
																																	<p>Are
																																		you
																																		sure,
																																		you
																																		want
																																		to
																																		remove?
																																	</p>
																																</div>
																																<div
																																	class="modal-footer">
																																	<button
																																		type="button"
																																		class="btn btn-outline-dark">Remove</button>
																																</div>
																															</ng-template>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</div>
																										<div class="modal-footer"
																											style="border: none; padding-top: 0px;">
																											<button
																												type="button"
																												class="btn btn-outline-dark"
																												(click)="modal.close('Save click')">Save</button>
																										</div>
																									</ng-template>
																									<ng-template
																										#addEvidLocPop
																										let-modal>
																										<div class="modal-header"
																											style="border: none; padding-bottom: 0px;">
																											<button
																												type="button"
																												class="close"
																												aria-label="Close"
																												(click)="modal.dismiss('Cross click')">
																												<span
																													aria-hidden="true">×</span>
																											</button>
																										</div>
																										<div
																											class="col-md-12">
																											<label>
																												Attachment</label>
																											<div
																												class="table-responsive">
																												<table
																													class="table table-bordered">
																													<thead>
																														<tr
																															class="bgGred">
																															<th>Upload
																															</th>
																															<th
																																style="width:50%;">
																																Remark
																																(Max
																																100
																																Character
																																allowed)
																															</th>
																														</tr>
																													</thead>
																													<tbody>
																														<tr *ngFor="let addEvidLocGrp of getAddEvidLocControls(i); let j = index"
																															[formGroup]="addEvidLocGrp">
																															<td>
																																<div
																																	class="row">
																																	<div
																																		class="col-md-9 ">
																																		<div
																																			class="form-group">
																																			<input
																																				*ngIf="addEvidLocGrp?.value?.isdocLocUploadedClicked"
																																				type="file"
																																				id="appealAttachments({{i}}).addEvidLoc({{j}}).fileLoc"
																																				class="filetype form-control"
																																				name="myfile"
																																				(change)="onFileSelectUpload($event,j,{form:'phase2Form',first : 'submmissionDtls',second:'appealAttachments',secondIndex : i,third:'addEvidLoc',thirdIndex : j})">
																																			<p *ngIf="!addEvidLocGrp?.value?.isdocLocUploadedClicked || addEvidLocGrp?.value.fileLoc"
																																				class="form-control pt10">
																																				{{getFileNameDta(addEvidLocGrp.value.fileLoc)}}
																																			</p>
																																		</div>
																																	</div>
																																	<div
																																		class="col-md-3 nopadding">
																																		<label
																																			for="exampleInputPassword1">
																																			<a class="downupIcon"
																																				*ngIf="addEvidLocGrp?.value.fileLoc"
																																				title="Download file"
																																				(click)="download(addEvidLocGrp.value.fileLoc)"><i
																																					class="fa fa-download"></i></a>
																																			<a class="downupIcon"
																																				*ngIf="!addEvidLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																				title="Upload file"
																																				(click)="uploadaddEvidLoc(i,j)"><i
																																					class="fa fa-upload"></i></a>
																																			<a class="downupIcon"
																																				(click)="deleteaddEvidLoc(i,j)"
																																				*ngIf="(addEvidLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																					class="fa fa-trash"
																																					aria-hidden="true"></i></a>
																																		</label>
																																	</div>
																																</div>
																															</td>
																															<td>
																																<input
																																	type="text"
																																	class="form-control"
																																	formControlName="remarks"
																																	[title]="addEvidLocGrp.value.remarks ? addEvidLocGrp.value.remarks : ''">
																																<div
																																	*ngIf="addEvidLocGrp.value.remarks && addEvidLocGrp.value.remarks.length > 100">
																																	<span
																																		class="text-danger">Remark
																																		Max
																																		character
																																		Length
																																		100</span>
																																</div>
																															</td>
																															<ng-template
																																#rmitrFPop
																																let-modal>
																																<div
																																	class="modal-header">
																																	<h4 class="modal-title"
																																		id="modal-basic-title">
																																		Remove
																																		Warning!
																																	</h4>
																																	<button
																																		type="button"
																																		class="close"
																																		aria-label="Close"
																																		(click)="modal.dismiss('Cross click')">
																																		<span
																																			aria-hidden="true">×</span>
																																	</button>
																																</div>
																																<div
																																	class="modal-body">
																																	<p>Are
																																		you
																																		sure,
																																		you
																																		want
																																		to
																																		remove?
																																	</p>
																																</div>
																																<div
																																	class="modal-footer">
																																	<button
																																		type="button"
																																		class="btn btn-outline-dark">Remove</button>
																																</div>
																															</ng-template>
																														</tr>
																													</tbody>
																												</table>
																											</div>
																										</div>
																										<div class="modal-footer"
																											style="border: none; padding-top: 0px;">
																											<button
																												type="button"
																												class="btn btn-outline-dark"
																												(click)="modal.close('Save click')">Save</button>
																										</div>
																									</ng-template>
																								</tr>
																							</ng-container>
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="col-md-12">
															<div class="form-group">
																<label>Appeal Summary</label>
																<textarea id="exampleTextarea1"
																	formControlName="subSummary"
																	[ngClass]="{errorBorder: isSubmmissionSubmitted && (phase2Form.controls.submmissionDtls.value.subSummary && phase2Form.controls.submmissionDtls.value.subSummary.length > 1000)}"
																	rows="4"
																	class="form-control ng-pristine ng-valid ng-touched"></textarea>
															</div>
															<div
																*ngIf="isSubmmissionSubmitted && (phase2Form.controls.submmissionDtls.value.subSummary && phase2Form.controls.submmissionDtls.value.subSummary.length > 1000)">
																<span class="text-danger">Max 1000 Characters</span>
															</div>
														</div>
														<div class="col-md-12">
															<div class="form-group">
																<label>Internal Remarks</label>
																<textarea id="exampleTextarea1"
																	formControlName="internalRemarks"
																	[ngClass]="{errorBorder: isSubmmissionSubmitted && (phase2Form.controls.submmissionDtls.value.internalRemarks && phase2Form.controls.submmissionDtls.value.internalRemarks.length > 1000)}"
																	rows="4"
																	class="form-control ng-pristine ng-valid ng-touched"></textarea>
															</div>
															<div
																*ngIf="isSubmmissionSubmitted && (phase2Form.controls.submmissionDtls.value.internalRemarks && phase2Form.controls.submmissionDtls.value.internalRemarks.length > 1000)">
																<span class="text-danger">Max 1000 Characters</span>
															</div>
														</div>
														<div class="col-md-12 mt15 mb-3">
															<button class="btn btn-outline-primary mr-1"
																(click)="nextForSubmmission()">Next</button>
														</div>

													</div>

												</div>
											</div>
										</fieldset>
									</div>
									<div class="tab-pane fade" id="aahd-2" role="tabpanel"
										aria-labelledby="aahd-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
										<fieldset [disabled]="action == 'view'">
											<div class="col-md-12 nopadding">
												<p class="textCenter">Hearing / Video Conferencing</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>

											<div formArrayName="hearingDtls">
												<div class="col-md-12 ">
													<span *ngIf="phase2Form.get('hearingDtls')">
														<ul class="nav nav-pills iris-gst-display-flex" role="tablist">

															<li class="nav-item"
																*ngFor="let hearing of phase2Form.get('hearingDtls').controls; let i = index"
																[formGroupName]="i">
																<a class="nav-link" id="adjudph1-tab" data-toggle="tab"
																	href="#adjudph1-1" role="tab"
																	aria-controls="adjudph1-1"
																	(click)="hearingPhaseChanged('phase' + i, i)"
																	[ngClass]="(selectedHearingIndex === 'phase' + i) ? 'active': ''"
																	aria-selected="true">Hearing {{i + 1}} <span
																		*ngIf="i != 0" (click)="removeHearing(i)"
																		class="closeH"><i
																			class="fa fa-close"></i></span></a>
															</li>

														</ul>
													</span>
													<ng-container *ngIf="phase2Form.get('hearingDtls')">
														<div *ngFor="let hearing of phase2Form.get('hearingDtls').controls; let i = index"
															[formGroupName]="i">
															<div class="tab-content nopadding">
																<div class="tab-pane fade show active" id="adjudph1-1"
																	role="tabpanel" aria-labelledby="adjudph1-tab">
																	<div *ngIf="selectedHearingIndex === 'phase' + i">
																		<div class=" ml5">
																			<div class="row">
																				<div class="col-md-3">
																					<div class="form-group">
																					  <label for="exampleInputPassword1">Hearing Ref.No </label>
																					  <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
																					</div>
																				 </div>
						   
																				 <div class="col-md-3">
																					<div class="form-group">
																					  <label for="exampleInputPassword1">Hearing Notice Date </label>
																					  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
																						<p-calendar class="form-control" formControlName="phNoticeDate"
																						  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
																						  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
																					  </div>
																					</div>
																				  </div>
																				<div class="col-md-3">
																					<div class="form-group">
																						<label for="exampleInputPassword1">Hearing Notice</label>
																						<input type="file"
																							class="file-upload-default"
																							#file>
																						<div class="attachment-block">
																							<span
																								(click)="openHearingNotice(hearingNoticeLoc,i)">
																								<i class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i><span
																									*ngIf="getHearingNoticeCopyLength(i) != 0">{{getHearingNoticeCopyLength(i)}}</span><span
																									*ngIf="getHearingNoticeCopyLength(i) != 0">
																									Attachment</span></span>
																						</div>
																					</div>
																				</div>

																				<ng-template #hearingNoticeLoc
																					let-modal>
																					<div class="modal-header"
																						style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th
																											style="width:50%;">
																											Remark (Max
																											100
																											Character
																											allowed)
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let phNoticeLocGrp of getHearingPhNoticeLoc(i); let j = index"
																										[formGroup]="phNoticeLocGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9 ">
																													<div
																														class="form-group">
																														<input
																															*ngIf="phNoticeLocGrp.value.isdocLocUploadedClicked"
																															type="file"
																															id="hearingDtls({{i}}).phNoticeLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'hearingDtls',firstIndex: i,second:'phNoticeLoc',secondIndex:j})">
																														<p *ngIf="!phNoticeLocGrp.value.isdocLocUploadedClicked || phNoticeLocGrp.value.fileLoc"
																															class="form-control pt10"
																															[title]="phNoticeLocGrp.value.fileName ? phNoticeLocGrp.value.fileName : ''">
																															{{getFileNameDta(phNoticeLocGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label>
																														<a class="downupIcon"
																															*ngIf="(phNoticeLocGrp.value.fileLoc)"
																															title="Download file"
																															(click)="download(phNoticeLocGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!(phNoticeLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploadHearingNoticeLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deleteHearingNoticeLoc(i,j)"
																															*ngIf="(phNoticeLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td> <input
																												type="text"
																												class="form-control"
																												placeholder=""
																												id="remarks"
																												formControlName="remarks"
																												[title]="phNoticeLocGrp.value.remarks ? phNoticeLocGrp.value.remarks : ''" />
																											<div
																												*ngIf="phNoticeLocGrp.value.remarks && phNoticeLocGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark
																													Max
																													character
																													Length
																													100</span>
																											</div>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>

																				<div class="col-md-3">
																					<div class="form-group">
																						<label> Hearing Date</label>
																						<div id="dtReviseDue"
																							class="input-group date datepicker">
																							<p-calendar
																								class="form-control"
																								[monthNavigator]="true"
																								formControlName="phScheduledDate"
																								[yearNavigator]="true"
																								yearRange="2010:{{currentYear}}"
																								dateFormat="dd-mm-yy"
																								[showIcon]="true"
																								dataType="string">
																							</p-calendar>
																						</div>
																					</div>
																				</div>

																				<div class="col-md-3">
																					<div class="form-group">
																					  <label for="exampleInputPassword1">Time of Hearing  </label>
																					  <select class="form-control form-select" id="phTime" formControlName="phTime">
																						<option [value]="null">-- Select --</option>
																						<ng-container *ngFor="let obj of modeOfTime">
																						  <option [value]="obj.value">{{obj.key}}</option>
																						</ng-container>
																					  </select>
																					</div>
																				  </div>
						   
																				 <div class="col-md-3">
																					<div class="form-group">
																					<label for="exampleInputPassword1">Mode of Hearing  </label>
																					<select class="form-control form-select" id="gstin" formControlName="mode">
																					   <option [value]="null">-- Select --</option>
																					   <ng-container *ngFor="let obj of modeOfPH">
																						  <option [value]="obj.value">{{obj.key}}</option>
																					   </ng-container>
																					</select>
																					</div>
																				 </div>

																				 <div class="col-md-3">
																					<div class="form-group">
																						<label>Attended</label>
																						<select
																							class="form-control form-select"
																							id="subMode"
																							(change)="checkHearingAttended(i)"
																							formControlName="attended">
																							<option [value]="null">--
																								Select --</option>
																							<ng-container
																								*ngFor="let obj of yesNoData">
																								<option
																									(change)="checkHearingAttended(i)"
																									[value]="obj.value">
																									{{ obj.label }}
																								</option>
																							</ng-container>
																						</select>
																					</div>
																				</div>
																				<div class="col-md-3">
																					<div class="form-group">
																						<label>Adjourned/ New Hearing</label>
																						<select
																							class="form-control form-select"
																							id="subMode"
																							(change)="getNewHearing(i)"
																							formControlName="adjournedOrNot">
																							<option [value]="null">
																								--Select --</option>
																							<ng-container
																								*ngFor="let obj of yesNoData">
																								<option
																									[value]="obj.value">
																									{{ obj.label }}
																								</option>
																							</ng-container>
																						</select>
																					</div>
																				</div>

																				<div class="col-md-3">
																					<div class="form-group">
																						<label for="exampleInputPassword1">Adjournment Letter</label>
																						<input type="file"
																							class="file-upload-default"
																							#file>
																						<div class="attachment-block">
																							<span
																								(click)="openAdjournment(phAdjournmentLetterLoc,i)">
																								<i class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i><span
																									*ngIf="getPhAdjournmentLetterLocLength(i) != 0">{{getPhAdjournmentLetterLocLength(i)}}</span><span
																									*ngIf="getPhAdjournmentLetterLocLength(i) != 0">
																									Attachment</span></span>
																						</div>
																					</div>
																				</div>

																				<ng-template #phAdjournmentLetterLoc let-modal>
																					<div class="modal-header"style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th	style="width:50%;">	Remark (Max	100 Character allowed)</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let getphAdjournmentLetterLocGrp of getphAdjournmentLetterLoc(i); let j = index"
																										[formGroup]="getphAdjournmentLetterLocGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9">
																													<div
																														class="form-group">
																														<input
																															*ngIf="getphAdjournmentLetterLocGrp.value.isdocLocUploadedClicked"
																															type="file"
																															id="hearingDtls({{i}}).phAdjournmentLetterLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'hearingDtls',firstIndex: i,second:'phAdjournmentLetterLoc',secondIndex:j})">
																														<p *ngIf="!getphAdjournmentLetterLocGrp.value.isdocLocUploadedClicked || getphAdjournmentLetterLocGrp.value.fileLoc"
																															class="form-control pt10"
																															[title]="getphAdjournmentLetterLocGrp.value.fileName ? getphAdjournmentLetterLocGrp.value.fileName : ''">
																															{{getFileNameDta(getphAdjournmentLetterLocGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label>
																														<a class="downupIcon"
																															*ngIf="(getphAdjournmentLetterLocGrp.value.fileLoc)"
																															title="Download file"
																															(click)="download(getphAdjournmentLetterLocGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!(getphAdjournmentLetterLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploadphAdjournmentLetterLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deletephAdjournmentLetterLoc(i,j)"
																															*ngIf="(getphAdjournmentLetterLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td> <input
																												type="text"
																												class="form-control"
																												placeholder=""
																												id="remarks"
																												formControlName="remarks"
																												[title]="getphAdjournmentLetterLocGrp.value.remarks ? getphAdjournmentLetterLocGrp.value.remarks : ''" />
																											<div
																												*ngIf="getphAdjournmentLetterLocGrp.value.remarks && getphAdjournmentLetterLocGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark Max character Length 100</span>
																											</div>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>
																				
																				<div class="col-md-3">
																					<div class="form-group">
																						<label>Date of Additional
																							Written Submissions</label>
																						<div id="dtReviseDue"
																							class="input-group date datepicker">
																							<p-calendar
																								class="form-control"
																								[monthNavigator]="true"
																								formControlName="addWrittenSubDate"
																								[yearNavigator]="true"
																								yearRange="2010:{{currentYear}}"
																								dateFormat="dd-mm-yy"
																								[showIcon]="true"
																								dataType="string"
																								[maxDate]="maxDate">
																							</p-calendar>
																						</div>
																					</div>
																				</div>
																				<div class="col-md-3">
																					<div class="form-group">
																						<label
																							for="exampleInputPassword1">Upload
																							the
																							Additional Submission
																						</label>
																						<input type="file"
																							class="file-upload-default"
																							#file>
																						<div class="attachment-block">
																							<span
																								(click)="openAdditionalSub(addSubLoc,i)">
																								<i class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i><span
																									*ngIf="getAddSubmmissionLength(i) != 0">{{getAddSubmmissionLength(i)}}</span><span
																									*ngIf="getAddSubmmissionLength(i) != 0">
																									Attachment</span></span>
																						</div>



																					</div>
																				</div>

																				<ng-template #addSubLoc let-modal>
																					<div class="modal-header"
																						style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th
																											style="width:50%;">
																											Remark (Max
																											100
																											Character
																											allowed)
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let getaddSubLocGrp of getaddSubLoc(i); let j = index"
																										[formGroup]="getaddSubLocGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9">
																													<div
																														class="form-group">
																														<input
																															*ngIf="getaddSubLocGrp.value.isdocLocUploadedClicked"
																															type="file"
																															id="hearingDtls({{i}}).addSubLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'hearingDtls',firstIndex: i,second:'addSubLoc',secondIndex:j})">
																														<p *ngIf="!getaddSubLocGrp.value.isdocLocUploadedClicked || getaddSubLocGrp.value.fileLoc"
																															class="form-control pt10"
																															[title]="getaddSubLocGrp.value.fileName ? getaddSubLocGrp.value.fileName : ''">
																															{{getFileNameDta(getaddSubLocGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label>
																														<a class="downupIcon"
																															*ngIf="(getaddSubLocGrp.value.fileLoc)"
																															title="Download file"
																															(click)="download(getaddSubLocGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!(getaddSubLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploadaddSubLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deleteaddSubLoc(i,j)"
																															*ngIf="(getaddSubLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td> <input
																												type="text"
																												class="form-control"
																												placeholder=""
																												id="remarks"
																												formControlName="remarks"
																												[title]="getaddSubLocGrp.value.remarks ? getaddSubLocGrp.value.remarks : ''" />
																											<div
																												*ngIf="getaddSubLocGrp.value.remarks && getaddSubLocGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark
																													Max
																													character
																													Length
																													100</span>
																											</div>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>

																				<!-- <div class="col-md-3">
																					<div class="form-group">
																						<label>Consultant</label>
																						<input type="text"
																							formControlName="consultant"
																							class="form-control"
																							id="delayReq">
																					</div>
																				</div> -->

																				<div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
																					<div class="form-group">
																					  <label for="exampleInputPassword1">Consultant</label>
																				  
																					  <!-- For Consultant Role: Create -->
																					  <div *ngIf="roleName === 'Consultant' && action === 'create'">
																					   <p>{{roleName}}--{{action}}</p>
																						<input 
																						  type="text" 
																						  class="form-control" 
																						  formControlName="consultant" 
																						  [readOnly]="action === 'create'">
																					  </div>
																				  
																					  <!-- For Non-Consultant Role -->
																					  <div *ngIf="roleName !== 'Consultant'">
																						<div *ngIf="isToggleConsultantDropdown2 && (action === 'update' || action === 'view')" class="input-group">
																						  <input 
																							type="text" 
																							class="form-control" 
																							placeholder="" 
																							formControlName="consultant" 
																							readonly>
																						  <button 
																							*ngIf="isToggleConsultantDropdown2 && action === 'update'" 
																							class="btn btn-outline-secondary" 
																							type="button" 
																							(click)="onClickToggleConsultantDropdown('p2')">
																							<i class="fa fa-pencil"></i>
																						  </button>
																						</div>
																				  
																						<select 
																						  class="form-control form-select" 
																						  id="consultant" 
																						  *ngIf="!isToggleConsultantDropdown2 || action === 'create'" 
																						  formControlName="consultant">
																						  <option [value]="null">-- Select --</option>
																						  <ng-container *ngFor="let obj of consultantList">
																							<option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
																						  </ng-container>
																						</select>
																					  </div>
																					</div>
																				 </div>

																				<div class="col-md-3">
																					<div class="form-group">
																						<label
																							for="exampleInputPassword1">Hearing
																							Record, If
																							Any </label>
																						<input type="file"
																							class="file-upload-default"
																							#file>
																						<div class="attachment-block">
																							<span
																								(click)="openphRecordIfAnyLoc(phRecordLocPop,i)">
																								<i class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i><span
																									*ngIf="getHearingRecordLength(i) != 0">{{getHearingRecordLength(i)}}</span><span
																									*ngIf="getHearingRecordLength(i) != 0">
																									Attachment</span></span>
																						</div>


																					</div>
																				</div>
																				<div>
																					<ng-template #phRecordLocPop
																						let-modal>
																						<div class="modal-header"
																							style="border: none; padding-bottom: 0px;">
																							<button type="button"
																								class="close"
																								aria-label="Close"
																								(click)="modal.dismiss('Cross click')">
																								<span
																									aria-hidden="true">×</span>
																							</button>
																						</div>
																						<div class="col-md-12">
																							<label> Attachment</label>
																							<div
																								class="table-responsive">
																								<table
																									class="table table-bordered">
																									<thead>
																										<tr
																											class="bgGred">
																											<th>Upload
																											</th>
																											<th
																												style="width:50%;">
																												Remark
																												(Max 100
																												Character
																												allowed)
																											</th>
																										</tr>
																									</thead>
																									<tbody>
																										<tr *ngFor="let phRecordLocGrp of getphRecordIfAnyLoc(i); let j = index"
																											[formGroup]="phRecordLocGrp">
																											<td>
																												<div
																													class="row">
																													<div
																														class="col-md-9 nopadding">
																														<div
																															class="form-group">
																															<input
																																*ngIf="phRecordLocGrp.value.isdocLocUploadedClicked"
																																type="file"
																																id="hearingDtls({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
																																class="filetype form-control"
																																name="myfile"
																																(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'hearingDtls',firstIndex: i,second:'phRecordIfAnyLoc',secondIndex:j})">
																															<p *ngIf="!phRecordLocGrp.value.isdocLocUploadedClicked || phRecordLocGrp.value.fileLoc"
																																class="form-control pt10"
																																[title]="phRecordLocGrp.value.fileName ? phRecordLocGrp.value.fileName : ''">
																																{{getFileNameDta(phRecordLocGrp.value.fileLoc)}}
																															</p>
																														</div>
																													</div>
																													<div
																														class="col-md-3 nopadding">
																														<label>
																															<a class="downupIcon"
																																*ngIf="(phRecordLocGrp.value.fileLoc)"
																																title="Download file"
																																(click)="download(phRecordLocGrp.value.fileLoc)"><i
																																	class="fa fa-download"></i></a>
																															<a class="downupIcon"
																																*ngIf="!(phRecordLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																																title="Upload file"
																																(click)="uploadphRecordIfAnyLoc(i,j)"><i
																																	class="fa fa-upload"></i></a>
																															<a class="downupIcon"
																																(click)="deletephRecordIfAnyLoc(i,j)"
																																*ngIf="(phRecordLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																	class="fa fa-trash"
																																	aria-hidden="true"></i></a>
																														</label>
																													</div>
																												</div>
																											</td>
																											<td> <input
																													type="text"
																													class="form-control"
																													placeholder=""
																													id="remarks"
																													formControlName="remarks"
																													[title]="phRecordLocGrp.value.remarks ? phRecordLocGrp.value.remarks : ''" />
																												<div
																													*ngIf="phRecordLocGrp.value.remarks && phRecordLocGrp.value.remarks.length > 100">
																													<span
																														class="text-danger">Remark
																														Max
																														character
																														Length
																														100</span>
																												</div>
																											</td>
																										</tr>
																									</tbody>
																								</table>
																							</div>
																						</div>
																						<div class="modal-footer"
																							style="border: none; padding-top: 0px;">
																							<button type="button"
																								class="btn btn-outline-dark"
																								(click)="modal.close('Save click')">Save</button>
																						</div>
																					</ng-template>
																				</div>
																				<div class="col-md-12">
																					<div class="form-group">
																						<label>Hearing Discussion
																							Notes</label>
																						<textarea id="exampleTextarea1"
																							rows="4"
																							[ngClass]="{errorBorder : (hearing.controls.phDiscNotes.value && hearing.controls.phDiscNotes.value.length > 1000)}"
																							formControlName="phDiscNotes"
																							class="form-control ng-pristine ng-valid ng-touched"></textarea>
																					</div>
																					<div
																						*ngIf="(hearing.controls.phDiscNotes.value && hearing.controls.phDiscNotes.value.length > 1000)">
																						<span class="text-danger">Max
																							1000 Characters</span>
																					</div>
																				</div>
																				<div class="col-md-12 mt15 mb-3">
																					<button
																						class="btn btn-outline-primary mr-1"
																						(click)="nextFormHearingDetails()">Next</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</ng-container>
												</div>
											</div>									
										</fieldset>
									</div>
									<div class="tab-pane fade" id="aasd-2" role="tabpanel"
										[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="aasd-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="col-md-12 nopadding">
												<p class="textCenter">Summon Details</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>
											<div formArrayName="summonDtls">
												<div class="col-md-12 noPadding">
													<span *ngIf="phase2Form.get('summonDtls')">
														<ul class="nav nav-pills iris-gst-display-flex" role="tablist">

															<li class="nav-item"
																*ngFor="let smdtls of phase2Form.get('summonDtls').controls; let i = index"
																[formGroupName]="i">
																<a class="nav-link" id="adjudph11-tab" data-toggle="tab"
																	href="#adjudph1-11" role="tab"
																	aria-controls="adjudph1-11"
																	(click)="summonPhaseChanges('phase' + i, i)"
																	[ngClass]="(selectedSmIndex === 'phase' + i) ? 'active': ''"
																	aria-selected="true">SM
																	{{i + 1}} <span (click)="removeSummon(i)"
																		*ngIf="i != 0" class="closeH"><i
																			class="fa fa-close"></i></span></a>
															</li>

														</ul>
													</span>
													<ng-container *ngIf="phase2Form.get('summonDtls')">
														<div *ngFor="let smdtls of phase2Form.get('summonDtls').controls; let i = index"
															[formGroupName]="i">
															<div class="tab-content nopadding">
																<div class="tab-pane fade show active" id="adjudph11-1"
																	role="tabpanel" aria-labelledby="adjudph11-tab">
																	<div *ngIf="selectedSmIndex === 'phase' + i">
																		<div class=" ml5">
																			<div class="row">
																				<div class="col-md-3">
																					<div class="form-group">
																						<label>Date of Summon
																							Scheduled</label>
																						<div id="dtReviseDue"
																							class="input-group date datepicker">
																							<p-calendar
																								class="form-control"
																								[monthNavigator]="true"
																								formControlName="summonDate"
																								[yearNavigator]="true"
																								yearRange="2010:{{currentYear}}"
																								dateFormat="dd-mm-yy"
																								[showIcon]="true"
																								dataType="string">
																							</p-calendar>
																						</div>
																					</div>
																				</div>
																				<div class="col-md-3">
																					<div class="form-group">
																						<label
																							for="exampleInputPassword1">Notice
																							for
																							Summon</label>
																						<div class="attachment-block">
																							<span
																								(click)="openSummonNotice(summonNoticeLoc,i)">
																								<i class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i><span
																									*ngIf="getSmNoticeCopyLength(i) != 0">{{getSmNoticeCopyLength(i)}}</span><span
																									*ngIf="getSmNoticeCopyLength(i) != 0">
																									Attachment</span></span>
																						</div>


																					</div>
																				</div>

																				<ng-template #summonNoticeLoc let-modal>
																					<div class="modal-header"
																						style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th
																											style="width:50%;">
																											Remark (Max
																											100
																											Character
																											allowed)
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let smNoticeLocGrp of getSummonNoticeLoc(i); let j = index"
																										[formGroup]="smNoticeLocGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9 nopadding">
																													<div
																														class="form-group">
																														<input
																															*ngIf="smNoticeLocGrp.value.isdocLocUploadedClicked"
																															type="file"
																															id="summonDtls({{i}}).summonNoticeLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'summonDtls',firstIndex: i,second:'summonNoticeLoc',secondIndex:j})">
																														<p *ngIf="!smNoticeLocGrp.value.isdocLocUploadedClicked || smNoticeLocGrp.value.fileLoc"
																															class="form-control pt10"
																															[title]="smNoticeLocGrp.value.fileName ? smNoticeLocGrp.value.fileName : ''">
																															{{getFileNameDta(smNoticeLocGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label>
																														<a class="downupIcon"
																															*ngIf="(smNoticeLocGrp.value.fileLoc)"
																															title="Download file"
																															(click)="download(smNoticeLocGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!(smNoticeLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploadSmNoticeLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deleteSmNoticeLoc(i,j)"
																															*ngIf="(smNoticeLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td> <input
																												type="text"
																												class="form-control"
																												placeholder=""
																												id="remarks"
																												formControlName="remarks"
																												[title]="smNoticeLocGrp.value.remarks ? smNoticeLocGrp.value.remarks : ''" />
																											<div
																												*ngIf="smNoticeLocGrp.value.remarks && smNoticeLocGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark
																													Max
																													character
																													Length
																													100</span>
																											</div>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>

																				<div class="col-md-3">
																					<div class="form-group">
																						<label>Adjourned/ New
																							Summon</label>
																						<select
																							class="form-control form-select"
																							id="subMode"
																							(change)="getNewSummon(i)"
																							formControlName="adjournedOrNot">
																							<option [value]="null">--
																								Select --</option>
																							<ng-container
																								*ngFor="let obj of yesNoData">
																								<option
																									(change)="getNewSummon(i)"
																									[value]="obj.value">
																									{{ obj.label }}
																								</option>
																							</ng-container>
																						</select>
																					</div>
																				</div>
																				<!-- <div class="col-md-3">
                                            <div class="form-group">
                                               <label >Summon Issued </label>
                                                  <select class="form-control form-select" id="isSummonIssued"  (change)="summonIssue(i)" formControlName="isSummonIssued">
                                                    <option [value]="null">-- Select --</option>
                                                    <ng-container *ngFor="let obj of yesNoData">
                                                       <option  (change)="summonIssue(i)" [value]="obj.value">{{ obj.label }}</option>
                                                    </ng-container>
                                                 </select>
                                            </div>
                                         </div> -->
																				<div class="col-md-3">
																					<div class="form-group">
																						<label>Attended</label>
																						<select
																							class="form-control form-select"
																							id="subMode"
																							(change)="checkSummonAttended(i)"
																							formControlName="attended">
																							<option [value]="null">--
																								Select --</option>
																							<ng-container
																								*ngFor="let obj of yesNoData">
																								<option
																									(change)="checkSummonAttended(i)"
																									[value]="obj.value">
																									{{ obj.label }}
																								</option>
																							</ng-container>
																						</select>
																					</div>
																				</div>
																				<div class="col-md-3">
																					<div class="form-group">
																						<label>Person Who
																							Attended</label>
																						<input type="text"
																							formControlName="personWhoAttended"
																							class="form-control"
																							id="delayReq">
																					</div>
																				</div>
																				<div class="col-md-3">
																					<div class="form-group">
																						<label>Date of Additional
																							Written Submissions</label>
																						<div id="dtReviseDue"
																							class="input-group date datepicker">
																							<p-calendar
																								class="form-control"
																								[monthNavigator]="true"
																								formControlName="addWritSubDate"
																								[yearNavigator]="true"
																								yearRange="2010:{{currentYear}}"
																								dateFormat="dd-mm-yy"
																								[showIcon]="true"
																								dataType="string"
																								[maxDate]="maxDate">
																							</p-calendar>
																						</div>
																					</div>
																				</div>
																				<div class="col-md-3">
																					<div class="form-group">
																						<label
																							for="exampleInputPassword1">Upload
																							the
																							Additional
																							Submission</label>
																						<div class="attachment-block">
																							<span
																								(click)="openAdditionalSummonSub(summonAddSubLoc,i)">
																								<i class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i><span
																									*ngIf="getSmAddSubmmissionLength(i) != 0">{{getSmAddSubmmissionLength(i)}}</span><span
																									*ngIf="getSmAddSubmmissionLength(i) != 0">
																									Attachment</span></span>
																						</div>

																					</div>
																				</div>

																				<ng-template #summonAddSubLoc let-modal>
																					<div class="modal-header"
																						style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th
																											style="width:50%;">
																											Remark (Max
																											100
																											Character
																											allowed)
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let summonAdSubGrp of getSummonaddSubLoc(i); let j = index"
																										[formGroup]="summonAdSubGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9 nopadding">
																													<div
																														class="form-group">
																														<input
																															*ngIf="summonAdSubGrp.value.isdocLocUploadedClicked"
																															type="file"
																															id="summonDtls({{i}}).addSubLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'summonDtls',firstIndex: i,second:'addSubLoc',secondIndex:j})">
																														<p *ngIf="!summonAdSubGrp.value.isdocLocUploadedClicked || summonAdSubGrp.value.fileLoc"
																															class="form-control pt10"
																															[title]="summonAdSubGrp.value.fileName ? summonAdSubGrp.value.fileName : ''">
																															{{getFileNameDta(summonAdSubGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label>
																														<a class="downupIcon"
																															*ngIf="(summonAdSubGrp.value.fileLoc)"
																															title="Download file"
																															(click)="download(summonAdSubGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!(summonAdSubGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploadSummonAddSubLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deleteSummonAddSubLoc(i,j)"
																															*ngIf="(summonAdSubGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td> <input
																												type="text"
																												class="form-control"
																												placeholder=""
																												id="remarks"
																												formControlName="remarks"
																												[title]="summonAdSubGrp.value.remarks ? summonAdSubGrp.value.remarks : ''" />
																											<div
																												*ngIf="summonAdSubGrp.value.remarks && summonAdSubGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark
																													Max
																													character
																													Length
																													100</span>
																											</div>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>

																				<div class="col-md-3">
																					<div class="form-group">
																						<label
																							for="exampleInputPassword1">Summon
																							Record
																							Received From Dept. </label>
																						<div class="attachment-block">
																							<span
																								(click)="openSummonRecordIfAnyLoc(summonDeptRecordLoc,i)">
																								<i class="icon fa fa fa-paperclip"
																									id="paperclip"
																									title="Attach file"></i><span
																									*ngIf="getSmRecordLength(i) != 0">{{getSmRecordLength(i)}}</span><span
																									*ngIf="getSmRecordLength(i) != 0">
																									Attachment</span></span>
																						</div>

																					</div>
																				</div>

																				<ng-template #summonDeptRecordLoc
																					let-modal>
																					<div class="modal-header"
																						style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th
																											style="width:50%;">
																											Remark (Max
																											100
																											Character
																											allowed)
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let summonDeptRecordGrp of getSummonRecordIfAnyLoc(i); let j = index"
																										[formGroup]="summonDeptRecordGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9 nopadding">
																													<div
																														class="form-group">
																														<input
																															*ngIf="summonDeptRecordGrp.value.isdocLocUploadedClicked"
																															type="file"
																															id="summonDtls({{i}}).deptSummonReceivedLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,i,{form:'phase2Form',first : 'summonDtls',firstIndex: i,second:'deptSummonReceivedLoc',secondIndex:j})">
																														<p *ngIf="!summonDeptRecordGrp.value.isdocLocUploadedClicked || summonDeptRecordGrp.value.fileLoc"
																															class="form-control pt10"
																															[title]="summonDeptRecordGrp.value.fileName ? summonDeptRecordGrp.value.fileName : ''">
																															{{getFileNameDta(summonDeptRecordGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label>
																														<a class="downupIcon"
																															*ngIf="(summonDeptRecordGrp.value.fileLoc)"
																															title="Download file"
																															(click)="download(summonDeptRecordGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!(summonDeptRecordGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploaddeptSummonReceivedLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deletedeptSummonReceivedLoc(i,j)"
																															*ngIf="(summonDeptRecordGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td> <input
																												type="text"
																												class="form-control"
																												placeholder=""
																												id="remarks"
																												formControlName="remarks"
																												[title]="summonDeptRecordGrp.value.remarks ? summonDeptRecordGrp.value.remarks : ''" />
																											<div
																												*ngIf="summonDeptRecordGrp.value.remarks && summonDeptRecordGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark
																													Max
																													character
																													Length
																													100</span>
																											</div>
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>

																				<div class="col-md-12">
																					<div class="form-group">
																						<label>PH Discussion
																							Notes</label>
																						<textarea id="exampleTextarea1"
																							rows="4"
																							formControlName="discNotes"
																							[ngClass]="{errorBorder : (smdtls.value.discNotes && smdtls.value.discNotes.length > 1000)}"
																							class="form-control ng-pristine ng-valid ng-touched"></textarea>
																					</div>
																					<div
																						*ngIf="(smdtls.value.discNotes && smdtls.value.discNotes.length > 1000)">
																						<span class="text-danger">Max
																							1000 Characters</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</ng-container>
												</div>
											</div>
											<div class="col-md-12 noPadding mt15 mb-3">
												<button class="btn btn-outline-primary mr-1"
													(click)="nextForSummonDetails()">Next</button>
											</div>
										</fieldset>
									</div>
									<div class="tab-pane fade" id="aatcc-2" role="tabpanel"
										[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="aatcc-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="col-md-12 nopadding">
												<p class="textCenter">Consultant Charges</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>
											<div formArrayName="consultChargesDtls">
												<div class="col-md-12 nopadding">

													<div>
														<div class="row">
															<div class="table-responsive">
																<table class="table table-bordered">
																	<thead class="bgGred">
																		<tr>
																			<th class="sl-no-cls">Sr.No.</th>
																			<th style="min-width: 210px">Name of the
																				Consultant
																			</th>
																			<th style="min-width: 100px">Name of
																				Consulting Firm 
																			</th>
																			<th>Nature of Work</th>
																			<th>Fees Charged(₹)</th>
																			<th>Supporting Documents</th>
																			<th style="min-width: 250px">Email id</th>
																			<th style="min-width: 250px">Contact Number
																			</th>
																			<th class="sl-no-cls">Action</th>
																		</tr>
																	</thead>
																	<tbody>
																		<ng-container
																			*ngIf="phase2Form.get('consultChargesDtls')">
																			<tr *ngFor="let consult of phase2Form.get('consultChargesDtls').controls; let i = index"
																				[formGroupName]="i">
																				<td class="w-40">{{i+1}}</td>
																				<td>
																					<!-- <input type="text"
																					formControlName="consultant"
																					class="form-control"
																					[title]="consult.value.consultant ? consult.value.consultant: ''"> -->
																					<div *ngIf="isToggleConsultantDropdownCC && action==='update' || action==='view'" class="input-group">
																						<input type="text" class="form-control"
																						  *ngIf="isToggleConsultantDropdownCC && action==='update' || action==='view'" placeholder=""
																						  formControlName="consultant" readonly>
																						<button *ngIf="isToggleConsultantDropdownCC && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('CC')">
																						  <i class="fa fa-pencil"></i>
																						</button> 
																					  </div>
																			  
																						<select class="form-control form-select" id="consultant"
																						  *ngIf="!isToggleConsultantDropdownCC || action==='create'" formControlName="consultant" (change)="consultantDataAutofil(i)">
																						  <option [value]="null">-- Select --</option>
																						  <ng-container *ngFor="let obj of consultantList">
																						  <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
																						  </ng-container>
																						</select>
																				</td>
																				<td>
																					<input type="text"
																					formControlName="firm"
																					class="form-control"
																					[title]="consult.value.firm ? consult.value.firm: ''"
																					readonly style="cursor: not-allowed;">
																				</td>
																				<td><input type="text"
																						formControlName="natureOfWork"
																						[ngClass]="{errorBorder : consult.controls.natureOfWork.value && consult.controls.natureOfWork.value.length > 100}"
																						class="form-control"
																						[title]="consult.value.natureOfWork ? consult.value.natureOfWork: ''">
																					<div
																						*ngIf="consult.controls.natureOfWork.value && consult.controls.natureOfWork.value.length > 100">
																						<span class="text-danger"
																							*ngIf="consult.controls.natureOfWork.value && consult.controls.natureOfWork.value.length > 100">
																							Max character Length
																							100</span>
																					</div>
																				</td>
																				<td><input type="text"
																						formControlName="fees"
																						(input)="onInputChange(consult.get('fees')?.value,i,'fees')"
																						class="form-control align-right">
																				</td>
																				<td><span
																						(click)="openConsultModalpop(supportingDocLocGrp, i)"><i
																							class="icon fa fa fa-paperclip"
																							id="paperclip"
																							title="Attach file"></i></span><span
																						*ngIf="getSupportingDocLocLength(i) !== 0">{{getSupportingDocLocLength(i)}}</span><span
																						*ngIf="getSupportingDocLocLength(i) !== 0">
																						Attachment</span></td>
																				<td><input type="text"
																						formControlName="email"
																						class="form-control"
																						[title]="consult.value.email ? consult.value.email : ''" readonly style="cursor: not-allowed;">
																				</td>
																				<td><input type="text"
																						formControlName="contact"
																						class="form-control" 
																						[title]="consult.value.contact ? consult.value.contact : ''" readonly style="cursor: not-allowed;">
																				</td>
																				<td class="w-80">
																					<a class="addExtLetter"
																						*ngIf="(phase2Form.get('consultChargesDtls').controls.length - 1) == i"
																						(click)="addConusltCharges()"><i
																							class="fa fa-plus"
																							title="Add row"></i>
																					</a>
																					<a *ngIf="i != 0"
																						class="addExtLetter"
																						(click)="openconsultRemove(consultRemovePop)"><i
																							class="fa fa-minus"></i></a>

																				</td>
																				<ng-template #consultRemovePop
																					let-modal>
																					<div class="modal-header">
																						<h4 class="modal-title"
																							id="modal-basic-title">
																							Remove
																							Warning! </h4>
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="modal-body">
																						<p>Are you sure, you want to
																							remove?</p>
																					</div>
																					<div class="modal-footer">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="removeConsultCharges(i)">Remove</button>
																					</div>
																				</ng-template>
																				<ng-template #supportingDocLocGrp
																					let-modal>
																					<div class="modal-header"
																						style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th
																											style="width:50%;">
																											Remark (Max
																											100
																											Character
																											allowed)
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let supportingDocGrp of getSupportingDocLocControls(i); let j = index"
																										[formGroup]="supportingDocGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9 ">
																													<div
																														class="form-group">
																														<input
																															*ngIf="supportingDocGrp?.value?.isdocLocUploadedClicked"
																															type="file"
																															id="consultChargesDtls({{i}}).supportingDocsLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,j,{form:'phase2Form',first : 'consultChargesDtls',second:'supportingDocsLoc',secondIndex : j,firstIndex : i})">
																														<p *ngIf="!supportingDocGrp?.value?.isdocLocUploadedClicked || supportingDocGrp?.value.fileLoc"
																															class="form-control pt10">
																															{{getFileNameDta(supportingDocGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label
																														for="exampleInputPassword1">
																														<a class="downupIcon"
																															*ngIf="supportingDocGrp?.value.fileLoc"
																															title="Download file"
																															(click)="download(supportingDocGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!supportingDocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploadSupportingDocLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deleteSupportingDocLoc(i,j)"
																															*ngIf="(supportingDocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td>
																											<input
																												type="text"
																												class="form-control"
																												formControlName="remarks"
																												[title]="supportingDocGrp.value.remarks ? supportingDocGrp.value.remarks : ''">
																											<div
																												*ngIf="supportingDocGrp.value.remarks && supportingDocGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark
																													Max
																													character
																													Length
																													100</span>
																											</div>
																										</td>
																										<ng-template
																											#rmitrFPop
																											let-modal>
																											<div
																												class="modal-header">
																												<h4 class="modal-title"
																													id="modal-basic-title">
																													Remove
																													Warning!
																												</h4>
																												<button
																													type="button"
																													class="close"
																													aria-label="Close"
																													(click)="modal.dismiss('Cross click')">
																													<span
																														aria-hidden="true">×</span>
																												</button>
																											</div>
																											<div
																												class="modal-body">
																												<p>Are
																													you
																													sure,
																													you
																													want
																													to
																													remove?
																												</p>
																											</div>
																											<div
																												class="modal-footer">
																												<button
																													type="button"
																													class="btn btn-outline-dark">Remove</button>
																											</div>
																										</ng-template>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>
																			</tr>

																		</ng-container>
																	</tbody>
																	<ng-container>
																		<tr>
																			<th colspan="4" class="total-titel">Total
																			</th>

																			<td><input type="text"
																					class="form-control bold-font align-right"
																					[value]="calculateTotalFees().totalFees"
																					id="fees" placeholder="" readonly>
																			</td>
																			<th colspan="4" class="total-titel">&nbsp;
																			</th>

																		</tr>
																	</ng-container>
																</table>
															</div>
														</div>
													</div>

												</div>
											</div>
											<div class="col-md-12 noPadding mt15 mb-3">
												<button class="btn btn-outline-primary mr-1"
													(click)="nextForConsultDetails()">Next</button>
											</div>
										</fieldset>
									</div>
									<div class="tab-pane fade" id="aacon-2" role="tabpanel"
										[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="aacon-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="col-md-12 nopadding">
												<p class="textCenter">Abeyance/ Remand Back</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>


											<div formGroupName="abeyaneRemandBackDtls">
												<div class="row mt5">
													<div class="col-md-3">
														<div class="form-group">
															<label>Whether the Case Is In Abeyance </label>
															<select class="form-control form-select" id="isAbeyance"
																(change)="isAbeyen()" formControlName="isAbeyance">
																<option [value]="null">-- Select --</option>
																<ng-container *ngFor="let obj of yesNoData">
																	<option [value]="obj.value">{{ obj.label }}</option>
																</ng-container>
															</select>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label>Whether the Case is Remanded Back</label>
															<select class="form-control form-select" id="isRemandedBack"
																(change)="isCaseRemandBack()"
																formControlName="isRemandedBack">
																<option [value]="null">-- Select --</option>
																<ng-container *ngFor="let obj of yesNoData">
																	<option [value]="obj.value">{{ obj.label }}</option>
																</ng-container>
															</select>
														</div>
													</div>

												</div>
											</div>
											<!-- <div class="col-md-12 mt15 mb-3">
                              <button class="btn btn-outline-primary mr-1" (click)="nextForRemandBack()">Next</button>
                           </div> -->
											<div class="col-md-12 mt15 mb-3">
												<button class="btn btn-outline-primary mr-1"
													(click)="updatePhase2()">Update</button>
											</div>
										</fieldset>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tab-pane fade" id="AppelAuthp21-1" role="tabpanel" aria-labelledby="AppelAuthp21-tab">
			<div class="card borderOrange">
				<div class="card-body ptl10 plr010">
					<div class="row">
						<div class="col-2" id="verticalTab11" *ngIf="!sidemenu">
							<ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="aano-tab-vertical" data-toggle="tab" href="#aano-2"
										role="tab" aria-controls="aano-2" aria-selected="false" #remandBackPhase>
										Notice

									</a>
								</li>
								<li class="nav-item" [ngClass]="{'disabled' : !isNoticeNextSubmmited}">
									<a class="nav-link" id="aaSub-tab-vertical" data-toggle="tab" href="#aaSub-2"
										role="tab" aria-controls="aaSub-2" aria-selected="false"
										(click)="submissionValidation('submission')" #submission>
										Submission

									</a>
								</li>

								<li class="nav-item" [ngClass]="{'disabled' : !isNoticeNextSubmmited}">
									<a class="nav-link" id="aaR-tab-vertical" data-toggle="tab" href="#aaR-2" role="tab"
										aria-controls="aaR-2" aria-selected="false" #remand>
										Remand Report

									</a>
								</li>
								<li class="nav-item">
									<a class="btn btn-outline-primary w100" (click)="updatePhase2()"
										[ngClass]="{'disabled': (action == 'view')}">
										Update

									</a>
								</li>


							</ul>
						</div>
						<div class="col-10 border " id="tabArea11" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
							<form [formGroup]="remandBackForm">
								<div class="tab-content tab-content-vertical">
									<div class="tab-pane fade show active" id="aano-2"
										[ngClass]="{'bgGray': (action == 'view')}" role="tabpanel"
										aria-labelledby="aano-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="row">
												<div class="col-md-12">
													<p class="textCenter">Remand Back Notice </p>
													<span class="toggleTab" (click)="sidemenu=!sidemenu">
														<i class="mdi mdi mdi-chevron-double-left leftTIcon"
															*ngIf="!sidemenu"></i>
														<i class="mdi mdi-chevron-double-right rightTIcon"
															*ngIf="sidemenu"></i>
													</span>
												</div>
												<div class="row" formGroupName="authDtls">
													<div class="col-md-3">
														<div class="form-group">
															<label>Name of
																Authority</label>
															<input type="text" formControlName="authName"
																[ngClass]="{errorBorder : remandBackForm.get('authDtls')?.get('authName')?.value && remandBackForm.get('authDtls')?.get('authName')?.value.length > 100}"
																[title]="remandBackForm.get('authDtls')?.get('authName').value ? remandBackForm.get('authDtls')?.get('authName').value : ''"
																class="form-control">
														</div>
														<div
															*ngIf="remandBackForm.get('authDtls')?.get('authName')?.value && remandBackForm.get('authDtls')?.get('authName')?.value.length > 100">
															<span class="text-danger"
																*ngIf="remandBackForm.get('authDtls')?.get('authName')?.value && remandBackForm.get('authDtls')?.get('authName')?.value.length > 100">
																Max Length is 100 character </span>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label>Designation of
																Authority </label>
															<input type="text" formControlName="authDesigation"
																[ngClass]="{errorBorder : remandBackForm.get('authDtls')?.get('authDesigation')?.value && remandBackForm.get('authDtls')?.get('authDesigation')?.value.length > 100}"
																[title]="remandBackForm.get('authDtls')?.get('authDesigation')?.value ? remandBackForm.get('authDtls')?.get('authDesigation')?.value : ''"
																class="form-control">
														</div>
														<div
															*ngIf="remandBackForm.get('authDtls')?.get('authDesigation')?.value && remandBackForm.get('authDtls')?.get('authDesigation')?.value.length > 100">
															<span class="text-danger"
																*ngIf="remandBackForm.get('authDtls')?.get('authDesigation')?.value && remandBackForm.get('authDtls')?.get('authDesigation')?.value.length > 100">
																Max Length is 100 character </span>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label>Jurisdiction
																Details </label>
															<input type="text" formControlName="jurisdiction"
																[ngClass]="{errorBorder : remandBackForm.get('authDtls')?.get('jurisdiction')?.value && remandBackForm.get('authDtls')?.get('jurisdiction')?.value.length > 100}"
																[title]="remandBackForm.get('authDtls')?.get('jurisdiction')?.value ? remandBackForm.get('authDtls')?.get('jurisdiction')?.value : ''"
																class="form-control">
														</div>
														<div
															*ngIf="remandBackForm.get('authDtls')?.get('jurisdiction')?.value && remandBackForm.get('authDtls')?.get('jurisdiction')?.value.length > 100">
															<span class="text-danger"
																*ngIf="remandBackForm.get('authDtls')?.get('jurisdiction')?.value && remandBackForm.get('authDtls')?.get('jurisdiction')?.value.length > 100">
																Max Length is 100 character </span>
														</div>
													</div>
												</div>
												<div formArrayName="notices">
													<div class="col-md-12">
														<span *ngIf="remandBackForm.get('notices')">
															<ul class="nav nav-pills iris-gst-display-flex "
																role="tablist">
																<li class="nav-item"
																	*ngFor="let notice of remandBackForm.get('notices').controls; let i = index"
																	[formGroupName]="i">
																	<a class="nav-link" id="notice1-tab"
																		data-toggle="tab" href="#notice1-1" role="tab"
																		aria-controls="notice1-1"
																		(click)="noticePhaseChanged('phase' + i, i)"
																		[ngClass]="(selectedNoticeIndex === 'phase' + i) ? 'active': ''"
																		aria-selected="true">Notice {{i + 1}}</a>
																</li>
															</ul>
														</span>
														<ng-container *ngIf="remandBackForm.get('notices')">
															<div *ngFor="let notice of remandBackForm.get('notices').controls; let i = index"
																[formGroupName]="i">
																<div class="tab-content nopadding">
																	<div class="tab-pane fade show active"
																		id="notice1-1" role="tabpanel"
																		aria-labelledby="notice1-tab">
																		<div
																			*ngIf="selectedNoticeIndex === 'phase' + i">
																			<div class="ml5">
																				<div class="col-md-12 noPadding ">
																					<div class="accordion accordion-solid-header "
																						id="accordion-16"
																						role="tablist">
																						<div class="">
																							<div id="collapse-16"
																								class="collapse show"
																								role="tabpanel"
																								aria-labelledby="heading-16"
																								data-parent="#accordion-16">
																								<div
																									class="card-body noPadding">
																									<div class="row">
																										<div
																											class="col-md-3">
																											<div
																												class="form-group">
																												<label>Norice
																													Ref.
																													Number/DIN<span
																														class="mandate"><i
																															class="fa fa-asterisk"></i></span>
																												</label>
																												<input
																													type="text"
																													[ngClass]="{errorBorder : (isNoticeNextSubmmited && !notice.controls.noticeRefNo.value) || (isNewNotice && !notice.controls.noticeRefNo.value)}"
																													class="form-control"
																													formControlName="noticeRefNo">
																											</div>
																											<div class="text-danger"
																												*ngIf="(isNoticeNextSubmmited && !notice.controls.noticeRefNo.value) || (isNewNotice && !notice.controls.noticeRefNo.value)">
																												<span>Required</span>
																											</div>
																										</div>



																										<div
																											class="col-md-3">
																											<div
																												class="form-group">
																												<label>Date
																													of
																													Notice*<span
																														class="mandate"><i
																															class="fa fa-asterisk"></i></span>
																												</label>
																												<div id="dtReviseDue"
																													class="input-group date datepicker">
																													<p-calendar
																														class="form-control"
																														[monthNavigator]="true"
																														formControlName="noticeDate"
																														[yearNavigator]="true"
																														yearRange="2010:{{currentYear}}"
																														dateFormat="dd-mm-yy"
																														[showIcon]="true"
																														dataType="string"
																														[ngClass]="{errorBorder : (isNoticeNextSubmmited && !notice.controls.noticeDate.value) || (isNewNotice && !notice.controls.noticeDate.value)}"
																														[maxDate]="maxDate">
																													</p-calendar>
																												</div>
																											</div>
																											<div class="text-danger"
																												*ngIf="(isNoticeNextSubmmited && !notice.controls.noticeDate.value) || (isNewNotice && !notice.controls.noticeDate.value)">
																												<span>Required</span>
																											</div>
																										</div>

																										<div
																											class="col-md-3">
																											<div
																												class="form-group">
																												<label>Date
																													of
																													Receipt
																													of
																													Notice
																													<span
																														class="mandate"><i
																															class="fa fa-asterisk"></i></span>
																												</label>
																												<div id="dtReviseDue"
																													class="input-group date datepicker">
																													<p-calendar
																														class="form-control"
																														[monthNavigator]="true"
																														formControlName="noticeReceiptDate"
																														[yearNavigator]="true"
																														yearRange="2010:{{currentYear}}"
																														dateFormat="dd-mm-yy"
																														[showIcon]="true"
																														dataType="string"
																														[ngClass]="{errorBorder : (isNoticeNextSubmmited && !notice.controls.noticeReceiptDate.value) || (isNewNotice && !notice.controls.noticeReceiptDate.value)}"
																														[maxDate]="maxDate">
																													</p-calendar>
																												</div>
																											</div>
																											<div class="text-danger"
																												*ngIf="(isNoticeNextSubmmited && !notice.controls.noticeReceiptDate.value) || (isNewNotice && !notice.controls.noticeReceiptDate.value)">
																												<span>Required</span>
																											</div>
																										</div>
																										<div
																											class="col-md-3">
																											<div
																												class="form-group">
																												<label>Due
																													Date
																													of
																													Reply*<span
																														class="mandate"><i
																															class="fa fa-asterisk"></i></span>
																												</label>
																												<div id="dtReviseDue"
																													class="input-group date datepicker">
																													<p-calendar
																														class="form-control"
																														[monthNavigator]="true"
																														formControlName="legalDueDate"
																														[yearNavigator]="true"
																														yearRange="2010:{{currentYear}}"
																														dateFormat="dd-mm-yy"
																														[showIcon]="true"
																														dataType="string"
																														[ngClass]="{errorBorder : (isNoticeNextSubmmited && !notice.controls.legalDueDate.value)  || (isNewNotice && !notice.controls.legalDueDate.value)}">
																													</p-calendar>
																												</div>
																											</div>
																											<div class="text-danger"
																												*ngIf="(isNoticeNextSubmmited && !notice.controls.legalDueDate.value)  || (isNewNotice && !notice.controls.legalDueDate.value)">
																												<span>Required</span>
																											</div>
																										</div>
																										<div
																											class="col-md-3">
																											<div
																												class="form-group">
																												<label>Internal
																													Due
																													Date</label>
																												<div id="dtReviseDue"
																													class="input-group date datepicker">
																													<p-calendar
																														class="form-control"
																														[monthNavigator]="true"
																														formControlName="internalDueDate"
																														[yearNavigator]="true"
																														yearRange="2010:{{currentYear}}"
																														dateFormat="dd-mm-yy"
																														[showIcon]="true"
																														dataType="string">
																													</p-calendar>
																												</div>
																											</div>
																											<!-- <div class="text-danger"
																												*ngIf="(isNoticeNextSubmmited && !notice.controls.internalDueDate.value) || (isNewNotice && !notice.controls.internalDueDate.value)">
																												<span>Required</span>
																											</div> -->
																										</div>
																										<div
																											class="col-md-3">
																											<div
																												class="form-group">
																												<label
																													for="exampleInputPassword1">Copy
																													Of
																													Notice
																													<span
																														class="mandate"><i
																															class="fa fa-asterisk"></i></span>
																												</label>
																												<input
																													type="file"
																													class="file-upload-default"
																													#file>
																												<div class="attachment-block"
																													[ngClass]="{errorBorder : (isNoticeNextSubmmited && getNoticeLength(i) == 0) || (isNewNotice && getNoticeLength(i) == 0)}">
																													<span
																														(click)="openNoticeCopy(noticeCopyLoc,i)">
																														<i class="icon fa fa fa-paperclip"
																															id="paperclip"
																															title="Attach file"></i><span
																															*ngIf="(getNoticeLength(i) != 0)">{{getNoticeLength(i)}}</span><span
																															*ngIf="getNoticeLength(i) != 0">
																															Attachment</span></span>
																												</div>



																											</div>
																											<div class="text-danger"
																												*ngIf="(isNoticeNextSubmmited && getNoticeLength(i) == 0) || (isNewNotice && getNoticeLength(i) == 0)">
																												<span>Required</span>
																											</div>
																										</div>
																										<ng-template
																											#noticeCopyLoc
																											let-modal>
																											<div class="modal-header"
																												style="border: none; padding-bottom: 0px;">
																												<button
																													type="button"
																													class="close"
																													aria-label="Close"
																													(click)="modal.dismiss('Cross click')">
																													<span
																														aria-hidden="true">×</span>
																												</button>
																											</div>
																											<div
																												class="col-md-12">
																												<label>
																													Attachment</label>
																												<div
																													class="table-responsive">
																													<table
																														class="table table-bordered">
																														<thead>
																															<tr
																																class="bgGred">
																																<th>Upload
																																</th>
																																<th
																																	style="width:50%;">
																																	Remark
																																	(Max
																																	100
																																	Character
																																	allowed)
																																</th>
																															</tr>
																														</thead>
																														<tbody>
																															<tr *ngFor="let phNoticeLocGrp of getNoticeLoc(i); let j = index"
																																[formGroup]="phNoticeLocGrp">
																																<td>
																																	<div
																																		class="row">
																																		<div
																																			class="col-md-9 nopadding">
																																			<div
																																				class="form-group">
																																				<input
																																					*ngIf="phNoticeLocGrp.value.isdocLocUploadedClicked"
																																					type="file"
																																					id="notices({{i}}).noticeLoc({{j}}).fileLoc"
																																					class="filetype form-control"
																																					name="myfile"
																																					(change)="onFileSelectUpload($event,i,{form:'remandBackForm',first : 'notices',firstIndex: i,second:'noticeLoc',secondIndex:j})">
																																				<p *ngIf="!phNoticeLocGrp.value.isdocLocUploadedClicked || phNoticeLocGrp.value.fileLoc"
																																					class="form-control pt10"
																																					[title]="phNoticeLocGrp.value.fileName ? phNoticeLocGrp.value.fileName : ''">
																																					{{getFileNameDta(phNoticeLocGrp.value.fileLoc)}}
																																				</p>
																																			</div>
																																		</div>
																																		<div
																																			class="col-md-3 nopadding">
																																			<label>
																																				<a class="downupIcon"
																																					*ngIf="(phNoticeLocGrp.value.fileLoc)"
																																					title="Download file"
																																					(click)="download(phNoticeLocGrp.value.fileLoc)"><i
																																						class="fa fa-download"></i></a>
																																				<a class="downupIcon"
																																					*ngIf="!(phNoticeLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																																					title="Upload file"
																																					(click)="uploadNoticeLoc(i,j)"><i
																																						class="fa fa-upload"></i></a>
																																				<a class="downupIcon"
																																					(click)="deleteNoticeLoc(i,j)"
																																					*ngIf="(phNoticeLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																						class="fa fa-trash"
																																						aria-hidden="true"></i></a>
																																			</label>
																																		</div>
																																	</div>
																																</td>
																																<td> <input
																																		type="text"
																																		class="form-control"
																																		placeholder=""
																																		id="remarks"
																																		formControlName="remarks"
																																		[title]="phNoticeLocGrp.value.remarks ? phNoticeLocGrp.value.remarks : ''" />
																																	<div
																																		*ngIf="phNoticeLocGrp.value.remarks && phNoticeLocGrp.value.remarks.length > 100">
																																		<span
																																			class="text-danger">Remark
																																			Max
																																			character
																																			Length
																																			100</span>
																																	</div>
																																</td>
																															</tr>
																														</tbody>
																													</table>
																												</div>
																											</div>
																											<div class="modal-footer"
																												style="border: none; padding-top: 0px;">
																												<button
																													type="button"
																													class="btn btn-outline-dark"
																													(click)="modal.close('Save click')">Save</button>
																											</div>
																										</ng-template>

																										<div
																											class="col-md-12 mt15">
																											<div
																												class="form-group">
																												<label>Notice
																													Summary
																												</label>
																												<textarea
																													id="exampleTextarea1"
																													rows="4"
																													formControlName="noticeSummary"
																													[ngClass]="{errorBorder : (notice.value.noticeSummary && notice.value.noticeSummary.length > 1000)}"
																													class="form-control ng-pristine ng-valid ng-touched"></textarea>
																											</div>
																											<div
																												*ngIf="(notice.value.noticeSummary && notice.value.noticeSummary.length > 1000)">
																												<span
																													class="text-danger">Max
																													1000
																													Characters</span>
																											</div>
																										</div>
																										<div
																											class="col-md-12">
																											<div
																												class="form-group">
																												<label>Internal
																													Remarks</label>
																												<textarea
																													id="exampleTextarea1"
																													rows="4"
																													formControlName="internalRemarks"
																													[ngClass]="{errorBorder : (notice.value.internalRemarks && notice.value.internalRemarks.length > 1000)}"
																													class="form-control ng-pristine ng-valid ng-touched"></textarea>
																											</div>
																											<div
																												*ngIf="(notice.value.internalRemarks && notice.value.internalRemarks.length > 1000)">
																												<span
																													class="text-danger">Max
																													1000
																													Characters</span>
																											</div>
																										</div>



																									</div>
																								</div>
																							</div>
																							<div
																								class="col-md-12 mt15 mb-3">
																								<button
																									class="btn btn-outline-primary mr-1"
																									(click)="getNewNotice(i)">Add
																									Notice</button>
																								<button *ngIf="i != 0"
																									class="btn btn-outline-primary mr-1 "
																									(click)=" openNoticeRemove(rmvNoticePop,i)">Remove
																									Notice</button>
																							</div>
																							<ng-template #rmvNoticePop
																								let-modal>
																								<div
																									class="modal-header">
																									<h4 class="modal-title"
																										id="modal-basic-title">
																										Remove
																										Warning! </h4>
																									<button
																										type="button"
																										class="close"
																										aria-label="Close"
																										(click)="modal.dismiss('Cross click')">
																										<span
																											aria-hidden="true">×</span>
																									</button>
																								</div>
																								<div class="modal-body">
																									<p>Are you sure, you
																										want to remove?
																									</p>
																								</div>
																								<div
																									class="modal-footer">
																									<button
																										type="button"
																										class="btn btn-outline-dark"
																										(click)="removeNotice(i)">Remove</button>
																								</div>
																							</ng-template>
																							<div
																								class="col-md-12 mt15 mb-3">
																								<button
																									class="btn btn-outline-primary mr-1"
																									(click)="nextForNotice()">Next</button>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</ng-container>
													</div>
												</div>
											</div>
										</fieldset>
									</div>
									<div class="tab-pane fade" id="aaSub-2" role="tabpanel"
										[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="aaSub-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="col-md-12">
												<p class="textCenter">Submission </p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>
											<div formArrayName="notices">
												<div class="col-md-12">
													<ul class="nav nav-pills iris-gst-display-flex " role="tablist">
														<li class="nav-item"
															*ngFor="let notice of noticesFormArray.controls; let i = index"
															[formGroupName]="i">
															<a class="nav-link" id="notice1-tab" data-toggle="tab"
																href="#notice1-1" role="tab" aria-controls="notice1-1"
																(click)="noticePhaseChanged('phase' + i, i)"
																[ngClass]="(selectedNoticeIndex === 'phase' + i) ? 'active': ''"
																aria-selected="true">Notice {{i + 1}}</a>
														</li>
													</ul>
													<div *ngFor="let notice of noticesFormArray.controls; let i = index"
														[formGroupName]="i">
														<div class="tab-content nopadding">
															<div class="tab-pane fade show active" id="rnotice1-1"
																role="tabpanel" aria-labelledby="rnotice1-tab">
																<div *ngIf="selectedNoticeIndex === 'phase' + i">
																	<div class=" ">
																		<div formArrayName="submissions">
																			<div class="subDiv col-md-12">
																				<div *ngFor="let submission of notice.get('submissions').controls; let j = index"
																					[formGroupName]="j">
																					<div class="row">
																						<div class="col-md-12">
																							<p class="card-title">
																								Submission {{j + 1}} <a
																									class="addDiv"
																									(click)="addNewSubmission(i,j)"><i
																										class="fa fa-plus"></i></a>
																							</p>
																						</div>
																						<div class="col-md-3">
																							<div class="form-group">
																								<label>Submission Date
																									<span
																										class="mandate"><i
																											class="fa fa-asterisk"></i></span>
																								</label>
																								<div id="dtReviseDue"
																									class="input-group date datepicker">
																									<p-calendar
																										class="form-control"
																										[monthNavigator]="true"
																										formControlName="subDate"
																										[yearNavigator]="true"
																										yearRange="2010:{{currentYear}}"
																										dateFormat="dd-mm-yy"
																										[showIcon]="true"
																										dataType="string"
																										[ngClass]="{errorBorder : isSubFormValid && !submission.value.subDate || isAddNewSubSubmitted && !submission.value.subDate}"
																										[maxDate]="maxDate">
																									</p-calendar>
																								</div>
																							</div>
																							<div
																								*ngIf="isSubFormValid && !submission.value.subDate || isAddNewSubSubmitted && !submission.value.subDate">
																								<span
																									class="text-danger">Required</span>
																							</div>
																						</div>

																						<div class="col-md-3">
																							<div class="form-group">
																								<label>Acknowledgement
																									Number <span
																										class="mandate"><i
																											class="fa fa-asterisk"></i></span>
																								</label>
																								<input type="text"
																									formControlName="ackNo"
																									class="form-control"
																									[ngClass]="{errorBorder : isSubFormValid && !submission.value.ackNo || isAddNewSubSubmitted && !submission.value.ackNo}">
																							</div>
																							<div
																								*ngIf="isSubFormValid && !submission.value.ackNo || isAddNewSubSubmitted && !submission.value.ackNo">
																								<span
																									class="text-danger">Required</span>
																							</div>
																							<div
																								*ngIf="(submission.value.ackNo && submission.value.ackNo.length > 50)">
																								<span
																									class="text-danger">Maximum
																									Length 50
																									character</span>
																							</div>
																						</div>
																						<div class="col-md-3">
																							<div class="form-group">
																								<label>Acknowledgement
																									Copy <span
																										class="mandate"><i
																											class="fa fa-asterisk"></i></span></label>
																								<div class="attachment-block"
																									[ngClass]="{errorBorder : isSubFormValid && getSubAckCopyLength(i,j) == 0 || isAddNewSubSubmitted && getSubAckCopyLength(i,j) == 0}">
																									<span
																										(click)="openSubmissionAckCopy(subAckCopyPop,i,j)">
																										<i class="icon fa fa fa-paperclip"
																											id="paperclip"
																											title="Attach file"></i><span
																											*ngIf="getSubAckCopyLength(i,j) !== 0">{{getSubAckCopyLength(i,j)}}</span><span
																											*ngIf="getSubAckCopyLength(i,j) !== 0">
																											Attachment</span></span>
																								</div>


																							</div>
																							<div
																								*ngIf="isSubFormValid && getSubAckCopyLength(i,j) == 0 || isAddNewSubSubmitted && getSubAckCopyLength(i,j) == 0">
																								<span
																									class="text-danger">Required</span>
																							</div>
																						</div>

																						<ng-template #subAckCopyPop
																							let-modal>
																							<div class="modal-header"
																								style="border: none; padding-bottom: 0px;">
																								<button type="button"
																									class="close"
																									aria-label="Close"
																									(click)="modal.dismiss('Cross click')">
																									<span
																										aria-hidden="true">×</span>
																								</button>
																							</div>
																							<div class="col-md-12">
																								<label>
																									Attachment</label>
																								<div
																									class="table-responsive">
																									<table
																										class="table table-bordered">
																										<thead>
																											<tr
																												class="bgGred">
																												<th>Upload
																												</th>
																												<th
																													style="width:50%;">
																													Remark
																													(Max
																													100
																													Character
																													allowed)
																												</th>
																											</tr>
																										</thead>
																										<tbody>
																											<tr *ngFor="let subAckGrp of getSubAckCopyLoc(i,j); let k = index"
																												[formGroup]="subAckGrp">
																												<td>
																													<div
																														class="row">
																														<div
																															class="col-md-9 ">
																															<div
																																class="form-group">
																																<input
																																	*ngIf="subAckGrp.value.isdocLocUploadedClicked"
																																	type="file"
																																	id="notices({{i}}).submissions({{j}}).ackCopyLoc({{k}}).fileLoc"
																																	class="filetype form-control"
																																	name="myfile"
																																	(change)="onFileSelectUpload($event,i,{form:'remandBackForm',first : 'notices',firstIndex: i,second:'submissions',secondIndex:j,third:'ackCopyLoc',thirdIndex:k})">
																																<p *ngIf="!subAckGrp.value.isdocLocUploadedClicked || subAckGrp.value.fileLoc"
																																	class="form-control pt10"
																																	[title]="subAckGrp.value.fileName ? subAckGrp.value.fileName : ''">
																																	{{getFileNameDta(subAckGrp.value.fileLoc)}}
																																</p>
																															</div>
																														</div>
																														<div
																															class="col-md-3 nopadding">
																															<label>
																																<a class="downupIcon"
																																	*ngIf="(subAckGrp.value.fileLoc)"
																																	title="Download file"
																																	(click)="download(subAckGrp.value.fileLoc)"><i
																																		class="fa fa-download"></i></a>
																																<a class="downupIcon"
																																	*ngIf="!(subAckGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																																	title="Upload file"
																																	(click)="uploadSubmissionAckCopyLoc(i,j,k)"><i
																																		class="fa fa-upload"></i></a>
																																<a class="downupIcon"
																																	(click)="deleteSubmissionAckCopyLoc(i,j,k)"
																																	*ngIf="(subAckGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																		class="fa fa-trash"
																																		aria-hidden="true"></i></a>
																															</label>
																														</div>
																													</div>
																												</td>
																												<td> <input
																														type="text"
																														class="form-control"
																														placeholder=""
																														id="remarks"
																														formControlName="remarks"
																														[title]="subAckGrp.value.remarks ? subAckGrp.value.remarks : ''" />
																													<div
																														*ngIf="subAckGrp.value.remarks && subAckGrp.value.remarks.length > 100">
																														<span
																															class="text-danger">Remark
																															Max
																															character
																															Length
																															100</span>
																													</div>
																												</td>
																											</tr>
																										</tbody>
																									</table>
																								</div>
																							</div>
																							<div class="modal-footer"
																								style="border: none; padding-top: 0px;">
																								<button type="button"
																									class="btn btn-outline-dark"
																									(click)="modal.close('Save click')">Save</button>
																							</div>
																						</ng-template>

																						<div class="col-md-3">
																							<div class="form-group">
																								<label>Submission Copy
																								</label>
																								<span class="mandate"><i
																										class="fa fa-asterisk"></i></span>
																								<div class="attachment-block"
																									[ngClass]="{errorBorder :isSubFormValid && getSubCopyLength(i,j) == 0 ||  isAddNewSubSubmitted && getSubCopyLength(i,j) == 0}">
																									<span
																										(click)="openSubmissionCopy(subCopyPop,i,j)">
																										<i class="icon fa fa fa-paperclip"
																											id="paperclip"
																											title="Attach file"></i><span
																											*ngIf="getSubCopyLength(i,j) !== 0">{{getSubCopyLength(i,j)}}</span><span
																											*ngIf="getSubCopyLength(i,j) !== 0">
																											Attachment</span></span>
																								</div>


																							</div>
																							<div
																								*ngIf="isSubFormValid && getSubCopyLength(i,j) == 0 || isAddNewSubSubmitted && getSubCopyLength(i,j) == 0">
																								<span
																									class="text-danger">Required</span>
																							</div>
																						</div>
																						<div>
																							<ng-template #subCopyPop
																								let-modal>
																								<div class="modal-header"
																									style="border: none; padding-bottom: 0px;">
																									<button
																										type="button"
																										class="close"
																										aria-label="Close"
																										(click)="modal.dismiss('Cross click')">
																										<span
																											aria-hidden="true">×</span>
																									</button>
																								</div>
																								<div class="col-md-12">
																									<label>
																										Attachment</label>
																									<div
																										class="table-responsive">
																										<table
																											class="table table-bordered">
																											<thead>
																												<tr
																													class="bgGred">
																													<th>Upload
																													</th>
																													<th
																														style="width:50%;">
																														Remark
																														(Max
																														100
																														Character
																														allowed)
																													</th>
																												</tr>
																											</thead>
																											<tbody>
																												<tr *ngFor="let subLocGrp of getSubCopyLoc(i,j); let k = index"
																													[formGroup]="subLocGrp">
																													<td>
																														<div
																															class="row">
																															<div
																																class="col-md-9 ">
																																<div
																																	class="form-group">
																																	<input
																																		*ngIf="subLocGrp.value.isdocLocUploadedClicked"
																																		type="file"
																																		id="notices({{i}}).submissions({{j}}).subCopyLoc({{k}}).fileLoc"
																																		class="filetype form-control"
																																		name="myfile"
																																		(change)="onFileSelectUpload($event,i,{form:'remandBackForm',first : 'notices',firstIndex: i,second:'submissions',secondIndex:j,third:'subCopyLoc',thirdIndex:k})">
																																	<p *ngIf="!subLocGrp.value.isdocLocUploadedClicked || subLocGrp.value.fileLoc"
																																		class="form-control pt10"
																																		[title]="subLocGrp.value.fileName ? subLocGrp.value.fileName : ''">
																																		{{getFileNameDta(subLocGrp.value.fileLoc)}}
																																	</p>
																																</div>
																															</div>
																															<div
																																class="col-md-3 nopadding">
																																<label>
																																	<a class="downupIcon"
																																		*ngIf="(subLocGrp.value.fileLoc)"
																																		title="Download file"
																																		(click)="download(subLocGrp.value.fileLoc)"><i
																																			class="fa fa-download"></i></a>
																																	<a class="downupIcon"
																																		*ngIf="!(subLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																																		title="Upload file"
																																		(click)="uploadSubmissioCopyLoc(i,j,k)"><i
																																			class="fa fa-upload"></i></a>
																																	<a class="downupIcon"
																																		(click)="deleteSubmissionCopyLoc(i,j,k)"
																																		*ngIf="(subLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																			class="fa fa-trash"
																																			aria-hidden="true"></i></a>
																																</label>
																															</div>
																														</div>
																													</td>
																													<td> <input
																															type="text"
																															class="form-control"
																															placeholder=""
																															id="remarks"
																															formControlName="remarks"
																															[title]="subLocGrp.value.remarks ? subLocGrp.value.remarks : ''" />
																														<div
																															*ngIf="subLocGrp.value.remarks && subLocGrp.value.remarks.length > 100">
																															<span
																																class="text-danger">Remark
																																Max
																																character
																																Length
																																100</span>
																														</div>
																													</td>
																												</tr>
																											</tbody>
																										</table>
																									</div>
																								</div>
																								<div class="modal-footer"
																									style="border: none; padding-top: 0px;">
																									<button
																										type="button"
																										class="btn btn-outline-dark"
																										(click)="modal.close('Save click')">Save</button>
																								</div>
																							</ng-template>
																						</div>
																						<div class="col-md-12">
																							<div class="form-group">
																								<label>Submission
																									Summary</label>
																								<textarea
																									id="exampleTextarea1"
																									rows="4"
																									formControlName="subSummary"
																									[ngClass]="{errorBorder : (submission.value.subSummary && submission.value.subSummary.length > 1000)}"
																									class="form-control ng-pristine ng-valid ng-touched"></textarea>
																							</div>
																							<div
																								*ngIf="(submission.value.subSummary && submission.value.subSummary.length > 1000)">
																								<span
																									class="text-danger">Max
																									1000
																									Characters</span>
																							</div>
																						</div>
																						<div class="col-md-12">
																							<div class="form-group">
																								<label>Internal
																									Remarks</label>
																								<textarea
																									id="exampleTextarea1"
																									rows="4"
																									formControlName="internalRemarks"
																									[ngClass]="{errorBorder : (submission.value.internalRemarks && submission.value.internalRemarks.length > 1000)}"
																									class="form-control ng-pristine ng-valid ng-touched"></textarea>
																							</div>
																							<div
																								*ngIf="(submission.value.internalRemarks && submission.value.internalRemarks.length > 1000)">
																								<span
																									class="text-danger">Max
																									1000
																									Characters</span>
																							</div>
																						</div>
																						<div>
																							<button
																								(click)="openRemoveSub(removeSubmissionPop, i,j)"
																								*ngIf="j!= 0"
																								class="btn btn-outline-primary mr-1">Remove
																								Submission</button>
																						</div>
																						<ng-template
																							#removeSubmissionPop
																							let-modal>
																							<div class="modal-header">
																								<h4 class="modal-title"
																									id="modal-basic-title">
																									Remove Warning!</h4>
																								<button type="button"
																									class="close"
																									aria-label="Close"
																									(click)="modal.dismiss('Cross click')">
																									<span
																										aria-hidden="true">×</span>
																								</button>
																							</div>
																							<div class="modal-body">
																								<p>Are you sure, you
																									want to remove?</p>
																							</div>
																							<div class="modal-footer">
																								<button type="button"
																									class="btn btn-outline-dark"
																									(click)="removeSubmission(i,j)">Remove</button>
																							</div>
																						</ng-template>

																					</div>
																				</div>
																			</div>
																		</div>
																		<div formGroupName="meetingDetails"
																			class="col-md-12">
																			<div class="accordion accordion-solid-header"
																				id="accordion-04" role="tablist">
																				<div class="card border-bottom">
																					<div class="card-header" role="tab"
																						id="heading-04">
																						<h6 class="mb-0">
																							<a data-toggle="collapse"
																								href="#collapse-04"
																								aria-expanded="true"
																								aria-controls="collapse-04">
																								Details of Physical
																								Meeting/ Video
																								Conferencing Department
																							</a>
																						</h6>
																					</div>
																					<div id="collapse-04"
																						class="collapse show"
																						role="tabpanel"
																						aria-labelledby="heading-04"
																						data-parent="#accordion-04">
																						<div class="card-body">
																							<div class="row">
																								<div
																									class="table-responsive">
																									<table
																										class="table table-bordered">
																										<thead
																											class="bgGred">
																											<tr>
																												<th
																													class="sl-no-cls">
																													Sr.No.
																												</th>
																												<th
																													style="min-width: 100px">
																													Date
																													of
																													Meeting
																												</th>
																												<th
																													style="min-width: 210px">
																													Persons
																													who
																													attended
																												</th>
																												<th>Internal
																													Remarks
																												</th>
																												<th>Copy
																													of
																													Submissions
																													made
																												</th>
																												<th
																													class="sl-no-cls">
																													Action
																												</th>
																											</tr>
																										</thead>
																										<tbody>
																											<tr *ngFor="let meeting of notice.get('meetingDetails').controls; let k = index"
																												[formGroupName]="k">
																												<td
																													class="w-40">
																													{{k+1}}
																												</td>
																												<td>
																													<div
																														class="input-group date datepicker">
																														<p-calendar
																															appendTo="body"
																															class="form-control"
																															[monthNavigator]="true"
																															formControlName="dateOfMeeting"
																															[yearNavigator]="true"
																															yearRange="2010:{{currentYear}}"
																															dateFormat="dd-mm-yy"
																															[showIcon]="true"
																															dataType="string"
																															[maxDate]="maxDate"></p-calendar>
																													</div>
																												</td>
																												<td><input
																														type="text"
																														formControlName="personAttended"
																														[ngClass]="{errorBorder : meeting.controls.personAttended?.value && meeting.controls.personAttended?.value.length > 100 }"
																														class="form-control">
																													<div *ngIf="meeting.controls.personAttended?.value && meeting.controls.personAttended?.value.length > 100"
																														class="text-danger">
																														<span>Max
																															Length
																															is
																															100
																															character</span>
																													</div>
																												</td>
																												<td><input
																														type="text"
																														formControlName="internalRemarks"
																														[ngClass]="{errorBorder : meeting.controls.internalRemarks?.value && meeting.controls.internalRemarks?.value.length > 100 }"
																														class="form-control">
																													<div *ngIf="meeting.controls.internalRemarks?.value && meeting.controls.internalRemarks?.value.length > 100"
																														class="text-danger">
																														<span>Max
																															Length
																															is
																															100
																															character</span>
																													</div>
																												</td>
																												<td><span
																														(click)="openMettingAttachPop(meetingDocLocPop, i,k)"><i
																															class="icon fa fa fa-paperclip"
																															id="paperclip"
																															title="Attach file"></i></span><span
																														*ngIf="getMeetingLength(i,k) !== 0">{{getMeetingLength(i,k)}}</span><span
																														*ngIf="getMeetingLength(i,k) !== 0">
																														Attachment</span>
																												</td>
																												<td
																													class="w-80">
																													<a class="addExtLetter"
																														*ngIf="(notice.get('meetingDetails').controls.length - 1) == k"
																														(click)="addMeetingDetails(i)"><i
																															class="fa fa-plus"
																															title="Add row"></i>
																													</a>
																													<a *ngIf="k != 0"
																														class="addExtLetter"
																														(click)="openMeeting(removeMeetingRefPop)"><i
																															class="fa fa-minus"></i></a>

																												</td>
																												<ng-template
																													#removeMeetingRefPop
																													let-modal>
																													<div
																														class="modal-header">
																														<h4 class="modal-title"
																															id="modal-basic-title">
																															Remove
																															Warning!
																														</h4>
																														<button
																															type="button"
																															class="close"
																															aria-label="Close"
																															(click)="modal.dismiss('Cross click')">
																															<span
																																aria-hidden="true">×</span>
																														</button>
																													</div>
																													<div
																														class="modal-body">
																														<p>Are
																															you
																															sure,
																															you
																															want
																															to
																															remove?
																														</p>
																													</div>
																													<div
																														class="modal-footer">
																														<button
																															type="button"
																															class="btn btn-outline-dark"
																															(click)="removeMeetingDetails(i,k)">Remove</button>
																													</div>
																												</ng-template>
																												<ng-template
																													#meetingDocLocPop
																													let-modal>
																													<div class="modal-header"
																														style="border: none; padding-bottom: 0px;">
																														<button
																															type="button"
																															class="close"
																															aria-label="Close"
																															(click)="modal.dismiss('Cross click')">
																															<span
																																aria-hidden="true">×</span>
																														</button>
																													</div>
																													<div
																														class="col-md-12">
																														<label>
																															Attachment</label>
																														<div
																															class="table-responsive">
																															<table
																																class="table table-bordered">
																																<thead>
																																	<tr
																																		class="bgGred">
																																		<th>Upload
																																		</th>
																																		<th
																																			style="width:50%;">
																																			Remark
																																			(Max
																																			100
																																			Character
																																			allowed)
																																		</th>
																																	</tr>
																																</thead>
																																<tbody>
																																	<tr *ngFor="let meetingCopyGrp of getMeetingCopyLoc(i,k); let l = index"
																																		[formGroup]="meetingCopyGrp">
																																		<td>
																																			<div
																																				class="row">
																																				<div
																																					class="col-md-9 nopadding">
																																					<div
																																						class="form-group">
																																						<input
																																							*ngIf="meetingCopyGrp?.value?.isdocLocUploadedClicked"
																																							type="file"
																																							id="notices({{i}}).meetingDetails({{k}}).copyOfSubmissionLoc({{l}}).fileLoc"
																																							class="filetype form-control"
																																							name="myfile"
																																							(change)="onFileSelectUpload($event,i,{form:'remandBackForm',first : 'notices',firstIndex: i,second:'meetingDetails',secondIndex:k,third:'copyOfSubmissionLoc',thirdIndex:l})">
																																						<p *ngIf="!meetingCopyGrp?.value?.isdocLocUploadedClicked || meetingCopyGrp?.value.fileLoc"
																																							class="form-control pt10">
																																							{{getFileNameDta(meetingCopyGrp.value.fileLoc)}}
																																						</p>
																																					</div>
																																				</div>
																																				<div
																																					class="col-md-3 nopadding">
																																					<label
																																						for="exampleInputPassword1">
																																						<a class="downupIcon"
																																							*ngIf="meetingCopyGrp?.value.fileLoc"
																																							title="Download file"
																																							(click)="download(meetingCopyGrp.value.fileLoc)"><i
																																								class="fa fa-download"></i></a>
																																						<a class="downupIcon"
																																							*ngIf="!meetingCopyGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																							title="Upload file"
																																							(click)="uploadMeetingLoc(i,k,l)"><i
																																								class="fa fa-upload"></i></a>
																																						<a class="downupIcon"
																																							(click)="deleteMeetingnCopyLoc(i,k,l)"
																																							*ngIf="(meetingCopyGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																								class="fa fa-trash"
																																								aria-hidden="true"></i></a>
																																					</label>
																																				</div>
																																			</div>
																																		</td>
																																		<td>
																																			<input
																																				type="text"
																																				class="form-control"
																																				formControlName="remarks"
																																				[title]="meetingCopyGrp.value.remarks ? meetingCopyGrp.value.remarks : ''">
																																			<div
																																				*ngIf="meetingCopyGrp.value.remarks && meetingCopyGrp.value.remarks.length > 100">
																																				<span
																																					class="text-danger">Remark
																																					Max
																																					character
																																					Length
																																					100</span>
																																			</div>
																																		</td>
																																		<ng-template
																																			#rmitrFPop
																																			let-modal>
																																			<div
																																				class="modal-header">
																																				<h4 class="modal-title"
																																					id="modal-basic-title">
																																					Remove
																																					Warning!
																																				</h4>
																																				<button
																																					type="button"
																																					class="close"
																																					aria-label="Close"
																																					(click)="modal.dismiss('Cross click')">
																																					<span
																																						aria-hidden="true">×</span>
																																				</button>
																																			</div>
																																			<div
																																				class="modal-body">
																																				<p>Are
																																					you
																																					sure,
																																					you
																																					want
																																					to
																																					remove?
																																				</p>
																																			</div>
																																			<div
																																				class="modal-footer">
																																				<button
																																					type="button"
																																					class="btn btn-outline-dark">Remove</button>
																																			</div>
																																		</ng-template>
																																	</tr>
																																</tbody>
																															</table>
																														</div>
																													</div>
																													<div class="modal-footer"
																														style="border: none; padding-top: 0px;">
																														<button
																															type="button"
																															class="btn btn-outline-dark"
																															(click)="modal.close('Save click')">Save</button>
																													</div>
																												</ng-template>
																											</tr>
																										</tbody>
																									</table>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class="col-md-12 mt15 mb-3">
																			<button class="btn btn-outline-primary mr-1"
																				(click)="nextForRemandSubmmisionTab(i)">Next</button>
																		</div>
																	</div>
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</fieldset>
									</div>

									<div class="tab-pane fade" id="aaR-2" [ngClass]="{'bgGray': (action == 'view')}"
										role="tabpanel" aria-labelledby="aaR-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="row">
												<div class="col-md-12">
													<p class="textCenter">Remand Report</p>
													<span class="toggleTab" (click)="sidemenu=!sidemenu">
														<i class="mdi mdi mdi-chevron-double-left leftTIcon"
															*ngIf="!sidemenu"></i>
														<i class="mdi mdi-chevron-double-right rightTIcon"
															*ngIf="sidemenu"></i>
													</span>
												</div>
												<div formArrayName="orderSummaryDtls">
													<div class="col-md-12">
														<div class="row">
															<div class="table-responsive">
																<table class="table table-bordered">
																	<thead class="bgGred">
																		<tr>
																			<th class="sl-no-cls">Sr.No.</th>
																			<th style="min-width: 100px">Date of Report
																			</th>
																			<th>Copy of Report</th>
																			<th style="min-width: 250px">Reference
																				Number/DIN</th>
																			<th style="min-width: 250px">Report Summary
																			</th>
																			<th class="sl-no-cls">Action</th>
																		</tr>
																	</thead>
																	<tbody>
																		<ng-container
																			*ngIf="remandBackForm.get('orderSummaryDtls')">
																			<tr *ngFor="let report of remandBackForm.get('orderSummaryDtls').controls; let i = index"
																				[formGroupName]="i">
																				<td class="w-40">{{i+1}}</td>
																				<td>
																					<p-calendar appendTo="body"
																						class="form-control"
																						[monthNavigator]="true"
																						formControlName="reportDate"
																						[yearNavigator]="true"
																						yearRange="2010:{{currentYear}}"
																						dateFormat="dd-mm-yy"
																						[showIcon]="true"
																						dataType="string"
																						[maxDate]="maxDate">
																					</p-calendar>
																				</td>
																				<td><span
																						(click)="openRemandBackReportpop(reportCopyLocGrp, i)"><i
																							class="icon fa fa fa-paperclip"
																							id="paperclip"
																							title="Attach file"></i></span><span
																						*ngIf="getReportDocLocLength(i) !== 0">{{getReportDocLocLength(i)}}</span><span
																						*ngIf="getReportDocLocLength(i) !== 0">
																						Attachment</span></td>
																				<td><input type="text"
																						formControlName="noticeRefNo"
																						class="form-control"> </td>
																				<td><input type="text"
																						formControlName="reportSummary"
																						class="form-control">
																				</td>
																				<td class="w-80">
																					<a class="addExtLetter"
																						*ngIf="(remandBackForm.get('orderSummaryDtls').controls.length - 1) == i"
																						(click)="addRemandReportCharges()"><i
																							class="fa fa-plus"
																							title="Add row"></i> </a>
																					<a *ngIf="i != 0"
																						class="addExtLetter"
																						(click)="openRemadnReport(remandReportRefPop)"><i
																							class="fa fa-minus"></i></a>

																				</td>
																				<ng-template #remandReportRefPop
																					let-modal>
																					<div class="modal-header">
																						<h4 class="modal-title"
																							id="modal-basic-title">
																							Remove
																							Warning! </h4>
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="modal-body">
																						<p>Are you sure, you want to
																							remove?</p>
																					</div>
																					<div class="modal-footer">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="removeRemandReport(i)">Remove</button>
																					</div>
																				</ng-template>
																				<ng-template #reportCopyLocGrp
																					let-modal>
																					<div class="modal-header"
																						style="border: none; padding-bottom: 0px;">
																						<button type="button"
																							class="close"
																							aria-label="Close"
																							(click)="modal.dismiss('Cross click')">
																							<span
																								aria-hidden="true">×</span>
																						</button>
																					</div>
																					<div class="col-md-12">
																						<label> Attachment</label>
																						<div class="table-responsive">
																							<table
																								class="table table-bordered">
																								<thead>
																									<tr class="bgGred">
																										<th>Upload</th>
																										<th
																											style="width:50%;">
																											Remark (Max
																											100
																											Character
																											allowed)
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<tr *ngFor="let reportDocGrp of getreportCopyLocControls(i); let j = index"
																										[formGroup]="reportDocGrp">
																										<td>
																											<div
																												class="row">
																												<div
																													class="col-md-9 nopadding">
																													<div
																														class="form-group">
																														<input
																															*ngIf="reportDocGrp?.value?.isdocLocUploadedClicked"
																															type="file"
																															id="orderSummaryDtls({{i}}).reportCopyLoc({{j}}).fileLoc"
																															class="filetype form-control"
																															name="myfile"
																															(change)="onFileSelectUpload($event,j,{form:'remandBackForm',first : 'orderSummaryDtls',second:'reportCopyLoc',secondIndex : j,firstIndex : i})">
																														<p *ngIf="!reportDocGrp?.value?.isdocLocUploadedClicked || reportDocGrp?.value.fileLoc"
																															class="form-control pt10">
																															{{getFileNameDta(reportDocGrp.value.fileLoc)}}
																														</p>
																													</div>
																												</div>
																												<div
																													class="col-md-3 nopadding">
																													<label
																														for="exampleInputPassword1">
																														<a class="downupIcon"
																															*ngIf="reportDocGrp?.value.fileLoc"
																															title="Download file"
																															(click)="download(reportDocGrp.value.fileLoc)"><i
																																class="fa fa-download"></i></a>
																														<a class="downupIcon"
																															*ngIf="!reportDocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																															title="Upload file"
																															(click)="uploadRemandReportDocLoc(i,j)"><i
																																class="fa fa-upload"></i></a>
																														<a class="downupIcon"
																															(click)="deleteRemandReportDocLoc(i,j)"
																															*ngIf="(reportDocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																class="fa fa-trash"
																																aria-hidden="true"></i></a>
																													</label>
																												</div>
																											</div>
																										</td>
																										<td>
																											<input
																												type="text"
																												class="form-control"
																												formControlName="remarks"
																												[title]="reportDocGrp.value.remarks ? reportDocGrp.value.remarks : ''">
																											<div
																												*ngIf="reportDocGrp.value.remarks && reportDocGrp.value.remarks.length > 100">
																												<span
																													class="text-danger">Remark
																													Max
																													character
																													Length
																													100</span>
																											</div>
																										</td>
																										<ng-template
																											#rmitrFPop
																											let-modal>
																											<div
																												class="modal-header">
																												<h4 class="modal-title"
																													id="modal-basic-title">
																													Remove
																													Warning!
																												</h4>
																												<button
																													type="button"
																													class="close"
																													aria-label="Close"
																													(click)="modal.dismiss('Cross click')">
																													<span
																														aria-hidden="true">×</span>
																												</button>
																											</div>
																											<div
																												class="modal-body">
																												<p>Are
																													you
																													sure,
																													you
																													want
																													to
																													remove?
																												</p>
																											</div>
																											<div
																												class="modal-footer">
																												<button
																													type="button"
																													class="btn btn-outline-dark">Remove</button>
																											</div>
																										</ng-template>
																									</tr>
																								</tbody>
																							</table>
																						</div>
																					</div>
																					<div class="modal-footer"
																						style="border: none; padding-top: 0px;">
																						<button type="button"
																							class="btn btn-outline-dark"
																							(click)="modal.close('Save click')">Save</button>
																					</div>
																				</ng-template>
																			</tr>
																		</ng-container>
																	</tbody>
																</table>
															</div>
														</div>


													</div>
												</div>

												<div class="col-md-12 mt15 mb-3">
													<button class="btn btn-outline-primary mr-1"
														(click)="updatePhase2()">Update</button>
												</div>
											</div>
										</fieldset>
									</div>


								</div>
							</form>
						</div>
					</div>


				</div>
			</div>
		</div>
		<div class="tab-pane fade" id="AppelAuthp3-1" role="tabpanel" aria-labelledby="AppelAuthp3-tab">
			<div class="card borderOrange">
				<div class="card-body ptl10 plr010">
					<div class="row">
						<div class="col-2" id="verticalTab12" *ngIf="!sidemenu">
							<ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="aaord-tab-vertical" data-toggle="tab" href="#aaord-2"
										role="tab" aria-controls="aaord-2" aria-selected="true" #remandOrder>
										Order
									</a>
								</li>
								<li class="nav-item" [ngClass]="{'disabled' : isNewOrder}">
									<a class="nav-link" id="aatp-tab-vertical" data-toggle="tab" href="#aatp-2"
										role="tab" aria-controls="aatp-2" aria-selected="false" #taxPayment>
										Tax Payment and Refund details
									</a>
								</li>
								<li class="nav-item" [ngClass]="{'disabled' : isNewOrder}">
									<a class="nav-link" id="aact-tab-vertical" data-toggle="tab" href="#aact-2"
										role="tab" aria-controls="aact-2" aria-selected="false" #cumulative>
										Cumulative Tax Details
									</a>
								</li>
								<li class="nav-item">
									<a class="btn btn-outline-primary w100" (click)="updatePhase2()"
										[ngClass]="{'disabled': (action == 'view')}">
										Update

									</a>
								</li>

							</ul>
						</div>
						<div class="col-10 border " id="tabArea12" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
							<form [formGroup]="phase3Form">
								<div class="tab-content tab-content-vertical">
									<div class="tab-pane fade show active" id="aaord-2"
										[ngClass]="{'bgGray': (action == 'view')}" role="tabpanel"
										aria-labelledby="aaord-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="row">
												<div class="col-md-12">
													<p class="textCenter">Order </p>
													<span class="toggleTab" (click)="sidemenu=!sidemenu">
														<i class="mdi mdi mdi-chevron-double-left leftTIcon"
															*ngIf="!sidemenu"></i>
														<i class="mdi mdi-chevron-double-right rightTIcon"
															*ngIf="sidemenu"></i>
													</span>
												</div>
												<div formArrayName="orderDtls">
													<div class="col-md-12">
														<span *ngIf="phase3Form.get('orderDtls')">
															<ul class="nav nav-pills iris-gst-display-flex "
																role="tablist">
																<li class="nav-item"
																	*ngFor="let order of phase3Form.get('orderDtls').controls; let i = index"
																	[formGroupName]="i">
																	<a class="nav-link" id="assnotice1-tab"
																		data-toggle="tab" href="#assnotice1-1"
																		role="tab" aaria-controls="assnotice1-1"
																		(click)="orderPhaseChanged('phase' + i, i)"
																		[ngClass]="(selectedOrderIndex === 'phase' + i) ? 'active': ''"
																		aria-selected="true">Order {{i + 1}}</a>
																</li>
															</ul>
														</span>
														<ng-container *ngIf="phase3Form.get('orderDtls')">
															<div *ngFor="let order of phase3Form.get('orderDtls').controls; let i = index"
																[formGroupName]="i">
																<div class="tab-content nopadding">
																	<div class="tab-pane fade show active"
																		id="assnotice1-1" role="tabpanel"
																		aria-labelledby="assnotice1-tab">
																		<div *ngIf="selectedOrderIndex === 'phase' + i">
																			<div class="subDiv col-md-12">
																				<div class="row">

																					<div class="col-md-3">
																						<div class="form-group">
																							<label>Order Number/
																								DIN<span
																									class="mandate"><i
																										class="fa fa-asterisk"></i></span></label>
																							<input type="text"
																								class="form-control"
																								[ngClass]="{errorBorder : isNewOrder && !order.controls.orderNo4.value}"
																								formControlName="orderNo4">
																						</div>
																						<div class="text-danger"
																							*ngIf="isNewOrder && !order.controls.orderNo4.value">
																							<span>Required</span>
																						</div>
																					</div>
																					<div class="col-md-3">
																						<div class="form-group">
																							<label>Date of Order
																								<span class="mandate"><i
																										class="fa fa-asterisk"></i></span></label>
																							<div id="orderDate1"
																								class="input-group date datepicker">
																								<p-calendar
																									class="form-control"
																									formControlName="orderDate4"
																									[monthNavigator]="true"
																									[yearNavigator]="true"
																									[maxDate]="maxDate"
																									yearRange="2010:{{currentYear}}"
																									dateFormat="dd-mm-yy"
																									[showIcon]="true"
																									dataType="string"
																									[ngClass]="{errorBorder : isNewOrder && !order.controls.orderDate4.value}">
																								</p-calendar>
																							</div>
																						</div>
																						<div class="text-danger"
																							*ngIf="isNewOrder && !order.controls.orderDate4.value">
																							<span>Required</span>
																						</div>
																					</div>
																					<div class="col-md-3">
																						<div class="form-group">
																							<label>Date of Receipt of
																								Order
																								<span class="mandate"><i
																										class="fa fa-asterisk"></i></span></label>
																							<div id="orderDate1"
																								class="input-group date datepicker">
																								<p-calendar
																									class="form-control"
																									formControlName="orderReceiptDate4"
																									[monthNavigator]="true"
																									[yearNavigator]="true"
																									[maxDate]="maxDate"
																									yearRange="2010:{{currentYear}}"
																									dateFormat="dd-mm-yy"
																									[showIcon]="true"
																									dataType="string"
																									[ngClass]="{errorBorder : isNewOrder && !order.controls.orderReceiptDate4.value}">
																								</p-calendar>
																							</div>
																						</div>
																						<div class="text-danger"
																							*ngIf="isNewOrder && !order.controls.orderReceiptDate4.value">
																							<span>Required</span>
																						</div>
																					</div>
																					<div class="col-md-3">
																						<div class="form-group">
																							<label>Mode of Receipt
																								of Order </label>
																							<select
																								class="form-control form-select"
																								formControlName="orderReceiptMode4">
																								<option [value]="null">
																									-- Select --
																								</option>
																								<ng-container
																									*ngFor="let obj of modeOfAppealOptions">
																									<option
																										[value]="obj.value">
																										{{obj.value}}
																									</option>
																								</ng-container>
																							</select>
																						</div>
																					</div>

																					<div class="col-md-3">
																						<div class="form-group">
																							<label>Order Passed by
																							</label>
																							<select
																								class="form-control form-select"
																								id="orderPassedBy4"
																								formControlName="orderPassedBy4">
																								<option [value]="null">
																									-- Select --
																								</option>
																								<ng-container
																									*ngFor="let obj of orderPassedBy">
																									<option
																										[value]="obj.value">
																										{{ obj.label }}
																									</option>
																								</ng-container>
																							</select>
																						</div>
																					</div>
																					<div class="col-md-3">
																						<div class="form-group">
																							<label>Order Copy <span
																									class="mandate"> <i
																										class="fa fa-asterisk"></i></span></label>
																							<div class="attachment-block"
																								[ngClass]="{errorBorder : isNewOrder && getOrderCopyLocLength(i) == 0}">
																								<span
																									(click)="openOrderCopy(orderCopyLoc,i)">
																									<i class="icon fa fa fa-paperclip"
																										id="paperclip"
																										title="Attach file"></i><span
																										*ngIf="getOrderCopyLocLength(i) !== 0">{{getOrderCopyLocLength(i)}}</span><span
																										*ngIf="getOrderCopyLocLength(i) !== 0">
																										Attachment</span></span>
																							</div>


																						</div>
																						<div class="text-danger"
																							*ngIf="isNewOrder && getOrderCopyLocLength(i) == 0">
																							<span>Required</span>
																						</div>
																					</div>

																					<ng-template #orderCopyLoc
																						let-modal>
																						<div class="modal-header"
																							style="border: none; padding-bottom: 0px;">
																							<button type="button"
																								class="close"
																								aria-label="Close"
																								(click)="modal.dismiss('Cross click')">
																								<span
																									aria-hidden="true">×</span>
																							</button>
																						</div>
																						<div class="col-md-12">
																							<label> Attachment</label>
																							<div
																								class="table-responsive">
																								<table
																									class="table table-bordered">
																									<thead>
																										<tr
																											class="bgGred">
																											<th>Upload
																											</th>
																											<th
																												style="width:50%;">
																												Remark
																												(Max 100
																												Character
																												allowed)
																											</th>
																										</tr>
																									</thead>
																									<tbody>
																										<tr *ngFor="let orderCopyLocGrp of getOrderNoticeLoc(i); let j = index"
																											[formGroup]="orderCopyLocGrp">
																											<td>
																												<div
																													class="row">
																													<div
																														class="col-md-9">
																														<div
																															class="form-group">
																															<input
																																*ngIf="orderCopyLocGrp.value.isdocLocUploadedClicked"
																																type="file"
																																id="orderDtls({{i}}).orderCopyLoc4({{j}}).fileLoc"
																																class="filetype form-control"
																																name="myfile"
																																(change)="onFileSelectUpload($event,i,{form:'phase3Form',first : 'orderDtls',firstIndex: i,second:'orderCopyLoc4',secondIndex:j})">
																															<p *ngIf="!orderCopyLocGrp.value.isdocLocUploadedClicked || orderCopyLocGrp.value.fileLoc"
																																class="form-control pt10"
																																[title]="orderCopyLocGrp.value.fileName ? orderCopyLocGrp.value.fileName : ''">
																																{{getFileNameDta(orderCopyLocGrp.value.fileLoc)}}
																															</p>
																														</div>
																													</div>
																													<div
																														class="col-md-3 nopadding">
																														<label>
																															<a class="downupIcon"
																																*ngIf="(orderCopyLocGrp.value.fileLoc)"
																																title="Download file"
																																(click)="download(orderCopyLocGrp.value.fileLoc)"><i
																																	class="fa fa-download"></i></a>
																															<a class="downupIcon"
																																*ngIf="!(orderCopyLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
																																title="Upload file"
																																(click)="uploadOrdereLoc(i,j)"><i
																																	class="fa fa-upload"></i></a>
																															<a class="downupIcon"
																																(click)="deleteOrdereLoc(i,j)"
																																*ngIf="(orderCopyLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																	class="fa fa-trash"
																																	aria-hidden="true"></i></a>
																														</label>
																													</div>
																												</div>
																											</td>
																											<td> <input
																													type="text"
																													class="form-control"
																													placeholder=""
																													id="remarks"
																													formControlName="remarks"
																													[title]="orderCopyLocGrp.value.remarks ? orderCopyLocGrp.value.remarks : ''" />
																												<div
																													*ngIf="orderCopyLocGrp.value.remarks && orderCopyLocGrp.value.remarks.length > 100">
																													<span
																														class="text-danger">Remark
																														Max
																														character
																														Length
																														100</span>
																												</div>
																											</td>
																										</tr>
																									</tbody>
																								</table>
																							</div>
																						</div>
																						<div class="modal-footer"
																							style="border: none; padding-top: 0px;">
																							<button type="button"
																								class="btn btn-outline-dark"
																								(click)="modal.close('Save click')">Save</button>
																						</div>
																					</ng-template>

																					<div class="col-md-3">
																						<div class="form-group">
																							<label>Outcome <span
																									class="mandate"><i
																										class="fa fa-asterisk"></i></span>
																							</label>
																							<select
																								class="form-control form-select"
																								id="outcome"
																								[ngClass]="{errorBorder : isNewOrder && !order.controls.outcome.value}"
																								formControlName="outcome">
																								<option [value]="null">
																									-- Select --
																								</option>
																								<ng-container
																									*ngFor=" let obj of outcomeData">
																									<option
																										[value]="obj.value">
																										{{ obj.value}}
																									</option>
																								</ng-container>
																							</select>
																						</div>
																						<div class="text-danger"
																							*ngIf="isNewOrder && !order.controls.outcome.value">
																							<span>Required</span>
																						</div>
																					</div>
																					<div class="col-md-12">
																						<div class="form-group">
																							<label>Order Summary
																							</label>
																							<textarea
																								id="exampleTextarea1"
																								rows="4"
																								class="form-control ng-pristine ng-valid ng-touched"
																								formControlName="orderSummary"
																								[ngClass]="{errorBorder : isNewOrder && (order.controls.orderSummary.value && order.controls.orderSummary.value.length > 1000)}"></textarea>
																						</div>
																						<div
																							*ngIf="(order.controls.orderSummary.value && order.controls.orderSummary.value.length > 1000)">
																							<span
																								class="text-danger">Max
																								1000 Characters</span>
																						</div>
																					</div>
																					<div class="col-md-12">
																						<div class="form-group">
																							<label>Internal Remarks
																							</label>
																							<textarea
																								id="exampleTextarea1"
																								rows="4"
																								class="form-control ng-pristine ng-valid ng-touched"
																								formControlName="internalRemarks4"
																								[ngClass]="{errorBorder : isNewOrder && (order.controls.internalRemarks4.value && order.controls.internalRemarks4.value.length > 1000)}"></textarea>
																						</div>
																						<div
																							*ngIf="(order.controls.internalRemarks4.value && order.controls.internalRemarks4.value.length > 1000)">
																							<span
																								class="text-danger">Max
																								1000 Characters</span>
																						</div>
																					</div>
																					<div class="col-md-12 "
																						id="showAdjTbl">
																						<div class="accordion accordion-solid-header"
																							id="accordion-02"
																							role="tablist">
																							<div
																								class="card border-bottom">
																								<div class="card-header"
																									role="tab"
																									id="heading-02">
																									<h6 class="mb-0">
																										<a data-toggle="collapse"
																											href="#collapse-02"
																											aria-expanded="true"
																											aria-controls="collapse-2">
																											Order
																											Details
																										</a>
																									</h6>
																								</div>
																								<div id="collapse-02"
																									class="collapse show"
																									role="tabpanel"
																									aria-labelledby="heading-02"
																									data-parent="#accordion-02">
																									<div
																										formGroupName="issues">
																										<div
																											class="card-body">
																											<div
																												class=" row">
																												<div
																													class="col-md-12">
																													<div
																														class="search-table-outter wrapper">
																														<table
																															class="table inner ">
																															<thead>
																																<tr
																																	class="bgGred">
																																	<th
																																		class="sl-no-cls">
																																		Sr
																																		No.
																																	</th>
																																	<th
																																		style="min-width: 288px;">
																																		Issues<span
																																			class="mandatetd"><i
																																				class="fa fa-asterisk"
																																				style="font-size: 7px; margin-left: 0px;"></i></span>
																																	</th>
																																	<th>Conclusion
																																		<span
																																			class="mandatetd"><i
																																				class="fa fa-asterisk"
																																				style="font-size: 7px; margin-left: 0px;"></i></span>
																																	</th>
																																	<th>Type
																																		of
																																		Matter
																																	</th>
																																	<th>Tag</th>
																																	<th
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		Addition
																																		of
																																		Income/
																																		Disallowance
																																		of
																																		Expenses
																																		(₹)
																																	</th>
																																	<th
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		Tax
																																		Rate
																																		(Inclusive
																																		of
																																		Surcharge
																																		and
																																		Cess)
																																	</th>
																																	<th
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		Tax
																																		Impact
																																		(Inclusive
																																		of
																																		Surcharge
																																		and
																																		Cess)
																																		(₹)
																																	</th>
																																	<th
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		Interest
																																		(₹)
																																	</th>
																																	<th>Penalty
																																		(₹)
																																	</th>
																																	<th
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		Total
																																		(₹)
																																	</th>
																																	<th>Remark
																																		(Max
																																		100
																																		Character)
																																	</th>
																																	<th
																																		class="w-80">
																																		Action
																																	</th>
																																</tr>

																															</thead>
																															<tbody>
																																<ng-container>
																																	<tr *ngFor="let issue of order.get('issues').controls; let j = index"
																																		[formGroupName]="j">
																																		<td
																																			class="w-40">
																																			{{j+1}}
																																		</td>
																																		<td class="w250"
																																			*ngIf="historyData.includes('PENALTY')">
																																			<select
																																				class="form-control form-select"
																																				formControlName="issue"
																																				[ngClass]="{errorBorder : (isIssueSubmitted && !issue.controls.issue.value) || (isNewOrder && !issue.controls.issue.value)}"
																																				[title]="issue.value.issue ? issue.value.issue : ''">
																																				<optgroup
																																					*ngFor='let grp of dataSourceIssue'
																																					label="{{grp.group}}">
																																					<option
																																						*ngFor='let item of grp.items'
																																						[value]="item.name"
																																						title="{{item.name}}">
																																						{{item.name}}
																																					</option>
																																				</optgroup>
																																			</select>
																																			<div class="text-danger"
																																				*ngIf="(isIssueSubmitted && !issue.controls.issue.value) || (isNewOrder && !issue.controls.issue.value)">
																																				<span>Required</span>
																																			</div>
																																		</td>
																																		<td class="w250"
																																			*ngIf="!historyData.includes('PENALTY') ">
																																			<select
																																				class="form-control form-select"
																																				formControlName="issue"
																																				[ngClass]="{errorBorder : (isIssueSubmitted && !issue.controls.issue.value) || (isNewOrder && !issue.controls.issue.value)}"
																																				[title]="issue.value.issue ? issue.value.issue : ''">
																																				<optgroup
																																					*ngFor='let grp of dataSource'
																																					label="{{grp.group}}">
																																					<option
																																						*ngFor='let item of grp.items'
																																						[value]="item.name"
																																						title="{{item.name}}">
																																						{{item.name}}
																																					</option>
																																				</optgroup>
																																			</select>
																																			<div class="text-danger"
																																				*ngIf="(isIssueSubmitted && !issue.controls.issue.value) || (isNewOrder && !issue.controls.issue.value)">
																																				<span>Required</span>
																																			</div>
																																		</td>
																																		<td>
																																			<select
																																				class="form-control form-select"
																																				id="status"
																																				[ngClass]="{errorBorder : (isIssueSubmitted && !issue.controls.status.value) || (isNewOrder && !issue.controls.status.value)}"
																																				formControlName="status"
																																				[title]="issue.value.status ? issue.value.status : ''">
																																				<ng-container
																																					*ngFor="let obj of issueConclusion">
																																					<option
																																						[value]="obj.value">
																																						{{obj.value}}
																																					</option>
																																				</ng-container>
																																			</select>
																																			<div class="text-danger"
																																				*ngIf="(isIssueSubmitted && !issue.controls.status.value) || (isNewOrder && !issue.controls.status.value)">
																																				<span>Required</span>
																																			</div>
																																		</td>
																																		<td>
																																			<select
																																				class="form-control form-select"
																																				id="matterType"
																																				formControlName="matterType"
																																				[title]="issue.value.matterType ? issue.value.matterType : ''">
																																				<ng-container
																																					*ngFor="let obj of matterType">
																																					<option
																																						[value]="obj.value">
																																						{{obj.value}}
																																					</option>
																																				</ng-container>
																																			</select>
																																		</td>
																																		<td>
																																			<div class="input-group">
																																			<input type="text" class="form-control"
																																			  *ngIf="isToggleSegmentDropdown2 && action==='update' || action==='view'" placeholder=""
																																			  formControlName="tag" readonly>
																																			<button *ngIf="isToggleSegmentDropdown2 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleSegmentDropdown('p2')">
																																			  <i class="fa fa-pencil"></i>
																																			</button> 
																																		  </div>
																																			<select class="form-control form-select" id=""
																																			*ngIf="!isToggleSegmentDropdown2 || action==='create'" formControlName="tag">
																																			<option [value]="null">-- Select --</option>
																																			<ng-container *ngFor="let obj of tagDataList">
																																			  <option [value]="obj">{{obj}}</option>
																																			</ng-container>
																																		  </select>
																																		  </td>
																																		<!-- <td><input type="text" formControlName="addnOrDisOfExpensesL" [value]="formatToIndianCurrency(issue.get('addnOrDisOfExpensesL')?.value)" class="form-control"></td> -->
																																		<td
																																			*ngIf="!historyData.includes('PENALTY') ">
																																			<input
																																				type="text"
																																				formControlName="addnOrDisOfExpensesL"
																																				(input)="onInputChange(issue.get('addnOrDisOfExpensesL')?.value,j,'addnOrDisOfExpensesL')"
																																				class="form-control align-right">
																																		</td>
																																		<td
																																			*ngIf="!historyData.includes('PENALTY') ">
																																			<div
																																				class="input-container">
																																				<input
																																					type="text"
																																					formControlName="taxRateL"
																																					[ngClass]="{errorBorder : (issue.get('taxRateL')?.value < 0 || issue.get('taxRateL')?.value > 100)}"
																																					(input)="onKeyPress($event,issue.get('taxRateL')?.value,j,'taxRateL')"
																																					(input)="onInputChange(issue.get('taxRateL')?.value,j,'taxRateL')"
																																					class="form-control input-field align-right">
																																				<i class="icon"
																																					style="position: absolute; transform: translateY(-50%);">%</i>
																																				<div
																																					*ngIf="(issue.get('taxRateL')?.value < 0 || issue.get('taxRateL')?.value > 100)">
																																					<span
																																						class="text-danger"
																																						*ngIf="(issue.get('taxRateL')?.value < 0 || issue.get('taxRateL')?.value > 100)">
																																						Please
																																						Enter
																																						0
																																						to
																																						100</span>
																																				</div>
																																			</div>
																																		</td>
																																		<td
																																			*ngIf="!historyData.includes('PENALTY') ">
																																			<input
																																				type="text"
																																				formControlName="taxImpactL"
																																				(input)="onInputChange(issue.get('taxImpactL')?.value,j,'taxImpactL')"
																																				class="form-control align-right">
																																		</td>
																																		<td
																																			*ngIf="!historyData.includes('PENALTY') ">
																																			<input
																																				type="text"
																																				formControlName="interestL"
																																				(input)="onInputChange(issue.get('interestL')?.value,j,'interestL')"
																																				class="form-control align-right">
																																		</td>
																																		<td><input
																																				type="text"
																																				formControlName="penaltyL"
																																				(input)="onInputChange(issue.get('penaltyL')?.value,j,'penaltyL')"
																																				class="form-control align-right">
																																		</td>
																																		<td
																																			*ngIf="!historyData.includes('PENALTY') ">
																																			<input
																																				type="text"
																																				formControlName="totalL"
																																				(input)="onInputChange(issue.get('totalL')?.value,j,'totalL')"
																																				readonly
																																				class="form-control align-right">
																																		</td>
																																		<td><input
																																				type="text"
																																				formControlName="remarkL"
																																				class="form-control"
																																				[ngClass]="{errorBorder : ((issue.controls.remarkL.value && issue.controls.remarkL.value.length > 100))}">
																																			<div class="text-danger"
																																				*ngIf="((issue.controls.remarkL.value && issue.controls.remarkL.value.length > 100))">
																																				<span>Max
																																					Length
																																					100
																																					character</span>
																																			</div>
																																		</td>
																																		<td
																																			class="w-80">
																																			<a *ngIf="j != 0"
																																				class="addExtLetter"
																																				(click)="openOrderRemove(removeOrderRef)"><i
																																					class="fa fa-minus"></i></a>

																																		</td>
																																		<ng-template
																																			#removeOrderRef
																																			let-modal>
																																			<div
																																				class="modal-header">
																																				<h4 class="modal-title"
																																					id="modal-basic-title">
																																					Remove
																																					Warning!
																																				</h4>
																																				<button
																																					type="button"
																																					class="close"
																																					aria-label="Close"
																																					(click)="modal.dismiss('Cross click')">
																																					<span
																																						aria-hidden="true">×</span>
																																				</button>
																																			</div>
																																			<div
																																				class="modal-body">
																																				<p>Are
																																					you
																																					sure,
																																					you
																																					want
																																					to
																																					remove?
																																				</p>
																																			</div>
																																			<div
																																				class="modal-footer">
																																				<button
																																					type="button"
																																					class="btn btn-outline-dark"
																																					(click)="removeIssueDetails(i,j)">Remove</button>
																																			</div>
																																		</ng-template>

																																	</tr>
																																</ng-container>

																															</tbody>
																															<ng-container>
																																<tr>
																																	<th colspan="4"
																																		class="total-titel">
																																		Total
																																	</th>

																																	<td
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		<input
																																			type="text"
																																			class="form-control bold-font align-right"
																																			[value]="calculateTotals().totOfAddnOrDisOfExpensesLTotal"
																																			id="tax"
																																			placeholder=""
																																			readonly>
																																	</td>
																																	<td *ngIf="!historyData.includes('PENALTY') "
																																		colspan="1">
																																		&nbsp;
																																	</td>
																																	<td
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		<input
																																			type="text"
																																			class="form-control bold-font align-right"
																																			[value]="calculateTotals().taxImpactLTotal"
																																			id="tax"
																																			placeholder=""
																																			readonly>
																																	</td>
																																	<td
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		<input
																																			type="text"
																																			class="form-control bold-font align-right"
																																			[value]="calculateTotals().interestLTotal"
																																			id="tax"
																																			placeholder=""
																																			readonly>
																																	</td>
																																	<td><input
																																			type="text"
																																			class="form-control bold-font align-right"
																																			[value]="calculateTotals().penaltyLTotal"
																																			id="tax"
																																			placeholder=""
																																			readonly>
																																	</td>
																																	<td
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		<input
																																			type="text"
																																			class="form-control bold-font align-right"
																																			[value]="calculateTotals().totOfTotal"
																																			id="tax"
																																			placeholder=""
																																			readonly>
																																	</td>
																																	<td colspan="9"
																																		*ngIf="!historyData.includes('PENALTY') ">
																																		&nbsp;
																																	</td>
																																	<td colspan="7"
																																		*ngIf="caseType =='PENALTY'">
																																		&nbsp;
																																	</td>
																																</tr>
																															</ng-container>
																														</table>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="col-md-12">
																						<div class="accordion accordion-solid-header"
																							id="accordion-53"
																							role="tablist">
																							<div
																								class="card border-bottom">
																								<div class="card-header"
																									role="tab"
																									id="heading-53">
																									<h6 class="mb-0">
																										<a data-toggle="collapse"
																											href="#collapse-53"
																											aria-expanded="true"
																											aria-controls="collapse-53">
																											Appeal
																											Details
																										</a>
																									</h6>
																								</div>
																								<div id="collapse-53"
																									class="collapse show"
																									role="tabpanel"
																									aria-labelledby="heading-53"
																									data-parent="#accordion-53">
																									<div
																										formGroupName="appealDetails">
																										<div
																											class="card-body">
																											<div
																												class="row">
																												<div
																													class="table-responsive">
																													<table
																														class="table table-bordered">
																														<tr
																															class="bgGred">
																															<th
																																class="sl-no-cls">
																																Sr.
																																No.
																															</th>
																															<th
																																style="width: 250px;">
																																Case
																																Movement
																																<span
																																	class="mandatetd"><i
																																		class="fa fa-asterisk"
																																		style="font-size: 7px; margin-left:-7px;"></i></span>
																															</th>
																															<th>Forum
																															</th>
																															<th>Action
																															</th>
																														</tr>
																														<ng-container>
																															<tr *ngFor="let appeal of order.get('appealDetails').controls; let k = index"
																																[formGroupName]="k">
																																<td
																																	class="sl-no-cls">
																																	{{k+1}}
																																</td>
																																<td>
																																	<select
																																		class="form-control form-select"
																																		id="action"
																																		[ngClass]="{errorBorder : isNewOrder && !appeal.controls.action.value}"
																																		formControlName="action"
																																		(change)="setForumOfAppeal()"
																																		[title]="appeal.value.action ? appeal.value.action : ''">
																																		<ng-container
																																			*ngFor="let obj of actionAppealData">
																																			<option
																																				[value]="obj.value">
																																				{{obj.label}}
																																			</option>
																																		</ng-container>
																																	</select>
																																	<div class="text-danger"
																																		*ngIf="isNewOrder && !appeal.controls.action.value">
																																		<span>Required</span>
																																	</div>
																																</td>
																																<td><input
																																		type="text"
																																		class="form-control"
																																		id="forum"
																																		placeholder=""
																																		disabled
																																		formControlName="forum"
																																		[title]="appeal.value.forum ? appeal.value.forum : ''">
																																</td>
																																<td
																																	class="w-80">
																																	<a class="addExtLetter"
																																		*ngIf="(order.get('appealDetails').controls.length - 1) == k "
																																		(click)="addAppealDetails(i)"><i
																																			class="fa fa-plus"
																																			title="Add row"></i>
																																	</a>
																																	<a *ngIf="k != 0"
																																		class="addExtLetter"
																																		(click)="openAppealRemove(removeAppeal)"><i
																																			class="fa fa-minus"></i></a>

																																</td>
																																<ng-template
																																	#removeAppeal
																																	let-modal>
																																	<div
																																		class="modal-header">
																																		<h4 class="modal-title"
																																			id="modal-basic-title">
																																			Remove
																																			Warning!
																																		</h4>
																																		<button
																																			type="button"
																																			class="close"
																																			aria-label="Close"
																																			(click)="modal.dismiss('Cross click')">
																																			<span
																																				aria-hidden="true">×</span>
																																		</button>
																																	</div>
																																	<div
																																		class="modal-body">
																																		<p>Are
																																			you
																																			sure,
																																			you
																																			want
																																			to
																																			remove?
																																		</p>
																																	</div>
																																	<div
																																		class="modal-footer">
																																		<button
																																			type="button"
																																			class="btn btn-outline-dark"
																																			(click)="removeAppealDetails(i,k)">Remove</button>
																																	</div>
																																</ng-template>
																															</tr>
																														</ng-container>
																													</table>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div class="col-md-12 myDiv"
																						id="showYes">
																						<div class="accordion accordion-solid-header"
																							id="accordion-54"
																							role="tablist">
																							<div
																								class="card border-bottom">
																								<div class="card-header"
																									role="tab"
																									id="heading-54">
																									<h6 class="mb-0">
																										<a data-toggle="collapse"
																											href="#collapse-2"
																											aria-expanded="true"
																											aria-controls="collapse-2">
																											Details of Application Made For Appeal Effect Order</a>
																									</h6>
																								</div>
																								<div id="collapse-54"
																									class="collapse show"
																									role="tabpanel"
																									aria-labelledby="heading-54"
																									data-parent="#accordion-54">
																									<div
																										formGroupName="applEffOrderDetails">
																										<div
																											class="card-body">
																											<div
																												class="row">
																												<div
																													class="table-responsive">
																													<table
																														class="table table-bordered">
																														<thead>
																															<tr
																																class="bgGred">
																																<th
																																	class="sl-no-cls">
																																	Sr
																																	No.
																																</th>
																																<th>Date
																																	of
																																	Application
																																</th>
																																<th>Application
																																	copy
																																</th>
																																<th>Name
																																	of
																																	Officer
																																</th>
																																<th>Designation
																																</th>
																																<th>Jurisdiction
																																</th>
																																<th>Date
																																	of
																																	Order
																																</th>
																																<th>Appeal
																																	Effect
																																	Order
																																	Copy
																																</th>
																																<th>Action
																																</th>
																															</tr>
																														</thead>
																														<tbody>
																															<ng-container>
																																<tr *ngFor="let appealEffect of order.get('applEffOrderDetails').controls; let l = index"
																																	[formGroupName]="l">
																																	<td
																																		class="sl-no-cls">
																																		{{l+1}}
																																	</td>
																																	<td>

																																		<p-calendar
																																			appendTo="body"
																																			class="form-control"
																																			[monthNavigator]="true"
																																			formControlName="applDate"
																																			[yearNavigator]="true"
																																			yearRange="2010:{{currentYear}}"
																																			dateFormat="dd-mm-yy"
																																			[showIcon]="true"
																																			dataType="string"
																																			[maxDate]="maxDate">
																																		</p-calendar>
																																	</td>
																																	<td>
																																		<span
																																			(click)="openApplCopyModal(applDocLocPop, i,l)"><i
																																				class="icon fa fa fa-paperclip"
																																				id="paperclip"
																																				title="Attach file"></i></span><span
																																			*ngIf="getapplicationCopyLength(i,l) !== 0">{{getapplicationCopyLength(i,l)}}</span><span
																																			*ngIf="getapplicationCopyLength(i,l) !== 0">
																																			Attachment</span>
																																	</td>
																																	<td><input
																																			type="text"
																																			formControlName="officer"
																																			[ngClass]="{errorBorder : appealEffect.get('officer').value && appealEffect.get('officer').value.length > 100}"
																																			[title]="appealEffect.get('officer').value ? appealEffect.get('officer').value : ''"
																																			class="form-control">
																																		<div
																																			*ngIf="appealEffect.get('officer').value && appealEffect.get('officer').value.length > 100">
																																			<span
																																				class="text-danger"
																																				*ngIf="appealEffect.get('officer').value && appealEffect.get('officer').value.length > 100">
																																				Max
																																				Length
																																				is
																																				100
																																				character
																																			</span>
																																		</div>
																																	</td>
																																	<td><input
																																			type="text"
																																			formControlName="designation"
																																			[ngClass]="{errorBorder : appealEffect.get('designation').value && appealEffect.get('designation').value.length > 100}"
																																			[title]="appealEffect.get('designation').value ? appealEffect.get('designation').value : ''"
																																			class="form-control">
																																		<div
																																			*ngIf="appealEffect.get('designation').value && appealEffect.get('designation').value.length > 100">
																																			<span
																																				class="text-danger"
																																				*ngIf="appealEffect.get('designation').value && appealEffect.get('designation').value.length > 100">
																																				Max
																																				Length
																																				is
																																				100
																																				character
																																			</span>
																																		</div>
																																	</td>
																																	<td><input
																																			type="text"
																																			formControlName="jurisdiction"
																																			[ngClass]="{errorBorder : appealEffect.get('jurisdiction').value && appealEffect.get('jurisdiction').value.length > 100}"
																																			[title]="appealEffect.get('jurisdiction').value ? appealEffect.get('jurisdiction').value : ''"
																																			class="form-control">
																																		<div
																																			*ngIf="appealEffect.get('jurisdiction').value && appealEffect.get('jurisdiction').value.length > 100">
																																			<span
																																				class="text-danger"
																																				*ngIf="appealEffect.get('jurisdiction').value && appealEffect.get('jurisdiction').value.length > 100">
																																				Max
																																				Length
																																				is
																																				100
																																				character
																																			</span>
																																		</div>
																																	</td>
																																	<td>
																																		<p-calendar
																																			appendTo="body"
																																			class="form-control"
																																			[monthNavigator]="true"
																																			formControlName="orderDate"
																																			[yearNavigator]="true"
																																			yearRange="2010:{{currentYear}}"
																																			dateFormat="dd-mm-yy"
																																			[showIcon]="true"
																																			dataType="string"
																																			[maxDate]="maxDate">
																																		</p-calendar>
																																	</td>
																																	<td><span
																																			(click)="openApplOrderCopyModal(applOrderDocLocPop, i,l)"><i
																																				class="icon fa fa fa-paperclip"
																																				id="paperclip"
																																				title="Attach file"></i></span><span
																																			*ngIf="getapplicationOrderCopyLength(i,l) !== 0">{{getapplicationOrderCopyLength(i,l)}}</span><span
																																			*ngIf="getapplicationOrderCopyLength(i,l) !== 0">
																																			Attachment</span>
																																	</td>
																																	<td
																																		class="w-80">
																																		<a class="addExtLetter"
																																			*ngIf="(order.get('applEffOrderDetails').controls.length - 1) == l "
																																			(click)="addApplOrderDetails(i)"><i
																																				class="fa fa-plus"
																																				title="Add row"></i>
																																		</a>
																																		<a *ngIf="l != 0"
																																			class="addExtLetter"
																																			(click)="openAppealEffectRemove(removeAppealEffRef)"><i
																																				class="fa fa-minus"></i></a>

																																	</td>
																																	<ng-template
																																		#removeAppealEffRef
																																		let-modal>
																																		<div
																																			class="modal-header">
																																			<h4 class="modal-title"
																																				id="modal-basic-title">
																																				Remove
																																				Warning!
																																			</h4>
																																			<button
																																				type="button"
																																				class="close"
																																				aria-label="Close"
																																				(click)="modal.dismiss('Cross click')">
																																				<span
																																					aria-hidden="true">×</span>
																																			</button>
																																		</div>
																																		<div
																																			class="modal-body">
																																			<p>Are
																																				you
																																				sure,
																																				you
																																				want
																																				to
																																				remove?
																																			</p>
																																		</div>
																																		<div
																																			class="modal-footer">
																																			<button
																																				type="button"
																																				class="btn btn-outline-dark"
																																				(click)="removeApplOrderDetails(i,l)">Remove</button>
																																		</div>
																																	</ng-template>
																																	<ng-template
																																		#applDocLocPop
																																		let-modal>
																																		<div class="modal-header"
																																			style="border: none; padding-bottom: 0px;">
																																			<button
																																				type="button"
																																				class="close"
																																				aria-label="Close"
																																				(click)="modal.dismiss('Cross click')">
																																				<span
																																					aria-hidden="true">×</span>
																																			</button>
																																		</div>
																																		<div
																																			class="col-md-12">
																																			<label>
																																				Attachment</label>
																																			<div
																																				class="table-responsive">
																																				<table
																																					class="table table-bordered">
																																					<thead>
																																						<tr
																																							class="bgGred">
																																							<th>
																																								Upload
																																							</th>
																																							<th
																																								style="width:50%;">
																																								Remark
																																								(Max
																																								100
																																								Character
																																								allowed)
																																							</th>
																																						</tr>
																																					</thead>
																																					<tbody>
																																						<tr *ngFor="let applLocGrp of getApplCopyLoc(i,l); let m = index"
																																							[formGroup]="applLocGrp">
																																							<td>
																																								<div
																																									class="row">
																																									<div
																																										class="col-md-9 nopadding">
																																										<div
																																											class="form-group">
																																											<input
																																												*ngIf="applLocGrp?.value?.isdocLocUploadedClicked"
																																												type="file"
																																												id="orderDtls({{i}}).applEffOrderDetails({{k}}).applCopyLoc({{l}}).fileLoc"
																																												class="filetype form-control"
																																												name="myfile"
																																												(change)="onFileSelectUpload($event,i,{form:'phase3Form',first : 'orderDtls',firstIndex: i,second:'applEffOrderDetails',secondIndex:l,third:'applCopyLoc',thirdIndex:m})">
																																											<p *ngIf="!applLocGrp?.value?.isdocLocUploadedClicked || applLocGrp?.value.fileLoc"
																																												class="form-control pt10">
																																												{{getFileNameDta(applLocGrp.value.fileLoc)}}
																																											</p>
																																										</div>
																																									</div>
																																									<div
																																										class="col-md-3 nopadding">
																																										<label
																																											for="exampleInputPassword1">
																																											<a class="downupIcon"
																																												*ngIf="applLocGrp?.value.fileLoc"
																																												title="Download file"
																																												(click)="download(applLocGrp.value.fileLoc)"><i
																																													class="fa fa-download"></i></a>
																																											<a class="downupIcon"
																																												*ngIf="!applLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																												title="Upload file"
																																												(click)="uploadApplLoc(i,l,m)"><i
																																													class="fa fa-upload"></i></a>
																																											<a class="downupIcon"
																																												(click)="deleteApplLoc(i,l,m)"
																																												*ngIf="(applLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																													class="fa fa-trash"
																																													aria-hidden="true"></i></a>
																																										</label>
																																									</div>
																																								</div>
																																							</td>
																																							<td>
																																								<input
																																									type="text"
																																									class="form-control"
																																									formControlName="remarks"
																																									[title]="applLocGrp.value.remarks ? applLocGrp.value.remarks : ''">
																																								<div
																																									*ngIf="applLocGrp.value.remarks && applLocGrp.value.remarks.length > 100">
																																									<span
																																										class="text-danger">Remark
																																										Max
																																										character
																																										Length
																																										100</span>
																																								</div>
																																							</td>
																																							<ng-template
																																								#rmitrFPop
																																								let-modal>
																																								<div
																																									class="modal-header">
																																									<h4 class="modal-title"
																																										id="modal-basic-title">
																																										Remove
																																										Warning!
																																									</h4>
																																									<button
																																										type="button"
																																										class="close"
																																										aria-label="Close"
																																										(click)="modal.dismiss('Cross click')">
																																										<span
																																											aria-hidden="true">×</span>
																																									</button>
																																								</div>
																																								<div
																																									class="modal-body">
																																									<p>Are
																																										you
																																										sure,
																																										you
																																										want
																																										to
																																										remove?
																																									</p>
																																								</div>
																																								<div
																																									class="modal-footer">
																																									<button
																																										type="button"
																																										class="btn btn-outline-dark">Remove</button>
																																								</div>
																																							</ng-template>
																																						</tr>
																																					</tbody>
																																				</table>
																																			</div>
																																		</div>
																																		<div class="modal-footer"
																																			style="border: none; padding-top: 0px;">
																																			<button
																																				type="button"
																																				class="btn btn-outline-dark"
																																				(click)="modal.close('Save click')">Save</button>
																																		</div>
																																	</ng-template>
																																	<ng-template
																																		#applOrderDocLocPop
																																		let-modal>
																																		<div class="modal-header"
																																			style="border: none; padding-bottom: 0px;">
																																			<button
																																				type="button"
																																				class="close"
																																				aria-label="Close"
																																				(click)="modal.dismiss('Cross click')">
																																				<span
																																					aria-hidden="true">×</span>
																																			</button>
																																		</div>
																																		<div
																																			class="col-md-12">
																																			<label>
																																				Attachment</label>
																																			<div
																																				class="table-responsive">
																																				<table
																																					class="table table-bordered">
																																					<thead>
																																						<tr
																																							class="bgGred">
																																							<th>
																																								Upload
																																							</th>
																																							<th
																																								style="width:50%;">
																																								Remark
																																								(Max
																																								100
																																								Character
																																								allowed)
																																							</th>
																																						</tr>
																																					</thead>
																																					<tbody>
																																						<tr *ngFor="let appOrderlLocGrp of getApplOrderCopyLoc(i,l); let n = index"
																																							[formGroup]="appOrderlLocGrp">
																																							<td>
																																								<div
																																									class="row">
																																									<div
																																										class="col-md-9 nopadding">
																																										<div
																																											class="form-group">
																																											<input
																																												*ngIf="appOrderlLocGrp?.value?.isdocLocUploadedClicked"
																																												type="file"
																																												id="orderDtls({{i}}).applEffOrderDetails({{k}}).applOrderCopyLoc({{l}}).fileLoc"
																																												class="filetype form-control"
																																												name="myfile"
																																												(change)="onFileSelectUpload($event,i,{form:'phase3Form',first : 'orderDtls',firstIndex: i,second:'applEffOrderDetails',secondIndex:l,third:'applOrderCopyLoc',thirdIndex:n})">
																																											<p *ngIf="!appOrderlLocGrp?.value?.isdocLocUploadedClicked || appOrderlLocGrp?.value.fileLoc"
																																												class="form-control pt10">
																																												{{getFileNameDta(appOrderlLocGrp.value.fileLoc)}}
																																											</p>
																																										</div>
																																									</div>
																																									<div
																																										class="col-md-3 nopadding">
																																										<label
																																											for="exampleInputPassword1">
																																											<a class="downupIcon"
																																												*ngIf="appOrderlLocGrp?.value.fileLoc"
																																												title="Download file"
																																												(click)="download(appOrderlLocGrp.value.fileLoc)"><i
																																													class="fa fa-download"></i></a>
																																											<a class="downupIcon"
																																												*ngIf="!appOrderlLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																												title="Upload file"
																																												(click)="uploadApplOrderLoc(i,l,n)"><i
																																													class="fa fa-upload"></i></a>
																																											<a class="downupIcon"
																																												(click)="deleteApplOrderLoc(i,l,n)"
																																												*ngIf="(appOrderlLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																													class="fa fa-trash"
																																													aria-hidden="true"></i></a>
																																										</label>
																																									</div>
																																								</div>
																																							</td>
																																							<td>
																																								<input
																																									type="text"
																																									class="form-control"
																																									formControlName="remarks"
																																									[title]="appOrderlLocGrp.value.remarks ? appOrderlLocGrp.value.remarks : ''">
																																								<div
																																									*ngIf="appOrderlLocGrp.value.remarks && appOrderlLocGrp.value.remarks.length > 100">
																																									<span
																																										class="text-danger">Remark
																																										Max
																																										character
																																										Length
																																										100</span>
																																								</div>
																																							</td>
																																							<ng-template
																																								#rmitrFPop
																																								let-modal>
																																								<div
																																									class="modal-header">
																																									<h4 class="modal-title"
																																										id="modal-basic-title">
																																										Remove
																																										Warning!
																																									</h4>
																																									<button
																																										type="button"
																																										class="close"
																																										aria-label="Close"
																																										(click)="modal.dismiss('Cross click')">
																																										<span
																																											aria-hidden="true">×</span>
																																									</button>
																																								</div>
																																								<div
																																									class="modal-body">
																																									<p>Are
																																										you
																																										sure,
																																										you
																																										want
																																										to
																																										remove?
																																									</p>
																																								</div>
																																								<div
																																									class="modal-footer">
																																									<button
																																										type="button"
																																										class="btn btn-outline-dark">Remove</button>
																																								</div>
																																							</ng-template>
																																						</tr>
																																					</tbody>
																																				</table>
																																			</div>
																																		</div>
																																		<div class="modal-footer"
																																			style="border: none; padding-top: 0px;">
																																			<button
																																				type="button"
																																				class="btn btn-outline-dark"
																																				(click)="modal.close('Save click')">Save</button>
																																		</div>
																																	</ng-template>
																																</tr>
																															</ng-container>
																														</tbody>


																													</table>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>




																					<!-- <div class="col-md-12 mt15 mb-3">
                                               <button class="btn btn-outline-primary mr-1" (click)="addNewOrder(i)">Add
                                                  Order</button>
                                               <button class="btn btn-outline-primary mr-1 " (click)="removeOrder(i)">Remove
                                                  Order</button>
                                            </div> -->
																					<div class="col-md-12 mt15 mb-3">
																						<button
																							class="btn btn-outline-primary mr-1"
																							(click)="nextForPhase3Order()">Next</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</ng-container>
													</div>
												</div>
											</div>
										</fieldset>
									</div>

									<div class="tab-pane fade" id="aatp-2" role="tabpanel"
										[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="aatp-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div class="col-md-12 nopadding">
												<p class="textCenter"> Tax Payment and Refund details</p>
												<span class="toggleTab" (click)="sidemenu=!sidemenu">
													<i class="mdi mdi mdi-chevron-double-left leftTIcon"
														*ngIf="!sidemenu"></i>
													<i class="mdi mdi-chevron-double-right rightTIcon"
														*ngIf="sidemenu"></i>
												</span>
											</div>
											<div class="col-md-12">
												<div class="accordion accordion-solid-header" id="accordion-30"
													role="tablist">
													<div class="card border-bottom">
														<div class="card-header" role="tab" id="heading-30">
															<h6 class="mb-0">
																<a data-toggle="collapse" href="#collapse-30"
																	aria-expanded="true" aria-controls="collapse-30">
																	Details of Assessed Tax Paid
																</a>
															</h6>
														</div>
														<div id="collapse-30" class="collapse show" role="tabpanel"
															aria-labelledby="heading-30" data-parent="#accordion-30">
															<div formArrayName="libDetails">
																<div class="card-body">
																	<div class="row">
																		<div class="table-responsive">
																			<table class="table table-bordered">
																				<thead>
																					<tr class="bgGred">
																						<th class="sl-no-cls">Sr No.
																						</th>
																						<!-- <th>Challan No.</th> -->
																						<th>CIN</th>
																						<th>Date</th>
																						<th>BSR Code</th>
																						<th>Tax (₹)</th>
																						<th>Interest (₹)</th>
																						<th>Total (₹)</th>
																						<th>Challan Copy</th>
																						<th>Action</th>
																					</tr>
																				</thead>
																				<tbody>
																					<ng-container
																						*ngIf="phase3Form.get('libDetails')">
																						<tr *ngFor="let detail of phase3Form.get('libDetails').controls; let i = index"
																							[formGroupName]="i">
																							<td class="sl-no-cls">
																								{{i+1}}</td>
																							<!-- <td><input type="text" formControlName="srNo"
                                                                           class="form-control"></td> -->
																							<td><input type="text"
																									formControlName="cinRefNo"
																									class="form-control">
																							</td>
																							<td>
																								<p-calendar
																									appendTo="body"
																									class="form-control"
																									[monthNavigator]="true"
																									formControlName="date"
																									[yearNavigator]="true"
																									yearRange="2010:{{currentYear}}"
																									dateFormat="dd-mm-yy"
																									[showIcon]="true"
																									dataType="string"
																									[maxDate]="maxDate">
																								</p-calendar>
																								<!-- <p-calendar appendTo="body" class="form-control" [monthNavigator]="true" formControlName="date"
                                                                    [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy"
                                                                    [showIcon]="true" dataType="string" [maxDate]="maxDate">
                                                                 </p-calendar> -->
																							</td>
																							<td><input type="text"
																									formControlName="bsrCode"
																									class="form-control">
																							</td>
																							<td><input type="text"
																									formControlName="tax"
																									(input)="onInputChange(detail.get('tax')?.value,i,'tax')"
																									class="form-control align-right">
																							</td>
																							<td><input type="text"
																									formControlName="interest"
																									(input)="onInputChange(detail.get('interest')?.value,i,'interest')"
																									class="form-control align-right">
																							</td>
																							<td><input type="text"
																									readonly
																									formControlName="total"
																									(input)="onInputChange(detail.get('total')?.value,i,'total')"
																									class="form-control align-right">
																							</td>

																							<td>
																								<span
																									(click)="openLibChallanCopy(challanDocLoc, i)"><i
																										class="icon fa fa fa-paperclip"
																										id="paperclip"
																										title="Attach file"></i></span><span
																									*ngIf="getChallanCopyLocLength(i) !== 0">{{getChallanCopyLocLength(i)}}</span><span
																									*ngIf="getChallanCopyLocLength(i) !== 0">
																									Attachment</span>
																							</td>


																							<td class="w-80">
																								<a class="addExtLetter"
																									*ngIf="phase3Form.get('libDetails')?.controls.length - 1 == i"
																									(click)="addNewLibDetails(i)"><i
																										class="fa fa-plus"
																										title="Add row"></i>
																								</a>
																								<a class="addExtLetter"
																									*ngIf="i != 0"
																									(click)="openLibRemove(removeLibffRef)"><i
																										class="fa fa-minus"></i></a>

																							</td>
																							<ng-template #removeLibffRef
																								let-modal>
																								<div
																									class="modal-header">
																									<h4 class="modal-title"
																										id="modal-basic-title">
																										Remove Warning!
																									</h4>
																									<button
																										type="button"
																										class="close"
																										aria-label="Close"
																										(click)="modal.dismiss('Cross click')">
																										<span
																											aria-hidden="true">×</span>
																									</button>
																								</div>
																								<div class="modal-body">
																									<p>Are you sure, you
																										want to remove?
																									</p>
																								</div>
																								<div
																									class="modal-footer">
																									<button
																										type="button"
																										class="btn btn-outline-dark"
																										(click)="removeLibDetails(i)">Remove</button>
																								</div>
																							</ng-template>
																							<ng-template #challanDocLoc
																								let-modal>
																								<div class="modal-header"
																									style="border: none; padding-bottom: 0px;">
																									<button
																										type="button"
																										class="close"
																										aria-label="Close"
																										(click)="modal.dismiss('Cross click')">
																										<span
																											aria-hidden="true">×</span>
																									</button>
																								</div>
																								<div class="col-md-12">
																									<label>
																										Attachment</label>
																									<div
																										class="table-responsive">
																										<table
																											class="table table-bordered">
																											<thead>
																												<tr
																													class="bgGred">
																													<th>Upload
																													</th>
																													<th
																														style="width:50%;">
																														Remark
																														(Max
																														100
																														Character
																														allowed)
																													</th>
																												</tr>
																											</thead>
																											<tbody>
																												<tr *ngFor="let challanLocGrp of getChallanLoc(i); let j = index"
																													[formGroup]="challanLocGrp">
																													<td>
																														<div
																															class="row">
																															<div
																																class="col-md-9 nopadding">
																																<div
																																	class="form-group">
																																	<input
																																		*ngIf="challanLocGrp?.value?.isdocLocUploadedClicked"
																																		type="file"
																																		id="libDetails({{i}}).challanCopyLoc({{j}}).fileLoc"
																																		class="filetype form-control"
																																		name="myfile"
																																		(change)="onFileSelectUpload($event,i,{form:'phase3Form',first : 'libDetails',firstIndex: i,second:'challanCopyLoc',secondIndex:j})">
																																	<p *ngIf="!challanLocGrp?.value?.isdocLocUploadedClicked || challanLocGrp?.value.fileLoc"
																																		class="form-control pt10">
																																		{{getFileNameDta(challanLocGrp.value.fileLoc)}}
																																	</p>
																																</div>
																															</div>
																															<div
																																class="col-md-3 nopadding">
																																<label
																																	for="exampleInputPassword1">
																																	<a class="downupIcon"
																																		*ngIf="challanLocGrp?.value.fileLoc"
																																		title="Download file"
																																		(click)="download(challanLocGrp.value.fileLoc)"><i
																																			class="fa fa-download"></i></a>
																																	<a class="downupIcon"
																																		*ngIf="!challanLocGrp?.value.fileLoc && !['DT_Viewer'].includes(roleName)"
																																		title="Upload file"
																																		(click)="uploaChallanLoc(i,j)"><i
																																			class="fa fa-upload"></i></a>
																																	<a class="downupIcon"
																																		(click)="deleteChallanLoc(i,j)"
																																		*ngIf="(challanLocGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
																																			class="fa fa-trash"
																																			aria-hidden="true"></i></a>
																																</label>
																															</div>
																														</div>
																													</td>
																													<td>
																														<input
																															type="text"
																															class="form-control"
																															formControlName="remarks"
																															[title]="challanLocGrp.value.remarks ? challanLocGrp.value.remarks : ''">
																														<div
																															*ngIf="challanLocGrp.value.remarks && challanLocGrp.value.remarks.length > 100">
																															<span
																																class="text-danger">Remark
																																Max
																																character
																																Length
																																100</span>
																														</div>
																													</td>
																													<ng-template
																														#rmitrFPop
																														let-modal>
																														<div
																															class="modal-header">
																															<h4 class="modal-title"
																																id="modal-basic-title">
																																Remove
																																Warning!
																															</h4>
																															<button
																																type="button"
																																class="close"
																																aria-label="Close"
																																(click)="modal.dismiss('Cross click')">
																																<span
																																	aria-hidden="true">×</span>
																															</button>
																														</div>
																														<div
																															class="modal-body">
																															<p>Are
																																you
																																sure,
																																you
																																want
																																to
																																remove?
																															</p>
																														</div>
																														<div
																															class="modal-footer">
																															<button
																																type="button"
																																class="btn btn-outline-dark">Remove</button>
																														</div>
																													</ng-template>
																												</tr>
																											</tbody>
																										</table>
																									</div>
																								</div>
																								<div class="modal-footer"
																									style="border: none; padding-top: 0px;">
																									<button
																										type="button"
																										class="btn btn-outline-dark"
																										(click)="modal.close('Save click')">Save</button>
																								</div>
																							</ng-template>

																						</tr>
																					</ng-container>
																				</tbody>


																			</table>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-12">
												<div class="accordion accordion-solid-header" id="accordion-30"
													role="tablist">
													<div class="card border-bottom">
														<div class="card-header" role="tab" id="heading-30">
															<h6 class="mb-0">
																<a data-toggle="collapse" href="#collapse-30"
																	aria-expanded="true" aria-controls="collapse-30">
																	Details of Refund Received
																</a>
															</h6>
														</div>
														<div id="collapse-30" class="collapse show" role="tabpanel"
															aria-labelledby="heading-30" data-parent="#accordion-30">
															<div formArrayName="refundDetails">
																<div class="card-body">
																	<div class=" row">
																		<div class="col-md-12">
																			<div class="search-table-outter wrapper">
																				<table class="table inner ">
																					<thead>
																						<tr class="bgGred">
																							<th class="sl-no-cls">Sr No.
																							</th>
																							<th>Date of Receipt</th>
																							<th>Mode</th>
																							<th>Tax (₹)</th>
																							<th>Interest (₹)</th>
																							<th>Total (₹)</th>

																							<th class="w-80">Action</th>
																						</tr>

																					</thead>
																					<tbody>
																						<ng-container
																							*ngIf="phase3Form.get('refundDetails')">
																							<tr *ngFor="let detail of phase3Form.get('refundDetails').controls; let i = index"
																								[formGroupName]="i">
																								<td class="sl-no-cls">
																									{{i+1}}</td>

																								<td>
																									<p-calendar
																										appendTo="body"
																										class="form-control"
																										[monthNavigator]="true"
																										formControlName="receiptDate"
																										[yearNavigator]="true"
																										yearRange="2010:{{currentYear}}"
																										dateFormat="dd-mm-yy"
																										[showIcon]="true"
																										dataType="string"
																										[maxDate]="maxDate">
																									</p-calendar>
																								</td>

																								<td>
																									<select
																										class="form-control form-select"
																										id="mode"
																										formControlName="mode"
																										[title]="detail.value.mode ? detail.value.mode : ''">
																										<ng-container
																											*ngFor="let obj of mode">
																											<option
																												[value]="obj.value">
																												{{obj.value}}
																											</option>
																										</ng-container>
																									</select>
																								</td>
																								<td><input type="text"
																										formControlName="tax"
																										(input)="onInputRefund(detail.get('tax')?.value,i,'tax')"
																										class="form-control align-right">
																								</td>
																								<td><input type="text"
																										formControlName="interest"
																										(input)="onInputRefund(detail.get('interest')?.value,i,'interest')"
																										class="form-control align-right">
																								</td>
																								<td><input type="text"
																										formControlName="total"
																										readonly
																										(input)="onInputRefund(detail.get('total')?.value,i,'total')"
																										class="form-control align-right">
																								</td>
																								<td class="w-80">
																									<a class="addExtLetter"
																										*ngIf="phase3Form.get('refundDetails').controls.length - 1 == i"
																										(click)="addNewRefundDetail(i)"><i
																											class="fa fa-plus"
																											title="Add row"></i>
																									</a>
																									<a class="addExtLetter"
																										*ngIf="i != 0"
																										(click)="openRefundRemove(removeRefundRef)"><i
																											class="fa fa-minus"></i></a>

																								</td>
																								<ng-template
																									#removeRefundRef
																									let-modal>
																									<div
																										class="modal-header">
																										<h4 class="modal-title"
																											id="modal-basic-title">
																											Remove
																											Warning!
																										</h4>
																										<button
																											type="button"
																											class="close"
																											aria-label="Close"
																											(click)="modal.dismiss('Cross click')">
																											<span
																												aria-hidden="true">×</span>
																										</button>
																									</div>
																									<div
																										class="modal-body">
																										<p>Are you sure,
																											you want to
																											remove?</p>
																									</div>
																									<div
																										class="modal-footer">
																										<button
																											type="button"
																											class="btn btn-outline-dark"
																											(click)="removeRefundDetail(i)">Remove</button>
																									</div>
																								</ng-template>

																							</tr>
																						</ng-container>

																					</tbody>

																				</table>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>




											<div class="col-md-12 noPadding mt15">
												<button class="btn btn-outline-primary mr-1"
													(click)="nextForCumulative()">Next</button>
											</div>
										</fieldset>
									</div>
									<div class="tab-pane fade" id="aact-2" role="tabpanel"
										[ngClass]="{'bgGray': (action == 'view')}" aria-labelledby="aact-tab-vertical">
										<fieldset [disabled]="action == 'view'">
											<div formGroupName="cumulativeTotals">
												<div class="col-md-12 nopadding">
													<p class="textCenter"> Cumulative Tax Details</p>
													<span class="toggleTab" (click)="sidemenu=!sidemenu">
														<i class="mdi mdi mdi-chevron-double-left leftTIcon"
															*ngIf="!sidemenu"></i>
														<i class="mdi mdi-chevron-double-right rightTIcon"
															*ngIf="sidemenu"></i>
													</span>
												</div>
												<div class="col-md-12">
													<div class="row">
														<div class="col-md-12 table-responsive">
															<table class="table table-bordered">
																<tr class="bgGred">
																	<th>Particulars</th>
																	<th>Tax(₹)</th>
																	<th>Interest (₹) </th>
																	<th>Penalty (₹) </th>
																	<th>Total (₹) </th>
																</tr>
																<tr *ngFor="let c of cumulativeData">
																	<td>{{c.label}}</td>
																	<td class="align-right">{{c.tax | INR :0 }}</td>
																	<td class="align-right">{{ c.interest | INR :0 }}
																	</td>
																	<td class="align-right">{{ c.penalty | INR :0 }}
																	</td>
																	<td class="align-right">{{ c.total | INR :0 }}</td>
																</tr>
															</table>
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-12 noPadding mt15 mb-3">
												<button class="btn btn-outline-primary mr-1"
													(click)="updatePhase2()">Submit</button>
											</div>
										</fieldset>
									</div>


								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { BusinessService } from '../../services/business.service';

@Component({
  selector: 'app-interest-calculation',
  templateUrl: './interest-calculation.component.html',
  styleUrl: './interest-calculation.component.css'
})
export class InterestCalculationComponent implements OnInit{
  
  emailForm!: UntypedFormGroup;
  selected: boolean = false;
  isDisabled: boolean = true;
  intCalOptOutRes:any;
  selectedOpt:any;
  changeNotification : any;
  onLoadselectedOpt:any;

  constructor(    
    private fBuild: UntypedFormBuilder,
    private router: Router,
    private toaster: ToasterService,
    private businessSerivce: BusinessService,
  ) { }

  ngOnInit() {
    this.intCalOptOutData();
     this.selected = this.selectedOpt;
    }

  toDashboard() {
    this.router.navigate(['/dashboard']);
  }
 
  intCalOptOutData() {    
    this.businessSerivce.getIntCalOptOutData().subscribe((response: any) => {
      if (response.status === 1) {
        this.intCalOptOutRes = response.response;
        this.selectedOpt = this.intCalOptOutRes.activated;
        if(this.selectedOpt == false || this.selected == false){
          this.selected = false;          
        } else{
          this.selected = true;
         
        }
      }
    });

  } 
  changeValue(selected:any){ 
    if(selected == false){
      this.changeNotification = true;
    }else{
      this.changeNotification = false;
    }
    this.isDisabled = false;
  }
  submitIntCalOptOut(){
  
    this.businessSerivce.submitIntCalOptOutFunction(this.changeNotification).subscribe((response: any) => {    
   
      if (response.status == 1 && this.changeNotification == true) {    
        this.toaster.showSuccess('Auto schedular for Interest Calculation has been ACTIVATED');    
        
      } else {
        this.toaster.showSuccess('Auto schedular for Interest Calculation has been DEACTIVATED');
      }     
    });
  }
}

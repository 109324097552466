export const environment = {
  production: true,
  apiUrl: 'https://dev.api.irisgst.com/irisgst', //dev
  uumUrl: 'https://dev.app.irisgst.com/logout-all-session', //dev
  baseUrl: 'https://dev.api.irisgst.com/irisgst/lms2',
  GA_TRACKING_ID: '',
  lmsUrl: 'https://dev.lms.irisgst.com',
  envName:'dev'

};

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface GetConsultantParam{
  consultantName: string,
  firmName: string
}

export interface ConsultantMastreDetilas{
  firmName: string;
  consultantName: string;
  email: string;
  contact: string;
  address: string;
  rootCompanyId: number;
  createDate: string;
  updateDate: string;
}

export interface ConsultantMaster{
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
export interface TagMaster{
  tag: string;
  active: string;
  id?: string;
}


export interface companyTagData{
  companyId: number;
  tags: { tag: string }[];
}
export interface pregstTagData{
  preGstReg: string;
  tags: { tag: string }[];
}

export interface ConsultantMastreResponse{
  data: ConsultantMastreDetilas[],
  totalRecords: number
}

export interface UploadMasterCSV{
  masterType: string,
  file: File
}

@Injectable({
  providedIn: 'root'
})

export class MasterService {

  constructor(
    private http: HttpClient
  ) { }

  getMasterList(){
    return this.http.get(`${environment.apiUrl}/lms2/master/types`).pipe(map((resp:any) => {
      return resp;
    }));
  }

  getAllConsultantMaster(){
    return this.http.get(`${environment.apiUrl}/lms2/consultant/master/all`).pipe(map((resp:any) => {
      return resp;
    }));
  }
 

  getConsultant(data:GetConsultantParam){
    return this.http.get(`${environment.apiUrl}/lms2/consultant/master/get?`,{
      params: {
        consultantName: data.consultantName,
        firm: data.firmName
      }
    }).pipe(map((resp:any) => {
      return resp;
    }));
  }

  getUploadTemplate(data:string){
    return this.http.get(`${environment.apiUrl}/lms2/master/template?`, {
      params: { masterType : data }
    });
  }
  
  uploadMasterCSV(data: FormData){
    return this.http.post(`${environment.apiUrl}/lms2/master/upload`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }

  addConsultantMaster(data: ConsultantMaster){
    return this.http.post(`${environment.apiUrl}/lms2/consultant/master/add`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }
    
  editConsultantMaster(data: ConsultantMaster){
    return this.http.put(`${environment.apiUrl}/lms2/consultant/master/edit`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }

  deleteConsultantMaster(data: ConsultantMaster){
    const payload = {
      body : data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/consultant/master/delete`, payload).pipe(map((resp:any) => {
      return resp;
    }));
  }

  deleteAllConsultantMaster(data:string){
    return this.http.delete(`${environment.apiUrl}/lms2/master/delete?`, {params: { masterType : data }}).pipe(map((resp:any) => {
      return resp;
    }));
  }

  watchlistUpdate(data:any){
    const payload = {
      userMailId : data.email,
      companyId : data.companyId,
    }
    console.log("API Upload:", payload);
    return this.http.post(`${environment.apiUrl}/lms2/wl/update/consultant`, payload).pipe(map((resp:any) => {
      return resp;
    }));
  }
  getAllSegmentMaster(){
    return this.http.get(`${environment.apiUrl}/lms2/segment/master/all`).pipe(map((resp:any) => {
      return resp;
    }));
  }
  addSegmentMaster(data: ConsultantMaster){
    return this.http.post(`${environment.apiUrl}/lms2/segment/master/add`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }
    
  editSegmentMaster(data: ConsultantMaster){
    return this.http.put(`${environment.apiUrl}/lms2/segment/master/edit`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }
  deleteSegment(data: ConsultantMaster){
    const payload = {
      body : data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/segment/master/delete`, payload).pipe(map((resp:any) => {
      return resp;
    }));
  }

  getAllTagMaster(){
    return this.http.get(`${environment.apiUrl}/lms2/tag/master/all`).pipe(map((resp:any) => {
      return resp;
    }));
  }
  editTagMaster(data: TagMaster){
    return this.http.post(`${environment.apiUrl}/lms2/tag/master/save`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }
  addTagMaster(data: ConsultantMaster){
    return this.http.post(`${environment.apiUrl}/lms2/tag/master/save`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }
    
  
  deleteTag(data: ConsultantMaster){
    const payload = {
      body : data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/segment/master/delete`, payload).pipe(map((resp:any) => {
      return resp;
    }));
  }
  downloadTagTemplate(data:string){
    return this.http.get(`${environment.apiUrl}/lms2/master/template?`, {
      params: { masterType : data }
    });
  }
  
  uploadTagMasterCSV(data: FormData){
    return this.http.post(`${environment.apiUrl}/lms2/master/upload`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }
  getEntityTagsCase(data:any){
    return this.http.get(`${environment.apiUrl}/lms2/entitytag/all`, {
      params: { regNo : data }
    });
  }
  getEntityTagsCaseDt(data:any){
    return this.http.get(`${environment.apiUrl}/lms2/entitytag/all`, {
      params: { companyId : data }
    });
  }
  getEntityTags(){
    return this.http.get(`${environment.apiUrl}/lms2/entitytag/all`).pipe(map((resp:any) => {
      return resp;
    }));
  }
  addCompanyTag(data: companyTagData[]){
    return this.http.post(`${environment.apiUrl}/lms2/entitytag/save`, data).pipe(map((resp:any) => {
      return resp;
    }));
  }
  
    exportTagMaster(data:string){
      return this.http.get(`${environment.apiUrl}/lms2/master/export?`, {
        params: { masterType : data },
        responseType: 'text'
      });
    }

    getpregstEntityTags(){
      return this.http.get(`${environment.apiUrl}/lms2/pregst/entitytag/all`).pipe(map((resp:any) => {
        return resp;
      }));
    }

    getpregstEntityTagsCase(data:any){
      return this.http.get(`${environment.apiUrl}/lms2/pregst/entitytag/all`, {
        params: { preGstReg : data }
      });
    }
    savePreGstTags(data: pregstTagData[]){
      return this.http.post(`${environment.apiUrl}/lms2/pregst/entitytag/save`, data).pipe(map((resp:any) => {
        return resp;
      }));
    }
    
  
    }

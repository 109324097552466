import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { values } from 'lodash-es';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { ADJ_APPELPRE, ADJ_OUTCOME, APPA_PH1_APPELUS, TAB_TYPE, ADJ_PH1_ADJUNOT, ADJ_ATTEND, Apa_PH1_PREDEPOAPP, LETTER_GSTIN, STATE_LIST, APPEAL_AGAINST, APA_PH1_PAY_MODE, ISSUE_RISK_PARAM, ISSUE_CONCLUSION, ISSUE_CONCLUSION_A, ADJ_DEPTPRE, QUARTER, YES_NO, ISSUING_AUTHORITY_TYPE, MODE_OF_RECEIPT, PAYMENT, MODE_OF_PAYMENT, FORM_TYPE_DETAILS_OF_APPEAL, MEMORANDOM_OUTCOME, ISSUE_CONCLUSION_COMMISSIONER, CASE_TYPES, FORUM_APPEAL_APPELLATE_TRIBUNAL, ISSUE_CONCLUSION_COMMISSIONERS, ISSUE_GST, MODE_OF_PH, MODE_OF_TIME_PH, INT_RATE } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { LitigationServiceService } from '../services/litigation-service.service';
import { log } from 'console';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';
import { forkJoin } from 'rxjs';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  GSTIN ='gstin',
  DIVISION ='gstinDiv',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}
interface PaymentDetails {
  issueName: any;
  refId: any;
  tag: any;
  stage: any;
  challanNo:any;
  markedForPayment: any;
}

interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
interface tagData {
  tags: string;
}
@Component({
  selector: 'app-sc',
  templateUrl: './sc.component.html',
  styleUrls: ['./sc.component.css'],
})
export class SCComponent implements OnInit {
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> =
    new EventEmitter<string[] | null>();

  parentCaseId: any;
  activeStages: string[] | null = null;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  intCalPopData:any;
  intCalIssueData:any;
  dataSource: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;
  selectedTab: any;
  selectedPhase: any;
  savedCaseId: any;
  caseId: any;
  action: any;
  caseData: any = {};
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  obj0: any = {};
  duplicateData:any;
  issueData: any;  

  adjornot: any;
  maxDate = new Date();
  drc03CopyLocF: any;
  copyOfAckLocF: any;
  otherAttachmentsLoc2F: any;
  phCopyLocF: any;
  additionalSubmissionLocF: any;
  phRecordIfAnyLocF: any;
  supremeCourtOrderLocF: any;
  paymentChallanLocF: any;
  getAccessTabData: any;
  selectedFilling: any;
  selectedGstin: any;
  selectedGstinDiv: any;
  selectedState: any;
  selectedStateCode: any;
  selectedStateName: any;
  matches: any;
  companyId: any;
  navContext: any;
  autoIssueList:any;
  autoTagList:any;
  scForm!: UntypedFormGroup;
  appAuthP2Form!: UntypedFormGroup;
  appAuthP3Form!: UntypedFormGroup;
  isDisableIcons: boolean = false;
  isSubmitted: boolean = false;
  hideData:boolean = false;

  isDataSaved: boolean = false;
  isUpdate: boolean = false;
  isCaseData: boolean = true;
  isCaseID: boolean = false;
  isadjorNot: boolean = false;
  isIssueAdd: boolean = false;
  isCreateBtn: boolean = false;
  isUpdateBtn: boolean = false;
  isProvAdd: boolean = false;
  isPhase2: boolean = false;
  appealBookLocReq: boolean = false;
  isPhase3: boolean = false;
  isMemorandum: boolean = true;
  disablField: boolean = false;

  disablePhase3 = false;
  isToggleDropdown = true;
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdown3 = true;
  isToggleSegmentDropdown = true;
  isToggleSegmentDropdown2 = true;
  hideDiv: boolean = true;

  disabledWeatherAppl: boolean = false;
  disabledWeatherDept: boolean = false;

  isUPappealBookLoc: boolean = true;
  isDWappealBookLoc: boolean = true;
  isappealBookLocFile: boolean = false;
  isappealBookLocText: boolean = true;

  isUPotherAttachmentsLoc1: boolean = true;
  isDWotherAttachmentsLoc1: boolean = true;
  isotherAttachmentsLoc1File: boolean = false;
  isotherAttachmentsLoc1Text: boolean = true;

  isUPbankChallanLoc: boolean = true;
  isDWbankChallanLoc: boolean = true;
  isbankChallanLocFile: boolean = false;
  isbankChallanLocText: boolean = true;

  isUPdrc03CopyLoc: boolean = true;
  isDWdrc03CopyLoc: boolean = true;
  isdrc03CopyLocFile: boolean = false;
  isdrc03CopyLocText: boolean = true;

  // isUPcopyOfAckLoc: boolean = true;
  // isDWcopyOfAckLoc: boolean = true;
  // iscopyOfAckLocFile: boolean = false;
  // iscopyOfAckLocText: boolean = true;

  // isUPotherAttachmentsLoc2: boolean = true;
  // isDWotherAttachmentsLoc2: boolean = true;
  // isotherAttachmentsLoc2File: boolean = false;
  // isotherAttachmentsLoc2Text: boolean = true;

  isUPphCopyLoc: boolean = true;
  isDWphCopyLoc: boolean = true;
  isphCopyLocFile: boolean = false;
  isphCopyLocText: boolean = true;

  isUPadditionalSubmissionLoc: boolean = true;
  isDWadditionalSubmissionLoc: boolean = true;
  isadditionalSubmissionLocFile: boolean = false;
  isadditionalSubmissionLocText: boolean = true;

  isUPphRecordIfAnyLoc: boolean = true;
  isDWphRecordIfAnyLoc: boolean = true;
  isphRecordIfAnyLocFile: boolean = false;
  isphRecordIfAnyLocText: boolean = true;

  isUPsupremeCourtOrderLoc: boolean = true;
  isDWsupremeCourtOrderLoc: boolean = true;
  issupremeCourtOrderLocFile: boolean = false;
  issupremeCourtOrderLocText: boolean = true;

  isUPpaymentChallanLoc: boolean = true;
  isDWpaymentChallanLoc: boolean = true;
  ispaymentChallanLocFile: boolean = false;
  ispaymentChallanLocText: boolean = true;

  isonupCreate: boolean = true;
  isondwCreate: boolean = true;

  copyOfAckLocReq: boolean = false;
  supremeCourtOrderLocReq: boolean = false;

  appaPh1Appelus: { label: string; value: string }[];
  tabType: { key: string; value: string }[];
  outcome: { key: string; value: string }[];
  riskParam: { key: string; value: string }[];
  issueConclusion: { key: string; value: string }[];
  issueConclusion2: { key: string; value: string }[];
  paymentMode: { key: string; value: string }[];
  //  whetherAppealPreferred: { label: string; value: boolean; }[];
  //  whetherDptAppealPreferred: { label: string; value: boolean; }[];
  adjPHAdjuorNotList: { label: string; value: boolean }[];
  preDepoApplicableList: { label: string; value: boolean }[];
  adjAttend: { label: string; value: boolean }[];
  p1stateList: { stateName: string; stateCode: string; id: number }[];
  p1Gstin: { key: string; value: string }[];
  appAgainst: { key: string; value: string }[];
  quarterList: { key: string; value: string; disabled: string }[];
  yesNoList: { label: string; value: boolean }[];
  intRate: { label: string; value: string }[];
  currentYear: any;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  formData: FormData = new FormData();
  filePath: any;
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    gstin: boolean;
    caseLabel: boolean;
    gstinDiv: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      gstin:false,
      gstinDiv:false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
    };
    issueSorting = SORT_ISSUE;
  // dwotherAttachmentsLoc2: any;
  // dwdsupremeCourtOrderLoc: any;
  // dwdpaymentChallanLoc: any;
  finyearData: any[] = [];
  caseItemList: any = [];
  typeOfAuthority = ISSUING_AUTHORITY_TYPE;
  orderReceiptMode = MODE_OF_RECEIPT;
  modeOfPH = MODE_OF_PH;
  modeOfTime =MODE_OF_TIME_PH;

  protestDetailsExpanded: boolean = false;
  rmvDetailsOfPaymentRef: any;
  protestDetailItemList: any = [];
  protestInterestTotal = 0;
  protestTaxTotal = 0;
  protestcgstTotal= 0;
  protestsgstTotal= 0;
  protestcessTotal= 0;
  protestPenaltyTotal = 0;
  protestTotal = 0;

  formOfPayment = PAYMENT;
  modeOfPayment = MODE_OF_PAYMENT;

  isProtestDetailCopyOfFormLocFile = false;
  isProtestDetailCopyOfFormLocText = true;
  isProtestDetailCopyOfFormLocDWText = true;
  isProtestDetailCopyOfFormLocUPText = true;

  isProtestDetailCopyOfBankChallanLocFile = false;
  isProtestDetailCopyOfBankChallanLocText = true;
  isProtestDetailCopyOfBankChallanLocDWText = true;
  isProtestDetailCopyOfBankChallanLocUPText = true;

  tagDataList: tagData[] = [] ;
  selectedCompanyId: any;
  navData:any;

  cumulativeCount = [
    {
      label: 'Cumulative Pre-Deposit',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative payment made under protest',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative payment made for admitted liability',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative Consultant Charges',
      tax: null,
      interest: null,
      penalty: null,
      total: 0,
    },
  ];

  selectedRemandedBackCaseDetail: any = null;
  deatilsOfAppealExpanded: boolean = false;
  rmvDetailsOfAppealRef: any;
  appealDetailItemList: any = [];
  detailsOfAppealFormType = FORM_TYPE_DETAILS_OF_APPEAL;
  modeOfAppealOptions = MODE_OF_RECEIPT;
  copyOfAckLocDocFile = false;
  copyOfAckLocDocText = true;
  copyOfAckLocDWText = true;
  copyOfAckLocUPText = true;

  isOtherAttachmentDWText = true;
  isOtherAttachmentUPText = true;
  isOtherAttachmentText = true;
  isOtherAttachmentFile = false;

  consultantPanleExpanded: boolean = false;
  rmvConsultantModalRef: any;
  feesChargedTotal = 0;
  consultantChargesItemList: any = [];

  isconsultantattachmentsLocText: boolean = true;
  isconsultantDWattachmentsLoc: boolean = true;
  isconsultantattachmentsLocFile: boolean = false;
  isconsultantUPattachmentsLoc: boolean = true;

  detailsOfLiabilityAddmittedAndPaidExpanded: Boolean = false;
  liabilityAdmittedItemList: any = [];
  rmvLiabilityAddmittedAndPaidModalRef: any;
  liabilityAddmittedAndPaidInterestTotal = 0;
  liabilityAddmittedAndPaidTaxTotal = 0;
  liabilityAddmittedAndPaidCgstTotal  = 0;
  liabilityAddmittedAndPaidSgstTotal  = 0;
  liabilityAddmittedAndPaidCessTotal = 0;
  liabilityAddmittedAndPaidPenaltyTotal = 0;
  liabilityAddmittedAndPaidTotal = 0;

  isLiabilityCopyOfFormLocDocFile: boolean = false; 
  isLiabilityCopyOfFormLocDocText: boolean = true;
  isLiabilityCopyOfFormLocDocDWText: boolean = true;
  isLiabilityCopyOfFormLocUPText: boolean = true;

  isLiabilityCopyOfBankChallanLocFile: boolean = false;
  isLiabilityCopyOfBankChallanLocText: boolean = true;
  isLiabilityCopyOfBankChallanLocDWText: boolean = true;
  isLiabilityCopyOfBankChallanLocUPText: boolean = true;

  detailsOfAllPaymentMadeExpansion: boolean = false;
  caseType: any;
  caseTypeData = CASE_TYPES;
  keepCaseInCallBookOptions = YES_NO;

  forumAppeal = FORUM_APPEAL_APPELLATE_TRIBUNAL;
  proceedWithPreferredForumAppeal: any;
  modeOfPaymentObjectLiabilityAdmitted = MODE_OF_PAYMENT;

  oldProtestDetailsValues: any = [];
  oldLiabilityDetailValues: any = [];
  oldPredepositeDetailsValues: any = [];
  oldConsulatntValues: any = [];
  prevStage: any;
  currentTab = 'sc';
  activeTab: any;
  // isNotEmpty!: boolean ;
  roleName:any

  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private litService: LitigationServiceService,
    private toaster: ToasterService,
    private activeSnapShot: ActivatedRoute,
    private commonServ: CommonService,
    private modalService: NgbModal,
    private inrService: InrService,
    private excelService: ExcelService,
    private http: HttpClient,
    private masterService: MasterService,
    private GS: GetterSetterService,
    private US: UserSettingsService,
  ) {
    this.appaPh1Appelus = APPA_PH1_APPELUS;
    this.tabType = TAB_TYPE;
    this.outcome = MEMORANDOM_OUTCOME;
    this.paymentMode = APA_PH1_PAY_MODE;
    this.riskParam = ISSUE_RISK_PARAM;
    this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS;
    this.issueConclusion2 = ISSUE_CONCLUSION_A;
    //  this.whetherAppealPreferred = ADJ_APPELPRE;
    //  this.whetherDptAppealPreferred = ADJ_DEPTPRE;
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.preDepoApplicableList = Apa_PH1_PREDEPOAPP;
    this.adjAttend = ADJ_ATTEND;
    this.p1Gstin = LETTER_GSTIN;
    this.appAgainst = APPEAL_AGAINST;
    this.p1stateList = STATE_LIST;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.activeSnapShot.snapshot.params.memorandum == 'true'
      ? (this.caseType = 'MEMORANDUM')
      : null;
    this.quarterList = QUARTER;
    this.yesNoList = YES_NO;
    this.intRate = INT_RATE;
    
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.selectedFilling = JSON.parse(
      sessionStorage.getItem('selectedFilling') || '{}'
    );
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstin = this.selectedFilling.gstin;
    this.navContext = this.commonServ.getNavContext();
    this.setStateAutoPop();
    //this.getCompanyTags();
    this.litService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    this.setDate();
    this.personResponsibleData();
    this.consultantData();
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.formInitialize();
    this.getYears();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
    }
    if (this.action === 'create') {
      this.isCreateBtn = true;
      this.isonupCreate = false;
      this.isondwCreate = false;
      this.isDisableIcons = true;
      this.getCompanyTags(this.selectedGstin);
      this.addIssueInv();
      this.addDetailOfPayment();
      this.addDetailOfAppeal();
      this.addConsultantInv();
      this.addDetailsOfLiabilityAddmittedAndPaid();

      //this.addProv();
      this.createAdjDiv();

      this.isappealBookLocFile = true;
      this.isappealBookLocText = false;
      this.isUPappealBookLoc = false;

      this.isotherAttachmentsLoc1File = true;
      this.isotherAttachmentsLoc1Text = false;
      this.isUPotherAttachmentsLoc1 = false;

      this.isbankChallanLocFile = true;
      this.isbankChallanLocText = false;
      this.isUPbankChallanLoc = false;

      this.isdrc03CopyLocFile = true;
      this.isdrc03CopyLocText = false;
      this.isUPdrc03CopyLoc = false;

      //  this.iscopyOfAckLocFile = true;
      //  this.iscopyOfAckLocText = false;
      //  this.isUPcopyOfAckLoc = false;

      //  this.isotherAttachmentsLoc2File = true;
      //  this.isotherAttachmentsLoc2Text = false;
      //  this.isUPotherAttachmentsLoc2 = false;

      this.isphCopyLocFile = true;
      this.isphCopyLocText = false;
      this.isUPphCopyLoc = false;

      this.isadditionalSubmissionLocFile = true;
      this.isadditionalSubmissionLocText = false;
      this.isUPadditionalSubmissionLoc = false;

      this.isphRecordIfAnyLocFile = true;
      this.isphRecordIfAnyLocText = false;
      this.isUPphRecordIfAnyLoc = false;

      this.issupremeCourtOrderLocFile = true;
      this.issupremeCourtOrderLocText = false;
      this.isUPsupremeCourtOrderLoc = false;

      this.ispaymentChallanLocFile = true;
      this.ispaymentChallanLocText = false;
      this.isUPpaymentChallanLoc = false;
    } else if (this.action === 'update') {
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.isIssueAdd = true;
      this.isDisableIcons = true;
    } else if (this.action === 'view') {
      this.isDataSaved = true;
      this.isonupCreate = false;
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.isDisableIcons = false;

      this.isDWotherAttachmentsLoc1 = true;
      this.isUPotherAttachmentsLoc1 = false;

      this.isUPappealBookLoc = false;
      this.isDWappealBookLoc = true;

      this.isUPbankChallanLoc = false;
      this.isDWbankChallanLoc = true;

      this.isUPdrc03CopyLoc = false;
      this.isDWdrc03CopyLoc = true;

      // this.isUPcopyOfAckLoc = false;
      // this.isDWcopyOfAckLoc = true;

      // this.isUPotherAttachmentsLoc2 = false;
      // this.isDWotherAttachmentsLoc2 = true;

      this.isUPphCopyLoc = false;
      this.isDWphCopyLoc = true;

      this.isUPadditionalSubmissionLoc = false;
      this.isDWadditionalSubmissionLoc = true;

      this.isUPphRecordIfAnyLoc = false;
      this.isDWphRecordIfAnyLoc = true;

      this.isUPsupremeCourtOrderLoc = false;
      this.isDWsupremeCourtOrderLoc = true;

      this.isUPpaymentChallanLoc = false;
      this.isDWpaymentChallanLoc = true;
    }
    this.issueDropdown();
    this.createTab();
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    const userId: any = sessionStorage.getItem('user');
    this.scForm.controls.personResponsible1.patchValue(userId);
   this.selectedGstinDiv = this.selectedFilling.gstinDiv;

    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
      console.log('roleName 1', this.roleName)
    } else {
      this.roleName = localStorage.getItem('roleName');
      console.log('roleName 2', this.roleName)
    }

    // console.log(this.selectedFilling);
    // sessionStorage.setItem('sectionType', this.tabType[3].key);

    // this.litService.getUploadFileData().subscribe((res: any) => {
    //   if (res == "true") {
    //     const fileData: any = sessionStorage.getItem('fileResponse');
    //     const selectedfield = sessionStorage.getItem('selectedfield');
    //     const type = sessionStorage.getItem('type');
    //     const parseData = JSON.parse(fileData);

    //     this.getFileUrl(parseData.url, parseData.path, selectedfield, type);
    //   }
    // })
  }
  tabChanged(tab: any, check?: any) {
    this.scForm.patchValue(this.scForm.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    } else if (tab === 'tab2') {
      //this.p2validation();
    } else if (tab === 'tab3') {
      //this.p3validation();
    }
  }
  createTab() {
    if (this.caseId != ' ' && this.caseId != undefined) {
      console.log(this.caseId);
      this.isCreateBtn = false;
      this.isUpdateBtn = true;
    } else {
      this.isCreateBtn = true;
      // this.isUpdateBtn = false;
    }
  }
  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
    if (phase === 'phase' + i) {
    }
  }
  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }
  /* Form initialization */
  formInitialize() {
    this.scForm = this.fBuild.group({
      originalCaseId: [this.caseData?.originalCaseId],
      // gstin: [this.selectedGstin],
      gstin: [this.caseData?.gstin ? this.caseData?.gstin : this.selectedGstin],
      typeOfAuthority1: [
        this.caseData?.typeOfAuthority1,
        [Validators.required],
      ],
      din1: [this.caseData?.din1],
      caseId: [this.caseData?.caseId],
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      appealAgainst: [this.caseData?.appealAgainst],
      orderNo1: [this.caseData?.orderNo1, [Validators.required]],
      dateOfOrder1: [this.caseData?.dateOfOrder1, [Validators.required]],
      dateOfReceiptOfOrder1: [
        this.caseData?.dateOfReceiptOfOrder1,
        [Validators.required],
      ],
      gstinDiv: [this.selectedGstinDiv],
      state: [this.selectedStateName],
      prevOrderLoc: [this.caseData?.prevOrderLoc],
      updatedBy: [this.caseData?.updatedBy],
      updatedOn: [this.caseData?.updatedOn],
      periodFromDate: [this.caseData?.periodFromDate, [Validators.required]],
      periodToDate: [this.caseData?.periodToDate, [Validators.required]],
      //appealAgainst: [this.caseData?.appealAgainst, [Validators.required]],
      //  highCourtOrderNo: [this.caseData?.highCourtOrderNo, [Validators.required]],
      //  dateOfOrder1: [this.caseData?.dateOfOrder1, [Validators.required]],
      //  dateOfReceiptOfOrder1: [this.caseData?.dateOfReceiptOfOrder1, [Validators.required]],
      // appealedUs: [this.caseData?.appealedUs],
      dueDateToAppeal: [this.caseData?.dueDateToAppeal, [Validators.required]],
      internalDueDate: [this.caseData?.internalDueDate],
      caseLabel: [
        this.caseData?.caseLabel,
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(5),
        ],
      ],
      protestDetails: new UntypedFormArray([]),

      //preDepositApplicable: [this.caseData?.preDepositApplicable, [Validators.required]],
      //  cPreDepositPaid: [this.caseData?.cPreDepositPaid, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      //personResponsible: [this.caseData?.personResponsible, [Validators.required]],
      // dateOfsubmission1: [this.caseData?.dateOfsubmission1],
      issues: new UntypedFormArray([]),
      caseSummary: [this.caseData?.caseSummary],
      internalRemarks: [this.caseData?.internalRemarks],
      provisionDetails: this.fBuild.array([]),
      //  igstTax1: [this.caseData?.igstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      //  cgstTax1: [this.caseData?.cgstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      //  sgstTax1: [this.caseData?.sgstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      //  cessTax1: [this.caseData?.cessTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      //  totalTax1: [this.action == 'create'? '':this.caseData?.totalTax1, []],
      // igstInterest1: [this.caseData?.igstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // igstPenalty1: [this.caseData?.igstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstInterest1: [this.caseData?.cgstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstPenalty1: [this.caseData?.cgstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstInterest1: [this.caseData?.sgstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstPenalty1: [this.caseData?.sgstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessInterest1: [this.caseData?.cessInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessPenalty1: [this.caseData?.cessPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // totalInterest1: [this.action == 'create'? '':this.caseData?.totalInterest1, []],
      // totalPenalty1: [this.action == 'create'? '':this.caseData?.totalPenalty1, []],
      //paymentMode: [this.caseData?.paymentMode],
      //bankChallanLoc: [this.caseData?.bankChallanLoc],
      consultant1: [this.caseData?.consultant1],
      personResponsible1: [
        this.caseData?.personResponsible1,
        [Validators.required],
      ],
      appealBookLoc: new UntypedFormArray([]),
      otherAttachmentsLoc1: new UntypedFormArray([]),
      appealDetails: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),

      orderNo3: [this.caseData?.orderNo3], //
      dateOfOrder3: [this.caseData?.dateOfOrder3], //
      dateOfReceiptOfOrder3: [this.caseData?.dateOfReceiptOfOrder3], //
      supremeCourtOrderLoc: new UntypedFormArray([]), //
      typeOfAuthority3: [this.caseData?.typeOfAuthority3],
      din3: [this.caseData?.din3],
      orderReceiptMode3: [this.caseData?.orderReceiptMode3],

      outcome: [this.caseData?.outcome], //
      //  whetherAppealPreferred: [this.caseData?.whetherAppealPreferred],//
      //  whetherDptAppealPreferred: [this.caseData?.whetherDptAppealPreferred],//
      //  forumOfAppeal: [null],
      orderSummary: [this.caseData?.orderSummary],
      liabilityDetails: new UntypedFormArray([]),
      whetherCaseCallBook: [this.caseData?.whetherCaseCallBook],
      //  paymentChallanLoc: [this.caseData?.paymentChallanLoc],
      //  drc03Tax: [this.caseData?.drc03Tax, [Validators.pattern(/^[1-9]\d*$/)]],
      //  drc03Interest: [this.caseData?.drc03Interest, [Validators.pattern(/^[1-9]\d*$/)]],
      //  drc03Penalty: [this.caseData?.drc03Penalty, [Validators.pattern(/^[1-9]\d*$/)]],
      // summary: [this.caseData?.summary],
      //  igstTax3: [this.caseData?.igstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  cgstTax3: [this.caseData?.cgstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  sgstTax3: [this.caseData?.sgstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  cessTax3: [this.caseData?.cessTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  totalTax3: [this.action == 'create'? '':this.caseData?.totalTax3, []],//
      //  igstInterest3: [this.caseData?.igstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  igstPenalty3: [this.caseData?.igstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  cgstInterest3: [this.caseData?.cgstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  cgstPenalty3: [this.caseData?.cgstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  sgstInterest3: [this.caseData?.sgstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  sgstPenalty3: [this.caseData?.sgstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  cessInterest3: [this.caseData?.cessInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  cessPenalty3: [this.caseData?.cessPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      //  totalInterest3: [this.action == 'create'? '':this.caseData?.totalInterest3, []],//
      //  totalPenalty3: [this.action == 'create'? '':this.caseData?.totalPenalty3, []],//
      drc03CopyLocFN: [this.drc03CopyLocF], //
      copyOfAckLocFN: [this.copyOfAckLocF], //
      otherAttachmentsLoc2FN: [this.otherAttachmentsLoc2F], //
      phCopyLocFN: [this.phCopyLocF], //
      additionalSubmissionLocFN: [this.additionalSubmissionLocF], //
      phRecordIfAnyLocFN: [this.phRecordIfAnyLocF], //
      supremeCourtOrderLocFN: [this.supremeCourtOrderLocF], //
      paymentChallanLocFN: [this.paymentChallanLocF], //

      //  dwddrc03CopyLoc: [this.dwddrc03CopyLoc],
      //  dwdcopyOfAckLoc: [this.dwdcopyOfAckLoc],
      //  dwotherAttachmentsLoc2: [this.dwotherAttachmentsLoc2],
      //  dwdsupremeCourtOrderLoc: [this.dwdsupremeCourtOrderLoc],
      //  dwdpaymentChallanLoc: [this.dwdpaymentChallanLoc],
      itemList: this.fBuild.array([]),
      igst1Total: [this.caseData?.igst1Total],
      cgst1Total: [this.caseData?.cgst1Total],
      sgst1Total: [this.caseData?.sgst1Total],
      cess1Total: [this.caseData?.cess1Total],
      interest1Total: [this.caseData?.interest1Total],
      penalty1Total: [this.caseData?.penalty1Total],
      totOfTotal1: [this.caseData?.totOfTotal1],
      igst3Total: [this.caseData?.igst3Total],
      cgst3Total: [this.caseData?.cgst3Total],
      sgst3Total: [this.caseData?.sgst3Total],
      cess3Total: [this.caseData?.cess3Total],
      interest3Total: [this.caseData?.interest3Total],
      penalty3Total: [this.caseData?.penalty3Total],
      totOfTotal3: [this.caseData?.totOfTotal3],
      status: [this.caseData?.status],
    });
    this.scForm.controls['protestDetails'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldProtestDetailsValues = this.scForm.value['protestDetails'];
        this.setCummulativeCount(
          this.oldProtestDetailsValues,
          selectedValue,
          'protestDetails'
        );
      }
    );

    // this.scForm.controls['protestDetails']
    //   .valueChanges
    //   .subscribe(selectedValue => {
    //     this.oldPredepositeDetailsValues = this.scForm.value['protestDetails'];
    //     this.setCummulativeCount(this.oldPredepositeDetailsValues, selectedValue ,'protestDetails');
    //   });

    this.scForm.controls['consultantCharges'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldConsulatntValues = this.scForm.value['consultantCharges'];
        this.setCummulativeCount(
          this.oldConsulatntValues,
          selectedValue,
          'consultantCharges'
        );
      }
    );

    this.scForm.controls['liabilityDetails'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldLiabilityDetailValues = this.scForm.value['liabilityDetails'];
        this.setCummulativeCount(
          this.oldLiabilityDetailValues,
          selectedValue,
          'liabilityDetails'
        );
      }
    );
  }
  get p1() {
    return this.scForm.controls;
  }
  getAppealBookLoc(): UntypedFormGroup[] {
    return (this.scForm.get('appealBookLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getOtherAttachmentLoc1(): UntypedFormGroup[] {
    return (this.scForm.get('otherAttachmentsLoc1') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getProtestCopyForm(protestCopyForm: UntypedFormGroup): UntypedFormGroup[] {
    return (protestCopyForm.get('copyOfFormLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getProtestBankChallan(protestBankChallan: UntypedFormGroup): UntypedFormGroup[] {
    return (protestBankChallan.get('copyOfBankChallanLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getAppealAckLoc(ackLocFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (ackLocFrmGrp.get('ackCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAppealOtherAttachLoc(otherAttachFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultOtherAttachment(consultAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultAttachGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getSupremeCourtOrderLoc(): UntypedFormGroup[] {
    return (this.scForm.get('supremeCourtOrderLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityCopyForm(liabilitycopyFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitycopyFormLoc.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityBankChallan(liabilitybankChallanFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitybankChallanFormLoc.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAppealBookLocLength() {
    let count = 0;
    let predepositFrmArray = this.scForm.get('appealBookLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getOtherAttachmentLoc1Length() {
    let count = 0;
    let predepositFrmArray = this.scForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getProtestCopyLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.scForm.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getProtestBankChallanLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.scForm.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAppealAckLocLength(j?: any) {
    let count = 0;
    let appealDetailsArray = this.scForm.get('appealDetails') as UntypedFormArray;
    let letterLocFrmArray = appealDetailsArray.controls[j]?.get('ackCopyLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAppealOtherAttachLocLength(j?: any) {
    let count = 0;
    let appealDetailsArray = this.scForm.get('appealDetails') as UntypedFormArray;
    let otherAttachmentsLocArray = appealDetailsArray.controls[j]?.get('otherAttachmentsLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.scForm.get('itemList') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.scForm.get('itemList') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get('phCopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.scForm.get('itemList') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get('additionalSubmissionLoc') as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.scForm.get('itemList') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.scForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getgstatOrderCopyLocLength() {
    let count = 0;
    let supremeCourtFrmArray = this.scForm.get('supremeCourtOrderLoc') as UntypedFormArray;
    let supremecount = supremeCourtFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return supremecount.length;
  }
  getLiabilityCopyFormLength(j?: any) {
    let count = 0;
    let liabilityDetailsArray = this.scForm.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = liabilityDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getLiabilityBankChallanLength(j?: any) {
    let count = 0;
    let liabilityDetailsArray = this.scForm.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = liabilityDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  createAppealBookLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachmentLoc1(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProtestCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProtestBankChallanForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAppealAckCopy(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAppealOtherAttach(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createConsultAttach(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createsupremeCourtOrderCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityBankChallan(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  openAppealBookLoc(content: any) {
    this.addAppealBookLoc()
    this.modalService.open(content);
  }
  openOtherAttachmentLoc1(content: any) {
    this.addOtherAttachmentLoc1()
    this.modalService.open(content);
  }
  openProtestCopyForm(content: any, i: any) {
    this.addProtestCopyForm(i, 0);
    this.modalService.open(content)
  }
  openProtestBankChallan(content: any, i: any) {
    this.addProtestBankChallan(i, 0);
    this.modalService.open(content)
  }
  openAppealAckCopy(content: any, i: any) {
    this.addAppealAckCopy(i, 0);
    this.modalService.open(content);
  }
  openAppealOtherAttach(content: any, i: any) {
    this.addAppealOtherAttach(i, 0);
    this.modalService.open(content);

  }
  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  openConsultAttach(content: any, i: any) {
    this.addConsultAttach(i, 0);
    this.modalService.open(content);
  }
  openSupremeCourtOrderLoc(content: any) {
    this.addSupremeCourtOrdeCopy()
    this.modalService.open(content);
  }
  openLiabilityCopyOfForm(content: any, i: any) {
    this.addLiabilityCopyOfForm(i, 0);
    this.modalService.open(content);
  }
  openLiabilityBankChallan(content: any, i: any) {
    this.addLiabilityCopyOfProBankChalan(i, 0);
    this.modalService.open(content);
  }
  addAppealBookLoc() {
    let appealBookLocArray = this.scForm.get('appealBookLoc') as UntypedFormArray;
    let i = 0;
    if (appealBookLocArray.length > 0) {
      let length = appealBookLocArray.length;

      while (i < 5 - length) {
        appealBookLocArray.push(this.createAppealBookLoc());

        i++;
      }
    } else {
      while (i < 5) {
        appealBookLocArray.push(this.createAppealBookLoc());

        i++;
      }
    }
  }
  uploadAppealBookLoc(bookLocIndex: any) {
    let bookLocArray = this.scForm.controls.appealBookLoc as UntypedFormArray;
    if (bookLocArray.controls.length > 0) {
      for (let index = 0; index < bookLocArray.controls.length; index++) {
        if (index === bookLocIndex) {
          let itemListuploadControl = bookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealBookLoc(bookLocIndex: any) {
    let bookLocArray = this.scForm.controls.appealBookLoc as UntypedFormArray;
    if (bookLocArray.controls.length > 0) {
      for (let index = 0; index < bookLocArray.controls.length; index++) {
        if (index === bookLocIndex) {
          let itemListuploadControl = bookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOtherAttachmentLoc1(otherAttachLoc1Index: any) {
    let otherAttachLoc1Array = this.scForm.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachLoc1Array.controls.length; index++) {
        if (index === otherAttachLoc1Index) {
          let itemListuploadControl = otherAttachLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachmentLoc1(otherAttachLoc1Index: any) {
    let otherAttachLoc1Array = this.scForm.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachLoc1Array.controls.length; index++) {
        if (index === otherAttachLoc1Index) {
          let itemListuploadControl = otherAttachLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addOtherAttachmentLoc1() {
    let otherAttachLoc1Array = this.scForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    let i = 0;

    if (otherAttachLoc1Array.length > 0) {
      let length = otherAttachLoc1Array.length;

      while (i < 5 - length) {
        otherAttachLoc1Array.push(this.createOtherAttachmentLoc1());

        i++;
      }
    } else {
      while (i < 5) {
        otherAttachLoc1Array.push(this.createOtherAttachmentLoc1());

        i++;
      }
    }
  }
  addProtestCopyForm(j: any, i: any) {
    let protestDtlsArray = this.scForm.get('protestDetails') as UntypedFormArray;
    let copyFormArray = protestDtlsArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormArray.length > 0) {
      let length = copyFormArray.length
      while (i < 5 - length) {
        copyFormArray.push(this.createProtestCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormArray.push(this.createProtestCopyForm());
        i++;
      }
    }
  }
  addProtestBankChallan(j: any, i: any) {
    let protestDtlsArray = this.scForm.get('protestDetails') as UntypedFormArray;
    let bankChallanArray = protestDtlsArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.length > 0) {
      let length = bankChallanArray.length
      while (i < 5 - length) {
        bankChallanArray.push(this.createProtestBankChallanForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        bankChallanArray.push(this.createProtestBankChallanForm());
        i++;
      }
    }
  }
  uploadProtestCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let protestDtlsArray = this.scForm.controls.protestDetails as UntypedFormGroup;
    let copyFormLocFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.scForm.controls.protestDetails as UntypedFormGroup;
    let copyFormLocFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadProtestBankChallan(copyListIndex: any, copyAckLocIndex: any) {

    let protestDtlsArray = this.scForm.controls.protestDetails as UntypedFormGroup;
    let bankChallanArray = protestDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestBankChallan(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.scForm.controls.protestDetails as UntypedFormGroup;
    let bankChallanArray = protestDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAppealAckCopy(j: any, i: any) {
    let appealDetails = this.scForm.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[j].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.length > 0) {
      let length = ackCopyLocArray.length
      while (i < 5 - length) {
        ackCopyLocArray.push(this.createAppealAckCopy());
        i++;
      }
    }
    else {
      while (i < 5) {
        ackCopyLocArray.push(this.createAppealAckCopy());
        i++;
      }
    }
  }
  uploadAppealAckCopy(ackCopyListIndex: any, ackCopyLocIndex: any) {
    let appealDetails = this.scForm.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[ackCopyListIndex].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealAckCopy(ackCopyListIndex: any, ackCopyLocIndex: any) {
    let appealDetails = this.scForm.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[ackCopyListIndex].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadAppealOtherAttach(otherAttachListIndex: any, otherAttachLocIndex: any) {
    let appealDetails = this.scForm.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[otherAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = otherAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealOtherAttach(otherAttachListIndex: any, otherAttachLocIndex: any) {
    let appealDetails = this.scForm.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[otherAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = otherAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAppealOtherAttach(j: any, i: any) {
    let appealDetails = this.scForm.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[j].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.length > 0) {
      let length = otherAttachLocArray.length
      while (i < 5 - length) {
        otherAttachLocArray.push(this.createAppealOtherAttach());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachLocArray.push(this.createAppealOtherAttach());
        i++;
      }
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.scForm.get('itemList') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get('phCopyLoc') as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {

    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.scForm.get('itemList') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }
  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.scForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }
  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.scForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.scForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addConsultAttach(j: any, i: any) {
    let consultFrmArray = this.scForm.get('consultantCharges') as UntypedFormArray;
    let consultAttachFrmArray = consultFrmArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachFrmArray.length > 0) {
      let length = consultAttachFrmArray.length
      while (i < 5 - length) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
    else {
      while (i < 5) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
  }
  uploadConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.scForm.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.scForm.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;
    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addSupremeCourtOrdeCopy() {
    let supremeCourtCopyLocArray = this.scForm.get('supremeCourtOrderLoc') as UntypedFormArray;
    let i = 0;
    if (supremeCourtCopyLocArray.length > 0) {
      let length = supremeCourtCopyLocArray.length;

      while (i < 5 - length) {
        supremeCourtCopyLocArray.push(this.createsupremeCourtOrderCopyLoc());

        i++;
      }
    } else {
      while (i < 5) {
        supremeCourtCopyLocArray.push(this.createsupremeCourtOrderCopyLoc());

        i++;
      }
    }
  }
  uploadSupremeOrderCopyLocFile(supremeCourtOrderLocArrayIndex: any) {
    let supremeCourtCopyLocArray = this.scForm.controls.supremeCourtOrderLoc as UntypedFormArray;
    if (supremeCourtCopyLocArray.controls.length > 0) {
      for (let index = 0; index < supremeCourtCopyLocArray.controls.length; index++) {
        if (index === supremeCourtOrderLocArrayIndex) {
          let itemListuploadControl = supremeCourtCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteSupremeCourtOrderCopyLoc(supremeCourtOrderLocArrayIndex: any) {
    let supremeCourtCopyLocArray = this.scForm.controls.supremeCourtOrderLoc as UntypedFormArray;
    if (supremeCourtCopyLocArray.controls.length > 0) {
      for (let index = 0; index < supremeCourtCopyLocArray.controls.length; index++) {
        if (index === supremeCourtOrderLocArrayIndex) {
          let itemListuploadControl = supremeCourtCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addLiabilityCopyOfForm(j: any, i: any) {
    let liabilityFrmArray = this.scForm.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = liabilityFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
  }
  addLiabilityCopyOfProBankChalan(j: any, i: any) {
    let liabilityFrmArray = this.scForm.get('liabilityDetails') as UntypedFormArray;
    let proBankChallanLocFrmArray = liabilityFrmArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (proBankChallanLocFrmArray.length > 0) {
      let length = proBankChallanLocFrmArray.length
      while (i < 5 - length) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
    else {
      while (i < 5) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
  }
  uploadLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.scForm.controls.liabilityDetails as UntypedFormGroup;
    let copyFormLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.scForm.controls.liabilityDetails as UntypedFormGroup;
    let copyFormFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.scForm.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.scForm.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  p2validation() {
    //phase2
    var appealDetailsArr = this.scForm.controls.appealDetails as UntypedFormArray;
    if (appealDetailsArr.controls.length > 0) {
      for (var i = 0; i < appealDetailsArr.controls.length; i++) {
        var issues1Control = appealDetailsArr.controls[i] as UntypedFormGroup;
        if (
          issues1Control.controls.appealRefNo.value === null ||
          issues1Control.controls.appealRefNo.value === ''
        ) {
          issues1Control.controls.appealRefNo.setErrors({ required: true });
        } else {
          issues1Control.controls.appealRefNo.setErrors(null);
        }
        if (
          issues1Control.controls.filingAppealDate.value === null ||
          issues1Control.controls.filingAppealDate.value === ''
        ) {
          issues1Control.controls.filingAppealDate.setErrors({
            required: true,
          });
        } else {
          issues1Control.controls.filingAppealDate.setErrors(null);
        }
      }
    }
    return true;
  }
  p3validation() {
    var issues1Array = this.scForm.controls.issues as UntypedFormArray;
    if (issues1Array.controls.length > 0) {
      for (var i = 0; i < issues1Array.controls.length; i++) {
        var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        if (
          issues1Control.controls.status.value === null ||
          issues1Control.controls.status.value === '' ||
          issues1Control.controls.status.value === "null"
        ) {
          issues1Control.controls.status.setErrors({ required: true });
        } else {
          issues1Control.controls.status.setErrors(null);
        }
      }
    }
    if (this.scForm.value['orderNo3'] === null) {
      this.scForm.controls['orderNo3'].setErrors({ required: true });
    }
    if (this.scForm.value['dateOfOrder3'] === null) {
      this.scForm.controls['dateOfOrder3'].setErrors({ required: true });
    }
    if (this.scForm.value['dateOfReceiptOfOrder3'] === null) {
      this.scForm.controls['dateOfReceiptOfOrder3'].setErrors({
        required: true,
      });
    }
    // if (this.scForm.value['typeOfAuthority3'] === null && this.caseType === this.caseData.MEMORANDUM) {
    //   this.scForm.controls['typeOfAuthority3'].setErrors({'required': true});
    //   }
    if (this.scForm.value['outcome'] === null) {
      this.scForm.controls['outcome'].setErrors({ required: true });
    }
    // if (this.scForm.value['whetherAppealPreferred'] === null) {
    // this.scForm.controls['whetherAppealPreferred'].setErrors({'required': true});
    // }
    return true;
  }

  apaDivArr(): UntypedFormArray {
    return this.scForm.get('itemList') as UntypedFormArray;
  }

  // phTabArr(): FormArray {
  //   return this.adjP1Form.get("itemList") as FormArray
  // }
  newphArray(item: any = null): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: ['', []],
      dateOfPhScheduled: ['', []],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: ['', []],
      mode: ['', []],
      phRefNo:['', []],
      phTime:['', []],
      consultant: [this.caseData?.consultant1, []],
      personResponsible: [this.caseData?.personResponsible1, []],
      dateOfsubmission: ['', []],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phDiscussionNotes: ['', []],
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      dateOfSubmission: ['', []],
    });
  }
  createAdjDiv() {
    // this.adjPhTablist.push(this.newphArray());
    this.apaDivArr().push(this.newphArray());
  }
  removeLetter(i: number) {
    this.apaDivArr().removeAt(i);
  }
  deleteitemListFile() {
    var itemListArray = this.scForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phRecordIfAnyLoc.setValue(null);
      }
    }
  }
  deletePHNoticeFile() {
    var itemListArray = this.scForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phCopyLoc.setValue(null);
      }
    }
  }
  deleteSubmissionFile() {
    var itemListArray = this.scForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.additionalSubmissionLoc.setValue(null);
      }
    }
  }
  changeadjOrNot(event: any, i: any) {
    if (event.target.value === 'true') {
      this.createAdjDiv();
      this.isadjorNot = true;
    } else {
      this.isadjorNot = false;
      this.removeLetter(i);
    }
  }
  checkpreDepositNo(event: any) {
    if (event.target.value === 'false') {
      this.disablField = true;
    } else {
      this.disablField = false;
    }
  }
  /*Issue invloved div itration */

  issuInvArr(): UntypedFormGroup[] {
    return (this.scForm.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newIssueInvArray(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      keyword: [null, []],
      riskParam: [null, [Validators.required]],
      tag:[null, []],
      periodFromDate: [null, []],
      periodToDate: [null, []],
      interestRate: [null, []],
      remark1: [null, [Validators.maxLength(500)]],
      igst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      details: new UntypedFormArray([]),
      status: [null, []],
    });
  }
  addIssueInv() {
    let issueFrmGrp = this.newIssueInvArray();
    (issueFrmGrp.get('details') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.scForm.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number) {
    (this.scForm.get('issues') as UntypedFormArray).removeAt(i);
    this.rmvIssueModalRef.close();
    this.issueValueChange();
  }
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp() {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(null),
      quarter: new UntypedFormControl(null),
      provisionAmt1: new UntypedFormControl(null),
      contLiabilityAmt1: new UntypedFormControl(null),
      otherLiability1: new UntypedFormControl(null),
      provisionChangeReason1: new UntypedFormControl(null),
      provisionNotMadeReason1: new UntypedFormControl(null),
      contLiabReason1: new UntypedFormControl(null),
      otherLiabilityReason1: new UntypedFormControl(null),
      considerInCaroReport1: new UntypedFormControl(null),
      caroAmt1: new UntypedFormControl(null),
    });
  }
  addDetail(j: any) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get(
      'details'
    ) as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();
  }
  /*Issue invloved div itration */

  /*upload file selection */

  // onFileSelect(event: any, type: any = null) {
  //   this.litService.onFileSelect(event, type);
  // }
  onFileSelect(event: any, type: any = null) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And')
        this.selectedFile = new File([originalFile], modifiedFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, type);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  onUpload(selectedfield: any, type: any) {
    let urlData = {
      gstinOrPan: this.scForm.controls.gstin.value,
      type: this.tabType[5].key,
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.litService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        // this.fileUrl = response.url;
        // this.filePath = response.path;
        this.getFileUrl(response.response.url, response.response.path, selectedfield, type);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any, type: any = null) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log('response receved is ', response);
      if (this.selectedFile && type === 'appealDetails' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.scForm.get('appealDetails') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (
        this.selectedFile &&
        type === 'consultantCharges' &&
        this.matches
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.scForm.get('consultantCharges') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (
        this.selectedFile &&
        type === 'liabilityDetails' &&
        this.matches
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.scForm.get('liabilityDetails') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (
        this.selectedFile &&
        type === 'protestDetails' &&
        this.matches
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.scForm.get('protestDetails') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (this.selectedFile && this.p1.itemList.value && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.itemList.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path; //this.filePath;
              this.savecaseItemList();
            }
          }
        });
      }
      if (selectedfield) {
        var toupdate = this.scForm.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.scForm.patchValue(this.scForm.value);
      }
      else {
        let field = selectedfield; //this.selectedField;
        let adjForm = this.scForm.value;
        adjForm[field] = path; //this.filePath;
        if (this.caseData == null) {
          this.caseData = {};
        }
        this.caseData[field] = path;
        //console.log(adjForm);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    });
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  changeOutcome(event: any = null) {
    let data;
    if (!event) {
      data = this.scForm.value.outcome;
    } else {
      data = event.target.value;
    }
    if (data === 'Favorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter(
        (x) => x.value == 'Favorable'
      );
      var issues1Array = this.scForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    } else if (data === 'Unfavorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter(
        (x) => x.value == 'Unfavorable' || x.value == 'Admitted'
      );
      // var issues1Array = this.scForm.controls.issues as FormArray
      // if(issues1Array.controls.length > 0) {
      //   for(var i = 0; i < issues1Array.controls.length; i++){
      //       var issues1Control = issues1Array.controls[i] as FormGroup;
      //       issues1Control.controls.status.setValue(data);
      //   }
      // }
    } else if (data === 'Remanded Back') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter(
        (x) => x.value == 'Remanded Back'
      );
      var issues1Array = this.scForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    } else if (data === 'Partly Favorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter(
        (x) =>
          x.value == 'Partly Favorable' ||
          x.value == 'Favorable' ||
          x.value == 'Unfavorable' ||
          x.value == 'Partly Unfavorable' ||
          x.value == 'Admitted'
      );
    } else if (data === 'Partly Remanded Back') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter(
        (x) =>
          x.value == 'Partly Remanded Back' ||
          x.value == 'Favorable' ||
          x.value == 'Unfavorable' ||
          x.value == 'Partly Favorable' ||
          x.value == 'Partly Unfavorable' ||
          x.value == 'Remanded Back' ||
          x.value == 'Admitted'
      );
    } else if (data === 'Partly Unfavorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter(
        (x) =>
          x.value == 'Partly Favorable' ||
          x.value == 'Favorable' ||
          x.value == 'Unfavorable' ||
          x.value == 'Partly Unfavorable' ||
          x.value == 'Admitted'
      );
    }
  }

  // changeIssueConclusionValue(){
  //   var issues1Array = this.scForm.controls.issues as FormArray;
  //   switch(this.scForm.value?.outcome){
  //     case 'Favorable' :  this.issueConclusion = [{ key: "F", value: "Favorable" }];
  //                         if(issues1Array.controls.length > 0) {
  //                         for(var i = 0; i < issues1Array.controls.length; i++){
  //                             var issues1Control = issues1Array.controls[i] as FormGroup;
  //                             issues1Control.controls.status.setValue('Favorable');
  //                           }
  //                         }
  //                         break;
  //     case 'Unfavorable' :    this.issueConclusion = [ { key: "U", value: "Unfavorable" }, { key: "A", value: "Admitted"}];
  //                             if(issues1Array.controls.length > 0) {
  //                               for(var i = 0; i < issues1Array.controls.length; i++){
  //                                   var issues1Control = issues1Array.controls[i] as FormGroup;
  //                                   issues1Control.controls.status.setValue(issues1Control.controls.status.value?issues1Control.controls.status.value:'');
  //                                 }
  //                             }
  //                             break;
  //     case 'Remanded Back' :  this.issueConclusion = [ { key: "R",  value: "Remanded Back" }];
  //                             if(issues1Array.controls.length > 0) {
  //                               for(var i = 0; i < issues1Array.controls.length; i++){
  //                                   var issues1Control = issues1Array.controls[i] as FormGroup;
  //                                   issues1Control.controls.status.setValue('Remanded Back');
  //                                 }
  //                             }
  //                             break;
  //     default :
  //               this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS;
  //               if(issues1Array.controls.length > 0) {
  //                 for(var i = 0; i < issues1Array.controls.length; i++){
  //                     var issues1Control = issues1Array.controls[i] as FormGroup;
  //                     issues1Control.controls.status.setValue(issues1Control.controls.status.value?issues1Control.controls.status.value:'');
  //                   }
  //               }
  //               break;
  //   }

  // }
  // checkAppYes(event: any){
  //   if (event.target.value === "true") {
  //     this.disabledWeatherDept = true;
  //     this.scForm.get("whetherDptAppealPreferred")?.clearValidators();
  //     this.scForm.get("whetherDptAppealPreferred")?.updateValueAndValidity();
  //     this.scForm.get("whetherDptAppealPreferred")?.setValue(null);
  //     // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
  //     // this.rmWeatherWritErrorBorder();
  //   }else{
  //     this.disabledWeatherDept = false;
  //   }
  // }

  // checkDeptYes(event: any){
  //   if (event.target.value === "true") {
  //     this.disabledWeatherAppl = true;
  //     this.scForm.get("whetherAppealPreferred")?.clearValidators();
  //     this.scForm.get("whetherAppealPreferred")?.updateValueAndValidity();
  //     this.scForm.get("whetherAppealPreferred")?.setValue(null);
  //     // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
  //     // this.rmWeatherWritErrorBorder();
  //   }else{
  //     this.disabledWeatherAppl = false;
  //   }
  // }

  /*create phase 1 (submit function)*/
  createP1() {
    // if(this.savedCaseId) {
    //   this.caseId = this.savedCaseId;
    // }
    this.isSubmitted = true;
    let data: any = {};
    if (this.caseType === this.caseTypeData.MEMORANDUM) {
      let dataAppealBookLoc = (this.scForm.get('appealBookLoc') as UntypedFormArray).controls;
      var count = dataAppealBookLoc?.some((x) => x.get('fileLoc')?.value != null);
      if (!count) {
        this.toaster.showError('Required Fields Are Empty');
        return;
      }
    }
    let protestArray = this.scForm.controls.protestDetails as UntypedFormArray
    for (let index = 0; index < protestArray.controls.length; index++) {
      let issuesControl = protestArray.controls[index] as UntypedFormGroup;

      if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
        let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;

        let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
        if (!hasSupportingDoc) {
          this.toaster.showError("Required Fields Are Empty for Challan No.");
          return;
        }
        let issueNameControls = issuesControl.controls.issueName.value;
        let tagControls = issuesControl.controls.tag.value;
        let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
        let challanNoControls = issuesControl.controls.challanNo.value ;
        //let hasissueName = issueNameControls.some((docControl) => docControl.get('fileLoc')?.value != null);
        if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
          this.toaster.showError("Required Fields Are Empty in Payment Table.");
          return;
        }
      }
     
    
    }
       if (this.scForm.valid) {
      data = this.scForm.value;
      let urlData = {
        typeVal: this.tabType[5].key,
        gstin: this.selectedGstin,
        companyId: this.companyId,
      };
      Object.assign(
        data,
        { action: 'CREATE' },
        { phase: '1' },
        { keyword: 'keyword1' }
      );
      let bookLocArray: any[] = [];
      let otherAttachmentLoc1Array: any[] = [];
      let protestCopyFormArray: any[] = [];
      let protestBankChallanArray: any[] = [];
      for (let i = 0; i < this.scForm.controls.appealBookLoc.value.length; i++) {
        const element = this.scForm.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          bookLocArray.push(element)
        }

      }
      for (let i = 0; i < this.scForm.controls.otherAttachmentsLoc1.value.length; i++) {
        const element = this.scForm.controls.otherAttachmentsLoc1.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachmentLoc1Array.push(element)
        }

      }
      for (let i = 0; i < this.scForm.controls.protestDetails.value.length; i++) {
        protestCopyFormArray = []
        const copyFormArray = this.scForm.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestCopyFormArray.push(element)
          }
        }
        this.scForm.controls.protestDetails.value[i].copyOfFormLoc = protestCopyFormArray;
      }
      for (let i = 0; i < this.scForm.controls.protestDetails.value.length; i++) {
        protestBankChallanArray = [];
        const bankChallanArray = this.scForm.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestBankChallanArray.push(element)
          }
        }
        this.scForm.controls.protestDetails.value[i].copyOfBankChallanLoc = protestBankChallanArray;
      }
      this.scForm.value.appealBookLoc = bookLocArray;
      this.scForm.value.otherAttachmentsLoc1 = otherAttachmentLoc1Array;
      data.caseType = this.caseType;
      data.cumulative = this.cumulativeCount;
      data.gstinDiv = this.selectedGstinDiv;
      this.inrFormattingRemoving();
      // this.scForm.value.dateOfLetter = this.datePipe.transform(this.scForm.value.dateOfLetter, 'yyyy-MM-dd');
      this.litService
        .saveSupremeCourtData(data)
        .subscribe((response: any) => {
          this.isSubmitted = false;
          this.formatTableValues();
          if (response.status === 1) {
            if (this.isDataSaved === true) {
              // this.p1validation();
            }
            this.toaster.showSuccess(response.message);
            this.isDataSaved = true;
            this.isPhase2 = true;
            this.isCreateBtn = false;
            this.isUpdateBtn = true;
            this.savedCaseId = response.response;
            this.saveCaseId.emit(this.savedCaseId);
            this.initializeData();
          } else {
            this.toaster.showError(response.message);
          }
        });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  /*get prefilled data (View)*/
  initializeData() {
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        caseId: this.caseId,
      };
      this.litService.getScData(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.caseType = response.response.caseType;
          this.activeStages = response.response?.prevStages;
          this.enablePrevStageTab.emit(this.activeStages);
          this.caseData = response.response;
          // const itemlistData = this.caseData.issues;
          // this.autoIssueList = []; 
          // this.autoTagList = []; 
          // const uniqueIssues = new Set(); 
          // const uniqueTags = new Set();
          //   itemlistData.forEach((issue : any) => {
          //     if (!uniqueIssues.has(issue.issue)) {
          //       uniqueIssues.add(issue.issue);
          //       this.autoIssueList.push(issue.issue); // Add to issue list
          //     }
          //     if (issue.tag !== null && issue.tag !== undefined && !uniqueTags.has(issue.tag)) {
          //       uniqueTags.add(issue.tag);
          //       this.autoTagList.push(issue.tag); // Add to tag list
          //     }
          //   });
          this.getCompanyTags(this.caseData.gstin);
          if (this.caseData?.issues) {
            this.caseData.issues.forEach((issue: any) => {
              issue.igst1 = this.inrService.formatCurrency(String(issue.igst1));
              issue.cgst1 = this.inrService.formatCurrency(String(issue.cgst1));
              issue.sgst1 = this.inrService.formatCurrency(String(issue.sgst1));
              issue.cess1 = this.inrService.formatCurrency(String(issue.cess1));
              issue.interest1 = this.inrService.formatCurrency(String(issue.interest1));
              issue.penalty1 = this.inrService.formatCurrency(String(issue.penalty1));
              issue.total1 = this.inrService.formatCurrency(String(issue.total1));
              issue.igst3 = this.inrService.formatCurrency(String(issue.igst3));
              issue.cgst3 = this.inrService.formatCurrency(String(issue.cgst3));
              issue.sgst3 = this.inrService.formatCurrency(String(issue.sgst3));
              issue.cess3 = this.inrService.formatCurrency(String(issue.cess3));
              issue.interest3 = this.inrService.formatCurrency(
                String(issue.interest3)
              );
              issue.penalty3 = this.inrService.formatCurrency(
                String(issue.penalty3)
              );
              issue.total3 = this.inrService.formatCurrency(
                String(issue.total3)
              );

              if (issue.details && issue.details.length) {
                issue.details.forEach((details: any) => {
                  details.provisionAmt1 = this.inrService.formatCurrency(
                    String(details.provisionAmt1)
                  );
                  details.contLiabilityAmt1 = this.inrService.formatCurrency(
                    String(details.contLiabilityAmt1)
                  );
                  details.otherLiability1 = this.inrService.formatCurrency(
                    String(details.otherLiability1)
                  );
                  details.caroAmt1 = this.inrService.formatCurrency(
                    String(details.caroAmt1)
                  );
                });
              }
            });
          }
          if (this.caseData?.consultantCharges) {
            this.caseData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees));
            });
          }

          if (this.caseData?.protestDetails) {
            this.caseData.protestDetails.forEach((protest: any) => {
              protest.igst = this.inrService.formatCurrency(String(protest.igst));
              protest.cgst = this.inrService.formatCurrency(String(protest.cgst));
              protest.sgst = this.inrService.formatCurrency(String(protest.sgst));
              protest.cess = this.inrService.formatCurrency(String(protest.cess));
              protest.interest = this.inrService.formatCurrency(String(protest.interest))
              protest.penalty = this.inrService.formatCurrency(String(protest.penalty))
              protest.total = this.inrService.formatCurrency(String(protest.total))
            });
          }

          if (this.caseData?.liabilityDetails) {
            this.caseData.liabilityDetails.forEach((liability: any) => {
              liability.igst = this.inrService.formatCurrency(String(liability.igst));
              liability.cgst = this.inrService.formatCurrency(String(liability.cgst));
              liability.sgst = this.inrService.formatCurrency(String(liability.sgst));             
              liability.cess = this.inrService.formatCurrency(String(liability.cess));             
             liability.interest = this.inrService.formatCurrency(String(liability.interest));
              liability.penalty = this.inrService.formatCurrency(String(liability.penalty));
              liability.total = this.inrService.formatCurrency(String(liability.total));
            });
          }
          this.selectedStateName = response.response.state;
          //  this.caseData.state = this.selectedStateName;
          this.drc03CopyLocF = this.litService.getFileName(
            this.caseData.drc03CopyLoc
          );
          this.formInitialize();
          this.whetherCallBookChanged();
          if (this.caseData.itemList && this.caseData.itemList.length > 0) {
            this.initializeItemList();
          } else {
            this.initializeItemList();
            var list: UntypedFormGroup = this.newphArray();
            this.apaDivArr().push(list);
          }
          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList();
          } else {
            this.addIssueInv();
          }

          if (
            this.caseData.protestDetails &&
            this.caseData.protestDetails.length > 0
          ) {
            this.initializePaymentDetailList();
          } else {
            this.addDetailOfPayment();
          }

          if (
            this.caseData.appealDetails &&
            this.caseData.appealDetails.length > 0
          ) {
            this.initializeDetailOfAppealList();
          } else {
            this.addDetailOfAppeal();
          }

          if (
            this.caseData.consultantCharges &&
            this.caseData.consultantCharges.length > 0
          ) {
            this.initializeConsultantList();
          } else {
            this.addConsultantInv();
          }

          if (
            this.caseData.liabilityDetails &&
            this.caseData.liabilityDetails.length > 0
          ) {
            this.initializeDetailOfLiability();
          } else {
            this.addDetailsOfLiabilityAddmittedAndPaid();
          }
          if (this.caseData.appealBookLoc && this.caseData.appealBookLoc.length > 0) {
            this.initializeAppealBookLoc();
          } else {
            this.addAppealBookLoc();
          }

          if (this.caseData.otherAttachmentsLoc1 && this.caseData.otherAttachmentsLoc1.length > 0) {
            this.initializeOtherAttachmentLoc1();
          } else {
            this.addOtherAttachmentLoc1();
          }
          if (this.caseData.supremeCourtOrderLoc && this.caseData.supremeCourtOrderLoc.length > 0) {
            this.initializeSupremeCourtOrderCopyLoc();
          } else {
            this.addSupremeCourtOrdeCopy();
          }
          this.cumulativeCount = this.caseData.cumulative ? this.caseData.cumulative
            : this.cumulativeCount;
          this.prevStage = this.caseData.prevStage;
          this.protestDetailsChanged();
          this.setCummulativeCount();
          this.liabilityAddmittedAndPaidChanged();
          this.feesChargedChanged();
          // this.changeIssueConclusionValue();
          this.issueValueChange();
          this.changeOutcome();

          this.isUpdate = true;
          this.isadjorNot = true;

          //  this.changeIssueConclusionValue();
        }
      });
    }
  }
  initializeAppealBookLoc() {
    if (this.caseData.appealBookLoc) {
      this.caseData.appealBookLoc.forEach((not: any) => {
        let appealBookLocGrp = this.createAppealBookLoc(not);
        (this.scForm.get('appealBookLoc') as UntypedFormArray).push(appealBookLocGrp);
      })
      this.scForm.patchValue(this.caseData);
    }
  }

  initializeOtherAttachmentLoc1() {
    if (this.caseData.otherAttachmentsLoc1) {

      this.caseData.otherAttachmentsLoc1.forEach((not: any) => {

        let otherAttachLoc1Grp = this.createOtherAttachmentLoc1(not);

        (this.scForm.get('otherAttachmentsLoc1') as UntypedFormArray).push(otherAttachLoc1Grp);

      })

      this.scForm.patchValue(this.caseData);
    }
  }

  initializeSupremeCourtOrderCopyLoc() {
    if (this.caseData.supremeCourtOrderLoc) {
      this.caseData.supremeCourtOrderLoc.forEach((not: any) => {
        let supremeCourtOrderCopy = this.createsupremeCourtOrderCopyLoc(not);
        (this.scForm.get('supremeCourtOrderLoc') as UntypedFormArray).push(supremeCourtOrderCopy);
      })
      this.scForm.patchValue(this.caseData);
    }
  }
  initializeItemList() {
    if (this.caseData.itemList) {
      this.caseData.itemList.forEach((items: any) => {
        let itemListFrmGrp = this.newphArray(items);
        (this.scForm.get('itemList') as UntypedFormArray).push(
          itemListFrmGrp
        );

        if (items.phCopyLoc) {
          items.phCopyLoc.forEach((not: any) => {
            let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
            (itemListFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
          });
        }

        if (items.phRecordIfAnyLoc) {
          items.phRecordIfAnyLoc.forEach((not: any) => {
            let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
            (itemListFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(
              phRecordAnyGrp
            );
          });
        }

        if (items.phAdjournmentLetterLoc) {
          items.phAdjournmentLetterLoc.forEach((not: any) => {
            let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
            (itemListFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
          });
        }
        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup =
              this.createAdditionalSubLoc(not);
            (itemListFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(
              additionalSubmissionFrmGrp
            );
          });
        }



      });
      this.scForm.patchValue(this.caseData);
    }
  }
  initializeIssueList() {
    if (this.caseData.issues) {
      this.caseData.issues.forEach((issue: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.newIssueInvArray();
        (this.scForm.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        if (issue.details) {
          issue.details.forEach((detail: any) => {
            let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp();
            (issuesFrmGrp.get('details') as UntypedFormArray).push(detailFrmGrp);
          });
        }

      });
      this.scForm.patchValue(this.caseData);
    }
    this.issueValueChange();
  }
  // initializeProvList() {
  //   if(this.caseData.provisionDetails){
  //  this.caseData.provisionDetails.forEach((t: any) => {
  //    var provlist: FormGroup = this.newProvArr();
  //    this.provArr().push(provlist);
  //  });
  //  this.scForm.patchValue(this.caseData);
  // }
  // }

  UpdateModelData() {
    if (this.caseData != null) {
      // this.scForm.value.copyOfAckLoc = this.caseData['copyOfAckLoc'];
      // if(this.caseData.otherAttachmentsLoc2 != null){
      //   this.scForm.value.otherAttachmentsLoc2 = this.caseData['otherAttachmentsLoc2'];
      // }else{
      //   this.scForm.value.otherAttachmentsLoc2 = '';
      // }
   
      // this.scForm.value.drc03CopyLoc = this.caseData['drc03CopyLoc'];
      this.scForm.value.bankChallanLoc = this.caseData['bankChallanLoc'];
      if (this.caseData.supremeCourtOrderLoc != null) {
        this.scForm.value.supremeCourtOrderLoc =
          this.caseData['supremeCourtOrderLoc'];
      } else {
        this.scForm.value.supremeCourtOrderLoc = '';
      }
      // if(this.caseData.paymentChallanLoc != null){
      //   this.scForm.value.paymentChallanLoc = this.caseData['paymentChallanLoc'];
      // }else{
      //   this.scForm.value.paymentChallanLoc = '';
      // }
    }
  }

  getValidDate(date: any): any {
    if (date.value) {
      let d = date.value.split('-');
      const dateValidation = new Date(d[1] + '-' + d[0] + '-' + d[2]);
      // return dateValidation;
      return new Date();
    }
    //  else{
    //   return new Date();
    // }
  }
  doValidation(phaseTab: any) {
    this.UpdateModelData();
    if (phaseTab === 'p2') {
      this.consultantValidation();
      return this.p2validation();
    } else if (phaseTab === 'p3') {
      this.liabilityValidation();
      return this.p3validation();
    } else {
      return true;
    }
  }
  consultantValidation() {
    let consultantArray = this.scForm.controls.consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(
          issuesControl.controls.fees.value
        );
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (
            issuesControl.controls.firm.value === null ||
            issuesControl.controls.firm.value === ''
          ) {
            issuesControl.controls.firm.setErrors({ required: true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  p1validation() {
    if (
      (this.scForm.value['appealBookLoc'] == null ||
        this.scForm.value['appealBookLoc'] === '') &&
      this.caseType === this.caseTypeData.MEMORANDUM
    ) {
      this.appealBookLocReq = true;
    } else {
      this.appealBookLocReq = false;
    }
    return !this.appealBookLocReq;
  }
 

  liabilityValidation() {
    let protestArray = this.scForm.controls.liabilityDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        if (
          issuesControl.controls.challanNo.value != null &&
          issuesControl.controls.challanNo.value
        ) {
          if (
            issuesControl.controls.copyOfFormLoc.value === null ||
            issuesControl.controls.copyOfFormLoc.value === ''
          ) {
            issuesControl.controls.copyOfFormLoc.setErrors({ required: true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }
  inrFormattingRemoving() {
    const data = this.scForm.value;
    //phase 2 form
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
      });
    }
    //phase 1 form
    if (data.protestDetails && data.protestDetails.length) {
      data.protestDetails.forEach((protestDetail: any) => {
        protestDetail.igst = this.inrService.removeCommasAndParseNumber(
          protestDetail.igst
        );
        protestDetail.cgst = this.inrService.removeCommasAndParseNumber(
          protestDetail.cgst
        );
        protestDetail.sgst = this.inrService.removeCommasAndParseNumber(
          protestDetail.sgst
        );
        protestDetail.cess = this.inrService.removeCommasAndParseNumber(
          protestDetail.cess
        );
        
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(
          protestDetail.interest
        );
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(
          protestDetail.penalty
        );
        protestDetail.total = this.inrService.removeCommasAndParseNumber(
          protestDetail.total
        );
      });
    }
    //phase 3 form
    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(
          liability.igst
        );
        liability.cgst = this.inrService.removeCommasAndParseNumber(
          liability.cgst
        );
        liability.sgst = this.inrService.removeCommasAndParseNumber(
          liability.sgst
        );
        liability.cess = this.inrService.removeCommasAndParseNumber(
          liability.cess
        );
        liability.interest = this.inrService.removeCommasAndParseNumber(
          liability.interest
        );
        liability.penalty = this.inrService.removeCommasAndParseNumber(
          liability.penalty
        );
        liability.total = this.inrService.removeCommasAndParseNumber(
          liability.total
        );
      });
    }
    // //phase 1 and 3 form
    if (data.issues && data.issues.length) {
      data.issues.forEach((issue: any) => {
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1);
        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1);
        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1);
        issue.interest1 = this.inrService.removeCommasAndParseNumber(
          issue.interest1
        );
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(
          issue.penalty1
        );
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3);
        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3);
        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3);
        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3);
        issue.interest3 = this.inrService.removeCommasAndParseNumber(
          issue.interest3
        );
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(
          issue.penalty3
        );
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3);
        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(
              details.provisionAmt1
            );
            details.contLiabilityAmt1 =
              this.inrService.removeCommasAndParseNumber(
                details.contLiabilityAmt1
              );
            details.otherLiability1 =
              this.inrService.removeCommasAndParseNumber(
                details.otherLiability1
              );
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(
              details.caroAmt1
            );
          });
        }
      });
    }
    // //phase 1 and 3 form
    data.igst1Total = this.inrService.removeCommasAndParseNumber(
      data.igst1Total
    );
    data.cgst1Total = this.inrService.removeCommasAndParseNumber(
      data.cgst1Total
    );
    data.sgst1Total = this.inrService.removeCommasAndParseNumber(
      data.sgst1Total
    );
    data.cess1Total = this.inrService.removeCommasAndParseNumber(
      data.cess1Total
    );
    data.interest1Total = this.inrService.removeCommasAndParseNumber(
      data.interest1Total
    );
    data.penalty1Total = this.inrService.removeCommasAndParseNumber(
      data.penalty1Total
    );
    data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(
      data.totOfTotal1
    );
    data.igst3Total = this.inrService.removeCommasAndParseNumber(
      data.igst3Total
    );
    data.cgst3Total = this.inrService.removeCommasAndParseNumber(
      data.cgst3Total
    );
    data.sgst3Total = this.inrService.removeCommasAndParseNumber(
      data.sgst3Total
    );
    data.cess3Total = this.inrService.removeCommasAndParseNumber(
      data.cess3Total
    );
    data.interest3Total = this.inrService.removeCommasAndParseNumber(
      data.interest3Total
    );
    data.penalty3Total = this.inrService.removeCommasAndParseNumber(
      data.penalty3Total
    );
    data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(
      data.totOfTotal3
    );
  }

  updateIssues() {
    (this.scForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ igst3: c.value.igst1 });
      c.patchValue({ cgst3: c.value.cgst1 });
      c.patchValue({ sgst3: c.value.sgst1 });
      c.patchValue({ cess3: c.value.cess1 });
      c.patchValue({ interest3: c.value.interest1 });
      c.patchValue({ penalty3: c.value.penalty1 });
      c.patchValue({ total3: c.value.total1 });
    });
  }
  /*Update function*/
  updateLetter(phaseTab: any) {
    console.log(this.scForm, 'scForm');
    this.isSubmitted = true;

    this.setCaseItemList();
    if (phaseTab === 'p1') {
      this.updateIssues();
      let protestArray = this.scForm.controls.protestDetails as UntypedFormArray
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;
         
          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
        let tagControls = issuesControl.controls.tag.value;
        let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;
        let challanNoControls = issuesControl.controls.challanNo.value ;
        //let hasissueName = issueNam eControls.some((docControl) => docControl.get('fileLoc')?.value != null);
        if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
          this.toaster.showError("Required Fields Are Empty in Payment Table.");
          return;
        }
        }      
        
       
      }  
    }else{
      this.p2validation();
    }
    if (phaseTab === 'p2') {
      this.p1validation();
      let submissionsFrmArray = (this.scForm.get('appealDetails') as UntypedFormArray).controls;
      let element1;
      for (let i = 0; i < submissionsFrmArray.length; i++) {
        element1 = (submissionsFrmArray[i].get('ackCopyLoc') as UntypedFormArray).controls;
      }
      let count = element1?.some((x: any) =>
        x.get('fileLoc')?.value != null);
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
    }

    let data: any = {};
    if (phaseTab === 'p3') {
      let dataGstatOrderCopyLoc = (this.scForm.get('supremeCourtOrderLoc') as UntypedFormArray).controls;
      var count = dataGstatOrderCopyLoc?.some((x) => x.get('fileLoc')?.value != '');
      if (!count) {
        this.toaster.showError('Required Fields Are Empty');
        return;
      }
      let alArray = this.scForm.controls.liabilityDetails as UntypedFormArray
      for (let index = 0; index < alArray.controls.length; index++) {
        let alControl = alArray.controls[index] as UntypedFormGroup;

        if (alControl.controls.challanNo.value || alControl.controls.total.value) {
          let copyOfFormLocControls = (alControl.get('copyOfFormLoc') as UntypedFormArray).controls;
         
          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = alControl.controls.issueName.value;
        let tagControls = alControl.controls.tag.value;
        let dateOfPaymentControls = alControl.controls.dateOfPayment.value ;
        let challanNoControls = alControl.controls.challanNo.value ;
        //let hasissueName = issueNam eControls.some((docControl) => docControl.get('fileLoc')?.value != null);
        if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
          this.toaster.showError("Required Fields Are Empty in Payment Table.");
          return;
        }
        }      
        
       
      }
    }
    let isTabValid = this.doValidation(phaseTab);

    for (const property in this.scForm.controls) {
      if (this.scForm.controls[property].status === 'INVALID')
        console.log(`${property}: ${this.scForm.controls[property].status}`);
    }
    if (this.scForm.valid && isTabValid) {
      data = this.scForm.value;
      data.state = this.selectedStateName;
      let urlData = {
        typeVal: this.tabType[5].key,
        gstin: this.selectedGstin,
      };
      data.caseType = this.caseType;
      data.cumulative = this.cumulativeCount;
      data.prevStage = this.caseData.prevStage;
      data.gstinDiv = this.selectedGstinDiv;

      Object.assign(
        data,
        { action: 'UPDATE' },
        { phase: '1' },
        { keyword: 'keyword1' }
      );
      let bookLocArray: any[] = [];
      let otherAttachmentLoc1Array: any[] = [];
      let protestCopyFormArray: any[] = [];
      let protestBankChallanArray: any[] = [];
      let appealAckCopyArray: any[] = [];
      let appealOtherAttachArray: any[] = [];
      let phCopyLocArray: any[] = [];
      let additionalSubmissionLocArray: any[] = [];
      let phRecordIfAnyLocArray: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];
      let consultChareAttachArray: any[] = [];
      let supremeCourtCopyLocArray: any[] = [];
      let liabilityCopyFormArray: any[] = [];
      let liabilityBankChallanArray: any[] = [];
      for (let i = 0; i < this.scForm.controls.appealBookLoc.value.length; i++) {
        const element = this.scForm.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          bookLocArray.push(element)
        }

      }
      for (let i = 0; i < this.scForm.controls.otherAttachmentsLoc1.value.length; i++) {
        const element = this.scForm.controls.otherAttachmentsLoc1.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachmentLoc1Array.push(element)
        }

      }
      for (let i = 0; i < this.scForm.controls.protestDetails.value.length; i++) {
        protestCopyFormArray = []
        const copyFormArray = this.scForm.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestCopyFormArray.push(element)
          }
        }
        this.scForm.controls.protestDetails.value[i].copyOfFormLoc = protestCopyFormArray;
      }
      for (let i = 0; i < this.scForm.controls.protestDetails.value.length; i++) {
        protestBankChallanArray = [];
        const bankChallanArray = this.scForm.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestBankChallanArray.push(element)
          }
        }
        this.scForm.controls.protestDetails.value[i].copyOfBankChallanLoc = protestBankChallanArray;
      }
      for (let i = 0; i < this.scForm.controls.appealDetails.value.length; i++) {
        appealAckCopyArray = [];
        const appealAckCopyArrayData = this.scForm.controls.appealDetails.value[i].ackCopyLoc;

        for (let j = 0; j < appealAckCopyArrayData.length; j++) {
          const element = appealAckCopyArrayData[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            appealAckCopyArray.push(element)
          }
        }
        this.scForm.controls.appealDetails.value[i].ackCopyLoc = appealAckCopyArray;
      }
      for (let i = 0; i < this.scForm.controls.appealDetails.value.length; i++) {
        appealOtherAttachArray = [];
        const appealOtherAttachArrayData = this.scForm.controls.appealDetails.value[i].otherAttachmentsLoc;

        for (let j = 0; j < appealOtherAttachArrayData.length; j++) {
          const element = appealOtherAttachArrayData[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            appealOtherAttachArray.push(element)
          }
        }
        this.scForm.controls.appealDetails.value[i].otherAttachmentsLoc = appealOtherAttachArray;
      }
      for (let i = 0; i < this.scForm.controls.itemList.value.length; i++) {
        phCopyLocArray = [];
        const elementPhCopyArray = this.scForm.controls.itemList.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element)
          }
        }
        this.scForm.controls.itemList.value[i].phCopyLoc = phCopyLocArray;
      }
      for (let i = 0; i < this.scForm.controls.itemList.value.length; i++) {
        additionalSubmissionLocArray = [];
        const elementAddSubArray = this.scForm.controls.itemList.value[i].additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element)
          }
        }
        this.scForm.controls.itemList.value[i].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (let i = 0; i < this.scForm.controls.itemList.value.length; i++) {
        phRecordIfAnyLocArray = [];
        const phRecordArray = this.scForm.controls.itemList.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element)
          }
        }
        this.scForm.controls.itemList.value[i].phRecordIfAnyLoc = phRecordIfAnyLocArray;
      }
      for (let i = 0; i < this.scForm.controls.itemList.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.scForm.controls.itemList.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.scForm.controls.itemList.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.scForm.controls.consultantCharges.value.length; i++) {
        consultChareAttachArray = [];
        const consultOtherAttachArrayData = this.scForm.controls.consultantCharges.value[i].otherAttachmentLoc;

        for (let j = 0; j < consultOtherAttachArrayData.length; j++) {
          const element = consultOtherAttachArrayData[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultChareAttachArray.push(element)
          }
        }
        this.scForm.controls.consultantCharges.value[i].otherAttachmentLoc = consultChareAttachArray;
      }
      for (let i = 0; i < this.scForm.controls.supremeCourtOrderLoc.value.length; i++) {
        const element = this.scForm.controls.supremeCourtOrderLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          supremeCourtCopyLocArray.push(element)
        }

      }
      for (let i = 0; i < this.scForm.controls.liabilityDetails.value.length; i++) {
        liabilityCopyFormArray = [];
        const liabilitycopyFormArray = this.scForm.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < liabilitycopyFormArray.length; j++) {
          const element = liabilitycopyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityCopyFormArray.push(element)
          }
        }
        this.scForm.controls.liabilityDetails.value[i].copyOfFormLoc = liabilityCopyFormArray;
      }
      for (let i = 0; i < this.scForm.controls.liabilityDetails.value.length; i++) {
        liabilityBankChallanArray = [];
        const bankChallanArray = this.scForm.controls.liabilityDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityBankChallanArray.push(element)
          }
        }
        this.scForm.controls.liabilityDetails.value[i].copyOfBankChallanLoc = liabilityBankChallanArray;
      }


      this.scForm.value.appealBookLoc = bookLocArray;
      this.scForm.value.otherAttachmentsLoc1 = otherAttachmentLoc1Array;
      this.scForm.value.supremeCourtOrderLoc = supremeCourtCopyLocArray;
      this.inrFormattingRemoving();

      this.litService.updateSupremeCourtData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (this.isDataSaved === true) {
            //this.p1validation();
          }
          if (phaseTab === 'p1') {
            this.isPhase2 = true;
          } else if (phaseTab === 'p2') {
            this.isPhase3 = true;
          } else if (phaseTab === 'p3') {
            if (
              this.scForm.value.whetherAppealPreferred === true ||
              this.scForm.value.whetherAppealPreferred === 'true'
            ) {
              this.litService.setAccessTabData({
                scnFlag: true,
                ajaFlag: true,
                apaFlag: true,
                aptFlag: true,
                hcFlag: true,
                scFlag: true,
              });
              this.callTabFunction.emit('');
            } else {
              this.router.navigate(['/litigationSummary']);
            }
          }

          this.toaster.showSuccess(response.message);
          this.isDataSaved = true;
        } else {
          this.toaster.showError(response.message);
        }
        //console.log("updateLetter");
        // console.log(this.scForm.value);
      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  formatTableValues() {
    const issues1Array = this.scForm.controls.issues as UntypedFormArray;
    const liabilityDetailsArray = this.scForm.controls
      .liabilityDetails as UntypedFormArray;
    const protestDetailsArray = this.scForm.controls
      .protestDetails as UntypedFormArray;
    const consultantChargesArray = this.scForm.controls
      .consultantCharges as UntypedFormArray;

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (
        var index = 0;
        index < consultantChargesArray.controls.length;
        index++
      ) {
        const consultantChargesControl = consultantChargesArray.controls[
          index
        ] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(
          this.inrService.formatCurrency(consultantChargesControl.value.fees)
        );
      }
    }

    if (protestDetailsArray && protestDetailsArray.controls.length) {
      for (
        var index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        const protestDetailsControl = protestDetailsArray.controls[
          index
        ] as UntypedFormGroup;
        protestDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.igst));
        protestDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cgst))
        protestDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.sgst))
        protestDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cess))
        protestDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.interest))
        protestDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.penalty))
        protestDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.total))
      
      }
    }

    if (liabilityDetailsArray.controls.length > 0) {
      for (
        let index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[
          index
        ] as UntypedFormGroup;
        liabilityDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.igst));
        liabilityDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cgst))
        liabilityDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.sgst))
        liabilityDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cess))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest))
        liabilityDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.penalty))
        liabilityDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.total))
      }
    }

    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['igst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.igst1)
        );
        issues1Control.controls['cgst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cgst1)
        );
        issues1Control.controls['sgst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.sgst1)
        );
        issues1Control.controls['cess1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cess1)
        );
        issues1Control.controls['interest1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interest1)
        );
        issues1Control.controls['penalty1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penalty1)
        );
        issues1Control.controls['total1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.total1)
        );
        issues1Control.controls['igst3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.igst3)
        );
        issues1Control.controls['cgst3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cgst3)
        );
        issues1Control.controls['sgst3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.sgst3)
        );
        issues1Control.controls['cess3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cess3)
        );
        issues1Control.controls['interest3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interest3)
        );
        issues1Control.controls['penalty3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penalty3)
        );
        issues1Control.controls['total3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.total3)
        );
      }
    }
    this.issueValueChange();
  }

  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = startYear + '-' + (startYear + 1);
      this.finyearData.push({ year: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof fileUrl == 'string')
      return this.litService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl,
    };
    this.litService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    });
  }



  deleteSupremeCourtOrder() {
    if (this.scForm.get('supremeCourtOrderLoc')?.value) {
      this.scForm.get('supremeCourtOrderLoc')?.setValue(null);
      this.caseData['supremeCourtOrderLoc'] = null;
      this.issupremeCourtOrderLocFile = false;
      this.issupremeCourtOrderLocText = true;
      this.isDWsupremeCourtOrderLoc = true;
      this.isUPsupremeCourtOrderLoc = true;
    }
  }
  uploadbankChallanLoc() {
    this.isbankChallanLocFile = true;
    this.isbankChallanLocText = false;
    this.isDWbankChallanLoc = false;
    this.isUPbankChallanLoc = false;
    this.scForm.value.bankChallanLoc = null;
  }


 
  uploaddrc03CopyLoc() {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;
    this.scForm.value.drc03CopyLoc = null;
  }

  // uploadcopyOfAckLoc(){
  //   this.iscopyOfAckLocFile = true;
  //   this.iscopyOfAckLocText = false;
  //   this.isDWcopyOfAckLoc = false;
  //   this.isUPcopyOfAckLoc = false;
  //   this.scForm.value.copyOfAckLoc = null;
  // }
  // uploadotherAttachmentsLoc2(){
  //   this.isotherAttachmentsLoc2File = true;
  //   this.isotherAttachmentsLoc2Text = false;
  //   this.isDWotherAttachmentsLoc2 = false;
  //   this.isUPotherAttachmentsLoc2 = false;
  //   this.scForm.value.otherAttachmentsLoc2 = null;
  // }

  uploadphCopyLoc(index: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;
    var itemListArray = this.scForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhCopyLocUploaded'] = true;
        }
      }
    }
  }

  uploadadditionalSubmissionLoc(index: any) {
    this.isadditionalSubmissionLocFile = true;
    this.isadditionalSubmissionLocText = false;
    this.isDWadditionalSubmissionLoc = false;
    this.isUPadditionalSubmissionLoc = false;
    var itemListArray = this.scForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isAdditionalSubmissionLocUploaded'] = true;
        }
      }
    }
  }

  uploadphRecordIfAnyLoc(index: any) {
    this.isphRecordIfAnyLocFile = true;
    this.isphRecordIfAnyLocText = false;
    this.isDWphRecordIfAnyLoc = false;
    this.isUPphRecordIfAnyLoc = false;
    var itemListArray = this.scForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhRecordIfAnyLocUploaded'] = true;
        }
      }
    }
  }

  // uploadsupremeCourtOrderLoc() {
  //   this.issupremeCourtOrderLocFile = true;
  //   this.issupremeCourtOrderLocText = false;
  //   this.isDWsupremeCourtOrderLoc = false;
  //   this.isUPsupremeCourtOrderLoc = false;
  //   this.scForm.value.supremeCourtOrderLoc = null;
  // }
  // uploadpaymentChallanLoc(){
  //   this.ispaymentChallanLocFile = true;
  //   this.ispaymentChallanLocText = false;
  //   this.isDWpaymentChallanLoc = false;
  //   this.isUPpaymentChallanLoc = false;
  //   this.scForm.value.paymentChallanLoc = null;
  // }
  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
    }
    this.selectedStateName = this.commonServ.getStateName(
      this.selectedStateCode
    );
  }
  setDate() {
    this.currentYear = new Date().getFullYear() + 1;
  }
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );

        if (matchingConsultant) {
          this.scForm.controls.consultant1.patchValue(matchingConsultant.consultantName)
        }
      }
    });
  }
  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if(phase === 'CC'){
      this.isToggleConsultantDropdown3 = !this.isToggleConsultantDropdown3;
    }
  }
  onClickToggleSegmentDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleSegmentDropdown = !this.isToggleSegmentDropdown;
    } else if (phase === 'p2') {
      this.isToggleSegmentDropdown2 = !this.isToggleSegmentDropdown2;
    }
  }
  
  issueDropdown() {
    this.dataSource = ISSUE_GST;
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
    this.finYearSelelctEvent(i);
    this.quarterSelectEvent(i);
  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  savecaseItemList() {
    console.log(this.scForm.value, 'value');
    const itemList = this.scForm.value.itemList;
    if (itemList.length > 0) {
      itemList.forEach((item: any, i: number) => {
        if (!this.caseItemList[i]) {
          this.caseItemList.push({});
        }
        if (item.phCopyLoc) {
          this.caseItemList[i].phCopyLoc = item.phCopyLoc;
        }
        if (item.additionalSubmissionLoc) {
          this.caseItemList[i].additionalSubmissionLoc =
            item.additionalSubmissionLoc;
        }
        if (item.phRecordIfAnyLoc) {
          this.caseItemList[i].phRecordIfAnyLoc = item.phRecordIfAnyLoc;
        }
        if (item.phAdjournmentLetterLoc) {
          this.caseItemList[i].phAdjournmentLetterLoc = item.phAdjournmentLetterLoc;
        }
        console.log(this.caseItemList);
      });

      // this.caseItemList.push()
    }
  }

  setCaseItemList() {
    this.scForm.value.itemList.forEach((data: any, i: number) => {
      if (!data.phCopyLoc) {
        data.phCopyLoc = this.caseItemList[i]?.phCopyLoc;
      }
      if (!data.additionalSubmissionLoc) {
        data.additionalSubmissionLoc =
          this.caseItemList[i]?.additionalSubmissionLoc;
      }
      if (!data.phRecordIfAnyLoc) {
        data.phRecordIfAnyLoc = this.caseItemList[i]?.phRecordIfAnyLoc;
      }
      if (!data.phAdjournmentLetterLoc) {
        data.phAdjournmentLetterLoc = this.caseItemList[i]?.phAdjournmentLetterLoc;
      }
    });
  }
  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map(
      (data: any) => data.finYear
    );
    this.finyearData.forEach((f) => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map(
      (data: any) => data.quarter
    );
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }

  whetherCallBookChanged() {
    console.log(
      this.scForm.value.whetherCaseCallBook,
      'this.preAjaForm.value.whetherCaseCallBook'
    );
    this.disablePhase3 =
      this.scForm.value.whetherCaseCallBook === 'true' ||
        this.scForm.value.whetherCaseCallBook === true
        ? true
        : false;
  }

  issueValueChange() {
    let igst1Total = 0;
    let cgst1Total = 0;
    let sgst1Total = 0;
    let cess1Total = 0;
    let interest1Total = 0;
    let penalty1Total = 0;
    let cess3Total = 0;
    let cgst3Total = 0;
    let igst3Total = 0;
    let interest3Total = 0;
    let penalty3Total = 0;
    let sgst3Total = 0;
    (this.scForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const igst1 = this.inrService.removeCommasAndParseNumber(c.value.igst1);
      const cgst1 = this.inrService.removeCommasAndParseNumber(c.value.cgst1);
      const sgst1 = this.inrService.removeCommasAndParseNumber(c.value.sgst1);
      const cess1 = this.inrService.removeCommasAndParseNumber(c.value.cess1);
      const interest1 = this.inrService.removeCommasAndParseNumber(
        c.value.interest1
      );
      const penalty1 = this.inrService.removeCommasAndParseNumber(
        c.value.penalty1
      );
      const igst3 = this.inrService.removeCommasAndParseNumber(c.value.igst3);
      const cgst3 = this.inrService.removeCommasAndParseNumber(c.value.cgst3);
      const sgst3 = this.inrService.removeCommasAndParseNumber(c.value.sgst3);
      const cess3 = this.inrService.removeCommasAndParseNumber(c.value.cess3);
      const interest3 = this.inrService.removeCommasAndParseNumber(
        c.value.interest3
      );
      const penalty3 = this.inrService.removeCommasAndParseNumber(
        c.value.penalty3
      );

      igst1Total += Number(igst1);
      cgst1Total += Number(cgst1);
      sgst1Total += Number(sgst1);
      cess1Total += Number(cess1);
      interest1Total += Number(interest1);
      penalty1Total += Number(penalty1);
      igst3Total += Number(igst3);
      cgst3Total += Number(cgst3);
      sgst3Total += Number(sgst3);
      cess3Total += Number(cess3);
      interest3Total += Number(interest3);
      penalty3Total += Number(penalty3);

      c.patchValue({
        total1: this.inrService.formatCurrency(
          String(
            Number(igst1) +
            Number(cgst1) +
            Number(sgst1) +
            Number(cess1) +
            Number(interest1) +
            Number(penalty1)
          )
        ),
      });
      c.patchValue({
        total3: this.inrService.formatCurrency(
          String(
            Number(igst3) +
            Number(cgst3) +
            Number(sgst3) +
            Number(cess3) +
            Number(interest3) +
            Number(penalty3)
          )
        ),
      });
    });

    this.scForm.controls['igst1Total'].setValue(
      this.inrService.formatCurrency(String(igst1Total))
    );
    this.scForm.controls['cgst1Total'].setValue(
      this.inrService.formatCurrency(String(cgst1Total))
    );
    this.scForm.controls['sgst1Total'].setValue(
      this.inrService.formatCurrency(String(sgst1Total))
    );
    this.scForm.controls['cess1Total'].setValue(
      this.inrService.formatCurrency(String(cess1Total))
    );
    this.scForm.controls['interest1Total'].setValue(
      this.inrService.formatCurrency(String(interest1Total))
    );
    this.scForm.controls['penalty1Total'].setValue(
      this.inrService.formatCurrency(String(penalty1Total))
    );
    this.scForm.controls['cess3Total'].setValue(
      this.inrService.formatCurrency(String(cess3Total))
    );
    this.scForm.controls['cgst3Total'].setValue(
      this.inrService.formatCurrency(String(cgst3Total))
    );
    this.scForm.controls['igst3Total'].setValue(
      this.inrService.formatCurrency(String(igst3Total))
    );
    this.scForm.controls['sgst3Total'].setValue(
      this.inrService.formatCurrency(String(sgst3Total))
    );
    this.scForm.controls['penalty3Total'].setValue(
      this.inrService.formatCurrency(String(penalty3Total))
    );
    this.scForm.controls['interest3Total'].setValue(
      this.inrService.formatCurrency(String(interest3Total))
    );

    this.scForm.controls['totOfTotal1'].setValue(
      this.inrService.formatCurrency(
        String(
          Number(
            +igst1Total +
            +cgst1Total +
            +sgst1Total +
            +cess1Total +
            +interest1Total +
            +penalty1Total
          )
        )
      )
    );
    this.scForm.controls['totOfTotal3'].setValue(
      this.inrService.formatCurrency(
        String(
          Number(
            +cess3Total +
            +cgst3Total +
            +igst3Total +
            +sgst3Total +
            +penalty3Total +
            +interest3Total
          )
        )
      )
    );
  }

  checkTypeOfAuthoritySelected(event: any) {
    if (event.target.value === 'CGST') {
      this.scForm.get('din1')?.setErrors({ required: true });
    } else {
      this.scForm.get('din1')?.setErrors(null);
    }
  }

  getDetailOfPaymentArr(): UntypedFormGroup[] {
    return (this.scForm.get('protestDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  rmPaymentUnderProtest(i: number) {
    (this.scForm.get('protestDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPaymentRef.close();
    this.protestDetailsChanged();
  }

  addDetailOfPayment() {
    let protestDetailsFrmGrp = this.detailOfprotestDetails();
    (this.scForm.get('protestDetails') as UntypedFormArray).push(protestDetailsFrmGrp);
  }
  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvDetailsOfPaymentRef = this.modalService.open(content);
  }
  initializePaymentDetailList() {
    if (this.caseData.protestDetails) {
      this.caseData.protestDetails.forEach((protestDetails: any) => {
        let depositDetailsFrmGrp: UntypedFormGroup =
          this.detailOfprotestDetails(protestDetails);
        (this.scForm.get('protestDetails') as UntypedFormArray).push(
          depositDetailsFrmGrp
        );

        if (protestDetails.copyOfFormLoc) {
          protestDetails.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createProtestCopyForm(not);
            (depositDetailsFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }

        if (protestDetails.copyOfBankChallanLoc) {
          protestDetails.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createProtestBankChallanForm(not);
            (depositDetailsFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);
          });
        }
      });
    }
  }

  savePortestlDetailList() {
    const appealDetailList = this.scForm.value.protestDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.protestDetailItemList[i]) {
          this.protestDetailItemList.push({});
        }
        if (item.copyOfFormLoc) {
          this.protestDetailItemList[i].copyOfFormLoc = item.copyOfFormLoc;
        }
        if (item.copyOfBankChallanLoc) {
          this.protestDetailItemList[i].copyOfBankChallanLoc =
            item.copyOfBankChallanLoc;
        }
      });
    }
    this.scForm.controls.protestDetails.patchValue(appealDetailList);
  }
  setPortestlDetailList() {
    this.scForm.value.protestDetails.forEach((data: any, i: number) => {
      if (!data.copyOfFormLoc) {
        data.copyOfFormLoc = this.protestDetailItemList[i]?.copyOfFormLoc;
      }
      if (!data.copyOfBankChallanLoc) {
        data.copyOfBankChallanLoc =
          this.protestDetailItemList[i]?.copyOfBankChallanLoc;
      }
    });
  }
  updateProtestDetails() {
    (this.scForm.get('protestDetails') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ copyOfFormLoc: c.value.copyOfFormLoc });
      c.patchValue({ copyOfBankChallanLoc: c.value.copyOfBankChallanLoc });
    });
  }
  protestDetailsChanged() {
    let igstTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let protestDetailTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;

    (this.scForm.get('protestDetails') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
        const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
        const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
        const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
        const interest = this.inrService.removeCommasAndParseNumber(
          c.value.interest
        );
        const penalty = this.inrService.removeCommasAndParseNumber(
          c.value.penalty
        );
        igstTotal += Number(igst);
        cgstTotal += Number(cgst);
        sgstTotal += Number(sgst);
        cessTotal += Number(cess);
        interestTotal += Number(interest);
        penaltyTotal += Number(penalty);
        c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + Number(cgst) + Number(sgst) + Number(cess) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });

        const total = this.inrService.removeCommasAndParseNumber(c.value.total);
        protestDetailTotal += Number(total);
      }
    );

    this.protestInterestTotal = interestTotal;
    this.protestTaxTotal = igstTotal;
    this.protestcgstTotal = cgstTotal;
    this.protestsgstTotal = sgstTotal;
    this.protestcessTotal = cessTotal;
    this.protestPenaltyTotal = penaltyTotal;
    this.protestTotal = protestDetailTotal;
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('details') as UntypedFormArray)
      .controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue =
      detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
        console.log(detailArr[index].controls[id]);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    });
  }

  // setCummulativeCount(){
  //   let preDepositeTax = 0;
  //   let preDepositePenalty   = 0;
  //   let preDepositeInterest = 0;
  //   let preDepositeTotal = 0;

  //   let protestPaymentTax = 0;
  //   let protestPaymentPenalty   = 0;
  //   let protestPaymentInterest = 0;
  //   let protestPaymentTotal = 0;

  //   let liabilityPaymentTax = 0
  //   let liabilityPaymentPenalty = 0;
  //   let liabilityPaymentInterest = 0;
  //   let liabilityPaymentTotal = 0;

  //   let consultantFees = 0;

  //  if(this.selectedRemandedBackCaseDetail && this.selectedRemandedBackCaseDetail?.cumulative?.length > 0){

  //  this.selectedRemandedBackCaseDetail.cumulative.forEach((p: any) =>{
  //    if(p.label === 'Cumulative payment made under protest'){
  //      p.tax ? protestPaymentTax += p.tax : null;
  //      p.penalty ? protestPaymentPenalty += p.penalty : null;
  //      p.interest ? protestPaymentInterest += p.interest : null;
  //      p.total ? protestPaymentTotal +=p.total : null;
  //    }else if(p.label === 'Cumulative payment made for admitted liability'){
  //      p.tax ? liabilityPaymentTax += p.tax : null;
  //      p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //      p.interest ? liabilityPaymentInterest += p.interest : null;
  //      p.total ? liabilityPaymentTotal +=p.total : null;
  //    }else if(p.label === 'Cumulative Consultant Charges'){
  //      p.fees ? consultantFees += p.fees : null;

  //    }
  //    })
  //  }

  //  if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
  //   this.caseData.cumulative.forEach((p: any) =>{
  //     if(p.label === 'Cumulative payment made under protest'){
  //       p.tax ? protestPaymentTax += p.tax : null;
  //       p.penalty ? protestPaymentPenalty += p.penalty : null;
  //       p.interest ? protestPaymentInterest += p.interest : null;
  //       p.total ? protestPaymentTotal +=p.total : null;
  //     }else if(p.label === 'Cumulative payment made for admitted liability'){
  //       p.tax ? liabilityPaymentTax += p.tax : null;
  //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //       p.interest ? liabilityPaymentInterest += p.interest : null;
  //       p.total ? liabilityPaymentTotal +=p.total : null;
  //     }else if(p.label === 'Cumulative Consultant Charges'){
  //       p.total ? consultantFees += p.total : null;

  //     }
  //     })
  //  }

  //  this.scForm.value.protestDetails.forEach((p : any) =>{

  //    p.tax ? protestPaymentTax += p.tax : null;
  //    p.penalty ? protestPaymentPenalty += p.penalty : null;
  //    p.interest ? protestPaymentInterest += p.interest : null;
  //    p.total ? protestPaymentTotal +=p.total : null;
  //  });
  //  this.scForm.value.liabilityDetails.forEach((p : any) =>{
  //    p.tax ? liabilityPaymentTax += p.tax : null;
  //    p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //    p.interest ? liabilityPaymentInterest += p.interest : null;
  //    p.total ? liabilityPaymentTotal +=p.total : null;
  //  });

  // //  this.scForm.value.protestDetails.forEach((p:any) => {
  // //   p.tax ? preDepositeTax += p.tax : null;
  // //   p.penalty ? preDepositePenalty += p.penalty : null;
  // //   p.interest ? preDepositeInterest += p.interest : null;
  // //   p.total ? preDepositeTotal +=p.total : null;
  // //  });

  //  this.scForm.value.consultantCharges.forEach((c : any) =>{
  //    c.fees ? consultantFees += c.fees : null;
  //  });
  //  this.cumulativeCount.forEach((c: any) =>{
  //   // if(c.label === 'Cumulative Pre-Deposit'){
  //   //   preDepositeTax ?  c.tax = preDepositeTax  : null;
  //   //   preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
  //   //   preDepositeInterest ?  c.interest = preDepositeInterest  : null;
  //   //   preDepositeTotal ?  c.total = preDepositeTotal  : null;
  //     // }
  //      if(c.label === 'Cumulative payment made under protest'){
  //        protestPaymentTax ?  c.tax = protestPaymentTax  : null;
  //        protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
  //        protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
  //        protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
  //      }else if(c.label === 'Cumulative payment made for admitted liability'){
  //        liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
  //        liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
  //        liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
  //        liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
  //      }else if(c.label === 'Cumulative Consultant Charges'){
  //        consultantFees ? c.total = consultantFees  : null;
  //      }
  //  });
  // }

  setCummulativeCount(
    oldArr: any = null,
    newArr: any = null,
    tableName: any = null
  ) {
    // let protestPaymentTax = 0;
    // let protestPaymentPenalty = 0;
    // let protestPaymentInterest = 0;
    // let protestPaymentTotal = 0;
    // let liabilityPaymentTax = 0
    // let liabilityPaymentPenalty = 0;
    // let liabilityPaymentInterest = 0;
    // let liabilityPaymentTotal = 0;
    // let consultantFees = 0;
    // let preDepositeTax = 0;
    // let preDepositePenalty = 0;
    // let preDepositeInterest = 0;
    // let preDepositeTotal = 0;
    // let oldTotalTax :any= 0, oldTotalInterrest :any= 0 , oldTotalPenalty:any = 0;
    // let newTotalTax :any= 0, newTotalInterrest :any= 0 , newTotalPenalty:any = 0;
    // let oldFees :any=0, newFees:any= 0;
    // if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
    //   this.caseData.cumulative.forEach((p: any) =>{
    //     if(p.label === 'Cumulative Pre-Deposit'){
    //       p.tax ? preDepositeTax += p.tax : null;
    //       p.penalty ? preDepositePenalty += p.penalty : null;
    //       p.interest ? preDepositeInterest += p.interest : null;
    //       p.total ? preDepositeTotal +=p.total : null;
    //     }
    //     if(p.label === 'Cumulative payment made under protest'){
    //       p.tax ? protestPaymentTax += p.tax : null;
    //       p.penalty ? protestPaymentPenalty += p.penalty : null;
    //       p.interest ? protestPaymentInterest += p.interest : null;
    //       p.total ? protestPaymentTotal +=p.total : null;
    //     }else if(p.label === 'Cumulative payment made for admitted liability'){
    //       p.tax ? liabilityPaymentTax += p.tax : null;
    //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //       p.interest ? liabilityPaymentInterest += p.interest : null;
    //       p.total ? liabilityPaymentTotal +=p.total : null;
    //     }else if(p.label === 'Cumulative Consultant Charges'){
    //       p.total ? consultantFees += p.total : null;
    //     }
    //     })
    //  }
    // //array is not null and empty
    // if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== 'consultantCharges'){
    //   oldArr.forEach((p : any) =>{
    //     if( p.tax != null)
    //     oldTotalTax +=  p.tax ;
    //     if( p.penalty != null)
    //     oldTotalPenalty += p.penalty;
    //     if( p.interest != null)
    //     oldTotalInterrest += p.interest;
    //   });
    //   newArr.forEach((p : any) =>{
    //     if( p.tax != null)
    //     newTotalTax +=  p.tax ;
    //     if( p.penalty != null)
    //     newTotalPenalty += p.penalty;
    //     if( p.interest != null)
    //     newTotalInterrest += p.interest;
    //   });
    // }else if(tableName === 'consultantCharges'){
    //   oldArr.forEach((p : any) =>{
    //    if(p.fees != null){
    //      oldFees += p.fees;
    //    }
    //   });
    //   newArr.forEach((p : any) =>{
    //     if(p.fees != null){
    //       newFees += p.fees;
    //     }
    //   })
    // }
    // if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== null){
    //   this.cumulativeCount.forEach((c: any) =>{
    //     if(c.label === 'Cumulative Pre-Deposit' && tableName === 'protestDetails'){
    //       c.tax = preDepositeTax - oldTotalTax + newTotalTax ;
    //       c.penalty = preDepositePenalty - oldTotalPenalty + newTotalPenalty ;
    //       c.interest = preDepositeInterest - oldTotalInterrest + newTotalInterrest ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }
    //   if(c.label === 'Cumulative payment made under protest' &&  tableName === 'protestDetails'){
    //     c.tax = protestPaymentTax - oldTotalTax + newTotalTax  ;
    //     c.penalty = protestPaymentPenalty - oldTotalPenalty + newTotalPenalty  ;
    //     c.interest = protestPaymentInterest - oldTotalInterrest + newTotalInterrest  ;
    //     c.total = c.tax + c.penalty + c.interest  ;
    //   }
    //   else if(c.label === 'Cumulative payment made for admitted liability' && tableName === 'liabilityDetails'){
    //     c.tax = liabilityPaymentTax - oldTotalTax + newTotalTax  ;
    //     c.penalty = liabilityPaymentPenalty - oldTotalPenalty + newTotalPenalty ;
    //     c.interest = liabilityPaymentInterest - oldTotalInterrest + newTotalInterrest ;
    //     c.total = c.tax + c.penalty + c.interest  ;
    //   }else if(c.label === 'Cumulative Consultant Charges' && tableName === 'consultantCharges'){
    //     c.total = consultantFees - oldFees + newFees  ;
    //   }
    //   });
    //   this.caseData.cumulative = this.cumulativeCount;
    // }else{
    //   this.cumulativeCount.forEach((c: any) =>{
    //     if(c.label === 'Cumulative Pre-Deposit'){
    //       preDepositeTax ?  c.tax = preDepositeTax  : null;
    //       preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
    //       preDepositeInterest ?  c.interest = preDepositeInterest  : null;
    //       preDepositeTotal ?  c.total = preDepositeTotal  : null;
    //     }
    //     if(c.label === 'Cumulative payment made under protest'){
    //       protestPaymentTax ?  c.tax = protestPaymentTax  : null;
    //       protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
    //       protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
    //       protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
    //     }else if(c.label === 'Cumulative payment made for admitted liability'){
    //       liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
    //       liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
    //       liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
    //       liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
    //     }else if(c.label === 'Cumulative Consultant Charges'){
    //       consultantFees ? c.total = consultantFees  : null;
    //     }
    //   });
    // }
    // if(tableName === 'protestDetails') this.protestDetailsChanged();
    // // if(tableName === 'protestDetails') this.preDepositeChanged();
    // if(tableName === 'consultantCharges') this.feesChargedChanged();
    // if(tableName === 'liabilityDetails') this.liabilityAddmittedAndPaidChanged();
  }

  detailOfprotestDetails(data: any = null) {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],
      markedForPayment: [data ? data?.markedForPayment : null],
      formOfPayment: [data ? data?.formOfPayment : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      challanNo: [data ? data?.challanNo : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, []],
      cgst: [data ? data?.cgst : 0, []],
      sgst: [data ? data?.sgst : 0, []],
      cess: [data ? data?.cess : 0, []],
      interest: [data ? data?.interest : 0, []],
      penalty: [data ? data?.penalty : 0, []],
      total: [data ? data?.total : 0, []],
    });
  }
  uploadProtestDetailsCopyOfFormLoc(i: any) {
    let protestDetailsArray = this.scForm.controls.protestDetails as UntypedFormArray;
    if (protestDetailsArray.controls.length > 0) {
      for (
        let index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          let protestDetailsControl = protestDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isProtestDetailCopyOfFormLocFile = true;
    this.isProtestDetailCopyOfFormLocText = false;
    this.isProtestDetailCopyOfFormLocDWText = true;
    this.isProtestDetailCopyOfFormLocUPText = false;
  }

  // uploadProtestDetailsCopyOfFormLoc() {
  //   this.isProtestDetailCopyOfFormLocFile = true;
  //   this.isProtestDetailCopyOfFormLocText = false;
  //   this.isProtestDetailCopyOfFormLocDWText = true;
  //   this.isProtestDetailCopyOfFormLocUPText = false;
  // }

  uploadProtestDetailsCopyOfBankChallanLoc(i: any) {
    let protestDetailsArray = this.scForm.controls.protestDetails as UntypedFormArray;
    if (protestDetailsArray.controls.length > 0) {
      for (
        let index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          let protestDetailsControl = protestDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          protestDetailsControl.value[
            'isCopyOfBankChallanLocDocUploadedClicked'
          ] = true;
        }
      }
    }
    this.isProtestDetailCopyOfBankChallanLocFile = true;
    this.isProtestDetailCopyOfBankChallanLocText = false;
    this.isProtestDetailCopyOfBankChallanLocDWText = true;
    this.isProtestDetailCopyOfBankChallanLocUPText = false;
  }
  // uploadProtestDetailsCopyOfBankChallanLoc() {
  //   this.isProtestDetailCopyOfBankChallanLocFile = true;
  //   this.isProtestDetailCopyOfBankChallanLocText = false;
  //   this.isProtestDetailCopyOfBankChallanLocDWText= true;
  //   this.isProtestDetailCopyOfBankChallanLocUPText = false;
  // }

  getDetailOfAppeal() {
    return (this.scForm.get('appealDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  addDetailOfAppeal() {
    let appealDetailsFrmGrp = this.detailOfAppeal();
    (this.scForm.get('appealDetails') as UntypedFormArray).push(appealDetailsFrmGrp);
  }

  detailOfAppeal(data: any = null) {
    return this.fBuild.group({
      formType: [data ? data?.formType : null],
      appealRefNo: [data ? data?.appealRefNo : null],
      filingAppealDate: [data ? data?.filingAppealDate : null],
      appealMode: [data ? data?.appealMode : null],
      ackCopyLoc: new UntypedFormArray([]),
      otherAttachmentsLoc: new UntypedFormArray([]),
    });
  }

  rmDetailsOfAppeal(i: number) {
    (this.scForm.get('appealDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfAppealRef.close();
  }

  openRemoveDetailsOfAppeal(content: any) {
    this.rmvDetailsOfAppealRef = this.modalService.open(content);
  }

  initializeDetailOfAppealList() {
    if (this.caseData.appealDetails) {
      this.caseData.appealDetails.forEach((appealDetails: any) => {
        let appealDetailsFrmGrp: UntypedFormGroup = this.detailOfAppeal(appealDetails);
        (this.scForm.get('appealDetails') as UntypedFormArray).push(appealDetailsFrmGrp);

        if (appealDetails.ackCopyLoc) {
          appealDetails.ackCopyLoc.forEach((not: any) => {
            let ackCopyGrp: UntypedFormGroup = this.createAppealAckCopy(not);
            (appealDetailsFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(ackCopyGrp);
          });
        }
        if (appealDetails.otherAttachmentsLoc) {
          appealDetails.otherAttachmentsLoc.forEach((not: any) => {
            let otherAttachFrmGrp: UntypedFormGroup = this.createAppealOtherAttach(not);
            (appealDetailsFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).push(otherAttachFrmGrp);
          });
        }
      });
    }
  }
  setDetailOfAppealList() {
    this.scForm.value.appealDetails.forEach((data: any, i: number) => {
      if (!data.ackCopyLoc) {
        data.ackCopyLoc = this.appealDetailItemList[i]?.ackCopyLoc;
      }
      if (!data.otherAttachmentsLoc) {
        data.otherAttachmentsLoc =
          this.appealDetailItemList[i]?.otherAttachmentsLoc;
      }
    });
  }

  saveDetailOfAppealList() {
    const detailOfAppealList = this.scForm.value.appealDetails;
    if (detailOfAppealList.length > 0) {
      detailOfAppealList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.ackCopyLoc) {
          this.appealDetailItemList[i].ackCopyLoc = item.ackCopyLoc;
        }
        if (item.otherAttachmentsLoc) {
          this.appealDetailItemList[i].otherAttachmentsLoc =
            item.otherAttachmentsLoc;
        }
      });
    }
    this.scForm.controls.appealDetails.patchValue(detailOfAppealList);
  }

  // updateDetailsOdAppeal(){
  //   (this.scForm.get('appealDetails') as FormArray).controls.forEach((c) =>{
  //     c.patchValue({ackCopyLoc:c.value.ackCopyLoc});
  //     c.patchValue({otherAttachmentsLoc:c.value.otherAttachmentsLoc});
  //   })
  // }
  uploadCopyOfAckLoc(i: any) {
    var appealDetailsArray = this.scForm.controls.appealDetails as UntypedFormArray;
    if (appealDetailsArray.controls.length > 0) {
      for (var index = 0; index < appealDetailsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = appealDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          appealDetailControl.value['isCopyOfAckLocDocUploadedClicked'] = true;
        }
      }
    }
    this.copyOfAckLocDocFile = true;
    this.copyOfAckLocDocText = false;
    this.copyOfAckLocDWText = true;
    this.copyOfAckLocUPText = false;
  }

  uploadotherAttachments(i: any) {
    var appealDetailsArray = this.scForm.controls.appealDetails as UntypedFormArray;
    if (appealDetailsArray.controls.length > 0) {
      for (var index = 0; index < appealDetailsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = appealDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          appealDetailControl.value['isOtherAttachmentsLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isOtherAttachmentDWText = true;
    this.isOtherAttachmentUPText = false;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentFile = true;
  }
  deleteSubmissions2UploadFile(i: any) {
    let submissionArray = this.scForm.controls.appealDetails as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentsLoc.setValue(null);
          issuesControl.value['isOtherAttachmentsLocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteSubmissionsUploadFile(i: any) {
    let submissionArray = this.scForm.controls.appealDetails as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.ackCopyLoc.setValue(null);
          issuesControl.value['iscopyOfAckLocUploadedClicked'] = true;
        }
      }
    }
  }

  // uploadCopyOfAckLoc() {
  //   this.copyOfAckLocDocFile = true;
  //   this.copyOfAckLocDocText = false;
  //   this.copyOfAckLocDWText = true;
  //   this.copyOfAckLocUPText = false;
  // }
  // uploadotherAttachments() {
  //   this.isOtherAttachmentDWText = true;
  //   this.isOtherAttachmentUPText =false;
  //   this.isOtherAttachmentText = false;
  //   this.isOtherAttachmentFile = true;
  // }

  consultantInvArr(): UntypedFormGroup[] {
    return (this.scForm.get('consultantCharges') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  addConsultantInv() {
    let consutantFrmGrp = this.consultantChargesList();
    (this.scForm.get('consultantCharges') as UntypedFormArray).push(consutantFrmGrp);
    return false;
  }

  rmConsultantInv(i: number) {
    (this.scForm.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.scForm.get('consultantCharges') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const fees = this.inrService.removeCommasAndParseNumber(c.value.fees);
        feesChargedTotal += Number(fees);
      }
    );

    this.feesChargedTotal = feesChargedTotal;
  }

  deleteConsultantFile(i: any) {
    let consultantArray = this.scForm.controls.consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  uploadoConsultantAttachmentsLoc(i: any) {
    var consultantArray = this.scForm.controls.consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value[
            'isSupportingAttachmentLocDocUploadedClicked'
          ] = true;
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = true;
    this.isconsultantUPattachmentsLoc = false;
  }
  saveConsultantChargesList() {
    const appealDetailList = this.scForm.value.consultantCharges;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.consultantChargesItemList[i]) {
          this.consultantChargesItemList.push({});
        }
        if (item.otherAttachmentLoc) {
          this.consultantChargesItemList[i].otherAttachmentLoc =
            item.otherAttachmentLoc;
        }
      });
    }
    this.scForm.controls.consultantCharges.patchValue(appealDetailList);
  }
  setConsultantChargesList() {
    this.scForm.value.consultantCharges.forEach((data: any, i: number) => {
      if (!data.otherAttachmentLoc) {
        data.otherAttachmentLoc =
          this.consultantChargesItemList[i]?.otherAttachmentLoc;
      }
    });
  }

  consultantChargesList(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : null],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [data ? data.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [data ? data.contact : null]
    });
  }
  initializeConsultantList() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultantCharges: any) => {
        let consultantListFrmGrp: UntypedFormGroup =
          this.consultantChargesList(consultantCharges);
        (this.scForm.get('consultantCharges') as UntypedFormArray).push(
          consultantListFrmGrp
        );

        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let consultAttachLocFrmGrp: UntypedFormGroup = this.createConsultAttach(not);
            (consultantListFrmGrp.get('otherAttachmentLoc') as UntypedFormArray).push(consultAttachLocFrmGrp);
          });
        }
      });
    }
  }

  openRmvconsultantPop(content: any, i: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  issuingAuthChange1() {
    if (
      this.scForm.value.typeOfAuthority1 === 'CGST' &&
      (this.scForm.value.din1 === null || this.scForm.value.din1 === '')
    ) {
      this.scForm.controls['din1']?.setErrors({ required: true });
    } else {
      this.scForm.controls['din1']?.setErrors(null);
    }
  }

  issuingAuthChange3() {
    if (
      this.scForm.value.typeOfAuthority3 === 'CGST' &&
      (this.scForm.value.din3 === null || this.scForm.value.din3 === '')
    ) {
      this.scForm.controls['din3']?.setErrors({ required: true });
    } else {
      this.scForm.controls['din3']?.setErrors(null);
    }
  }

  addDetailsOfLiabilityAddmittedAndPaid() {
    let liabilityAddmittedAndPaidFrmGrp =
      this.detailsOfLiabilityAddmittedAndPaid();
    (this.scForm.get('liabilityDetails') as UntypedFormArray).push(
      liabilityAddmittedAndPaidFrmGrp
    );
  }

  detailsOfLiabilityAddmittedAndPaidArr(): UntypedFormGroup[] {
    return (this.scForm.get('liabilityDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  rmLiabilityAddmittedAndPaidInv(i: number) {
    (this.scForm.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityAddmittedAndPaidModalRef.close();
    this.liabilityAddmittedAndPaidChanged();
  }

  openLiabilityAddmittedAndPaidPop(content: any) {
    this.rmvLiabilityAddmittedAndPaidModalRef = this.modalService.open(content);
  }
  detailsOfLiabilityAddmittedAndPaid(data: any = null) {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],
      markedForPayment: [data ? data?.markedForPayment : null],
      formOfPayment: [data ? data?.formOfPayment : null],
      challanNo: [data ? data?.challanNo : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst: [data ? data?.cgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst: [data ? data?.sgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess: [data ? data?.cess : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [
        data ? data?.interest : 0,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
    });
  }
  liabilityAddmittedAndPaidChanged() {
    let igstTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;

    (this.scForm.get('liabilityDetails') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
         const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
        const interest = this.inrService.removeCommasAndParseNumber(
          c.value.interest
        );
        const penalty = this.inrService.removeCommasAndParseNumber(
          c.value.penalty
        );
        igstTotal += Number(igst);
         cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
        interestTotal += Number(interest);
        penaltyTotal += Number(penalty);
        c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + (Number(cgst)) + (Number(sgst)) + (Number(cess)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });

        const total = this.inrService.removeCommasAndParseNumber(c.value.total);
        liabilityPaidTotal += Number(total);
      }
    );

    this.liabilityAddmittedAndPaidInterestTotal = interestTotal;
    this.liabilityAddmittedAndPaidTaxTotal = igstTotal;
   this.liabilityAddmittedAndPaidCgstTotal = cgstTotal;
    this.liabilityAddmittedAndPaidSgstTotal = sgstTotal;
    this.liabilityAddmittedAndPaidCessTotal = cessTotal;
    this.liabilityAddmittedAndPaidPenaltyTotal = penaltyTotal;
    this.liabilityAddmittedAndPaidTotal = liabilityPaidTotal;
  }

  initializeDetailOfLiability() {
    if (this.caseData.liabilityDetails) {
      this.caseData.liabilityDetails.forEach((liabilityDetails: any) => {
        let liabilityAddmittedAndPaidFrmGrp =
          this.detailsOfLiabilityAddmittedAndPaid(liabilityDetails);
        (this.scForm.get('liabilityDetails') as UntypedFormArray).push(
          liabilityAddmittedAndPaidFrmGrp
        );

        if (liabilityDetails.copyOfFormLoc) {
          liabilityDetails.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createLiabilityCopyForm(not);
            (liabilityAddmittedAndPaidFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }
        if (liabilityDetails.copyOfBankChallanLoc) {
          liabilityDetails.copyOfBankChallanLoc.forEach((not: any) => {
            let banckChallanGrp: UntypedFormGroup = this.createLiabilityBankChallan(not);
            (liabilityAddmittedAndPaidFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(banckChallanGrp);
          });
        }
      });
    }
  }

  saveLiabilityDetailsList() {
    const appealDetailList = this.scForm.value.liabilityDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.liabilityAdmittedItemList[i]) {
          this.liabilityAdmittedItemList.push({});
        }
        if (item.copyOfFormLoc) {
          this.liabilityAdmittedItemList[i].copyOfFormLoc = item.copyOfFormLoc;
        }
        if (item.copyOfBankChallanLoc) {
          this.liabilityAdmittedItemList[i].copyOfBankChallanLoc =
            item.copyOfBankChallanLoc;
        }
      });
    }
    this.scForm.controls.liabilityDetails.patchValue(appealDetailList);
  }
  setLiabilityDetailsList() {
    this.scForm.value.liabilityDetails.forEach((data: any, i: number) => {
      if (!data.copyOfFormLoc) {
        data.copyOfFormLoc = this.liabilityAdmittedItemList[i]?.copyOfFormLoc;
      }
      if (!data.copyOfBankChallanLoc) {
        data.copyOfBankChallanLoc =
          this.liabilityAdmittedItemList[i]?.copyOfBankChallanLoc;
      }
    });
  }

  //  Details of Liability Admitted and Paid
  uploadcopyOfForm(i: any) {
    var liabilityDetailsArray = this.scForm.controls
      .liabilityDetails as UntypedFormArray;
    if (liabilityDetailsArray.controls.length > 0) {
      for (
        var index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isLiabilityCopyOfFormLocDocFile = true;
    this.isLiabilityCopyOfFormLocDocText = false;
    this.isLiabilityCopyOfFormLocDocDWText = true;
    this.isLiabilityCopyOfFormLocUPText = false;
  }
  deleteUploadFile(i: any) {
    let protestArray = this.scForm.controls.protestDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['copyOfFormDocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteProtestFile(i: any) {
    let protestArray = this.scForm.controls.protestDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['iscopyOfBankChallanUploadedClicked'] = true;
        }
      }
    }
  }

  uploadcopyOfBankChallan(i: any) {
    var liabilityDetailsArray = this.scForm.controls
      .liabilityDetails as UntypedFormArray;
    if (liabilityDetailsArray.controls.length > 0) {
      for (
        var index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          liabilityDetailsControl.value[
            'isCopyOfBankChallanLocDocUploadedClicked'
          ] = true;
        }
      }
    }
    this.isLiabilityCopyOfBankChallanLocFile = true;
    this.isLiabilityCopyOfBankChallanLocText = false;
    this.isLiabilityCopyOfBankChallanLocDWText = true;
    this.isLiabilityCopyOfBankChallanLocUPText = false;
  }

  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.scForm.controls.liabilityDetails as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentUploadedClicked'] = true;
        }
      }
    }
  }

  deleteliabilityFile(i: any) {
    let liabilityArray = this.scForm.controls.liabilityDetails as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }
  // LiabilityuploadCopyOfFormLoc() {
  //   this.isLiabilityCopyOfFormLocDocFile = true;
  //   this.isLiabilityCopyOfFormLocDocText = false;
  //   this.isLiabilityCopyOfFormLocDocDWText = true;
  //   this.isLiabilityCopyOfFormLocUPText = false;
  // }

  // LiabilityuploadCopyOfBankChallanLoc() {
  //   this.isLiabilityCopyOfBankChallanLocFile = true;
  //   this.isLiabilityCopyOfBankChallanLocText = false;
  //   this.isLiabilityCopyOfBankChallanLocDWText = true;
  //   this.isLiabilityCopyOfBankChallanLocUPText = false;
  // }

  openProceedWithPreferredForumAppealPop(content: any) {
    this.proceedWithPreferredForumAppeal = this.modalService.open(content);
  }

  rmProceedWithPreferredForumAppeal(removeSelectedValue: any = false) {
    if (removeSelectedValue) this.scForm.get('forumOfAppeal')?.setValue('');
    this.proceedWithPreferredForumAppeal.close();
  }

  getForumAppealValue(value: any) {
    let [findValue] = this.forumAppeal.filter((forum) => {
      return forum.key === value;
    });
    if (findValue) return findValue.value;
    return;
  }

  refreshCumulativeTable(event: any) {
    event.stopPropagation();
    let refreshValue = this.scForm.value;
    refreshValue['cumulative'] = this.caseData.cumulative;
    refreshValue['prevStage'] = this.prevStage;

    if (
      refreshValue.consultantCharges &&
      refreshValue.consultantCharges.length
    ) {
      refreshValue.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
      });
    }

    if (refreshValue.protestDetails && refreshValue.protestDetails.length) {
      refreshValue.protestDetails.forEach((protestDetails: any) => {
        protestDetails.igst = this.inrService.removeCommasAndParseNumber(protestDetails.igst)
        protestDetails.cgst = this.inrService.removeCommasAndParseNumber(protestDetails.cgst);
        protestDetails.sgst = this.inrService.removeCommasAndParseNumber(protestDetails.sgst);
        protestDetails.cess = this.inrService.removeCommasAndParseNumber(protestDetails.cess);
        protestDetails.interest = this.inrService.removeCommasAndParseNumber(protestDetails.interest)
        protestDetails.penalty = this.inrService.removeCommasAndParseNumber(protestDetails.penalty)
        protestDetails.total = this.inrService.removeCommasAndParseNumber(protestDetails.total)
        
      })
    }
    if (refreshValue.liabilityDetails && refreshValue.liabilityDetails.length) {
      refreshValue.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(liability.igst)
        liability.cgst = this.inrService.removeCommasAndParseNumber(liability.cgst);
        liability.sgst = this.inrService.removeCommasAndParseNumber(liability.sgst);
        liability.cess = this.inrService.removeCommasAndParseNumber(liability.cess);
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }

    if (refreshValue.issues && refreshValue.issues.length) {
      refreshValue.issues.forEach((issue: any) => {
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1);
        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1);
        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1);
        issue.interest1 = this.inrService.removeCommasAndParseNumber(
          issue.interest1
        );
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(
          issue.penalty1
        );
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3);
        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3);
        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3);
        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3);
        issue.interest3 = this.inrService.removeCommasAndParseNumber(
          issue.interest3
        );
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(
          issue.penalty3
        );
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3);
      });
    }

    refreshValue.igst1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.igst1Total
    );
    refreshValue.cgst1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cgst1Total
    );
    refreshValue.sgst1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.sgst1Total
    );
    refreshValue.cess1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cess1Total
    );
    refreshValue.interest1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.interest1Total
    );
    refreshValue.penalty1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.penalty1Total
    );
    refreshValue.totOfTotal1 = this.inrService.removeCommasAndParseNumber(
      refreshValue.totOfTotal1
    );
    refreshValue.igst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.igst3Total
    );
    refreshValue.cgst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cgst3Total
    );
    refreshValue.sgst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.sgst3Total
    );
    refreshValue.cess3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cess3Total
    );
    refreshValue.interest3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.interest3Total
    );
    refreshValue.penalty3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.penalty3Total
    );
    refreshValue.totOfTotal3 = this.inrService.removeCommasAndParseNumber(
      refreshValue.totOfTotal3
    );
    this.litService.getCumulativeCount(this.tabType[5].key, refreshValue).subscribe((response: any) => {
      console.log(response.response);
      this.formatTableValues();
      this.caseData.cumulative = response.response;
      this.cumulativeCount = response.response;
    });
  }
  handlePaste(event: any) {
    this.inrService.handlePaste(event);
  }
  selectElement:any
  getCurrentTime(){
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    // Round minutes to nearest half-hour
    const roundedMinute = Math.round(currentMinute / 30) * 30;

    // Adjust current hour for AM/PM format
    const timeFormat = currentHour >= 12 ? 'PM' : 'AM';
    const hour12 = (currentHour % 12) || 12; // Convert hour to 12-hour format

    // Construct current selected time
    const currentSelectedTime = `${hour12.toString().padStart(2, '0')}:${roundedMinute.toString().padStart(2, '0')} ${timeFormat}`;
    var itemListArray = this.scForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phTime.setValue(currentSelectedTime);

      }
    } 
   }
  getperiodToValue(content: any){
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "GST",
    model.gstin = this.selectedGstin,
    model.criterias= [
    {
        "p": "periodFromDate",
        "o": "between",
        "v1": this.scForm.value.periodFromDate,
        "v2":  this.scForm.value.periodToDate,
    },{
        "p": "periodToDate",
        "o": "between",
        "v1": this.scForm.value.periodFromDate,
        "v2": this.scForm.value.periodToDate,
    }
]
this.litService.getDuplicateData(model).subscribe((response: any) => {
  console.log(response.response.length,"response.length");
    if (response.response.length === 0) {
      this.modalService.dismissAll();
    }
    else{
      this.duplicateData = response?.response;
         this.duplicateData?.forEach((obj: any, index: number) => {
        if (obj.forum === 'Appellate Authority')
          this.duplicateData[index].forum = 'Commissioner (A)'
      })
     this.modalService.open(content)
    }
    
  });
}
  }
  selectedIssues:any
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.scForm.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = "7437",
    model.module = "GST",
    model.role = "issueRef",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.scForm.value.issue");
  this.litService.getIssueRefData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.status === 1) {
        this.issueData = response?.response;
        if(this.issueData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
      }      
    });
  }
  exportAsXLSX() {
    if (this.issueData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueData.map((source: any) => ({
      'PAN Name': source.panName,
      'GSTIN': source.gstin,
      'GSTIN Name': source.gstinName,
      'Division': source.gstinDiv,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'Period From': source.periodFromDate,
      'Period to': source.periodToDate,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.STATE: {
        if (!this.sorting.state) {
          this.sorting.state = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'asc'
          );
        } else {
          this.sorting.state = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERIOD_FROM: {
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'asc'
          );
        } else {
          this.sorting.periodFromDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERIOD_TO: {
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'asc'
          );
        } else {
          this.sorting.periodToDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible', 'desc' );
        }
        break;
      }
      case SORT_ISSUE.GSTIN: {
        if (!this.sorting.gstin) {
          this.sorting.gstin = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'desc'
          );
        } else {
          this.sorting.gstin = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.DIVISION: {
        if (!this.sorting.gstinDiv) {
          this.sorting.gstinDiv = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'asc'
          );
        } else {
          this.sorting.gstinDiv = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'desc'   
          );
        }
        break;
      }
    }


  }

goToSummary(){
  this.router.navigate(['/litigationSummary']);
  this.modalService.dismissAll();
}
getCompanyTags(gstin:any) {
  const regNo = gstin;
  this.masterService.getEntityTagsCase(regNo).subscribe((response: any) => {
    if (response.status === 1) {
      const allTags = response.response.data;
      
      // Filter tags based on the selected company ID
      this.tagDataList = allTags
        .filter((tagItem: any) => tagItem.regNo === regNo)
        .flatMap((tagItem: any) =>
          tagItem.tags
            .filter((tag: any) => tag.active) // Filter tags with active: true
            .map((tag: any) => tag.tag)
        ); 

    } else {        
      this.toaster.showSuccess(response.message);
    }
  });
}

openIntCalPop(content: any) {
  this.modalService.open(content, { windowClass: 'preanalytics-pop' });
  this.initIntCalPopData();
}
closeIntCalPop(){
  this.initializeData();
  this.modalService.dismissAll();
}

initIntCalPopData() {
  let model = {
         type: this.tabType[5].key,
         phase: this.selectedTab == 'tab1'? 'PHASE-1' : 'PHASE-3',
         caseId : this.caseId,
         companyId: localStorage.getItem('selectedId'),
       };
     this.litService.getIntCalPopData(model).subscribe((response: any) => {
     if (response.status === 1) {
        this.intCalPopData = response.response.issueDetails;
        if(this.intCalPopData == null) return;
        const keys = Object.keys(this.intCalPopData);
        this.intCalIssueData = [];
        keys.forEach((key: any) => {
          const valuesArray  = this.intCalPopData[key];    
          valuesArray.forEach((values: any) => {
            this.intCalIssueData.push({
              issue: values.issue,
              tag: values.tag,
              periodFromDate: values.periodFromDate,
              periodToDate: values.periodToDate,
              interestRate: values.interestRate,
              totalTax: values.totalTax,
              refId: values.refId,
              taxValueAdjusted: values.taxValueAdjusted,   
              paymentDetails: values.paymentDetails, 
              expanded: false,              
            });
          });        
          
        })
     }       
     });
 }
 saveIntCalData(){
  const payload = {
    type: this.tabType[5].key,
    phase: this.selectedTab === "tab1" ? "PHASE-1" : "PHASE-3",
    caseId: this.caseId,
    companyId: localStorage.getItem('selectedId'),
    issues: [],
    protestDetails: [] as PaymentDetails[],
    predepositDetails: [] as PaymentDetails[],
    admittedLiabDetails: [] as PaymentDetails[]
};

payload.issues = this.intCalIssueData.map((issue:any) => ({
  issue: issue.issue,
  refId: issue.refId || null,
  interestRate: issue.interestRate,
  periodFromDate: issue.periodFromDate, 
  periodToDate: issue.periodToDate, 
}));
this.intCalIssueData.forEach((issue:any) => {
  issue.paymentDetails.forEach((payment:any) => {
    const baseDetails = {
      issueName: issue.issue,
      refId: issue.refId,
      // refId: payment.refId,
      tag: payment.tag,
      stage: payment.stage,
      challanNo:payment.challanNo,
      markedForPayment: payment.markedForPayment
    };
    
    if (payment.type === 'PROTEST_DETAILS') {
      payload.protestDetails.push(baseDetails);
    } else if (payment.type === 'PREDEPOSIT_DETAILS') {
      payload.predepositDetails.push(baseDetails);
    } else if (payment.type === 'ADMITTED_LIABILITY') {
      payload.admittedLiabDetails.push(baseDetails);
    }
  });
});

  this.litService.saveIntCalPopData(payload).subscribe((response: any) => {
    if (response.status === 1) {
      this.toaster.showSuccess(response.message);
      this.initIntCalPopData();
    }else{
      this.toaster.showError(response.message);
    }
  });
 }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateCommissionorRequest, ItatRequest, SupremeCourtRequest } from 'src/app/models/direct-tax.interface';
import { ACTION_INTIMATION_ORDER3, ACTION_SC_ORDER, ADJ_DEPTPRE, APPLSTATUS_LIST, AUTHORITY_LIST, CASE_MOVED, CASE_MOVED_HC, CASE_MOVED_SC, DT_ISSUE_DROPDOWN, DT_ISSUE_DROPDOWN_PENALTY, FORUM_HC_PROCEED, FORUM_SC_PROCEED, GRANTED, INTIMATION_OUTCOME, ISSUE_CONCLUSION_DT, ISSUE_CONCLUSIONS_DT, ISSUE_RISK_PARAM, ISSUING_AUTHORITY, ITR_Form_Type, MATTER_TYPE_DT, MODE_OF_PH, MODE_OF_RECEIPT, MODE_OF_TIME_PH, MODE_TYPE, ORDER_PASSED_BY, QUARTER, REDUCED1, RETURN_FILED, SUB_MODE, TYPE_OF_MATTER, TYPE_OF_NOTICE, TYPE_OF_ODRDER, TYPE_OF_REASON, YES_NO } from 'src/app/shared/constant';
import { TaxServiceService } from '../service/tax-service.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';
import { forkJoin } from 'rxjs';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  PAN = 'pan',
  CASE_LABEL = 'Case Label',
  AY = 'ay',
  PERSON_RESPONSIBLE = 'Person Responsible',
}

interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
interface tagData {
  tags: string;
}
@Component({
  selector: 'app-dt-sc',
  templateUrl: './dt-sc.component.html',
  styleUrls: ['./dt-sc.component.css']
})
export class DtScComponent implements OnInit {

  @Input() activeStages: string[] | null = null;
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  selectedHearingIndex: any;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();

  @ViewChild('atjAoTab') atjAoTab!: ElementRef;
  @ViewChild('itrTab') itrTab!: ElementRef;
  @ViewChild('preDepositTab', { static: false }) preDepositTab!: ElementRef;
  @ViewChild('saveTab', { static: false }) saveTab!: ElementRef;
  @ViewChild('submision', { static: false }) submision!: ElementRef;
  @ViewChild('nextBtn') nextBtn!: ElementRef;

  finyearData: any[] = [];
  generalInfo !: UntypedFormGroup
  scForm !: UntypedFormGroup;
  iTRDetailsForm !: UntypedFormGroup;
  preDepositForm!: UntypedFormGroup;
  submisionForm!: UntypedFormGroup;
  phase2Form!: UntypedFormGroup;
  remandBackForm!: UntypedFormGroup;
  phase3Form!: UntypedFormGroup;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdownCC = true;
  isToggleSegmentDropdown = true;
  isToggleSegmentDropdown2 = true;
  savedCaseId: any;
  fileUrl: any;
  cumulativeData: any;
  selectedField: any;
  selectedFile: any;
  matches: any;
  maxDate = new Date();
  currentYear: any;
  companyId: any;
  selectedCompanyId: any;
  selectedPanName: any;
  selectedPan: any;
  navContext: any
  uploadData: any;
  activeTab: any;
  getAccessTabData: any;
  parentCaseId: any;
  dataSource: any;
  dataSourceIssue: any;
  caseData: any;
  action: any;
  caseId: any;
  caseType: any;
  isCaseID: any;
  caseStatus: any;
  historyData: string[] = [];
  penaltyData: boolean = false;
  savedPreCaseId: any;
  prevCaseId: string | null = null;
  selectedValue: any;
  forumAppeal: any;
  duplicateData:any;
  sidemenu = false;
  detailsOfIssuingAuthority = false;
  showUpperBenchHearing: boolean = false;
  prevStage: any
  roleName: any;
  issueRefData:any;
  currentTab = 'dtsc';

  formData: FormData = new FormData();
  rmvorderCopyRef: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  rmvSubmissionModalRef!: NgbModalRef;
  rmvIssuingAuthorityModalRef!: NgbModalRef;
  rmvItrDetailsModalRef!: NgbModalRef;
  rmvStayModalRef!: NgbModalRef;
  rmvPredepositDetailsModalRef!: NgbModalRef;
  rmvapplBookByDpRef!: NgbModalRef;
  rmvChallanFormLocModalRef!: NgbModalRef;
  rmvNoticeRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;
  scRequest: SupremeCourtRequest = new SupremeCourtRequest();



  predepositInterestTotal = 0;
  predepositTaxTotal = 0;
  predepositTotal = 0;

  refundPaidInterestTotal = 0;
  refundPaidTaxTotal = 0;
  refundPaidTotal = 0;

  preLitPH1Preceded = ISSUING_AUTHORITY;
  typeOfOrder = TYPE_OF_NOTICE;
  itrDetailsOptions = ADJ_DEPTPRE;
  grantedOptions = GRANTED;
  Order = TYPE_OF_ODRDER;
  riskParam = ISSUE_RISK_PARAM;
  issueConclusion = ISSUE_CONCLUSIONS_DT;
  typeOfMatter = TYPE_OF_MATTER;
  OrReduced1 = REDUCED1;
  returnFiledUs = RETURN_FILED;
  itrFormList = ITR_Form_Type;
  authorityList = AUTHORITY_LIST;
  applStatusList = APPLSTATUS_LIST
  quarterList = QUARTER;
  yesNoList = YES_NO;
  orderReceiptMode = MODE_OF_RECEIPT;
  orderPassedBy = ORDER_PASSED_BY;
  modeOfAppealOptions = MODE_OF_RECEIPT;
  typeOfmode = SUB_MODE;
  typeOfReason = TYPE_OF_REASON;
  caseMovedFrom = CASE_MOVED_SC;
  outcomeData = INTIMATION_OUTCOME;
  matterType = MATTER_TYPE_DT;
  action3Data = ACTION_INTIMATION_ORDER3;
  mode = MODE_TYPE;
  actionAppealData = ACTION_SC_ORDER;
  sorting: {
    caseId: boolean;
    panName: boolean;
    caseLabel: boolean;
    ay: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      panName: false,
      caseLabel: false,
      ay: false,
      personResponsible: false,
    };
    issueSorting = SORT_ISSUE;

  modeOfPH = MODE_OF_PH;
  modeOfTime =MODE_OF_TIME_PH;



  isSubmitted: boolean = false;
  isSubmittedItr: boolean = false;
  isNextBtnClicked: boolean = false;
  showTable: boolean = false;
  updateBtn: boolean = false;
  isCreateBtn: boolean = false;

  tab2Disable: boolean = false;
  tab3Disable: boolean = false;
  tab4Disable: boolean = false;
  tab5Disable: boolean = false;
  tab6Disable: boolean = false;

  isTab2: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isVisibleCaseId: boolean = false;
  srAutoOrderNo: number = 1;
  isPhase2Visible: boolean = false;
  isDisabledSelect: boolean = false;
  viewIons = false;
  hideData:boolean = false;

  isUPorderLoc: boolean = true;
  isDWorderLoc: boolean = true;
  isorderLocFile: boolean = false;
  isorderLocText: boolean = true;

  isSupportingDocDWText: boolean = true;
  isSupportingDocUPText: boolean = true;
  isSupportingDocFile: boolean = false;
  isSupportingDocText: boolean = true;

  isOrderCopyDWText: boolean = true;
  isOrderCopyUPText: boolean = true;
  isOrderCopyFile: boolean = false;
  isOrderCopyText: boolean = true;
  isToggleDropdown=true;

  isRemandBackTabVisible: boolean = false;

  applFormLength: boolean = false;
  applGroundLocLength: boolean = false;
  stmtFactLength: boolean = false;
  applChallanFeesLength: boolean = false;
  isSubmmissionSubmitted: boolean = false;
  isHearingVisible: boolean = false;
  isNoticeNextSubmmited: boolean = false;
  isAddNewSubSubmitted: boolean = false;
  subAckCopyLength: boolean = false;
  subCopyLength: boolean = false;
  selectedSmIndex: any;
  selectedNoticeIndex: any;
  selectedOrderIndex: any;

  selectedUpperHearingIndex: any;
  appealCaseCreated: any;
  modeOfAppeal: any = [
    { label: 'Online', value: 'online' },
    { label: 'Manual', value: 'mannual' },
  ];
  responseTyep: any = [
    { label: 'PART', value: 'PART' },
    { label: 'FULL', value: 'FULL' },
  ];
  yesNoData: any = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  upperHearingMode: any = [
    { label: 'Video', value: 'video' },
    { label: 'Manual', value: 'mannual' },
  ];
  phase2UpdatePayload: any;
  selectedPhaseTab: any;
  tagDataList: tagData[] = [] ;
  navData:any;
  @ViewChild('hearing') hearing!: ElementRef;
  @ViewChild('summon') summon!: ElementRef;
  @ViewChild('consultant') consultant!: ElementRef;
  @ViewChild('remandBack') remandBack!: ElementRef;
  @ViewChild('submission') submission!: ElementRef;
  @ViewChild('remand') remand!: ElementRef;
  @ViewChild('taxPayment') taxPayment!: ElementRef;
  @ViewChild('cumulative') cumulative!: ElementRef;
  @ViewChild('refer') refer!: ElementRef;
  @ViewChild('remandBackPhase') remandBackPhase!: ElementRef;
  @ViewChild('remandOrder') remandOrder!: ElementRef;
  consultRemoveRef!: NgbModalRef;
  removeMeetingRef!: NgbModalRef;
  remandReportMeetingRef!: NgbModalRef;
  removeOrderRef!: NgbModalRef;
  removeAppealEffRef!: NgbModalRef;
  removeAppealRef!: NgbModalRef;
  removeLibffRef!: NgbModalRef;
  removeRefundEffRef!: NgbModalRef;
  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private activeSnapShot: ActivatedRoute,
    private taxService: TaxServiceService,
    private toaster: ToasterService,
    private commonServ: CommonService,
    private excelService: ExcelService,
    private getterSetter: GetterSetterService,
    private inrService: InrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private masterService : MasterService,
    private US: UserSettingsService,
  ) {
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.caseId = this.activeSnapShot.snapshot.params.caseId
    this.activeSnapShot.snapshot.params.new == "true" ? this.caseType = 'NEW' : null;
    this.activeSnapShot.snapshot.params.penalty == "true" ? this.caseType = 'PENALTY' : null;
    this.activeSnapShot.snapshot.params.memorandum == "true" ? this.caseType = 'MEMORANDUM' : null;
    this.activeSnapShot.snapshot.params.Appealpenalty == "true" ? this.caseType = 'MEMORANDUM PENALTY CASE' : null;
    this.activeSnapShot.snapshot.params.writTax == "true" ? this.caseType = 'WRIT' : null;
    this.historyData.push(this.caseType)
  }

  ngOnInit(): void {
    this.navContext = this.commonServ.getNavContext();
    this.navData = JSON.parse(this.getterSetter.getNavContextData() || '{}');
    this.selectedPan = this.navContext.pan;
    if (this.navContext.entityType === 'Business') {
      this.selectedPanName = sessionStorage.getItem('company');
      this.getAllTagData();
    } else {
      this.selectedPanName = this.navContext.entityName;
      this.getCompanyTags();
    }
    if (this.caseType == 'NEW' || this.caseType == 'PENALTY') {
      this.selectedValue = this.typeOfReason[0].value;
    } else {
      this.selectedValue = this.typeOfReason[1].value;
    }

    this.formInit();
    this.setDate();
    this.getYears();
    this.personResponsibleData();
    //this.getCompanyTags();
    this.consultantData();
    this.issueDropdown();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
    }
    // this.initializeData();
    if (this.action === 'create') {
      this.addItrDetails();
      this.addPredepositDetails();
      this.addStay();
      this.addIssueInv();
      this.isCreateBtn = true;
      this.viewIons=false;
    } else if (this.action === 'update') {
      this.updateBtn = true;
      this.isDisabledSelect = true;
      this.viewIons=true;
      this.addIssueInv();
      this.isPhase2 = true;
      this.isPhase3 = true;

    } else if (this.action === 'view') {

      this.isPhase2 = true;
      this.isPhase3 = true;
      this.updateBtn = true;
      this.tab2Disable = true;
      this.tab3Disable = true;
      this.tab4Disable = true;
      this.viewIons=false;
      this.tab5Disable = true;
    }
    this.selectedHearingIndex = 'phase0';
    this.selectedSmIndex = 'phase0';
    this.selectedNoticeIndex = 'phase0';
    this.selectedOrderIndex = 'phase0';
    this.selectedUpperHearingIndex = 'phase0';
    sessionStorage.setItem('IsAbeyanceHc', 'false');
    sessionStorage.setItem('IsRemandBackHc', 'false');
    this.createTab();
    this.roleName = localStorage.getItem('role');
    const userId: any = sessionStorage.getItem('user');
    this.generalInfo.controls.personResp.patchValue(userId);
  }

  openconsultRemove(content: any) {
    this.consultRemoveRef = this.modalService.open(content);
  }
  openMeeting(content: any) {
    this.removeMeetingRef = this.modalService.open(content);
  }
  openRemadnReport(content: any) {
    this.remandReportMeetingRef = this.modalService.open(content);
  }
  openOrderRemove(content: any) {
    this.removeOrderRef = this.modalService.open(content);
  }
  openAppealEffectRemove(content: any) {
    this.removeAppealEffRef = this.modalService.open(content);
  }
  openLibRemove(content: any) {
    this.removeLibffRef = this.modalService.open(content);
  }
  openRefundRemove(content: any) {
    this.removeRefundEffRef = this.modalService.open(content);
  }
  getHearingDtlsControls(): UntypedFormArray {
    return this.phase2Form.get('hearingDtls') as UntypedFormArray;
  }
  hearingPhaseChanged(phase: any, i: any) {
    this.selectedHearingIndex = phase;
  }
  summonPhaseChanges(phase: any, i: any) {
    this.selectedSmIndex = phase;
  }
  noticePhaseChanged(phase: any, i: any) {
    this.selectedNoticeIndex = phase;
  }
  orderPhaseChanged(phase: any, i: any) {
    this.selectedOrderIndex = phase;
  }
  upperHearingPhaseChanged(phase: any, i: any) {
    this.selectedUpperHearingIndex = phase;
  }
  getConsultChargesControls(): UntypedFormArray {
    return this.phase2Form.get('consultChargesDtls') as UntypedFormArray;
  }
  getRemandReportControls(): UntypedFormArray {
    return this.remandBackForm.get('orderSummaryDtls') as UntypedFormArray;
  }
  formInit() {
    this.generalInfo = this.fBuild.group({
      updatedBy: [this.caseData?.updatedBy],
      updateDate: [this.caseData?.updateDate],
      caseId: [this.caseData?.caseId],
      pan: [this.caseData?.pan ? this.caseData?.pan : this.selectedPan],
      panName: [this.caseData?.panName ? this.caseData?.panName : this.selectedPanName],
      ay: [this.caseData?.ay, [Validators.required]],
      finYear: [this.caseData?.finYear, [Validators.required]],
      consultant: [this.caseData?.consultant, Validators.maxLength(100)],
      personResp: [this.caseData?.personResp, [Validators.required]],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.minLength(5), Validators.maxLength(125)]],
      caseSummary: [this.caseData?.caseSummary, [Validators.maxLength(1000)]],
      internalRemarks1: [this.caseData?.internalRemarks1, [Validators.maxLength(1000)]],
      reason: [this.caseData?.reason ? this.caseData?.reason : this.selectedValue],
      caseMovedFrom: [this.caseData?.caseMovedFrom],
    })
    this.scForm = this.fBuild.group({
      // orderUs: [this.caseData?.orderUs, [Validators.required]],
      orderNo1: [this.caseData?.orderNo1, [Validators.required]],
      orderDate1: [this.caseData?.orderDate1, [Validators.required]],
      orderReceiptDate1: [this.caseData?.orderReceiptDate1],
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      // orderPassedBy1: [this.caseData?.orderPassedBy1],
      appealDueDate: [this.caseData?.appealDueDate],

      itatNoticeReceiptDate: [this.caseData?.itatNoticeReceiptDate],
      crossObjDueDate: [this.caseData?.crossObjDueDate],
      applBookByDptLoc: new UntypedFormArray([]),

      internalDueDate: [this.caseData?.internalDueDate],
      taxImpactFTotal: [this.caseData?.taxImpactFTotal],
      interestFTotal: [this.caseData?.interestFTotal],
      penaltyFTotal: [this.caseData?.penaltyFTotal],
      taxRateFTotal: [this.caseData?.taxRateFTotal],
      totOfAddnOrDisOfExpensesF: [this.caseData?.totOfAddnOrDisOfExpensesF],
      totOfTotal: [this.caseData?.totOfTotal],
      issues: new UntypedFormArray([]),
      orderCopyLoc1: new UntypedFormArray([]),
    })

    this.iTRDetailsForm = this.fBuild.group({
      itrDetails: new UntypedFormArray([]),
    })
    this.preDepositForm = this.fBuild.group({
      predepositDetails: new UntypedFormArray([]),
      stayOrder: new UntypedFormArray([]),
    })

    this.phase2Form = this.fBuild.group({
      submmissionDtls: this.fBuild.group({
        ackNo: [null, [Validators.required, Validators.maxLength(50)]],
        subDate: [null, Validators.required],
        subMode: [null],
        respType: ['FULL'],
        ackCopyLoc: new UntypedFormArray([]),
        appealNo: [null],
        subSummary: [null],
        internalRemarks: [null],
        appealAttachments: this.fBuild.array([this.createAppelAttachments()]),
      }),
      hearingDtls: this.fBuild.array([this.createHearningDtls()]),
      summonDtls: this.fBuild.array([this.createSummonDetails()]),
      consultChargesDtls: this.fBuild.array([this.createConsultCharges()]),
      abeyaneRemandBackDtls: this.fBuild.group({
        isAbeyance: [null],
        isRemandedBack: [null],
      }),
      isCaseRefToUpperBench: [false],
      upperBenchHearingDetails: this.fBuild.array([this.createupperHearingDtls()])
    });
    this.remandBackForm = this.fBuild.group({
      authDtls: this.fBuild.group({
        authName: [null, [Validators.maxLength(100)]],
        authDesigation: [null, [Validators.maxLength(100)]],
        jurisdiction: [null, [Validators.maxLength(100)]],
      }),
      notices: this.fBuild.array([this.createNotices()]),
      orderSummaryDtls: this.fBuild.array([this.createremandBackOrderDtls()]),

    });
    this.phase3Form = this.fBuild.group({
      orderDtls: this.fBuild.array([this.createNewOrder()]),
      libDetails: this.fBuild.array([this.createPaymentDetails()]),
      refundDetails: this.fBuild.array([this.createRefundDetails()]),
      cumulativeTotals: this.fBuild.group({
        label: [null],
        tax: [null],
        interest: [null],
        penalty: [null],
        total: [null]
      })
    })

  }
  get noticesFormArray(): UntypedFormArray {
    return this.remandBackForm.get('notices') as UntypedFormArray;
  }
  tabChanged(phasetab: any, check?: any) {
    this.selectedPhaseTab = phasetab;
    if (phasetab === 'tab1') {
      this.initializeData();
    } else if (phasetab === 'tab2') {
      const phase2Form: any = this.phase2Form.controls;
      const remandBackValue = sessionStorage.getItem('IsRemandBack');
      const abeyence = sessionStorage.getItem('IsAbeyance');
      if (phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.value == true) {
        phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.patchValue(true);
      } else {
        phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.patchValue(false);
      }
      if (phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.value == true) {
        phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.patchValue(true);
      } else {
        phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.patchValue(false);
      }
      // this.initializePhase2Data();
      this.initializeData();

    } else if (phasetab === 'remadnBack') {
      const phase2Form: any = this.phase2Form.controls;
      if (phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.value == true) {
        phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.patchValue(true);
      } else {
        phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.patchValue(false);
      }
      if (phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.value == true) {
        phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.patchValue(true);
      } else {
        phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.patchValue(false);
      }
      // this.initializePhase2Data();
      this.initializeData();
    } else if (phasetab === 'tab3') {
      // this.initializePhase2Data();
      this.initializeData();
    }
  }

  createTab() {
    if (this.caseId != ' ' && this.caseId != undefined) {
      this.isCreateBtn = false;
      this.updateBtn = true;
    } else {
      this.isCreateBtn = true;

    }
  }
  openNoticeLocPop(content: any) {
    this.addOrder();
    this.modalService.open(content);
  }
  /*orderCopyLoc1 Array*/
  getFormControlOrder() {
    return ((this.scForm.get('orderCopyLoc1') as UntypedFormArray).controls as UntypedFormGroup[])
  }
  getFormControlOrderLength() {
    const length = (this.scForm.get('orderCopyLoc1') as UntypedFormArray)
      .controls as UntypedFormGroup[];
    return length.filter((element) => element.value.fileLoc !== null).length;
  }
  getOrderCopyLoc1Length() {
    let count = 0;
    let dtNoticesFrmArray = this.scForm.get('orderCopyLoc1') as UntypedFormArray;
    let noticecount = dtNoticesFrmArray.value.filter((x: any) => x.fileLoc != "" && x.fileLoc != null);
    return noticecount.length;
  }

  createOrder(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    })
  }


  addOrder() {
    let orderCopyListFrmArray = this.scForm.get('orderCopyLoc1') as UntypedFormArray;
    let i = 0;
    if (orderCopyListFrmArray.length > 0) {
      let length = orderCopyListFrmArray.length;
      while (i < 5 - length) {
        orderCopyListFrmArray.push(this.createOrder());
        i++;
      }
    }
    else {
      while (i < 5) {
        orderCopyListFrmArray.push(this.createOrder());
        i++;
      }
    }
  }

  rmorderCopyLoc1(i: number) {
    (this.scForm.get('orderCopyLoc1') as UntypedFormArray).removeAt(i);
    this.rmvorderCopyRef.close();
  }
  uploadOrderCopyFormLoc(i: any) {
    this.isUPorderLoc = false;
    this.isDWorderLoc = false;
    this.isorderLocFile = true;
    this.isorderLocText = false;
    let orderCopyArray = this.scForm.controls.orderCopyLoc1 as UntypedFormArray
    if (orderCopyArray.controls.length > 0) {
      for (let index = 0; index < orderCopyArray.controls.length; index++) {
        if (index === i) {
          let protestDetailsControl = orderCopyArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
  }
  deleteUploadFile(i: any) {
    let orderCopyArray = this.scForm.controls.orderCopyLoc1 as UntypedFormArray
    if (orderCopyArray.controls.length > 0) {
      for (let index = 0; index < orderCopyArray.controls.length; index++) {
        if (index == i) {
          let orderCopyControl = orderCopyArray.controls[index] as UntypedFormGroup;
          orderCopyControl.controls.fileLoc.setValue(null);
          orderCopyControl.value['isCopyOfFormLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvorderCopyRef = this.modalService.open(content);
  }
  /*orderCopyLoc1 Array end*/

  /*applBookByDptLoc Array*/

  openApplBookByDptPop(content: any) {
    this.addApplBook();
    this.modalService.open(content);
  }

  getFormControlApplBook() {
    return ((this.scForm.get('applBookByDptLoc') as UntypedFormArray).controls as UntypedFormGroup[])
  }
  getFormControlApplBookLength() {
    const length = (this.scForm.get('applBookByDptLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
    return length.filter((element) => element.value.fileLoc !== null).length;
  }
  getApplBookLength() {
    let count = 0;
    let dtNoticesFrmArray = this.scForm.get('applBookByDptLoc') as UntypedFormArray;
    let noticecount = dtNoticesFrmArray.value.filter((x: any) => x.fileLoc != "" && x.fileLoc != null);
    return noticecount.length;
  }

  createApplBook(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    })
  }


  addApplBook() {
    let applBookByDptListFrmArray = this.scForm.get('applBookByDptLoc') as UntypedFormArray;
    let i = 0;
    if (applBookByDptListFrmArray.length > 0) {
      let length = applBookByDptListFrmArray.length;
      while (i < 5 - length) {
        applBookByDptListFrmArray.push(this.createApplBook());
        i++;
      }
    }
    else {
      while (i < 5) {
        applBookByDptListFrmArray.push(this.createApplBook());
        i++;
      }
    }
  }

  rmApplBook(i: number) {
    (this.scForm.get('applBookByDptLoc') as UntypedFormArray).removeAt(i);
    this.rmvapplBookByDpRef.close();
  }
  uploadApplBook(i: any) {
    this.isUPorderLoc = false;
    this.isDWorderLoc = false;
    this.isorderLocFile = true;
    this.isorderLocText = false;
    let applBookByArray = this.scForm.controls.applBookByDptLoc as UntypedFormArray
    if (applBookByArray.controls.length > 0) {
      for (let index = 0; index < applBookByArray.controls.length; index++) {
        if (index === i) {
          let protestDetailsControl = applBookByArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
  }
  deleteUploadApplBook(i: any) {
    let applBookByArray = this.scForm.controls.applBookByDptLoc as UntypedFormArray
    if (applBookByArray.controls.length > 0) {
      for (let index = 0; index < applBookByArray.controls.length; index++) {
        if (index == i) {
          let orderCopyControl = applBookByArray.controls[index] as UntypedFormGroup;
          orderCopyControl.controls.fileLoc.setValue(null);
          orderCopyControl.value['isCopyOfFormLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  openRemoveApplBookPop(content: any) {
    this.rmvapplBookByDpRef = this.modalService.open(content);
  }
  /*applBookByDptLoc Array end*/
  /*Issue Array  */
  issuInvArr(): UntypedFormGroup[] {
    return (this.scForm.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  createIssueInvArray(letter: any = null): UntypedFormGroup {
    return this.fBuild.group({
      issue: [letter ? letter.issue : null, [Validators.required]],
      keyword: [letter ? letter.keyword : null, []],
      tag: [letter ? letter.tag : null, []],
      riskParam: [letter ? letter.riskParam : null, [Validators.required]],
      addnOrDisOfExpensesF: [letter ? letter.addnOrDisOfExpensesF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      taxRateF: [letter ? letter.taxRateF : null, [Validators.pattern(/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/)]],
      taxImpactF: [letter ? letter.taxImpactF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interestF: [letter ? letter.interestF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penaltyF: [letter ? letter.penaltyF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      totalF: [letter ? letter.totalF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      remarkF: [letter ? letter.remarkF : null, []],
      provAndLibDetails: new UntypedFormArray([]),
    })
  }
  addIssueInv() {
    let issueFrmGrp = this.createIssueInvArray();
    (issueFrmGrp.get('provAndLibDetails') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.scForm.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number, isRemandedBack: boolean = false) {
    (this.scForm.get('issues') as UntypedFormArray).removeAt(i);
    if (!isRemandedBack) this.rmvIssueModalRef.close();
    this.issueValueChange();
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openRemoveSub(content: any, i: any, j: any) {
    this.rmvSubmissionModalRef = this.modalService.open(content);
  }

  /*remove letter function*/
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('provAndLibDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp(detail: any = null) {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(detail ? detail.finYear : '', []),
      quarter: new UntypedFormControl(detail ? detail.quarter : '', []),
      month: new UntypedFormControl(detail ? detail.month : '', []),
      provAmtF: new UntypedFormControl(detail ? detail.provAmtF : '', []),
      contLibAmtF: new UntypedFormControl(detail ? detail.contLibAmtF : '', []),
      othLibF: new UntypedFormControl(detail ? detail.othLibF : '', []),
      provChangeReasonF: new UntypedFormControl(
        detail ? detail.provChangeReasonF : null,
        [Validators.maxLength(150)]
      ),
      provNotMadeReasonF: new UntypedFormControl(
        detail ? detail.provNotMadeReasonF : null,
        []
      ),
      contLibReasonF: new UntypedFormControl(
        detail ? detail.contLibReasonF : null,
        [Validators.maxLength(150)]
      ),
      othLibReasonF: new UntypedFormControl(detail ? detail.othLibReasonF : null, [Validators.maxLength(150)]),
      isConsInCaroF: new UntypedFormControl(detail ? detail.isConsInCaroF : '', []),
      caroAmtF: new UntypedFormControl(detail ? detail.caroAmtF : '', []),
    })
  }
  addDetail(j: any) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get('provAndLibDetails') as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('provAndLibDetails') as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();

  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  upload36Form(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let form36LocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'applFormLoc'
    ) as UntypedFormArray;

    if (form36LocArray.controls.length > 0) {
      for (let index = 0; index < form36LocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = form36LocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  delete36Form(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let form36LocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'applFormLoc'
    ) as UntypedFormArray;

    if (form36LocArray.controls.length > 0) {
      for (let index = 0; index < form36LocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = form36LocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  /*Issue end  */


  /*itrDetails array */
  getItrDetails(): UntypedFormGroup[] {
    return (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createItrDetailsFrmGrp(details: any = null): UntypedFormGroup {
    return this.fBuild.group({
      returnFiledUs: new UntypedFormControl(details ? details.returnFiledUs : '', []),
      itrForm: new UntypedFormControl(details ? details.itrForm : '', []),
      ackNo: new UntypedFormControl(details ? details.ackNo : '', []),
      filingDate: new UntypedFormControl(details ? details.filingDate : '', []),
      totTaxIncome: new UntypedFormControl(details ? details.totTaxIncome : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      totTaxLiability: new UntypedFormControl(details ? details.totTaxLiability : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      advanceTax: new UntypedFormControl(details ? details.advanceTax : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      tdsAmount: new UntypedFormControl(details ? details.tdsAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      tcsAmount: new UntypedFormControl(details ? details.tcsAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      selfAssessmentAmount: new UntypedFormControl(details ? details.selfAssessmentAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      totTaxPaid: new UntypedFormControl(details ? details.totTaxPaid : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      refundDemand: new UntypedFormControl(details ? details.refundDemand : '', [Validators.pattern(/^-?[0-9,]+$/)]),
    })
  }
  addItrDetails() {
    let itrDetailsFrmGrp = this.createItrDetailsFrmGrp();
    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).push(itrDetailsFrmGrp);
  }
  rmItrDetailsInv(i: number) {
    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).removeAt(i);
    this.rmvItrDetailsModalRef.close();

  }
  openItrDetailsPop(content: any, i: any) {
    this.rmvItrDetailsModalRef = this.modalService.open(content);
  }
  /*itrDetails end array */


  /*predepositDetails array */
  getpredepositDetails(): UntypedFormGroup[] {
    return (this.preDepositForm.get('predepositDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createPredepositDetailsFrmGrp(data: any = null) {
    return this.fBuild.group({
      cinRefNo: [data ? data?.cinRefNo : null],
      date: [data ? data?.date : null],
      bsrCode: [data ? data?.bsrCode : null],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      challanCopyLoc: new UntypedFormArray([])
    })
  }

  addPredepositDetails() {
    let predepositDetailsFrmGrp = this.createPredepositDetailsFrmGrp();
    (this.preDepositForm.get('predepositDetails') as UntypedFormArray).push(predepositDetailsFrmGrp);

  }

  rmPredepositDetailsInv(i: number) {
    (this.preDepositForm.get('predepositDetails') as UntypedFormArray).removeAt(i);
    this.rmvPredepositDetailsModalRef.close();
    this.predepositChanged();

  }

  openPredepositDetailsPop(content: any, i: any) {
    this.rmvPredepositDetailsModalRef = this.modalService.open(content);
  }
  openLibDetailsGrpPop(content: any, i: any) {
    this.addChallanDiv(i, 0);
    this.modalService.open(content);
  }
  /*predepositDetails end array */
  /*challanCopyLoc array p2*/
  getChallan(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('challanCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getChallanLength(j?: any) {
    let count = 0;
    let predepositFrmArray = this.preDepositForm.get('predepositDetails') as UntypedFormArray;
    let itrFrmArray = predepositFrmArray.controls[j]?.get('challanCopyLoc') as UntypedFormArray;
    let itrcount = itrFrmArray.value.filter((x: any) => x.fileLoc != "" && x.fileLoc != null);
    return itrcount.length;
  }
  initChallanFrmGrp(data: any = null) {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(data ? data?.fileLoc : null, []),
      remarks: new UntypedFormControl(data ? data?.remarks : null, []),
    })
  }
  addChallanDiv(j: any, i: any) {
    let predepositFrmArray = this.preDepositForm.get('predepositDetails') as UntypedFormArray;
    let itrFrmArray = predepositFrmArray.controls[j]?.get('challanCopyLoc') as UntypedFormArray;
    if (itrFrmArray.length > 0) {
      let length = itrFrmArray.length
      while (i < 5 - length) {
        itrFrmArray.push(this.initChallanFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        itrFrmArray.push(this.initChallanFrmGrp());
        i++;
      }
    }
  }
  rmChallanDiv(i: number, k: number) {
    let predepositFrmArray = this.preDepositForm.get('predepositDetails') as UntypedFormArray;
    let itrFrmArray = predepositFrmArray.controls[i].get('challanCopyLoc') as UntypedFormArray;
    itrFrmArray.removeAt(k);
    this.rmvChallanFormLocModalRef.close();
  }
  openChallanPop(content: any, i: any) {
    this.rmvChallanFormLocModalRef = this.modalService.open(content);
  }

  uploadChallan(predepositIndex: any, judicialIndex: any) {
    this.isSupportingDocFile = true;
    this.isSupportingDocText = false;
    this.isSupportingDocDWText = true;
    this.isSupportingDocUPText = false;
    let predepositFrmArray = this.preDepositForm.controls.predepositDetails as UntypedFormGroup;
    let itrFrmArray = predepositFrmArray.controls[predepositIndex].get('challanCopyLoc') as UntypedFormArray;
    if (itrFrmArray.controls.length > 0) {
      for (let index = 0; index < itrFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let libDetailstuploadControl = itrFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true
          libDetailstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteChallanCopyFile(predepositIndex: any, ChallanIndex: any) {
    let predepositFrmArray = this.preDepositForm.controls.predepositDetails as UntypedFormGroup;
    let itrFrmArray = predepositFrmArray.controls[predepositIndex].get('challanCopyLoc') as UntypedFormArray;
    if (itrFrmArray.controls.length > 0) {
      for (let index = 0; index < itrFrmArray.controls.length; index++) {
        if (index === ChallanIndex) {
          let libDetailstuploadControl = itrFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true
          libDetailstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*challanCopyLoc array */

  /*stayOrder array */
  getStay(): UntypedFormGroup[] {
    return (this.preDepositForm.get('stayOrder') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createStayFrmGrp(Oder: any = null) {
    return this.fBuild.group({
      applicationDate: [Oder ? Oder?.applicationDate : null],
      authority: [Oder ? Oder?.authority : null],
      applicationStatus: [Oder ? Oder?.applicationStatus : null],
      amount: [Oder ? Oder?.amount : null],
      validUptoDate: [Oder ? Oder?.validUptoDate : null],
      copyOfStayLoc: new UntypedFormArray([]),
    })
  }

  addStay() {
    let stayOrderFrmGrp = this.createStayFrmGrp();
    (this.preDepositForm.get('stayOrder') as UntypedFormArray).push(stayOrderFrmGrp);
  }

  rmStayInv(i: number) {
    (this.preDepositForm.get('stayOrder') as UntypedFormArray).removeAt(i);
    this.rmvStayModalRef.close();
  }

  openStayPop(content: any, i: any) {
    this.rmvStayModalRef = this.modalService.open(content);
  }

  openStayGrpPop(content: any, i: any) {
    this.addOrderCopyDiv(i, 0);
    this.modalService.open(content);
  }
  /*stayOrder end array */
  /*copyOfStayLoc array p2*/
  getOrderCopy(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('copyOfStayLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getOrderCopyLength(j?: any) {
    let count = 0;
    let stayOrderFrmArray = this.preDepositForm.get('stayOrder') as UntypedFormArray;
    let orderCopyFrmArray = stayOrderFrmArray.controls[j]?.get('copyOfStayLoc') as UntypedFormArray;
    let itrcount = orderCopyFrmArray.value.filter((x: any) => x.fileLoc != "" && x.fileLoc != null);
    return itrcount.length;
  }
  initOrderCopyFrmGrp(stayOrdData: any = null) {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(stayOrdData ? stayOrdData?.fileLoc : null, []),
      remarks: new UntypedFormControl(stayOrdData ? stayOrdData?.remarks : null, []),
    })
  }
  addOrderCopyDiv(j: any, i: any) {
    let stayOrderFrmArray = this.preDepositForm.get('stayOrder') as UntypedFormArray;
    let orderCopyFrmArray = stayOrderFrmArray.controls[j]?.get('copyOfStayLoc') as UntypedFormArray;
    if (orderCopyFrmArray.length > 0) {
      let length = orderCopyFrmArray.length
      while (i < 5 - length) {
        orderCopyFrmArray.push(this.initOrderCopyFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        orderCopyFrmArray.push(this.initOrderCopyFrmGrp());
        i++;
      }
    }
  }
  rmOrderCopyDiv(i: number, k: number) {
    let stayOrderFrmArray = this.preDepositForm.get('stayOrder') as UntypedFormArray;
    let orderCopyFrmArray = stayOrderFrmArray.controls[i].get('copyOfStayLoc') as UntypedFormArray;
    orderCopyFrmArray.removeAt(k);
    this.rmvChallanFormLocModalRef.close();
  }
  openOrderCopyPop(content: any, i: any) {
    this.rmvChallanFormLocModalRef = this.modalService.open(content);
  }

  uploadOrderCopy(stayOrderIndex: any, judicialIndex: any) {
    this.isOrderCopyFile = true;
    this.isOrderCopyText = false;
    this.isOrderCopyDWText = true;
    this.isOrderCopyUPText = false;
    let stayOrderFrmArray = this.preDepositForm.controls.stayOrder as UntypedFormGroup;
    let orderCopyFrmArray = stayOrderFrmArray.controls[stayOrderIndex].get('copyOfStayLoc') as UntypedFormArray;
    if (orderCopyFrmArray.controls.length > 0) {
      for (let index = 0; index < orderCopyFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let libDetailstuploadControl = orderCopyFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true
          libDetailstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteOrderCopyFile(stayOrderIndex: any, ChallanIndex: any) {
    let stayOrderFrmArray = this.preDepositForm.controls.stayOrder as UntypedFormGroup;
    let orderCopyFrmArray = stayOrderFrmArray.controls[stayOrderIndex].get('copyOfStayLoc') as UntypedFormArray;
    if (orderCopyFrmArray.controls.length > 0) {
      for (let index = 0; index < orderCopyFrmArray.controls.length; index++) {
        if (index === ChallanIndex) {
          let libDetailstuploadControl = orderCopyFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true
          libDetailstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*orderCopy array */

  //phase 2

  openAckCopyLocPop(content: any) {
    this.addAckCopyLoc();
    this.modalService.open(content);
  }
  openDetailModalPop(content: any, i: number) {
    this.addapplFormLocLoc(i, 0);
    this.addapplGroundLocPop(i, 0);
    this.addstmtFactsLocPop(i, 0);
    this.addapplChallanFeesLocPop(i, 0);
    this.addcondonationOfDelayLocPop(i, 0);
    this.addaddEvidLocPop(i, 0);

    this.modalService.open(content);
  }
  openHearingNotice(content: any, i: number) {
    this.addHearingNoticeLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionalSub(content: any, i: number) {
    this.addAdditionalSub(i, 0);
    this.modalService.open(content);
  }
  openphRecordIfAnyLoc(content: any, i: number) {
    this.addphRecordIfAnyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdjournment(content: any, i: number) {
    this.addphAdjournmentLetterLoc(i, 0);
    this.modalService.open(content);
  }
  openSummonNotice(content: any, i: number) {
    this.addSummonNoticeLoc(i, 0);
    this.modalService.open(content);
  }
  openSummonRecordIfAnyLoc(content: any, i: number) {
    this.adddeptSummonReceivedLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionalSummonSub(content: any, i: number) {
    this.addSummonAddSubLoc(i, 0);
    this.modalService.open(content);
  }
  openConsultModalpop(content: any, i: number) {
    this.addSupportingDocLoc(i, 0);
    this.modalService.open(content);
  }
  openNoticeCopy(content: any, i: number) {
    this.addNoticeLoc(i, 0);
    this.modalService.open(content);
  }
  openUpperPhHearingNotice(content: any, i: number) {
    this.addUpperHearingNoticeLoc(i, 0);
    this.modalService.open(content);
  }
  openUpperphRecordIfAnyLoc(content: any, i: number) {
    this.addUpperphRecordIfAnyLoc(i, 0);
    this.modalService.open(content);
  }
  addAckCopyLoc() {
    const ackCopyLocArray = this.phase2Form.get(
      'submmissionDtls.ackCopyLoc'
    ) as UntypedFormArray;
    let i = 0;
    if (ackCopyLocArray.length > 0) {
      let length = ackCopyLocArray.length;
      while (i < 5 - length) {
        ackCopyLocArray.push(this.createAckCopyLoc());
        i++;
      }
    } else {
      while (i < 5) {
        ackCopyLocArray.push(this.createAckCopyLoc());
        i++;
      }
    }
  }
  addapplFormLocLoc(j: any, i: any) {
    const appealAttachmentsArray: any = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormArray;

    let form36LocArray = appealAttachmentsArray.controls[j]?.get(
      'applFormLoc'
    ) as UntypedFormArray;
    if (form36LocArray.length > 0) {
      let length = form36LocArray.length;
      while (i < 5 - length) {
        form36LocArray.push(this.createAppealFormLoc());
        i++;
      }
    } else {
      while (i < 5) {
        form36LocArray.push(this.createAppealFormLoc());
        i++;
      }
    }
  }

  addapplGroundLocPop(j: any, i: any) {
    const appealAttachmentsArray: any = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormArray;
    let applGroundLocArray = appealAttachmentsArray.controls[j]?.get(
      'applGroundLoc'
    ) as UntypedFormArray;
    if (applGroundLocArray.length > 0) {
      let length = applGroundLocArray.length;
      while (i < 5 - length) {
        applGroundLocArray.push(this.createapplGroundLoc());
        i++;
      }
    } else {
      while (i < 5) {
        applGroundLocArray.push(this.createapplGroundLoc());
        i++;
      }
    }
  }
  addstmtFactsLocPop(j: any, i: any) {
    const appealAttachmentsArray: any = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormArray;
    let stmtFactsLocArray = appealAttachmentsArray.controls[j]?.get(
      'stmtFactsLoc'
    ) as UntypedFormArray;
    if (stmtFactsLocArray.length > 0) {
      let length = stmtFactsLocArray.length;
      while (i < 5 - length) {
        stmtFactsLocArray.push(this.createstmtFactsLoc());
        i++;
      }
    } else {
      while (i < 5) {
        stmtFactsLocArray.push(this.createstmtFactsLoc());
        i++;
      }
    }
  }
  addapplChallanFeesLocPop(j: any, i: any) {
    const appealAttachmentsArray: any = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormArray;
    let applChallanFeesLocArray = appealAttachmentsArray.controls[j]?.get(
      'applChallanFeesLoc'
    ) as UntypedFormArray;
    if (applChallanFeesLocArray.length > 0) {
      let length = applChallanFeesLocArray.length;
      while (i < 5 - length) {
        applChallanFeesLocArray.push(this.createapplChallanFeesLoc());
        i++;
      }
    } else {
      while (i < 5) {
        applChallanFeesLocArray.push(this.createapplChallanFeesLoc());
        i++;
      }
    }
  }
  addcondonationOfDelayLocPop(j: any, i: any) {
    const appealAttachmentsArray: any = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormArray;
    let condonationOfDelayLocArray = appealAttachmentsArray.controls[j]?.get(
      'condonationOfDelayLoc'
    ) as UntypedFormArray;
    if (condonationOfDelayLocArray.length > 0) {
      let length = condonationOfDelayLocArray.length;
      while (i < 5 - length) {
        condonationOfDelayLocArray.push(this.createcondonationOfDelayLoc());
        i++;
      }
    } else {
      while (i < 5) {
        condonationOfDelayLocArray.push(this.createcondonationOfDelayLoc());
        i++;
      }
    }
  }
  addaddEvidLocPop(j: any, i: any) {
    const appealAttachmentsArray: any = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormArray;
    let addEvidLocArray = appealAttachmentsArray.controls[j]?.get(
      'addEvidLoc'
    ) as UntypedFormArray;
    if (addEvidLocArray.length > 0) {
      let length = addEvidLocArray.length;
      while (i < 5 - length) {
        addEvidLocArray.push(this.createaddEvidLoc());
        i++;
      }
    } else {
      while (i < 5) {
        addEvidLocArray.push(this.createaddEvidLoc());
        i++;
      }
    }
  }
  addHearingNoticeLoc(j: any, i: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormArray;
    let phNoticeLocControl = hearingDtlsArray.controls[j]?.get(
      'phNoticeLoc'
    ) as UntypedFormArray;
    if (phNoticeLocControl.length > 0) {
      let length = phNoticeLocControl.length;
      while (i < 5 - length) {
        phNoticeLocControl.push(this.createHearingNoticeFormLoc());
        i++;
      }
    } else {
      while (i < 5) {
        phNoticeLocControl.push(this.createHearingNoticeFormLoc());
        i++;
      }
    }
  }
  addAdditionalSub(j: any, i: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormArray;
    let addSubLocControl = hearingDtlsArray.controls[j]?.get(
      'addSubLoc'
    ) as UntypedFormArray;
    if (addSubLocControl.length > 0) {
      let length = addSubLocControl.length;
      while (i < 5 - length) {
        addSubLocControl.push(this.createaddSubLocFormLoc());
        i++;
      }
    } else {
      while (i < 5) {
        addSubLocControl.push(this.createaddSubLocFormLoc());
        i++;
      }
    }
  }
  addphRecordIfAnyLoc(j: any, i: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormArray;
    let phRecordIfAnyLocControl = hearingDtlsArray.controls[j]?.get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    if (phRecordIfAnyLocControl.length > 0) {
      let length = phRecordIfAnyLocControl.length;
      while (i < 5 - length) {
        phRecordIfAnyLocControl.push(this.createphRecordIfAnyLocLoc());
        i++;
      }
    } else {
      while (i < 5) {
        phRecordIfAnyLocControl.push(this.createphRecordIfAnyLocLoc());
        i++;
      }
    }
  }
  addphAdjournmentLetterLoc(j: any, i: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormArray;
    let phAdjournmentLetterLocControl = hearingDtlsArray.controls[j]?.get(
      'phAdjournmentLetterLoc'
    ) as UntypedFormArray;
    if (phAdjournmentLetterLocControl.length > 0) {
      let length = phAdjournmentLetterLocControl.length;
      while (i < 5 - length) {
        phAdjournmentLetterLocControl.push(this.createphAdjournmentLetterLoc());
        i++;
      }
    } else {
      while (i < 5) {
        phAdjournmentLetterLocControl.push(this.createphAdjournmentLetterLoc());
        i++;
      }
    }
  }
  addSummonNoticeLoc(j: any, i: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormArray;
    let smNoticeLocControl = summonDtlsArray.controls[j]?.get(
      'summonNoticeLoc'
    ) as UntypedFormArray;
    if (smNoticeLocControl.length > 0) {
      let length = smNoticeLocControl.length;
      while (i < 5 - length) {
        smNoticeLocControl.push(this.createSmNoticeFormLoc());
        i++;
      }
    } else {
      while (i < 5) {
        smNoticeLocControl.push(this.createSmNoticeFormLoc());
        i++;
      }
    }
  }
  addSummonAddSubLoc(j: any, i: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormArray;
    let smaddSubLocControl = summonDtlsArray.controls[j]?.get(
      'addSubLoc'
    ) as UntypedFormArray;
    if (smaddSubLocControl.length > 0) {
      let length = smaddSubLocControl.length;
      while (i < 5 - length) {
        smaddSubLocControl.push(this.createSmaddSubLocFormLoc());
        i++;
      }
    } else {
      while (i < 5) {
        smaddSubLocControl.push(this.createSmaddSubLocFormLoc());
        i++;
      }
    }
  }
  adddeptSummonReceivedLoc(j: any, i: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormArray;
    let smNoticeLocControl = summonDtlsArray.controls[j]?.get(
      'deptSummonReceivedLoc'
    ) as UntypedFormArray;
    if (smNoticeLocControl.length > 0) {
      let length = smNoticeLocControl.length;
      while (i < 5 - length) {
        smNoticeLocControl.push(this.createSmRecordIfAnyLocLoc());
        i++;
      }
    } else {
      while (i < 5) {
        smNoticeLocControl.push(this.createSmRecordIfAnyLocLoc());
        i++;
      }
    }
  }
  addSupportingDocLoc(j: any, i: any) {
    const consultChargesDtlsArray: any = this.phase2Form.get(
      'consultChargesDtls'
    ) as UntypedFormArray;

    let supportingDocsLocArray = consultChargesDtlsArray.controls[j]?.get(
      'supportingDocsLoc'
    ) as UntypedFormArray;
    if (supportingDocsLocArray.length > 0) {
      let length = supportingDocsLocArray.length;
      while (i < 5 - length) {
        supportingDocsLocArray.push(this.createSupportingDocLoc());
        i++;
      }
    } else {
      while (i < 5) {
        supportingDocsLocArray.push(this.createSupportingDocLoc());
        i++;
      }
    }
  }
  addUpperHearingNoticeLoc(j: any, i: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormArray;
    let phNoticeLocControl = hearingDtlsArray.controls[j]?.get(
      'phNoticeLoc'
    ) as UntypedFormArray;
    if (phNoticeLocControl.length > 0) {
      let length = phNoticeLocControl.length;
      while (i < 5 - length) {
        phNoticeLocControl.push(this.createUpperHearingNoticeFormLoc());
        i++;
      }
    } else {
      while (i < 5) {
        phNoticeLocControl.push(this.createUpperHearingNoticeFormLoc());
        i++;
      }
    }
  }
  addUpperphRecordIfAnyLoc(j: any, i: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormArray;
    let phRecordIfAnyLocControl = hearingDtlsArray.controls[j]?.get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    if (phRecordIfAnyLocControl.length > 0) {
      let length = phRecordIfAnyLocControl.length;
      while (i < 5 - length) {
        phRecordIfAnyLocControl.push(this.createUpperphRecordIfAnyLocLoc());
        i++;
      }
    } else {
      while (i < 5) {
        phRecordIfAnyLocControl.push(this.createUpperphRecordIfAnyLocLoc());
        i++;
      }
    }
  }


  createAckCopyLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    });
  }
  createAppealFormLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null],
    });
  }
  createapplGroundLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    });
  }
  createstmtFactsLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    });
  }
  createapplChallanFeesLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    });
  }
  createcondonationOfDelayLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    });
  }
  createaddEvidLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []],
    });
  }
  createReportCopyLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createHearingNoticeFormLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createSmNoticeFormLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createSmaddSubLocFormLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }

  createSmRecordIfAnyLocLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createSupportingDocLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createUpperHearingNoticeFormLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createUpperphRecordIfAnyLocLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  getAckCopyLength() {
    const ackCopyLength = (
      this.phase2Form.get('submmissionDtls.ackCopyLoc') as UntypedFormArray
    ).controls as UntypedFormGroup[];
    return ackCopyLength.filter((element) => element.value.fileLoc !== null)
      .length;
  }
  getapplFormLocLength(i: number) {
    const applFormLocLength = (
      (
        this.phase2Form.get(`submmissionDtls.appealAttachments`) as UntypedFormArray
      ).at(i) as UntypedFormGroup
    ).get('applFormLoc') as UntypedFormArray;

    let appealLength = 0;
    if (appealLength == 0) {
      this.applFormLength = true;
    } else {
      this.applFormLength = false;
    }
    if (applFormLocLength && applFormLocLength.value && applFormLocLength.value.length > 0) {
      appealLength = applFormLocLength.value.filter(
        (element: any) => element.fileLoc !== null
      ).length;
      if (appealLength == 0) {
        this.applFormLength = true;
        //  if(this.caseType == 'MEMORANDUM'){
        //    this.applFormLength = false;
        // }
      } else {
        this.applFormLength = false;
      }
    }
    return appealLength;
    // const applFormLocLength = ((this.phase2Form.get(`submmissionDtls.appealAttachments`) as FormArray).at(i) as FormGroup).get('applFormLoc') as FormArray;
    // const appealLength = applFormLocLength.value.filter((element: any) => element.fileLoc !== null).length;
    // if (appealLength == 0) {
    //   this.applFormLength = true;
    // } else {
    //   this.applFormLength = false;
    // }
    // return appealLength;
  }
  getapplGroundLocLength(i: number): number {
    const applFormLocLength = (
      (
        this.phase2Form.get(`submmissionDtls.appealAttachments`) as UntypedFormArray
      ).at(i) as UntypedFormGroup
    ).get('applGroundLoc') as UntypedFormArray;
    let aplGroundLocength = 0;
    if (aplGroundLocength == 0) {
      this.applGroundLocLength = true;
    } else {
      this.applGroundLocLength = false;
    }
    if (applFormLocLength && applFormLocLength.value && applFormLocLength.value.length > 0) {
      aplGroundLocength = applFormLocLength.value.filter(
        (element: any) => element.fileLoc !== null
      ).length;
      if (aplGroundLocength == 0) {
        this.applGroundLocLength = true;
        if (this.caseType == 'MEMORANDUM') {
          this.applGroundLocLength = false;
        }
      } else {
        this.applGroundLocLength = false;
      }

    }
    return aplGroundLocength;
  }
  getstmtFactsLocLength(i: number): number {
    const applFormLocLength = (
      (
        this.phase2Form.get(`submmissionDtls.appealAttachments`) as UntypedFormArray
      ).at(i) as UntypedFormGroup
    ).get('stmtFactsLoc') as UntypedFormArray;
    let stmtFactLocength = 0;
    if (stmtFactLocength == 0) {
      this.stmtFactLength = true;
    } else {
      this.stmtFactLength = false;
    }
    if (applFormLocLength && applFormLocLength.value && applFormLocLength.value.length > 0) {
      stmtFactLocength = applFormLocLength.value.filter(
        (element: any) => element.fileLoc !== null
      ).length;
      if (stmtFactLocength == 0) {
        this.stmtFactLength = true;
        if (this.caseType == 'MEMORANDUM') {
          this.stmtFactLength = false;
        }
      } else {
        this.stmtFactLength = false;
      }
    }
    return stmtFactLocength;
  }
  getapplChallanFeesLocLength(i: number): number {
    const applFormLocLength = (
      (
        this.phase2Form.get(`submmissionDtls.appealAttachments`) as UntypedFormArray
      ).at(i) as UntypedFormGroup
    ).get('applChallanFeesLoc') as UntypedFormArray;
    let applChallanFeesLocength = 0;
    if (applChallanFeesLocength == 0) {
      this.applChallanFeesLength = true;
    } else {
      this.applChallanFeesLength = false;
    }
    if (applFormLocLength && applFormLocLength.value && applFormLocLength.value.length > 0) {
      applChallanFeesLocength = applFormLocLength.value.filter(
        (element: any) => element.fileLoc !== null
      ).length;
      if (applChallanFeesLocength == 0) {
        this.applChallanFeesLength = true;
        if (this.caseType == 'MEMORANDUM') {
          this.applChallanFeesLength = false;
        }
      } else {
        this.applChallanFeesLength = false;
      }
    }
    return applChallanFeesLocength;
  }
  getcondonationOfDelayLocLength(i: number): number {
    const applFormLocLength = (
      (
        this.phase2Form.get(`submmissionDtls.appealAttachments`) as UntypedFormArray
      ).at(i) as UntypedFormGroup
    ).get('condonationOfDelayLoc') as UntypedFormArray;
    let condonationOfDelayLocLength = 0;
    if (applFormLocLength && applFormLocLength.value && applFormLocLength.value.length > 0) {
      condonationOfDelayLocLength = applFormLocLength.value.filter(
        (element: any) => element.fileLoc !== null
      ).length;
    }
    return condonationOfDelayLocLength;
  }
  getaddEvidLocLength(i: number): number {
    const applFormLocLength = (
      (
        this.phase2Form.get(`submmissionDtls.appealAttachments`) as UntypedFormArray
      ).at(i) as UntypedFormGroup
    ).get('addEvidLoc') as UntypedFormArray;
    let addEvidLocLength = 0;
    if (applFormLocLength && applFormLocLength.value && applFormLocLength.value.length > 0) {
      addEvidLocLength = applFormLocLength.value.filter(
        (element: any) => element.fileLoc !== null
      ).length;
    }
    return addEvidLocLength;
  }
  getSupportingDocLocLength(i: number): number {
    const consultAttachLength = (
      (this.phase2Form.get(`consultChargesDtls`) as UntypedFormArray).at(
        i
      ) as UntypedFormGroup
    ).get('supportingDocsLoc') as UntypedFormArray;
    return consultAttachLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
  }


  getSmNoticeCopyLength(i: number) {
    const smNoticeLocLength = (
      (this.phase2Form.get(`summonDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('summonNoticeLoc') as UntypedFormArray;
    const smNoticeLoccength = smNoticeLocLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return smNoticeLoccength;
  }
  getHearingNoticeCopyLength(i: number) {
    const phNoticeLocLength = (
      (this.phase2Form.get(`hearingDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('phNoticeLoc') as UntypedFormArray;
    const phNoticeLoccength = phNoticeLocLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return phNoticeLoccength;
  }

  getSmRecordLength(i: number) {
    const smRecordIfAnyLocArrayLength = (
      (this.phase2Form.get(`summonDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('deptSummonReceivedLoc') as UntypedFormArray;
    const smRecordIfAnyLoccength = smRecordIfAnyLocArrayLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return smRecordIfAnyLoccength;
  }
  getUpperHearingNoticeCopyLength(i: number) {
    const phNoticeLocLength = (
      (this.phase2Form.get(`upperBenchHearingDetails`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('phNoticeLoc') as UntypedFormArray;
    const phNoticeLoccength = phNoticeLocLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return phNoticeLoccength;
  }
  getUpperHearingRecordLength(i: number) {
    const phRecordIfAnyLocArrayLength = (
      (this.phase2Form.get(`upperBenchHearingDetails`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('phRecordIfAnyLoc') as UntypedFormArray;
    const phRecordIfAnyLoccength = phRecordIfAnyLocArrayLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return phRecordIfAnyLoccength;
  }

  getAckCopyLoc() {
    return (this.phase2Form.get('submmissionDtls.ackCopyLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getUpperHearingPhNoticeLoc(i: number) {
    return (this.phase2Form.get(`upperBenchHearingDetails.${i}.phNoticeLoc`) as UntypedFormArray)
      .controls;
  }
  getUpperPhRecordIfAnyLoc(i: number) {
    return (
      this.phase2Form.get(`upperBenchHearingDetails.${i}.phRecordIfAnyLoc`) as UntypedFormArray
    ).controls;
  }
  getAppealAttachmentsControls(): UntypedFormArray {
    return this.phase2Form.get('submmissionDtls.appealAttachments') as UntypedFormArray;
  }
  getApplFormLocControls(i: number): AbstractControl[] {
    return (
      this.phase2Form.get(
        `submmissionDtls.appealAttachments.${i}.applFormLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getApplGroundLocControls(i: number): AbstractControl[] {
    return (
      this.phase2Form.get(
        `submmissionDtls.appealAttachments.${i}.applGroundLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getStmtFactsLocControls(i: number): AbstractControl[] {
    return (
      this.phase2Form.get(
        `submmissionDtls.appealAttachments.${i}.stmtFactsLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getApplChallanFeesLocControls(i: number): AbstractControl[] {
    return (
      this.phase2Form.get(
        `submmissionDtls.appealAttachments.${i}.applChallanFeesLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getCondonationOfDelayLocControls(i: number): AbstractControl[] {
    return (
      this.phase2Form.get(
        `submmissionDtls.appealAttachments.${i}.condonationOfDelayLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getAddEvidLocControls(i: number): AbstractControl[] {
    return (
      this.phase2Form.get(
        `submmissionDtls.appealAttachments.${i}.addEvidLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getHearingPhNoticeLoc(i: number) {
    return (this.phase2Form.get(`hearingDtls.${i}.phNoticeLoc`) as UntypedFormArray)
      .controls;
  }
  getSummonRecordIfAnyLoc(i: number) {
    return (
      this.phase2Form.get(`summonDtls.${i}.deptSummonReceivedLoc`) as UntypedFormArray
    ).controls;
  }
  uploadphRecordIfAnyLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deletephRecordIfAnyLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadSmNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormGroup;
    const element = summonDtlsArray.controls[itemListIndex].get(
      'summonNoticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteSmNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormGroup;
    const element = summonDtlsArray.controls[itemListIndex].get(
      'summonNoticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploaddeptSummonReceivedLoc(itemListIndex: any, judicialIndex: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormGroup;
    const element = summonDtlsArray.controls[itemListIndex].get(
      'deptSummonReceivedLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deletedeptSummonReceivedLoc(itemListIndex: any, judicialIndex: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormGroup;
    const element = summonDtlsArray.controls[itemListIndex].get(
      'deptSummonReceivedLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  uploadAckCopyFormLoc(i: any) {
    const ackCopyLocArray = this.phase2Form.get(
      'submmissionDtls.ackCopyLoc'
    ) as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === i) {
          let subDetailsControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          subDetailsControl.value['isAckCopyLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  deleteAckCopyFormLoc(i: any) {
    const ackCopyLocArray = this.phase2Form.get(
      'submmissionDtls.ackCopyLoc'
    ) as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === i) {
          let subDetailsControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          subDetailsControl.value['isAckCopyLocDocUploadedClicked'] = true;
          subDetailsControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadapplForm(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let form36LocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'applFormLoc'
    ) as UntypedFormArray;

    if (form36LocArray.controls.length > 0) {
      for (let index = 0; index < form36LocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = form36LocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteapplForm(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let form36LocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'applFormLoc'
    ) as UntypedFormArray;

    if (form36LocArray.controls.length > 0) {
      for (let index = 0; index < form36LocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = form36LocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadapplGroundLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let applGroundLocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'applGroundLoc'
    ) as UntypedFormArray;

    if (applGroundLocArray.controls.length > 0) {
      for (let index = 0; index < applGroundLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = applGroundLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteapplGroundLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let applGroundLocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'applGroundLoc'
    ) as UntypedFormArray;

    if (applGroundLocArray.controls.length > 0) {
      for (let index = 0; index < applGroundLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = applGroundLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  uploadstmtFactsLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let stmtFactsLocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'stmtFactsLoc'
    ) as UntypedFormArray;

    if (stmtFactsLocArray.controls.length > 0) {
      for (let index = 0; index < stmtFactsLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = stmtFactsLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deletestmtFactsLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let stmtFactsLocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'stmtFactsLoc'
    ) as UntypedFormArray;

    if (stmtFactsLocArray.controls.length > 0) {
      for (let index = 0; index < stmtFactsLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = stmtFactsLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  uploadapplChallanFeesLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let applChallanFeesLocArray = appealAttachmentsArray.controls[
      itemListIndex
    ].get('applChallanFeesLoc') as UntypedFormArray;

    if (applChallanFeesLocArray.controls.length > 0) {
      for (
        let index = 0;
        index < applChallanFeesLocArray.controls.length;
        index++
      ) {
        if (index === judicialIndex) {
          let itemListuploadControl = applChallanFeesLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteapplChallanFeesLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let applChallanFeesLocArray = appealAttachmentsArray.controls[
      itemListIndex
    ].get('applChallanFeesLoc') as UntypedFormArray;

    if (applChallanFeesLocArray.controls.length > 0) {
      for (
        let index = 0;
        index < applChallanFeesLocArray.controls.length;
        index++
      ) {
        if (index === judicialIndex) {
          let itemListuploadControl = applChallanFeesLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  uploadcondonationOfDelayLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let condonationOfDelayLocArray = appealAttachmentsArray.controls[
      itemListIndex
    ].get('condonationOfDelayLoc') as UntypedFormArray;

    if (condonationOfDelayLocArray.controls.length > 0) {
      for (
        let index = 0;
        index < condonationOfDelayLocArray.controls.length;
        index++
      ) {
        if (index === judicialIndex) {
          let itemListuploadControl = condonationOfDelayLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deletecondonationOfDelayLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let condonationOfDelayLocArray = appealAttachmentsArray.controls[
      itemListIndex
    ].get('condonationOfDelayLoc') as UntypedFormArray;

    if (condonationOfDelayLocArray.controls.length > 0) {
      for (
        let index = 0;
        index < condonationOfDelayLocArray.controls.length;
        index++
      ) {
        if (index === judicialIndex) {
          let itemListuploadControl = condonationOfDelayLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  uploadaddEvidLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let addEvidLocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'addEvidLoc'
    ) as UntypedFormArray;

    if (addEvidLocArray.controls.length > 0) {
      for (let index = 0; index < addEvidLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = addEvidLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteaddEvidLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormGroup;
    let addEvidLocArray = appealAttachmentsArray.controls[itemListIndex].get(
      'addEvidLoc'
    ) as UntypedFormArray;

    if (addEvidLocArray.controls.length > 0) {
      for (let index = 0; index < addEvidLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = addEvidLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  addNoticeLoc(j: any, i: any) {
    const noticesArray: any = this.remandBackForm.get('notices') as UntypedFormArray;
    let noticeLocControl = noticesArray.controls[j]?.get(
      'noticeLoc'
    ) as UntypedFormArray;
    if (noticeLocControl.length > 0) {
      let length = noticeLocControl.length;
      while (i < 5 - length) {
        noticeLocControl.push(this.createNoticeLoc());
        i++;
      }
    } else {
      while (i < 5) {
        noticeLocControl.push(this.createNoticeLoc());
        i++;
      }
    }
  }
  uploadNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const noticesArray: any = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesArray.controls[itemListIndex].get(
      'noticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const noticesArray: any = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesArray.controls[itemListIndex].get(
      'noticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadHearingNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phNoticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteHearingNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phNoticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadaddSubLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'addSubLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteaddSubLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'addSubLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadSummonAddSubLoc(itemListIndex: any, judicialIndex: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormGroup;
    const element = summonDtlsArray.controls[itemListIndex].get(
      'addSubLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteSummonAddSubLoc(itemListIndex: any, judicialIndex: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormGroup;
    const element = summonDtlsArray.controls[itemListIndex].get(
      'addSubLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadSupportingDocLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'consultChargesDtls'
    ) as UntypedFormGroup;
    let supportingDocsLocArray = appealAttachmentsArray.controls[
      itemListIndex
    ].get('supportingDocsLoc') as UntypedFormArray;

    if (supportingDocsLocArray.controls.length > 0) {
      for (
        let index = 0;
        index < supportingDocsLocArray.controls.length;
        index++
      ) {
        if (index === judicialIndex) {
          let itemListuploadControl = supportingDocsLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteSupportingDocLoc(itemListIndex: any, judicialIndex: any) {
    let appealAttachmentsArray = this.phase2Form.get(
      'consultChargesDtls'
    ) as UntypedFormGroup;
    let supportingDocsLocArray = appealAttachmentsArray.controls[
      itemListIndex
    ].get('supportingDocsLoc') as UntypedFormArray;

    if (supportingDocsLocArray.controls.length > 0) {
      for (
        let index = 0;
        index < supportingDocsLocArray.controls.length;
        index++
      ) {
        if (index === judicialIndex) {
          let itemListuploadControl = supportingDocsLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadUpperHearingNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phNoticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteUpperHearingNoticeLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phNoticeLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadUpperphRecordIfAnyLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteUpperphRecordIfAnyLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  uploadphAdjournmentLetterLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phAdjournmentLetterLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deletephAdjournmentLetterLoc(itemListIndex: any, judicialIndex: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormGroup;
    const element = hearingDtlsArray.controls[itemListIndex].get(
      'phAdjournmentLetterLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }


  // getNewHearing(index: any) {
  //   this.isNewHearing = true;
  //   const hearingDtlsArray: any = this.phase2Form.get(
  //     'hearingDtls'
  //   ) as FormArray;

  //   const element = hearingDtlsArray.controls[index].controls;

  //   if (element.adjournedOrNot.value == 'true') {
  //     if (element.phDiscNotes.value && element.phDiscNotes.value.length > 1000) {
  //       this.isNewHearing = true;
  //       this.selectedHearingIndex = 'phase' + index;
  //     } else {
  //       this.isNewHearing = false;
  //       const newHearingFormGroup = this.createHearningDtls();
  //       hearingDtlsArray.push(newHearingFormGroup);
  //       this.selectedHearingIndex = 'phase' + (index + 1);
  //       element.adjournedOrNot.value = true;
  //       if (index == 0) {
  //         element.adjournedOrNot.value = false;
  //       this.selectedHearingIndex = 'phase' + index;
  //       } 
  //     }


  //   } 
  // }
  removeHearing(index: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'hearingDtls'
    ) as UntypedFormArray;

    const element = hearingDtlsArray.controls[index].controls;
    if (index != 0) {
      hearingDtlsArray.removeAt(index);
      this.selectedHearingIndex = 'phase' + (index - 1);
    }
  }
  isAddNewNotice: boolean = false;
  isNewUperHearing: boolean = false;

  getNewUpperHearing(index: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormArray;

    const element = hearingDtlsArray.controls[index].controls;

    if (element.adjournedOrNot.value == 'true') {
      if (element.phDiscNotes.value && element.phDiscNotes.value.length > 1000) {
        this.isNewUperHearing = true;
        this.selectedUpperHearingIndex = 'phase' + index;
      } else {
        this.isNewUperHearing = false;
        const newHearingFormGroup = this.createupperHearingDtls();
        hearingDtlsArray.push(newHearingFormGroup);
        this.selectedUpperHearingIndex = 'phase' + (index + 1);
        element.adjournedOrNot.value = true;
        if (index == 0) {
          element.adjournedOrNot.value = false;
          this.selectedUpperHearingIndex = 'phase' + index;
        }
      }


    }

  }
  removeUpperHearing(index: any) {
    const hearingDtlsArray: any = this.phase2Form.get(
      'upperBenchHearingDetails'
    ) as UntypedFormArray;

    const element = hearingDtlsArray.controls[index].controls;
    if (index != 0) {
      hearingDtlsArray.removeAt(index);
      this.selectedUpperHearingIndex = 'phase' + (index - 1);
    }
  }

  getNewHearing(index: any) {
    const hearingDtlsArray: any = this.phase2Form.get('hearingDtls') as UntypedFormArray;
    const element = hearingDtlsArray.controls[index].controls;
    if (element.adjournedOrNot.value == 'true') {
      if (element.phDiscNotes.value) {
        if (element.phDiscNotes.value.length > 1000) {
          this.selectedHearingIndex = 'phase' + (index);
        } else {
          const newHearingFormGroup = this.createHearningDtls();
          hearingDtlsArray.push(newHearingFormGroup);
          this.selectedHearingIndex = 'phase' + (index + 1);
        }
      } else {
        const newHearingFormGroup = this.createHearningDtls();
        hearingDtlsArray.push(newHearingFormGroup);
        this.selectedHearingIndex = 'phase' + (index + 1);
      }
    }
  }

  getNewSummon(index: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormArray;
    const element = summonDtlsArray.controls[index].controls;
    if (element.adjournedOrNot.value == 'true') {
      if (element.discNotes.value) {
        if (element.discNotes.value.length > 1000) {
          this.selectedSmIndex = 'phase' + (index);
        } else {
          const newSummonFormGroup = this.createSummonDetails();
          summonDtlsArray.push(newSummonFormGroup);
          this.selectedSmIndex = 'phase' + (index + 1);
        }
      } else {
        const newSummonFormGroup = this.createSummonDetails();
        summonDtlsArray.push(newSummonFormGroup);
        this.selectedSmIndex = 'phase' + (index + 1);
      }
    }
  }
  removeSummon(index: any) {
    const summonDtlsArray: any = this.phase2Form.get('summonDtls') as UntypedFormArray;

    const element = summonDtlsArray.controls[index].controls;
    if (index != 0) {
      summonDtlsArray.removeAt(index);
      this.selectedSmIndex = 'phase' + (index - 1);
    }
  }
  getAddSubmmissionLength(i: number) {
    const addSubLocArrayLength = (
      (this.phase2Form.get(`hearingDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('addSubLoc') as UntypedFormArray;
    const addSubLoccength = addSubLocArrayLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return addSubLoccength;
  }
  getNoticeLoc(i: number) {
    return (this.remandBackForm.get(`notices.${i}.noticeLoc`) as UntypedFormArray)
      .controls;
  }
  getaddSubLoc(i: number) {
    return (this.phase2Form.get(`hearingDtls.${i}.addSubLoc`) as UntypedFormArray)
      .controls;
  }
  isNoticeValid: boolean = false;
  getNoticeLength(i: number) {
    const noticeLocLength = (
      (this.remandBackForm.get(`notices`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('noticeLoc') as UntypedFormArray;
    const phNoticeLoccength = noticeLocLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    if (phNoticeLoccength == 0) {
      this.isNoticeValid = true;
    } else {
      this.isNoticeValid = false;
    }
    return phNoticeLoccength;
  }
  getHearingRecordLength(i: number) {
    const phRecordIfAnyLocArrayLength = (
      (this.phase2Form.get(`hearingDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('phRecordIfAnyLoc') as UntypedFormArray;
    const phRecordIfAnyLoccength = phRecordIfAnyLocArrayLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return phRecordIfAnyLoccength;
  }
  getPhAdjournmentLetterLocLength(i: number) {
    const phAdjournmentLetterLocArrayLength = (
      (this.phase2Form.get(`hearingDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('phAdjournmentLetterLoc') as UntypedFormArray;
    const phAdjournmentLetterength = phAdjournmentLetterLocArrayLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return phAdjournmentLetterength;
  }
  getSmAddSubmmissionLength(i: number) {
    const addSmSubLocArrayLength = (
      (this.phase2Form.get(`summonDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('addSubLoc') as UntypedFormArray;
    const addSmSubLoccength = addSmSubLocArrayLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return addSmSubLoccength;
  }
  getphRecordIfAnyLoc(i: number) {
    return (
      this.phase2Form.get(`hearingDtls.${i}.phRecordIfAnyLoc`) as UntypedFormArray
    ).controls;
  }
  getphAdjournmentLetterLoc(i: number) {
    return (
      this.phase2Form.get(`hearingDtls.${i}.phAdjournmentLetterLoc`) as UntypedFormArray
    ).controls;
  }
  getSummonNoticeLoc(i: number) {
    return (this.phase2Form.get(`summonDtls.${i}.summonNoticeLoc`) as UntypedFormArray)
      .controls;
  }
  getSummonaddSubLoc(i: number) {
    return (this.phase2Form.get(`summonDtls.${i}.addSubLoc`) as UntypedFormArray)
      .controls;
  }
  getSupportingDocLocControls(i: number): AbstractControl[] {
    return (
      this.phase2Form.get(
        `consultChargesDtls.${i}.supportingDocsLoc`
      ) as UntypedFormArray
    ).controls;
  }
  addHearingDetails() {
    const newAttachment = this.createHearningDtls(); // Create a new appealAttachments group
    const hearingAttachArray = this.phase2Form.get('hearingDtls') as UntypedFormArray;

    hearingAttachArray.push(newAttachment);
  }
  createHearningDtls() {
    return this.fBuild.group({
      phScheduledDate: [null],
      phNoticeLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: [null],
      consultant: [null],
      addWrittenSubDate: [null],
      addSubLoc: new UntypedFormArray([]),
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phDiscNotes: [null],
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      mode: [null],
      phRefNo: [null],
      phTime: [null],
      phNoticeDate: [null],
    });
  }
  createaddSubLocFormLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createphRecordIfAnyLocLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createphAdjournmentLetterLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, [Validators.maxLength(100)]],
    });
  }
  createupperHearingDtls() {
    return this.fBuild.group({
      noticeDate: [null],
      phScheduledDate: [null],
      din: [null],
      appealNo: [null],
      adjournedOrNot: [false],
      mode: [null],
      attended: [null],
      consultant: [null],
      phNoticeLoc: new UntypedFormArray([]),
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phDiscNotes: [null]

    })
  }
  createSummonDetails() {
    return this.fBuild.group({
      summonDate: [null],
      summonNoticeLoc: new UntypedFormArray([]),
      adjournedOrNot: [],
      attended: [null],
      personWhoAttended: [null],
      addWritSubDate: [null],
      addSubLoc: new UntypedFormArray([]),
      deptSummonReceivedLoc: new UntypedFormArray([]),
      discNotes: [null],
    });
  }
  createConsultCharges() {
    return this.fBuild.group({
      firm: [null, [Validators.maxLength(100)]],
      consultant: [null, [Validators.maxLength(100)]],
      natureOfWork: [null, [Validators.maxLength(100)]],
      fees: [null],
      supportingDocsLoc: new UntypedFormArray([]),
      email: [null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [null, [Validators.pattern(/^\d+$/), Validators.maxLength(12)]],
    });
  }

  consultantDataAutofil(index: number): void {
    console.log('Index:', index);
  
    const consultChargesDtls = this.phase2Form.get('consultChargesDtls') as FormArray;
    const consultantGrp = consultChargesDtls.at(index) as FormGroup;
  
    const selectedConsultantName = consultantGrp.get('consultant')?.value;
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );
  
    if (selectedConsultant) {
      consultantGrp.patchValue({
        firm: selectedConsultant.firmName || '',  
        email: selectedConsultant.email || '',
        contact: selectedConsultant.contact || ''
      });
    } else {
      // consultantGrp.reset();
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
  
    }
  
    console.log('Data populated successfully!');
  }
  createNotices() {
    return this.fBuild.group({
      noticeRefNo: [null, Validators.required],
      noticeDate: [null, Validators.required],
      noticeReceiptDate: [null, Validators.required],
      legalDueDate: [null, Validators.required],
      internalDueDate: [null, [Validators.required]],
      noticeSummary: [null],
      internalRemarks: [null],
      noticeLoc: new UntypedFormArray([]),
      submissions: new UntypedFormArray([this.createSubmission()]),
      meetingDetails: new UntypedFormArray([this.createNoticeMeetingDetails()])
    });
  }
  createSubmission() {
    return this.fBuild.group({
      subDate: [null],
      ackNo: [null, [Validators.maxLength(50)]],
      ackCopyLoc: new UntypedFormArray([]),
      subCopyLoc: new UntypedFormArray([]),
      subSummary: [null],
      internalRemarks: [null],
    });
  }
  createNoticeMeetingDetails() {
    return this.fBuild.group({
      dateOfMeeting: [null],
      personAttended: [null, [Validators.maxLength(100)]],
      internalRemarks: [null],
      copyOfSubmissionLoc: new UntypedFormArray([])
    })
  }
  createNewOrder() {
    return this.fBuild.group({
      orderNo4: [null, Validators.required],
      orderDate4: [null, Validators.required],
      orderReceiptDate4: [null, Validators.required],
      orderReceiptMode4: [null],
      // orderPassedBy4: [null],
      orderCopyLoc4: new UntypedFormArray([]),
      outcome: [null, Validators.required],
      orderSummary: [null],
      internalRemarks4: [null],
      appealDetails: new UntypedFormArray([this.createAppealDetails()]),
      applEffOrderDetails: new UntypedFormArray([this.createEffectAppealDetails()]),
      issues: new UntypedFormArray([this.createOrderIssue()]),

    })
  }
  createPaymentDetails() {
    return this.fBuild.group({
      // srNo: [null],
      cinRefNo: [null],
      date: [null],
      bsrCode: [null],
      tax: [null],
      interest: [null],
      total: [null],
      challanCopyLoc: new UntypedFormArray([])
    })

  }
  createRefundDetails() {
    return this.fBuild.group({
      receiptDate: [null],
      mode: [null],
      tax: [null],
      interest: [null],
      total: [null]
    })
  }
  createAppealDetails() {
    return this.fBuild.group({
      action: [null],
      forum: [null]
    })
  }
  createEffectAppealDetails() {
    return this.fBuild.group({
      applDate: [null],
      applCopyLoc: new UntypedFormArray([]),
      officer: [null, [Validators.maxLength(100)]],
      designation: [null, [Validators.maxLength(100)]],
      jurisdiction: [null, [Validators.maxLength(100)]],
      orderDate: [null],
      applOrderCopyLoc: new UntypedFormArray([])
    })
  }
  createOrderIssue() {
    return this.fBuild.group({
      issue: [null, Validators.required],
      // keyword: [null],
      status: [null, Validators.required],
      matterType: [null],
      tag: [null],
      addnOrDisOfExpensesL: [null],
      taxRateL: [null],
      taxImpactL: [null],
      interestL: [null],
      penaltyL: [null],
      totalL: [null],
      remarkL: [null],
      totOfAddnOrDisOfExpensesL: [null],
      taxImpactLTotal: [null],
      interestLTotal: [null],
      penaltyLTotal: [null],
      totOfTotal: [null],

    })
  }
  addAppealAttach() {
    const newAttachment = this.createAppelAttachments(); // Create a new appealAttachments group
    const appealAttachmentsArray = this.phase2Form.get(
      'submmissionDtls.appealAttachments'
    ) as UntypedFormArray;

    appealAttachmentsArray.push(newAttachment);
  }
  removeAttach(index: number): void {
    const appealAttachmentsArray =
      this.getAppealAttachmentsControls() as UntypedFormArray;

    appealAttachmentsArray.removeAt(index);
  }
  addConusltCharges() {
    const newConsult = this.createConsultCharges();
    const consultAttachments = this.phase2Form.get(
      'consultChargesDtls'
    ) as UntypedFormArray;
    consultAttachments.push(newConsult);
  }
  removeConsultCharges(index: number): void {
    const consultAttachArray = this.getConsultChargesControls() as UntypedFormArray;

    consultAttachArray.removeAt(index);
    this.consultRemoveRef.close();
  }
  createAppelAttachments() {
    return this.fBuild.group({
      applFormLoc: new UntypedFormArray([]),
      applGroundLoc: new UntypedFormArray([]),
      stmtFactsLoc: new UntypedFormArray([]),
      applChallanFeesLoc: new UntypedFormArray([]),
      condonationOfDelayLoc: new UntypedFormArray([]),
      addEvidLoc: new UntypedFormArray([]),
    });
  }

  onFileSelectUpload(event: any, index: number, object: any) {
    this.selectedField = event.target.getAttribute('id');
    if (event.target.files[0] !== undefined) {
      if (
        this.checkExtensionFunction(event.target.files[0], [
          'csv',
          'zip',
          'pdf',
          'xlsx',
          'rar',
          'doc',
          'docx',
          'pptx',
          'xlsb',
          'png',
          'jpg',
          'jpeg',
          'msg',
        ])
      ) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUploadFile(this.selectedField, index, object);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported');
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  onUploadFile(selectedfield: any, index: number, object: any) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'NEW',
      field: selectedfield,
      contentType: this.selectedFile.type,
    };
    this.taxService
      .uploadFile(this.selectedFile, urlData)
      .subscribe((response: any) => {
        if (response.status == 1) {
          this.toaster.showSuccess('File Uploaded Successfully');
        } else {
          this.toaster.showError(response.message);
        }
        if (response != null) {
          this.getFileData(
            response.response.url,
            response.response.path,
            selectedfield,
            index,
            object
          );
        } else {
        }
      });
  }
  getFileData(url: any, path: any, selectedfield: any, index: number, object: any) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (object.form) {
        let formName;
        if (object.form == 'phase2Form') {
          formName = this.phase2Form;
        }
        if (object.form == 'remandBackForm') {
          formName = this.remandBackForm;
        }
        if (object.form == 'phase3Form') {
          formName = this.phase3Form;
        }
        if (object.first) {
          const nestedFormGroup = formName?.get(object.first) as UntypedFormGroup;
          if (nestedFormGroup) {
            if (object.second) {
              if (object.third) {
                const firstIndex = object.firstIndex != undefined ? object.firstIndex.toString() : null;
                const secondIndex = object.secondIndex != undefined ? object.secondIndex.toString() : null;
                const thirdIndex = object.thirdIndex != undefined ? object.thirdIndex.toString() : null;
                if (firstIndex != null && secondIndex != null && thirdIndex != null) {
                  const nestedFirstFormGroupArray = formName?.get(object.first) as UntypedFormArray;
                  const firstIndexFormGroup = nestedFirstFormGroupArray.at(object.firstIndex) as UntypedFormArray;
                  const nestedSeconfFormArray = firstIndexFormGroup.get(object.second) as UntypedFormArray;
                  const specificSecondFormGroup = nestedSeconfFormArray.at(object.secondIndex) as UntypedFormGroup;
                  const nestedThirdFormArray = specificSecondFormGroup.get(object.third) as UntypedFormArray;
                  const specificThirdIndexFormGroup = nestedThirdFormArray.at(object.thirdIndex) as UntypedFormGroup;
                  if (specificThirdIndexFormGroup) {
                    specificThirdIndexFormGroup.patchValue({
                      fileLoc: path,
                    });
                  }
                } else {
                  const nestedFormArray = nestedFormGroup.get(
                    object.second
                  ) as UntypedFormArray;
                  const specificFormGroup = nestedFormArray.at(
                    object.secondIndex
                  ) as UntypedFormArray;

                  const nestedThirdFormArray = specificFormGroup.get(
                    object.third
                  ) as UntypedFormArray;
                  const specificThirdFormGroup = nestedThirdFormArray.at(
                    object.thirdIndex
                  ) as UntypedFormGroup;

                  if (specificThirdFormGroup) {
                    specificThirdFormGroup.patchValue({
                      fileLoc: path,
                    });
                  }
                }
              } else {
                const first =
                  object.firstIndex != undefined
                    ? object.firstIndex.toString()
                    : null;
                const second =
                  object.secondIndex != undefined
                    ? object.secondIndex.toString()
                    : null;
                if (first != null && second != null) {
                  const nestedFormGroupArray = formName?.get(
                    object.first
                  ) as UntypedFormArray;
                  const specificFormGroup = nestedFormGroupArray.at(
                    object.firstIndex
                  ) as UntypedFormArray;

                  const nestedSeconfFormArray = specificFormGroup.get(
                    object.second
                  ) as UntypedFormArray;
                  const specificThirdFormGroup = nestedSeconfFormArray.at(
                    object.secondIndex
                  ) as UntypedFormGroup;

                  if (specificThirdFormGroup) {
                    specificThirdFormGroup.patchValue({
                      fileLoc: path,
                    });
                  }
                } else {
                  const nestedFormArray = nestedFormGroup.get(
                    object.second
                  ) as UntypedFormArray;
                  const specificFormGroup = nestedFormArray.at(
                    index
                  ) as UntypedFormGroup;

                  if (specificFormGroup) {
                    specificFormGroup.patchValue({
                      fileLoc: path,
                    });
                  }
                }
              }
            } else {
              const nestedFormArray = nestedFormGroup.get(
                object.first
              ) as UntypedFormArray;
              const specificFormGroup = nestedFormArray.at(index) as UntypedFormGroup;

              if (specificFormGroup) {
                specificFormGroup.patchValue({
                  fileLoc: path,
                });
              }
            }
          }
        }
      }
    });

    // const nestedFormGroup = this.phase2Form.get(formName) as FormGroup;

    // if (nestedFormGroup) {
    //   const nestedFormArray = nestedFormGroup.get(fieldName) as FormArray;
    //   console.log('nestedFormArray: ', nestedFormArray);
    //   const specificFormGroup = nestedFormArray.at(index) as FormGroup;

    //   if (specificFormGroup) {
    //     specificFormGroup.patchValue({
    //       fileLoc: path,
    //     });
    //     console.log('nestedFormArray: ', nestedFormArray);
    //   }
    // }
  }

  nextForRemandBack() {
    const remandBack: any = this.phase2Form.controls.abeyaneRemandBackDtls;
    if (remandBack.controls.isRemandedBack.value === 'true') {
      this.remandBackPhase.nativeElement.click();
    } else {
      this.remandOrder.nativeElement.click();
    }
  }
  nextForSubmmission() {
    this.isSubmmissionSubmitted = true;
    let dataAckCopyLoc = (this.phase2Form.get('submmissionDtls.ackCopyLoc') as UntypedFormArray).controls;
    var count = dataAckCopyLoc?.some((x) => x.get('fileLoc')?.value != null);
    if (!count) {
      this.toaster.showError('Required Fields Are Empty');
      return;
    }
    if ((this.caseType !== 'MEMORANDUM') &&
      (  !this.phase2Form.value.submmissionDtls.ackNo ||  !this.phase2Form.value.submmissionDtls.subDate ||
        this.applFormLength ||
        this.applGroundLocLength ||
        this.stmtFactLength ||
        this.applChallanFeesLength)
    ) {
      this.toaster.showError('Mandatory Fields are Required');
      this.isHearingVisible = false;
    } else if (((this.phase2Form.controls.submmissionDtls.value.subSummary) && this.phase2Form.controls.submmissionDtls.value.subSummary.length > 1000)) {
      this.isHearingVisible = false;

    } else if (((this.phase2Form.controls.submmissionDtls.value.internalRemarks) && this.phase2Form.controls.submmissionDtls.value.internalRemarks.length > 1000)) {
      this.isHearingVisible = false;

    } else {
      this.hearing.nativeElement.click();
      this.isHearingVisible = true;
    }
  }
  nextFormHearingDetails() {
    const phase2Form: any = this.phase2Form.controls.hearingDtls;
    const lastIndex: any = phase2Form.length - 1;
    const data = phase2Form.controls[lastIndex];
    if (data.controls.phDiscNotes.value) {
      if (data.controls.phDiscNotes.value.length < 1000) {
        this.summon.nativeElement.click();
      } else {

      }
    } else {
      this.summon.nativeElement.click();
    }


  }
  nextForSummonDetails() {
    const phase2Form: any = this.phase2Form.controls.summonDtls;
    if (phase2Form) { }
    const lastIndex: any = phase2Form.length - 1;
    const data = phase2Form.controls[lastIndex];
    if (data.controls.discNotes.value) {
      if (data.controls.discNotes.value.length < 1000) {
        this.refer.nativeElement.click();
      } else {

      }
    } else {
      this.refer.nativeElement.click();
    }

  }
  nextForRefer() {
    const phase2Form: any = this.phase2Form.controls.upperBenchHearingDetails;
    const lastIndex: any = phase2Form.length - 1;
    const data = phase2Form.controls[lastIndex];
    if (data.controls.phDiscNotes.value) {
      if (data.controls.phDiscNotes.value.length < 1000) {
        this.consultant.nativeElement.click();
      } else {

      }
    } else {
      this.consultant.nativeElement.click();
    }

  }

  nextForConsultDetails() {
    this.remandBack.nativeElement.click();
  }
  nextForNotice() {
    this.isNoticeNextSubmmited = true;
    const remandBackForm: any = this.remandBackForm.controls.notices;
    const lastIndex: any = remandBackForm.length - 1;
    const data = remandBackForm.controls[lastIndex];

    if (data.controls.noticeRefNo.value && data.controls.noticeDate.value && data.controls.noticeReceiptDate.value && data.controls.legalDueDate.value && !this.isNoticeValid) {
      if (data.controls.noticeSummary.value && data.controls.noticeSummary.value.length > 1000 || data.controls.internalRemarks.value && data.controls.internalRemarks.value.length > 1000) {
        this.isNoticeNextSubmmited = true;
      } else {
        this.submission.nativeElement.click();
      }
    } else {
      this.toaster.showError('Required fields are Empty');
    }
  }
  isSubFormValid: boolean = false;
  nextForRemandSubmmisionTab(index: any) {
    this.isSubFormValid = true;
    const remandBackForm: any = (this.remandBackForm.get('notices') as UntypedFormArray).at(index) as UntypedFormArray;
    const submission = remandBackForm.get('submissions').length - 1;
    const last: any = ((this.remandBackForm.get('notices') as UntypedFormArray).at(index).get('submissions') as UntypedFormArray).at(submission);

    if ((!last.value.subDate || !last.value.ackNo) || (this.subAckCopyLength || this.subCopyLength)) {
      this.toaster.showError("Required Fields are Empty");

    } else {
      if (last.value.subSummary && last.value.subSummary.length > 1000 || last.value.internalRemarks && last.value.internalRemarks.length > 1000) {
        this.isSubFormValid = true;
      } else {
        this.remand.nativeElement.click();
      }
      // this.remand.nativeElement.click();
    }
  }
  isNewOrder: boolean = false;
  isIssueSubmitted: boolean = false;

  nextForPhase3Order() {
    this.isNewOrder = true;
    const lastIndex = this.phase3Form.value.orderDtls.length - 1;
    const lastIndexArray = this.phase3Form.value.orderDtls[lastIndex];
    const lastIssueIndex = lastIndexArray.issues.length - 1;
    const lastIssue = lastIndexArray.issues[lastIssueIndex];
    if (
      lastIndexArray.orderNo4 == null ||
      lastIndexArray.orderDate4 == null ||
      lastIndexArray.orderReceiptDate4 == null ||
      lastIndexArray.outcome == null
    ) {
      this.toaster.showError('Required fields are Empty');
    } else {
      this.isNewOrder = false;
      if (
        lastIssue.issue == null ||
        lastIssue.status == null ||
        lastIndexArray.appealDetails[0].action == null
      ) {
        this.isNewOrder = true;
        this.isIssueSubmitted = true;
        this.toaster.showError('Required fields are Empty');
      } else {
        this.isIssueSubmitted = false;
        this.isNewOrder = false;
        this.taxPayment.nativeElement.click();
      }
    }
  }
  nextForCumulative() {
    this.cumulative.nativeElement.click();
  }
  isAbeyen() {
    const remandBack: any = this.phase2Form.controls.abeyaneRemandBackDtls;
    if (remandBack.controls.isAbeyance.value === 'true') {
      sessionStorage.setItem('IsAbeyanceHc', 'true');
    } else {
      sessionStorage.setItem('IsAbeyanceHc', 'false');
    }
  }
  isCaseRemandBack() {
    const remandBack: any = this.phase2Form.controls.abeyaneRemandBackDtls;
    if (remandBack.controls.isRemandedBack.value === 'true') {
      sessionStorage.setItem('IsRemandBackHc', 'true');
      this.isRemandBackTabVisible = true;
    } else {
      sessionStorage.setItem('IsRemandBackHc', 'false');
      this.isRemandBackTabVisible = false;
    }
  }
  //phase 2
  //Remand back
  getReportDocLocLength(i: number): number {
    const ReportDocLength = (
      (this.remandBackForm.get(`orderSummaryDtls`) as UntypedFormArray).at(
        i
      ) as UntypedFormGroup
    ).get('reportCopyLoc') as UntypedFormArray;
    return ReportDocLength.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
  }
  getreportCopyLocControls(i: number): AbstractControl[] {
    return (
      this.remandBackForm.get(
        `orderSummaryDtls.${i}.reportCopyLoc`
      ) as UntypedFormArray
    ).controls;
  }
  addRemandReportDocLoc(j: any, i: any) {
    const orderSummaryDtlsArray: any = this.remandBackForm.get(
      'orderSummaryDtls'
    ) as UntypedFormArray;

    let reportCopyLocArray = orderSummaryDtlsArray.controls[j]?.get(
      'reportCopyLoc'
    ) as UntypedFormArray;
    if (reportCopyLocArray.length > 0) {
      let length = reportCopyLocArray.length;
      while (i < 5 - length) {
        reportCopyLocArray.push(this.createReportCopyLoc());
        i++;
      }
    } else {
      while (i < 5) {
        reportCopyLocArray.push(this.createReportCopyLoc());
        i++;
      }
    }
  }
  isRefToUpperBench() {
    if (this.phase2Form.controls.isCaseRefToUpperBench.value === 'true') {
      this.showUpperBenchHearing = true;
      this.phase2Form.controls.isCaseRefToUpperBench.patchValue(true);
    } else {
      this.showUpperBenchHearing = false;
      this.phase2Form.controls.isCaseRefToUpperBench.patchValue(false);
    }
  }
  isAttendedUpperHearing() {
    const upperHearingDetails: any = this.phase2Form.controls.upperBenchHearingDetails;
    for (let index = 0; index < upperHearingDetails.controls.length; index++) {
      const element = upperHearingDetails.controls[index];
      if (element.controls.attended.value == "true") {
        element.controls.attended.patchValue(true);
      } else {
        element.controls.attended.patchValue(false);
      }

    }
  }
  isAttendedHearing() {
    const hearingDtls: any = this.phase2Form.controls.hearingDtls;
    for (let index = 0; index < hearingDtls.controls.length; index++) {
      const element = hearingDtls.controls[index];
      if (element.controls.attended.value == "true") {
        element.controls.attended.patchValue(true);
      } else {
        element.controls.attended.patchValue(false);
      }

    }
  }
  isAttendedSummon() {
    const summonDtls: any = this.phase2Form.controls.summonDtls;
    for (let index = 0; index < summonDtls.controls.length; index++) {
      const element = summonDtls.controls[index];
      if (element.controls.attended.value == "true") {
        element.controls.attended.patchValue(true);
      } else {
        element.controls.attended.patchValue(false);
      }

    }
  }
  submissionValidation(type: any) {
    const submissionForm = this.remandBackForm.controls.notices.get(
      'submissions'
    ) as UntypedFormGroup;
    if (type == 'submission') {
      submissionForm?.controls.subDate.setValidators([Validators.required]);
      submissionForm?.controls.ackNo.setValidators([Validators.required]);
      submissionForm?.updateValueAndValidity();
    }
  }
  getNewNotice(index: any) {
    this.isAddNewNotice = true;
    const noticeDtlsArray: any = this.remandBackForm.get(
      'notices'
    ) as UntypedFormArray;

    const element = noticeDtlsArray.controls[index].controls;

    if (element.noticeSummary.value && element.noticeSummary.value.length > 1000 || element.internalRemarks.value && element.internalRemarks.value.length > 1000) {
      this.selectedNoticeIndex = 'phase' + (index);
    } else {
      if (element.noticeRefNo.value && element.noticeDate.value && element.noticeReceiptDate.value && element.legalDueDate.value && !this.isNoticeValid) {
        const newNoticeFormGroup = this.createNotices();
        noticeDtlsArray.push(newNoticeFormGroup);
        this.selectedNoticeIndex = 'phase' + (index + 1);
        this.isAddNewNotice = false;
      } else {
        this.toaster.showError('Required fields are Empty');

      }
    }
  }
  openNoticeRemove(content: any, index: any) {
    this.rmvNoticeRef = this.modalService.open(content);
  }
  removeNotice(index: any) {
    const noticeDtlsArray: any = this.remandBackForm.get(
      'notices'
    ) as UntypedFormArray;


    const element = noticeDtlsArray.controls[index].controls;
    if (index != 0) {
      noticeDtlsArray.removeAt(index);
      this.selectedNoticeIndex = 'phase' + (index - 1);
      this.rmvNoticeRef.close();
    }
  }
  addRemandReportCharges() {
    const newReport = this.createremandBackOrderDtls();
    const reportCopyLocAttachments = this.remandBackForm.get(
      'orderSummaryDtls'
    ) as UntypedFormArray;
    reportCopyLocAttachments.push(newReport);
  }
  removeRemandReport(index: number): void {
    const reportAttachArray = this.getRemandReportControls() as UntypedFormArray;

    reportAttachArray.removeAt(index);
    this.remandReportMeetingRef.close();
  }
  addNewSubmission(index: any, j: any) {
    const noticeDtlsArray: any = this.remandBackForm.get(
      'notices'
    ) as UntypedFormArray;
    const newSubArray = noticeDtlsArray.at(index).get('submissions') as UntypedFormArray;
    const subArray = (noticeDtlsArray.controls[index].controls.submissions.controls[j].controls);

    if (!subArray.subDate.value || this.subCopyLength || this.subAckCopyLength || (subArray.subSummary.value && subArray.subSummary.value.length > 1000) || subArray.internalRemarks.value && subArray.internalRemarks.value.length > 1000) {
      this.toaster.showError("Required Fields are Empty");
      this.isAddNewSubSubmitted = true;
    } else {
      this.isAddNewSubSubmitted = false;
      const subCopyArray = this.createSubmission();
      newSubArray.push(subCopyArray);
    }
  }
  removeSubmission(index: any, j: any) {
    const noticeDtlsArray: any = this.remandBackForm.get(
      'notices'
    ) as UntypedFormArray;

    const subArray = noticeDtlsArray.at(index).get('submissions') as UntypedFormArray;
    if (j != 0) {

      subArray.removeAt(j);
      this.rmvSubmissionModalRef.close();
    }
  }
  isNewHearing: boolean = false;
  openSubmissionAckCopy(content: any, i: number, j: number) {
    this.addSubAckCopyLoc(i, j, 0);
    this.modalService.open(content);
  }
  openSubmissionCopy(content: any, i: number, j: number) {
    this.addSubCopyLoc(i, j, 0);
    this.modalService.open(content);
  }
  openMettingAttachPop(content: any, i: number, k: number) {
    this.addMeetingCopyLoc(i, k, 0);
    this.modalService.open(content);
  }
  openRemandBackReportpop(content: any, i: number) {
    this.addRemandReportDocLoc(i, 0);
    this.modalService.open(content);
  }
  addSubAckCopyLoc(i: any, j: any, k: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormArray;
    const noticesGroup = noticesFormArray.at(i) as UntypedFormGroup;

    if (noticesGroup) {
      const submissionsArray = noticesGroup.get('submissions') as UntypedFormArray;
      const submissionGroup = submissionsArray.at(j) as UntypedFormGroup;

      if (submissionGroup) {
        const ackCopyLocArray = submissionGroup.get('ackCopyLoc') as UntypedFormArray;
        if (ackCopyLocArray.length > 0) {
          let length = ackCopyLocArray.length;
          while (k < 5 - length) {
            ackCopyLocArray.push(this.createSubAckFormLoc());
            k++;
          }
        } else {
          while (k < 5) {
            ackCopyLocArray.push(this.createSubAckFormLoc());
            k++;
          }
        }
      }
    }
  }
  addSubCopyLoc(i: any, j: any, k: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormArray;
    const noticesGroup = noticesFormArray.at(i) as UntypedFormGroup;

    if (noticesGroup) {
      const submissionsArray = noticesGroup.get('submissions') as UntypedFormArray;
      const submissionGroup = submissionsArray.at(j) as UntypedFormGroup;

      if (submissionGroup) {
        const subCopyLocArray = submissionGroup.get('subCopyLoc') as UntypedFormArray;
        if (subCopyLocArray.length > 0) {
          let length = subCopyLocArray.length;
          while (k < 5 - length) {
            subCopyLocArray.push(this.createSubFormLoc());
            k++;
          }
        } else {
          while (k < 5) {
            subCopyLocArray.push(this.createSubFormLoc());
            k++;
          }
        }
      }
    }
  }
  addMeetingCopyLoc(i: any, j: any, k: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormArray;
    const noticesGroup = noticesFormArray.at(i) as UntypedFormGroup;

    if (noticesGroup) {
      const meetingsArray = noticesGroup.get('meetingDetails') as UntypedFormArray;
      const meetingnGroup = meetingsArray.at(j) as UntypedFormGroup;

      if (meetingnGroup) {
        const subCopyLocArray = meetingnGroup.get('copyOfSubmissionLoc') as UntypedFormArray;
        if (subCopyLocArray.length > 0) {
          let length = subCopyLocArray.length;
          while (k < 5 - length) {
            subCopyLocArray.push(this.createMeetingLoc());
            k++;
          }
        } else {
          while (k < 5) {
            subCopyLocArray.push(this.createMeetingLoc());
            k++;
          }
        }
      }
    }
  }
  addMeetingDetails(index: any) {
    const newMeeting = this.createNoticeMeetingDetails();
    const noticeDtlsArray: any = this.remandBackForm.get(
      'notices'
    ) as UntypedFormArray;

    const meetingDetailsArray = noticeDtlsArray.at(index).get('meetingDetails') as UntypedFormArray;
    meetingDetailsArray.push(newMeeting);
  }
  removeMeetingDetails(i: any, k: any) {
    const noticeDtlsArray: any = this.remandBackForm.get(
      'notices'
    ) as UntypedFormArray;
    const meetingDetailsArray = noticeDtlsArray.at(i).get('meetingDetails') as UntypedFormArray;

    meetingDetailsArray.removeAt(k);
    this.removeMeetingRef.close();
  }
  createremandBackOrderDtls() {
    return this.fBuild.group({
      reportDate: [null],
      reportCopyLoc: new UntypedFormArray([]),
      noticeRefNo: [null],
      reportSummary: [null],
    });
  }
  createSubAckFormLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createNoticeLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null],
    });
  }

  createSubFormLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createMeetingLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  getSubAckCopyLength(i: number, j: number) {
    const noticeArray = this.remandBackForm.get('notices') as UntypedFormArray;
    const subArray = noticeArray.at(i).get('submissions') as UntypedFormArray;
    const ackCopyArray = subArray.at(j).get('ackCopyLoc') as UntypedFormArray;
    const ackCopyArrayLength = ackCopyArray.value.filter((element: any) => element.fileLoc !== null).length;
    if (ackCopyArrayLength == 0) {
      this.subAckCopyLength = true;
    } else {
      this.subAckCopyLength = false;
    }
    return ackCopyArrayLength;
  }
  getSubCopyLength(i: number, j: number) {
    const noticeArray = this.remandBackForm.get('notices') as UntypedFormArray;
    const subArray = noticeArray.at(i).get('submissions') as UntypedFormArray;
    const subCopyArray = subArray.at(j).get('subCopyLoc') as UntypedFormArray;
    const subCopyArrayLength = subCopyArray.value.filter((element: any) => element.fileLoc !== null).length;
    if (subCopyArrayLength == 0) {
      this.subCopyLength = true;
    } else {
      this.subCopyLength = false;

    }
    return subCopyArrayLength;
  }
  getMeetingLength(i: number, k: number) {
    const noticeArray = this.remandBackForm.get('notices') as UntypedFormArray;
    const meetingArray = noticeArray.at(i).get('meetingDetails') as UntypedFormArray;
    const meetingCopyLength = meetingArray.at(k).get('copyOfSubmissionLoc') as UntypedFormArray;
    const subCopyArrayLength = meetingCopyLength.value.filter((element: any) => element.fileLoc !== null).length;

    return subCopyArrayLength;
  }
  getMeetingCopyLoc(i: number, k: number) {
    return (
      this.remandBackForm.get(
        `notices.${i}.meetingDetails.${k}.copyOfSubmissionLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getSubAckCopyLoc(i: number, j: number) {
    return (
      this.remandBackForm.get(
        `notices.${i}.submissions.${j}.ackCopyLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getSubCopyLoc(i: number, j: number) {
    return (
      this.remandBackForm.get(
        `notices.${i}.submissions.${j}.subCopyLoc`
      ) as UntypedFormArray
    ).controls;
  }
  uploadSubmissionAckCopyLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('ackCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  deleteSubmissionAckCopyLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('ackCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  uploadSubmissioCopyLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('subCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  deleteSubmissionCopyLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('subCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  uploadMeetingLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('meetingDetails') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('copyOfSubmissionLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  deleteMeetingnCopyLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.remandBackForm.get('notices') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('meetingDetails') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('copyOfSubmissionLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  uploadRemandReportDocLoc(itemListIndex: any, judicialIndex: any) {
    let orderSummaryArray = this.remandBackForm.get(
      'orderSummaryDtls'
    ) as UntypedFormGroup;
    let reportCopyLocArray = orderSummaryArray.controls[itemListIndex].get(
      'reportCopyLoc'
    ) as UntypedFormArray;

    if (reportCopyLocArray.controls.length > 0) {
      for (let index = 0; index < reportCopyLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = reportCopyLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteRemandReportDocLoc(itemListIndex: any, judicialIndex: any) {
    let orderSummaryArray = this.remandBackForm.get(
      'orderSummaryDtls'
    ) as UntypedFormGroup;
    let reportCopyLocArray = orderSummaryArray.controls[itemListIndex].get(
      'reportCopyLoc'
    ) as UntypedFormArray;

    if (reportCopyLocArray.controls.length > 0) {
      for (let index = 0; index < reportCopyLocArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = reportCopyLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  //Remand back
  //phase 3
  setForumOfAppeal() {

    this.forumAppeal = FORUM_SC_PROCEED;
    const orderDetails: any = this.phase3Form.controls.orderDtls;
    for (let index = 0; index < orderDetails.controls.length; index++) {
      const element = orderDetails.controls[index];
      const appealDetails = element.controls.appealDetails.controls;
      for (let j = 0; j < appealDetails.length; j++) {
        const element1 = appealDetails[j];
        // if (element1.controls.action.value == 'Not Applicable') {
        //   element1.controls.forum.patchValue(this.forumAppeal[0].value);
        // } else
        if (element1.controls.action.value == 'CLOSED') {
          element1.controls.forum.patchValue(this.forumAppeal[1].value);
        }

      }
    }

  }
  openOrderCopy(content: any, i: number) {
    this.addOrderNoticeLoc(i, 0);
    this.modalService.open(content);
  }
  openApplCopyModal(content: any, i: number, l: number) {
    this.addApplCopyLoc(i, l, 0);
    this.modalService.open(content);
  }
  openApplOrderCopyModal(content: any, i: number, l: number) {
    this.addApplOrderCopyLoc(i, l, 0);
    this.modalService.open(content);
  }
  openLibChallanCopy(content: any, i: number) {
    this.addChallanCopyLoc(i, 0);
    this.modalService.open(content);
  }
  addOrderNoticeLoc(j: any, i: any) {
    const orderDtls: any = this.phase3Form.get('orderDtls') as UntypedFormArray;
    let orderCopyLoc = orderDtls.controls[j]?.get(
      'orderCopyLoc4'
    ) as UntypedFormArray;
    if (orderCopyLoc.length > 0) {
      let length = orderCopyLoc.length;
      while (i < 5 - length) {
        orderCopyLoc.push(this.createOrderFormLoc());
        i++;
      }
    } else {
      while (i < 5) {
        orderCopyLoc.push(this.createOrderFormLoc());
        i++;
      }
    }
  }
  addApplCopyLoc(i: any, j: any, k: any) {
    const ordersFormArray = this.phase3Form.get('orderDtls') as UntypedFormArray;
    const noticesGroup = ordersFormArray.at(i) as UntypedFormGroup;

    if (noticesGroup) {
      const meetingsArray = noticesGroup.get('applEffOrderDetails') as UntypedFormArray;
      const meetingnGroup = meetingsArray.at(j) as UntypedFormGroup;

      if (meetingnGroup) {
        const subCopyLocArray = meetingnGroup.get('applCopyLoc') as UntypedFormArray;
        if (subCopyLocArray.length > 0) {
          let length = subCopyLocArray.length;
          while (k < 5 - length) {
            subCopyLocArray.push(this.createApplLoc());
            k++;
          }
        } else {
          while (k < 5) {
            subCopyLocArray.push(this.createApplLoc());
            k++;
          }
        }
      }
    }
  }
  addApplOrderCopyLoc(i: any, j: any, k: any) {
    const ordersFormArray = this.phase3Form.get('orderDtls') as UntypedFormArray;
    const noticesGroup = ordersFormArray.at(i) as UntypedFormGroup;

    if (noticesGroup) {
      const meetingsArray = noticesGroup.get('applEffOrderDetails') as UntypedFormArray;
      const meetingnGroup = meetingsArray.at(j) as UntypedFormGroup;

      if (meetingnGroup) {
        const subCopyLocArray = meetingnGroup.get('applOrderCopyLoc') as UntypedFormArray;
        if (subCopyLocArray.length > 0) {
          let length = subCopyLocArray.length;
          while (k < 5 - length) {
            subCopyLocArray.push(this.createApplOrderLoc());
            k++;
          }
        } else {
          while (k < 5) {
            subCopyLocArray.push(this.createApplOrderLoc());
            k++;
          }
        }
      }
    }
  }
  addChallanCopyLoc(j: any, i: any) {
    const libDtls: any = this.phase3Form.get('libDetails') as UntypedFormArray;
    let orderCopyLoc = libDtls.controls[j]?.get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    if (orderCopyLoc.length > 0) {
      let length = orderCopyLoc.length;
      while (i < 5 - length) {
        orderCopyLoc.push(this.createChallanLoc());
        i++;
      }
    } else {
      while (i < 5) {
        orderCopyLoc.push(this.createChallanLoc());
        i++;
      }
    }
  }
  addIssueDetails(index: any) {
    const issue = this.createOrderIssue();
    const issueOrder = this.phase3Form.get('orderDtls') as UntypedFormArray;

    const issueDetailsArray = issueOrder.at(index).get('issues') as UntypedFormArray;
    issueDetailsArray.push(issue);
  }
  removeIssueDetails(index: any) {
    const orderDetailsArray: any = this.phase3Form.get(
      'orderDtls'
    ) as UntypedFormArray;
    const meetingDetailsArray = orderDetailsArray.at(index).get('issues') as UntypedFormArray;

    meetingDetailsArray.removeAt(index);
    this.removeOrderRef.close();
  }
  addNewOrder(index: any) {
    this.isNewOrder = true;
    const newOrderArray = this.phase3Form.get('orderDtls') as UntypedFormArray;
    const currentIndexArray = newOrderArray.controls[index] as UntypedFormArray;
    const lastIssueIndex = currentIndexArray.value.issues.length - 1;
    const lastIssue = currentIndexArray.value.issues[lastIssueIndex];
    if (
      !currentIndexArray.value.orderNo4 ||
      !currentIndexArray.value.orderDate4 ||
      !currentIndexArray.value.orderReceiptDate4 ||
      !currentIndexArray.value.outcome
    ) {
      this.toaster.showError('Required fields are Empty');
    } else {
      if (lastIssue.issue == null) {
        this.toaster.showError('Required fields are Empty');
        this.isNewOrder = true;
        this.isIssueSubmitted = true;
      } else {
        const newOrder = this.createNewOrder();
        newOrderArray.push(newOrder);
        this.selectedOrderIndex = 'phase' + (index + 1);
        this.isNewOrder = false;
        this.isIssueSubmitted = false;
      }
    }
  }
  removeOrder(index: any) {
    const newOrderArray = this.phase3Form.get('orderDtls') as UntypedFormArray;
    if (index != 0) {
      newOrderArray.removeAt(index);
      this.selectedOrderIndex = 'phase' + (index - 1);

    }
  }
  addApplOrderDetails(index: any) {
    const applEffOrderDetails = this.createEffectAppealDetails();
    const issueOrder = this.phase3Form.get('orderDtls') as UntypedFormArray;

    const issueDetailsArray = issueOrder.at(index).get('applEffOrderDetails') as UntypedFormArray;
    issueDetailsArray.push(applEffOrderDetails);
  }
  addAppealDetails(index: any) {
    const appeal = this.createAppealDetails();
    const issueOrder = this.phase3Form.get('orderDtls') as UntypedFormArray;
    const appealDetailsArray = issueOrder
      .at(index)
      .get('appealDetails') as UntypedFormArray;
    appealDetailsArray.push(appeal);
  }
  openAppealRemove(content: any) {
    this.removeAppealRef = this.modalService.open(content);
  }
  removeAppealDetails(index: any, k: any) {
    const orderDetailsArray: any = this.phase3Form.get(
      'orderDtls'
    ) as UntypedFormArray;
    const meetingDetailsArray = orderDetailsArray
      .at(index)
      .get('appealDetails') as UntypedFormArray;

    meetingDetailsArray.removeAt(k);
    this.removeAppealRef.close();
  }
  removeApplOrderDetails(index: any,l:any) {
    const orderDetailsArray: any = this.phase3Form.get(
      'orderDtls'
    ) as UntypedFormArray;
    const meetingDetailsArray = orderDetailsArray.at(index).get('applEffOrderDetails') as UntypedFormArray;

    meetingDetailsArray.removeAt(l);
    this.removeAppealEffRef.close();
  }
  addNewLibDetails(index: any) {
    const newLibdetailsArray = this.phase3Form.get('libDetails') as UntypedFormArray;
    const newOrder = this.createPaymentDetails();
    newLibdetailsArray.push(newOrder);
  }
  removeLibDetails(index: any) {
    const newLibdetailsArray = this.phase3Form.get('libDetails') as UntypedFormArray;
    newLibdetailsArray.removeAt(index);
    this.removeLibffRef.close();
  }
  addNewRefundDetail(index: any) {
    const newLibdetailsArray = this.phase3Form.get('refundDetails') as UntypedFormArray;
    const newOrder = this.createRefundDetails();
    newLibdetailsArray.push(newOrder);
  }
  removeRefundDetail(index: any) {
    const newLibdetailsArray = this.phase3Form.get('refundDetails') as UntypedFormArray;
    newLibdetailsArray.removeAt(index);
    this.removeRefundEffRef.close();
  }
  createOrderFormLoc(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null],
    });
  }
  createApplLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createApplOrderLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  createChallanLoc() {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(),
      remarks: new UntypedFormControl(),
    });
  }
  getOrderCopyLocLength(i: number) {
    const orderDetails = (
      (this.phase3Form.get(`orderDtls`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('orderCopyLoc4') as UntypedFormArray;
    const orderLoccength = orderDetails.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return orderLoccength;
  }
  getChallanCopyLocLength(i: number) {
    const libDetails = (
      (this.phase3Form.get(`libDetails`) as UntypedFormArray).at(i) as UntypedFormGroup
    ).get('challanCopyLoc') as UntypedFormArray;
    const challanLoccength = libDetails.value.filter(
      (element: any) => element.fileLoc !== null
    ).length;
    return challanLoccength;
  }
  getapplicationCopyLength(i: number, l: number) {
    const orderDetailsArray = this.phase3Form.get('orderDtls') as UntypedFormArray;
    const applEffOrderDetailsArray = orderDetailsArray.at(i).get('applEffOrderDetails') as UntypedFormArray;
    const applCopyLocLength = applEffOrderDetailsArray.at(l).get('applCopyLoc') as UntypedFormArray;
    const subCopyArrayLength = applCopyLocLength.value.filter((element: any) => element.fileLoc !== null).length;
    return subCopyArrayLength;
  }
  getapplicationOrderCopyLength(i: number, l: number) {
    const orderDetailsArray = this.phase3Form.get('orderDtls') as UntypedFormArray;
    const applEffOrderDetailsArray = orderDetailsArray.at(i).get('applEffOrderDetails') as UntypedFormArray;
    const applCopyLocLength = applEffOrderDetailsArray.at(l).get('applOrderCopyLoc') as UntypedFormArray;
    const subCopyOrderArrayLength = applCopyLocLength.value.filter((element: any) => element.fileLoc !== null).length;
    return subCopyOrderArrayLength;
  }
  getOrderNoticeLoc(i: number) {
    return (this.phase3Form.get(`orderDtls.${i}.orderCopyLoc4`) as UntypedFormArray)
      .controls;
  }
  getApplCopyLoc(i: number, k: number) {
    return (
      this.phase3Form.get(
        `orderDtls.${i}.applEffOrderDetails.${k}.applCopyLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getApplOrderCopyLoc(i: number, k: number) {
    return (
      this.phase3Form.get(
        `orderDtls.${i}.applEffOrderDetails.${k}.applOrderCopyLoc`
      ) as UntypedFormArray
    ).controls;
  }
  getChallanLoc(i: number) {
    return (this.phase3Form.get(`libDetails.${i}.challanCopyLoc`) as UntypedFormArray)
      .controls;
  }
  uploaChallanLoc(itemListIndex: any, judicialIndex: any) {
    const libDtlsArray: any = this.phase3Form.get('libDetails') as UntypedFormGroup;
    const element = libDtlsArray.controls[itemListIndex].get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteChallanLoc(itemListIndex: any, judicialIndex: any) {
    const libDtlsArray: any = this.phase3Form.get('libDetails') as UntypedFormGroup;
    const element = libDtlsArray.controls[itemListIndex].get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadOrdereLoc(itemListIndex: any, judicialIndex: any) {
    const orderDtlsArray: any = this.phase3Form.get('orderDtls') as UntypedFormGroup;
    const element = orderDtlsArray.controls[itemListIndex].get(
      'orderCopyLoc4'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteOrdereLoc(itemListIndex: any, judicialIndex: any) {
    const orderDtlsArray: any = this.phase3Form.get('orderDtls') as UntypedFormGroup;
    const element = orderDtlsArray.controls[itemListIndex].get(
      'orderCopyLoc4'
    ) as UntypedFormArray;
    if (element.controls.length > 0) {
      for (let index = 0; index < element.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = element.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadApplLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.phase3Form.get('orderDtls') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('applEffOrderDetails') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('applCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  deleteApplLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.phase3Form.get('orderDtls') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('applEffOrderDetails') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('applCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }

  }
  uploadApplOrderLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.phase3Form.get('orderDtls') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('applEffOrderDetails') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('applOrderCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteApplOrderLoc(itemListIndex: any, judicialIndex: any, kIndex: any) {
    const noticesFormArray = this.phase3Form.get('orderDtls') as UntypedFormGroup;
    const element = noticesFormArray.controls[itemListIndex].get('applEffOrderDetails') as UntypedFormGroup;
    const element2 = element.controls[judicialIndex].get('applOrderCopyLoc') as UntypedFormArray;

    if (element2.controls.length > 0) {
      for (let index = 0; index < element2.controls.length; index++) {
        if (index === kIndex) {
          let itemListuploadControl = element2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  //phase 3


  //initialise issue array
  initializeIssueList(data: any) {
    if (data.issues) {
      data.issues.forEach((letter: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.createIssueInvArray(letter);
        (this.scForm.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        letter.provAndLibDetails.forEach((detail: any) => {
          let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp(detail);
          (issuesFrmGrp.get('provAndLibDetails') as UntypedFormArray).push(detailFrmGrp);
        });
      });
      this.scForm.patchValue(data);
    }
    this.issueValueChange();
  }

  //  initialise orderCopyLoc1 array
  initializeOrderCopy() {
    this.caseData.orderCopyLoc1.forEach((data: any) => {
      let orderCopyFrmGrp = this.createOrder(data);
      (this.scForm.get('orderCopyLoc1') as UntypedFormArray).push(orderCopyFrmGrp);
    })
  }
  //  initialise applBookByDptLoc array
  initializeApplBookByDpt() {
    this.caseData.applBookByDptLoc.forEach((data: any) => {
      let applBookByDptFrmGrp = this.createApplBook(data);
      (this.scForm.get('applBookByDptLoc') as UntypedFormArray).push(applBookByDptFrmGrp);
    })
  }


  //  initialise itrDetails array
  initializeItrDetails() {
    this.caseData.itrDetails.forEach((details: any) => {
      let itrDetailsFrmGrp = this.createItrDetailsFrmGrp(details);
      (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).push(itrDetailsFrmGrp);
    })
  }

  initializePredepositDetails(data: any) {
    if (data.predepositDetails) {
      data.predepositDetails.forEach((data: any) => {
        let predeposiFrmGrp: UntypedFormGroup = this.createPredepositDetailsFrmGrp(data);
        (this.preDepositForm.get('predepositDetails') as UntypedFormArray).push(predeposiFrmGrp);
        if (data.challanCopyLoc) {
          data.challanCopyLoc.forEach((data: any) => {
            let challanCopyLFrmGrp: UntypedFormGroup = this.initChallanFrmGrp(data);
            (predeposiFrmGrp.get('challanCopyLoc') as UntypedFormArray).push(challanCopyLFrmGrp);
          });
        }
      });
      this.preDepositForm.patchValue(data);
    }
    this.predepositChanged();
  }


  initializeStayOrder(data: any) {
    if (data.stayOrder) {
      data.stayOrder.forEach((data: any) => {
        let stayOrderFrmGrp: UntypedFormGroup = this.createStayFrmGrp(data);
        (this.preDepositForm.get('stayOrder') as UntypedFormArray).push(stayOrderFrmGrp);
        if (data.copyOfStayLoc) {
          data.copyOfStayLoc.forEach((locList: any) => {
            let orderCopyFrmGrp: UntypedFormGroup = this.initOrderCopyFrmGrp(locList);
            (stayOrderFrmGrp.get('copyOfStayLoc') as UntypedFormArray).push(orderCopyFrmGrp);
          });
        }
      });
    }
    this.preDepositForm.patchValue(data);
  }




  onFileSelect(event: any, identifier?: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];

        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, identifier);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }

  onUpload(selectedfield: any, identifier?: any) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'NEW',
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield, identifier);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any, identifier?: string) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      var toupdate = this.scForm.value;
      var toTaxUpdate = this.preDepositForm.value;
      if (identifier == "notice") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.scForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toupdate = toupdate[pathToVeriabl[pathIndex]];
            } else {
              toupdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.scForm.patchValue(this.scForm.value);
      } else if (identifier == "tax") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.preDepositForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toTaxUpdate = toTaxUpdate[pathToVeriabl[pathIndex]];
            } else {
              toTaxUpdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.preDepositForm.patchValue(this.preDepositForm.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }


  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  /*upload function */

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.taxService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.taxService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  /* get download file path function */

  /*  Save api*/
  saveNotice(caseOption: string) {
    console.log(this.scForm, 'generalInfo');

    if (this.scForm.valid && this.generalInfo.valid) {
      let noticedata = this.scForm.get('issues') as UntypedFormArray;
      let orderCopyedata = this.scForm.get('orderCopyLoc1') as UntypedFormArray;
      let applBookByDptData = this.scForm.get('applBookByDptLoc') as UntypedFormArray;
      let itrdata = this.iTRDetailsForm.get('itrDetails') as UntypedFormArray;
      let stayOrderdata = this.preDepositForm.get('stayOrder') as UntypedFormArray;
      let predepositdata = this.preDepositForm.get('predepositDetails') as UntypedFormArray;

      this.inrFormattingRemoving();

      let issueArray: any = [];
      let noticeLocArray: any = [];
      let applBookByArray: any = [];
      let itrDetailsArray: any = [];
      let stayOrderArray: any = [];
      let predepositArray: any = [];
      let stayOrderDataArray: any = [];
      let stayOrderLocArray: any = [];
      let challanCopyArray: any = [];
      let challanCopyData: any = [];


      let issueData: any = {};
      let applBookByData: any = {};
      let datanoticeLoc: any = {};
      let dataItrDetails: any = {};
      let datastayOrder: any = {};
      let dataPredepositDetails1: any = {};
      let stayOrderLoc: any = {}
      let challanCopyDetails: any = {}


      noticedata.controls.forEach(element => {
        issueData = {
          issue: element.value.issue,
          keyword: element.value.keyword,
          riskParam: element.value.riskParam,
          tag: element.value.tag,
          addnOrDisOfExpensesF: element.value.addnOrDisOfExpensesF,
          taxRateF: element.value.taxRateF,
          taxImpactF: element.value.taxImpactF,
          interestF: element.value.interestF,
          penaltyF: element.value.penaltyF,
          totalF: element.value.totalF,
          remarkF: element.value.remarkF,
          provAndLibDetails: element.value.provAndLibDetails
        }
        issueArray.push(issueData);
      });

      orderCopyedata.controls.forEach(element => {
        if (element.value.fileLoc != '' && element.value.fileLoc != null) {
          datanoticeLoc = {
            fileLoc: element.value.fileLoc,
            remarks: element.value.remarks,
          }
          noticeLocArray.push(datanoticeLoc);
        }
      });

      applBookByDptData.controls.forEach(element => {
        if (element.value.fileLoc != '' && element.value.fileLoc != null) {
          applBookByData = {
            fileLoc: element.value.fileLoc,
            remarks: element.value.remarks,
          }
          applBookByArray.push(applBookByData);
        }
      });

      itrdata.controls.forEach(element => {
        if (element.value.itrForm != '' && element.value.itrForm != null) {
          dataItrDetails = {
            returnFiledUs: element.value.returnFiledUs,
            itrForm: element.value.itrForm,
            ackNo: element.value.ackNo,
            filingDate: element.value.filingDate,
            totTaxIncome: element.value.totTaxIncome,
            totTaxLiability: element.value.totTaxLiability,
            advanceTax: element.value.advanceTax,
            tdsAmount: element.value.tdsAmount,
            tcsAmount: element.value.tcsAmount,
            selfAssessmentAmount: element.value.selfAssessmentAmount,
            totTaxPaid: element.value.totTaxPaid,
            refundDemand: element.value.refundDemand,
          }
          itrDetailsArray.push(dataItrDetails);
        }
      });

      stayOrderdata.controls.forEach(element => {
        stayOrderDataArray = element.get('copyOfStayLoc') as UntypedFormArray;
      })
      if (stayOrderDataArray != null && stayOrderDataArray != ' ') {
        stayOrderDataArray.controls.forEach((element: any) => {
          if (element.value.fileLoc != '' && element.value.fileLoc != null) {
            stayOrderLoc = {
              fileLoc: element.value.fileLoc,
              remarks: element.value.remarks,
            }
            stayOrderLocArray.push(stayOrderLoc);
          }
        });
      }

      stayOrderdata.controls.forEach(element => {
        datastayOrder = {
          applicationDate: element.value.applicationDate,
          authority: element.value.authority,
          applicationStatus: element.value.applicationStatus,
          amount: element.value.amount,
          validUptoDate: element.value.validUptoDate,
          copyOfStayLoc: stayOrderLocArray,
        }
        stayOrderArray.push(datastayOrder);
      });

      predepositdata.controls.forEach(element => {
        challanCopyData = element.get('challanCopyLoc') as UntypedFormArray;
      })
      if (challanCopyData != null && challanCopyData != ' ') {
        challanCopyData.controls.forEach((element: any) => {
          if (element.value.fileLoc != '' && element.value.fileLoc != null) {
            challanCopyDetails = {
              fileLoc: element.value.fileLoc,
              remarks: element.value.remarks,
            }
            challanCopyArray.push(challanCopyDetails);
          }
        });
      }

      predepositdata.controls.forEach(element => {
        dataPredepositDetails1 = {
          cinRefNo: element.value.cinRefNo,
          date: element.value.date,
          bsrCode: element.value.bsrCode,
          tax: element.value.tax,
          interest: element.value.interest,
          total: element.value.total,
          challanCopyLoc: challanCopyArray
        }
        predepositArray.push(dataPredepositDetails1);
      });

      this.scRequest.phase = 1,
        this.scRequest.action = 'CREATE',
        this.scRequest.caseType = caseOption,
        this.scRequest.pan = this.generalInfo.controls.pan.value,
        this.scRequest.panName = this.generalInfo.controls.panName.value,
        this.scRequest.ay = this.generalInfo.controls.ay.value,
        this.scRequest.finYear = this.generalInfo.controls.finYear.value,
        this.scRequest.consultant = this.generalInfo.controls.consultant.value,
        this.scRequest.personResp = this.generalInfo.controls.personResp.value,
        this.scRequest.caseLabel = this.generalInfo.controls.caseLabel.value,
        this.scRequest.caseSummary = this.generalInfo.controls.caseSummary.value,
        this.scRequest.internalRemarks1 = this.generalInfo.controls.internalRemarks1.value,
        this.scRequest.reason = this.generalInfo.controls.reason.value,
        this.scRequest.caseMovedFrom = this.generalInfo.controls.caseMovedFrom.value,

        // this.scRequest.orderUs = this.scForm.controls.orderUs.value,
        this.scRequest.orderNo1 = this.scForm.controls.orderNo1.value,
        this.scRequest.orderDate1 = this.scForm.controls.orderDate1.value,
        this.scRequest.orderReceiptDate1 = this.scForm.controls.orderReceiptDate1.value,
        this.scRequest.orderReceiptMode1 = this.scForm.controls.orderReceiptMode1.value,
        this.scRequest.appealDueDate = this.scForm.controls.appealDueDate.value,
        this.scRequest.itatNoticeReceiptDate = this.scForm.controls.itatNoticeReceiptDate.value,
        this.scRequest.crossObjDueDate = this.scForm.controls.crossObjDueDate.value,
        this.scRequest.internalDueDate = this.scForm.controls.internalDueDate.value,
        // this.scRequest.orderPassedBy1 = this.scForm.controls.orderPassedBy1.value,
        this.scRequest.taxImpactFTotal = this.scForm.controls.taxImpactFTotal.value,
        this.scRequest.interestFTotal = this.scForm.controls.interestFTotal.value,
        this.scRequest.penaltyFTotal = this.scForm.controls.penaltyFTotal.value,
        this.scRequest.taxRateFTotal = this.scForm.controls.taxRateFTotal.value,
        this.scRequest.totOfTotal = this.scForm.controls.totOfTotal.value,
        this.scRequest.totOfAddnOrDisOfExpensesF = this.scForm.controls.totOfAddnOrDisOfExpensesF.value,

        this.scRequest.issues = issueArray;
      this.scRequest.orderCopyLoc1 = noticeLocArray;
      this.scRequest.applBookByDptLoc = applBookByArray;
      this.scRequest.itrDetails = itrDetailsArray;
      this.scRequest.stayOrder = stayOrderArray;
      this.scRequest.predepositDetails = predepositArray;

      this.taxService.CreateSc(this.scRequest).subscribe((response: any) => {
        if (response.status === 1) {
          this.toaster.showSuccess(response.message);
          this.formatTableValues();
          this.savedCaseId = response.response;
          this.isPhase2 = true;
          this.saveCaseId.emit(this.savedCaseId);
          this.initializeData();
        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    }
    else {
      this.toaster.showError("Required Fields Are Empty");
    }

  }

  /* Save Api end* */
  isAppealValid: boolean = false;
  updatePhase2() {
    // this.isSubmmissionSubmitted = true;
    let fetchCaseData: any;
    const phase2Form = this.phase2Form.value;
    const remandBackForm = this.remandBackForm.value;
    const phase3Form = this.phase3Form.value;
    const generalInfoForm = this.generalInfo.value;
    const appelOrderForm = this.scForm.value;
    const itrDetailForm = this.iTRDetailsForm.value;
    const preDepoForm = this.preDepositForm.value;
    let isAllHearingNull: boolean = true;
    let isAllUpperHearingNull: boolean = true;
    let isAllSummonNull: boolean = true;
    let isAllConsultNull: boolean = true;
    let isAllItrDetailsNull: boolean = true;
    let isAllPreDepositeNull: boolean = true;
    let isAllstayOrderNull: boolean = true;
    let isAllapplEffOrderNull: boolean = true;
    let isAlllibDetailsNull: boolean = true;
    let isAllrefundDetailsNull: boolean = true;
    let isAllRemandReport: boolean = true;

    if (this.caseId != null) {

      this.phase2UpdatePayload = {
        caseType: this.caseData.caseType,
        pan: generalInfoForm.pan,
        panName: generalInfoForm.panName,
        finYear: generalInfoForm.finYear,
        caseId: this.prevCaseId ?? this.caseId,
        // phase: 2,
        action: 'UPDATE',
        // appealNo: phase2Form.submmissionDtls.appealNo,
        // subSummary: phase2Form.submmissionDtls.subSummary,
        // internalRemarks: phase2Form.submmissionDtls.internalRemarks,
        submissions: phase2Form.submmissionDtls.appealAttachments,
        hearingDetails: phase2Form.hearingDtls,
        upperBenchHearingDetails: phase2Form.upperBenchHearingDetails,
        isCaseRefToUpperBench: phase2Form.isCaseRefToUpperBench,
        summonDetails: phase2Form.summonDtls,
        cc: phase2Form.consultChargesDtls,
        isAbeyance: JSON.parse(phase2Form.abeyaneRemandBackDtls.isAbeyance),
        isRemandedBack: JSON.parse(
          phase2Form.abeyaneRemandBackDtls.isRemandedBack
        ),
        ay: generalInfoForm.ay,
        consultant: generalInfoForm.consultant,
        personResp: generalInfoForm.personResp,
        caseLabel: generalInfoForm.caseLabel,
        caseSummary: generalInfoForm.caseSummary,
        internalRemarks1: generalInfoForm.internalRemarks1,
        reason: generalInfoForm.reason,
        caseMovedFrom: generalInfoForm.caseMovedFrom,
        orderUs: appelOrderForm.orderUs,
        orderNo1: appelOrderForm.orderNo1,
        orderDate1: appelOrderForm.orderDate1,
        orderReceiptDate1: appelOrderForm.orderReceiptDate1,
        orderReceiptMode1: appelOrderForm.orderReceiptMode1,
        // orderPassedBy1: appelOrderForm.orderPassedBy1,
        orderNo4: phase3Form.orderDtls[0].orderNo4,
        orderDate4: phase3Form.orderDtls[0].orderDate4,
        orderReceiptDate4: phase3Form.orderDtls[0].orderReceiptDate4,
        orderReceiptMode4: phase3Form.orderDtls[0].orderReceiptMode4,
        // orderPassedBy4: phase3Form.orderDtls[0].orderPassedBy4,
        orderSummary: phase3Form.orderDtls[0].orderSummary,
        internalRemarks4: phase3Form.orderDtls[0].internalRemarks4,
        outcome: phase3Form.orderDtls[0].outcome,
        orderCopyLoc4: phase3Form.orderDtls[0].orderCopyLoc4,
        appealDueDate: appelOrderForm.appealDueDate,
        internalDueDate: appelOrderForm.internalDueDate,
        orderCopyLoc1: appelOrderForm.orderCopyLoc1,
        issues: appelOrderForm.issues,
        itrDetails: itrDetailForm.itrDetails,
        predepositDetails: preDepoForm.predepositDetails,
        stayOrder: preDepoForm.stayOrder,
        appealDetails: phase3Form.orderDtls[0].appealDetails,
        applEffOrderDetails: phase3Form.orderDtls[0].applEffOrderDetails,
        libDetails: phase3Form.libDetails,
        refundDetails: phase3Form.refundDetails,
        status: this.caseStatus
      };
      // const orderIssues =  this.phase2UpdatePayload.orderDtls.map((orderDetail) => orderDetail.issues);


      this.phase2UpdatePayload.submissions.forEach((detail: any) => {
        if (detail.applFormLoc) {
          detail.applFormLoc = detail.applFormLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.applFormLoc.length == 0) {
            detail.applFormLoc = null;
          }
        }
        if (detail.applGroundLoc) {
          detail.applGroundLoc = detail.applGroundLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.applGroundLoc.length == 0) {
            detail.applGroundLoc = null;
          }
        }
        if (detail.stmtFactsLoc) {
          detail.stmtFactsLoc = detail.stmtFactsLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.stmtFactsLoc.length == 0) {
            detail.stmtFactsLoc = null;
          }
        }
        if (detail.applChallanFeesLoc) {
          detail.applChallanFeesLoc = detail.applChallanFeesLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.applChallanFeesLoc.length == 0) {
            detail.applChallanFeesLoc = null;
          }
        }
        if (detail.condonationOfDelayLoc) {
          detail.condonationOfDelayLoc = detail.condonationOfDelayLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.condonationOfDelayLoc.length == 0) {
            detail.condonationOfDelayLoc = null;
          }
        }
        if (detail.addEvidLoc) {
          detail.addEvidLoc = detail.addEvidLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.addEvidLoc.length == 0) {
            detail.addEvidLoc = null;
          }
        }
      });
      this.phase2UpdatePayload.submissions[0].ackNo =
        phase2Form.submmissionDtls.ackNo;
      this.phase2UpdatePayload.submissions[0].appealNo =
        phase2Form.submmissionDtls.appealNo;
      this.phase2UpdatePayload.submissions[0].subSummary =
        phase2Form.submmissionDtls.subSummary;
      this.phase2UpdatePayload.submissions[0].internalRemarks =
        phase2Form.submmissionDtls.internalRemarks;
      this.phase2UpdatePayload.submissions[0].subDate =
        phase2Form.submmissionDtls.subDate;
      this.phase2UpdatePayload.submissions[0].subMode =
        phase2Form.submmissionDtls.subMode;
      this.phase2UpdatePayload.submissions[0].respType =
        phase2Form.submmissionDtls.respType;
      (this.phase2UpdatePayload.submissions[0].ackCopyLoc =
        phase2Form.submmissionDtls.ackCopyLoc),
        (this.phase2UpdatePayload.submissions[0].ackCopyLoc =
          this.phase2UpdatePayload.submissions[0].ackCopyLoc.filter(
            (item: any) => item.fileLoc !== null
          ));
      // Iterate through hearingDetails and remove null fileLoc and remarks from each array
      this.phase2UpdatePayload.hearingDetails.forEach((detail: any) => {
        if (detail.phNoticeLoc) {
          detail.phNoticeLoc = detail.phNoticeLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.phNoticeLoc.length == 0) {
            detail.phNoticeLoc = null;
          }
        }
        if (detail.addSubLoc) {
          detail.addSubLoc = detail.addSubLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.addSubLoc.length == 0) {
            detail.addSubLoc = null;
          }
        }
        if (detail.phRecordIfAnyLoc) {
          detail.phRecordIfAnyLoc = detail.phRecordIfAnyLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.phRecordIfAnyLoc.length == 0) {
            detail.phRecordIfAnyLoc = null;
          }
        }
        if (detail.phAdjournmentLetterLoc) {
          detail.phAdjournmentLetterLoc = detail.phAdjournmentLetterLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.phAdjournmentLetterLoc.length == 0) {
            detail.phAdjournmentLetterLoc = null;
          }
        }
      });

      this.phase2UpdatePayload.upperBenchHearingDetails.forEach((detail: any) => {
        if (detail.phNoticeLoc) {
          detail.phNoticeLoc = detail.phNoticeLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.phNoticeLoc.length == 0) {
            detail.phNoticeLoc = null;
          }
        }
        if (detail.phRecordIfAnyLoc) {
          detail.phRecordIfAnyLoc = detail.phRecordIfAnyLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.phRecordIfAnyLoc.length == 0) {
            detail.phRecordIfAnyLoc = null;
          }
        }
      });

      this.phase2UpdatePayload.cc.forEach((detail: any) => {
        if (detail.supportingDocsLoc) {
          detail.supportingDocsLoc = detail.supportingDocsLoc.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.supportingDocsLoc.length == 0) {
            detail.supportingDocsLoc = null;
          }
        }
      });

      this.phase2UpdatePayload.remBackDetails = {
        dtNotices: remandBackForm.notices,
        remandRepDetails: remandBackForm.orderSummaryDtls,
        authName: remandBackForm.authDtls.authName,
        authDesigation: remandBackForm.authDtls.authDesigation,
        jurisdiction: remandBackForm.authDtls.jurisdiction,
      };
      // this.phase2UpdatePayload.remBackDetails.orderSummaryDtls = remandBackForm.orderSummaryDtls

      this.phase2UpdatePayload.remBackDetails.dtNotices.forEach(
        (notice: any) => {
          if (notice) {
            if (notice.submissions) {
              notice.submissions.forEach((submission: any) => {
                if (submission.ackCopyLoc) {
                  submission.ackCopyLoc = submission.ackCopyLoc.filter(
                    (item: any) => item.fileLoc !== null
                  );
                  if (submission.ackCopyLoc.length == 0) {
                    submission.ackCopyLoc = null;
                  }
                }
                if (submission.subCopyLoc) {
                  submission.subCopyLoc = submission.subCopyLoc.filter(
                    (item: any) => item.fileLoc !== null
                  );
                  if (submission.subCopyLoc.length == 0) {
                    submission.subCopyLoc = null;
                  }
                }
              });
            }
            if (notice.meetingDetails) {
              notice.meetingDetails.forEach((meeting: any) => {
                if (meeting.copyOfSubmissionLoc) {
                  meeting.copyOfSubmissionLoc = meeting.copyOfSubmissionLoc.filter(
                    (item: any) => item.fileLoc !== null
                  );
                  if (meeting.copyOfSubmissionLoc.length == 0) {
                    meeting.copyOfSubmissionLoc = null;
                  }
                }
              });
            }
          }
        }
      );
      this.phase2UpdatePayload.orderDtls = phase3Form.orderDtls;
      this.phase2UpdatePayload.libDetails = phase3Form.libDetails;
      this.phase2UpdatePayload.refundDetails = phase3Form.refundDetails;
      this.phase2UpdatePayload.remBackDetails.dtNotices.forEach(
        (detail: any) => {
          if (detail.noticeLoc) {
            detail.noticeLoc = detail.noticeLoc.filter(
              (item: any) => item.fileLoc !== null
            );
            if (detail.noticeLoc.length == 0) {
              detail.noticeLoc = null;
            }
          }
        }
      );
      this.phase2UpdatePayload.remBackDetails.remandRepDetails.forEach(
        (detail: any) => {
          if (detail.reportCopyLoc) {
            detail.reportCopyLoc = detail.reportCopyLoc.filter(
              (item: any) => item.fileLoc !== null
            );
            if (detail.reportCopyLoc.length == 0) {
              detail.reportCopyLoc = null;
            }
          }
        }
      );
      this.phase2UpdatePayload.remBackDetails.dtNotices.forEach(
        (notice: any) => {
          if (notice.submissions) {
            notice.submissions = notice.submissions.filter(
              (submission: any) =>
                submission.ackNo !== null || (submission.subCopyLoc && submission.subCopyLoc.length > 0)
            );
            if (notice.submissions.length === 0) {
              delete notice.submissions;
            }
          }
        }
      );
      this.phase2UpdatePayload.orderDtls.forEach((detail: any) => {
        if (detail.orderCopyLoc4) {
          detail.orderCopyLoc4 = detail.orderCopyLoc4.filter(
            (item: any) => item.fileLoc !== null
          );
          if (detail.orderCopyLoc4.length == 0) {
            detail.orderCopyLoc4 = null;
          }
        }
      });
      this.phase2UpdatePayload.orderDtls.forEach((order: any) => {
        if (order) {
          if (order.applEffOrderDetails) {
            order.applEffOrderDetails.forEach((orderEffect: any) => {
              if (orderEffect.ackCopyLoc) {
                orderEffect.ackCopyLoc = orderEffect.ackCopyLoc.filter(
                  (item: any) => item.fileLoc !== null
                );
                if (orderEffect.ackCopyLoc.length == 0) {
                  orderEffect.ackCopyLoc = null;
                }
              }
              if (orderEffect.applOrderCopyLoc) {
                orderEffect.applOrderCopyLoc =
                  orderEffect.applOrderCopyLoc.filter(
                    (item: any) => item.fileLoc !== null
                  );
                if (orderEffect.applOrderCopyLoc.length == 0) {
                  orderEffect.applOrderCopyLoc = null;
                }
              }
              if (orderEffect.applCopyLoc) {
                orderEffect.applCopyLoc = orderEffect.applCopyLoc.filter(
                  (item: any) => item.fileLoc !== null
                );
                if (orderEffect.applCopyLoc.length == 0) {
                  orderEffect.applCopyLoc = null;
                }
              }
              if (orderEffect.subCopyLoc) {
                orderEffect.subCopyLoc = orderEffect.subCopyLoc.filter(
                  (item: any) => item.fileLoc !== null
                );
                if (orderEffect.subCopyLoc.length == 0) {
                  orderEffect.subCopyLoc = null;
                }
              }
            });
          }
        }
      });
      this.phase2UpdatePayload.libDetails.forEach((libDetail: any) => {
        if (libDetail.challanCopyLoc) {
          libDetail.challanCopyLoc = libDetail.challanCopyLoc.filter(
            (entry: any) => entry.fileLoc !== null
          );
          if (libDetail.challanCopyLoc.length == 0) {
            libDetail.challanCopyLoc = null;
          }
        }
      });
      if (
        this.phase2UpdatePayload &&
        this.phase2UpdatePayload.orderCopyLoc1
      ) {
        this.phase2UpdatePayload.orderCopyLoc1 =
          this.phase2UpdatePayload.orderCopyLoc1.filter(
            (item: any) => item.fileLoc !== null
          );
        if (this.phase2UpdatePayload.orderCopyLoc1.length == 0) {
          this.phase2UpdatePayload.orderCopyLoc1 = null;
        }
      }

      if (this.phase2UpdatePayload && this.phase2UpdatePayload.stayOrder) {
        this.phase2UpdatePayload.stayOrder.forEach((order: any) => {
          if (order.copyOfStayLoc) {
            order.copyOfStayLoc = order.copyOfStayLoc.filter(
              (loc: any) => loc.fileLoc !== null
            );
            if (order.copyOfStayLoc.length == 0) {
              order.copyOfStayLoc = null;
            }
          }
        });
      }
      if (
        this.phase2UpdatePayload &&
        this.phase2UpdatePayload.predepositDetails
      ) {
        this.phase2UpdatePayload.predepositDetails.forEach((detail: any) => {
          if (detail.challanCopyLoc) {
            detail.challanCopyLoc = detail.challanCopyLoc.filter(
              (loc: any) => loc.fileLoc !== null
            );
            if (detail.challanCopyLoc.length == 0) {
              detail.challanCopyLoc = null;
            }
          }
        });
      }

      if (this.phase2UpdatePayload.summonDetails) {
        this.phase2UpdatePayload.summonDetails.forEach((detail: any) => {
          if (detail.summonNoticeLoc) {
            detail.summonNoticeLoc = detail.summonNoticeLoc.filter(
              (item: any) => item.fileLoc !== null
            );
            if (detail.summonNoticeLoc.length == 0) {
              detail.summonNoticeLoc = null;
            }
          }
          if (detail.addSubLoc) {
            detail.addSubLoc = detail.addSubLoc.filter(
              (item: any) => item.fileLoc !== null
            );
            if (detail.addSubLoc.length == 0) {
              detail.addSubLoc = null;
            }
          }
          if (detail.deptSummonReceivedLoc) {
            detail.deptSummonReceivedLoc =
              detail.deptSummonReceivedLoc.filter(
                (item: any) => item.fileLoc !== null
              );
            if (detail.deptSummonReceivedLoc.length == 0) {
              detail.deptSummonReceivedLoc = null;
            }
          }
        });
      }
      if (
        this.phase2UpdatePayload.submissions[0].subDate == null ||
        this.phase2UpdatePayload.submissions[0].respType == null ||
        this.phase2UpdatePayload.submissions[0].ackNo == null
      ) {
        delete this.phase2UpdatePayload.submissions;
      }
      if (this.phase2UpdatePayload.issues[0].issue == null) {
        delete this.phase2UpdatePayload.issues;
      }
      this.phase2UpdatePayload.prevStage = this.caseData.prevStage;
      if (this.selectedPhaseTab == 'tab2') {
        if (!this.phase2Form.value.submmissionDtls.subDate) {
          this.isSubmmissionSubmitted = true;
          this.toaster.showError('Required fields are Empty');
        } else if (!this.phase2Form.value.submmissionDtls.ackNo) {
          this.isSubmmissionSubmitted = true;
          this.toaster.showError('Required fields are Empty');
        } else if ((this.caseType !== 'MEMORANDUM') && (this.applFormLength || this.applGroundLocLength || this.stmtFactLength || this.applChallanFeesLength)) {
          this.isSubmmissionSubmitted = true;
          this.toaster.showError('Required fields are Empty');
        } else {
          this.isSubmmissionSubmitted = false;
        }

        this.phase2UpdatePayload.phase = 2;
        this.isNoticeNextSubmmited = false;
      } else if (this.selectedPhaseTab == 'remadnBack') {
        const remandBackForm: any = this.remandBackForm.controls.notices;
        const lastIndex: any = remandBackForm.length - 1;
        const data = remandBackForm.controls[lastIndex];


        if (data.controls.noticeRefNo.value && data.controls.noticeDate.value && data.controls.noticeReceiptDate.value && data.controls.legalDueDate.value && !this.isNoticeValid) {
          if (data.controls.noticeSummary.value && data.controls.noticeSummary.value.length > 1000 || data.controls.internalRemarks.value && data.controls.internalRemarks.value.length > 1000) {
            this.isNoticeNextSubmmited = true;
          } else {
            // this.submission.nativeElement.click();
            this.isNoticeNextSubmmited = false;

          }
        } else {
          this.isNoticeNextSubmmited = true;
          this.toaster.showError('Required fields are Empty');
        }
        if (this.phase2UpdatePayload.remBackDetails) {
          if (this.phase2UpdatePayload.remBackDetails.dtNotices) {
            for (const notice of this.phase2UpdatePayload.remBackDetails.dtNotices) {
              if (notice.submissions) {
                for (const submission of notice.submissions) {
                  if (
                    (submission.subDate === null || submission.subDate === undefined || submission.subDate === "") ||
                    (submission.ackNo === null || submission.ackNo === undefined || submission.ackNo === "") ||
                    submission.ackCopyLoc.length === 0 ||
                    submission.subCopyLoc.length === 0
                  ) {
                    this.isAddNewSubSubmitted = true;
                    this.toaster.showError('Required fields are Empty');
                    return;

                  } else {
                    this.isAddNewSubSubmitted = false;

                  }
                }
              }
            }
          }
        }

        this.phase2UpdatePayload.phase = 3;
      } else if (this.selectedPhaseTab == 'tab3') {
        const lastIndex = this.phase3Form.value.orderDtls.length - 1;
        const lastIndexArray = this.phase3Form.value.orderDtls[lastIndex];
        const lastIssueIndex = lastIndexArray.issues.length - 1;
        const lastIssue = lastIndexArray.issues[lastIssueIndex];
        if (
          (lastIndexArray.orderNo4 == null || lastIndexArray.orderNo4 == undefined || lastIndexArray.orderNo4 == "") ||
          (lastIndexArray.orderDate4 == null || lastIndexArray.orderDate4 == undefined || lastIndexArray.orderDate4 == "") ||
          (lastIndexArray.orderReceiptDate4 == null || lastIndexArray.orderReceiptDate4 == undefined || lastIndexArray.orderReceiptDate4 == "") ||
          (lastIndexArray.outcome == null || lastIndexArray.outcome == undefined || lastIndexArray.outcome == "")
        ) {
          this.isNewOrder = true;
          this.toaster.showError('Required fields are Empty');
        } else {
          this.isNewOrder = false;
          if (
            (lastIssue.issue == null || lastIssue.issue == undefined || lastIssue.issue == "") ||
            (lastIssue.status == null || lastIssue.status == undefined || lastIssue.status == "") ||
            (lastIndexArray.appealDetails[0].action == null || lastIndexArray.appealDetails[0].action == undefined || lastIndexArray.appealDetails[0].action == "")
          ) {
            this.isNewOrder = true;
            this.isIssueSubmitted = true;
            this.toaster.showError('Required fields are Empty');
          } else {
            this.isIssueSubmitted = false;
            this.isNewOrder = false;
          }
        }
        this.phase2UpdatePayload.phase = 4;
        this.isNoticeNextSubmmited = false;
      } else {

        if (generalInfoForm.caseLabel == "" || generalInfoForm.caseLabel == null || generalInfoForm.caseLabel == undefined) {
          this.isNextBtnClicked = true;
          this.toaster.showError('Required Fields Are Empty');
        } else {
          this.isNextBtnClicked = false;
        }
        if (appelOrderForm.orderCopyLoc1) {
          appelOrderForm.orderCopyLoc1 = appelOrderForm.orderCopyLoc1.filter((item: any) => item.fileLoc !== null);
        }
        if ((appelOrderForm.orderNo1 == "" || appelOrderForm.orderNo1 == null || appelOrderForm.orderNo1 == undefined) ||
          (appelOrderForm.orderDate1 == "" || appelOrderForm.orderDate1 == null || appelOrderForm.orderDate1 == undefined) ||
          (appelOrderForm.orderReceiptDate1 == "" || appelOrderForm.orderReceiptDate1 == null || appelOrderForm.orderReceiptDate1 == undefined) ||
          appelOrderForm.orderCopyLoc1.length == 0
        ) {
          this.isAppealValid = true;
        } else {
          this.isAppealValid = false;
        }
        if (this.phase2UpdatePayload.issues) {
          for (const issue of this.phase2UpdatePayload.issues) {
            if (issue.issue === null || issue.riskParam === null) {
              this.isSubmitted = true;
              return; // Issue or Risk Parameter is null, returning or breaking the process
            } else {
              this.isSubmitted = false;
            }
          }
        }
        this.phase2UpdatePayload.phase = 1;
        this.isNoticeNextSubmmited = false;
      }
      if (this.phase2UpdatePayload.issues) {

        const issue1 = this.phase2UpdatePayload.issues;
        const issue2 = this.phase2UpdatePayload.orderDtls[0].issues;
        let combined: any = [];
        for (let index = 0; index < issue1.length; index++) {
          // const element = array[index];
          let comObject = {
            addnOrDisOfExpensesF: issue1[index].addnOrDisOfExpensesF,
            taxRateF: issue1[index].taxRateF,
            taxImpactF: issue1[index].taxImpactF,
            interestF: issue1[index].interestF,
            totalF: issue1[index].totalF,
            penaltyF: issue1[index].penaltyF,
            addnOrDisOfExpensesL: (issue2[index]) ? issue2[index].addnOrDisOfExpensesL : null,
            taxRateL: (issue2[index]) ? ((Array.isArray(issue2[index]?.taxRateL)) ? issue2[index]?.taxRateL[0] : issue2[index]?.taxRateL) : null,
            taxImpactL: (issue2[index]) ? issue2[index].taxImpactL : null,
            interestL: (issue2[index]) ? issue2[index].interestL : null,
            penaltyL: (issue2[index]) ? issue2[index].penaltyL : null,
            totalL: (issue2[index]) ? issue2[index].totalL : null,
            remarkL: (issue2[index]) ? issue2[index].remarkL : null,
            matterType: (issue2[index]) ? issue2[index].matterType : null,
            status: (issue2[index]) ? issue2[index].status : null,
            issue: issue1[index].issue,
            provAndLibDetails: issue1[index].provAndLibDetails,
            keyword: issue1[index].keyword,
            tag: issue1[index].tag,
            riskParam: issue1[index].riskParam,
            remarkF: issue1[index].remarkF


          }
          combined.push(comObject)

        }
        this.phase2UpdatePayload.issues = combined;
      }
      if (this.phase2UpdatePayload.isRemandedBack == false) {
        delete this.phase2UpdatePayload.remBackDetails;
      }
      if (this.phase2UpdatePayload.appealDetails[0].action == null) {
        delete this.phase2UpdatePayload.appealDetails;
      }
      if (this.phase2UpdatePayload.appealDetails) {
        const appeal = this.phase2UpdatePayload.appealDetails;
        for (let index = 0; index < this.phase2UpdatePayload.appealDetails.length; index++) {
          const element = this.phase2UpdatePayload.appealDetails[index];

          if (element.forum == 'Not Applicable') {
            this.phase2UpdatePayload.appealDetails[index].forum = 'Not Applicable';
          } else if (element.forum == 'Closed') {
            this.phase2UpdatePayload.appealDetails[index].forum = 'CLOSED';
          }
        }
      }
      const convertAndRemoveCommas = (obj: any) => {
        for (const key in obj) {
          if (typeof obj[key] === 'string' && !isNaN(Number(obj[key].replace(/,/g, '')))) {
            obj[key] = Number(obj[key].replace(/,/g, ''));
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            convertAndRemoveCommas(obj[key]); // Recursion for nested objects
          }
        }
      };
      if (this.phase2UpdatePayload.issues) {
        this.phase2UpdatePayload.issues.forEach((issue: any) => {
          convertAndRemoveCommas(issue);
        });
      }
      if (this.phase2UpdatePayload.itrDetails) {
        this.phase2UpdatePayload.itrDetails.forEach((itr: any) => {
          convertAndRemoveCommas(itr);
        });
      }
      if (this.phase2UpdatePayload.predepositDetails) {
        this.phase2UpdatePayload.predepositDetails.forEach((pre: any) => {
          convertAndRemoveCommas(pre);
        });
      }
      if (this.phase2UpdatePayload.stayOrder) {
        this.phase2UpdatePayload.stayOrder.forEach((order: any) => {
          convertAndRemoveCommas(order);
        });
      }
      if (this.phase2UpdatePayload.cc) {
        this.phase2UpdatePayload.cc.forEach((consult: any) => {
          convertAndRemoveCommas(consult);
        });
      }
      if (this.phase2UpdatePayload.libDetails) {
        this.phase2UpdatePayload.libDetails.forEach((lib: any) => {
          convertAndRemoveCommas(lib);
        });
      }
      if (this.phase2UpdatePayload.refundDetails) {
        this.phase2UpdatePayload.refundDetails.forEach((refund: any) => {
          convertAndRemoveCommas(refund);
        });
      }
      if (this.phase2UpdatePayload.reason) {
        if (this.phase2UpdatePayload.caseType == 'WRIT') {
          delete this.phase2UpdatePayload.reason;
        }
      }
      if (this.phase2UpdatePayload.caseMovedFrom) {
        if (this.phase2UpdatePayload.caseType == 'WRIT') {
          delete this.phase2UpdatePayload.caseMovedFrom;
        }
      }
      if (this.phase2UpdatePayload.caseType == 'MEMORANDUM PENALTY CASE') {
        this.phase2UpdatePayload.itatNoticeReceiptDate = appelOrderForm.itatNoticeReceiptDate;
        this.phase2UpdatePayload.crossObjDueDate = appelOrderForm.crossObjDueDate;
        this.phase2UpdatePayload.applBookByDptLoc = appelOrderForm.applBookByDptLoc;
        if (appelOrderForm.applBookByDptLoc) {
          appelOrderForm.applBookByDptLoc = appelOrderForm.applBookByDptLoc.filter((item: any) => item.fileLoc !== null);
          if (appelOrderForm.applBookByDptLoc.length == 0) {
            this.isAppealValid = true;
          } else {
            this.isAppealValid = false;
          }
        }
        this.phase2UpdatePayload.caseType = 'MEMORANDUM PENALTY CASE';
      }
      if (this.phase2UpdatePayload.caseType == 'PENALTY') {
        this.phase2UpdatePayload.appealDueDate = appelOrderForm.appealDueDate;
        if ((appelOrderForm.appealDueDate == "" || appelOrderForm.appealDueDate == null || appelOrderForm.appealDueDate == undefined)) {
          this.isAppealValid = true;
        } else {
          this.isAppealValid = false;
        }
        this.phase2UpdatePayload.caseType = 'PENALTY';
      }
      if (this.phase2UpdatePayload.caseType == 'NEW') {
        this.phase2UpdatePayload.appealDueDate = appelOrderForm.appealDueDate;
        if ((appelOrderForm.appealDueDate == "" || appelOrderForm.appealDueDate == null || appelOrderForm.appealDueDate == undefined)) {
          this.isAppealValid = true;
        } else {
          this.isAppealValid = false;
        }
        this.phase2UpdatePayload.caseType = 'NEW';
      }
      if (this.phase2UpdatePayload.caseType == 'MEMORANDUM') {
        this.phase2UpdatePayload.itatNoticeReceiptDate = appelOrderForm.itatNoticeReceiptDate;
        this.phase2UpdatePayload.crossObjDueDate = appelOrderForm.crossObjDueDate;
        this.phase2UpdatePayload.applBookByDptLoc = appelOrderForm.applBookByDptLoc;
        if (appelOrderForm.applBookByDptLoc) {
          appelOrderForm.applBookByDptLoc = appelOrderForm.applBookByDptLoc.filter((item: any) => item.fileLoc !== null);
          if (appelOrderForm.applBookByDptLoc.length == 0) {
            this.isAppealValid = true;
          } else {
            this.isAppealValid = false;
          }
        }
        this.phase2UpdatePayload.caseType = 'MEMORANDUM';
      }
      if (this.phase2UpdatePayload.caseType == 'WRIT') {
        this.phase2UpdatePayload.appealDueDate = appelOrderForm.appealDueDate;
        if ((appelOrderForm.appealDueDate == "" || appelOrderForm.appealDueDate == null || appelOrderForm.appealDueDate == undefined)) {
          this.isAppealValid = true;
        } else {
          this.isAppealValid = false;
        }
        this.phase2UpdatePayload.caseType = 'WRIT';
      }
      //Hearing Details
      if (this.phase2UpdatePayload.hearingDetails) {
        for (let index = 0; index < this.phase2UpdatePayload.hearingDetails.length; index++) {
          const element = this.phase2UpdatePayload.hearingDetails[index];

          if ((this.taxService.isEmpty(element.phScheduledDate)) || (this.taxService.isEmpty(element.phNoticeLoc)) || (this.taxService.isEmpty(element.attended)) || (this.taxService.isEmpty(element.consultant)) || (this.taxService.isEmpty(element.addWrittenSubDate)) || (this.taxService.isEmpty(element.addSubLoc)) || (this.taxService.isEmpty(element.phRecordIfAnyLoc)) ||(this.taxService.isEmpty(element.phAdjournmentLetterLoc))|| (this.taxService.isEmpty(element.phDiscNotes))  || (this.taxService.isEmpty(element.phRefNo)) || (this.taxService.isEmpty(element.phNoticeDate)) || (this.taxService.isEmpty(element.mode)) || (this.taxService.isEmpty(element.phTime))) {
            isAllHearingNull = false;
            break;
          }


        }
      }
      //Upper Hearing
      if (this.phase2UpdatePayload.upperBenchHearingDetails) {
        for (let index = 0; index < this.phase2UpdatePayload.upperBenchHearingDetails.length; index++) {
          const element = this.phase2UpdatePayload.upperBenchHearingDetails[index];

          if ((this.taxService.isEmpty(element.noticeDate)) || (this.taxService.isEmpty(element.phScheduledDate)) || (this.taxService.isEmpty(element.din)) || (this.taxService.isEmpty(element.appealNo)) || (this.taxService.isEmpty(element.mode)) || (this.taxService.isEmpty(element.attended)) || (this.taxService.isEmpty(element.consultant)) || (this.taxService.isEmpty(element.phNoticeLoc)) || (this.taxService.isEmpty(element.phRecordIfAnyLoc)) || (this.taxService.isEmpty(element.phDiscNotes))) {
            isAllUpperHearingNull = false;
            break;
          }


        }
      }
      //Summon Details
      if (this.phase2UpdatePayload.summonDetails) {
        for (let index = 0; index < this.phase2UpdatePayload.summonDetails.length; index++) {
          const element = this.phase2UpdatePayload.summonDetails[index];

          if ((this.taxService.isEmpty(element.summonDate)) || (this.taxService.isEmpty(element.summonNoticeLoc)) || (this.taxService.isEmpty(element.attended)) || (this.taxService.isEmpty(element.personWhoAttended)) || (this.taxService.isEmpty(element.addWritSubDate)) || (this.taxService.isEmpty(element.addSubLoc)) || (this.taxService.isEmpty(element.deptSummonReceivedLoc)) || (this.taxService.isEmpty(element.discNotes))) {
            isAllSummonNull = false;
            break;
          }


        }
      }
      //Consultant Charges
      if (this.phase2UpdatePayload.cc) {
        for (let index = 0; index < this.phase2UpdatePayload.cc.length; index++) {
          const element = this.phase2UpdatePayload.cc[index];

          if ((this.taxService.isEmpty(element.firm)) || (this.taxService.isEmpty(element.consultant)) || (this.taxService.isEmpty(element.natureOfWork)) || ((this.taxService.isEmpty(element.fees)) && (element.fees !== 0)) || (this.taxService.isEmpty(element.supportingDocsLoc)) || (this.taxService.isEmpty(element.email)) || (this.taxService.isEmpty(element.contact))) {
            isAllConsultNull = false;
            break;
          }

        }
      }

      //ITR Details
      if (this.phase2UpdatePayload.itrDetails) {
        for (let index = 0; index < this.phase2UpdatePayload.itrDetails.length; index++) {
          const element = this.phase2UpdatePayload.itrDetails[index];

          if ((this.taxService.isEmpty(element.returnFiledUs)) || (this.taxService.isEmpty(element.itrForm)) || (this.taxService.isEmpty(element.ackNo)) || (this.taxService.isEmpty(element.filingDate)) || ((this.taxService.isEmpty(element.totTaxIncome)) && element.totTaxIncome != 0) || ((this.taxService.isEmpty(element.totTaxLiability)) && element.totTaxLiability != 0) || ((this.taxService.isEmpty(element.totTaxPaid)) && element.totTaxPaid != 0) || ((this.taxService.isEmpty(element.advanceTax)) && element.advanceTax != 0)  || ((this.taxService.isEmpty(element.tdsAmount)) && element.tdsAmount != 0) || ((this.taxService.isEmpty(element.tcsAmount)) && element.tcsAmount != 0) || ((this.taxService.isEmpty(element.selfAssessmentAmount)) && element.selfAssessmentAmount != 0)  || ((this.taxService.isEmpty(element.refundDemand)) && element.refundDemand != 0)) {
            isAllItrDetailsNull = false;
            break;
          }

        }
      }
      //PRE Deposite Details
      if (this.phase2UpdatePayload.predepositDetails) {
        for (let index = 0; index < this.phase2UpdatePayload.predepositDetails.length; index++) {
          const element = this.phase2UpdatePayload.predepositDetails[index];

          if ((this.taxService.isEmpty(element.cinRefNo)) || (this.taxService.isEmpty(element.date)) || (this.taxService.isEmpty(element.bsrCode)) || (this.taxService.isEmpty(element.challanCopyLoc)) || (element.tax != 0) || (element.interest != 0) || (element.total != 0)) {
            isAllPreDepositeNull = false;
            break;
          }

        }
      }
      //Stay Order Details
      if (this.phase2UpdatePayload.stayOrder) {
        for (let index = 0; index < this.phase2UpdatePayload.stayOrder.length; index++) {
          const element = this.phase2UpdatePayload.stayOrder[index];

          if ((this.taxService.isEmpty(element.applicationDate)) || (this.taxService.isEmpty(element.authority)) || (this.taxService.isEmpty(element.applicationStatus)) || (this.taxService.isEmpty(element.validUptoDate)) || (this.taxService.isEmpty(element.copyOfStayLoc)) || ((this.taxService.isEmpty(element.amount)) && (element.amount != 0))) {
            isAllstayOrderNull = false;
            break;
          }

        }
      }
      //Appeal Effect Details
      if (this.phase2UpdatePayload.applEffOrderDetails) {
        for (let index = 0; index < this.phase2UpdatePayload.applEffOrderDetails.length; index++) {
          const element = this.phase2UpdatePayload.applEffOrderDetails[index];

          if ((this.taxService.isEmpty(element.applDate)) || (this.taxService.isEmpty(element.applCopyLoc)) || (this.taxService.isEmpty(element.officer)) || (this.taxService.isEmpty(element.designation)) || (this.taxService.isEmpty(element.jurisdiction)) || (this.taxService.isEmpty(element.orderDate)) || (this.taxService.isEmpty(element.applOrderCopyLoc))) {
            isAllapplEffOrderNull = false;
            break;
          }

        }
      }
      //Lib Details
      if (this.phase2UpdatePayload.libDetails) {
        for (let index = 0; index < this.phase2UpdatePayload.libDetails.length; index++) {
          const element = this.phase2UpdatePayload.libDetails[index];

          if ((this.taxService.isEmpty(element.cinRefNo)) || (this.taxService.isEmpty(element.date)) || (this.taxService.isEmpty(element.bsrCode)) || (this.taxService.isEmpty(element.tax)) || (this.taxService.isEmpty(element.interest)) || (this.taxService.isEmpty(element.total)) || (this.taxService.isEmpty(element.challanCopyLoc))) {
            isAlllibDetailsNull = false;
            break;
          }

        }
      }
      //Refund Details
      if (this.phase2UpdatePayload.refundDetails) {

        for (let index = 0; index < this.phase2UpdatePayload.refundDetails.length; index++) {
          const element = this.phase2UpdatePayload.refundDetails[index];

          if ((this.taxService.isEmpty(element.receiptDate)) || (this.taxService.isEmpty(element.mode)) || (this.taxService.isEmpty(element.tax)) || (this.taxService.isEmpty(element.interest)) || (this.taxService.isEmpty(element.total))) {
            isAllrefundDetailsNull = false;
            break;
          }

        }
      }
      //RemandBack Report
      if (this.phase2UpdatePayload.remBackDetails) {
        if (this.phase2UpdatePayload.remBackDetails.remandRepDetails) {
          for (let index = 0; index < this.phase2UpdatePayload.remBackDetails.remandRepDetails.length; index++) {
            const element = this.phase2UpdatePayload.remBackDetails.remandRepDetails[index];

            if ((this.taxService.isEmpty(element.noticeRefNo)) || (this.taxService.isEmpty(element.reportCopyLoc)) || (this.taxService.isEmpty(element.reportDate)) || (this.taxService.isEmpty(element.reportSummary))) {
              isAllRemandReport = false;
              break;
            }

          }
        }
      }

      this.phase2UpdatePayload.hearingDetails = (!isAllHearingNull) ? phase2Form.hearingDtls : null;
      this.phase2UpdatePayload.upperBenchHearingDetails = (!isAllUpperHearingNull) ? phase2Form.upperBenchHearingDetails : null;
      this.phase2UpdatePayload.summonDetails = (!isAllSummonNull) ? phase2Form.summonDtls : null;
      this.phase2UpdatePayload.cc = (!isAllConsultNull) ? phase2Form.consultChargesDtls : null;
      this.phase2UpdatePayload.itrDetails = (!isAllItrDetailsNull) ? itrDetailForm.itrDetails : null;
      this.phase2UpdatePayload.predepositDetails = (!isAllPreDepositeNull) ? preDepoForm.predepositDetails : null;
      this.phase2UpdatePayload.stayOrder = (!isAllstayOrderNull) ? preDepoForm.stayOrder : null;
      this.phase2UpdatePayload.applEffOrderDetails = (!isAllapplEffOrderNull) ? phase3Form.orderDtls[0].applEffOrderDetails : null;
      this.phase2UpdatePayload.libDetails = (!isAlllibDetailsNull) ? phase3Form.libDetails : null;
      this.phase2UpdatePayload.refundDetails = (!isAllrefundDetailsNull) ? phase3Form.refundDetails : null;
      if (this.phase2UpdatePayload.remBackDetails) {
        this.phase2UpdatePayload.remBackDetails.remandRepDetails = (!isAllRemandReport) ? remandBackForm.orderSummaryDtls : null;
      }
      if (this.phase2UpdatePayload.appealDetails) {
        if (this.phase2UpdatePayload.appealDetails.length > 0) {
          if (this.appealCaseCreated && this.appealCaseCreated.length > 0) {


            this.phase2UpdatePayload.appealDetails.forEach((detail: any, index: any) => {
              if (!this.phase2UpdatePayload.appealDetails[index].isCaseCreated) {
                detail.isCaseCreated = null;
              } else {
                detail.isCaseCreated = this.appealCaseCreated[index].isCaseCreated;
              }

            });

          } else {
            this.phase2UpdatePayload.appealDetails.forEach((detail: any, index: any) => {
              detail.isCaseCreated = null;
            });
          }
        }
      }
      if (this.phase2UpdatePayload.orderDtls) {
        delete this.phase2UpdatePayload.orderDtls;
      }
      if (phase2Form.isCaseRefToUpperBench == false) {
        delete this.phase2UpdatePayload.upperBenchHearingDetails;
      }
      if (this.selectedPhaseTab == 'tab2') {
        if (!this.isSubmmissionSubmitted) {
          this.taxService
            .updateSc(this.phase2UpdatePayload).subscribe((res: any) => {

              if (res.status !== 1) {
                this.toaster.showError(res.message);
              } else {
                this.toaster.showSuccess(res.message);
                // this.initializeData();
              }
            })
        }

      } else if (this.selectedPhaseTab == 'remadnBack') {
        if (!this.isSubmmissionSubmitted && !this.isNoticeNextSubmmited && !this.isAddNewSubSubmitted) {
          this.taxService
            .updateSc(this.phase2UpdatePayload).subscribe((res: any) => {

              if (res.status !== 1) {
                this.toaster.showError(res.message);
              } else {
                this.toaster.showSuccess(res.message);
                // this.initializeData();
              }
            })
        }

      } else if (this.selectedPhaseTab == 'tab3') {
        if (!this.isNewOrder && !this.isSubmmissionSubmitted) {
          this.taxService.updateSc(this.phase2UpdatePayload).subscribe((res: any) => {
            if (res.status === 1) {
              this.toaster.showSuccess(res.message);
            } else {
              this.toaster.showError(res.message);
              // this.initializeData();
            }
          })
        }
      } else {
        if (!this.isNextBtnClicked && !this.isSubmitted) {
          this.taxService.updateSc(this.phase2UpdatePayload).subscribe((res: any) => {
            if (res.status !== 1) {
              this.toaster.showError(res.message);
            } else {
              this.toaster.showSuccess(res.message);
              // this.initializeData();
            }
          })
        } else {
          this.toaster.showError("Required Fields Are Empty");
        }
      }

    }
  }
  /*  fetch api*/
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        caseId: this.caseId,
      }
      this.taxService.getGetSC(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.caseData = response.response;
          this.prevCaseId = this.caseData.caseId
          this.caseType = this.caseData.caseType;
          this.caseStatus = this.caseData.status;
          this.cumulativeData = response.response.cumulativeTotals;
            if(this.cumulativeData.length > 0){
            this.cumulativeData[1].tax += this.cumulativeData[0].tax;
            this.cumulativeData[1].interest += this.cumulativeData[0].interest;
            this.cumulativeData[1].total += this.cumulativeData[0].total;
            this.cumulativeData.shift();
          }
          const indexToUpdate = this.cumulativeData.findIndex((obj: { label: string; }) => obj.label === "Cumulative payment made for admitted liability");
          if (indexToUpdate !== -1) {
                this.cumulativeData[indexToUpdate].label = "Cumulative payment made for assessed tax";
          }
          this.prevStage = this.caseData.prevStage;
          this.historyData = this.caseData.caseTypeHistory;
          this.penaltyData = this.historyData.includes('PENALTY');
          if (this.caseType == 'MEMORANDUM PENALTY CASE') {
            this.caseType = 'MEMORANDUM PENALTY CASE';
          }
          if (this.caseType == 'MEMORANDUM') {
            this.caseType = 'MEMORANDUM';
          }
          if (this.caseType == 'PENALTY') {
            this.caseType = 'PENALTY';
          }
          if (this.caseType == 'NEW') {
            this.caseType = 'NEW';
          }
          if (this.caseType == 'WRIT') {
            this.caseType = 'WRIT';
          }
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.formInit();
          if (this.caseData.stayOrder && this.caseData.stayOrder.length) {
            this.caseData.stayOrder.forEach((Oder: any) => {
              Oder.amount = this.inrService.formatCurrency(String(Oder.amount))
            })
          }
          // if (this.caseData.caseMovedFrom) {
          //   if (this.caseData.caseMovedFrom == 'C(A)') {
          //     this.generalInfo.controls.caseMovedFrom.patchValue('Commissioner (A)');
          //   } else if (this.caseData.caseMovedFrom == 'Assessment') {
          //     this.generalInfo.controls.caseMovedFrom.patchValue('Assessment');
          //   }else if (this.caseData.caseMovedFrom == 'Intimation') {
          //     this.generalInfo.controls.caseMovedFrom.patchValue('Intimation Order');
          //   }else if (this.caseData.caseMovedFrom == 'Tribunal') {
          //     this.generalInfo.controls.caseMovedFrom.patchValue('Appellate Tribunal');
          //   } {
          //      this.generalInfo.controls.caseMovedFrom.patchValue(this.caseData.caseMovedFrom);
          //   }
          // }
          if (this.caseData.predepositDetails && this.caseData.predepositDetails.length) {
            this.caseData.predepositDetails.forEach((refundDetails: any) => {
              refundDetails.tax = this.inrService.formatCurrency(String(refundDetails.tax))
              refundDetails.interest = this.inrService.formatCurrency(String(refundDetails.interest))
              refundDetails.total = this.inrService.formatCurrency(String(refundDetails.total))
            })
          }

          if (this.caseData.itrDetails && this.caseData.itrDetails.length) {
            this.caseData.itrDetails.forEach((itrDetails: any) => {
              itrDetails.totTaxIncome = this.inrService.formatCurrency(String(itrDetails.totTaxIncome))
              itrDetails.totTaxLiability = this.inrService.formatCurrency(String(itrDetails.totTaxLiability))
              itrDetails.advanceTax = this.inrService.formatCurrency(String(itrDetails.advanceTax))
              itrDetails.tdsAmount = this.inrService.formatCurrency(String(itrDetails.tdsAmount))
              itrDetails.tcsAmount = this.inrService.formatCurrency(String(itrDetails.tcsAmount))
              itrDetails.selfAssessmentAmount = this.inrService.formatCurrency(String(itrDetails.selfAssessmentAmount))
              itrDetails.totTaxPaid = this.inrService.formatCurrency(String(itrDetails.totTaxPaid))
              itrDetails.refundDemand = this.inrService.formatCurrency(String(itrDetails.refundDemand))
            })
          }

          if (this.caseData.issues && this.caseData.issues.length) {
            this.caseData.issues.forEach((letter: any) => {
              letter.addnOrDisOfExpensesF = this.inrService.formatCurrency(String(letter.addnOrDisOfExpensesF))
              // letter.taxRateF = this.inrService.formatCurrency(String(letter.taxRateF))
              letter.taxImpactF = this.inrService.formatCurrency(String(letter.taxImpactF))
              letter.interestF = this.inrService.formatCurrency(String(letter.interestF))
              letter.penaltyF = this.inrService.formatCurrency(String(letter.penaltyF))
              letter.totalF = this.inrService.formatCurrency(String(letter.totalF))
              if (letter.provAndLibDetails && letter.provAndLibDetails.length) {
                letter.provAndLibDetails.forEach((details: any) => {
                  details.provAmtF = this.inrService.formatCurrency(String(details.provAmtF))
                  details.contLibAmtF = this.inrService.formatCurrency(String(details.contLibAmtF))
                  details.othLibF = this.inrService.formatCurrency(String(details.othLibF))
                  details.caroAmtF = this.inrService.formatCurrency(String(details.caroAmtF))
                })
              }
            })
          }

          this.isVisibleCaseId = true;

          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList(this.caseData);
          } else {
            this.addIssueInv();
          }

          if (this.caseData.orderCopyLoc1 && this.caseData.orderCopyLoc1.length > 0) {
            this.initializeOrderCopy();
          } else {
            // this.addOrder(i);
          }

          if (this.caseData.applBookByDptLoc && this.caseData.applBookByDptLoc.length > 0) {
            this.initializeApplBookByDpt();
          } else {
          }

          if (this.caseData.itrDetails && this.caseData.itrDetails.length > 0) {
            this.initializeItrDetails();
          } else {
            this.addItrDetails();
          }

          if (this.caseData.predepositDetails && this.caseData.predepositDetails.length > 0) {
            this.initializePredepositDetails(this.caseData);
          } else {
            this.addPredepositDetails();
          }

          if (this.caseData.stayOrder && this.caseData.stayOrder.length > 0) {
            this.initializeStayOrder(this.caseData);
          } else {
            this.addStay();
          }

          this.predepositChanged();
          this.issueValueChange();
        }
        //PHASE 2 & 3
        const phase2Form: any = this.phase2Form.controls;
        const submissionDtlsControl = phase2Form.submmissionDtls;

        if (submissionDtlsControl) {
          submissionDtlsControl.controls.ackCopyLoc.controls = [];
          submissionDtlsControl.controls.appealAttachments.controls[0].controls.applFormLoc.controls = [];
          submissionDtlsControl.controls.appealAttachments.controls[0].controls.applGroundLoc.controls = [];
          submissionDtlsControl.controls.appealAttachments.controls[0].controls.stmtFactsLoc.controls = [];
          submissionDtlsControl.controls.appealAttachments.controls[0].controls.applChallanFeesLoc.controls = [];
          submissionDtlsControl.controls.appealAttachments.controls[0].controls.condonationOfDelayLoc.controls = [];
          submissionDtlsControl.controls.appealAttachments.controls[0].controls.addEvidLoc.controls = [];


        }


        const remandBackForm: any = this.remandBackForm.controls;
        const phase3Form: any = this.phase3Form.controls;

        if (response.status === 1) {
          this.caseType = this.caseData.caseType;
          this.caseStatus = this.caseData.status;

          if (this.caseType == 'MEMORANDUM PENALTY CASE') {
            this.caseType = 'MEMORANDUM PENALTY CASE';
          }
          if (this.caseType == 'MEMORANDUM') {
            this.caseType = 'MEMORANDUM';
          }
          if (this.caseType == 'PENALTY') {
            this.caseType = 'PENALTY';
          }
          if (this.caseType == 'NEW') {
            this.caseType = 'NEW';
          }
          if (this.caseType == 'WRIT') {
            this.caseType = 'WRIT';
          }
          if (this.caseData !== null) {

            if (this.caseData.submissions) {
              if (this.caseData.submissions[0].subSummary) {
                phase2Form.submmissionDtls.controls.subSummary.patchValue(this.caseData.submissions[0].subSummary);
              }
              if (this.caseData.submissions[0].appealNo) {
                phase2Form.submmissionDtls.controls.appealNo.patchValue(this.caseData.submissions[0].appealNo);
              }
              if (this.caseData.submissions[0].internalRemarks) {
                phase2Form.submmissionDtls.controls.internalRemarks.patchValue(this.caseData.submissions[0].internalRemarks);
              }
              phase2Form.submmissionDtls.controls.ackNo.patchValue(
                this.caseData.submissions[0].ackNo
              );
              phase2Form.submmissionDtls.controls.subDate.patchValue(
                this.caseData.submissions[0].subDate
              );
              phase2Form.submmissionDtls.controls.respType.patchValue(
                this.caseData.submissions[0].respType
              );
              phase2Form.submmissionDtls.controls.subMode.patchValue(
                this.caseData.submissions[0].subMode
              );


              if (this.caseData.submissions[0].ackCopyLoc) {
                this.caseData.submissions[0].ackCopyLoc.forEach((data: any) => {
                  let copyFrmGrp = this.createAckCopyLoc(data);
                  (
                    phase2Form.submmissionDtls.controls.ackCopyLoc as UntypedFormArray
                  ).push(copyFrmGrp);
                });
              }
              if (this.caseData.submissions[0].applFormLoc) {
                this.caseData.submissions[0].applFormLoc.forEach((data: any) => {
                  let copyFrmGrp = this.createAppealFormLoc(data);
                  (
                    phase2Form.submmissionDtls.controls.appealAttachments
                      .controls[0].controls.applFormLoc as UntypedFormArray
                  ).push(copyFrmGrp);
                });
              }
              if (this.caseData.submissions[0].applGroundLoc) {
                this.caseData.submissions[0].applGroundLoc.forEach(
                  (data: any) => {
                    let copyFrmGrp = this.createapplGroundLoc(data);
                    (
                      phase2Form.submmissionDtls.controls.appealAttachments
                        .controls[0].controls.applGroundLoc as UntypedFormArray
                    ).push(copyFrmGrp);
                  }
                );
              }
              if (this.caseData.submissions[0].stmtFactsLoc) {
                this.caseData.submissions[0].stmtFactsLoc.forEach((data: any) => {
                  let copyFrmGrp = this.createstmtFactsLoc(data);
                  (
                    phase2Form.submmissionDtls.controls.appealAttachments
                      .controls[0].controls.stmtFactsLoc as UntypedFormArray
                  ).push(copyFrmGrp);
                });
              }
              if (this.caseData.submissions[0].applChallanFeesLoc) {
                this.caseData.submissions[0].applChallanFeesLoc.forEach(
                  (data: any) => {
                    let copyFrmGrp = this.createapplChallanFeesLoc(data);
                    (
                      phase2Form.submmissionDtls.controls.appealAttachments
                        .controls[0].controls.applChallanFeesLoc as UntypedFormArray
                    ).push(copyFrmGrp);
                  }
                );
              }
              if (this.caseData.submissions[0].condonationOfDelayLoc) {
                this.caseData.submissions[0].condonationOfDelayLoc.forEach(
                  (data: any) => {
                    let copyFrmGrp = this.createcondonationOfDelayLoc(data);
                    (
                      phase2Form.submmissionDtls.controls.appealAttachments
                        .controls[0].controls.condonationOfDelayLoc as UntypedFormArray
                    ).push(copyFrmGrp);
                  }
                );
              }
              if (this.caseData.submissions[0].addEvidLoc) {
                this.caseData.submissions[0].addEvidLoc.forEach((data: any) => {
                  let copyFrmGrp = this.createaddEvidLoc(data);
                  (
                    phase2Form.submmissionDtls.controls.appealAttachments
                      .controls[0].controls.addEvidLoc as UntypedFormArray
                  ).push(copyFrmGrp);
                });
              }
            }
            if (this.caseData.hearingDetails) {
              this.isHearingVisible = true;
              const hearingDetails = this.caseData.hearingDetails; // Replace 'yourData' with your actual data variable name

              const hearingControl = this.phase2Form.get(
                'hearingDtls'
              ) as UntypedFormArray;
              while (hearingControl.length !== 0) {
                hearingControl.removeAt(0);
              }
              hearingDetails.forEach((detail: any, index: any) => {
                const hearingGroup = this.createHearningDtls();
                hearingControl.push(hearingGroup);
                hearingControl.at(index).patchValue({
                  phScheduledDate: detail.phScheduledDate,
                  adjournedOrNot: detail.adjournedOrNot,
                  attended: detail.attended,
                  consultant: detail.consultant,
                  addWrittenSubDate: detail.addWrittenSubDate,
                  phDiscNotes: detail.phDiscNotes,
                  mode: detail.mode,
                  phRefNo: detail.phRefNo,
                  phTime: detail.phTime,
                  phNoticeDate: detail.phNoticeDate,
                });

                const phNoticeLocArray = detail.phNoticeLoc || [];
                const addSubLocArray = detail.addSubLoc || [];
                const phRecordIfAnyLocArray = detail.phRecordIfAnyLoc || [];
                const phAdjournmentLetterLocArray = detail.phAdjournmentLetterLoc || [];


                if (phNoticeLocArray.length > 0) {
                  const noticeControl = hearingControl
                    .at(index)
                    .get('phNoticeLoc') as UntypedFormArray;

                  phNoticeLocArray.forEach((notice: any, noticeIndex: any) => {
                    noticeControl.push(
                      this.fBuild.group({
                        fileLoc: notice.fileLoc,
                        remarks: notice.remarks,
                      })
                    );
                  });
                }

                if (addSubLocArray.length > 0) {
                  const noticeControl = hearingControl
                    .at(index)
                    .get('addSubLoc') as UntypedFormArray;

                  addSubLocArray.forEach((notice: any, noticeIndex: any) => {
                    noticeControl.push(
                      this.fBuild.group({
                        fileLoc: notice.fileLoc,
                        remarks: notice.remarks,
                      })
                    );
                  });
                }

                if (phRecordIfAnyLocArray.length > 0) {
                  const noticeControl = hearingControl
                    .at(index)
                    .get('phRecordIfAnyLoc') as UntypedFormArray;

                  phRecordIfAnyLocArray.forEach(
                    (notice: any, noticeIndex: any) => {
                      noticeControl.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    }
                  );
                }
                if (phAdjournmentLetterLocArray.length > 0) {
                  const noticeControl = hearingControl
                    .at(index)
                    .get('phAdjournmentLetterLoc') as UntypedFormArray;
  
                    phAdjournmentLetterLocArray.forEach(
                    (notice: any, noticeIndex: any) => {
                      noticeControl.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    }
                  );
                }
              });

              // phase2Form.hearingDtls.controls = this.caseData.hearingDetails;
            }
            if (this.caseData.isCaseRefToUpperBench) {
              if (this.caseData.isCaseRefToUpperBench == true) {
                phase2Form.isCaseRefToUpperBench.patchValue(true);
                this.showUpperBenchHearing = true;
              } else {
                phase2Form.isCaseRefToUpperBench.patchValue(false);
                this.showUpperBenchHearing = false;
              }
            }
            if (this.caseData.upperBenchHearingDetails) {
              this.isHearingVisible = true;
              const upperBenchHearingDetails = this.caseData.upperBenchHearingDetails; // Replace 'yourData' with your actual data variable name

              const upperBenchHearingDetailsControl = this.phase2Form.get(
                'upperBenchHearingDetails'
              ) as UntypedFormArray;
              while (upperBenchHearingDetailsControl.length !== 0) {
                upperBenchHearingDetailsControl.removeAt(0);
              }
              upperBenchHearingDetails.forEach((detail: any, index: any) => {
                const hearingGroup = this.createupperHearingDtls();
                upperBenchHearingDetailsControl.push(hearingGroup);
                upperBenchHearingDetailsControl.at(index).patchValue({
                  noticeDate: detail.noticeDate,
                  phScheduledDate: detail.phScheduledDate,
                  din: detail.din,
                  appealNo: detail.appealNo,
                  adjournedOrNot: detail.adjournedOrNot,
                  mode: detail.mode,
                  attended: detail.attended,
                  consultant: detail.consultant,
                  phDiscNotes: detail.phDiscNotes,

                });

                const phNoticeLocArray = detail.phNoticeLoc || [];
                const phRecordIfAnyLocArray = detail.phRecordIfAnyLoc || [];

                if (phNoticeLocArray.length > 0) {
                  const noticeControl = upperBenchHearingDetailsControl
                    .at(index)
                    .get('phNoticeLoc') as UntypedFormArray;

                  phNoticeLocArray.forEach((notice: any, noticeIndex: any) => {
                    noticeControl.push(
                      this.fBuild.group({
                        fileLoc: notice.fileLoc,
                        remarks: notice.remarks,
                      })
                    );
                  });
                }


                if (phRecordIfAnyLocArray.length > 0) {
                  const noticeControl = upperBenchHearingDetailsControl
                    .at(index)
                    .get('phRecordIfAnyLoc') as UntypedFormArray;

                  phRecordIfAnyLocArray.forEach(
                    (notice: any, noticeIndex: any) => {
                      noticeControl.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    }
                  );
                }
              });

              // phase2Form.hearingDtls.controls = this.caseData.hearingDetails;
            }
            if (this.caseData.summonDetails) {
              const summonDetails = this.caseData.summonDetails; // Replace 'yourData' with your actual data variable name

              const summonControl = this.phase2Form.get(
                'summonDtls'
              ) as UntypedFormArray;
              while (summonControl.length !== 0) {
                summonControl.removeAt(0);
              }
              summonDetails.forEach((detail: any, index: any) => {
                const summonGroup = this.createSummonDetails();
                summonControl.push(summonGroup);
                summonControl.at(index).patchValue({
                  summonDate: detail.summonDate,
                  adjournedOrNot: detail.adjournedOrNot,
                  attended: detail.attended,
                  personWhoAttended: detail.personWhoAttended,
                  addWritSubDate: detail.addWritSubDate,
                  discNotes: detail.discNotes,
                  // isSummonIssued: detail.isSummonIssued,
                });

                const summonNoticeLocArray = detail.summonNoticeLoc || [];
                const addSubLocArray = detail.addSubLoc || [];
                const deptSummonReceivedLocArray =
                  detail.deptSummonReceivedLoc || [];

                if (summonNoticeLocArray.length > 0) {
                  const noticeControl = summonControl
                    .at(index)
                    .get('summonNoticeLoc') as UntypedFormArray;

                  summonNoticeLocArray.forEach(
                    (notice: any, noticeIndex: any) => {
                      noticeControl.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    }
                  );
                }

                if (addSubLocArray.length > 0) {
                  const noticeControl = summonControl
                    .at(index)
                    .get('addSubLoc') as UntypedFormArray;

                  addSubLocArray.forEach((notice: any, noticeIndex: any) => {
                    noticeControl.push(
                      this.fBuild.group({
                        fileLoc: notice.fileLoc,
                        remarks: notice.remarks,
                      })
                    );
                  });
                }

                if (deptSummonReceivedLocArray.length > 0) {
                  const noticeControl = summonControl
                    .at(index)
                    .get('deptSummonReceivedLoc') as UntypedFormArray;

                  deptSummonReceivedLocArray.forEach(
                    (notice: any, noticeIndex: any) => {
                      noticeControl.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    }
                  );
                }
              });
            }
            if (this.caseData.cc) {
              const consultChargeDetails = this.caseData.cc; // Replace 'yourData' with your actual data variable name

              const consultChargesControl = this.phase2Form.get(
                'consultChargesDtls'
              ) as UntypedFormArray;
              while (consultChargesControl.length !== 0) {
                consultChargesControl.removeAt(0);
              }
              consultChargeDetails.forEach((detail: any, index: any) => {
                const consultGroup = this.createConsultCharges();
                consultChargesControl.push(consultGroup);
                consultChargesControl.at(index).patchValue({
                  firm: detail.firm,
                  consultant: detail.consultant,
                  natureOfWork: detail.natureOfWork,
                  fees: this.formatToIndianCurrency(detail.fees),
                  email: detail.email,
                  contact: detail.contact,
                });
                detail.fees = detail.fees ? this.formatToIndianCurrency(detail.fees) : null;

                const consultSupportingDocArray = detail.supportingDocsLoc || [];

                if (consultSupportingDocArray.length > 0) {
                  const noticeControl = consultChargesControl
                    .at(index)
                    .get('supportingDocsLoc') as UntypedFormArray;

                  consultSupportingDocArray.forEach(
                    (notice: any, noticeIndex: any) => {
                      noticeControl.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    }
                  );
                }
              });
            }
            const remandBackValue = sessionStorage.getItem('IsRemandBackHc');
            const abeyenceValue = sessionStorage.getItem('IsAbeyanceHc');
            if (remandBackValue == 'true') {
              phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.patchValue(
                true
              );
              this.isRemandBackTabVisible = true;
            } else {
              if (this.caseData.isRemandedBack == true) {
                phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.patchValue(
                  true
                );
                this.isRemandBackTabVisible = true;
              } else {
                phase2Form.abeyaneRemandBackDtls.controls.isRemandedBack.patchValue(
                  false
                );
              }
            }
            if (abeyenceValue == 'true') {
              phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.patchValue(
                true
              );
            } else {
              if (this.caseData.isAbeyance == true) {
                phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.patchValue(
                  true
                );
              } else {
                phase2Form.abeyaneRemandBackDtls.controls.isAbeyance.patchValue(
                  false
                );
              }
            }

            if (this.caseData.remBackDetails) {
              remandBackForm.authDtls.controls.authName.patchValue(
                this.caseData.remBackDetails.authName
              );
              remandBackForm.authDtls.controls.authDesigation.patchValue(
                this.caseData.remBackDetails.authDesigation
              );
              remandBackForm.authDtls.controls.jurisdiction.patchValue(
                this.caseData.remBackDetails.jurisdiction
              );
              if (this.caseData.remBackDetails.dtNotices) {
                const noticeDetails = this.caseData.remBackDetails.dtNotices; // Replace 'yourData' with your actual data variable name

                const noticeControl = this.remandBackForm.get(
                  'notices'
                ) as UntypedFormArray;
                while (noticeControl.length !== 0) {
                  noticeControl.removeAt(0);
                }
                noticeDetails.forEach((detail: any, index: any) => {
                  const noticegGroup = this.createNotices();
                  noticeControl.push(noticegGroup);
                  noticeControl.at(index).patchValue({
                    noticeRefNo: detail.noticeRefNo,
                    noticeDate: detail.noticeDate,
                    noticeReceiptDate: detail.noticeReceiptDate,
                    legalDueDate: detail.legalDueDate,
                    internalDueDate: detail.internalDueDate,
                    noticeSummary: detail.noticeSummary,
                    internalRemarks: detail.internalRemarks,
                  });

                  const noticeLocArray = detail.noticeLoc || [];

                  if (noticeLocArray.length > 0) {
                    const noticeControll = noticeControl
                      .at(index)
                      .get('noticeLoc') as UntypedFormArray;

                    noticeLocArray.forEach((notice: any, noticeIndex: any) => {
                      noticeControll.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    });
                  }
                  if (detail.submissions) {
                    this.isNoticeNextSubmmited = true;
                    const submissionsArray = detail.submissions || [];
                    const submissionControl = noticeControl
                      .at(index)
                      .get('submissions') as UntypedFormArray;

                    submissionsArray.forEach(
                      (submission: any, submissionIndex: number) => {
                        if (submissionControl.length <= submissionIndex) {
                          submissionControl.push(this.createSubmission());
                        }

                        submissionControl.at(submissionIndex).patchValue({
                          subDate: submission.subDate,
                          ackNo: submission.ackNo,
                          subSummary: submission.subSummary,
                          internalRemarks: submission.internalRemarks,
                          // Set other values as needed
                        });

                        const ackCopyLocArray = submission.ackCopyLoc || [];
                        const subCopyLocArray = submission.subCopyLoc || [];

                        const ackCopyLocControl = submissionControl
                          .at(submissionIndex)
                          .get('ackCopyLoc') as UntypedFormArray;
                        const subCopyLocControl = submissionControl
                          .at(submissionIndex)
                          .get('subCopyLoc') as UntypedFormArray;

                        ackCopyLocArray.forEach((ackLoc: any, ackIndex: number) => {
                          if (ackCopyLocControl.length <= ackIndex) {
                            ackCopyLocControl.push(
                              this.fBuild.group({
                                fileLoc: ackLoc.fileLoc,
                                remarks: ackLoc.remarks,
                              })
                            );
                          } else {
                            ackCopyLocControl.at(ackIndex).patchValue({
                              fileLoc: ackLoc.fileLoc,
                              remarks: ackLoc.remarks,
                            });
                          }
                        });

                        subCopyLocArray.forEach((subLoc: any, subIndex: number) => {
                          if (subCopyLocControl.length <= subIndex) {
                            subCopyLocControl.push(
                              this.fBuild.group({
                                fileLoc: subLoc.fileLoc,
                                remarks: subLoc.remarks,
                              })
                            );
                          } else {
                            subCopyLocControl.at(subIndex).patchValue({
                              fileLoc: subLoc.fileLoc,
                              remarks: subLoc.remarks,
                            });
                          }
                        });
                      }
                    );
                  }
                  const meetingsArray = detail.meetingDetails || [];
                  const meetingControl = noticeControl
                    .at(index)
                    .get('meetingDetails') as UntypedFormArray;
                  meetingsArray.forEach((meeting: any, meetingIndex: number) => {
                    if (meetingControl.length <= meetingIndex) {
                      meetingControl.push(this.createNoticeMeetingDetails());
                    }

                    meetingControl.at(meetingIndex).patchValue({
                      dateOfMeeting: meeting.dateOfMeeting,
                      personAttended: meeting.personAttended,
                      internalRemarks: meeting.internalRemarks,
                      // Set other values as needed
                    });

                    const copyOfSubLocArray = meeting.copyOfSubmissionLoc || [];

                    const copyOfSubLocControl = meetingControl
                      .at(meetingIndex)
                      .get('copyOfSubmissionLoc') as UntypedFormArray;

                    copyOfSubLocArray.forEach((ackLoc: any, ackIndex: number) => {
                      if (copyOfSubLocControl.length <= ackIndex) {
                        copyOfSubLocControl.push(
                          this.fBuild.group({
                            fileLoc: ackLoc.fileLoc,
                            remarks: ackLoc.remarks,
                          })
                        );
                      } else {
                        copyOfSubLocControl.at(ackIndex).patchValue({
                          fileLoc: ackLoc.fileLoc,
                          remarks: ackLoc.remarks,
                        });
                      }
                    });
                  });
                  // const submissionsArray = detail.submissions || [];
                });
              }
              if (this.caseData.remBackDetails.remandRepDetails) {
                const summaryDetails =
                  this.caseData.remBackDetails.remandRepDetails; // Replace 'yourData' with your actual data variable name

                const summaryControl = this.remandBackForm.get(
                  'orderSummaryDtls'
                ) as UntypedFormArray;
                while (summaryControl.length !== 0) {
                  summaryControl.removeAt(0);
                }

                summaryDetails.forEach((detail: any, index: any) => {
                  const summaryControlGroup = this.createremandBackOrderDtls();
                  summaryControl.push(summaryControlGroup);
                  summaryControl.at(index).patchValue({
                    reportDate: detail.reportDate,
                    noticeRefNo: detail.noticeRefNo,
                    reportSummary: detail.reportSummary,
                  });

                  const reportLocArray = detail.reportCopyLoc || [];

                  if (reportLocArray.length > 0) {
                    const noticeControll = summaryControl
                      .at(index)
                      .get('reportCopyLoc') as UntypedFormArray;

                    reportLocArray.forEach((notice: any, noticeIndex: any) => {
                      noticeControll.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    });
                  }
                });
              }
            }

            if (this.caseData.orderNo4) {
              phase3Form.orderDtls.controls[0].controls.orderNo4.patchValue(
                this.caseData.orderNo4
              );
            }
            if (this.caseData.orderDate4) {
              phase3Form.orderDtls.controls[0].controls.orderDate4.patchValue(
                this.caseData.orderDate4
              );
            }
            if (this.caseData.orderReceiptDate4) {
              phase3Form.orderDtls.controls[0].controls.orderReceiptDate4.patchValue(
                this.caseData.orderReceiptDate4
              );
            }
            if (this.caseData.orderReceiptMode4) {
              phase3Form.orderDtls.controls[0].controls.orderReceiptMode4.patchValue(
                this.caseData.orderReceiptMode4
              );
            }
            // if (this.caseData.orderPassedBy4) {
            //   phase3Form.orderDtls.controls[0].controls.orderPassedBy4.patchValue(
            //     this.caseData.orderPassedBy4
            //   );
            // }
            if (this.caseData.outcome) {
              phase3Form.orderDtls.controls[0].controls.outcome.patchValue(
                this.caseData.outcome
              );
            }
            if (this.caseData.orderSummary) {
              phase3Form.orderDtls.controls[0].controls.orderSummary.patchValue(
                this.caseData.orderSummary
              );
            }
            if (this.caseData.internalRemarks4) {
              phase3Form.orderDtls.controls[0].controls.internalRemarks4.patchValue(
                this.caseData.internalRemarks4
              );
            }
            if (this.caseData.orderCopyLoc4) {
              phase3Form.orderDtls.controls[0].controls.orderCopyLoc4.controls = [];
              this.caseData.orderCopyLoc4.forEach((data: any) => {
                let copyFrmGrp = this.createOrderFormLoc(data);
                (
                  phase3Form.orderDtls.controls[0].controls
                    .orderCopyLoc4 as UntypedFormArray
                ).push(copyFrmGrp);
              });
            }

            if (this.caseData.issues) {
              const issuesData = this.caseData.issues;
              const issuesFormArray = this.phase3Form.get(
                'orderDtls.0.issues'
              ) as UntypedFormArray;
              while (issuesFormArray.length) {
                issuesFormArray.removeAt(0);
              }

              issuesData.forEach((issue: any) => {
                const formattedControls: any = {
                  issue: [issue.issue, Validators.required],
                  // keyword: [issue.keyword],
                  status: [issue.status, Validators.required],
                  tag: [issue.tag],
                  matterType: [issue.matterType ? issue.matterType : 'Question of Fact'],
                  remarkL: [issue.remarkL ? issue.remarkL : null],
                  addnOrDisOfExpensesL: [
                    issue.addnOrDisOfExpensesL
                      ? issue.addnOrDisOfExpensesL
                      : issue.addnOrDisOfExpensesF,
                  ],
                  taxRateL: [issue.taxRateL ? issue.taxRateL : issue.taxRateF],
                  taxImpactL: [issue.taxImpactL ? issue.taxImpactL : issue.taxImpactF,],
                  interestL: [issue.interestL ? issue.interestL : issue.interestF,],
                  penaltyL: [issue.penaltyL ? issue.penaltyL : issue.penaltyF],
                  totalL: [issue.totalL ? issue.totalL : issue.totalF],
                  totOfAddnOrDisOfExpensesL: [issue.totOfAddnOrDisOfExpensesL ? issue.totOfAddnOrDisOfExpensesL : issue.totOfAddnOrDisOfExpensesL],
                  taxImpactLTotal: [issue.taxImpactLTotal ? issue.taxImpactLTotal : issue.taxImpactLTotal],
                  interestLTotal: [issue.interestLTotal ? issue.interestLTotal : issue.interestLTotal],
                  penaltyLTotal: [issue.penaltyLTotal ? issue.penaltyLTotal : issue.penaltyLTotal],
                  totOfTotal: [issue.totOfTotal ? issue.totOfTotal : issue.totOfTotal],
                };
                const fieldsToFormat = ['addnOrDisOfExpensesL', 'taxRateL', 'taxImpactL', 'interestL', 'penaltyL', 'totalL', 'totOfAddnOrDisOfExpensesL', 'taxImpactLTotal', 'interestLTotal', 'penaltyLTotal', 'totOfTotal'];
                fieldsToFormat.forEach(fieldName => {
                  if (fieldName != 'taxRateL') {

                    const fieldValue = formattedControls[fieldName] ? this.formatToIndianCurrency(formattedControls[fieldName]) : null;
                    formattedControls[fieldName] = [fieldValue];
                  } else {
                    const fieldValue = formattedControls[fieldName] ? (formattedControls[fieldName]) : null;
                    formattedControls[fieldName] = [fieldValue];
                  }
                });
                const formattedIssue = this.fBuild.group(formattedControls);
                issuesFormArray.push(formattedIssue);
              });
            }
            if (this.caseData.libDetails && this.caseData.libDetails.length > 0) {
              const libDetails = this.caseData.libDetails;

              const libControl = this.phase3Form.get('libDetails') as UntypedFormArray;

              while (libControl.length !== 0) {
                libControl.removeAt(0);
              }

              libDetails.forEach((detail: any, index: any) => {
                const libControlGroup = this.createPaymentDetails();
                libControl.push(libControlGroup);

                const formattedControls: any = {
                  cinRefNo: detail.cinRefNo,
                  date: detail.date,
                  bsrCode: detail.bsrCode,
                  tax: detail.tax ? this.formatToIndianCurrency(detail.tax) : null,
                  interest: detail.interest ? this.formatToIndianCurrency(detail.interest) : null,
                  total: detail.total ? this.formatToIndianCurrency(detail.total) : null,
                };

                libControl.at(index).patchValue(formattedControls);

                const challanCopyLocArray = detail.challanCopyLoc || [];

                if (challanCopyLocArray.length > 0) {
                  const noticeControl = libControl
                    .at(index)
                    .get('challanCopyLoc') as UntypedFormArray;

                  challanCopyLocArray.forEach((notice: any, noticeIndex: any) => {
                    noticeControl.push(
                      this.fBuild.group({
                        fileLoc: [notice.fileLoc],
                        remarks: [notice.remarks],
                      })
                    );
                  });
                }
              });
            }

            if (this.caseData.refundDetails) {
              const refundDetailsArray = this.phase3Form.get('refundDetails') as UntypedFormArray;
              while (refundDetailsArray.length) {
                refundDetailsArray.removeAt(0);
              }

              this.caseData.refundDetails.forEach((refund: any, index: any) => {
                const formattedRefund: any = {
                  receiptDate: refund.receiptDate,
                  mode: refund.mode,
                  tax: refund.tax ? this.formatToIndianCurrency(refund.tax) : null,
                  interest: refund.interest ? this.formatToIndianCurrency(refund.interest) : null,
                  total: refund.total ? this.formatToIndianCurrency(refund.total) : null,
                };

                const formattedRefundGroup = this.fBuild.group(formattedRefund);
                refundDetailsArray.push(formattedRefundGroup);
              });
            }
            if (this.caseData.appealDetails) {
              const appealData = this.caseData.appealDetails;
              const appealFormArray = this.phase3Form.get(
                'orderDtls.0.appealDetails'
              ) as UntypedFormArray;
              while (appealFormArray.length) {
                appealFormArray.removeAt(0);
              }
              appealData.forEach((appeal: any) => {
                appealFormArray.push(
                  this.fBuild.group({
                    action: [appeal.action, Validators.required],
                    forum: [appeal.forum == 'Not Applicable' ? appeal.forum = 'Not Applicable' : 'Closed'],
                    isCaseCreated: [appeal.isCaseCreated, Validators.required],
                  })
                );
              });
              this.appealCaseCreated = appealData;
            }
            if (this.caseData.applEffOrderDetails) {
              const appealData = this.caseData.applEffOrderDetails;
              const appealFormArray = this.phase3Form.get(
                'orderDtls.0.applEffOrderDetails'
              ) as UntypedFormArray;
              while (appealFormArray.length) {
                appealFormArray.removeAt(0);
              }
              appealData.forEach((detail: any, index: any) => {
                const appealControlGroup = this.createEffectAppealDetails();
                appealFormArray.push(appealControlGroup);
                appealFormArray.at(index).patchValue({
                  applDate: detail.applDate,
                  officer: detail.officer,
                  designation: detail.designation,
                  jurisdiction: detail.jurisdiction,
                  orderDate: detail.orderDate,
                });

                const applCopyLocArray = detail.applCopyLoc || [];
                const applOrderCopyLocArray = detail.applOrderCopyLoc || [];
                if (applCopyLocArray.length > 0) {
                  const noticeControll = appealFormArray
                    .at(index)
                    .get('applCopyLoc') as UntypedFormArray;

                  applCopyLocArray.forEach((notice: any, noticeIndex: any) => {
                    noticeControll.push(
                      this.fBuild.group({
                        fileLoc: notice.fileLoc,
                        remarks: notice.remarks,
                      })
                    );
                  });
                }
                if (applOrderCopyLocArray.length > 0) {
                  const noticeControll = appealFormArray.at(index).get('applOrderCopyLoc') as UntypedFormArray;
                  applOrderCopyLocArray.forEach(
                    (notice: any, noticeIndex: any) => {
                      noticeControll.push(
                        this.fBuild.group({
                          fileLoc: notice.fileLoc,
                          remarks: notice.remarks,
                        })
                      );
                    }
                  );
                }
              });
            }
          }
        }
      });
    }
  }

  calculateTotalFees() {
    const consultChargesDtlsArray = this.phase2Form.get('consultChargesDtls') as UntypedFormArray;
    let totalFees = 0;

    if (consultChargesDtlsArray) {
      consultChargesDtlsArray.controls.forEach((control: AbstractControl) => {
        const feesControl = control.get('fees');
        if (feesControl && feesControl.value !== null && feesControl.value !== undefined) {
          const fees = feesControl.value.toString().replace(/,/g, '');
          totalFees += parseFloat(fees) || 0;
        }
      });
    }

    return {
      totalFees: totalFees.toLocaleString('en-IN', { maximumFractionDigits: 2 }),
    };
  }
  calculateTotals() {
    const issuesArray = (this.phase3Form.get('orderDtls') as UntypedFormArray).at(0).get('issues') as UntypedFormArray;

    let totOfAddnOrDisOfExpensesLTotal = 0;
    let taxImpactLTotal = 0;
    let interestLTotal = 0;
    let penaltyLTotal = 0;
    let totOfTotal = 0;
    issuesArray.controls.forEach((control: AbstractControl) => {
      const issue = control as UntypedFormGroup;

      // Retrieve the value directly from the control and parse it to a numeric value
      totOfAddnOrDisOfExpensesLTotal += this.parseNumericValue(issue.get('addnOrDisOfExpensesL')?.value);
      taxImpactLTotal += this.parseNumericValue(issue.get('taxImpactL')?.value);
      interestLTotal += this.parseNumericValue(issue.get('interestL')?.value);
      penaltyLTotal += this.parseNumericValue(issue.get('penaltyL')?.value);
      totOfTotal += this.parseNumericValue(issue.get('totalL')?.value);
    });



    return {
      totOfAddnOrDisOfExpensesLTotal: totOfAddnOrDisOfExpensesLTotal.toLocaleString('en-IN', { maximumFractionDigits: 2 }),
      taxImpactLTotal: taxImpactLTotal.toLocaleString('en-IN', { maximumFractionDigits: 2 }),
      interestLTotal: interestLTotal.toLocaleString('en-IN', { maximumFractionDigits: 2 }),
      penaltyLTotal: penaltyLTotal.toLocaleString('en-IN', { maximumFractionDigits: 2 }),
      totOfTotal: totOfTotal.toLocaleString('en-IN', { maximumFractionDigits: 2 }),
    };
  }
  parseNumericValue(value: any): number {
    if (value === null || value === undefined) {
      return 0;
    }
    const parsedValue = parseFloat(value.toString().replace(/,/g, ''));
    return isNaN(parsedValue) ? 0 : parsedValue;
  }
  onInputRefund(value: any, j: any, fieldName: string): void {
    const refundFormArray = this.phase3Form.get('refundDetails') as UntypedFormArray;
    const controlsRefund = ['tax', 'interest', 'total'];
    //Refund
    let refundwithoutSymbol;
    if (controlsRefund.includes(fieldName)) {
      const refundcontrol = refundFormArray.at(j).get(fieldName);
      if (refundcontrol) {
        const numericValue = Number(value.toString().replace(/,/g, ''));
        refundwithoutSymbol = this.formatToIndianCurrency(value);
        refundcontrol.setValue(refundwithoutSymbol);

        // Additional logic specific to libDetails
        if (fieldName === 'tax' || fieldName === 'interest') {
          const totalValueRefund: any = this.calculateTotalRefundDetails(j);
          const totalControlRefund = refundFormArray.at(j).get('total');
          if (totalControlRefund) {
            totalControlRefund.setValue(this.formatToIndianCurrency(totalValueRefund));
          }
        }
      }
    }
  }
  onInputChange(value: any, j: any, fieldName: string): void {
    const phase2Form = (this.phase2Form.get('consultChargesDtls') as UntypedFormArray).at(j) as UntypedFormArray;
    const libFormArray = this.phase3Form.get('libDetails') as UntypedFormArray;

    // const phase3FormRefund = (this.phase3Form.get('refundDetails') as FormArray).at(j) as FormArray;
    const issuesArray: any = (this.phase3Form.get('orderDtls') as UntypedFormArray).at(0).get('issues') as UntypedFormArray;

    const controlsToUpdate = ['addnOrDisOfExpensesL', 'taxRateL', 'taxImpactL', 'interestL', 'penaltyL', 'totalL'];
    const controlsLib = ['tax', 'interest', 'total'];


    let withoutSymbol;
    let taxImpactValue: any;
    if (controlsToUpdate.includes(fieldName)) {
      const control = issuesArray?.at(j).get(fieldName)
      if (control) {
        if (fieldName != 'taxRateL') {
          withoutSymbol = this.formatToIndianCurrency(value);
          control.setValue(withoutSymbol);
        }
        // if (fieldName != 'taxRateL') {
        //    withoutSymbol = this.formatToIndianCurrency(value);
        //   control.setValue(withoutSymbol);
        // } else {
        //   withoutSymbol = value;
        //   control.setValue(withoutSymbol);
        //   // const taxImpactValue :any = (addnOrDisOfExpensesValue * taxRateValue) / 100;
        // }

        if (fieldName === 'addnOrDisOfExpensesL' || fieldName === 'interestL' || fieldName === 'penaltyL' || fieldName === 'taxRateL') {
          const addnOrDisOfExpensesControl = issuesArray.at(j).get('addnOrDisOfExpensesL');

          if (addnOrDisOfExpensesControl) {
            const stringWithoutCommas = (addnOrDisOfExpensesControl.value).replace(/,/g, '');
            const addnOrDisOfExpensesValue = parseFloat(stringWithoutCommas) || 0;
            let taxRateValue: any;
            // if (issuesArray.controls[j].controls.taxRateL.value) {
            //   withoutSymbol = value;
            //   control.setValue(withoutSymbol);
            //   taxRateValue = parseFloat(control.value);
            // }

            // Convert taxRateValue to a decimal by dividing by 100
            // const taxRateAsDecimal = taxRateValue / 100;
            // taxRateValue = issuesArray.at(j).get('taxRatetL')?.value;
            if (issuesArray.controls[j].controls.taxRateL.value && Array.isArray(issuesArray.controls[j].controls.taxRateL.value)) {
              taxRateValue = issuesArray.controls[j].controls.taxRateL.value[0];
            } else {
              taxRateValue = issuesArray.controls[j].controls.taxRateL.value;
            }
            // const taxImpactValue :any = (addnOrDisOfExpensesValue * taxRateValue) / 100;
            const taxImpactControl = issuesArray.at(j).get('taxImpactL');
            if (taxImpactControl) {
              taxImpactValue = parseFloat((issuesArray.at(j).get('taxImpactL')?.value || '').replace(/,/g, '')) || 0;
              // taxImpactControl.setValue(this.formatToIndianCurrency(taxImpactValue));
            }
            const interestValue = parseFloat((issuesArray.at(j).get('interestL')?.value || '').replace(/,/g, '')) || 0;
            const penaltyValue = parseFloat((issuesArray.at(j).get('penaltyL')?.value || '').replace(/,/g, '')) || 0;
            const totalValue = taxImpactValue + interestValue + penaltyValue;
            const totalControl = issuesArray.at(j).get('totalL');
            if (totalControl) {
              totalControl.setValue(this.formatToIndianCurrency(totalValue));
            }
          }
        }
      }
    }

    //Lib
    let libwithoutSymbol;
    if (controlsLib.includes(fieldName)) {
      const libcontrol = libFormArray.at(j).get(fieldName);
      if (libcontrol) {
        const numericValue = Number(value.toString().replace(/,/g, ''));
        libwithoutSymbol = this.formatToIndianCurrency(value);
        libcontrol.setValue(libwithoutSymbol);

        // Additional logic specific to libDetails
        if (fieldName === 'tax' || fieldName === 'interest') {
          const totalValueLib: any = this.calculateTotalLibDetails(j);
          const totalControlLib = libFormArray.at(j).get('total');
          if (totalControlLib) {
            totalControlLib.setValue(this.formatToIndianCurrency(totalValueLib));
          }
        }
      }
    }



    const fees = phase2Form?.get('fees');
    if (fees) {
      const withoutSymbol = this.formatToIndianCurrency(value);
      fees.setValue(withoutSymbol);
    }


  }

  calculateTotalLibDetails(j: any): number {
    const formGroup = (this.phase3Form.get('libDetails') as UntypedFormArray).at(j) as UntypedFormGroup;

    const taxControl = formGroup.get('tax');
    const interestControl = formGroup.get('interest');

    const taxValue = parseFloat((taxControl?.value || '').replace(/,/g, '')) || 0;
    const interestValue = parseFloat((interestControl?.value || '').replace(/,/g, '')) || 0;

    return taxValue + interestValue;
  }
  calculateTotalRefundDetails(j: any): number {
    const formGroup = (this.phase3Form.get('refundDetails') as UntypedFormArray).at(j) as UntypedFormGroup;

    const taxControl = formGroup.get('tax');
    const interestControl = formGroup.get('interest');

    const taxValue = parseFloat((taxControl?.value || '').replace(/,/g, '')) || 0;
    const interestValue = parseFloat((interestControl?.value || '').replace(/,/g, '')) || 0;

    return taxValue + interestValue;

  }
  formatToIndianCurrency(inputValue: string): string {
    const numberStr = String(inputValue).replace(/,/g, '');
    const number = parseFloat(numberStr);
    if (!isNaN(number)) {
      const formattedValue = number.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const indexOfDecimal = formattedValue.indexOf('.');
      const formattedWithoutSymbol = indexOfDecimal !== -1 ? formattedValue.substring(0, indexOfDecimal) : formattedValue;

      return formattedWithoutSymbol;
    }
    return '';
  }
  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('provAndLibDetails') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
  }

  issueDropdown() {
    this.dataSource = DT_ISSUE_DROPDOWN;
    this.dataSourceIssue = DT_ISSUE_DROPDOWN_PENALTY;

  }

  personResponsibleData() {
    let model = {
      companyid: this.selectedCompanyId
    }
    this.taxService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');

        // Check if userMailID exists in consultantList
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );
        if (matchingConsultant) {
          this.generalInfo.controls.consultant.patchValue(matchingConsultant.consultantName);
        }
      }
    });
  }

  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC'){
      this.isToggleConsultantDropdownCC = !this.isToggleConsultantDropdownCC;
    }
  }
  onClickToggleSegmentDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleSegmentDropdown = !this.isToggleSegmentDropdown;
    } else if (phase === 'p2') {
      this.isToggleSegmentDropdown2 = !this.isToggleSegmentDropdown2;
    }
  }
  

  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }

  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2001;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push(financialYrRange);
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
     this.finyearData = this.finyearData.map(yearRange => {
      const [start, end] = yearRange.split('-').map(Number);
      return `${start + 1}-${end + 1}`;
    });
  }


  getYearData() {
    var assYear = this.generalInfo.controls.ay.value;
    const dashIndex = assYear.indexOf('-');
    if (dashIndex !== -1) {
      var years = assYear.substring(0, dashIndex);
    }
    var convertToInt = parseInt(years);
    var year = convertToInt - 1;
    var financialYear = (year) + '-' + years
    this.generalInfo.controls.finYear.patchValue(financialYear);
  }
  getperiodToValue(content: any){
    this.getYearData();
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "DT",
    model.pan = this.selectedPan,
    model.criterias= [
      {
          "p": "ay",
          "o": "eq",
          "v": this.generalInfo.value.ay,
      }
  ]
  this.taxService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/directTaxSummary']);
    this.modalService.dismissAll();
  }

  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('provAndLibDetails') as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map((data: any) => data.finYear);
    this.finyearData.forEach(f => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }

  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.scForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('provAndLibDetails') as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map((data: any) => data.quarter);
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }

  predepositChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let predeposPaidTotal = 0;

    (this.preDepositForm.get('predepositDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = this.inrService.removeCommasAndParseNumber(c.value.tax);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);

      taxTotal += Number(tax);
      interestTotal += Number(interest);

      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      predeposPaidTotal += Number(total);
    })
    this.predepositTaxTotal = taxTotal;
    this.predepositInterestTotal = interestTotal;
    this.predepositTotal = predeposPaidTotal;
  }

  itrDetailsPaidChanged() {
    let totTaxLiabilityTotal = 0;
    let totTaxPaidTotal = 0;
    let totAdvanceTax = 0;
    let totTdsAmountTotal = 0;
    let totTcsAmountTotal = 0;
    let totSelfAssessmentAmountTotal = 0;
    let refundDemandTotal = 0;

    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const totTaxLiability = this.inrService.removeCommasAndParseNumber(c.value.totTaxLiability);
      const totTaxPaid = this.inrService.removeCommasAndParseNumber(c.value.totTaxPaid);
      const advanceTax = this.inrService.removeCommasAndParseNumber(c.value.advanceTax);
      const tdsAmount = this.inrService.removeCommasAndParseNumber(c.value.tdsAmount);
      const tcsAmount = this.inrService.removeCommasAndParseNumber(c.value.tcsAmount);
      const selfAssessmentAmount = this.inrService.removeCommasAndParseNumber(c.value.selfAssessmentAmount);
      const refundDemand = this.inrService.removeCommasAndParseNumber(c.value.refundDemand);

      totTaxLiabilityTotal += Number(totTaxLiability);
      totAdvanceTax += Number(advanceTax);
      totTdsAmountTotal += Number(tdsAmount);
      totTcsAmountTotal += Number(tcsAmount);
      totSelfAssessmentAmountTotal += Number(selfAssessmentAmount);
      totTaxPaidTotal += Number(totTaxPaid);
      refundDemandTotal += Number(refundDemand);

      c.patchValue({ totTaxPaid: this.inrService.formatCurrency(String((+Number(advanceTax)) + (+Number(tdsAmount)) + (+Number(tcsAmount)) + (+Number(selfAssessmentAmount)) )) });
      totTaxPaidTotal += Number(this.inrService.removeCommasAndParseNumber(c.value.totTaxPaid));

      c.patchValue({ refundDemand: this.inrService.formatCurrency(String((+Number(totTaxLiability)) - (+Number(advanceTax)) - (+Number(tdsAmount)) - (+Number(tcsAmount)) - (+Number(selfAssessmentAmount) ))) });

    })
  }



  issueValueChange() {
    let totOfAddnOrDisOfExpensesF = 0;
    let taxRateFTotal = 0;
    let taxImpactFTotal = 0;
    let interestFTotal = 0
    let penaltyFTotal = 0;

    (this.scForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const addnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(c.value.addnOrDisOfExpensesF);
      const taxRateF = this.inrService.removeCommasAndParseNumber(c.value.taxRateF);
      const taxImpactF = this.inrService.removeCommasAndParseNumber(c.value.taxImpactF);
      const interestF = this.inrService.removeCommasAndParseNumber(c.value.interestF);
      const penaltyF = this.inrService.removeCommasAndParseNumber(c.value.penaltyF);

      taxImpactFTotal += Number(taxImpactF);
      interestFTotal += Number(interestF);
      penaltyFTotal += Number(penaltyF);
      taxRateFTotal += Number(taxRateF);
      totOfAddnOrDisOfExpensesF += Number(addnOrDisOfExpensesF);

      // c.patchValue({ taxImpactF: this.inrService.formatCurrency((Number(addnOrDisOfExpensesF) / 100)*Number(taxRateF))});
      // taxImpactFTotal += Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactF));

      c.patchValue({ totalF: this.inrService.formatCurrency(String(Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactF)) + Number(interestF) + Number(penaltyF))) });
    })
    this.scForm.controls['taxImpactFTotal'].setValue(this.inrService.formatCurrency(String(taxImpactFTotal)));
    this.scForm.controls['interestFTotal'].setValue(this.inrService.formatCurrency(String(interestFTotal)));
    this.scForm.controls['penaltyFTotal'].setValue(this.inrService.formatCurrency(String(penaltyFTotal)));
    this.scForm.controls['totOfAddnOrDisOfExpensesF'].setValue(this.inrService.formatCurrency(String(totOfAddnOrDisOfExpensesF)));

    this.scForm.controls['totOfTotal'].setValue(this.inrService.formatCurrency(String(Number((+taxImpactFTotal) + (+interestFTotal) + (+penaltyFTotal)))));

  }

  formatTableValues() {
    const issues1Array = this.scForm.controls.issues as UntypedFormArray
    const predepositArray = this.preDepositForm.controls.predepositDetails as UntypedFormArray

    if (predepositArray && predepositArray.controls.length) {
      for (var index = 0; index < predepositArray.controls.length; index++) {
        const predepositControl = predepositArray.controls[index] as UntypedFormGroup;
        predepositControl.controls['tax'].setValue(this.inrService.formatCurrency(predepositControl.value.tax))
        predepositControl.controls['interest'].setValue(this.inrService.formatCurrency(predepositControl.value.interest))
        predepositControl.controls['total'].setValue(this.inrService.formatCurrency(predepositControl.value.total))
      }
    }

    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['addnOrDisOfExpensesF'].setValue(this.inrService.formatCurrency(issues1Control.value.addnOrDisOfExpensesF))
        issues1Control.controls['taxRateF'].setValue(this.inrService.formatCurrency(issues1Control.value.taxRateF))
        issues1Control.controls['taxImpactF'].setValue(this.inrService.formatCurrency(issues1Control.value.taxImpactF))
        issues1Control.controls['interestF'].setValue(this.inrService.formatCurrency(issues1Control.value.interestF))
        issues1Control.controls['penaltyF'].setValue(this.inrService.formatCurrency(issues1Control.value.penaltyF))
        issues1Control.controls['totalF'].setValue(this.inrService.formatCurrency(issues1Control.value.totalF))
      }
    }
    this.issueValueChange();
  }

  inrFormattingRemoving() {
    var data = this.scForm.value;
    var dataValue = this.preDepositForm.value;
    var itrValue = this.iTRDetailsForm.value;

    if (dataValue.stayOrder && dataValue.stayOrder.length) {
      dataValue.stayOrder.forEach((Oder: any) => {
        Oder.amount = this.inrService.removeCommasAndParseNumber(Oder.amount)
      })
    }

    if (dataValue.predepositDetails && dataValue.predepositDetails.length) {
      dataValue.predepositDetails.forEach((refundDetails: any) => {
        refundDetails.tax = this.inrService.removeCommasAndParseNumber(refundDetails.tax)
        refundDetails.interest = this.inrService.removeCommasAndParseNumber(refundDetails.interest)
        refundDetails.total = this.inrService.removeCommasAndParseNumber(refundDetails.total)
      })
    }

    this.preDepositForm.patchValue(dataValue);

    if (itrValue.itrDetails && itrValue.itrDetails.length) {
      itrValue.itrDetails.forEach((itrDetails: any) => {
        itrDetails.totTaxIncome = this.inrService.removeCommasAndParseNumber(itrDetails.totTaxIncome)
        itrDetails.totTaxLiability = this.inrService.removeCommasAndParseNumber(itrDetails.totTaxLiability)
        itrDetails.advanceTax = this.inrService.removeCommasAndParseNumber(itrDetails.advanceTax)
        itrDetails.tdsAmount = this.inrService.removeCommasAndParseNumber(itrDetails.tdsAmount)
        itrDetails.tcsAmount = this.inrService.removeCommasAndParseNumber(itrDetails.tcsAmount)
        itrDetails.selfAssessmentAmount = this.inrService.removeCommasAndParseNumber(itrDetails.selfAssessmentAmount)
        itrDetails.totTaxPaid = this.inrService.removeCommasAndParseNumber(itrDetails.totTaxPaid)
        itrDetails.refundDemand = this.inrService.removeCommasAndParseNumber(itrDetails.refundDemand)
      })
    }
    this.iTRDetailsForm.patchValue(itrValue);

    if (data.issues && data.issues.length) {
      data.issues.forEach((letter: any) => {
        letter.addnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(letter.addnOrDisOfExpensesF)
        letter.taxRateF = this.inrService.removeCommasAndParseNumber(letter.taxRateF)
        letter.taxImpactF = this.inrService.removeCommasAndParseNumber(letter.taxImpactF)
        letter.interestF = this.inrService.removeCommasAndParseNumber(letter.interestF)
        letter.penaltyF = this.inrService.removeCommasAndParseNumber(letter.penaltyF)
        letter.totalF = this.inrService.removeCommasAndParseNumber(letter.totalF)
        if (letter.provAndLibDetails && letter.provAndLibDetails.length) {
          letter.provAndLibDetails.forEach((details: any) => {
            details.provAmtF = this.inrService.removeCommasAndParseNumber(details.provAmtF)
            details.contLibAmtF = this.inrService.removeCommasAndParseNumber(details.contLibAmtF)
            details.othLibF = this.inrService.removeCommasAndParseNumber(details.othLibF)
            details.caroAmtF = this.inrService.removeCommasAndParseNumber(details.caroAmtF)
          })
        }
      })
    }
    data.totOfAddnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(data.totOfAddnOrDisOfExpensesF)
    data.taxImpactFTotal = this.inrService.removeCommasAndParseNumber(data.taxImpactFTotal)
    data.interestFTotal = this.inrService.removeCommasAndParseNumber(data.interestFTotal)
    data.penaltyFTotal = this.inrService.removeCommasAndParseNumber(data.penaltyFTotal)
    data.totOfTotal = this.inrService.removeCommasAndParseNumber(data.totOfTotal)

    this.scForm.patchValue(data);
  }
  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }

  handleDecimalPaste(index: any) {
    const issues1Array = this.scForm.controls.issues as UntypedFormArray;
    const issues1Control = issues1Array.controls[index] as UntypedFormGroup;
    issues1Control.controls['taxRateF'].setValue((Math.round(issues1Control.value.taxRateF * 100) / 100).toFixed(2));
  }

  submitgeneralInfo() {
    this.isNextBtnClicked = true;
    if (this.generalInfo.valid) {
      this.tab2Disable = true;
      this.atjAoTab.nativeElement.click();

    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }

  submitOrderForm() {
    this.isSubmitted = true;
    if (this.caseType !== 'WRIT') {
      let dataOrderCopy = (this.scForm.get('orderCopyLoc1') as UntypedFormArray).controls;
      var count = dataOrderCopy?.some((x) => x.get('fileLoc')?.value != null);
      if (!count) {
        this.toaster.showError('Required Fields Are Empty');
        return;
      }
    }

    if (this.caseType === 'MEMORANDUM') {
      let dataApplBookByDpt = (this.scForm.get('applBookByDptLoc') as UntypedFormArray).controls;
      var count = dataApplBookByDpt?.some((x) => x.get('fileLoc')?.value != null);
      if (!count) {
        this.toaster.showError('Required Fields Are Empty');
        return;
      }
    }
    if (this.scForm.valid) {
      this.tab3Disable = true;
      this.itrTab.nativeElement.click();

    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  submitiTRDetailsForm() {
    this.isSubmittedItr = true;
    if (this.iTRDetailsForm.valid) {
      this.tab4Disable = true;
      this.preDepositTab.nativeElement.click();

    } else {
      this.toaster.showError('Required Fields Are Empty')
    }
  }
  SaveForm(caseOption: string) {
    if (this.preDepositForm.valid) {
      this.tab5Disable = true;
      this.updateBtn = true;
      this.saveNotice(caseOption);
      this.isPhase2 = true;
      this.tab6Disable = true
      this.submision.nativeElement.click();


    } else {
      this.toaster.showError('Required Fields Are Empty')
    }
  }
  onKeyPress(event: KeyboardEvent, value: any, j: any, fieldName: string) {
    // Get the character code
    const charCode = event.which || event.keyCode;

    // Allow only numeric keys (0-9)
    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault(); // Prevent the key press
    } else {
      this.onInputChange(value, j, fieldName);
    }
  }
  saveBtn() {
    let id = document.getElementById('isNext');
    this.nextBtn.nativeElement.click();
    this.updateBtn = true;
  }

  selectedIssues:any
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.scForm.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "updatedOn",
    model.sortdir = "DESC",
    model.companyId =  "7437",
    model.module = "DT",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.preAjaForm.value.issue");
  this.taxService.getIssueRefData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
    if (response.status === 1) {
      this.issueRefData = response?.response;
      if(this.issueRefData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
    }      
    });
  }
  exportAsXLSX() {
    if (this.issueRefData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueRefData.map((source: any) => ({
      'PAN Name': source.panName,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'Assessment Year': source.ay,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'panName',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'panName',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.AY: {
        if (!this.sorting.ay) {
          this.sorting.ay = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'ay',
            'asc'
          );
        } else {
          this.sorting.ay = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'ay',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,'personResponsible', 'desc' );
        }
        break;
      }
    }
  }
  getAllTagData(){   
    this.masterService.getAllTagMaster().subscribe((response: any) => {
      if (response.status === 1) {
        const allTags = response.response.data;
        this.tagDataList = allTags
        .filter((tagItem: any) => tagItem.active) 
        .map((tagItem: any) => tagItem.tag);
      } else {     
        this.toaster.showSuccess(response.message);
      }
    });
  }

  getCompanyTags() {
    const selectedCompanyId = localStorage.getItem('selectedId'); // Get selected company ID
    this.masterService.getEntityTagsCaseDt(selectedCompanyId).subscribe((response: any) => {
      if (response.status === 1) {
        const allTags = response.response.data;
        
        // Filter tags based on the selected company ID
        this.tagDataList = allTags
          .filter((tagItem: any) => tagItem.companyId === Number(selectedCompanyId))
          .flatMap((tagItem: any) =>
            tagItem.tags
              .filter((tag: any) => tag.active) 
              .map((tag: any) => tag.tag)
          ); 
  
      } else {        
        this.toaster.showSuccess(response.message);
      }
    });
  }
}



import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { TaxServiceService } from '../service/tax-service.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ACTION_ENQUIRY_PROCEED,
  ACTION_INTIMATION_ORDER,
  ACTION_INTIMATION_ORDER3,
  ADJ_DEPTPRE,
  ADJ_PH1_ADJUNOT,
  CORRESPOND_INV_AMT,
  DT_ISSUE_DROPDOWN,
  FORUM_ENQUIRY_PROCEED,
  GRANTED,
  INTIMATION_OUTCOME,
  ISSUE_CONCLUSION_COMMISSIONER,
  ISSUE_CONCLUSION_DT,
  ISSUE_RISK_PARAM,
  ISSUING_AUTHORITY,
  ITR_Form_Type,
  MODE_OF_PAYMENT,
  MODE_OF_RECEIPT,
  ORDER_PASSED_BY,
  QUARTER,
  RECTIFICATION,
  RECTIFICATION_MODE,
  REDUCED1,
  RETURN_FILED,
  TYPE_OF_MATTER,
  TYPE_OF_NOTICE,
  YES_NO
} from 'src/app/shared/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { InrService } from 'src/app/shared/services/inr.service';
import { data } from 'jquery';
import { CreateIntimationOrderRequest } from 'src/app/models/direct-tax.interface';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { forkJoin } from 'rxjs';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  PAN = 'pan',
  CASE_LABEL = 'Case Label',
  AY = 'ay',
  PERSON_RESPONSIBLE = 'Person Responsible',
}

interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
interface tagData {
  tags: string;
}
@Component({
  selector: 'app-intimation-order',
  templateUrl: './intimation-order.component.html',
  styleUrls: ['./intimation-order.component.css']
})
export class IntimationOrderComponent implements OnInit {
  @Input() activeStages: string[] | null = null;
  @Output() saveCaseId = new EventEmitter<boolean>();
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<
    string[] | null
  >();
  @ViewChild('intimTab') intimTab!: ElementRef;
  @ViewChild('itrTab') itrTab!: ElementRef;
  @ViewChild('taxPayTab', { static: false }) taxPayTab!: ElementRef;
  @ViewChild('conclusionTab', { static: false }) conclusionTab!: ElementRef;
  @ViewChild('rectificationTab', { static: false }) rectificationTab!: ElementRef;
  @ViewChild('consultantTab', { static: false }) consultantTab!: ElementRef;
  @ViewChild('orderTab', { static: false }) orderTab!: ElementRef;
  @ViewChild('cumulativeTab', { static: false }) cumulativeTab!: ElementRef;
  @ViewChild('saveTab', { static: false }) saveTab!: ElementRef;
  @ViewChild('nextBtn') nextBtn!: ElementRef;

  finyearData: any[] = [];
  generalInfo!: UntypedFormGroup;
  noticeForm!: UntypedFormGroup;
  iTRDetailsForm!: UntypedFormGroup;
  taxPaymentRefundDetailsForm!: UntypedFormGroup;
  conclusionForm!: UntypedFormGroup;
  rectifForm!: UntypedFormGroup;
  consultantForm!: UntypedFormGroup;
  orderForm!: UntypedFormGroup;
  cumulativeForm!: UntypedFormGroup;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdownCC = true;
  isToggleSegmentDropdown = true;
  isToggleSegmentDropdown2 = true;
  savedCaseId: any;
  duplicateData:any
  fileUrl: any;
  selectedField: any;
  selectedFile: any;
  matches: any;
  maxDate = new Date();
  currentYear: any;
  companyId: any;
  selectedCompanyId: any;
  selectedPanName: any;
  selectedPan: any;
  navContext: any;
  uploadData: any;
  getAccessTabData: any;
  parentCaseId: any;
  dataSource: any;
  caseData: any;
  action: any;
  cumulativeData: any;
  caseId: any;
  sidemenu = false;
  detailsOfIssuingAuthority = false;
  selectedRectif: any;
  selectedOrder: any;
  prevStage: any;
  currentTab = 'dtintimation';
  activeTab: any;
  issueRefData:any;
  roleName: any;

  selectedNoticeType = 'Intimation Order U/s 143(1)';

  formData: FormData = new FormData();
  rmvorderCopyRef: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  rmvIssuingAuthorityModalRef!: NgbModalRef;
  rmvItrDetailsModalRef!: NgbModalRef;
  rmvLibDetailsModalRef!: NgbModalRef;
  rmvRefundDetailsModalRef!: NgbModalRef;
  rmvChallanFormLocModalRef!: NgbModalRef;
  rmvorderLocModalRef!: NgbModalRef;
  rmvDepartmentRef!: NgbModalRef;
  rmvConsultantModalRef!: NgbModalRef;
  rmvAppealDetailsRef!: NgbModalRef;
  reqDocModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;
  rmvofficerDetailsModalRef!: NgbModalRef;

  intimationOrderRequest: CreateIntimationOrderRequest =
    new CreateIntimationOrderRequest();

  liabilityAddmittedAndPaidInterestTotal = 0;
  liabilityAddmittedAndPaidTaxTotal = 0;
  liabilityAddmittedAndPaidTotal = 0;

  refundPaidInterestTotal = 0;
  refundPaidTaxTotal = 0;
  refundPaidTotal = 0;

  sorting: {
    caseId: boolean;
    panName: boolean;
    caseLabel: boolean;
    ay: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      panName: false,
      caseLabel: false,
      ay: false,
      personResponsible: false,
    };
    issueSorting = SORT_ISSUE;

  preLitPH1Preceded = ISSUING_AUTHORITY;
  typeOfOrder = TYPE_OF_NOTICE;
  itrDetailsOptions = YES_NO;
  grantedOptions = GRANTED;
  orderReceiptMode = MODE_OF_RECEIPT;
  modeOfPayemt = MODE_OF_PAYMENT;
  riskParam = ISSUE_RISK_PARAM;
  issueConclusion = ISSUE_CONCLUSION_DT;
  typeOfMatter = TYPE_OF_MATTER;
  OrReduced1 = REDUCED1;
  returnFiledUs = RETURN_FILED;
  itrFormList = ITR_Form_Type;
  quarterList = QUARTER;
  yesNoList = YES_NO;
  orderReceived = YES_NO;
  transferredAO = YES_NO;
  rectifModeOptions = MODE_OF_RECEIPT;
  rectificationOptions = RECTIFICATION;
  orderPassedBy = ORDER_PASSED_BY;
  forumAppeal = FORUM_ENQUIRY_PROCEED;
  actionData = ACTION_INTIMATION_ORDER;
  action3Data = ACTION_INTIMATION_ORDER3;
  outcomeData = INTIMATION_OUTCOME;
  whetherRectification = ADJ_PH1_ADJUNOT;

  feesChargedTotal = 0;
  rectiIndex: any;
  viewIons = false;
  hideData:boolean = false;
  isSelectDisabled: boolean = true;
  isCaseID: boolean = false;
  isSubmitted: boolean = false;
  isSubmittedItr: boolean = false;
  isNextBtnClicked: boolean = false;
  isSubmittedRectif: boolean = false;
  isSubmittedOrder: boolean = false;
  isSubmittedConsultant: boolean = false;
  isSubmittedAppeal: boolean = false;
  showTable: boolean = false;
  showTable1: boolean = false;
  updateBtn: boolean = false;
  tab2Disable: boolean = false;
  tab3Disable: boolean = false;
  tab4Disable: boolean = false;
  tab5Disable: boolean = false;
  tab6Disable: boolean = false;
  tab7Disable: boolean = false;
  tab8Disable: boolean = false;
  tab9Disable: boolean = false;

  isTab2: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isVisibleCaseId: boolean = false;
  isDisabledSelect: boolean = false;
  srAutoOrderNo: number = 1;

  isUPorderLoc: boolean = true;
  isDWorderLoc: boolean = true;
  isorderLocFile: boolean = false;
  isorderLocText: boolean = true;

  isSupportingDocDWText: boolean = true;
  isSupportingDocUPText: boolean = true;
  isSupportingDocFile: boolean = false;
  isSupportingDocText: boolean = true;

  isOrderLocDWText: boolean = true;
  isOrderLocUPText: boolean = true;
  isOrderLocFile: boolean = false;
  isOrderLocText: boolean = true;

  isadjDocsLocFile: boolean = false;
  isadjDocsLocText: boolean = true;
  isDWadjDocsLoc: boolean = true;
  isUPadjDocsLoc: boolean = true;

  isUPnoticeLoc: boolean = true;
  isDWnoticeLoc: boolean = true;
  isnoticeLocFile: boolean = false;
  isnoticeLocText: boolean = true;
  isToggleDropdown=true;
  isToggleDropdownPerResp2=true;

  tagDataList: tagData[] = [] ;
  navData:any;

  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private activeSnapShot: ActivatedRoute,
    private taxService: TaxServiceService,
    private toaster: ToasterService,
    private excelService: ExcelService,
    private commonServ: CommonService,
    private getterSetter: GetterSetterService,
    private inrService: InrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private masterService: MasterService,
    private US: UserSettingsService,
  ) {
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
  }

  ngOnInit(): void {
    this.navContext = this.commonServ.getNavContext();    
    this.selectedPan = this.navContext.pan;
    if (this.navContext.entityType === 'Business') {
      this.selectedPanName = sessionStorage.getItem('company');
      this.getAllTagData();
    } else {
      this.selectedPanName = this.navContext.entityName;
      this.getCompanyTags();
    }
    this.navData = JSON.parse(this.getterSetter.getNavContextData() || '{}');
    this.formInit();
    this.setDate();
    this.getYears();
    this.personResponsibleData();
    //this.getCompanyTags();
    this.consultantData();
    this.issueDropdown();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.initializeData();
    }
    if (this.action === 'create') {
      this.addRefundDetails();
      this.addIssuingAuthority();
      this.addItrDetails();
      this.addLibDetails();
      this.addIssueInv();
      this.addRectif();
      this.addAppealDetail();
      this.addAppealDetail3();
      this.viewIons=false;
    } else if (this.action === 'update') {
      this.updateBtn = true;
      this.isDisabledSelect = true;
      this.viewIons=true;
    } else if (this.action === 'view') {
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.viewIons=false;
    }
    this.selectedRectif = 'phase0';
    this.selectedOrder = 'phase0';
    this.roleName = localStorage.getItem('role');
    const userId: any = sessionStorage.getItem('user');
    this.generalInfo.controls.personResp.patchValue(userId);
    
  }

  formInit() {
    this.generalInfo = this.fBuild.group({
      updatedBy: [this.caseData?.updatedBy],
      updateDate: [this.caseData?.updateDate],
      caseId: [this.caseData?.caseId],
      pan: [this.caseData?.pan ? this.caseData?.pan : this.selectedPan],
      panName: [this.caseData?.panName ? this.caseData?.panName : this.selectedPanName],
      ay: [this.caseData?.ay, [Validators.required]],
      finYear: [this.caseData?.finYear, [Validators.required]],
      consultant: [this.caseData?.consultant, Validators.maxLength(100)],
      personResp: [this.caseData?.personResp, [Validators.required]],
      caseLabel: [
        this.caseData?.caseLabel,
        [Validators.required, Validators.minLength(5), Validators.maxLength(125)]
      ],
      caseSummary: [this.caseData?.caseSummary, [Validators.maxLength(1000)]],
      internalRemarks: [this.caseData?.internalRemarks, [Validators.maxLength(1000)]]
    });
    // Intimation/Order
    this.noticeForm = this.fBuild.group({
      orderNo: [this.caseData?.orderNo, [Validators.required]],
      orderDate: [this.caseData?.orderDate, [Validators.required]],
      orderReceiptDate: [this.caseData?.orderReceiptDate, [Validators.required]],
      orderReceiptMode: [this.caseData?.orderReceiptMode],
      noticeType: [this.selectedNoticeType],
      noticeSummary: [this.caseData?.noticeSummary, [Validators.maxLength(1000)]],
      isDeviationRetFiled: [this.caseData?.isDeviationRetFiled, [Validators.required]],
      isDemandRaisedOrReduced1: [this.caseData?.isDemandRaisedOrReduced1],
      demandAmt1: [this.caseData?.demandAmt1],
      status: [this.caseData?.status],
      taxImpactFTotal: [this.caseData?.taxImpactFTotal],
      interestFTotal: [this.caseData?.interestFTotal],
      penaltyFTotal: [this.caseData?.penaltyFTotal],
      taxRateFTotal: [this.caseData?.taxRateFTotal],
      totOfAddnOrDisOfExpensesF: [this.caseData?.totOfAddnOrDisOfExpensesF],
      totOfTotal: [this.caseData?.totOfTotal],
      orderCopyLoc: new UntypedFormArray([]),
      issues: new UntypedFormArray([]),
      issuingAuthority: new UntypedFormArray([])
    });

    this.iTRDetailsForm = this.fBuild.group({
      itrDetails: new UntypedFormArray([])
    });
    this.taxPaymentRefundDetailsForm = this.fBuild.group({
      libDetails1: new UntypedFormArray([]),
      refundDetails1: new UntypedFormArray([])
    });
    this.conclusionForm = this.fBuild.group({
      appealDetails1: new UntypedFormArray([])
    });
    this.rectifForm = this.fBuild.group({
      rectifDetails: new UntypedFormArray([]), // ph2

      isRightsTransToAO: [this.caseData?.isRightsTransToAO],
      officerDetails: new UntypedFormArray([])
    });
    this.consultantForm = this.fBuild.group({
      cc: new UntypedFormArray([])
    });

    this.orderForm = this.fBuild.group({
      rectifDetails1: new UntypedFormArray([]),
      appealDetails3: new UntypedFormArray([]), //ph3
      taxImpactLTotal: [this.caseData?.taxImpactLTotal],
      interestLTotal: [this.caseData?.interestLTotal],
      penaltyLTotal: [this.caseData?.penaltyLTotal],
      demandAmt3: [this.caseData?.demandAmt3],
      taxRateLTotal: [this.caseData?.taxRateLTotal],
      addnOrDisOfExpensesLTotal: [this.caseData?.addnOrDisOfExpensesLTotal],
      totalLTotal: [this.caseData?.totalLTotal],
      isDemandRaisedOrReduced3: [this.caseData?.isDemandRaisedOrReduced3]
    });
  }
  /*Validation */
  p1IssueValidation() {
    if (
      this.noticeForm.controls.isDeviationRetFiled.value == 'true' ||
      this.noticeForm.controls.isDeviationRetFiled.value == true
    ) {
      var issuesArray = this.noticeForm.get('issues') as UntypedFormArray;
      for (
        var issue1Index = 0;
        issue1Index < issuesArray.controls.length;
        issue1Index++
      ) {
        var issuesControl = issuesArray.controls[issue1Index] as UntypedFormGroup;
        if (
          issuesControl.controls.issue.value === null ||
          issuesControl.controls.issue.value === ''
        ) {
          issuesControl.controls.issue.setErrors({ required: true });
        } else {
          issuesControl.controls.issue.setErrors(null);
        }
        if (
          issuesControl.controls.riskParam.value === null ||
          issuesControl.controls.riskParam.value === ''
        ) {
          issuesControl.controls.riskParam.setErrors({ required: true });
        } else {
          issuesControl.controls.riskParam.setErrors(null);
        }
      }
    }
    if (
      this.noticeForm.controls.isDemandRaisedOrReduced1.value == 'true' ||
      this.noticeForm.controls.isDemandRaisedOrReduced1.value == true
    ) {
      if (
        this.noticeForm.controls.demandAmt1.value === null ||
        this.noticeForm.controls.demandAmt1.value === ''
      ) {
        this.noticeForm.controls.demandAmt1.setErrors({ required: true });
      } else {
        this.noticeForm.controls.demandAmt1.setErrors(null);
      }
    }
  }

  p3Validation() {
    if (
      this.orderForm.controls.isDemandRaisedOrReduced3.value == 'true' ||
      this.orderForm.controls.isDemandRaisedOrReduced3.value == true
    ) {
      if (
        this.orderForm.controls.demandAmt3.value === null ||
        this.orderForm.controls.demandAmt3.value === ''
      ) {
        this.orderForm.controls.demandAmt3.setErrors({ required: true });
      } else {
        this.orderForm.controls.demandAmt3.setErrors(null);
      }
    }

    var issuesArray = this.noticeForm.get('issues') as UntypedFormArray;
    if (issuesArray.controls.length > 0) {
      for (var i = 0; i < issuesArray.controls.length; i++) {
        var issues1Control = issuesArray.controls[i] as UntypedFormGroup;
        if (
          issues1Control.controls.status.value === null ||
          issues1Control.controls.status.value === ''
        ) {
          issues1Control.controls.status.setErrors({ required: true });
        } else {
          issues1Control.controls.status.setErrors(null);
        }
      }
    } 
  }

  appealDeailsValidation() {
    if (this.caseData.appealDetails1 != undefined) {
      if (['AP', 'WP', 'CLOSED'].includes(this.caseData.appealDetails1.action)) {
        this.isPhase2 = false;
        this.isPhase3 = false;
      } else if (this.caseData.appealDetails1.action === 'RP') {
        this.isPhase2 = true;
      }
    } else {
      this.isPhase2 = true;
      this.isPhase3 = true;
    }
  }
  phase3Validation() {
    var rectifFormArray = this.rectifForm.controls.rectifDetails as UntypedFormArray;
    if (rectifFormArray.controls.length > 0) {
      var itemListControl = rectifFormArray.controls[0] as UntypedFormGroup;
      if (
        itemListControl.controls.rectifOrderReceived.value == 'true' ||
        itemListControl.controls.rectifOrderReceived.value == true
      ) {
        this.isPhase3 = true;
      } else {
        this.isPhase3 = false;
      }
    }
  }

  phaseRectification(phase: any, i: any) {
    this.selectedRectif = phase;
  }
  phaseOrder(phase: any, i: any) {
    this.selectedOrder = phase;
  }
  /*Validation */
  openNoticeLocPop(content: any) {
    this.addOrder();
    this.modalService.open(content);
  }
  /*orderCopyLoc Array*/
  getFormControlOrder() {
    return (this.noticeForm.get('orderCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getOrderCopyLength() {
    let count = 0;
    let predepositFrmArray = this.noticeForm.get('orderCopyLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return itrcount.length;
  }

  createOrder(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      fileLoc: [data ? data?.fileLoc : null],
      remarks: [data ? data?.remarks : null, []]
    });
  }

  addOrder() {
    let orderCopyListFrmArray = this.noticeForm.get('orderCopyLoc') as UntypedFormArray;
    let i = 0;
    if (orderCopyListFrmArray.length > 0) {
      let length = orderCopyListFrmArray.length;
      while (i < 5 - length) {
        orderCopyListFrmArray.push(this.createOrder());
        i++;
      }
    } else {
      while (i < 5) {
        orderCopyListFrmArray.push(this.createOrder());
        i++;
      }
    }
  }

  rmorderCopyLoc(i: number) {
    (this.noticeForm.get('orderCopyLoc') as UntypedFormArray).removeAt(i);
    this.rmvorderCopyRef.close();
  }
  uploadOrderCopyFormLoc(i: any) {
    this.isUPorderLoc = false;
    this.isDWorderLoc = false;
    this.isorderLocFile = true;
    this.isorderLocText = false;
    let orderCopyArray = this.noticeForm.controls.orderCopyLoc as UntypedFormArray;
    if (orderCopyArray.controls.length > 0) {
      for (let index = 0; index < orderCopyArray.controls.length; index++) {
        if (index === i) {
          let protestDetailsControl = orderCopyArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  deleteUploadFile(i: any) {
    let orderCopyArray = this.noticeForm.controls.orderCopyLoc as UntypedFormArray;
    if (orderCopyArray.controls.length > 0) {
      for (let index = 0; index < orderCopyArray.controls.length; index++) {
        if (index == i) {
          let orderCopyControl = orderCopyArray.controls[index] as UntypedFormGroup;
          orderCopyControl.controls.fileLoc.setValue(null);
          orderCopyControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvorderCopyRef = this.modalService.open(content);
  }
  /*orderCopyLoc Array end*/

  /*Issue Array  */
  issuInvArr(): UntypedFormGroup[] {
    return (this.noticeForm.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  createIssueInvArray(letter: any = null): UntypedFormGroup {
    return this.fBuild.group({
      issue: [letter ? letter.issue : null, []],
      keyword: [letter ? letter.keyword : null, [Validators.maxLength(150)]],
      riskParam: [letter ? letter.riskParam : null, []],
      status: [letter ? letter.status : null, []],
      tag: [letter ? letter.tag : null, []],
      addnOrDisOfExpensesF: [
        letter ? letter.addnOrDisOfExpensesF : null,
        [Validators.pattern(/^-?[0-9,]+$/)]
      ],
      taxRateF: [
        letter ? letter.taxRateF : null,
        [Validators.pattern(/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/)]
      ],
      taxImpactF: [
        letter ? letter.taxImpactF : null,
        [Validators.pattern(/^-?[0-9,]+$/)]
      ],
      interestF: [letter ? letter.interestF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penaltyF: [letter ? letter.penaltyF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      totalF: [letter ? letter.totalF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      remarkF: [letter ? letter.remarkF : null, [Validators.maxLength(100)]],
      // phase 3
      addnOrDisOfExpensesL: [
        letter ? letter.addnOrDisOfExpensesL : null,
        [Validators.pattern(/^-?[0-9,]+$/)]
      ],
      taxRateL: [
        letter ? letter.taxRateL : null,
        [Validators.pattern(/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/)]
      ],
      taxImpactL: [
        letter ? letter.taxImpactL : null,
        [Validators.pattern(/^-?[0-9,]+$/)]
      ],
      interestL: [letter ? letter.interestL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penaltyL: [letter ? letter.penaltyL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      totalL: [letter ? letter.totalL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      remarkL: [letter ? letter.remarkL : null, [Validators.maxLength(100)]],
      provAndLibDetails: new UntypedFormArray([])
    });
  }
  addIssueInv() {
    let issueFrmGrp = this.createIssueInvArray();
    (this.noticeForm.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number, isRemandedBack: boolean = false) {
    (this.noticeForm.get('issues') as UntypedFormArray).removeAt(i);
    if (!isRemandedBack) this.rmvIssueModalRef.close();
    this.issueValueChange();
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }

  isDeviationRetData() {
    var data = this.noticeForm.controls.isDeviationRetFiled.value;
    if (data == 'true') {
      this.showTable = true;
      this.showTable1 = true;
    } else {
      this.showTable = false;
      this.showTable1 = false;
    }
  }

  isRightsTransToAOData() {
    var data = this.rectifForm.controls.isRightsTransToAO.value;
    if (data == 'true') {
      this.showTable = true;
    } else {
      this.showTable = false;
    }
  }

  /*remove letter function*/
  // details array
  // getdetails(div: FormGroup): FormGroup[] {
  //   return (div.get('provAndLibDetails') as FormArray).controls as FormGroup[];
  // }
  // newDetailsFrmGrp(detail: any = null) {
  //   return new FormGroup({
  //     finYear: new FormControl(detail ? detail.finYear : '', []),
  //     quarter: new FormControl(detail ? detail.quarter : '', []),
  //     month: new FormControl(detail ? detail.month : '', []),
  //     provAmtF: new FormControl(detail ? detail.provAmtF : '', []),
  //     contLibAmtF: new FormControl(detail ? detail.contLibAmtF : '', []),
  //     othLibF: new FormControl(detail ? detail.othLibF : '', []),
  //     provChangeReasonF: new FormControl(detail ? detail.provChangeReasonF : '', []),
  //     provNotMadeReasonF: new FormControl(detail ? detail.provNotMadeReasonF : '', []),
  //     contLibReasonF: new FormControl(detail ? detail.contLibReasonF : '', []),
  //     othLibReasonF: new FormControl(detail ? detail.othLibReasonF : '', []),
  //     isConsInCaroF: new FormControl(detail ? detail.isConsInCaroF : '', []),
  //     caroAmtF: new FormControl(detail ? detail.caroAmtF : '', []),
  //   })
  // }
  // addDetail(j: any) {
  //   let issuesFrmArray = this.noticeForm.get('issues') as FormArray;
  //   let detailsFrmArray = issuesFrmArray.controls[j].get('provAndLibDetails') as FormArray;
  //   detailsFrmArray.push(this.newDetailsFrmGrp());
  // }
  // rmDetail(i: number, k: number) {
  //   let issuesFrmArray = this.noticeForm.get('issues') as FormArray;
  //   let detailsFrmArray = issuesFrmArray.controls[i].get('provAndLibDetails') as FormArray;
  //   detailsFrmArray.removeAt(k);
  //   this.rmvDetailModalRef.close();

  // }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  /*Issue end  */

  /*issuingAuthority array */
  getIssuingAuthority(): UntypedFormGroup[] {
    return (this.noticeForm.get('issuingAuthority') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createIssuingAuthorityFrmGrp(issuing: any = null): UntypedFormGroup {
    return this.fBuild.group({
      noticeNo: new UntypedFormControl(issuing ? issuing.noticeNo : '', []),
      officer: new UntypedFormControl(issuing ? issuing.officer : '', []),
      designation: new UntypedFormControl(issuing ? issuing.designation : '', []),
      jurisdiction: new UntypedFormControl(issuing ? issuing.jurisdiction : '', []),
      address: new UntypedFormControl(issuing ? issuing.address : '', []),
      email: new UntypedFormControl(issuing ? issuing.email : '', [
        Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)
      ]),
      contact: new UntypedFormControl(issuing ? issuing.contact : '', [])
    });
  }
  addIssuingAuthority() {
    let issuingAuthorityFrmGrp = this.createIssuingAuthorityFrmGrp();
    (this.noticeForm.get('issuingAuthority') as UntypedFormArray).push(issuingAuthorityFrmGrp);
  }
  rmIssuingAuthorityInv(i: number) {
    (this.noticeForm.get('issuingAuthority') as UntypedFormArray).removeAt(i);
    this.rmvIssuingAuthorityModalRef.close();
  }
  openIssuingAuthorityPop(content: any, i: any) {
    this.rmvIssuingAuthorityModalRef = this.modalService.open(content);
  }
  /*IssuingAuthority array */

  /*itrDetails array */
  getItrDetails(): UntypedFormGroup[] {
    return (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createItrDetailsFrmGrp(details: any = null): UntypedFormGroup {
    return this.fBuild.group({
      returnFiledUs: new UntypedFormControl(details ? details.returnFiledUs : '', []),
      itrForm: new UntypedFormControl(details ? details.itrForm : '', []),
      ackNo: new UntypedFormControl(details ? details.ackNo : '', []),
      filingDate: new UntypedFormControl(details ? details.filingDate : '', []),
      totTaxIncome: new UntypedFormControl(details ? details.totTaxIncome : '', [
        Validators.pattern(/^-?[0-9,]+$/)
      ]),
      totTaxLiability: new UntypedFormControl(details ? details.totTaxLiability : '', [
        Validators.pattern(/^-?[0-9,]+$/)
      ]),
      advanceTax: new UntypedFormControl(details ? details.advanceTax : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      tdsAmount: new UntypedFormControl(details ? details.tdsAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      tcsAmount: new UntypedFormControl(details ? details.tcsAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      selfAssessmentAmount: new UntypedFormControl(details ? details.selfAssessmentAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      totTaxPaid: new UntypedFormControl(details ? details.totTaxPaid : '', [
        Validators.pattern(/^-?[0-9,]+$/)
      ]),
      refundDemand: new UntypedFormControl(details ? details.refundDemand : '', [
        Validators.pattern(/^-?[0-9,]+$/)
      ])
    });
  }
  addItrDetails() {
    let itrDetailsFrmGrp = this.createItrDetailsFrmGrp();
    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).push(itrDetailsFrmGrp);
  }
  rmItrDetailsInv(i: number) {
    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).removeAt(i);
    this.rmvItrDetailsModalRef.close();
  }
  openItrDetailsPop(content: any, i: any) {
    this.rmvItrDetailsModalRef = this.modalService.open(content);
  }
  /*itrDetails end array */

  /*refundDetails1 array */
  getRefundDetails(): UntypedFormGroup[] {
    return (this.taxPaymentRefundDetailsForm.get('refundDetails1') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  createRefundDetailsFrmGrp(data: any = null) {
    return this.fBuild.group({
      receiptDate: [data ? data?.receiptDate : null],
      mode: [data ? data?.mode : null],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]]
    });
  }

  addRefundDetails() {
    let refundDetailsFrmGrp = this.createRefundDetailsFrmGrp();
    (this.taxPaymentRefundDetailsForm.get('refundDetails1') as UntypedFormArray).push(
      refundDetailsFrmGrp
    );
  }

  rmRefundDetailsInv(i: number) {
    (this.taxPaymentRefundDetailsForm.get('refundDetails1') as UntypedFormArray).removeAt(i);
    this.rmvRefundDetailsModalRef.close();
    this.refundDetailsChanged();
  }

  openRefundDetailsPop(content: any, i: any) {
    this.rmvRefundDetailsModalRef = this.modalService.open(content);
  }

  /*refundDetails1 end array */

  /*libDetails1 array */
  getLibDetails(): UntypedFormGroup[] {
    return (this.taxPaymentRefundDetailsForm.get('libDetails1') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  createLibDetailsFrmGrp(data: any = null) {
    return this.fBuild.group({
      cinRefNo: [data ? data?.cinRefNo : null],
      date: [data ? data?.date : null],
      bsrCode: [data ? data?.bsrCode : null],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      challanCopyLoc: new UntypedFormArray([])
    });
  }

  addLibDetails() {
    let liabilityAddmittedAndPaidFrmGrp = this.createLibDetailsFrmGrp();
    (this.taxPaymentRefundDetailsForm.get('libDetails1') as UntypedFormArray).push(
      liabilityAddmittedAndPaidFrmGrp
    );
  }

  rmLibDetailsInv(i: number) {
    (this.taxPaymentRefundDetailsForm.get('libDetails1') as UntypedFormArray).removeAt(i);
    this.rmvLibDetailsModalRef.close();
    this.liabilityAddmittedAndPaidChanged();
  }

  openLibDetailsPop(content: any, i: any) {
    this.rmvLibDetailsModalRef = this.modalService.open(content);
  }

  openLibDetailsGrpPop(content: any, i: any) {
    this.addChallanDiv(i, 0);
    this.modalService.open(content);
  }
  /*libDetails1 end array */

  /*challanCopyLoc array p2*/
  getChallan(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('challanCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getChallanLength(j?: any) {
    let count = 0;
    let libDetailstFrmArray = this.taxPaymentRefundDetailsForm.get(
      'libDetails1'
    ) as UntypedFormArray;
    let challanFrmArray = libDetailstFrmArray.controls[j]?.get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    let itrcount = challanFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return itrcount.length;
  }
  initChallanFrmGrp(data: any = null) {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(data ? data?.fileLoc : null, []),
      remarks: new UntypedFormControl(data ? data?.remarks : null, [])
    });
  }
  addChallanDiv(j: any, i: any) {
    let libDetailstFrmArray = this.taxPaymentRefundDetailsForm.get(
      'libDetails1'
    ) as UntypedFormArray;
    let challanFrmArray = libDetailstFrmArray.controls[j]?.get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    if (challanFrmArray.length > 0) {
      let length = challanFrmArray.length;
      while (i < 5 - length) {
        challanFrmArray.push(this.initChallanFrmGrp());
        i++;
      }
    } else {
      while (i < 5) {
        challanFrmArray.push(this.initChallanFrmGrp());
        i++;
      }
    }
  }
  rmChallanDiv(i: number, k: number) {
    let libDetailstFrmArray = this.taxPaymentRefundDetailsForm.get(
      'libDetails1'
    ) as UntypedFormArray;
    let challanFrmArray = libDetailstFrmArray.controls[i].get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    challanFrmArray.removeAt(k);
    this.rmvChallanFormLocModalRef.close();
  }
  openChallanPop(content: any, i: any) {
    this.rmvChallanFormLocModalRef = this.modalService.open(content);
  }

  uploadChallan(libDetailstIndex: any, judicialIndex: any) {
    this.isSupportingDocFile = true;
    this.isSupportingDocText = false;
    this.isSupportingDocDWText = true;
    this.isSupportingDocUPText = false;
    let libDetailstsArray = this.taxPaymentRefundDetailsForm.controls
      .libDetails1 as UntypedFormGroup;
    let challanFrmArray = libDetailstsArray.controls[libDetailstIndex].get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    if (challanFrmArray.controls.length > 0) {
      for (let index = 0; index < challanFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let libDetailstuploadControl = challanFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true;
          libDetailstuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  deleteChallanCopyFile(libDetailstIndex: any, ChallanIndex: any) {
    let libDetailstsArray = this.taxPaymentRefundDetailsForm.controls
      .libDetails1 as UntypedFormGroup;
    let challanFrmArray = libDetailstsArray.controls[libDetailstIndex].get(
      'challanCopyLoc'
    ) as UntypedFormArray;
    if (challanFrmArray.controls.length > 0) {
      for (let index = 0; index < challanFrmArray.controls.length; index++) {
        if (index === ChallanIndex) {
          let libDetailstuploadControl = challanFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true;
          libDetailstuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  /*challanCopyLoc array */

  /*rectifDetails Array*/
  getFormControlRectif() {
    return (this.rectifForm.get('rectifDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createRectif(letter: any = null) {
    return this.fBuild.group({
      docNo: new UntypedFormControl(letter ? letter.docNo : null),
      rectifDate: new UntypedFormControl(letter ? letter.rectifDate : null, [
        Validators.required
      ]),
      ackNo: new UntypedFormControl(letter ? letter.ackNo : null, [Validators.required]),
      rectifMode: new UntypedFormControl(letter ? letter.rectifMode : null, []),
      rectifOptions: new UntypedFormControl(letter ? letter.rectifOptions : null, [
        Validators.required
      ]),
      rectifReason: new UntypedFormControl(letter ? letter.rectifReason : null, []),
      consultant: new UntypedFormControl(letter ? letter.consultant : null, []),
      rectifOrderReceived: new UntypedFormControl(letter ? letter.rectifOrderReceived : null, [
        Validators.required
      ]),
      personResp: new UntypedFormControl(letter ? letter.personResp : null, [
        Validators.required
      ]),
      internalRemarks: new UntypedFormControl(letter ? letter.internalRemarks : null, [
        Validators.maxLength(1000)
      ]),
      reqDocLoc: new UntypedFormArray([]),
      meetingDetails: new UntypedFormArray([])
    });
  }
  addRectif() {
    let rectificationFrmGrp = this.createRectif();
    (this.rectifForm.get('rectifDetails') as UntypedFormArray).push(rectificationFrmGrp);
    (rectificationFrmGrp.get('meetingDetails') as UntypedFormArray).push(
      this.detailsOfDepartmentFrmGrp()
    );
    this.consultantData();
    this.perResData();
  }

  removeRectif(index: number) {
    (this.rectifForm.get('rectifDetails') as UntypedFormArray).removeAt(index);
    this.selectedRectif = 'phase' + (index - 1);
  }

  // Details of Meeting With Department
  departmentInvArr(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('meetingDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  detailsOfDepartmentFrmGrp(meeting: any = null): UntypedFormGroup {
    return this.fBuild.group({
      dateOfMeeting: new UntypedFormControl(meeting ? meeting.dateOfMeeting : null, []),
      personAttended: new UntypedFormControl(meeting ? meeting.personAttended : null, []),
      internalRemarks: new UntypedFormControl(meeting ? meeting.internalRemarks : null, []),
      copyOfSubmissionLoc: new UntypedFormArray([])
    });
  }

  addDepartmentInv(j: any) {
    let rectifDetailsFrmArray = this.rectifForm.get('rectifDetails') as UntypedFormArray;
    let departmentFrmArray = rectifDetailsFrmArray.controls[j].get(
      'meetingDetails'
    ) as UntypedFormArray;
    departmentFrmArray.push(this.detailsOfDepartmentFrmGrp());
    (departmentFrmArray.get('copyOfSubmissionLoc') as UntypedFormArray).push(
      this.createCopyOfSub()
    );
  }

  rmDepartmentInv(i: number, k: number) {
    let rectifDetailsFrmArray = this.rectifForm.get('rectifDetails') as UntypedFormArray;
    let departmentFrmArray = rectifDetailsFrmArray.controls[i].get(
      'meetingDetails'
    ) as UntypedFormArray;
    departmentFrmArray.removeAt(k);
    this.rmvDepartmentRef.close();
  }
  openRmDepartmentPop(content: any, i: any) {
    this.rmvDepartmentRef = this.modalService.open(content);
  }

  opencopyOfSubPop(content: any, i: any) {
    this.addCopyOfSubDiv(i, 0);
    this.modalService.open(content);
  }

  /*copyOfSubmissionLoc array p2*/
  getCopyOfSub(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('copyOfSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getCopyOfSubLength(
    rectifDetailsIndex: number,
    meetingDetailsFormIndex: number
  ): number {
    const rectifDetailsFrmArray = this.rectifForm.get('rectifDetails') as UntypedFormArray;
    const departmentFrmArray = rectifDetailsFrmArray
      .at(rectifDetailsIndex)
      .get('meetingDetails') as UntypedFormArray;
    const copyOfSubmissionLocArray = departmentFrmArray
      .at(meetingDetailsFormIndex)
      .get('copyOfSubmissionLoc') as UntypedFormArray;
    if (copyOfSubmissionLocArray && copyOfSubmissionLocArray.length > -1) {
      let countOfcopyOfSubmissionLoc = 0;
      for (let i = 0; i < copyOfSubmissionLocArray.length; i++) {
        const singleCopyOfSubmissionLoc = copyOfSubmissionLocArray.value[i];
        if (
          singleCopyOfSubmissionLoc.fileLoc != '' &&
          singleCopyOfSubmissionLoc.fileLoc != null
        ) {
          countOfcopyOfSubmissionLoc++;
        }
      }
      return countOfcopyOfSubmissionLoc;
    }

    return 0;
  }

  createCopyOfSub(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, [])
    });
  }

  addCopyOfSubDiv(j: any, i: any) {
    let rectifDetailsFrmArray: any = this.rectifForm.get('rectifDetails') as UntypedFormArray;
    for (let index = 0; index < rectifDetailsFrmArray.length; index++) {
      const element = rectifDetailsFrmArray.controls[index];
      var departmentFrmArray: any = element.controls.meetingDetails.controls;
      for (let i = 0; i < departmentFrmArray.length; i++) {
        const element1 = departmentFrmArray[i].controls.copyOfSubmissionLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            departmentFrmArray[i].controls.copyOfSubmissionLoc.push(
              this.createCopyOfSub()
            );
          }
        }
      }
    }
  }

  uploadCopyOfSub(
    rectifDetailsIndex: any,
    meetingDetailsIndex: any,
    copyOfSubmissionIndex: any
  ) {
    this.isadjDocsLocFile = true;
    this.isadjDocsLocText = false;
    this.isDWadjDocsLoc = false;
    this.isUPadjDocsLoc = false;
    let rectifDetailsFrmArray = this.rectifForm.controls.rectifDetails as UntypedFormGroup;
    let meetingDetailsArray = rectifDetailsFrmArray.controls[rectifDetailsIndex].get(
      'meetingDetails'
    ) as UntypedFormGroup;
    let copyOfSubmissionLocArray = meetingDetailsArray.controls[
      copyOfSubmissionIndex
    ].get('copyOfSubmissionLoc') as UntypedFormArray;
    if (copyOfSubmissionLocArray.controls.length > 0) {
      for (let index = 0; index < copyOfSubmissionLocArray.controls.length; index++) {
        if (index === meetingDetailsIndex) {
          let itemListuploadControl = copyOfSubmissionLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  deleteCopyOfSubFile(
    rectifDetailsIndex: any,
    meetingDetailsIndex: any,
    copyOfSubmissionIndex: any
  ) {
    let rectifDetailsFrmArray = this.rectifForm.controls.rectifDetails as UntypedFormGroup;
    let meetingDetailsArray = rectifDetailsFrmArray.controls[rectifDetailsIndex].get(
      'meetingDetails'
    ) as UntypedFormGroup;
    let copyOfSubmissionLocArray = meetingDetailsArray.controls[
      copyOfSubmissionIndex
    ].get('copyOfSubmissionLoc') as UntypedFormArray;
    if (copyOfSubmissionLocArray.controls.length > 0) {
      for (let index = 0; index < copyOfSubmissionLocArray.controls.length; index++) {
        if (index === meetingDetailsIndex) {
          let itemListuploadControl = copyOfSubmissionLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  /*copyOfSubmissionLoc array */

  /*ReqDoc Array */
  getReqDoc(noticeLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (noticeLocGrp.get('reqDocLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getReqDocLength(j?: any) {
    let count = 0;
    let rectifDetailsArray = this.rectifForm.get('rectifDetails') as UntypedFormArray;
    let noticeLocFrmArray = rectifDetailsArray.controls[j]?.get('reqDocLoc') as UntypedFormArray;
    let noticecount = noticeLocFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createReqDoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, [])
    });
  }
  addReqDoc(j: any, i: any) {
    let rectifDetailsArray = this.rectifForm.get('rectifDetails') as UntypedFormArray;
    let noticeLocFrmArray = rectifDetailsArray.controls[j].get('reqDocLoc') as UntypedFormArray;
    if (noticeLocFrmArray.length > 0) {
      let length = noticeLocFrmArray.length;
      while (i < 5 - length) {
        noticeLocFrmArray.push(this.createReqDoc());
        i++;
      }
    } else {
      while (i < 5) {
        noticeLocFrmArray.push(this.createReqDoc());
        i++;
      }
    }
  }
  rmReqDoc(i: number, k: number) {
    let rectifDetailsArray = this.rectifForm.get('rectifDetails') as UntypedFormArray;
    let noticeLocFrmArray = rectifDetailsArray.controls[i].get(
      'notreqDocLociceLoc'
    ) as UntypedFormArray;
    noticeLocFrmArray.removeAt(k);
    this.reqDocModalRef.close();
  }

  openReqDocPop(content: any, i: any) {
    this.addReqDoc(i, 0);
    this.modalService.open(content);
  }

  uploadReqDocCopy(noticeListIndex: any, noticeLocIndex: any) {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;

    let rectifDetailsArray = this.rectifForm.controls.rectifDetails as UntypedFormGroup;
    let noticeLocFrmArray = rectifDetailsArray.controls[noticeListIndex].get(
      'reqDocLoc'
    ) as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteReqFile(noticeListIndex: any, noticeLocIndex: any) {
    let rectifDetailsArray = this.rectifForm.controls.rectifDetails as UntypedFormGroup;
    let noticeLocFrmArray = rectifDetailsArray.controls[noticeListIndex].get(
      'reqDocLoc'
    ) as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  /*ReqDoc Array end*/

  /*officerDetails Array*/
  getOfficerDetails() {
    return (this.rectifForm.get('officerDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createOfficerDetails(issuing: any = null) {
    return this.fBuild.group({
      officer: new UntypedFormControl(issuing ? issuing.officer : '', []),
      designation: new UntypedFormControl(issuing ? issuing.designation : '', []),
      jurisdiction: new UntypedFormControl(issuing ? issuing.jurisdiction : '', []),
      address: new UntypedFormControl(issuing ? issuing.address : '', []),
      email: new UntypedFormControl(issuing ? issuing.email : '', [
        Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)
      ]),
      contact: new UntypedFormControl(issuing ? issuing.contact : '', [])
    });
  }
  addOfficerDetails() {
    let officerDetailsFrmGrp = this.createOfficerDetails();
    (this.rectifForm.get('officerDetails') as UntypedFormArray).push(officerDetailsFrmGrp);
  }
  removeOfficerDetails(i: any) {
    (this.rectifForm.get('officerDetails') as UntypedFormArray).removeAt(i);
    this.rmvofficerDetailsModalRef.close();
  }
  openOfficerPop(content: any) {
    this.rmvofficerDetailsModalRef = this.modalService.open(content);
  }

  /*officerDetails array */

  // Consultant Charges Table
  consultantInvArr(): UntypedFormGroup[] {
    return (this.consultantForm.get('cc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  ConsultantCharges(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : null],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      supportingDocsLoc: new UntypedFormArray([]),
      email: [
        data ? data.email : null,
        [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]
      ],
      contact: [data ? data.contact : null]
    });
  }

  addConsultantInv() {
    let detailOfConsultant = this.ConsultantCharges();
    (this.consultantForm.get('cc') as UntypedFormArray).push(detailOfConsultant);
  }

  rmConsultantInv(i: number) {
    (this.consultantForm.get('cc') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.consultantForm.get('cc') as UntypedFormArray).controls.forEach((c: any) => {
      const fees =
        typeof c.value.fees === 'string' ? c.value.fees.replace(/,/g, '') : c.value.fees;
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  openRmvconsultantPop(content: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  openCCDetailPop(content: any, i: any) {
    this.addConsultant(i, 0);
    this.modalService.open(content);
  }

  /*supportingDocsLoc Array */
  getConsultant(noticeLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (noticeLocGrp.get('supportingDocsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getConsultantLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.consultantForm.get('cc') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j]?.get(
      'supportingDocsLoc'
    ) as UntypedFormArray;
    let noticecount = noticeLocFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createConsultant(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, [])
    });
  }
  addConsultant(j: any, i: any) {
    let dtNoticesFrmArray = this.consultantForm.get('cc') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j].get(
      'supportingDocsLoc'
    ) as UntypedFormArray;
    if (noticeLocFrmArray.length > 0) {
      let length = noticeLocFrmArray.length;
      while (i < 5 - length) {
        noticeLocFrmArray.push(this.createConsultant());
        i++;
      }
    } else {
      while (i < 5) {
        noticeLocFrmArray.push(this.createConsultant());
        i++;
      }
    }
  }

  uploadConsultant(noticeListIndex: any, noticeLocIndex: any) {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
    let itemListsArray = this.consultantForm.controls.cc as UntypedFormGroup;
    let noticeLocFrmArray = itemListsArray.controls[noticeListIndex].get(
      'supportingDocsLoc'
    ) as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteConsultant(noticeListIndex: any, noticeLocIndex: any) {
    let noticeArray = this.consultantForm.controls.cc as UntypedFormGroup;
    let noticeLocFrmArray = noticeArray.controls[noticeListIndex].get(
      'supportingDocsLoc'
    ) as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  /*supportingDocsLoc Array end*/

  /*appealDetails1 Array */
  getappealDetail(): UntypedFormGroup[] {
    return (this.conclusionForm.get('appealDetails1') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  appealDetailFrmGrp(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      action: [data ? data.action : null, [Validators.required]],
      forum: [data ? data.forum : null],
      isCaseCreated: ['false']
    });
  }

  addAppealDetail() {
    let detailOfConsultant = this.appealDetailFrmGrp();
    (this.conclusionForm.get('appealDetails1') as UntypedFormArray).push(detailOfConsultant);
  }
  openRemovAppealPop(content: any) {
    this.rmvAppealDetailsRef = this.modalService.open(content);
  }

  rmAppealDetail(i: number) {
    (this.conclusionForm.get('appealDetails1') as UntypedFormArray).removeAt(i);
    this.rmvAppealDetailsRef.close();
  }

  // Conclusion Array end

  // Phase 3
  /*order  data  Array*/
  getOrderData() {
    return (this.orderForm.get('rectifDetails1') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  orderCreate(letter: any = null) {
    return this.fBuild.group({
      orderRefNo: new UntypedFormControl(letter ? letter.orderRefNo : null),
      orderDate: new UntypedFormControl(letter ? letter.orderDate : null),
      orderRecepitDate: new UntypedFormControl(letter ? letter.orderRecepitDate : null),
      orderReceiptMode: new UntypedFormControl(letter ? letter.orderReceiptMode : null),
      orderLoc: new UntypedFormArray([]),
      orderPassedBy: new UntypedFormControl(letter ? letter.orderPassedBy : null),
      outcome: new UntypedFormControl(letter ? letter.outcome : null),
      orderSummary: new UntypedFormControl(letter ? letter.orderSummary : null, [
        Validators.maxLength(1000)
      ]),
      isAppliedForReRectif: new UntypedFormControl(letter ? letter.isAppliedForReRectif : null),
      rectificationIndex: new UntypedFormControl(letter ? letter.rectificationIndex : -1)
    });
  }
  addOrderData(i: string) {
    var reorder: any = this.orderForm.get('rectifDetails1') as UntypedFormArray;
    let orderForm = this.orderCreate();
    if (i) {
      orderForm.controls['rectificationIndex'].setValue(Number(i));
    }
    reorder.push(orderForm);
  }

  removeOrder(index: number) {
    (this.orderForm.get('rectifDetails1') as UntypedFormArray).removeAt(index);
    this.selectedOrder = 'phase' + (index - 1);
  }

  /*order  data  array */

  /*rectifDetails array */

  /*orderLoc array p2*/
  getOrderLoc(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('orderLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getOrderLocength(j?: any) {
    let count = 0;
    let rectifDetailsFrmArray = this.orderForm.get('rectifDetails1') as UntypedFormArray;
    let challanFrmArray = rectifDetailsFrmArray.controls[j]?.get('orderLoc') as UntypedFormArray;
    let itrcount = challanFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return itrcount.length;
  }
  initOrderLocFrmGrp(data: any = null) {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(data ? data?.fileLoc : null, []),
      remarks: new UntypedFormControl(data ? data?.remarks : null, [])
    });
  }
  addOrderLocDiv(j: any, i: any) {
    let rectifDetailsFrmArray = this.orderForm.get('rectifDetails1') as UntypedFormArray;
    let orderFrmArray = rectifDetailsFrmArray.controls[j]?.get('orderLoc') as UntypedFormArray;
    if (orderFrmArray.length > 0) {
      let length = orderFrmArray.length;
      while (i < 5 - length) {
        orderFrmArray.push(this.initOrderLocFrmGrp());
        i++;
      }
    } else {
      while (i < 5) {
        orderFrmArray.push(this.initOrderLocFrmGrp());
        i++;
      }
    }
  }
  rmOrderLocDiv(i: number, k: number) {
    let rectifDetailsFrmArray = this.orderForm.get('rectifDetails1') as UntypedFormArray;
    let orderFrmArray = rectifDetailsFrmArray.controls[i].get('orderLoc') as UntypedFormArray;
    orderFrmArray.removeAt(k);
    this.rmvorderLocModalRef.close();
  }
  openOrderLoc(content: any, i: any) {
    this.rmvorderLocModalRef = this.modalService.open(content);
  }

  uploadOrderLoc(rectifIndex: any, orderLocIndex: any) {
    this.isOrderLocFile = true;
    this.isOrderLocText = false;
    this.isOrderLocDWText = true;
    this.isOrderLocUPText = false;

    let rectifDetailsFrmArray = this.orderForm.controls.rectifDetails1 as UntypedFormGroup;
    let orderFrmArray = rectifDetailsFrmArray.controls[rectifIndex].get(
      'orderLoc'
    ) as UntypedFormArray;
    if (orderFrmArray.controls.length > 0) {
      for (let index = 0; index < orderFrmArray.controls.length; index++) {
        if (index === orderLocIndex) {
          let orderLocstuploadControl = orderFrmArray.controls[index] as UntypedFormGroup;
          orderLocstuploadControl.value['isdocLocUploadedClicked'] = true;
          orderLocstuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  deleteOrderLocFile(rectifIndex: any, orderLocIndex: any) {
    let rectifDetailsFrmArray = this.orderForm.controls.rectifDetails1 as UntypedFormGroup;
    let orderFrmArray = rectifDetailsFrmArray.controls[rectifIndex].get(
      'orderLoc'
    ) as UntypedFormArray;
    if (orderFrmArray.controls.length > 0) {
      for (let index = 0; index < orderFrmArray.controls.length; index++) {
        if (index === orderLocIndex) {
          let orderLocstuploadControl = orderFrmArray.controls[index] as UntypedFormGroup;
          orderLocstuploadControl.value['isdocLocUploadedClicked'] = true;
          orderLocstuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  openOrderGrpPop(content: any, i: any) {
    this.addOrderLocDiv(i, 0);
    this.modalService.open(content);
  }
  /*orderLoc array */

  /*appealDetails3 Array */
  getappealDetail3(): UntypedFormGroup[] {
    return (this.orderForm.get('appealDetails3') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  appealDetail3FrmGrp(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      action: [data ? data.action : null, [Validators.required]],
      forum: [data ? data.forum : null],
      isCaseCreated: ['false']
    });
  }

  addAppealDetail3() {
    let detailOfConsultant = this.appealDetail3FrmGrp();
    (this.orderForm.get('appealDetails3') as UntypedFormArray).push(detailOfConsultant);
  }

  /*  Save api*/
  saveCase() {
    console.log(this.noticeForm, 'generalInfo');

    if (this.noticeForm.valid && this.generalInfo.valid) {
      let noticedata = this.noticeForm.get('issues') as UntypedFormArray;
      let orderCopyedata = this.noticeForm.get('orderCopyLoc') as UntypedFormArray;
      let authoritydata = this.noticeForm.get('issuingAuthority') as UntypedFormArray;
      let itrdata = this.iTRDetailsForm.get('itrDetails') as UntypedFormArray;
      let libDetails1data = this.taxPaymentRefundDetailsForm.get(
        'libDetails1'
      ) as UntypedFormArray;
      let refundDetails1data = this.taxPaymentRefundDetailsForm.get(
        'refundDetails1'
      ) as UntypedFormArray;

      this.inrFormattingRemoving();

      let issueArray: any = [];
      let noticeLocArray: any = [];
      let issuingAuthorityArray: any = [];
      let itrDetailsArray: any = [];
      let libDetails1Array: any = [];
      let refundDetails1Array: any = [];
      let challanCopyArray: any = [];
      let challanCopyData: any = [];

      let issueData: any = {};
      let datanoticeLoc: any = {};
      let dataIssuingAuthority: any = {};
      let dataItrDetails: any = {};
      let dataLibDetails1: any = {};
      let dataRefundDetails1: any = {};
      let challanCopyDetails: any = {};

      noticedata.controls.forEach((element) => {
        issueData = {
          issue: element.value.issue,
          keyword: element.value.keyword,
          riskParam: element.value.riskParam,
          tag: element.value.tag,
          addnOrDisOfExpensesF: element.value.addnOrDisOfExpensesF,
          taxRateF: element.value.taxRateF,
          taxImpactF: element.value.taxImpactF,
          interestF: element.value.interestF,
          penaltyF: element.value.penaltyF,
          totalF: element.value.totalF,
          remarkF: element.value.remarkF,
          provAndLibDetails: element.value.provAndLibDetails
        };
        issueArray.push(issueData);
      });

      orderCopyedata.controls.forEach((element) => {
        if (element.value.fileLoc != '' && element.value.fileLoc != null) {
          datanoticeLoc = {
            fileLoc: element.value.fileLoc,
            remarks: element.value.remarks
          };
          noticeLocArray.push(datanoticeLoc);
        }
      });

      libDetails1data.controls.forEach((element) => {
        challanCopyData = element.get('challanCopyLoc') as UntypedFormArray;
      });
      challanCopyData.controls.forEach((element: any) => {
        if (element.value.fileLoc != '' && element.value.fileLoc != null) {
          challanCopyDetails = {
            fileLoc: element.value.fileLoc,
            remarks: element.value.remarks
          };
          challanCopyArray.push(challanCopyDetails);
        }
      });

      authoritydata.controls.forEach((element) => {
        dataIssuingAuthority = {
          noticeNo: element.value.noticeNo,
          officer: element.value.officer,
          designation: element.value.designation,
          jurisdiction: element.value.jurisdiction,
          address: element.value.address,
          email: element.value.email,
          contact: element.value.contact
        };
        issuingAuthorityArray.push(dataIssuingAuthority);
      });

      itrdata.controls.forEach((element) => {
        if (element.value.itrForm != '' && element.value.itrForm != null) {
          dataItrDetails = {
            returnFiledUs: element.value.returnFiledUs,
            itrForm: element.value.itrForm,
            ackNo: element.value.ackNo,
            filingDate: element.value.filingDate,
            totTaxIncome: element.value.totTaxIncome,
            totTaxLiability: element.value.totTaxLiability,
            advanceTax: element.value.advanceTax,
            tdsAmount: element.value.tdsAmount,
            tcsAmount: element.value.tcsAmount,
            selfAssessmentAmount: element.value.selfAssessmentAmount,
            totTaxPaid: element.value.totTaxPaid,
            refundDemand: element.value.refundDemand
          };
          itrDetailsArray.push(dataItrDetails);
        }
      });

      libDetails1data.controls.forEach((element) => {
        dataLibDetails1 = {
          cinRefNo: element.value.cinRefNo,
          date: element.value.date,
          bsrCode: element.value.bsrCode,
          tax: element.value.tax,
          interest: element.value.interest,
          total: element.value.total,
          challanCopyLoc: challanCopyArray
        };
        libDetails1Array.push(dataLibDetails1);
      });

      refundDetails1data.controls.forEach((element) => {
        challanCopyData = element.get('challanCopyLoc') as UntypedFormArray;
      });
      if (challanCopyData != null && challanCopyData != ' ') {
        challanCopyData.controls.forEach((element: any) => {
          if (element.value.fileLoc != '' && element.value.fileLoc != null) {
            challanCopyDetails = {
              fileLoc: element.value.fileLoc,
              remarks: element.value.remarks
            };
            challanCopyArray.push(challanCopyDetails);
          }
        });
      }

      refundDetails1data.controls.forEach((element) => {
        dataRefundDetails1 = {
          receiptDate: element.value.receiptDate,
          mode: element.value.mode,
          tax: element.value.tax,
          interest: element.value.interest,
          total: element.value.total
        };
        refundDetails1Array.push(dataRefundDetails1);
      });

      this.intimationOrderRequest.phase = 1;
      this.intimationOrderRequest.action = 'CREATE';
      this.intimationOrderRequest.caseType = 'NEW';
      this.intimationOrderRequest.pan = this.generalInfo.controls.pan.value;
      this.intimationOrderRequest.panName = this.generalInfo.controls.panName.value;
      this.intimationOrderRequest.ay = this.generalInfo.controls.ay.value;
      this.intimationOrderRequest.finYear = this.generalInfo.controls.finYear.value;
      this.intimationOrderRequest.consultant = this.generalInfo.controls.consultant.value;
      this.intimationOrderRequest.personResp = this.generalInfo.controls.personResp.value;
      this.intimationOrderRequest.caseSummary =
        this.generalInfo.controls.caseSummary.value;
      this.intimationOrderRequest.internalRemarks =
        this.generalInfo.controls.internalRemarks.value;
      this.intimationOrderRequest.caseLabel = this.generalInfo.controls.caseLabel.value;
      this.intimationOrderRequest.orderNo = this.noticeForm.controls.orderNo.value;
      this.intimationOrderRequest.orderDate = this.noticeForm.controls.orderDate.value;
      this.intimationOrderRequest.orderReceiptDate =
        this.noticeForm.controls.orderReceiptDate.value;
      this.intimationOrderRequest.orderReceiptMode =
        this.noticeForm.controls.orderReceiptMode.value;
      this.intimationOrderRequest.isDeviationRetFiled =
        this.noticeForm.controls.isDeviationRetFiled.value;
      this.intimationOrderRequest.noticeType = this.noticeForm.controls.noticeType.value;
      this.intimationOrderRequest.noticeSummary =
        this.noticeForm.controls.noticeSummary.value;
      this.intimationOrderRequest.isDemandRaisedOrReduced1 =
        this.noticeForm.controls.isDemandRaisedOrReduced1.value;
      this.intimationOrderRequest.demandAmt1 = this.noticeForm.controls.demandAmt1.value;
      this.intimationOrderRequest.taxImpactFTotal =
        this.noticeForm.controls.taxImpactFTotal.value;
      this.intimationOrderRequest.interestFTotal =
        this.noticeForm.controls.interestFTotal.value;
      this.intimationOrderRequest.penaltyFTotal =
        this.noticeForm.controls.penaltyFTotal.value;
      this.intimationOrderRequest.taxRateFTotal =
        this.noticeForm.controls.taxRateFTotal.value;
      this.intimationOrderRequest.totOfTotal = this.noticeForm.controls.totOfTotal.value;
      this.intimationOrderRequest.totOfAddnOrDisOfExpensesF =
        this.noticeForm.controls.totOfAddnOrDisOfExpensesF.value;
      this.intimationOrderRequest.orderCopyLoc = noticeLocArray;
      this.intimationOrderRequest.itrDetails = itrDetailsArray;
      this.intimationOrderRequest.issuingAuthority = issuingAuthorityArray;
      this.intimationOrderRequest.libDetails1 = libDetails1Array;
      this.intimationOrderRequest.refundDetails1 = refundDetails1Array;
      if (
        this.noticeForm.controls.isDeviationRetFiled.value == 'true' ||
        this.noticeForm.controls.isDeviationRetFiled.value == true
      ) {
        this.intimationOrderRequest.issues = issueArray;
      }
      this.taxService
        .CreateIntimation(this.intimationOrderRequest)
        .subscribe((response: any) => {
          if (response.status === 1) {
            this.formatTableValues();
            this.savedCaseId = response.response;
            this.isPhase2 = false;
            this.saveCaseId.emit(this.savedCaseId);
            this.toaster.showSuccess(response.message && this.savedCaseId);
            this.initializeData();
          } else {
            this.toaster.showError(response.message);
          }
        });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  /* Save Api end* */

  /*update Api */
  updateCase(phaseTab: any) {
    if (phaseTab === 1) {
      this.isNextBtnClicked = true;
      this.isSubmitted = true;
      this.isSubmittedAppeal = true;
      this.updateIssues();
    } else if (phaseTab === 2) {
      this.isSubmittedRectif = true;
    } else if (phaseTab === 3) {
      this.isSubmittedOrder = true;
    }

    if (this.noticeForm.valid || this.generalInfo.valid) {
      this.inrFormattingRemoving();
      let finalPayload: any = {};
      const noticeData = this.noticeForm.value;

      // Manipulation For orderCopyLoc
      let orderCopyArray: any = [];
      for (let l = 0; l < noticeData.orderCopyLoc.length; l++) {
        const element = noticeData.orderCopyLoc[l];
        if (element.fileLoc != '' && element.fileLoc != null) {
          const noticeData = {
            fileLoc: element.fileLoc,
            remarks: element.remarks
          };
          orderCopyArray.push(noticeData);
        }
      }
      finalPayload.orderCopyLoc = orderCopyArray;

      // Preparation For issueForm
      if (this.noticeForm.controls.isDeviationRetFiled.value == "true" || this.noticeForm.controls.isDeviationRetFiled.value == true) {
        const issueForm = this.noticeForm.value;
        let checkissueFormNotEmpty = false;
        for (let i = 0; i < issueForm.issues.length; i++) {
          const singleissueForm = issueForm.issues[i];
          if (
            !checkissueFormNotEmpty &&
            (singleissueForm.issue != null ||
              singleissueForm.keyword != null ||
              singleissueForm.tag != null ||
              singleissueForm.riskParam != null ||
              singleissueForm.matterType != null ||
              singleissueForm.remarkF != null ||
              singleissueForm.status != null)
          ) {
            checkissueFormNotEmpty = true;
          }
        }
        if (checkissueFormNotEmpty) {
          finalPayload.issues = issueForm.issues;
        }
      }

      // Preparation For issuingAuthority
      const issuingAuthorityForm = this.noticeForm.value;
      let checkissuingFormNotEmpty = false;
      for (let i = 0; i < issuingAuthorityForm.issuingAuthority.length; i++) {
        const singleIssuingForm = issuingAuthorityForm.issuingAuthority[i];
        if (
          !checkissuingFormNotEmpty &&
          (singleIssuingForm.noticeNo != null ||
            singleIssuingForm.officer != null ||
            singleIssuingForm.designation != null ||
            singleIssuingForm.address != null ||
            singleIssuingForm.email != null ||
            singleIssuingForm.contact != null)
        ) {
          checkissuingFormNotEmpty = true;
        }
      }
      if (checkissuingFormNotEmpty) {
        finalPayload.issuingAuthority = issuingAuthorityForm.issuingAuthority;
      }

      // Preparation For iTRDetailsForm
      const iTRForm = this.iTRDetailsForm.value;
      let checkiTRFormNotEmpty = false;
      for (let i = 0; i < iTRForm.itrDetails.length; i++) {
        const singleiTRForm = iTRForm.itrDetails[i];

        if (
          !checkiTRFormNotEmpty &&
          (singleiTRForm.returnFiledUs != null ||
            singleiTRForm.itrForm != null ||
            singleiTRForm.ackNo != null ||
            singleiTRForm.filingDate != null ||
            singleiTRForm.totTaxIncome != null ||
            singleiTRForm.totTaxLiability != null ||
            singleiTRForm.advanceTax != null ||
            singleiTRForm.tdsAmount != null ||
            singleiTRForm.tcsAmount != null ||
            singleiTRForm.selfAssessmentAmount != null ||
            singleiTRForm.totTaxPaid != null ||
            singleiTRForm.refundDemand != null)
        ) {
          checkiTRFormNotEmpty = true;
        }
      }
      if (checkiTRFormNotEmpty) {
        finalPayload.itrDetails = iTRForm.itrDetails;
      }

      // Preparation For libDetails1
      const libDetails1Form = this.taxPaymentRefundDetailsForm.value;
      let checkLibDetailsFormNotEmpty = false;
      for (let i = 0; i < libDetails1Form.libDetails1.length; i++) {
        const singleLibDetails1Form = libDetails1Form.libDetails1[i];
        // Manipulation For challanCopyLoc
        const challanCopyLocArray = libDetails1Form.libDetails1[i].challanCopyLoc;
        let finalchallanCopyLocArray: any = [];
        for (let j = 0; j < challanCopyLocArray.length; j++) {
          const element = challanCopyLocArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const supportingDocDetails = {
              fileLoc: element.fileLoc,
              remarks: element.remarks
            };
            finalchallanCopyLocArray.push(supportingDocDetails);
          }
        }
        libDetails1Form.libDetails1[i].challanCopyLoc = finalchallanCopyLocArray;
        if (
          !checkLibDetailsFormNotEmpty &&
          (singleLibDetails1Form.cinRefNo != null ||
            singleLibDetails1Form.date != null ||
            singleLibDetails1Form.bsrCode != null ||
            singleLibDetails1Form.tax != null ||
            singleLibDetails1Form.interest != null ||
            singleLibDetails1Form.penalty != null ||
            singleLibDetails1Form.total != null ||
            singleLibDetails1Form.challanCopyLoc.length > 0)
        ) {
          checkLibDetailsFormNotEmpty = true;
        }
      }
      if (checkLibDetailsFormNotEmpty) {
        finalPayload.libDetails1 = libDetails1Form.libDetails1;
      }

      // Preparation For refundDetails1
      const refundDetails1Form = this.taxPaymentRefundDetailsForm.value;
      let checkrefundDetails1FormNotEmpty = false;
      for (let i = 0; i < refundDetails1Form.refundDetails1.length; i++) {
        const singleRefundDetails1Form = refundDetails1Form.refundDetails1[i];

        if (
          !checkrefundDetails1FormNotEmpty &&
          (singleRefundDetails1Form.receiptDate != null ||
            singleRefundDetails1Form.mode != null ||
            singleRefundDetails1Form.tax != null ||
            singleRefundDetails1Form.interest != null ||
            singleRefundDetails1Form.total != null)
        ) {
          checkrefundDetails1FormNotEmpty = true;
        }
      }
      if (checkrefundDetails1FormNotEmpty) {
        finalPayload.refundDetails1 = refundDetails1Form.refundDetails1;
      }

      // Preparation For officerDetails
      const officerDetailsForm = this.rectifForm.value;
      let checkofficerDetailsNotEmpty = false;
      for (let i = 0; i < officerDetailsForm.officerDetails.length; i++) {
        const singleOfficerDetailsForm = officerDetailsForm.officerDetails[i];
        if (
          !checkofficerDetailsNotEmpty &&
          (singleOfficerDetailsForm.officer != null ||
            singleOfficerDetailsForm.address != null ||
            singleOfficerDetailsForm.contact != null ||
            singleOfficerDetailsForm.designation != null ||
            singleOfficerDetailsForm.email != null ||
            singleOfficerDetailsForm.jurisdiction != null)
        ) {
          checkofficerDetailsNotEmpty = true;
        }
      }
      if (checkofficerDetailsNotEmpty) {
        finalPayload.officerDetails = officerDetailsForm.officerDetails;
      }

      // Preparation For CC
      const consultantForm = this.consultantForm.value;
      let checkCCFormNotEmpty = false;
      for (let i = 0; i < consultantForm.cc.length; i++) {
        const singleCcForm = consultantForm.cc[i];

        // Manipulation For supportingDocDetails
        const supportingDocsArray = consultantForm.cc[i].supportingDocsLoc;
        let finalsupportingDocsArray: any = [];
        for (let j = 0; j < supportingDocsArray.length; j++) {
          const element = supportingDocsArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const supportingDocDetails = {
              fileLoc: element.fileLoc,
              remarks: element.remarks
            };
            finalsupportingDocsArray.push(supportingDocDetails);
          }
        }
        consultantForm.cc[i].supportingDocsLoc = finalsupportingDocsArray;
        if (
          !checkCCFormNotEmpty &&
          (singleCcForm.firm != null ||
            singleCcForm.consultant != null ||
            singleCcForm.contact != null ||
            singleCcForm.natureOfWork != null ||
            singleCcForm.email != null ||
            singleCcForm.supportingDocsLoc.length > 0)
        ) {
          checkCCFormNotEmpty = true;
        }
      }
      if (checkCCFormNotEmpty) {
        finalPayload.cc = consultantForm.cc;
      }
      //  appealDetails1 data
      let appealDetails1Data = this.conclusionForm.get('appealDetails1') as UntypedFormArray;
      let appealDetails1Detalis: any = {};
      let appealDetails1Array: any = [];

      appealDetails1Data.controls.forEach((element) => {
        appealDetails1Detalis = {
          action: element.value.action,
          forum: element.value.forum,
          isCaseCreated: element.value.isCaseCreated
        };
        appealDetails1Array.push(appealDetails1Detalis);
      });
      if (this.conclusionForm.controls.appealDetails1.value[0].action != null) {
        finalPayload.appealDetails1 = appealDetails1Detalis;
      }

      //  appealDetails3 data
      let appealDetailsData = this.orderForm.get('appealDetails3') as UntypedFormArray;
      let appealDetailsDetalis: any = {};
      let appealDetailsArray: any = [];

      appealDetailsData.controls.forEach((element) => {
        appealDetailsDetalis = {
          action: element.value.action,
          forum: element.value.forum,
          isCaseCreated: element.value.isCaseCreated
        };
        appealDetailsArray.push(appealDetailsDetalis);
      });
      if (this.orderForm.controls.appealDetails3.value[0].action != null) {
        finalPayload.appealDetails3 = appealDetailsDetalis;
      }

      if (
        this.rectifForm.value.rectifDetails.length > 0 &&
        this.orderForm.value.rectifDetails1.length > 0 &&
        phaseTab === 3
      ) {
        let rectifDetailsArr = [];

        for (let i = 0; i < this.rectifForm.value.rectifDetails.length; i++) {
          const rectifDetails = this.rectifForm.value.rectifDetails[i];

          // Manipulation For reqDocLoc
          const reqDocArray = this.rectifForm.value.rectifDetails[i].reqDocLoc;
          let finalreqDocArrayDetailsArray: any = [];
          for (let j = 0; j < reqDocArray.length; j++) {
            const element = reqDocArray[j];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const reqDocArrayDetails = {
                fileLoc: element.fileLoc,
                remarks: element.remarks
              };
              finalreqDocArrayDetailsArray.push(reqDocArrayDetails);
            }
          }

          this.rectifForm.value.rectifDetails[i].reqDocLoc = finalreqDocArrayDetailsArray;
          // Manupulation For meetingDetails
          const meetingDetailsArray =
            this.rectifForm.value.rectifDetails[i].meetingDetails;

          for (let j = 0; j < meetingDetailsArray.length; j++) {
            const meetingDetailsObj = meetingDetailsArray[j];

            // Manipulation For copyOfSubmissionLoc
            let copyOfSubmissionLocArray: any = [];
            for (let k = 0; k < meetingDetailsObj.copyOfSubmissionLoc.length; k++) {
              const element = meetingDetailsObj.copyOfSubmissionLoc[k];
              if (element.fileLoc != '' && element.fileLoc != null) {
                const copyOfSubmissionLocData = {
                  fileLoc: element.fileLoc,
                  remarks: element.remarks
                };
                copyOfSubmissionLocArray.push(copyOfSubmissionLocData);
              }
            }
            meetingDetailsObj.copyOfSubmissionLoc = copyOfSubmissionLocArray;
          }

          const orderIndex = this.orderForm.value.rectifDetails1.findIndex(
            (ele: any) => ele.rectificationIndex === i
          );
          let finalRectificationObject = {};
          if (orderIndex >= -1) {
            const singleOrder = this.orderForm.value.rectifDetails1[orderIndex];
            // Manipulation For orderLoc
            const orderLocArray = this.orderForm.value.rectifDetails1[i].orderLoc;
            let finalorderLocArrayDetailsArray: any = [];
            for (let j = 0; j < orderLocArray.length; j++) {
              const element = orderLocArray[j];
              if (element.fileLoc != '' && element.fileLoc != null) {
                const orderLocArrayDetails = {
                  fileLoc: element.fileLoc,
                  remarks: element.remarks
                };
                finalorderLocArrayDetailsArray.push(orderLocArrayDetails);
              }
            }

            this.orderForm.value.rectifDetails1[i].orderLoc =
              finalorderLocArrayDetailsArray;

            delete singleOrder.rectificationIndex;
            finalRectificationObject = { ...rectifDetails, ...singleOrder };
          } else {
            finalRectificationObject = rectifDetails;
          }

          rectifDetailsArr.push(finalRectificationObject);
        }

        console.log('rectifDetailsArr: ', rectifDetailsArr);
        finalPayload.rectifDetails = rectifDetailsArr;
      } else {
        const rectifDetailsForm = this.rectifForm.value;
        console.log(rectifDetailsForm, 'rectifDetailsForm');
        for (let i = 0; i < rectifDetailsForm.rectifDetails.length; i++) {
          const rectifDetails = rectifDetailsForm.rectifDetails[i];

          // Manipulation For reqDocLoc
          const reqDocArray = this.rectifForm.value.rectifDetails[i].reqDocLoc;
          let finalreqDocArrayDetailsArray: any = [];
          for (let j = 0; j < reqDocArray.length; j++) {
            const element = reqDocArray[j];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const reqDocArrayDetails = {
                fileLoc: element.fileLoc,
                remarks: element.remarks
              };
              finalreqDocArrayDetailsArray.push(reqDocArrayDetails);
            }
          }

          this.rectifForm.value.rectifDetails[i].reqDocLoc = finalreqDocArrayDetailsArray;
          // Manupulation For meetingDetails
          const meetingDetailsArray =
            this.rectifForm.value.rectifDetails[i].meetingDetails;

          for (let j = 0; j < meetingDetailsArray.length; j++) {
            const meetingDetailsObj = meetingDetailsArray[j];

            // Manipulation For copyOfSubmissionLoc
            let copyOfSubmissionLocArray: any = [];
            for (let k = 0; k < meetingDetailsObj.copyOfSubmissionLoc.length; k++) {
              const element = meetingDetailsObj.copyOfSubmissionLoc[k];
              if (element.fileLoc != '' && element.fileLoc != null) {
                const copyOfSubmissionLocData = {
                  fileLoc: element.fileLoc,
                  remarks: element.remarks
                };
                copyOfSubmissionLocArray.push(copyOfSubmissionLocData);
              }
            }
            meetingDetailsObj.copyOfSubmissionLoc = copyOfSubmissionLocArray;
          }
        }

        console.log('rectifDetailsForm.rectifDetails: ', rectifDetailsForm.rectifDetails);
        finalPayload.rectifDetails = rectifDetailsForm.rectifDetails;
      }
      finalPayload.prevStage = this.caseData.prevStage;
      finalPayload.phase = phaseTab;
      finalPayload.action = 'UPDATE';
      finalPayload.caseType = 'NEW';
      finalPayload.pan = this.generalInfo.controls.pan.value;
      finalPayload.panName = this.generalInfo.controls.panName.value;
      finalPayload.caseId = this.generalInfo.controls.caseId.value;
      finalPayload.ay = this.generalInfo.controls.ay.value;
      finalPayload.finYear = this.generalInfo.controls.finYear.value;
      finalPayload.consultant = this.generalInfo.controls.consultant.value;
      finalPayload.personResp = this.generalInfo.controls.personResp.value;
      finalPayload.caseSummary = this.generalInfo.controls.caseSummary.value;
      finalPayload.internalRemarks = this.generalInfo.controls.internalRemarks.value;
      finalPayload.caseLabel = this.generalInfo.controls.caseLabel.value;
      finalPayload.status = this.noticeForm.controls.status.value;
      finalPayload.orderNo = this.noticeForm.controls.orderNo.value;
      finalPayload.orderDate = this.noticeForm.controls.orderDate.value;
      finalPayload.orderReceiptDate = this.noticeForm.controls.orderReceiptDate.value;
      finalPayload.orderReceiptMode = this.noticeForm.controls.orderReceiptMode.value;
      finalPayload.isDeviationRetFiled = this.noticeForm.controls.isDeviationRetFiled.value;
      finalPayload.noticeType = this.noticeForm.controls.noticeType.value;
      finalPayload.noticeSummary = this.noticeForm.controls.noticeSummary.value;
      finalPayload.isDemandRaisedOrReduced1 =
        this.noticeForm.controls.isDemandRaisedOrReduced1.value;
      finalPayload.demandAmt1 = this.noticeForm.controls.demandAmt1.value;
      finalPayload.taxImpactFTotal = this.noticeForm.controls.taxImpactFTotal.value;
      finalPayload.interestFTotal = this.noticeForm.controls.interestFTotal.value;
      finalPayload.penaltyFTotal = this.noticeForm.controls.penaltyFTotal.value;
      finalPayload.taxRateFTotal = this.noticeForm.controls.taxRateFTotal.value;
      finalPayload.totOfTotal = this.noticeForm.controls.totOfTotal.value;
      finalPayload.totOfAddnOrDisOfExpensesF =
        this.noticeForm.controls.totOfAddnOrDisOfExpensesF.value;
      finalPayload.taxImpactLTotal = this.orderForm.controls.taxImpactLTotal.value;
      finalPayload.interestLTotal = this.orderForm.controls.interestLTotal.value;
      finalPayload.penaltyLTotal = this.orderForm.controls.penaltyLTotal.value;
      finalPayload.taxRateLTotal = this.orderForm.controls.taxRateLTotal.value;
      finalPayload.addnOrDisOfExpensesLTotal =
        this.orderForm.controls.addnOrDisOfExpensesLTotal.value;
      finalPayload.totalLTotal = this.orderForm.controls.totalLTotal.value;
      finalPayload.isDemandRaisedOrReduced3 =
        this.orderForm.controls.isDemandRaisedOrReduced3.value;
      finalPayload.demandAmt3 = this.orderForm.controls.demandAmt3.value;
      finalPayload.isRightsTransToAO = this.rectifForm.controls.isRightsTransToAO.value;

      console.log(finalPayload, 'finalPayload');
      this.taxService.updateIntorder(finalPayload).subscribe((response: any) => {
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 1) {
            this.isNextBtnClicked = false;
            this.isSubmitted = false;
            this.isSubmittedAppeal = false;
            const appealDetails1 = this.conclusionForm.controls
              .appealDetails1 as UntypedFormArray;
            for (let index = 0; index < appealDetails1.controls.length; index++) {
              const element: any = appealDetails1.controls[index];
              if (['AP', 'WP', 'CLOSED'].includes(element.controls.action.value)) {
                this.isPhase2 = false;
                this.isPhase3 = false;
              }
              this.isPhase2 = element.controls.action.value === 'RP';
              if (element.controls.action.value === 'AP') this.callTabFunction.emit('');
              if (element.controls.action.value === 'WP') this.moveToHC.emit('');
            }
          } else if (phaseTab === 2) {
            var rectifFormArray = this.rectifForm.controls.rectifDetails as UntypedFormArray;
            var itemListControl = rectifFormArray.controls[0] as UntypedFormGroup;
            if (
              itemListControl.controls.rectifOrderReceived.value == 'true' ||
              itemListControl.controls.rectifOrderReceived.value == true
            ) {
              this.isPhase3 = true;
            } else {
              this.isPhase3 = false;
            }
          } else if (phaseTab === 3) {
            const appealDetails3Data = this.orderForm.controls
              .appealDetails3 as UntypedFormArray;
            for (let index = 0; index < appealDetails3Data.controls.length; index++) {
              const element: any = appealDetails3Data.controls[index];
              if (
                element.controls.action.value == 'AP' &&
                element.controls.forum.value == 'dtcommissioner'
              ) {
                this.callTabFunction.emit('');
              } else if (
                element.controls.action.value == 'WP' &&
                element.controls.forum.value == 'dthc'
              ) {
                this.moveToHC.emit('');
              } else if (
                element.controls.action.value == 'CLOSED' &&
                element.controls.forum.value == 'CLOSED'
              ) {
              }
            }
          }
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }
  /* Update api end */

  /*  fetch api*/
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        caseId: this.caseId
      };
      this.taxService.getGetIntimationcase(model).subscribe((response: any) => {
        if (response.status === 1) {
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.caseData = response.response;
          this.cumulativeData = response.response.cumulativeTotals;
          if(this.cumulativeData.length > 0){
            this.cumulativeData[1].tax += this.cumulativeData[0].tax;
            this.cumulativeData[1].interest += this.cumulativeData[0].interest;
            this.cumulativeData[1].total += this.cumulativeData[0].total;
            this.cumulativeData.shift();
          }
          const indexToUpdate = this.cumulativeData.findIndex((obj: { label: string; }) => obj.label === "Cumulative payment made for admitted liability");
          if (indexToUpdate !== -1) {
                this.cumulativeData[indexToUpdate].label = "Cumulative payment made for assessed tax";
          }
          if (this.caseData.isDeviationRetFiled == true) {
            this.showTable = true;
            this.showTable1 = true;
          }
          this.prevStage = this.caseData.prevStage;
          this.formInit();

          if (this.caseData.libDetails1 && this.caseData.libDetails1.length) {
            this.caseData.libDetails1.forEach((libDetails: any) => {
              libDetails.tax = this.inrService.formatCurrency(String(libDetails.tax));
              libDetails.interest = this.inrService.formatCurrency(
                String(libDetails.interest)
              );
              libDetails.total = this.inrService.formatCurrency(String(libDetails.total));
            });
          }

          if (this.caseData.refundDetails1 && this.caseData.refundDetails1.length) {
            this.caseData.refundDetails1.forEach((refundDetails: any) => {
              refundDetails.tax = this.inrService.formatCurrency(
                String(refundDetails.tax)
              );
              refundDetails.interest = this.inrService.formatCurrency(
                String(refundDetails.interest)
              );
              refundDetails.total = this.inrService.formatCurrency(
                String(refundDetails.total)
              );
            });
          }
          if (this.caseData.cc && this.caseData.cc.length) {
            this.caseData.cc.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees));
            });
          }

          if (this.caseData.itrDetails && this.caseData.itrDetails.length) {
            this.caseData.itrDetails.forEach((itrDetails: any) => {
              itrDetails.totTaxIncome = this.inrService.formatCurrency(
                String(itrDetails.totTaxIncome)
              );
              itrDetails.totTaxLiability = this.inrService.formatCurrency(
                String(itrDetails.totTaxLiability)
              );
              itrDetails.advanceTax = this.inrService.formatCurrency(String(itrDetails.advanceTax))
              itrDetails.tdsAmount = this.inrService.formatCurrency(String(itrDetails.tdsAmount))
              itrDetails.tcsAmount = this.inrService.formatCurrency(String(itrDetails.tcsAmount))
              itrDetails.selfAssessmentAmount = this.inrService.formatCurrency(String(itrDetails.selfAssessmentAmount))
              itrDetails.totTaxPaid = this.inrService.formatCurrency(
                String(itrDetails.totTaxPaid)
              );
              itrDetails.refundDemand = this.inrService.formatCurrency(
                String(itrDetails.refundDemand)
              );
            });
          }

          if (this.caseData.issues && this.caseData.issues.length) {
            this.caseData.issues.forEach((letter: any) => {
              letter.addnOrDisOfExpensesF = this.inrService.formatCurrency(
                String(letter.addnOrDisOfExpensesF)
              );
              // letter.taxRateF = this.inrService.formatCurrency(String(letter.taxRateF))
              letter.taxImpactF = this.inrService.formatCurrency(
                String(letter.taxImpactF)
              );
              letter.interestF = this.inrService.formatCurrency(String(letter.interestF));
              letter.penaltyF = this.inrService.formatCurrency(String(letter.penaltyF));
              letter.totalF = this.inrService.formatCurrency(String(letter.totalF));

              letter.addnOrDisOfExpensesL = this.inrService.formatCurrency(
                String(letter.addnOrDisOfExpensesL)
              );
              // letter.taxRateL = this.inrService.formatCurrency(String(letter.taxRateL))
              letter.taxImpactL = this.inrService.formatCurrency(
                String(letter.taxImpactL)
              );
              letter.interestL = this.inrService.formatCurrency(String(letter.interestL));
              letter.penaltyL = this.inrService.formatCurrency(String(letter.penaltyL));
              letter.totalL = this.inrService.formatCurrency(String(letter.totalL));
            });
          }
          this.caseData.demandAmt1 = this.inrService.formatCurrency(
            String(this.caseData.demandAmt1)
          );

          this.caseData.demandAmt3 = this.inrService.formatCurrency(
            String(this.caseData.demandAmt3)
          );

          this.isVisibleCaseId = true;

          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList(this.caseData);
          } else {
            this.addIssueInv();
          }

          if (this.caseData.itrDetails && this.caseData.itrDetails.length > 0) {
            this.initializeItrDetails();
          } else {
            this.addItrDetails();
          }

          if (
            this.caseData.issuingAuthority &&
            this.caseData.issuingAuthority.length > 0
          ) {
            this.initializeIssuingAuthoritys();
          } else {
            this.addIssuingAuthority();
          }

          if (this.caseData.libDetails1 && this.caseData.libDetails1.length > 0) {
            this.initializeLibDetails(this.caseData);
          } else {
            this.addLibDetails();
          }

          if (this.caseData.refundDetails1 && this.caseData.refundDetails1.length > 0) {
            this.initializeRefundDetails();
          } else {
            this.addRefundDetails();
          }

          if (this.caseData.orderCopyLoc && this.caseData.orderCopyLoc.length > 0) {
            this.initializeOrderCopy();
          } else {
            // this.addOrder(i);
          }
          if (this.caseData.appealDetails1) {
            this.initializeAppealDetails();
          } else {
            this.addAppealDetail();
          }
          if (this.caseData.appealDetails3) {
            this.initializeAppealDetails3();
          } else {
            this.addAppealDetail3();
          }

          if (this.caseData.officerDetails && this.caseData.officerDetails.length > 0) {
            this.initializeIOfficalDetalis();
          } else {
            this.addOfficerDetails();
          }
          if (this.caseData.cc && this.caseData.cc.length > 0) {
            this.initializeConsultantCharges();
          } else {
            this.addConsultantInv();
          }

          this.caseData.rectifDetails1 = [];
          if (this.caseData.rectifDetails && this.caseData.rectifDetails.length > 0) {
            for (let i = 0; i < this.caseData.rectifDetails.length; i++) {
              const rectifDetails = this.caseData.rectifDetails[i];
              let orderRecti: any = {};
              if (rectifDetails.orderRefNo && rectifDetails.orderRefNo !== '') {
                orderRecti.orderRefNo = rectifDetails.orderRefNo;
                orderRecti.orderDate = rectifDetails.orderDate;
                orderRecti.orderRecepitDate = rectifDetails.orderRecepitDate;
                orderRecti.orderReceiptMode = rectifDetails.orderReceiptMode;
                orderRecti.orderLoc = rectifDetails.orderLoc;
                orderRecti.orderPassedBy = rectifDetails.orderPassedBy;
                orderRecti.outcome = rectifDetails.outcome;
                orderRecti.orderSummary = rectifDetails.orderSummary;
                orderRecti.isAppliedForReRectif = rectifDetails.isAppliedForReRectif;
                orderRecti.rectificationIndex = i;

                delete rectifDetails.orderRefNo;
                delete rectifDetails.orderDate;
                delete rectifDetails.orderRecepitDate;
                delete rectifDetails.orderReceiptMode;
                delete rectifDetails.orderLoc;
                delete rectifDetails.orderPassedBy;
                delete rectifDetails.outcome;
                delete rectifDetails.orderSummary;
                delete rectifDetails.isAppliedForReRectif;

                this.caseData.rectifDetails1.push(orderRecti);
              }
            }
            console.log('rectifDetails: ', this.caseData.rectifDetails);
            console.log('rectifDetails1: ', this.caseData.rectifDetails1);

            this.initializeRectif();
          } else {
            this.addRectif();
          }

          if (this.caseData.rectifDetails1 && this.caseData.rectifDetails1.length > 0) {
            this.initializeRectifDetails1Details();
          } else {
            // this.addOrderData('')
          }

          this.liabilityAddmittedAndPaidChanged();
          this.refundDetailsChanged();
          this.feesChargedChanged();
          this.appealDeailsValidation();
          this.phase3Validation();
        }
      });
    }
  }

  //initialise issue array
  initializeIssueList(data: any) {
    if (data.issues != undefined) {
      data.issues.forEach((letter: any) => {
        if (data.issues.length > 0) {
          let issuesFrmGrp: UntypedFormGroup = this.createIssueInvArray(letter);
          (this.noticeForm.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        }
      });
    }
    this.noticeForm.patchValue(data);
    this.issueValueChange();
  }

  //  initialise orderCopyLoc array
  initializeOrderCopy() {
    if (this.caseData.orderCopyLoc != undefined) {
      this.caseData.orderCopyLoc.forEach((data: any) => {
        let orderCopyFrmGrp = this.createOrder(data);
        (this.noticeForm.get('orderCopyLoc') as UntypedFormArray).push(orderCopyFrmGrp);
      });
    }
  }

  //  initialise appealDetails1 array
  initializeAppealDetails() {
    if (this.caseData.appealDetails1 != undefined) {
      let appealDetailFrmGrp = this.appealDetailFrmGrp(this.caseData.appealDetails1);
      (this.conclusionForm.get('appealDetails1') as UntypedFormArray).push(appealDetailFrmGrp);
    }
  }

  //  initialise appealDetails3 array
  initializeAppealDetails3() {
    if (this.caseData.appealDetails3 != undefined) {
      let appealDetail3FrmGrp = this.appealDetail3FrmGrp(this.caseData.appealDetails3);
      (this.orderForm.get('appealDetails3') as UntypedFormArray).push(appealDetail3FrmGrp);
    }
  }

  //  initialise rectifDetails array
  initializeRectif() {
    if (this.caseData.rectifDetails) {
      this.caseData.rectifDetails.forEach((data: any) => {
        let rectifDetailsFrmGrp = this.createRectif(data);
        (this.rectifForm.get('rectifDetails') as UntypedFormArray).push(rectifDetailsFrmGrp);
        if (data.reqDocLoc != undefined) {
          data.reqDocLoc.forEach((not: any) => {
            if (data.reqDocLoc.length > 0) {
              let reqDocFrmGrp: UntypedFormGroup = this.createReqDoc(not);
              (rectifDetailsFrmGrp.get('reqDocLoc') as UntypedFormArray).push(reqDocFrmGrp);
            }
          });
          const leftReqDocLoc =
            5 - (rectifDetailsFrmGrp.get('reqDocLoc') as UntypedFormArray).length;
          for (let i = 0; i < leftReqDocLoc; i++) {
            (rectifDetailsFrmGrp.get('reqDocLoc') as UntypedFormArray).push(this.createReqDoc());
          }
        }
        if (data.meetingDetails && data.meetingDetails.length > 0) {
          data.meetingDetails.forEach((meeting: any) => {
            let meetingDetailFrmGrp: UntypedFormGroup = this.detailsOfDepartmentFrmGrp(meeting);
            (rectifDetailsFrmGrp.get('meetingDetails') as UntypedFormArray).push(
              meetingDetailFrmGrp
            );

            if (meeting.copyOfSubmissionLoc) {
              meeting.copyOfSubmissionLoc.forEach((docSub: any) => {
                let copyOfSubmissionLocGrp: UntypedFormGroup = this.createCopyOfSub(docSub);
                (meetingDetailFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).push(
                  copyOfSubmissionLocGrp
                );
              });
              const leftcopyOfSubmissionLoc =
                5 - (meetingDetailFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).length;
              for (let i = 0; i < leftcopyOfSubmissionLoc; i++) {
                (meetingDetailFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).push(
                  this.createCopyOfSub()
                );
              }
            }
          });
        } else {
          (rectifDetailsFrmGrp.get('meetingDetails') as UntypedFormArray).push(
            this.detailsOfDepartmentFrmGrp()
          );
        }
      });
    }
  }

  //  initialise rectifDetails1 array
  initializeRectifDetails1Details() {
    if (this.caseData.rectifDetails1) {
      this.caseData.rectifDetails1.forEach((data: any) => {
        let rectifDetails1FrmGrp: UntypedFormGroup = this.orderCreate(data);
        (this.orderForm.get('rectifDetails1') as UntypedFormArray).push(rectifDetails1FrmGrp);
        if (data.orderLoc != undefined) {
          data.orderLoc.forEach((data: any) => {
            let orderCopyLocFrmGrp: UntypedFormGroup = this.initOrderLocFrmGrp(data);
            (rectifDetails1FrmGrp.get('orderLoc') as UntypedFormArray).push(orderCopyLocFrmGrp);
          });
          const leftReqDocLoc =
            5 - (rectifDetails1FrmGrp.get('orderLoc') as UntypedFormArray).length;
          for (let i = 0; i < leftReqDocLoc; i++) {
            (rectifDetails1FrmGrp.get('orderLoc') as UntypedFormArray).push(
              this.initOrderLocFrmGrp()
            );
          }
        }
      });
    }
  }

  //  initialise issuingAuthority array
  initializeIssuingAuthoritys() {
    if (this.caseData.issuingAuthority != undefined) {
      this.caseData.issuingAuthority.forEach((issuing: any) => {
        let issuingAuthorityFrmGrp = this.createIssuingAuthorityFrmGrp(issuing);
        (this.noticeForm.get('issuingAuthority') as UntypedFormArray).push(
          issuingAuthorityFrmGrp
        );
      });
    }
  }

  //  initialise itrDetails array
  initializeItrDetails() {
    if (this.caseData.itrDetails != undefined) {
      this.caseData.itrDetails.forEach((details: any) => {
        let itrDetailsFrmGrp = this.createItrDetailsFrmGrp(details);
        (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).push(itrDetailsFrmGrp);
      });
    }
  }

  //  initialise officerDetails array
  initializeIOfficalDetalis() {
    if (this.caseData.officerDetails != undefined) {
      this.caseData.officerDetails.forEach((issuing: any) => {
        let officerDetailsFrmGrp = this.createOfficerDetails(issuing);
        (this.rectifForm.get('officerDetails') as UntypedFormArray).push(officerDetailsFrmGrp);
      });
    }
  }
  //  initialise cc array
  initializeConsultantCharges() {
    if (this.caseData.cc != undefined) {
      this.caseData.cc.forEach((data: any) => {
        let detailOfConsultant = this.ConsultantCharges(data);
        (this.consultantForm.get('cc') as UntypedFormArray).push(detailOfConsultant);
        if (data.supportingDocsLoc != undefined) {
          data.supportingDocsLoc.forEach((not: any) => {
            if (data.supportingDocsLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createConsultant(not);
              (detailOfConsultant.get('supportingDocsLoc') as UntypedFormArray).push(
                detailFrmGrp
              );
            }
          });
        }
      });
      this.feesChargedChanged();
    }
  }

  //  initialise libDetails1 array
  initializeLibDetails(data: any) {
    if (data.libDetails1 != undefined) {
      data.libDetails1.forEach((data: any) => {
        let libDetails1FrmGrp: UntypedFormGroup = this.createLibDetailsFrmGrp(data);
        (this.taxPaymentRefundDetailsForm.get('libDetails1') as UntypedFormArray).push(
          libDetails1FrmGrp
        );
        if (data.challanCopyLoc != undefined) {
          data.challanCopyLoc.forEach((locList: any) => {
            if (data.challanCopyLoc.length > 0) {
              let challanCopyLFrmGrp: UntypedFormGroup = this.initChallanFrmGrp(locList);
              (libDetails1FrmGrp.get('challanCopyLoc') as UntypedFormArray).push(
                challanCopyLFrmGrp
              );
            }
          });
        }
      });
    }
    this.taxPaymentRefundDetailsForm.patchValue(data);
    this.liabilityAddmittedAndPaidChanged();
  }

  //  initialise refundDetails1 array

  initializeRefundDetails() {
    if (this.caseData.refundDetails1 != undefined) {
      this.caseData.refundDetails1.forEach((data: any) => {
        let refundDetailsFrmGrp = this.createRefundDetailsFrmGrp(data);
        (this.taxPaymentRefundDetailsForm.get('refundDetails1') as UntypedFormArray).push(
          refundDetailsFrmGrp
        );
      });
    }
  }

  onFileSelect(event: any, identifier?: any) {
    this.selectedField = event.target.getAttribute('id');
    if (event.target.files[0] !== undefined) {
      if (
        this.checkExtensionFunction(event.target.files[0], [
          'csv',
          'zip',
          'pdf',
          'xlsx',
          'rar',
          'doc',
          'docx',
          'pptx',
          'xlsb',
          'png',
          'jpg',
          'jpeg',
          'msg'
        ])
      ) {
        this.selectedFile = event.target.files[0];
        console.log(this.selectedFile, 'this.selectedFile');
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, identifier);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported');
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }

  onUpload(selectedfield: any, identifier?: any) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'NEW',
      field: selectedfield,
      contentType: this.selectedFile.type
    };
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(
          response.response.url,
          response.response.path,
          selectedfield,
          identifier
        );
      } else {
      }
    });
  }

  getFileUrl(url: any, path: any, selectedfield: any, identifier?: string) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      var toupdate = this.noticeForm.value;
      var toTaxUpdate = this.taxPaymentRefundDetailsForm.value;
      var toRectifUpdate = this.rectifForm.value;
      var updateConsultant = this.consultantForm.value;
      var updateOrderData = this.orderForm.value;

      if (identifier == 'notice') {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.noticeForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toupdate = toupdate[pathToVeriabl[pathIndex]];
            } else {
              toupdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.noticeForm.patchValue(this.noticeForm.value);
      } else if (identifier == 'tax') {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.taxPaymentRefundDetailsForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toTaxUpdate = toTaxUpdate[pathToVeriabl[pathIndex]];
            } else {
              toTaxUpdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.taxPaymentRefundDetailsForm.patchValue(
          this.taxPaymentRefundDetailsForm.value
        );
      } else if (identifier == 'orderData') {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.orderForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              updateOrderData = updateOrderData[pathToVeriabl[pathIndex]];
            } else {
              updateOrderData[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.orderForm.patchValue(this.orderForm.value);
      } else if (identifier == 'rectification') {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.rectifForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toRectifUpdate = toRectifUpdate[pathToVeriabl[pathIndex]];
            } else {
              toRectifUpdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.rectifForm.patchValue(this.rectifForm.value);
      } else if (identifier == 'consultant') {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.consultantForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              updateConsultant = updateConsultant[pathToVeriabl[pathIndex]];
            } else {
              updateConsultant[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.consultantForm.patchValue(this.consultantForm.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    });
  }

  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (
      let splitedPathIndex = 0;
      splitedPathIndex < splitedPath.length - 1;
      splitedPathIndex++
    ) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  checkExtensionFunction(selectedFile: { name: string }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  /*upload function */

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl) return this.taxService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    };
    this.taxService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    });
  }
  /* get download file path function */

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('provAndLibDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    });
  }
  openTextBoxModal1(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (this.noticeForm.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    });
  }

  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
  }

  issueDropdown() {
    this.dataSource = DT_ISSUE_DROPDOWN;
  }

  personResponsibleData() {
    let model = {
      companyid: this.selectedCompanyId
    };
    this.taxService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }
  onClickToggleDropdownPerResp2(){
    this.isToggleDropdownPerResp2 = !this.isToggleDropdownPerResp2;
  }
  

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');

        // Check if userMailID exists in consultantList
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );
        if (matchingConsultant) {
          this.generalInfo.controls.consultant.patchValue(matchingConsultant.consultantName);
          const rectifDetailsArray = this.rectifForm.controls.rectifDetails as UntypedFormArray
          rectifDetailsArray.controls.forEach((group: any) => {
            group.controls.consultant.patchValue(matchingConsultant.consultantName);
          });
        }
      }
    });
  }
  perResData(){
  const rectifDetailsArray = this.rectifForm.controls.rectifDetails as UntypedFormArray
    rectifDetailsArray.controls.forEach((group: any) => {
      group.controls.personResp.patchValue(sessionStorage.getItem('user'));
    });
  }  
  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC'){
      this.isToggleConsultantDropdownCC = !this.isToggleConsultantDropdownCC;
    }
  }
 
  onClickToggleSegmentDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleSegmentDropdown = !this.isToggleSegmentDropdown;
    } else if (phase === 'p2') {
      this.isToggleSegmentDropdown2 = !this.isToggleSegmentDropdown2;
    }
  }

  changeadOrder(event: any, i: any) {
    if (event.target.value === "true" || event.target.value === true) {
      this.addOrderData(i.toString());
      this.selectedOrder = 'phase' + (i);
    } else if (event.target.value === "false" || event.target.value === false) {
      this.removeOrder(i);
    }
  }
  openOrderDataPop(content: any, event: any, i: any) {
    this.modalService.open(content);
    this.changeadOrder(event, i);
  }
  setDate() {
    this.currentYear = new Date().getFullYear() + 1;
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2001;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push(financialYrRange);
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
    this.finyearData = this.finyearData.map(yearRange => {
      const [start, end] = yearRange.split('-').map(Number);
      return `${start + 1}-${end + 1}`;
    });
  }

  getYearData() {
    var assYear = this.generalInfo.controls.ay.value;
    const dashIndex = assYear.indexOf('-');
    if (dashIndex !== -1) {
      var years = assYear.substring(0, dashIndex);
    }
    var convertToInt = parseInt(years);
    var year = convertToInt - 1;
    var financialYear = year + '-' + years;
    this.generalInfo.controls.finYear.patchValue(financialYear);
  }
  getperiodToValue(content: any){
    this.getYearData();
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = "7437",
    model.module = "DT",
    model.pan = this.selectedPan,
    model.criterias= [
      {
          "p": "ay",
          "o": "eq",
          "v": this.generalInfo.value.ay,
      }
  ]
  this.taxService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/directTaxSummary']);
    this.modalService.dismissAll();
  }

  setForumOfAppeal() {
    this.forumAppeal = FORUM_ENQUIRY_PROCEED;
    const appealDetails1 = this.conclusionForm.controls.appealDetails1 as UntypedFormArray;
    for (let index = 0; index < appealDetails1.controls.length; index++) {
      const element: any = appealDetails1.controls[index];
      if (element.controls.action.value == 'AP') {
        element.controls.forum.setValue(this.forumAppeal[0].value);
      } else if (element.controls.action.value == 'WP') {
        element.controls.forum.setValue(this.forumAppeal[1].value);
      } else if (element.controls.action.value == 'CLOSED') {
        element.controls.forum.setValue(this.forumAppeal[2].value);
      } else if (element.controls.action.value == 'RP') {
        element.controls.forum.setValue(this.forumAppeal[3].value);
      }
    }
  }

  setForumOfAppeal3() {
    this.forumAppeal = FORUM_ENQUIRY_PROCEED;
    const appealDetails3 = this.orderForm.controls.appealDetails3 as UntypedFormArray;
    for (let index = 0; index < appealDetails3.controls.length; index++) {
      const element: any = appealDetails3.controls[index];
      if (element.controls.action.value == 'AP') {
        element.controls.forum.setValue(this.forumAppeal[0].value);
      } else if (element.controls.action.value == 'WP') {
        element.controls.forum.setValue(this.forumAppeal[1].value);
      } else if (element.controls.action.value == 'CLOSED') {
        element.controls.forum.setValue(this.forumAppeal[2].value);
      } else if (element.controls.action.value == 'RRP') {
        element.controls.forum.setValue(this.forumAppeal[3].value);
      }
    }
  }

  updateIssues() {
    (this.noticeForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ addnOrDisOfExpensesL: c.value.addnOrDisOfExpensesF });
      c.patchValue({ taxRateL: c.value.taxRateF });
      c.patchValue({ taxImpactL: c.value.taxImpactF });
      c.patchValue({ interestL: c.value.interestF });
      c.patchValue({ penaltyL: c.value.penaltyF });
      c.patchValue({ totalL: c.value.totalF });
    });
  }

  refundDetailsChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let refundPaidTotal = 0;

    (
      this.taxPaymentRefundDetailsForm.get('refundDetails1') as UntypedFormArray
    ).controls.forEach((c: any) => {
      const tax = this.inrService.removeCommasAndParseNumber(c.value.tax);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);

      taxTotal += Number(tax);
      interestTotal += Number(interest);

      c.patchValue(
        {
          total: this.inrService.formatCurrency(String(+Number(tax) + +Number(interest)))
        },
        { emitEvent: false, onlySelf: true }
      );
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      refundPaidTotal += Number(total);
    });

    this.refundPaidTaxTotal = taxTotal;
    this.refundPaidInterestTotal = interestTotal;
    this.refundPaidTotal = refundPaidTotal;
  }

  liabilityAddmittedAndPaidChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let liabilityPaidTotal = 0;

    (this.taxPaymentRefundDetailsForm.get('libDetails1') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const tax = this.inrService.removeCommasAndParseNumber(c.value.tax);
        const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);

        taxTotal += Number(tax);
        interestTotal += Number(interest);

        c.patchValue(
          {
            total: this.inrService.formatCurrency(
              String(+Number(tax) + +Number(interest))
            )
          },
          { emitEvent: false, onlySelf: true }
        );
        const total = this.inrService.removeCommasAndParseNumber(c.value.total);
        liabilityPaidTotal += Number(total);
      }
    );
    this.liabilityAddmittedAndPaidTaxTotal = taxTotal;
    this.liabilityAddmittedAndPaidInterestTotal = interestTotal;
    this.liabilityAddmittedAndPaidTotal = liabilityPaidTotal;
  }
  

  itrDetailsPaidChanged() {
    let totTaxLiabilityTotal = 0;
    let totTaxPaidTotal = 0;
    let totAdvanceTax = 0;
    let totTdsAmountTotal = 0;
    let totTcsAmountTotal = 0;
    let totSelfAssessmentAmountTotal = 0;
    let refundDemandTotal = 0;

    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const totTaxLiability = this.inrService.removeCommasAndParseNumber(c.value.totTaxLiability);
      const totTaxPaid = this.inrService.removeCommasAndParseNumber(c.value.totTaxPaid);
      const advanceTax = this.inrService.removeCommasAndParseNumber(c.value.advanceTax);
      const tdsAmount = this.inrService.removeCommasAndParseNumber(c.value.tdsAmount);
      const tcsAmount = this.inrService.removeCommasAndParseNumber(c.value.tcsAmount);
      const selfAssessmentAmount = this.inrService.removeCommasAndParseNumber(c.value.selfAssessmentAmount);
      const refundDemand = this.inrService.removeCommasAndParseNumber(c.value.refundDemand);

      totTaxLiabilityTotal += Number(totTaxLiability);
      totAdvanceTax += Number(advanceTax);
      totTdsAmountTotal += Number(tdsAmount);
      totTcsAmountTotal += Number(tcsAmount);
      totSelfAssessmentAmountTotal += Number(selfAssessmentAmount);
      totTaxPaidTotal += Number(totTaxPaid);
      refundDemandTotal += Number(refundDemand);

      c.patchValue({ totTaxPaid: this.inrService.formatCurrency(String((+Number(advanceTax)) + (+Number(tdsAmount)) + (+Number(tcsAmount)) + (+Number(selfAssessmentAmount)) )) });
      totTaxPaidTotal += Number(this.inrService.removeCommasAndParseNumber(c.value.totTaxPaid));

      c.patchValue({ refundDemand: this.inrService.formatCurrency(String((+Number(totTaxLiability)) - (+Number(advanceTax)) - (+Number(tdsAmount)) - (+Number(tcsAmount)) - (+Number(selfAssessmentAmount) ))) });

    })
  }

  issueValueChange() {
    let totOfAddnOrDisOfExpensesF = 0;
    let taxRateFTotal = 0;
    let taxImpactFTotal = 0;
    let interestFTotal = 0;
    let penaltyFTotal = 0;

    let addnOrDisOfExpensesLTotal = 0;
    let taxRateLTotal = 0;
    let taxImpactLTotal = 0;
    let interestLTotal = 0;
    let penaltyLTotal = 0;

    (this.noticeForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const addnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(
        c.value.addnOrDisOfExpensesF
      );
      const taxRateF = c.value.taxRateF;
      const taxImpactF = this.inrService.removeCommasAndParseNumber(c.value.taxImpactF);
      // const previousTaxImpactF = this.inrService.formatCurrency( (Number(addnOrDisOfExpensesF) / 100) * Number(taxRateF) );
      const interestF = this.inrService.removeCommasAndParseNumber(c.value.interestF);
      const penaltyF = this.inrService.removeCommasAndParseNumber(c.value.penaltyF);

      const addnOrDisOfExpensesL = this.inrService.removeCommasAndParseNumber(
        c.value.addnOrDisOfExpensesL
      );
      const taxRateL = c.value.taxRateL;
      const taxImpactL = this.inrService.removeCommasAndParseNumber(c.value.taxImpactL);
      const interestL = this.inrService.removeCommasAndParseNumber(c.value.interestL);
      const penaltyL = this.inrService.removeCommasAndParseNumber(c.value.penaltyL);

      taxImpactFTotal += Number(taxImpactF);
      interestFTotal += Number(interestF); //ph-1
      penaltyFTotal += Number(penaltyF);
      taxRateFTotal += Number(taxRateF);
      totOfAddnOrDisOfExpensesF += Number(addnOrDisOfExpensesF);

      taxImpactLTotal += Number(taxImpactL);
      interestLTotal += Number(interestL); //ph-3
      penaltyLTotal += Number(penaltyL);
      taxRateLTotal += Number(taxRateL);
      addnOrDisOfExpensesLTotal += Number(addnOrDisOfExpensesL);

      // if (isTaxImpactF) {
      //   c.patchValue({ taxImpactF:this.inrService.formatCurrency(Number(taxImpactF) )});
      // } else {
      //   c.patchValue({
      //     taxImpactF: this.inrService.formatCurrency( (Number(addnOrDisOfExpensesF) / 100) * Number(taxRateF) )});
      // }
      // taxImpactFTotal += Number( this.inrService.removeCommasAndParseNumber(c.value.taxImpactF) );

      c.patchValue({
        totalF: this.inrService.formatCurrency(String(Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactF)) +
          Number(interestF) + Number(penaltyF)))
      });

      // c.patchValue({ taxImpactL: this.inrService.formatCurrency((Number(addnOrDisOfExpensesL) / 100) * Number(taxRateL) )});
      // taxImpactLTotal += Number( this.inrService.removeCommasAndParseNumber(c.value.taxImpactL));

      c.patchValue({
        totalL: this.inrService.formatCurrency(
          String(Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactL)) + Number(interestL) + Number(penaltyL)
          ))
      });
    });

    this.noticeForm.controls['taxImpactFTotal'].setValue(this.inrService.formatCurrency(String(taxImpactFTotal)));
    this.noticeForm.controls['interestFTotal'].setValue(this.inrService.formatCurrency(String(interestFTotal)));
    this.noticeForm.controls['penaltyFTotal'].setValue(this.inrService.formatCurrency(String(penaltyFTotal)));
    this.noticeForm.controls['totOfAddnOrDisOfExpensesF'].setValue(this.inrService.formatCurrency(String(totOfAddnOrDisOfExpensesF)));

    this.noticeForm.controls['totOfTotal'].setValue(this.inrService.formatCurrency((Number(+taxImpactFTotal + +interestFTotal + +penaltyFTotal))));

    this.orderForm.controls['taxImpactLTotal'].setValue(this.inrService.formatCurrency(String(taxImpactLTotal)));

    this.orderForm.controls['interestLTotal'].setValue(this.inrService.formatCurrency(String(interestLTotal)));

    this.orderForm.controls['penaltyLTotal'].setValue(this.inrService.formatCurrency(String(penaltyLTotal)));

    this.orderForm.controls['addnOrDisOfExpensesLTotal'].setValue(this.inrService.formatCurrency(String(addnOrDisOfExpensesLTotal)));

    this.orderForm.controls['totalLTotal'].setValue(
      this.inrService.formatCurrency(String(Number(+taxImpactLTotal + +interestLTotal + +penaltyLTotal))));
  }

  formatTableValues() {
    const issues1Array = this.noticeForm.controls.issues as UntypedFormArray;
    const libDetails1Array = this.taxPaymentRefundDetailsForm.controls
      .libDetails1 as UntypedFormArray;
    const refundDetails1Array = this.taxPaymentRefundDetailsForm.controls
      .refundDetails1 as UntypedFormArray;
    const itrValueArray = this.iTRDetailsForm.controls.itrDetails as UntypedFormArray;

    if (refundDetails1Array && refundDetails1Array.controls.length) {
      for (var index = 0; index < refundDetails1Array.controls.length; index++) {
        const refundDetails1Control = refundDetails1Array.controls[index] as UntypedFormGroup;
        refundDetails1Control.controls['tax'].setValue(
          this.inrService.formatCurrency(refundDetails1Control.value.tax)
        );
        refundDetails1Control.controls['interest'].setValue(
          this.inrService.formatCurrency(refundDetails1Control.value.interest)
        );
        refundDetails1Control.controls['total'].setValue(
          this.inrService.formatCurrency(refundDetails1Control.value.total)
        );
      }
    }

    if (itrValueArray && itrValueArray.controls.length) {
      for (var index = 0; index < itrValueArray.controls.length; index++) {
        const itrControl = itrValueArray.controls[index] as UntypedFormGroup;
        itrControl.controls['totTaxIncome'].setValue(
          this.inrService.formatCurrency(itrControl.value.totTaxIncome)
        );
        itrControl.controls['totTaxLiability'].setValue(
          this.inrService.formatCurrency(itrControl.value.totTaxLiability)
        );
        itrControl.controls['advanceTax'].setValue(this.inrService.formatCurrency(itrControl.value.advanceTax))
        itrControl.controls['tdsAmount'].setValue(this.inrService.formatCurrency(itrControl.value.tdsAmount))
        itrControl.controls['tcsAmount'].setValue(this.inrService.formatCurrency(itrControl.value.tcsAmount))
        itrControl.controls['selfAssessmentAmount'].setValue(this.inrService.formatCurrency(itrControl.value.selfAssessmentAmount))
        itrControl.controls['totTaxPaid'].setValue(
          this.inrService.formatCurrency(itrControl.value.totTaxPaid)
        );
        itrControl.controls['refundDemand'].setValue(
          this.inrService.formatCurrency(itrControl.value.refundDemand)
        );
      }
    }

    if (libDetails1Array.controls.length > 0) {
      for (let index = 0; index < libDetails1Array.controls.length; index++) {
        const libDetails1Control = libDetails1Array.controls[index] as UntypedFormGroup;
        libDetails1Control.controls['tax'].setValue(
          this.inrService.formatCurrency(libDetails1Control.value.tax)
        );
        libDetails1Control.controls['interest'].setValue(
          this.inrService.formatCurrency(libDetails1Control.value.interest)
        );
        libDetails1Control.controls['total'].setValue(
          this.inrService.formatCurrency(libDetails1Control.value.total)
        );
      }
    }

    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['addnOrDisOfExpensesF'].setValue(
          this.inrService.formatCurrency(issues1Control.value.addnOrDisOfExpensesF)
        );
        issues1Control.controls['taxRateF'].setValue(issues1Control.value.taxRateF);
        issues1Control.controls['taxImpactF'].setValue(
          this.inrService.formatCurrency(issues1Control.value.taxImpactF)
        );
        issues1Control.controls['interestF'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interestF)
        );
        issues1Control.controls['penaltyF'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penaltyF)
        );
        issues1Control.controls['totalF'].setValue(
          this.inrService.formatCurrency(issues1Control.value.totalF)
        );

        issues1Control.controls['addnOrDisOfExpensesL'].setValue(
          this.inrService.formatCurrency(issues1Control.value.addnOrDisOfExpensesL)
        );
        issues1Control.controls['taxRateL'].setValue(issues1Control.value.taxRateL);
        issues1Control.controls['taxImpactL'].setValue(
          this.inrService.formatCurrency(issues1Control.value.taxImpactL)
        );
        issues1Control.controls['interestL'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interestL)
        );
        issues1Control.controls['penaltyL'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penaltyL)
        );
        issues1Control.controls['totalL'].setValue(
          this.inrService.formatCurrency(issues1Control.value.totalL)
        );
      }
    }
    this.issueValueChange();
  }

  inrFormattingRemoving() {
    var data = this.noticeForm.value;
    var dataOrdertable = this.orderForm.value;
    var dataValue = this.taxPaymentRefundDetailsForm.value;
    var itrValue = this.iTRDetailsForm.value;
    var dataConsultan = this.consultantForm.value;

    data.demandAmt1 = this.inrService.removeCommasAndParseNumber(data.demandAmt1);

    dataOrdertable.demandAmt3 = this.inrService.removeCommasAndParseNumber(
      dataOrdertable.demandAmt3
    );

    if (dataConsultan.cc && dataConsultan.cc.length) {
      dataConsultan.cc.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
      });
    }

    if (dataValue.libDetails1 && dataValue.libDetails1.length) {
      dataValue.libDetails1.forEach((libDetails: any) => {
        libDetails.tax = this.inrService.removeCommasAndParseNumber(libDetails.tax);
        libDetails.interest = this.inrService.removeCommasAndParseNumber(
          libDetails.interest
        );
        libDetails.total = this.inrService.removeCommasAndParseNumber(libDetails.total);
      });
    }

    if (dataValue.refundDetails1 && dataValue.refundDetails1.length) {
      dataValue.refundDetails1.forEach((refundDetails: any) => {
        refundDetails.tax = this.inrService.removeCommasAndParseNumber(refundDetails.tax);
        refundDetails.interest = this.inrService.removeCommasAndParseNumber(
          refundDetails.interest
        );
        refundDetails.total = this.inrService.removeCommasAndParseNumber(
          refundDetails.total
        );
      });
    }

    this.taxPaymentRefundDetailsForm.patchValue(dataValue);

    if (itrValue.itrDetails && itrValue.itrDetails.length) {
      itrValue.itrDetails.forEach((itrDetails: any) => {
        itrDetails.totTaxIncome = this.inrService.removeCommasAndParseNumber(
          itrDetails.totTaxIncome
        );
        itrDetails.totTaxLiability = this.inrService.removeCommasAndParseNumber(
          itrDetails.totTaxLiability
        );
        itrDetails.advanceTax = this.inrService.removeCommasAndParseNumber(itrDetails.advanceTax)
        itrDetails.tdsAmount = this.inrService.removeCommasAndParseNumber(itrDetails.tdsAmount)
        itrDetails.tcsAmount = this.inrService.removeCommasAndParseNumber(itrDetails.tcsAmount)
        itrDetails.selfAssessmentAmount = this.inrService.removeCommasAndParseNumber(itrDetails.selfAssessmentAmount)
        itrDetails.totTaxPaid = this.inrService.removeCommasAndParseNumber(
          itrDetails.totTaxPaid
        );
        itrDetails.refundDemand = this.inrService.removeCommasAndParseNumber(
          itrDetails.refundDemand
        );
      });
    }
    this.iTRDetailsForm.patchValue(itrValue);

    if (data.issues && data.issues.length) {
      data.issues.forEach((letter: any) => {
        letter.addnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(
          letter.addnOrDisOfExpensesF
        );
        letter.taxRateF = letter.taxRateF;
        letter.taxImpactF = this.inrService.removeCommasAndParseNumber(letter.taxImpactF);
        letter.interestF = this.inrService.removeCommasAndParseNumber(letter.interestF);
        letter.penaltyF = this.inrService.removeCommasAndParseNumber(letter.penaltyF);
        letter.totalF = this.inrService.removeCommasAndParseNumber(letter.totalF);

        letter.addnOrDisOfExpensesL = this.inrService.removeCommasAndParseNumber(
          letter.addnOrDisOfExpensesL
        );
        letter.taxRateL = letter.taxRateL;
        letter.taxImpactL = this.inrService.removeCommasAndParseNumber(letter.taxImpactL);
        letter.interestL = this.inrService.removeCommasAndParseNumber(letter.interestL);
        letter.penaltyL = this.inrService.removeCommasAndParseNumber(letter.penaltyL);
        letter.totalL = this.inrService.removeCommasAndParseNumber(letter.totalL);
        if (letter.provAndLibDetails && letter.provAndLibDetails.length) {
          letter.provAndLibDetails.forEach((details: any) => {
            details.provAmtF = this.inrService.removeCommasAndParseNumber(
              details.provAmtF
            );
            details.contLibAmtF = this.inrService.removeCommasAndParseNumber(
              details.contLibAmtF
            );
            details.othLibF = this.inrService.removeCommasAndParseNumber(details.othLibF);
            details.caroAmtF = this.inrService.removeCommasAndParseNumber(
              details.caroAmtF
            );
          });
        }
      });
    }
    data.totOfAddnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(
      data.totOfAddnOrDisOfExpensesF
    );
    data.taxImpactFTotal = this.inrService.removeCommasAndParseNumber(
      data.taxImpactFTotal
    );
    data.interestFTotal = this.inrService.removeCommasAndParseNumber(data.interestFTotal);
    data.penaltyFTotal = this.inrService.removeCommasAndParseNumber(data.penaltyFTotal);
    data.totOfTotal = this.inrService.removeCommasAndParseNumber(data.totOfTotal);

    dataOrdertable.addnOrDisOfExpensesLTotal = this.inrService.removeCommasAndParseNumber(
      dataOrdertable.addnOrDisOfExpensesLTotal
    );
    dataOrdertable.taxImpactLTotal = this.inrService.removeCommasAndParseNumber(
      dataOrdertable.taxImpactLTotal
    );
    dataOrdertable.interestLTotal = this.inrService.removeCommasAndParseNumber(
      dataOrdertable.interestLTotal
    );
    dataOrdertable.penaltyLTotal = this.inrService.removeCommasAndParseNumber(
      dataOrdertable.penaltyLTotal
    );
    dataOrdertable.totalLTotal = this.inrService.removeCommasAndParseNumber(
      dataOrdertable.totalLTotal
    );

    this.noticeForm.patchValue(data);
    this.orderForm.patchValue(dataOrdertable);
  }
  handlePaste(event: any) {
    this.inrService.handlePaste(event);
  }

  handleDecimalPaste(index: any) {
    const issues1Array = this.noticeForm.controls.issues as UntypedFormArray;
    const issues1Control = issues1Array.controls[index] as UntypedFormGroup;
    issues1Control.controls['taxRateF'].setValue(
      (Math.round(issues1Control.value.taxRateF * 100) / 100).toFixed(2)
    );
    issues1Control.controls['taxRateL'].setValue(
      (Math.round(issues1Control.value.taxRateL * 100) / 100).toFixed(2)
    );
  }

  consultantValidation() {
    let consultantArray = this.consultantForm.controls.cc as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(
          issuesControl.controls.fees.value
        );
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (
            issuesControl.controls.firm.value === null ||
            issuesControl.controls.firm.value === ''
          ) {
            issuesControl.controls.firm.setErrors({ required: true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  submitgeneralInfo() {
    this.isNextBtnClicked = true;
    if (this.generalInfo.valid) {
      this.tab2Disable = true;
      this.intimTab.nativeElement.click();
      console.log(this.generalInfo.value);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  submitOrderForm() {
    this.isSubmitted = true;
    this.p1IssueValidation();
    let orderCopyLoc = (this.noticeForm.get('orderCopyLoc') as UntypedFormArray).controls;
    var count = orderCopyLoc?.some((x) => x.get('fileLoc')?.value != null);
    if (!count) {
      this.toaster.showError('Required Fields Are Empty');
      return;
    }
    if (this.noticeForm.valid) {
      this.tab3Disable = true;
      this.itrTab.nativeElement.click();
      console.log(this.noticeForm.value);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }
  submitiTRDetailsForm() {
    this.isSubmittedItr = true;
    if (this.iTRDetailsForm.valid) {
      this.tab4Disable = true;
      this.taxPayTab.nativeElement.click();
      console.log(this.iTRDetailsForm.value);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  submittaxPaymentRefundDetailsForm() {
    if (this.taxPaymentRefundDetailsForm.valid) {
      this.tab5Disable = true;
      this.saveCase();
      this.updateBtn = true;
      this.tab6Disable = true;
      this.conclusionTab.nativeElement.click();
      console.log(this.taxPaymentRefundDetailsForm.value);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  submitTaxForm() {
    if (this.taxPaymentRefundDetailsForm.valid) {
      this.tab5Disable = true;
      this.updateBtn = true;
      this.conclusionTab.nativeElement.click();
      console.log(this.taxPaymentRefundDetailsForm.value);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  conclusionSubmitForm(phaseTab: number) {
    this.isSubmittedAppeal = true;
    if (this.conclusionForm.valid && this.noticeForm.valid) {
      this.updateCase(phaseTab);
      this.consultantData();
      this.perResData();
      const appealDetails1 = this.conclusionForm.controls.appealDetails1 as UntypedFormArray;
      for (let index = 0; index < appealDetails1.controls.length; index++) {
        const element: any = appealDetails1.controls[index];
        if (
          element.controls.action.value == 'RP' &&
          element.controls.forum.value == 'Not Applicable'
        ) {
          this.isPhase2 = true;
          this.rectificationTab.nativeElement.click();
        }
      }
      console.log(this.conclusionForm.value);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  updateForm(phaseTab: number) {
    this.isSubmittedAppeal = true;
    if (this.conclusionForm.valid && this.noticeForm.valid) {
      this.updateCase(phaseTab);
      this.consultantData();
      this.perResData();
      console.log(this.conclusionForm.value);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  rectificationSubmit() {
    this.isSubmittedRectif = true;
    console.log(this.rectifForm, 'rectifForm');
    if (this.rectifForm.valid) {
      this.tab8Disable = true;
      this.consultantTab.nativeElement.click();
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  updateFormPh2(phaseTab: number) {
    this.isSubmittedRectif = true;
    this.isSubmittedConsultant = true;
    this.consultantValidation();
    console.log(this.consultantForm, 'consultantForm');
    if (this.consultantForm.valid && this.rectifForm.valid) {
      this.updateCase(phaseTab);
      // this.orderTab.nativeElement.click();
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }
  orderSubmit() {
    this.isSubmittedOrder = true;
    this.p3Validation();
    var rectifFormArray = this.rectifForm.controls.rectifDetails as UntypedFormArray;
    if (rectifFormArray.controls.length > 0) {
      var itemListControl = rectifFormArray.controls[0] as UntypedFormGroup;
      if (
        itemListControl.controls.rectifOrderReceived.value == 'true' ||
        itemListControl.controls.rectifOrderReceived.value == true
      ) {
        var rectifDetails = this.getOrderData();
        let orderLoc;
        for (let i = 0; i < rectifDetails.length; i++) {
          orderLoc = (rectifDetails[i].get('orderLoc') as UntypedFormArray).controls;
        }
        var count = orderLoc?.some((x) => x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
      }
    }
    if (this.rectifForm.valid && this.orderForm.valid && this.noticeForm.valid) {
      this.cumulativeTab.nativeElement.click();
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  updateFormPh3(phaseTab: number) {
    this.isSubmittedOrder = true;
    this.p3Validation();
    if (this.rectifForm.valid || this.orderForm.valid) {
      this.updateCase(phaseTab);
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  saveBtn() {
    let id = document.getElementById('isNext');
    this.nextBtn.nativeElement.click();
    this.updateBtn = true;
  }
  selectedIssues:any
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    if(this.action === "update"){
    this.selectedIssues = this.noticeForm.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "updatedOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "DT",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.preAjaForm.value.issue");
  this.taxService.getIssueRefData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
    if (response.status === 1) {
      this.issueRefData = response?.response;
      if(this.issueRefData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
    }      
    });
  }
  }
  exportAsXLSX() {
    if (this.issueRefData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueRefData.map((source: any) => ({
      'PAN Name': source.panName,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'Assessment Year': source.ay,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'panName',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'panName',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.AY: {
        if (!this.sorting.ay) {
          this.sorting.ay = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'ay',
            'asc'
          );
        } else {
          this.sorting.ay = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'ay',
            'desc'
          );
        }
        break;
      }
      
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,'personResponsible', 'desc' );
        }
        break;
      }
    }
  }

  getAllTagData(){   
    this.masterService.getAllTagMaster().subscribe((response: any) => {
      if (response.status === 1) {
        const allTags = response.response.data;
        this.tagDataList = allTags
        .filter((tagItem: any) => tagItem.active) 
        .map((tagItem: any) => tagItem.tag);
      } else {     
        this.toaster.showSuccess(response.message);
      }
    });
  }

  getCompanyTags() {
    const selectedCompanyId = localStorage.getItem('selectedId'); // Get selected company ID
    this.masterService.getEntityTagsCaseDt(selectedCompanyId).subscribe((response: any) => {
      if (response.status === 1) {
        const allTags = response.response.data;
        
        // Filter tags based on the selected company ID
        this.tagDataList = allTags
          .filter((tagItem: any) => tagItem.companyId === Number(selectedCompanyId))
          .flatMap((tagItem: any) =>
            tagItem.tags
              .filter((tag: any) => tag.active) 
              .map((tag: any) => tag.tag)
          );  // Extract the `tag` property
  
      } else {        
        this.toaster.showSuccess(response.message);
      }
    });
  }
}

import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { MODE_OF_RECEIPT, TYPE_OF_NOTICE, TYPE_OF_RESPONSE, ISSUING_AUTHORITY, ADJ_DEPTPRE, GRANTED, ADJ_ATTEND, ADJ_PH1_ADJUNOT, STATUS_DATA, FORUM_ENQUIRY_PROCEED, ACTION_ENQUIRY_PROCEED, YES_NO } from '../../shared/constant';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TaxServiceService } from '../service/tax-service.service';
import { HttpClient } from '@angular/common/http';
import { CreateEnquiryProceedingRequest } from 'src/app/models/direct-tax.interface';
import { InrService } from 'src/app/shared/services/inr.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';

interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}

@Component({
  selector: 'app-enquiry-proceeding',
  templateUrl: './enquiry-proceeding.component.html',
  styleUrls: ['./enquiry-proceeding.component.css']
})
export class EnquiryProceedingComponent implements OnInit {
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  parentCaseId: any;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;
  @ViewChild('noticeTab', { static: false }) noticeTab!: ElementRef;
  @ViewChild('subTab', { static: false }) subTab!: ElementRef;
  @ViewChild('nextBtn') nextBtn!: ElementRef;
  @ViewChild('update') update!: ElementRef;
  @ViewChild('hearingTab', { static: false }) hearingTab!: ElementRef;
  @ViewChild('consultantTab', { static: false }) consultantTab!: ElementRef;
  @ViewChild('conclusionTab', { static: false }) conclusionTab!: ElementRef;

  generalInfo!: UntypedFormGroup;
  noticeForm!: UntypedFormGroup;
  hearingForm!: UntypedFormGroup;
  consultantForm!: UntypedFormGroup;
  conclusionForm!: UntypedFormGroup;
  file!: ElementRef;
  fileUrl: any;
  duplicateData:any;
  maxDate = new Date();
  finyearData: any[] = [];
  savedCaseId: any;
  caseType: any;
  caseId: any;
  action: any;
  isSelectDisabled: boolean = true;
  selectedTab: any;
  caseData: any = {};
  selectedField: any;
  selectedFile: any;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdownCC = true;
  currentYear: any;
  companyId: any;
  matches: any;
  selectedCompanyId: any;
  selectedPanName: any;
  selectedPan: any;
  navContext: any
  getAccessTabData: any;
  selectedNotice: any;
  selectedReplyNotice: any;
  selectedPhase: any;
  currentTab = 'dtenqproc';
  activeTab: any;
  roleName: any

  formData: FormData = new FormData();
  sidemenu = false;
  isCaseID: boolean = false;
  phaseDisable: boolean = false;
  isSubmittedNotice: boolean = false;
  isSubmittedSubmission: boolean = false;
  isSubmittedPhRecord: boolean = false;
  isSubmitted: boolean = false;
  isSubmittedAppeal: boolean = false;
  isNextBtnClicked: boolean = false;
  showTable: boolean = false;
  detailsOfSubmissionsExpansion = false;
  detailsOfIssuingAuthority = false;



  modeOfReceiptNotice = MODE_OF_RECEIPT;
  preLitPH1Preceded = ISSUING_AUTHORITY;
  typeOfNotice = TYPE_OF_NOTICE;
  modeOfAppealOptions = MODE_OF_RECEIPT;
  ajaSubDetailsOptions = ADJ_DEPTPRE;
  grantedOptions = GRANTED;
  yesNoList = YES_NO;
  TypeOfRespo = TYPE_OF_RESPONSE;
  adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
  adjAttend = ADJ_ATTEND;
  statusData = STATUS_DATA;
  forumAppeal = FORUM_ENQUIRY_PROCEED;
  actionData = ACTION_ENQUIRY_PROCEED;

  rmvLetterModalRef!: NgbModalRef;
  rmvNoticeModalRef!: NgbModalRef;
  rmvajaSubDetailsModalRef!: NgbModalRef;
  rmvSubmissionsModalRef!: NgbModalRef;
  rmvsubDetailsModalRef!: NgbModalRef;
  phNoticeLocModalRef!: NgbModalRef;
  rmvConsultantModalRef!: NgbModalRef;
  rmvConclusionRef!: NgbModalRef;
  rmvAppealDetailsRef!: NgbModalRef;
  rmvdepartmentModalRef!: NgbModalRef;



  rmvIssuingAuthorityModalRef!: NgbModalRef;
  rmvreqDetailsModalRef!: NgbModalRef;
  noticeLocModalRef!: NgbModalRef;
  enquiryProceedingRequest: CreateEnquiryProceedingRequest = new CreateEnquiryProceedingRequest();
  showLeftIcon: boolean = false;
  updateBtn: boolean = false;
  saveApi: boolean = false;
  isPhase2: boolean = false;
  isVisibleCaseId: boolean = false;
  tab2Disable: boolean = false;
  tab3Disable: boolean = false;
  tab4Disable: boolean = false;
  tab5Disable: boolean = false;
  tab6Disable: boolean = false;
  tab7Disable: boolean = false;
  isUpdate: boolean = false;
  isDisabledSelect: boolean = false;
  savedPreCaseId: any;
  selectedForum: any
  isToggleDropdown=true;



  isUPadjDocsLoc: boolean = true;
  isDWadjDocsLoc: boolean = true;
  isadjDocsLocFile: boolean = false;
  isadjDocsLocText: boolean = true;

  isUPackCopyLoc: boolean = true;
  isDWackCopyLoc: boolean = true;
  isackCopyLocFile: boolean = false;
  isackCopyLocText: boolean = true;

  isUPCopyOfSubmissionLoc: boolean = true;
  isDWCopyOfSubmissionLoc: boolean = true;
  isDWCopyOfSubmissionLocFile: boolean = false;
  isDWCopyOfSubmissionLocText: boolean = true;


  isUPdocLoc: boolean = true;
  isDWdocLoc: boolean = true;
  isdocLocFile: boolean = false;
  isdocLocText: boolean = true;

  isUPSubCopyLoc: boolean = true;
  isDWSubCopyLoc: boolean = true;
  isSubCopyLocFile: boolean = false;
  isSubCopyLocText: boolean = true;

  isUPnoticeLoc: boolean = true;
  isDWnoticeLoc: boolean = true;
  isnoticeLocFile: boolean = false;
  isnoticeLocText: boolean = true;

  isUPPhNotic: boolean = true;
  isDWPhNotic: boolean = true;
  isPhNoticFile: boolean = false;
  isPhNoticText: boolean = true;


  isconsultantUPattachmentsLocText: boolean = true;
  isconsultantDWattachmentsLocText: boolean = true;
  isconsultantattachmentsLocFile: boolean = false;
  isconsultantattachmentsLocText: boolean = true;

  feesChargedTotal = 0;


  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private activeSnapShot: ActivatedRoute,
    private taxService: TaxServiceService,
    private toaster: ToasterService,
    private commonServ: CommonService,
    private http: HttpClient,
    private inrService: InrService,
    private getterSetter: GetterSetterService,
    private US: UserSettingsService,
    private modalService: NgbModal,
    private masterService:MasterService

  ) {
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;

  }
  ngOnInit() {
    this.navContext = this.commonServ.getNavContext();
    this.selectedPan = this.navContext.pan;
    if (this.navContext.entityType === 'Business') {
      this.selectedPanName = sessionStorage.getItem('company');
    } else {
      this.selectedPanName = this.navContext.entityName;
    }
    this.formInitialize();
    this.setDate();
    this.getYears();
    this.personResponsibleData();
    this.consultantData();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.initializeData();
    }

    if (this.action === 'create') {
      this.addNotice();
      this.createAdjDiv();
      this.addConsultantInv();
      this.addConclusionInv();



    } else if (this.action === 'update') {
      this.updateBtn = true;
      this.isDisabledSelect = true
      // this.createAdjDiv();
      // this.addNotice();
      // this.addConsultantInv();
      // this.addConclusionInv();


    } else if (this.action === 'view') {

      this.isPhase2 = true;

    }
    this.selectedNotice = 'phase0';
    this.selectedReplyNotice = 'phase0';
    this.selectedPhase = 'phase0';
    this.roleName = localStorage.getItem('role');
    console.log('role name ', this.roleName)
    const userId: any = sessionStorage.getItem('user');
    this.generalInfo.controls.personResp.patchValue(userId);
  }

  /* Form initialization */
  formInitialize() {
    this.generalInfo = this.fBuild.group({
      updatedBy: [this.caseData?.updatedBy],
      updateDate: [this.caseData?.updateDate],
      caseId: [this.caseData?.caseId],
      pan: [this.caseData?.pan ? this.caseData?.pan : this.selectedPan],
      panName: [this.caseData?.panName ? this.caseData?.panName : this.selectedPanName],
      ay: [this.caseData?.ay, [Validators.required]],
      finYear: [this.caseData?.finYear, [Validators.required]],
      consultant: [this.caseData?.consultant, Validators.maxLength(100)],
      personResp: [this.caseData?.personResp, [Validators.required]],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.minLength(5), Validators.maxLength(125)]],
      caseSummary: [this.caseData?.caseSummary, [Validators.maxLength(1000)]],
      internalRemarks: [this.caseData?.internalRemarks, [Validators.maxLength(1000)]],
    });
    this.noticeForm = this.fBuild.group({
      dtNotices: new UntypedFormArray([]),
    })
    this.hearingForm = this.fBuild.group({
      hearingDetails: new UntypedFormArray([]),
    })
    this.consultantForm = this.fBuild.group({
      cc: new UntypedFormArray([]),
    })
    this.conclusionForm = this.fBuild.group({
      status: [this.caseData?.status],
      deptResponseDetails: new UntypedFormArray([]),
      appealDetails: new UntypedFormArray([])
    })
  }

  p1validation() {
    var itemListArray = this.noticeForm.controls.dtNotices as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (let index = 0; index < itemListArray.controls.length; index++) {
        let issuesControl = itemListArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.isExtApplied.value === true || issuesControl.controls.isExtApplied.value === 'true') {
          if (issuesControl.controls.extDueDate.value == null || issuesControl.controls.extDueDate.value === null || issuesControl.controls.extDueDate.value === undefined) {
            issuesControl.controls.extDueDate.setErrors({ 'required': true });
          } else {
            issuesControl.controls.extDueDate.setErrors(null);
          }
        }
      }
    }
  }



  p2validation() {
    var itemListArray = this.noticeForm.controls.dtNotices as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var itemListIndex = 0; itemListIndex < itemListArray.controls.length; itemListIndex++) {
        var submissionsArray = itemListArray.controls[itemListIndex].get('submissions') as UntypedFormArray
        for (var submissionIndex = 0; submissionIndex < submissionsArray.controls.length; submissionIndex++) {
          var submissionContol = submissionsArray.controls[submissionIndex] as UntypedFormGroup
          if (submissionContol.controls.subDate.value == null || submissionContol.controls.subDate.value === null || submissionContol.controls.subDate.value === undefined) {
            submissionContol.controls.subDate.setErrors({ 'required': true });
          } else {
            submissionContol.controls.subDate.setErrors(null);
          }
          if (submissionContol.controls.ackNo.value === null || submissionContol.controls.ackNo.value === null) {
            submissionContol.controls.ackNo.setErrors({ 'required': true });
          } else {
            submissionContol.controls.ackNo.setErrors(null);
          }
          if (submissionContol.controls.respType.value === null || submissionContol.controls.respType.value === null) {
            submissionContol.controls.respType.setErrors({ 'required': true });
          } else {
            submissionContol.controls.respType.setErrors(null);
          }
        }
      }
    }
  }
  consultantValidation() {
    let consultantArray = this.consultantForm.controls.cc as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(issuesControl.controls.fees.value)
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  phaseChanged(phase: any, i: any) {
    this.selectedNotice = phase;
  }
  phaseRNChanged(phase: any, i: any) {
    this.selectedReplyNotice = phase;
  }
  phaseChangedph(phase: any, i: any) {
    this.selectedPhase = phase;
  }

  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2001;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push(financialYrRange);
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
    this.finyearData = this.finyearData.map(yearRange => {
      const [start, end] = yearRange.split('-').map(Number);
      return `${start + 1}-${end + 1}`;
    });
  }
  getYearData() {
    var assYear = this.generalInfo.controls.ay.value;
    const dashIndex = assYear.indexOf('-');
    if (dashIndex !== -1) {
      var years = assYear.substring(0, dashIndex);
    }
    var convertToInt = parseInt(years);
    var year = convertToInt - 1;
    var financialYear = (year) + '-' + years
    this.generalInfo.controls.finYear.patchValue(financialYear);
  }
  getperiodToValue(content: any){
    this.getYearData();
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "DT",
    model.pan = this.selectedPan,
    model.criterias= [
      {
          "p": "ay",
          "o": "eq",
          "v": this.generalInfo.value.ay,
      }
  ]
  this.taxService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/directTaxSummary']);
    this.modalService.dismissAll();
  }

  tabChanged(tab: any, check?: any) {
    this.generalInfo.patchValue(this.generalInfo.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    } else if (tab === 'tab2') {
    }
  }

  /*dtNotices Array*/
  getFormControlNotice() {
    return ((this.noticeForm.get('dtNotices') as UntypedFormArray).controls as UntypedFormGroup[])
  }
  get notice() {
    return this.noticeForm.get('dtNotices') as UntypedFormArray;
  }

  createNotice(letter: any = null) {
    return this.fBuild.group({
      issuingAuth: new UntypedFormControl(letter ? letter.issuingAuth : null, [Validators.required]),
      noticeRefNo: new UntypedFormControl(letter ? letter.noticeRefNo : null, [Validators.required]),
      noticeType: new UntypedFormControl(letter ? letter.noticeType : null, []),
      noticeReceiptDate: new UntypedFormControl(letter ? letter.noticeReceiptDate : null, [Validators.required]),
      noticeReceiptMode: new UntypedFormControl(letter ? letter.noticeReceiptMode : null, []),
      internalDueDate: new UntypedFormControl(letter ? letter.internalDueDate : null, []),
      noticeDate: new UntypedFormControl(letter ? letter.noticeDate : null, [Validators.required]),
      legalDueDate: new UntypedFormControl(letter ? letter.legalDueDate : null, [Validators.required]),
      isExtApplied: new UntypedFormControl(letter ? letter.isExtApplied : null, []),
      extDueDate: new UntypedFormControl(letter ? letter.extDueDate : null, []),
      noticeSummary: new UntypedFormControl(letter ? letter.noticeSummary : null, [Validators.maxLength(1000)]),
      internalRemarks: new UntypedFormControl(letter ? letter.internalRemarks : null, [Validators.maxLength(1000)]),
      adjSubDetails: new UntypedFormArray([]),
      reqDetails: new UntypedFormArray([]),
      issuingAuthority: new UntypedFormArray([]),
      noticeLoc: new UntypedFormArray([]),
      //Phase 2
      submissions: new UntypedFormArray([]),
      meetingDetails: new UntypedFormArray([]),
    })
  }
  addNotice(i?: any) {
    let dtNoticesFrmGrp = this.createNotice();
    this.selectedNotice = 'phase' + (i + 1);
    window.scrollTo(0, 0);
    (dtNoticesFrmGrp.get('adjSubDetails') as UntypedFormArray).push(this.initAjaSubDetailsFrmGrp());
    (dtNoticesFrmGrp.get('reqDetails') as UntypedFormArray).push(this.initreqDetailsFrmGrp());
    (dtNoticesFrmGrp.get('noticeLoc') as UntypedFormArray).push(this.createnoticeLoc());
    (dtNoticesFrmGrp.get('issuingAuthority') as UntypedFormArray).push(this.initIssuingAuthorityFrmGrp());
    (dtNoticesFrmGrp.get('meetingDetails') as UntypedFormArray).push(this.detailsOfDepartmentFrmGrp());
    (dtNoticesFrmGrp.get('submissions') as UntypedFormArray).push(this.initSubmissionFrmGrp());
    const submissionsIndex = (dtNoticesFrmGrp.get('submissions') as UntypedFormArray).length - 1;
    ((dtNoticesFrmGrp.get('submissions') as UntypedFormArray).controls[submissionsIndex].get('subDetails') as UntypedFormArray).push(this.initsubDetailsFrmGrp());
    (this.noticeForm.get('dtNotices') as UntypedFormArray).push(dtNoticesFrmGrp);
  }

  removeLetter(index: number) {
    (this.noticeForm.get('dtNotices') as UntypedFormArray).removeAt(index);
    this.selectedNotice = 'phase' + (index - 1);
    this.rmvNoticeModalRef.close();
  }

  openRmvNoticePop(content: any, i: any) {
    this.rmvNoticeModalRef = this.modalService.open(content);
  }

  /*dtNotices array */

  /*adjSubDetails array */
  getAjaSubDetails(noticediv: any): UntypedFormGroup[] {
    return (noticediv.get('adjSubDetails') as UntypedFormArray).controls as UntypedFormGroup[];;
  }

  get adjSubDetails() {
    return this.noticeForm.get('adjSubDetails') as UntypedFormArray;
  }

  initAjaSubDetailsFrmGrp(adjSub: any = null): UntypedFormGroup {
    return this.fBuild.group({
      adjFiledDate: new UntypedFormControl(adjSub ? adjSub.adjFiledDate : null, []),
      newAdjDate: new UntypedFormControl(adjSub ? adjSub.newAdjDate : null, []),
      adjReason: new UntypedFormControl(adjSub ? adjSub.adjReason : null, [Validators.maxLength(100)]),
      isGranted: new UntypedFormControl(adjSub ? adjSub.isGranted : null, []),
      adjDocsLoc: new UntypedFormArray([]),
    })
  }

  addAjaSubDetails(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let adjSubDetailsFrmArray = dtNoticesFrmArray.controls[j].get('adjSubDetails') as UntypedFormArray;
    adjSubDetailsFrmArray.push(this.initAjaSubDetailsFrmGrp());
    (adjSubDetailsFrmArray.get('adjDocsLoc') as UntypedFormArray).push(this.createAdjDocDetails());
  }
  rmAjaSubInv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let adjSubDetailsFrmArray = dtNoticesFrmArray.controls[i].get('adjSubDetails') as UntypedFormArray;
    adjSubDetailsFrmArray.removeAt(k);
    this.rmvajaSubDetailsModalRef.close();
  }
  openRemoveAjaSubDetailsPop(content: any, i: any) {
    this.rmvajaSubDetailsModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.addAdjDetailsDiv(i, 0);
    this.modalService.open(content);
  }

  /*adjSubDetails array end*/

  /*adjDocsLoc Array */
  getadjDocLoc(adjDocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (adjDocGrp.get('adjDocsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjLength(noticeFormIndex: number, adjSubDetailsFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const adjSubDetailsArray = noticesListFrmArray.at(noticeFormIndex).get('adjSubDetails') as UntypedFormArray;
    const adjDocsLocListArray = adjSubDetailsArray.at(adjSubDetailsFormIndex).get('adjDocsLoc') as UntypedFormArray;
    if (adjDocsLocListArray && adjDocsLocListArray.length > -1) {
      let adjcount = 0;
      for (let i = 0; i < adjDocsLocListArray.length; i++) {
        const singleAdjDocsLoc = adjDocsLocListArray.value[i];
        if (singleAdjDocsLoc.fileLoc != '' && singleAdjDocsLoc.fileLoc != null) {
          adjcount++;
        }
      }
      return adjcount;
    }

    return 0;
  }

  createAdjDocDetails(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }

  addAdjDetailsDiv(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var adjSubDetailsArray: any = element.controls.adjSubDetails.controls;

      for (let i = 0; i < adjSubDetailsArray.length; i++) {
        const element1 = adjSubDetailsArray[i].controls.adjDocsLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            adjSubDetailsArray[i].controls.adjDocsLoc.push(this.createAdjDocDetails())
          }
        }


      }
    }
  }

  uploadAdjDetails(noticeListIndex: any, adjDocsLocIndex: any, adjSubDetailsIndex: any) {
    this.isadjDocsLocFile = true;
    this.isadjDocsLocText = false;
    this.isDWadjDocsLoc = false;
    this.isUPadjDocsLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let adjSubDetailsArray = noticesListFrmArray.controls[noticeListIndex].get('adjSubDetails') as UntypedFormGroup;
    let adjDocsLocArray = adjSubDetailsArray.controls[adjSubDetailsIndex].get('adjDocsLoc') as UntypedFormArray
    if (adjDocsLocArray.controls.length > 0) {
      for (let index = 0; index < adjDocsLocArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = adjDocsLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deletedocLocFile(noticeListIndex: any, adjDocsLocIndex: any, adjSubDetailsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let adjSubDetailsArray = noticesListFrmArray.controls[noticeListIndex].get('adjSubDetails') as UntypedFormGroup;
    let adjDocsLocArray = adjSubDetailsArray.controls[adjSubDetailsIndex].get('adjDocsLoc') as UntypedFormArray
    if (adjDocsLocArray.controls.length > 0) {
      for (let index = 0; index < adjDocsLocArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = adjDocsLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*adjDocsLoc Array end*/

  /*reqDetails array */
  getreqDetails(noticediv: any): UntypedFormGroup[] {
    return (noticediv.get('reqDetails') as UntypedFormArray).controls as UntypedFormGroup[];;
  }
  initreqDetailsFrmGrp(req: any = null): UntypedFormGroup {
    return this.fBuild.group({
      docReq: new UntypedFormControl(req ? req.docReq : null, [Validators.pattern(/(.*[a-zA-Z]){3}/)]),
      remarks: new UntypedFormControl(req ? req.remarks : null, [Validators.maxLength(150)]),
    })
  }
  addreqDetails(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let reqDetailsFrmArray = dtNoticesFrmArray.controls[j].get('reqDetails') as UntypedFormArray;
    reqDetailsFrmArray.push(this.initreqDetailsFrmGrp());
  }
  rmreqDetailsInv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let reqDetailsFrmArray = dtNoticesFrmArray.controls[i].get('reqDetails') as UntypedFormArray;
    reqDetailsFrmArray.removeAt(k);
    this.rmvreqDetailsModalRef.close();
  }

  openRemovereqDetailsPop(content: any, i: any) {
    this.rmvreqDetailsModalRef = this.modalService.open(content);
  }
  /*reqDetails array */

  /*issuingAuthority array */
  getIssuingAuthority(issuingFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (issuingFrmGrp.get('issuingAuthority') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initIssuingAuthorityFrmGrp(issuing: any = null): UntypedFormGroup {
    return this.fBuild.group({
      noticeNo: new UntypedFormControl(issuing ? issuing.noticeNo : null, []),
      officer: new UntypedFormControl(issuing ? issuing.officer : null, []),
      designation: new UntypedFormControl(issuing ? issuing.designation : null, []),
      jurisdiction: new UntypedFormControl(issuing ? issuing.jurisdiction : null, []),
      address: new UntypedFormControl(issuing ? issuing.address : null, []),
      email: new UntypedFormControl(issuing ? issuing.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]),
      contact: new UntypedFormControl(issuing ? issuing.contact : null, []),
    })
  }
  addIssuingAuthority(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let issuingAuthorityFrmArray = dtNoticesFrmArray.controls[j].get('issuingAuthority') as UntypedFormArray;
    issuingAuthorityFrmArray.push(this.initIssuingAuthorityFrmGrp());
  }
  rmIssuingAuthorityInv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let issuingAuthorityFrmArray = dtNoticesFrmArray.controls[i].get('issuingAuthority') as UntypedFormArray;
    issuingAuthorityFrmArray.removeAt(k);
    this.rmvIssuingAuthorityModalRef.close();
  }
  openIssuingAuthorityPop(content: any, i: any) {
    this.rmvIssuingAuthorityModalRef = this.modalService.open(content);
  }
  /*IssuingAuthority array */

  /*noticeLoc Array */
  getnoticeLoc(noticeLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (noticeLocGrp.get('noticeLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getnoticeLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j]?.get('noticeLoc') as UntypedFormArray;
    let noticecount = noticeLocFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createnoticeLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addnoticeLoc(j: any, i: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j].get('noticeLoc') as UntypedFormArray;
    if (noticeLocFrmArray.length > 0) {
      let length = noticeLocFrmArray.length
      while (i < 5 - length) {
        noticeLocFrmArray.push(this.createnoticeLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        noticeLocFrmArray.push(this.createnoticeLoc());
        i++;
      }
    }
  }
  rmNoticeLoc(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[i].get('noticeLoc') as UntypedFormArray;
    noticeLocFrmArray.removeAt(k);
    this.noticeLocModalRef.close();
  }
  // openNoticeLocPop(content: any, i: any) {   
  //   this.noticeLocModalRef =  this.modalService.open(content);
  // }
  openNoticeLocPop(content: any, i: any) {
    this.addnoticeLoc(i, 0);
    this.modalService.open(content);
  }

  uploadNticeLocCopy(noticeListIndex: any, noticeLocIndex: any) {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
    let itemListsArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let noticeLocFrmArray = itemListsArray.controls[noticeListIndex].get('noticeLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletenNticeLocCopyFile(noticeListIndex: any, noticeLocIndex: any) {
    let noticeArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let noticeLocFrmArray = noticeArray.controls[noticeListIndex].get('noticeLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*noticeLoc Array end*/
  /*Phase 2  */
  /*getSubmissions array */
  getSubmissions(noticeTabdiv: any): UntypedFormGroup[] {
    return (noticeTabdiv.get('submissions') as UntypedFormArray).controls as UntypedFormGroup[];;
  }

  initSubmissionFrmGrp(sub: any = null): UntypedFormGroup {
    const submissionFormControl = this.fBuild.group({
      subDate: new UntypedFormControl(sub ? sub.subDate : null, []),
      subMode: new UntypedFormControl(sub ? sub.subMode : null, []),
      respType: new UntypedFormControl(sub ? sub.respType : "FULL", []),
      ackNo: new UntypedFormControl(sub ? sub.ackNo : null, []),
      personWhoSubmitted: new UntypedFormControl(sub ? sub.personWhoSubmitted : null, []),
      ackCopyLoc: new UntypedFormArray([]),
      subCopyLoc: new UntypedFormArray([]),
      subDetails: new UntypedFormArray([]),
      subSummary: new UntypedFormControl(sub ? sub.subSummary : null, [Validators.maxLength(1000)]),
      internalRemarks: new UntypedFormControl(sub ? sub.internalRemarks : null, [Validators.maxLength(1000)]),
    });

    if (this.action === 'create') {
      (submissionFormControl.get('subDetails') as UntypedFormArray).push(this.initsubDetailsFrmGrp());
    }
    return submissionFormControl;
  }
  addSubmissions(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionsFrmArray = dtNoticesFrmArray.controls[j].get('submissions') as UntypedFormArray;
    submissionsFrmArray.push(this.initSubmissionFrmGrp());
    const submissionsIndex = submissionsFrmArray.length - 1;
    (submissionsFrmArray.controls[submissionsIndex].get('subDetails') as UntypedFormArray).push(this.initsubDetailsFrmGrp());
  }

  rmSubmissionDiv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionFrmArray = dtNoticesFrmArray.controls[i].get('submissions') as UntypedFormArray;
    submissionFrmArray.removeAt(k);
    this.rmvSubmissionsModalRef.close();
  }

  openRemoveSubmissionsPop(content: any, i: any, k: any) {
    this.rmvSubmissionsModalRef = this.modalService.open(content);
  }
  openSubDetailPop(content: any, i: any) {
    this.addAckCopyLoc(i, 0);
    this.addSubLoc(i, 0);
    this.modalService.open(content);
  }

  /*submissions array end*/

  /*ackCopyLoc Array */
  getAckCopyLoc(ackCopyLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (ackCopyLoc.get('ackCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAckLength(noticeFormIndex: number, submissionFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const submissionFrmArray = noticesListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const ackCopyLocArray = submissionFrmArray.at(submissionFormIndex).get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray && ackCopyLocArray.length > -1) {
      let countOfackCopyLoc = 0;
      for (let i = 0; i < ackCopyLocArray.length; i++) {
        const singleAckLoc = ackCopyLocArray.value[i];
        if (singleAckLoc.fileLoc != '' && singleAckLoc.fileLoc != null) {
          countOfackCopyLoc++;
        }
      }
      return countOfackCopyLoc;
    }

    return 0;
  }

  createAckCopyLoc(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }

  addAckCopyLoc(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];
      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.ackCopyLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.ackCopyLoc.push(this.createAckCopyLoc())
          }
        }
      }
    }
  }

  uploadAckCopyLoc(noticeListIndex: any, adjDocsLocIndex: any, submissionsIndex: any) {
    this.isackCopyLocFile = true;
    this.isackCopyLocText = false;
    this.isDWackCopyLoc = false;
    this.isUPackCopyLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyArray = submissionsArray.controls[submissionsIndex].get('ackCopyLoc') as UntypedFormArray
    if (ackCopyArray.controls.length > 0) {
      for (let index = 0; index < ackCopyArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = ackCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteAckCopyLocFile(noticeListIndex: any, adjDocsLocIndex: any, submissionsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyArray = submissionsArray.controls[submissionsIndex].get('ackCopyLoc') as UntypedFormArray
    if (ackCopyArray.controls.length > 0) {
      for (let index = 0; index < ackCopyArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = ackCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*ackCopyLoc Array end*/

  /*subCopyLoc Array */

  getSubLength(noticeFormIndex: number, submissionFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const submissionFrmArray = noticesListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const subCopyLocArray = submissionFrmArray.at(submissionFormIndex).get('subCopyLoc') as UntypedFormArray;
    if (subCopyLocArray && subCopyLocArray.length > -1) {
      let countOfsubCopyLoc = 0;
      for (let i = 0; i < subCopyLocArray.length; i++) {
        const singleSubLoc = subCopyLocArray.value[i];
        if (singleSubLoc.fileLoc != '' && singleSubLoc.fileLoc != null) {
          countOfsubCopyLoc++;
        }
      }
      return countOfsubCopyLoc;
    }

    return 0;
  }
  getSubLoc(ackCopyLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (ackCopyLoc.get('subCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  createSubLoc(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addSubLoc(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.subCopyLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.subCopyLoc.push(this.createSubLoc())
          }
        }


      }
    }
  }
  uploadSubLoc(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    this.isSubCopyLocFile = true;
    this.isSubCopyLocText = false;
    this.isDWSubCopyLoc = false;
    this.isUPSubCopyLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subCopyLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteSubLoc(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subCopyLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*SubLocLoc Array end*/


  /*subDetails array */
  getsubDetails(SubmisionFrmGrp: any): UntypedFormGroup[] {
    return (SubmisionFrmGrp.get('subDetails') as UntypedFormArray).controls as UntypedFormGroup[];;
  }

  initsubDetailsFrmGrp(adjSub: any = null): UntypedFormGroup {
    return this.fBuild.group({
      docReq: new UntypedFormControl(adjSub ? adjSub.docReq : null, [Validators.pattern(/(.*[a-zA-Z]){3}/)]),
      remarks: new UntypedFormControl(adjSub ? adjSub.remarks : null, []),
      docLoc: new UntypedFormArray([]),
    })
  }

  addSubDetails(i: any, j: any,) {
    let noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionsArray = noticesListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    let subDetailsFrmArray = submissionsArray.controls[j].get('subDetails') as UntypedFormArray;
    subDetailsFrmArray.push(this.initsubDetailsFrmGrp());
  }
  rmSubDetailstInv(i: number, k: number, j: number) {
    let noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionsFrmArray = noticesListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    let subDetailsFrmArray = submissionsFrmArray.controls[k].get('subDetails') as UntypedFormArray;
    subDetailsFrmArray.removeAt(j);
    this.rmvsubDetailsModalRef.close();
  }

  openRemoveSubDetailsPop(content: any, i: any) {
    this.rmvsubDetailsModalRef = this.modalService.open(content);
  }
  openSubDetailsPop(content: any, i: any) {
    this.addDocLo(i, 0);
    this.modalService.open(content);
  }


  /*docLoc Array */
  getDocLoc(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('docLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getDocLoength(noticeFormIndex: number, submissionFormIndex: number, subDetailsFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const submissionFrmArray = noticesListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const subDetailsArray = submissionFrmArray.at(submissionFormIndex).get('subDetails') as UntypedFormArray;
    const docLocArray = subDetailsArray.at(subDetailsFormIndex).get('docLoc') as UntypedFormArray;
    if (docLocArray && docLocArray.length > -1) {
      let countOfdocCopyLoc = 0;
      for (let i = 0; i < docLocArray.length; i++) {
        const singleDocLoc = docLocArray.value[i];
        if (singleDocLoc.fileLoc != '' && singleDocLoc.fileLoc != null) {
          countOfdocCopyLoc++;
        }
      }
      return countOfdocCopyLoc;
    }
    return 0;
  }

  createDocLo(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addDocLo(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1Array = submissionArray[i].controls.subDetails;
        for (let j = 0; j < element1Array.length; j++) {
          const docEle = element1Array.controls[j].controls.docLoc;
          if (docEle.controls.length === 0) {
            for (let k = 0; k < 5; k++) {
              docEle.push(this.createDocLo());
            }
          }

        }

      }
    }

  }
  uploadDocLoc(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any, docLocIndex: any,) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subDetails') as UntypedFormGroup;
    let docLocArray = subCopysArray.controls[subCopyIndex].get('docLoc') as UntypedFormArray;

    if (docLocArray.controls.length > 0) {
      for (let index = 0; index < docLocArray.controls.length; index++) {
        if (index === docLocIndex) {
          let itemListuploadControl = docLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteDocLo(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any, docLocIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subDetails') as UntypedFormGroup;
    let docLocArray = subCopysArray.controls[subCopyIndex].get('docLoc') as UntypedFormArray;

    if (docLocArray.controls.length > 0) {
      for (let index = 0; index < docLocArray.controls.length; index++) {
        if (index === docLocIndex) {
          let itemListuploadControl = docLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*DocLoLoc Array end*/
  /*subDetails array end*/

  /*meetingDetails array */

  departmentInvArr(noticediv: UntypedFormGroup): UntypedFormGroup[] {
    return (noticediv.get('meetingDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }


  detailsOfDepartmentFrmGrp(meeting: any = null): UntypedFormGroup {
    return this.fBuild.group({
      dateOfMeeting: new UntypedFormControl(meeting ? meeting.dateOfMeeting : null, []),
      personAttended: new UntypedFormControl(meeting ? meeting.personAttended : null, [Validators.maxLength(100)]),
      hearingDetails: new UntypedFormControl(meeting ? meeting.hearingDetails : null, [Validators.maxLength(500)]),
      copyOfSubmissionLoc: new UntypedFormArray([]),
    })

  }

  addDepartmentInv(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let departmentFrmArray = dtNoticesFrmArray.controls[j].get('meetingDetails') as UntypedFormArray;
    departmentFrmArray.push(this.detailsOfDepartmentFrmGrp());
    (departmentFrmArray.get('copyOfSubmissionLoc') as UntypedFormArray).push(this.createCopyOfSub());
  }

  rmDepartmentInv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let departmentFrmArray = dtNoticesFrmArray.controls[i].get('meetingDetails') as UntypedFormArray;
    departmentFrmArray.removeAt(k);
    this.rmvdepartmentModalRef.close();
  }

  openRemovDepartmentPop(content: any, i: any, k: any) {
    this.rmvdepartmentModalRef = this.modalService.open(content);
  }
  openDepartmentPop(content: any, i: any) {
    this.addCopyOfSub(i, 0);
    this.modalService.open(content);
  }

  /*copyOfSubmissionLoc Array */
  getCopyOfSub(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('copyOfSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getCopyOfSubLength(noticeFormIndex: number, meetingDetailsFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const departmentFrmArray = noticesListFrmArray.at(noticeFormIndex).get('meetingDetails') as UntypedFormArray;
    const copyOfSubmissionLocArray = departmentFrmArray.at(meetingDetailsFormIndex).get('copyOfSubmissionLoc') as UntypedFormArray;
    if (copyOfSubmissionLocArray && copyOfSubmissionLocArray.length > -1) {
      let countOfcopyOfSubmissionLoc = 0;
      for (let i = 0; i < copyOfSubmissionLocArray.length; i++) {
        const singleCopyOfSubmissionLoc = copyOfSubmissionLocArray.value[i];
        if (singleCopyOfSubmissionLoc.fileLoc != '' && singleCopyOfSubmissionLoc.fileLoc != null) {
          countOfcopyOfSubmissionLoc++;
        }
      }
      return countOfcopyOfSubmissionLoc;
    }

    return 0;
  }

  createCopyOfSub(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addCopyOfSub(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var departmentFrmArray: any = element.controls.meetingDetails.controls;

      for (let i = 0; i < departmentFrmArray.length; i++) {
        const element1 = departmentFrmArray[i].controls.copyOfSubmissionLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            departmentFrmArray[i].controls.copyOfSubmissionLoc.push(this.createCopyOfSub())
          }
        }


      }
    }
  }

  uploadCopyOfSub(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    this.isDWCopyOfSubmissionLocFile = true;
    this.isDWCopyOfSubmissionLocText = false;
    this.isDWCopyOfSubmissionLoc = false;
    this.isUPCopyOfSubmissionLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let departmentFrmArray = noticesListFrmArray.controls[noticeListIndex].get('meetingDetails') as UntypedFormGroup;
    let subCopysArray = departmentFrmArray.controls[submissionsIndex].get('copyOfSubmissionLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteCopyOfSub(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let departmentFrmArray = noticesListFrmArray.controls[noticeListIndex].get('meetingDetails') as UntypedFormGroup;
    let subCopysArray = departmentFrmArray.controls[submissionsIndex].get('copyOfSubmissionLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*copyOfSubmissionLoc Array end*/


  /*meetingDetails array end*/


  /*hearingDetails start */

  changeadjOrNot(event: any, i: any) {
    if (event.target.value === "true") {
      this.createAdjDiv(i);
    }
  }

  phDivArr() {
    return ((this.hearingForm.get('hearingDetails') as UntypedFormArray).controls as UntypedFormGroup[])
  }

  newphArray(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      phScheduledDate: [data ? data.phScheduledDate : null],
      phNoticeLoc: new UntypedFormArray([]),
      adjournedOrNot: [data ? data.adjournedOrNot : null, []],
      attended: [data ? data.attended : null, []],
      consultant: [data ? data.consultant : null],
      addWrittenSubDate: [data ? data.addWrittenSubDate : null, []],
      addSubLoc: new UntypedFormArray([]),
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phDiscNotes: [data ? data.phDiscNotes : null, [Validators.maxLength(1000)]],

    })
  }

  createAdjDiv(i?: any) {
    var addPh: any = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    addPh.push(this.newphArray());
  }

  removephArr(index: number) {
    (this.hearingForm.get('hearingDetails') as UntypedFormArray).removeAt(index);
    this.selectedPhase = 'phase' + (index - 1);
  }

  /*phNoticeLoc Array */
  getPhNotice(PhNoticeGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (PhNoticeGrp.get('phNoticeLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhNoticeLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j]?.get('phNoticeLoc') as UntypedFormArray;
    let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createPhNotice(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addPhNotice(j: any, i: any) {
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j].get('phNoticeLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.length > 0) {
      let length = PhNoticcFrmArray.length
      while (i < 5 - length) {
        PhNoticcFrmArray.push(this.createPhNotice());
        i++;
      }
    }
    else {
      while (i < 5) {
        PhNoticcFrmArray.push(this.createPhNotice());
        i++;
      }
    }
  }

  openPhNoticePop(content: any, i: any) {
    this.addPhNotice(i, 0);
    this.modalService.open(content);
  }

  uploadPhNoticeCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isPhNoticFile = true;
    this.isPhNoticText = false;
    this.isDWPhNotic = false;
    this.isUPPhNotic = false;

    let itemListsArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('phNoticeLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhNoticeCopyFile(noticeListIndex: any, PhNoticIndex: any) {
    let noticeArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = noticeArray.controls[noticeListIndex].get('phNoticeLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*phNoticeLoc Array end*/

  /*addSubLoc Array */
  getAddSub(AddSubGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (AddSubGrp.get('addSubLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAddSubLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j]?.get('addSubLoc') as UntypedFormArray;
    let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createAddSub(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addAddSub(j: any, i: any) {
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.length > 0) {
      let length = PhNoticcFrmArray.length
      while (i < 5 - length) {
        PhNoticcFrmArray.push(this.createAddSub());
        i++;
      }
    }
    else {
      while (i < 5) {
        PhNoticcFrmArray.push(this.createAddSub());
        i++;
      }
    }
  }

  openAddSubPop(content: any, i: any) {
    this.addAddSub(i, 0);
    this.modalService.open(content);
  }

  uploadAddSubCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isPhNoticFile = true;
    this.isPhNoticText = false;
    this.isDWPhNotic = false;
    this.isUPPhNotic = false;

    let itemListsArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAddSubFile(noticeListIndex: any, PhNoticIndex: any) {
    let noticeArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = noticeArray.controls[noticeListIndex].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*addSubLoc Array end*/


  /*phRecordIfAnyLoc Array */
  getPhRecordIfAny(PhNoticeGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (PhNoticeGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecordIfAnyLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createPhRecordIfAny(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addPhRecordIfAny(j: any, i: any) {
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.length > 0) {
      let length = PhNoticcFrmArray.length
      while (i < 5 - length) {
        PhNoticcFrmArray.push(this.createPhRecordIfAny());
        i++;
      }
    }
    else {
      while (i < 5) {
        PhNoticcFrmArray.push(this.createPhRecordIfAny());
        i++;
      }
    }
  }

  openPhRecordIfAnyPop(content: any, i: any) {
    this.addPhRecordIfAny(i, 0);
    this.modalService.open(content);
  }

  uploadPhRecordIfAnyCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isPhNoticFile = true;
    this.isPhNoticText = false;
    this.isDWPhNotic = false;
    this.isUPPhNotic = false;

    let itemListsArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecordIfAnyFile(noticeListIndex: any, PhNoticIndex: any) {
    let noticeArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = noticeArray.controls[noticeListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*phRecordIfAnyLoc Array end*/

  /*hearingDetails end*/

  // Consultant Charges Table
  consultantInvArr(): UntypedFormGroup[] {
    return (this.consultantForm.get('cc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  ConsultantCharges(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : null],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      supportingDocsLoc: new UntypedFormArray([]),
      email: [data ? data.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [data ? data.contact : null],

    })
  }

  addConsultantInv() {
    let detailOfConsultant = this.ConsultantCharges();
    (this.consultantForm.get('cc') as UntypedFormArray).push(detailOfConsultant);
  }

  rmConsultantInv(i: number) {
    (this.consultantForm.get('cc') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.consultantForm.get('cc') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = typeof c.value.fees === 'string' ? c.value.fees.replace(/,/g, '') : c.value.fees;
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  openRmvconsultantPop(content: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  openCCDetailPop(content: any, i: any) {
    this.addConsultant(i, 0);
    this.modalService.open(content);
  }


  /*supportingDocsLoc Array */
  getConsultant(noticeLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (noticeLocGrp.get('supportingDocsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getConsultantLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.consultantForm.get('cc') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j]?.get('supportingDocsLoc') as UntypedFormArray;
    let noticecount = noticeLocFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createConsultant(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addConsultant(j: any, i: any) {
    let dtNoticesFrmArray = this.consultantForm.get('cc') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j].get('supportingDocsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.length > 0) {
      let length = noticeLocFrmArray.length
      while (i < 5 - length) {
        noticeLocFrmArray.push(this.createConsultant());
        i++;
      }
    }
    else {
      while (i < 5) {
        noticeLocFrmArray.push(this.createConsultant());
        i++;
      }
    }
  }

  uploadConsultant(noticeListIndex: any, noticeLocIndex: any) {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
    let itemListsArray = this.consultantForm.controls.cc as UntypedFormGroup;
    let noticeLocFrmArray = itemListsArray.controls[noticeListIndex].get('supportingDocsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsultant(noticeListIndex: any, noticeLocIndex: any) {
    let noticeArray = this.consultantForm.controls.cc as UntypedFormGroup;
    let noticeLocFrmArray = noticeArray.controls[noticeListIndex].get('supportingDocsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*supportingDocsLoc Array end*/

  // Conclusion Array
  getConclusion(): UntypedFormGroup[] {
    return (this.conclusionForm.get('deptResponseDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  conclusionFrmGrp(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      respDate: [data ? data.respDate : null],
      din: [data ? data.din : null],
      respReceiptDate: [data ? data.respReceiptDate : null],
      replyDueDate: [data ? data.replyDueDate : null, []],
      respSummary: [data ? data.respSummary : null],
      attchmentsLoc: new UntypedFormArray([]),
    })
  }

  addConclusionInv() {
    let detailOfConsultant = this.conclusionFrmGrp();
    (this.conclusionForm.get('deptResponseDetails') as UntypedFormArray).push(detailOfConsultant);
    (detailOfConsultant.get('attchmentsLoc') as UntypedFormArray).push(this.createAttchment());

  }

  rmConclusionInv(i: number) {
    (this.conclusionForm.get('deptResponseDetails') as UntypedFormArray).removeAt(i);
    this.rmvConclusionRef.close();
  }


  openRmvConclusionPop(content: any) {
    this.rmvConclusionRef = this.modalService.open(content);
  }

  openConclusionDetailPop(content: any, i: any) {
    this.addAttchment(i, 0);
    this.modalService.open(content);
  }


  /*attchmentsLoc Array */
  getAttchment(noticeLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (noticeLocGrp.get('attchmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getAttchmentLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.conclusionForm.get('deptResponseDetails') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j]?.get('attchmentsLoc') as UntypedFormArray;
    let noticecount = noticeLocFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createAttchment(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addAttchment(j: any, i: any) {
    let dtNoticesFrmArray = this.conclusionForm.get('deptResponseDetails') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j].get('attchmentsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.length > 0) {
      let length = noticeLocFrmArray.length
      while (i < 5 - length) {
        noticeLocFrmArray.push(this.createAttchment());
        i++;
      }
    }
    else {
      while (i < 5) {
        noticeLocFrmArray.push(this.createAttchment());
        i++;
      }
    }
  }

  uploadAttchment(noticeListIndex: any, noticeLocIndex: any) {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
    let itemListsArray = this.conclusionForm.controls.deptResponseDetails as UntypedFormGroup;
    let noticeLocFrmArray = itemListsArray.controls[noticeListIndex].get('attchmentsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAttchment(noticeListIndex: any, noticeLocIndex: any) {
    let noticeArray = this.conclusionForm.controls.deptResponseDetails as UntypedFormGroup;
    let noticeLocFrmArray = noticeArray.controls[noticeListIndex].get('attchmentsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*supportingDocsLoc Array end*/
  setForumOfAppeal() {
    this.forumAppeal = FORUM_ENQUIRY_PROCEED;
    const appealDetails = this.conclusionForm.controls.appealDetails as UntypedFormArray;
    for (let index = 0; index < appealDetails.controls.length; index++) {
      const element: any = appealDetails.controls[index]
      console.log(element, "element")
      // if (element.controls.action.value == 'AP') {
      //   element.controls.forum.patchValue(this.forumAppeal[0].value);
      // } else if (element.controls.action.value == 'WP') {
      //   element.controls.forum.patchValue(this.forumAppeal[1].value);
      if (element.controls.action.value == 'DEPT RESPONSE PENDING') {
        element.controls.forum.patchValue(this.forumAppeal[3].value);
      } else if (element.controls.action.value == 'CLOSED') {
        element.controls.forum.patchValue(this.forumAppeal[2].value);
      }

    }

  }

  /*appealDetails Array */
  getappealDetail(): UntypedFormGroup[] {
    return (this.conclusionForm.get('appealDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  appealDetailFrmGrp(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      action: [data ? data.action : null, [Validators.required]],
      forum: [data ? data.forum : null],
      isCaseCreated: ["false"],
    })
  }

  addAppealDetail() {
    let detailOfConsultant = this.appealDetailFrmGrp();
    (this.conclusionForm.get('appealDetails') as UntypedFormArray).push(detailOfConsultant);
  }
  openRemovAppealPop(content: any) {
    this.rmvAppealDetailsRef = this.modalService.open(content);
  }

  rmAppealDetail(i: number) {
    (this.conclusionForm.get('appealDetails') as UntypedFormArray).removeAt(i);
    this.rmvAppealDetailsRef.close();
  }


  /*Phase 2 end */

  // save api 
  saveNotice(i: any) {
    console.log(this.noticeForm, 'generalInfo');
    if (this.noticeForm.valid && this.generalInfo.valid) {
      let noticeForm = this.notice.at(i) as UntypedFormGroup;
      let adjSubForm = noticeForm.get('adjSubDetails') as UntypedFormArray;
      let reqDetailsForm = noticeForm.get('reqDetails') as UntypedFormArray;
      let noticeLocForm = noticeForm.get('noticeLoc') as UntypedFormArray;
      let issuingAuthForm = noticeForm.get('issuingAuthority') as UntypedFormArray;
      this.p1validation();

      if (this.generalInfo.valid) {
        if (this.noticeForm.valid) {
          let notices = this.noticeForm.get('dtNotices') as UntypedFormArray;
          let data: any = {};
          let dataadj: any = {};
          let dataadjDoc: any = {};
          let datareqDetails: any = {};
          let datanoticeLoc: any = {};
          let dataissuingAuth: any = {};
          let adjDocsLocData: any = {};

          let dtNoticesData: any = [];
          let adjArray: any = [];
          let adjDocsDataArray: any = [];
          let reqDetailArray: any = [];
          let noticeLocArray: any = [];
          let issuingAuthArray: any = [];
          let adjSubArray: any = [];
          let adjDocsLocArray: any = [];

          adjSubForm.controls.forEach(element => {
            adjSubArray = element.get('adjDocsLoc') as UntypedFormArray;
          })
          adjSubArray.controls.forEach((element: any) => {
            if (element.value.fileLoc != '' && element.value.fileLoc != null) {
              adjDocsLocData = {
                fileLoc: element.value.fileLoc,
                remarks: element.value.remarks,
              }
              adjDocsLocArray.push(adjDocsLocData);
            }
          });

          adjSubForm.controls.forEach(element => {
            dataadj = {
              adjFiledDate: element.value.adjFiledDate,
              newAdjDate: element.value.newAdjDate,
              adjReason: element.value.adjReason,
              isGranted: element.value.isGranted,
              adjDocsLoc: adjDocsLocArray
            }
            adjArray.push(dataadj);
          });
          reqDetailsForm.controls.forEach(element => {
            datareqDetails = {
              docReq: element.value.docReq,
              remarks: element.value.remarks,
            }
            reqDetailArray.push(datareqDetails);
          });
          issuingAuthForm.controls.forEach(element => {
            dataissuingAuth = {
              noticeNo: element.value.noticeNo,
              officer: element.value.officer,
              designation: element.value.designation,
              jurisdiction: element.value.jurisdiction,
              address: element.value.address,
              email: element.value.email,
              contact: element.value.contact,

            }
            issuingAuthArray.push(dataissuingAuth);
          });
          noticeLocForm.controls.forEach(element => {
            if (element.value.fileLoc != '' && element.value.fileLoc != null) {
              datanoticeLoc = {
                fileLoc: element.value.fileLoc,
                remarks: element.value.remarks,
              }
              noticeLocArray.push(datanoticeLoc);
            }
          });

          notices.controls.forEach(element => {
            data = {
              issuingAuth: element.value.issuingAuth,
              noticeRefNo: element.value.noticeRefNo,
              noticeType: element.value.noticeType,
              noticeReceiptDate: element.value.noticeReceiptDate,
              noticeDate: element.value.noticeDate,
              internalDueDate: element.value.internalDueDate,
              noticeReceiptMode: element.value.noticeReceiptMode,
              legalDueDate: element.value.legalDueDate,
              isExtApplied: element.value.isExtApplied,
              extDueDate: element.value.extDueDate,
              noticeSummary: element.value.noticeSummary,
              internalRemarks: element.value.internalRemarks,
              adjSubDetails: adjArray,
              reqDetails: reqDetailArray,
              issuingAuthority: issuingAuthArray,
              noticeLoc: noticeLocArray,
            }
            dtNoticesData.push(data);
          });

          this.enquiryProceedingRequest.phase = 1,
            this.enquiryProceedingRequest.action = 'CREATE',
            this.enquiryProceedingRequest.caseType = "NEW",
            this.enquiryProceedingRequest.pan = this.generalInfo.controls.pan.value,
            this.enquiryProceedingRequest.panName = this.generalInfo.controls.panName.value,
            this.enquiryProceedingRequest.ay = this.generalInfo.controls.ay.value,
            this.enquiryProceedingRequest.finYear = this.generalInfo.controls.finYear.value,
            this.enquiryProceedingRequest.consultant = this.generalInfo.controls.consultant.value,
            this.enquiryProceedingRequest.personResp = this.generalInfo.controls.personResp.value,
            this.enquiryProceedingRequest.caseSummary = this.generalInfo.controls.caseSummary.value,
            this.enquiryProceedingRequest.internalRemarks = this.generalInfo.controls.internalRemarks.value,
            this.enquiryProceedingRequest.caseLabel = this.generalInfo.controls.caseLabel.value,
            this.enquiryProceedingRequest.dtNotices = dtNoticesData;
          console.log(dtNoticesData, "data")
        }

        this.taxService.CreateEnquiryproceeding(this.enquiryProceedingRequest).subscribe((response: any) => {
          if (response.status === 1) {
            this.savedCaseId = response.response;
            this.saveCaseId.emit(this.savedCaseId);
            this.isPhase2 = true;
            this.updateBtn = true;
            this.initializeData();
            this.toaster.showSuccess(response.message && this.savedCaseId);
          } else {
            this.toaster.showError(response.errorList[0].message);
          }
        });
      }
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
    // this.toaster.showError(response.message);
  }

  updatePhaseTwo(phaseTab: any) {
    this.inrFormattingRemoving();
    this.p1validation();

    if (phaseTab == 1) {
      this.isNextBtnClicked = true;
      this.isSubmittedNotice = true;
      // noticeLoc validation
      var dtNotices = this.getFormControlNotice();
      let noticeLoc;
      for (let i = 0; i < dtNotices.length; i++) {
        noticeLoc = (dtNotices[i].get('noticeLoc') as UntypedFormArray).controls;
      }
      var count = noticeLoc?.some(x =>
        x.get('fileLoc')?.value != null
      );
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      } else {
      }
    } else {
      this.isSubmitted = true;
      this.isSubmittedAppeal = true
      this.isSubmittedSubmission = true;
      // copy of submssion validation
      let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
      let element1;
      for (let index = 0; index < noticesListFrmArray.length; index++) {
        const element = noticesListFrmArray.controls[index];
        var submissionArray: any = element.controls.submissions.controls;
        for (let i = 0; i < submissionArray.length; i++) {
          element1 = (submissionArray[i].get('ackCopyLoc') as UntypedFormArray).controls;
        }
      }
      var count = element1?.some((x: any) =>
        x.get('fileLoc')?.value != null
      );
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
    }
    if ((phaseTab == 1 && this.generalInfo.valid && this.noticeForm.valid) || (phaseTab == 2 && this.generalInfo.valid && this.noticeForm.valid && this.conclusionForm.valid && this.consultantForm.valid)) {
      let finalPayload: any = {};
      const dtNotices = this.notice.value;

      // Preparation For DT Notices 
      for (let i = 0; i < dtNotices.length; i++) {
        const dtNoticesArray = dtNotices[i];

        // Manupulation For adjSubDetails
        for (let j = 0; j < dtNoticesArray.adjSubDetails.length; j++) {
          const adjSubDetailsArray = dtNoticesArray.adjSubDetails[j];
          let finalAdjDocsLocArray: any = [];
          for (let k = 0; k < adjSubDetailsArray.adjDocsLoc.length; k++) {
            const adjDocsLocArray = adjSubDetailsArray.adjDocsLoc[k];
            if (adjDocsLocArray.fileLoc != '' && adjDocsLocArray.fileLoc != null) {
              const adjDocsLocData = {
                fileLoc: adjDocsLocArray.fileLoc,
                remarks: adjDocsLocArray.remarks,
              }
              finalAdjDocsLocArray.push(adjDocsLocData);
            }
          }
          adjSubDetailsArray.adjDocsLoc = finalAdjDocsLocArray;
        }

        // Manipulation For reqDetails
        let reqDetailArray: any = [];
        for (let l = 0; l < dtNoticesArray.reqDetails.length; l++) {
          const element = dtNoticesArray.reqDetails[l];
          if (element.docReq != '' && element.docReq != null) {
            const reqData = {
              docReq: element.docReq,
              remarks: element.remarks,
            }
            reqDetailArray.push(reqData);
          }
        }
        dtNoticesArray.reqDetails = reqDetailArray;

        // Manipulation For noticeLoc
        let noticeLocArray: any = [];
        for (let l = 0; l < dtNoticesArray.noticeLoc.length; l++) {
          const element = dtNoticesArray.noticeLoc[l];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const noticeData = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            noticeLocArray.push(noticeData);
          }
        }
        dtNoticesArray.noticeLoc = noticeLocArray;


        // Manupulation For submissions
        for (let j = 0; j < dtNoticesArray.submissions.length; j++) {
          const submissionObj = dtNoticesArray.submissions[j];

          // Manipulation For ackCopyLoc
          let ackCopyLocArray: any = [];
          for (let k = 0; k < submissionObj.ackCopyLoc.length; k++) {
            const element = submissionObj.ackCopyLoc[k];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const ackCopyData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              ackCopyLocArray.push(ackCopyData);
            }
          }
          submissionObj.ackCopyLoc = ackCopyLocArray;

          // Manipulation For subCopyLoc
          let subCopyLocArray: any = [];
          for (let l = 0; l < submissionObj.subCopyLoc.length; l++) {
            const element = submissionObj.subCopyLoc[l];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const ackCopyData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              subCopyLocArray.push(ackCopyData);
            }
          }
          submissionObj.subCopyLoc = subCopyLocArray;

          for (let m = 0; m < submissionObj.subDetails.length; m++) {
            const subDetailsObj = submissionObj.subDetails[m];

            // Manipulation For docLoc
            let docLocArray: any = [];
            for (let n = 0; n < subDetailsObj.docLoc.length; n++) {
              const element = subDetailsObj.docLoc[n];
              if (element.fileLoc != '' && element.fileLoc != null) {
                const docLocData = {
                  fileLoc: element.fileLoc,
                  remarks: element.remarks,
                }
                docLocArray.push(docLocData);
              }
            }
            subDetailsObj.docLoc = docLocArray;
          }
        }

        // Manupulation For meetingDetails
        for (let j = 0; j < dtNoticesArray.meetingDetails.length; j++) {
          const meetingDetailsObj = dtNoticesArray.meetingDetails[j];

          // Manipulation For copyOfSubmissionLoc
          let copyOfSubmissionLocArray: any = [];
          for (let k = 0; k < meetingDetailsObj.copyOfSubmissionLoc.length; k++) {
            const element = meetingDetailsObj.copyOfSubmissionLoc[k];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const copyOfSubmissionLocData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              copyOfSubmissionLocArray.push(copyOfSubmissionLocData);
            }
          }
          meetingDetailsObj.copyOfSubmissionLoc = copyOfSubmissionLocArray;
        }
      }
      finalPayload.dtNotices = dtNotices;



      // Preparation For CC
      const consultantForm = this.consultantForm.value;
      let checkCCFormNotEmpty = false;
      for (let i = 0; i < consultantForm.cc.length; i++) {
        const singleCcForm = consultantForm.cc[i];

        // Manipulation For supportingDocDetails
        const supportingDocsArray = consultantForm.cc[i].supportingDocsLoc;
        let finalsupportingDocsArray: any = [];
        for (let j = 0; j < supportingDocsArray.length; j++) {
          const element = supportingDocsArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const supportingDocDetails = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalsupportingDocsArray.push(supportingDocDetails);
          }
        }
        consultantForm.cc[i].supportingDocsLoc = finalsupportingDocsArray;
        if ((!checkCCFormNotEmpty) && (singleCcForm.firm != null || singleCcForm.consultant != null || singleCcForm.contact != null || singleCcForm.natureOfWork != null || singleCcForm.email != null || singleCcForm.supportingDocsLoc.length > 0)) {
          checkCCFormNotEmpty = true;
        }
      }
      if (checkCCFormNotEmpty) {
        finalPayload.cc = consultantForm.cc;
      }

      // Preparation For Hearing Details
      const hearingDetailsForm = this.hearingForm.value;
      let checkHearingDetailsNotEmpty = false;
      for (let i = 0; i < hearingDetailsForm.hearingDetails.length; i++) {
        const singleHearingDetails = hearingDetailsForm.hearingDetails[i];
        // Manipulation For phNoticeLoc
        const phNoticeLocArray = hearingDetailsForm.hearingDetails[i].phNoticeLoc;
        let finalPhNoticeLocArray: any = [];

        for (let j = 0; j < phNoticeLocArray.length; j++) {
          const element = phNoticeLocArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const phNoticeDetalis = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalPhNoticeLocArray.push(phNoticeDetalis);
          }
        }
        hearingDetailsForm.hearingDetails[i].phNoticeLoc = finalPhNoticeLocArray;

        // Manipulation For addSubLoc
        const addSubLocArray = hearingDetailsForm.hearingDetails[i].addSubLoc;
        let finalAddSubLocArray: any = [];

        for (let k = 0; k < addSubLocArray.length; k++) {
          const element = addSubLocArray[k];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const addSubDetalis = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalAddSubLocArray.push(addSubDetalis);
          }
        }
        hearingDetailsForm.hearingDetails[i].addSubLoc = finalAddSubLocArray;

        // Manipulation For phRecordIfAnyLoc
        const phRecordIfAnyLocArray = hearingDetailsForm.hearingDetails[i].phRecordIfAnyLoc;
        let finalPhRecordIfAnyLocArray: any = [];

        for (let l = 0; l < phRecordIfAnyLocArray.length; l++) {
          const element = phRecordIfAnyLocArray[l];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const phRecordIfAnyDetalis = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalPhRecordIfAnyLocArray.push(phRecordIfAnyDetalis);
          }
        }
        hearingDetailsForm.hearingDetails[i].phRecordIfAnyLoc = finalPhRecordIfAnyLocArray;

        if ((!checkHearingDetailsNotEmpty) && (singleHearingDetails.phScheduledDate != null || singleHearingDetails.attended != null || singleHearingDetails.consultant != null || singleHearingDetails.addWrittenSubDate != null || singleHearingDetails.phDiscNotes != null || singleHearingDetails.phNoticeLoc.length > 0 || singleHearingDetails.addSubLoc.length > 0 || singleHearingDetails.phRecordIfAnyLoc.length > 0)) {
          checkHearingDetailsNotEmpty = true;
        }
      }
      if (checkHearingDetailsNotEmpty) {
        finalPayload.hearingDetails = hearingDetailsForm.hearingDetails;
      }

      // Preparation For Dept Response Details
      const conclusionForm = this.conclusionForm.value;
      let checkDeptResponseNotEmpty = false;
      for (let i = 0; i < conclusionForm.deptResponseDetails.length; i++) {
        const singleDeptResponse = conclusionForm.deptResponseDetails[i];
        // Manipulation For attchmentsLoc
        const attchmentsLocArray = conclusionForm.deptResponseDetails[i].attchmentsLoc;
        let finalAttchmentsLocArray: any = [];
        for (let j = 0; j < attchmentsLocArray.length; j++) {
          const element = attchmentsLocArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const attchmentsLocDetails = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalAttchmentsLocArray.push(attchmentsLocDetails);
          }
        }
        conclusionForm.deptResponseDetails[i].attchmentsLoc = finalAttchmentsLocArray;
        if ((!checkDeptResponseNotEmpty) && (singleDeptResponse.din != null || singleDeptResponse.respReceiptDate != null || singleDeptResponse.replyDueDate != null || singleDeptResponse.respSummary != null || singleDeptResponse.attchmentsLoc.length > 0)) {
          checkDeptResponseNotEmpty = true;
        }
      }
      if (checkDeptResponseNotEmpty) {
        finalPayload.deptResponseDetails = conclusionForm.deptResponseDetails;
      }

      let appealDetailsData = this.conclusionForm.get('appealDetails') as UntypedFormArray;
      let appealDetailsDetalis: any = {};
      let appealDetailsArray: any = [];

      appealDetailsData.controls.forEach(element => {
        appealDetailsDetalis = {
          action: element.value.action,
          forum: element.value.forum,
          isCaseCreated: element.value.isCaseCreated,
        }
        appealDetailsArray.push(appealDetailsDetalis);
      });

      finalPayload.phase = phaseTab;
      finalPayload.action = 'UPDATE';
      finalPayload.caseType = "NEW";
      finalPayload.pan = this.generalInfo.controls.pan.value;
      finalPayload.panName = this.generalInfo.controls.panName.value;
      finalPayload.ay = this.generalInfo.controls.ay.value;
      finalPayload.caseId = this.generalInfo.controls.caseId.value;
      finalPayload.finYear = this.generalInfo.controls.finYear.value;
      finalPayload.consultant = this.generalInfo.controls.consultant.value;
      finalPayload.personResp = this.generalInfo.controls.personResp.value;
      finalPayload.caseSummary = this.generalInfo.controls.caseSummary.value;
      finalPayload.internalRemarks = this.generalInfo.controls.internalRemarks.value;
      finalPayload.caseLabel = this.generalInfo.controls.caseLabel.value;
      finalPayload.status = this.conclusionForm.controls.status.value;
      if (conclusionForm.appealDetails[0].action != null) {
        finalPayload.appealDetails = appealDetailsDetalis;
      }
      console.log(finalPayload, "finalPayload");
      this.taxService.updateEnquiryproceeding(finalPayload).subscribe((response: any) => {
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 1) {
            this.isNextBtnClicked = false;
            this.isSubmittedNotice = false;
            this.isPhase2 = true;
          } else if (phaseTab === 2) {
            this.isSubmittedSubmission = false;
            this.isSubmitted = false;
            this.isSubmittedAppeal = false;
            const appealDetails = this.conclusionForm.controls.appealDetails as UntypedFormArray;
            for (let index = 0; index < appealDetails.controls.length; index++) {
              const element: any = appealDetails.controls[index]
              // if (element.controls.action.value == 'AP' && element.controls.forum.value == ' Commissioner Appeal') {
              //   this.callTabFunction.emit('');
              // } else if (element.controls.action.value == 'WP' && element.controls.forum.value == 'High Court') {
              //   this.moveToHC.emit('');
              // }
              if ((element.controls.action.value == 'CLOSED' && element.controls.forum.value == 'CLOSED') || (element.controls.action.value == 'DEPT RESPONSE PENDING' && element.controls.forum.value == 'Not Applicable')) {
                // this.router.navigate(['/directTaxSummary']);
              }
            }
          }
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showError(response.message);
        }

      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }


  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        caseId: this.caseId,
      }
      this.taxService.getCaseData(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.caseData = response.response;
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.formInitialize();
          this.isVisibleCaseId = true;

          if (this.caseData?.cc) {
            this.caseData.cc.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }
          if (this.caseData.dtNotices && this.caseData.dtNotices.length > 0) {
            this.initializeItemList();
          } else {
            this.addNotice();
          }

          // this.initializeItemList();

          if (this.caseData.deptResponseDetails && this.caseData.deptResponseDetails.length > 0) {
            this.initializeDeptResponse();
          } else {
            this.addConclusionInv();
          }

          if (this.caseData.cc && this.caseData.cc.length > 0) {
            this.initializeConsultantCharges();
          } else {
            this.addConsultantInv();
          }
          if (this.caseData.hearingDetails && this.caseData.hearingDetails.length > 0) {
            this.initializeHearingDetails();
          } else {
            this.createAdjDiv();
          }
          if (this.caseData.appealDetails) {
            this.initializeAppealDetails();
          } else {
            this.addAppealDetail();
          }
        }
      });
    }
  }

  //deptResponseDetails functions
  initializeDeptResponse() {
    if (this.caseData.deptResponseDetails != undefined) {
      this.caseData.deptResponseDetails.forEach((data: any) => {
        let detailOfDeptResponse: UntypedFormGroup = this.conclusionFrmGrp(data);
        (this.conclusionForm.get('deptResponseDetails') as UntypedFormArray).push(detailOfDeptResponse);

        if (data.attchmentsLoc != undefined) {
          data.attchmentsLoc.forEach((not: any) => {
            if (data.attchmentsLoc.length > 0) {
              let attchmentsLocFrmGrp: UntypedFormGroup = this.createAttchment(not);
              (detailOfDeptResponse.get('attchmentsLoc') as UntypedFormArray).push(attchmentsLocFrmGrp);
            }
          });
        }
      });
    }
  }

  initializeConsultantCharges() {
    if (this.caseData.cc != undefined) {
      this.caseData.cc.forEach((data: any) => {
        let detailOfConsultant: UntypedFormGroup = this.ConsultantCharges(data);
        (this.consultantForm.get('cc') as UntypedFormArray).push(detailOfConsultant);
        if (data.supportingDocsLoc != undefined) {
          data.supportingDocsLoc.forEach((not: any) => {
            if (data.supportingDocsLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createConsultant(not);
              (detailOfConsultant.get('supportingDocsLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }
      });
      this.feesChargedChanged();
    }
  }

  // hearingDetails functions
  initializeHearingDetails() {
    if (this.caseData.hearingDetails != undefined) {
      this.caseData.hearingDetails.forEach((data: any) => {
        let hearingDetailFrmGrp: UntypedFormGroup = this.newphArray(data);
        (this.hearingForm.get('hearingDetails') as UntypedFormArray).push(hearingDetailFrmGrp);

        if (data.phNoticeLoc != undefined) {
          data.phNoticeLoc.forEach((not: any) => {
            if (data.phNoticeLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createPhNotice(not);
              (hearingDetailFrmGrp.get('phNoticeLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }

        if (data.addSubLoc != undefined) {
          data.addSubLoc.forEach((not: any) => {
            if (data.addSubLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createAddSub(not);
              (hearingDetailFrmGrp.get('addSubLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }

        if (data.phRecordIfAnyLoc != undefined) {
          data.phRecordIfAnyLoc.forEach((not: any) => {
            if (data.phRecordIfAnyLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createPhRecordIfAny(not);
              (hearingDetailFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }
      });
    }
  }

  //appealDetails functions
  initializeAppealDetails() {
    if (this.caseData.appealDetails != undefined) {
      let appealDetailFrmGrp = this.appealDetailFrmGrp(this.caseData.appealDetails);
      (this.conclusionForm.get('appealDetails') as UntypedFormArray).push(appealDetailFrmGrp);
    }
  }


  //initialization

  initializeItemList() {
    this.caseData.dtNotices.forEach((letter: any) => {
      if (!letter.meetingDetails && !letter.submissions && !letter.reqDetails) {
        letter.meetingDetails = [];
        letter.submissions = [];
        letter.reqDetails = [];
      }
      let noticesListGrp: UntypedFormGroup = this.createNotice(letter);
      (this.noticeForm.get('dtNotices') as UntypedFormArray).push(noticesListGrp);
      if (letter.adjSubDetails && letter.adjSubDetails.length > 0) {
        letter.adjSubDetails.forEach((adjSub: any) => {
          let adjSubDetailsFrmGrp: UntypedFormGroup = this.initAjaSubDetailsFrmGrp(adjSub);
          (noticesListGrp.get('adjSubDetails') as UntypedFormArray).push(adjSubDetailsFrmGrp);
          if (adjSub.adjDocsLoc) {
            adjSub.adjDocsLoc.forEach((docSub: any) => {
              let adjDocsLocGrp: UntypedFormGroup = this.createAdjDocDetails(docSub);
              (adjSubDetailsFrmGrp.get('adjDocsLoc') as UntypedFormArray).push(adjDocsLocGrp);
            });
            const leftAckCopyLoc = 5 - (adjSubDetailsFrmGrp.get('adjDocsLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftAckCopyLoc; i++) {
              (adjSubDetailsFrmGrp.get('adjDocsLoc') as UntypedFormArray).push(this.createAdjDocDetails());
            }
          }
        });
      }
      if (letter.reqDetails && letter.reqDetails.length > 0) {
        letter.reqDetails.forEach((req: any) => {
          let reqDetailsFrmGrp: UntypedFormGroup = this.initreqDetailsFrmGrp(req);
          (noticesListGrp.get('reqDetails') as UntypedFormArray).push(reqDetailsFrmGrp);
        });
      } else {
        (noticesListGrp.get('reqDetails') as UntypedFormArray).push(this.initreqDetailsFrmGrp());
      }
      if (letter.noticeLoc) {
        letter.noticeLoc.forEach((not: any) => {
          let noticeLocFrmGrp: UntypedFormGroup = this.createnoticeLoc(not);
          (noticesListGrp.get('noticeLoc') as UntypedFormArray).push(noticeLocFrmGrp);
        });
      }
      if (letter.issuingAuthority && letter.issuingAuthority.length > 0) {
        letter.issuingAuthority.forEach((issuing: any) => {
          let issuingAuthorityFrmGrp: UntypedFormGroup = this.initIssuingAuthorityFrmGrp(issuing);
          (noticesListGrp.get('issuingAuthority') as UntypedFormArray).push(issuingAuthorityFrmGrp);
        });
      }
      if (letter.meetingDetails && letter.meetingDetails.length > 0) {
        letter.meetingDetails.forEach((meeting: any) => {
          let meetingDetailsFrmGrp: UntypedFormGroup = this.detailsOfDepartmentFrmGrp(meeting);
          (noticesListGrp.get('meetingDetails') as UntypedFormArray).push(meetingDetailsFrmGrp);

          if (meeting.copyOfSubmissionLoc) {
            meeting.copyOfSubmissionLoc.forEach((docSub: any) => {
              let copyOfSubmissionLocGrp: UntypedFormGroup = this.createCopyOfSub(docSub);
              (meetingDetailsFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).push(copyOfSubmissionLocGrp);
            });
            const leftcopyOfSubmissionLoc = 5 - (meetingDetailsFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftcopyOfSubmissionLoc; i++) {
              (meetingDetailsFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).push(this.createCopyOfSub());
            }
          }
        });
      } else {
        (noticesListGrp.get('meetingDetails') as UntypedFormArray).push(this.detailsOfDepartmentFrmGrp());
      }

      if (letter.submissions && letter.submissions.length > 0) {
        letter.submissions.forEach((sub: any) => {
          let submissionsFrmGrp: UntypedFormGroup = this.initSubmissionFrmGrp(sub);
          (noticesListGrp.get('submissions') as UntypedFormArray).push(submissionsFrmGrp);
          if (sub.ackCopyLoc) {
            sub.ackCopyLoc.forEach((docSub: any) => {
              let ackCopyLocGrp: UntypedFormGroup = this.createAckCopyLoc(docSub);
              (submissionsFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(ackCopyLocGrp);
            });

            const leftAckCopyLoc = 5 - (submissionsFrmGrp.get('ackCopyLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftAckCopyLoc; i++) {
              (submissionsFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(this.createAckCopyLoc());
            }
          }
          if (sub.subCopyLoc) {
            sub.subCopyLoc.forEach((docSub: any) => {
              let subCopyGrp: UntypedFormGroup = this.createSubLoc(docSub);
              (submissionsFrmGrp.get('subCopyLoc') as UntypedFormArray).push(subCopyGrp);
            });

            const leftSubCopyLoc = 5 - (submissionsFrmGrp.get('subCopyLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftSubCopyLoc; i++) {
              (submissionsFrmGrp.get('subCopyLoc') as UntypedFormArray).push(this.createSubLoc());
            }
          }

          if (!sub.subDetails) {
            sub.subDetails = [];
          }
          if (sub.subDetails && sub.subDetails.length > 0) {
            sub.subDetails.forEach((adjSub: any) => {
              let subDetailsFrmGep: UntypedFormGroup = this.initsubDetailsFrmGrp(adjSub);
              (submissionsFrmGrp.get('subDetails') as UntypedFormArray).push(subDetailsFrmGep);
              if (adjSub.docLoc) {
                adjSub.docLoc.forEach((docSub: any) => {
                  let docLocGrp: UntypedFormGroup = this.createDocLo(docSub);
                  (subDetailsFrmGep.get('docLoc') as UntypedFormArray).push(docLocGrp);
                });

                const leftSubCopyLoc = 5 - (subDetailsFrmGep.get('docLoc') as UntypedFormArray).length;
                for (let i = 0; i < leftSubCopyLoc; i++) {
                  (subDetailsFrmGep.get('docLoc') as UntypedFormArray).push(this.createDocLo());
                }
              }
            });
          } else {
            (submissionsFrmGrp.get('subDetails') as UntypedFormArray).push(this.initsubDetailsFrmGrp());
          }
        });
      } else {
        if (this.action === 'create') {
          (noticesListGrp.get('submissions') as UntypedFormArray).push(this.initSubmissionFrmGrp());
        }
      }
    });
    this.noticeForm.patchValue(this.caseData);
  }

  /*upload function  start */
  onFileSelect(event: any, identifier?: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, identifier);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }

  onUpload(selectedfield: any, identifier?: any) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'NEW',
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield, identifier);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any, identifier?: string) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      var toupdate = this.noticeForm.value;
      var toTaxUpdate = this.hearingForm.value;
      var updateConclusion = this.conclusionForm.value;
      var updateConsultant = this.consultantForm.value
      if (identifier == "notice") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.noticeForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toupdate = toupdate[pathToVeriabl[pathIndex]];
            } else {
              toupdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.noticeForm.patchValue(this.noticeForm.value);

      } else if (identifier == "hearing") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.hearingForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toTaxUpdate = toTaxUpdate[pathToVeriabl[pathIndex]];
            } else {
              toTaxUpdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.hearingForm.patchValue(this.hearingForm.value);

      } else if (identifier == "consultantData") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.consultantForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              updateConsultant = updateConsultant[pathToVeriabl[pathIndex]];
            } else {
              updateConsultant[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.consultantForm.patchValue(this.consultantForm.value);

      } else if (identifier == "conclusion") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.conclusionForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              updateConclusion = updateConclusion[pathToVeriabl[pathIndex]];
            } else {
              updateConclusion[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.conclusionForm.patchValue(this.conclusionForm.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }

  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  /*upload function  end*/

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.taxService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.taxService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  /* get download file path function */

  /*adjSubDetails array */
  personResponsibleData() {
    let model = {
      companyid: this.selectedCompanyId
    }
    this.taxService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

  }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        const userMailID: any = sessionStorage.getItem('UserId');

      // Check if userMailID exists in consultantList
      const matchingConsultant = this.consultantList.find(
        (consultant: any) => consultant.email === userMailID
      );
      if (matchingConsultant) {
        this.generalInfo.controls.consultant.patchValue(matchingConsultant.consultantName);
      }
        }
    });
  }

  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC'){
      this.isToggleConsultantDropdownCC = !this.isToggleConsultantDropdownCC;
    }
  }

  formatTableValues() {
    const consultantChargesArray = this.consultantForm.controls.cc as UntypedFormArray

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }
  }

  inrFormattingRemoving() {
    var data = this.consultantForm.value;
    if (data.cc && data.cc.length) {
      data.cc.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }

  }

  //   click function of next bttons 
  submitgeneralInfo() {
    this.isNextBtnClicked = true;
    if (this.generalInfo.valid) {
      this.tab2Disable = true;
      this.noticeTab.nativeElement.click();
      console.log(this.generalInfo.value)
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  submitNoticeForm(i: number) {
    console.log(this.noticeForm, "noticeForm")
    this.isSubmittedNotice = true;
    this.p1validation();
    var dtNotices = this.getFormControlNotice();
    let noticeLoc;
    for (let i = 0; i < dtNotices.length; i++) {
      noticeLoc = (dtNotices[i].get('noticeLoc') as UntypedFormArray).controls;
    }
    var count = noticeLoc?.some(x =>
      x.get('fileLoc')?.value != null
    );
    if (!count) {
      this.toaster.showError("Required Fields Are Empty");
      return;
    }
    if (this.noticeForm.valid) {
      this.tab3Disable = true;
      this.saveNotice(i);
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }

  // this for Phase 1 Update api
  submitNoticeFormUpdate(phaseTab: number) {
    this.isSubmittedNotice = true;
    if (!this.noticeForm.valid) {
      this.toaster.showError("Required Fields Are Empty");
    }
    this.updatePhaseTwo(phaseTab);
  }



  submitSubmissionForm() {
    this.isSubmittedSubmission = true;
    this.p2validation();
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let element1;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];
      var submissionArray: any = element.controls.submissions.controls;
      for (let i = 0; i < submissionArray.length; i++) {
        element1 = (submissionArray[i].get('ackCopyLoc') as UntypedFormArray).controls;
      }
    }
    var count = element1?.some((x: any) =>
      x.get('fileLoc')?.value != null
    );
    if (!count) {
      this.toaster.showError("Required Fields Are Empty");
      return;
    }
    if (this.noticeForm.valid) {
      this.tab4Disable = true;
      this.hearingTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  hearingSubmit() {
    console.log(this.hearingForm, "hearingForm")
    this.isSubmittedPhRecord = true;
    if (this.hearingForm.valid) {
      this.tab5Disable = true;
      this.consultantTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  consultantSubmit() {
    this.isSubmitted = true;
    this.consultantValidation();
    console.log(this.consultantForm, "consultantForm")
    if (this.consultantForm.valid) {
      this.tab6Disable = true;
      this.conclusionTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }


  // this for Phase 2 Update api
  conclusionSubmitForm(phaseTab: number) {
    this.isSubmittedSubmission = true;
    this.isSubmittedAppeal = true;
    this.p2validation();
    if (!this.noticeForm.valid && this.conclusionForm.valid) {
      this.toaster.showError("Required Fields Are Empty");

    }
    this.updatePhaseTwo(phaseTab);
  }

  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }

  saveBtn() {
    let id = document.getElementById('isNext');
    this.nextBtn.nativeElement.click();
    this.updateBtn = true;
  }
  updatePhase1Btton() {
    let id = document.getElementById('isupdate');
    this.update.nativeElement.click();
    this.updateBtn = true;
  }


}

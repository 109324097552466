import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    private http: HttpClient
  ) { }

  getSubscriptionPlan(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/subscription/active?pan=${data.pan}`).pipe(map((resp) => {
      return resp;
    }));
  }

  getCorporatePlan(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/subscription/subscriptionPlan?`, {
      params: {
        subscriptionCode: (data.subCode != undefined ? data.subCode : data)
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }

  addBusinessFunction(data: any) {
    return this.http.put(`${environment.apiUrl}/mgmt/company/business`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  viewEntity(comId: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/business?`, {
      params: {
        companyid: comId
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }
  updateBusiness(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/company/business`, data).pipe(map((response) => {
      return response;
    }));
  }
  getGstinData(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/ctinDetail/fetchCtinDetail?`, {
      params: {
        ctin: data
      }
    }).pipe(map((resp) => {
      return resp;
    }));
  }
  addGstinFunction(data: any) {
    return this.http.put(`${environment.apiUrl}/mgmt/company/business`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  updateNIC(data: any) {
    return this.http.post(`${environment.apiUrl}/mgmt/company/updateNICCredentials`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  fetchImages() {
    return this.http.get(`${environment.apiUrl}/onyx/einvoice/logo/viewall`).pipe(map((resp) => {
      return resp;
    }));
  }

  newtemplate() {
    return this.http.put(`${environment.apiUrl}/onyx/einvoice/template/add`, {}).pipe(map((resp) => {
      return resp;
    }));
  }

  saveTemplate(data: any) {
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/template/save`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  getTemplateById(id: any) {
    return this.http.get(`${environment.apiUrl}/onyx/einvoice/template/view?id=${id}`).pipe(map((resp) => {
      return resp;
    }));
  }

  getAllTemplates() {
    return this.http.get(`${environment.apiUrl}/onyx/einvoice/template/viewall`).pipe(map((resp) => {
      return resp;
    }));
  }

  changeTemplateStatus(data: any) {
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/template/update`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  delTemplate(id: any) {
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/template/delete?id=${id}`, {}).pipe(map((resp) => {
      return resp;
    }));
  }

  previewPDF(html: any) {
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/template/preview`, html, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }

  uploadAsset(name: any, fD: any) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('img', fD);
    return this.http.post(`${environment.apiUrl}/onyx/einvoice/logo/add`, formData).pipe(map((resp) => {
      return resp;
    }));
  }

  getGstinDiv() {
    return this.http.get(`${environment.apiUrl}/lms2/compdivision/get`).pipe(map((resp) => {
      return resp;
    }));
  }

  createDueDate(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/compdivision/create`, data).pipe(map((response) => {
      return response;
    }));
  }
  addPreGst(data: any) {
    // return this.http.post(`${environment.apiUrl}/lms/pregstreg-save`, data).pipe(map((resp) => {
    //   return resp;
    // }));

    return this.http.post(`${environment.apiUrl}/lms2/pregstreg/create`, data);
  }

  updatePreGst(data: any) {
    return this.http.put(`${environment.apiUrl}/lms2/pregstreg/update`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  getPreGstRegData(data: any) {
    return this.http.get(
      data.panId ? `${environment.apiUrl}/lms2/pregstreg/query?companyId=${data.companyId}&pan=${data.panId}`
        : `${environment.apiUrl}/lms2/pregstreg/query?companyId=${data.companyId}`).pipe(map((resp) => {
          return resp;
        }));
  }
  getPreGstRegTagData(data: any) {
    return this.http.get(
      data.panId ? `${environment.apiUrl}/lms2/pregstreg/query?companyId=${data}&pan=${data.panId}`
        : `${environment.apiUrl}/lms2/pregstreg/query?companyId=${data}`).pipe(map((resp) => {
          return resp;
        }));
  }

  getDivisionMapping(companyId: any, userId: any, page: number, size: number) {
    const params = {
      companyId: companyId,
      userMailId: userId,
      page: page.toString(),
      size: size.toString()
    };
    return this.http.get(`${environment.apiUrl}/mgmt/user/company/getGstinDivMapping`, { params });
  }
  getemailOptOutData() {
    return this.http.get(`${environment.apiUrl}/lms2/optOut/get`).pipe(map((resp) => {
      return resp;
    }));
  }
  submitEmailOptOutFunction(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/optOut/create?optOut=${data}`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  getIntCalOptOutData() {
    return this.http.get(`${environment.apiUrl}/lms2/gst/get/interestCalc/Activation`).pipe(map((resp) => {
      return resp;
    }));
  }
  submitIntCalOptOutFunction(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/gst/create/interestCalc/Config?isActivated=${data}`, data).pipe(map((resp) => {
      return resp;
    }));
  }
}


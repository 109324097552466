import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  AccessTabData$ = new BehaviorSubject<any>({ ailFlag: false, aflFlag: false });

  constructor(
    private http: HttpClient
  ) { }
  private selectedFilling: Subject<any> = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('selectedFilling') || '{}'));
  selectedFilling$ = this.selectedFilling.asObservable();
  modifyFilling(data: any) {
    this.selectedFilling.next(data);
  }
  /*save company gst details */
  saveCompGstData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/companydetails/save`, data).pipe(map((response) => {
      return response;
    }));
  }
  /*save company gst details */
  /*fetch company gst details */
  getCompGstData(gstinOrPan: any) {
    return this.http.get(`${environment.apiUrl}/lms2/companydetails/get?gstinOrPan=${gstinOrPan}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*fetch company gst details */
  /*upload file */
  uploadFiles(data: any, urlData: any) {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),

    };
    return this.http.get(`${environment.apiUrl}/lms2/common/upload?gstinOrPan=${urlData.gstinOrPan}&type=${urlData.type}&field=${urlData.field}&fname=${data.name}`, options).pipe(map((resp) => {
      return resp;
    }));
  }

  uploadFile(requestBody: any, a?: any) {
    return this.http.post(`${environment.apiUrl}/lms1/file/sfsdfsdfdgfete/upload`, requestBody).pipe(map((resp) => {
      return resp;
    }));
  }

  uploadReconFile(data: any, urlData: any) {
    //this.companyId = this.GS.getSetCompanyId();
    let options = {
      headers: new HttpHeaders({
        // 'Content-Type': data.type,
        "Content-Type": "application/json",
        //'companyId': '7676'
      }),

    };
    return this.http.get(`${environment.apiUrl}/lms2/common/upload?reconId=${urlData.reconId}&type=${urlData.type}&field=${urlData.field}&fname=${data.name}&fmime=${data.type}`, options).pipe(map((resp) => {
      return resp;
    }));
  }
  getUploadUrl(formdata: FormData, data: any) {
    //getUploadUrl(formdata: FormData, data: any): Observable<any> {
    const headers = {
      'Content-Type': data.fmime,
      'fmime': data.fmime,
      'fname': data.fname
    };
    let url = data.url;
    return this.http.put<any>(url, formdata, { headers }).pipe(map((resp) => {
      return resp;
    }));
  }
  /*upload file */
  /*get file name */
  getFileName(fileUrl: any) {
    if (fileUrl) {
      let index = fileUrl.lastIndexOf('/');
      return fileUrl.substring(index + 1);
    }
  }

  /*get file name */
  /*download file */
  downloadFile(urlData: any) {
    let options = {
      headers: new HttpHeaders({
        //'companyId': '7676'
      }),

    };
    return this.http.get(`${environment.apiUrl}/lms2/common/download?fname=${urlData.fname}`, options).pipe(map((resp) => {
      return resp;
    }));
  }
  /*download file */
  /*fetch recon details */
  getReconData(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/audit/recon/get?gstin=${data.gstin}&finYear=${data.finYear}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*fetch recon details */
  /*save recon details */
  saveReconData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/audit/recon/save`, data).pipe(map((response) => {
      return response;
    }));
  }
  /*save recon details */


  /*fetch tax post details */
  getTaxPosData(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/audit/taxpos/get?gstinOrPan=${data.gstinOrPan}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*fetch tax post details */
  /*save tax post details */
  saveTaxPosData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/audit/taxpos/save`, data).pipe(map((response) => {
      return response;
    }));
  }
  /*save tax post details */
  /*create Audit */
  saveIntimLetterData(data: any, urlData: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.apiUrl}/lms/audit-create?type=${urlData.type}&gstin=${urlData.gstin}&companyId=${companyId}`, data).pipe(map((response) => {
      return response;
    }));
  }
  /*create Audit*/
  /*upload audit module file */
  // uploadAuditFile(data: any, urlData: any) {
  //   let options = {
  //     headers: new HttpHeaders({
  //       // 'Content-Type': data.type,
  //       "Content-Type": "application/json",
  //       //'companyId': '7676'
  //     }),

  //   };
  //   return this.http.get(`${environment.apiUrl}/lms/case-file-upload?auditId=${urlData.auditId}&type=${urlData.type}&field=${urlData.field}&fname=${data.name}`, options).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  /*upload audit module file */
  /*Audit summary data*/
  // getAuditSummary(data: any) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   return this.http.post(`${environment.apiUrl}/lms/audit-query?gstin=${data.gstin}&pan=${data.pan}&st=${data.st}&companyId=${companyId}`, data).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  // getAuditFilterSummary(data: any, type: any) {
  //   let url = `${environment.apiUrl}/lms/audit-query`;
  //   data.type = type;
  //   data.gstin = data.gstin;
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   data.companyId = companyId
  //   data.pan = data.pan
  //   return this.http.post(url, data).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  /*Audit summary data*/
  /*person responsible data*/
  getUserResList(data: any) {
    return this.http.get(`${environment.apiUrl}/mgmt/user/userRoleInfo?gstin=${data.gstin}&companyId=${data.companyId}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*person responsible data*/
  /*fetch audit data */
  // getAuditData(data: any) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');

  //   return this.http.get(`${environment.apiUrl}/lms/audit-fetch?type=${data.type}&auditId=${data.auditId}&companyId=${companyId}`).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  /*fetch audit data */
  /*update audit ail data*/
  // updateData(data: any, urlData: any) {
  //   return this.http.post(`${environment.apiUrl}/lms/audit-update?gstin=${urlData.gstin}&type=${urlData.type}`, data).pipe(map((response) => {
  //     return response;
  //   }));
  // }
  /*update audit ail data*/
  setAccessTabData(data: any) {
    this.AccessTabData$.next(data);
  }
  getAccessTabData() {
    return this.AccessTabData$.asObservable();
  }
  /*Audit summary count */
  /*case count data*/
  // getCount(data: any) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   return this.http.post(`${environment.apiUrl}/lms/audit-count?gstin=${data.gstin}&st=${data.st}&companyId=${companyId}`, data).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  // getFilterCount(data: any, type: any) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   data.st = type;
  //   data.gstin = data.gstin;
  //   data.companyId = companyId
  //   data.pan = data.pan
  //   let url = `${environment.apiUrl}/lms/audit-count?gstin=${data.gstin}&st=${data.st}&companyId=${companyId}`;
  //   return this.http.post(url, data).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  /*Audit summary count */
  getAllCaseCount(data: any) {
    return this.http.post(`${environment.baseUrl}/audit/count`, data);
  }
  getAllCaseSummary(data: any) {
    return this.http.post(`${environment.baseUrl}/audit/fetch/all`, data);
  }
  saveAilData(data: any) {
    return this.http.post(`${environment.baseUrl}/audit/ail/create`, data);
  }
  saveAflData(data: any) {
    return this.http.post(`${environment.baseUrl}/audit/afl/create`, data);
  }

  updateAilData(data: any, urlData: any) {
    return this.http.post(`${environment.baseUrl}/audit/ail/update?gstin=${urlData.gstin}`, data);
  }
  updateAflData(data: any, urlData: any) {
    return this.http.post(`${environment.baseUrl}/audit/afl/update?gstin=${urlData.gstin}`, data);
  }

  getAllCaseSummaryAil(data: any) {
    return this.http.get(`${environment.baseUrl}/audit/ail/get?auditId=${data.auditId}`);
  }
  getAllCaseSummaryAfl(data: any) {
    return this.http.get(`${environment.baseUrl}/audit/afl/get?auditId=${data.auditId}`);
  }

  commonUpload(data: any, urlData: any) {
    return this.http.get(`${environment.apiUrl}/lms2/common/upload?gstinOrPan=${urlData.gstinOrPan}&field=${urlData.field}&fname=${data.name}&type=${urlData.type}`);
  }

     // Duplicate Check 
     getIssueRefData(data: any) {
      return this.http.post(`${environment.apiUrl}/lms2/api/issueRef`, data);
    }
  
    getDuplicateData(data: any) {
      return this.http.post(`${environment.apiUrl}/lms2/api/duplicate/check`, data);
    }
    deleteAudit(data: any) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: data
      }
      return this.http.delete(`${environment.apiUrl}/lms2/audit/delete`, httpOptions);
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NoticeServiceService } from '../../services/notification.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { forkJoin } from 'rxjs';
import { add } from 'lodash-es';

@Component({
  selector: 'app-gst-summary',
  templateUrl: './gst-summary.component.html',
  styleUrls: ['./gst-summary.component.css']
})
export class GstSummaryComponent implements OnInit {
  navContext: any;
  noticeCntNew: any;
  noticeCnt: any;
  subCntNew: any;
  subCnt: any;
  adNoticeCntNew: any;
  addVPCountNew:any;
  addLUTCountNew:any;
  addNoticeCnt:any;
  addVPCount:any;
  addLUTCount:any;
  orgSetting!: UntypedFormGroup;
  formData: any = {};
  isSubmitted: boolean = false;
  isDisabled: boolean = true;
  selected: boolean = false;
  navContextData: any;
  companyId: any;
  orgID: any;
  selectedGstin: any;
  selectedFilling: any;
  orgData: any;
  apiKey: any;
  changeNotification : any;
  selectedOpt:any;


  constructor(
    private router: Router,
    private noticeService: NoticeServiceService,
    private fBuild: UntypedFormBuilder,
    private getterSetter: GetterSetterService,
    private toaster: ToasterService,
    private businessService: BusinessService,
    // private modalService: NgbModal,
  ) {
    this.navContextData = JSON.parse(this.getterSetter.getNavContextData() || '{}');
  }

  ngOnInit() {
    this.navContext = JSON.parse(sessionStorage.getItem('navContext') || '{}');
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.companyId = this.navContextData.companyId;
    this.noticeCount();
    this.initSettingData();
    this.selected = this.selectedOpt;

  }
  toNoticeOrder(isNew: any) {
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/gstNoticetOrder']);
  }
  toSubmission(isNew: any) {
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/gstSubmission']);
  }
  toAdditionalOrder(isNew: any) {
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/gstAdditionalOrder']);
  }
  toVoluntaryPayment(isNew: any){
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/gstVoluntaryPayment']);
  }
  toLetterOfUndertaking(isNew: any){
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/gstLetterOfUndertaking']);
  }
  getOrgData() {
    let urlData = {
      companyId: this.getterSetter.getSetCompanyId()
    }
    this.noticeService.getOrgID(urlData).subscribe((response: any) => {
      if (response.status === 1) {
        this.orgData = response.response;
        this.settingFormInit();
      }
    });
  }
  initSettingData() {
    if (this.navContextData.companyId != null || this.navContextData.orgId != null) {
      this.companyId = this.navContextData.companyId;
    } else {
      this.companyId = this.getterSetter.getSetCompanyId();
    }
    this.businessService.viewEntity(this.companyId).subscribe((response: any) => {
      if (response.status === "SUCCESS") {
        if (response.response !== null && response.response !== "") {
          this.formData = response.response;
        }
      } else {
        this.toaster.showError(response.message);
      }
      this.settingFormInit();
    });
  }
  settingFormInit() {
    this.orgSetting = this.fBuild.group({
      companyname: [this.formData.companyName],
      pan: [this.formData.panNo],
      companyId: [this.companyId],
      orgId: [this.orgData?.orgId],
      apiKey: [this.orgData?.apiKey]
    });
  }
  saveSetting() {
    this.isSubmitted = true;
    if (this.orgSetting.valid) {
      if (this.navContextData.companyId != null) {
        this.orgSetting.value.companyid = this.formData.companyId;
      }
      this.noticeService.saveOrgId(this.orgSetting.value).subscribe((response: any) => {
        if (response.status == 1) {
          this.formData = {};
          this.toaster.showSuccess(response.response);
        } else {
          this.toaster.showWarning(response.errorList[0].message);
        }
        this.isSubmitted = false;
      });
    }
  }

  noticeCount() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    let model: any = {};
    model.companyId = localStorage.getItem('selectedId');
    model.sortdir = "ASC",
    model.sortfield = "createdAt",
    model.newRecord = isNewCase === "Newnotice"
    model.criterias = [];

    const entityCrieterias : any = [{
      "p":"gstin",
      "o":"in",
      "v":this.selectedGstin
    }] 
    const entityCrieteriasPan : any = [{
      "p":"pan",
      "o":"in",
      "v":this.navContext.pan
    }]

    if (this.navContext.entityType === 'FILING') model.criterias = [...entityCrieterias];
    if (this.navContext.entityType === 'LEGAL') model.criterias = [...entityCrieteriasPan];
    const getNoticeCount1 = this.noticeService.getNoticeCount(model);
    const getAdditionalNoticeCount = this.noticeService.getAdditionalNoticeCount(model);

    console.log('Summary page Payload', model)
    forkJoin([getNoticeCount1, getAdditionalNoticeCount]).subscribe(([noticeResponse, additionalResponse]) => {
      if (noticeResponse.status === 1 && additionalResponse.status===1) {
        this.noticeCntNew = noticeResponse.response.newNoticeCount;
        this.noticeCnt = noticeResponse.response.noticeCount;
        this.subCntNew = noticeResponse.response.newSubmissionCount;
        this.subCnt = noticeResponse.response.submissionCount;
        this.addNoticeCnt = additionalResponse.response.additionalNoticeCount;
        this.adNoticeCntNew = additionalResponse.response.newAdditionalNoticeCount;
        this.addVPCount = additionalResponse.response.vlntryPymntNoticeCount;
        this.addLUTCount = additionalResponse.response.leutNoticeCount;
        this.addVPCountNew = additionalResponse.response.newVlntryPymntNoticeCount;
        this.addLUTCountNew = additionalResponse.response.newLeutNoticeCount;

      }
    })
  }



  isOrgIdDisabled(): boolean {
    return !!this.orgSetting.controls.orgId.value;
  }
  isApiKeyDisabled(): boolean {
    return !!this.orgSetting.controls.apiKey.value;
  }


  
  submitautoCaseData() {  
    this.noticeService.saveNMId(this.changeNotification).subscribe((response: any) => {    
   
      if (response.status == 1 && this.changeNotification == true) {    
        this.toaster.showSuccess('Auto schedular for Auto case creation has been ACTIVATED');    
        
      } else {
        this.toaster.showSuccess('Auto schedular for Auto case creation has been DEACTIVATED');
      }     
    });
  } 

  changeValue(selected:any){ 
    if(selected == false){
      this.changeNotification = true;
    }else{
      this.changeNotification = false;
    }
    this.isDisabled = false;
  }
getAutoCaseData:any;
  getData() {    
    let urlData = {
      companyId: this.getterSetter.getSetCompanyId()
    }
    this.noticeService.getNMData(urlData).subscribe((response: any) => {
      if (response.status === 1) {
        this.getAutoCaseData = response.response;
        this.selectedOpt = this.getAutoCaseData.updateFlag;
        if(this.selectedOpt == false || this.selected == false){
          this.selected = false;          
        } else{
          this.selected = true;
         
        }
      }
    });

  } 
}

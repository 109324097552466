import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, Output, EventEmitter, NgModule, TemplateRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray, FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { LETTER_GSTIN, STATE_LIST, MULTIPLE_LETTER, TAB_TYPE, PROCEED_TO_SCN, CORRESPOND_INV_AMT, ISSUE_CONCLUSION, ISSUE_RISK_PARAM, ISSUING_AUTHORITY_TYPE, TYPE_FORM_NOTICE, EXTENSION_Time_GRANTED, ISSUED_US, DESIGNATION, WHETHER_WRIT_APPLICATION, REPLY_FORM_TYPE, PAYMENT_MODE, PAYMENT, MODE_OF_PAYMENT, MODE_OF_RECEIPT, ISSUE_CONCLUSION_NOTICE, ISSUE_GST, ADJ_PH1_ADJUNOT, ADJ_ATTEND, MODE_OF_TIME_PH, MODE_OF_PH } from '../../shared/constant';
import { CaseMasterComponent } from '../case-master/case-master.component';
import { LitigationServiceService } from '../services/litigation-service.service';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { controllers } from 'chart.js';
import { PreAnalyticsComponent } from 'src/app/preGstLitigation/pre-analytics/pre-analytics.component';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { takeWhile } from 'rxjs/operators';
import { forkJoin, interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { keysIn } from 'lodash-es';
import { debug } from 'console';
import { MasterService } from 'src/app/businessManagement/services/master.service';
export enum STATUS {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
}
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  GSTIN = 'gstin',
  DIVISION = 'gstinDiv',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}

interface OcrLetterMap {
  [key: string]: {
    uploaded: boolean;
    processed: boolean;
    taskId?: string;
    filePath?: string;
    response: any;
  }
}

interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
interface tagData {
  tags: string;
}

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();
  @ViewChild('scnTrigPop', { read: TemplateRef }) scnTrigPop!: TemplateRef<any>;
  file!: ElementRef;
  fileUrl: any;
  itemList: any[] = [];
  maxDate = new Date();
  letterP1Form!: UntypedFormGroup;
  letterP2Form!: UntypedFormGroup;
  @Input() letterP1Data: any;
  p1Gstin: { key: string; value: string; }[];
  p1stateList: { stateName: string; stateCode: string; id: number; }[];
  p1mulLetter: { key: string; value: string; }[];
  tabType: { key: string; value: string; }[];
  issueConclusion: { key: string; value: string; }[];
  riskParam: { key: string; value: string; }[];
  proceededToScn: { label: string; value: string; }[];
  corresInvAmt: { label: string; value: boolean; }[];
  // added labels
  preLitPH1Preceded: { label: string; value: string; }[];
  preLitIssuedPH1Preceded: { label: string; value: string; }[];
  typeOfFormNotice: { label: string; value: string; }[];
  typeOfDesignation: any;
  replyFormType: { label: string; value: string; }[];
  modeOfAppealOptions: { key: string; value: string; }[];
  formPayment: { label: string; value: string; }[];
  modeOfPayment: any;
  extensionOfTimeGranted: { label: string; value: boolean; }[];
  whetherWritApplication: { label: string; value: boolean; }[];
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    gstin: boolean;
    caseLabel: boolean;
    gstinDiv: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      gstin: false,
      gstinDiv: false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
    };
  issueSorting = SORT_ISSUE;
  //
  currentYear: any;
  selectedFile: any;
  selectedFileName: any;
  typeVal: any;
  gstin: any;
  caseId: any;
  caseData: any;
  type: any;
  action: any;
  data: any;
  savedCaseId: any;
  selectedField: any;
  mulLetterOption: any;
  selectedTab: any;
  selectedPhase: any;
  drc03CopyLocF: any;
  getScnData: any;
  getAccessTabData: any;
  selectedFilling: any;
  selectedGstin: any;
  selectedPeriodFromDate: any;
  selectedPeriodToDate: any;
  selectedGstinDiv: any;
  selectedState: any;
  selectedStateCode: any;
  selectedStateName: any;
  navData: any;
  companyId: any;
  userList: any;
  navContext: any;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdown3 = true;
  isToggleSegmentDropdown = true;
  isToggleSegmentDropdown2 = true;
  dataSource: any;
  mulLetterobj0: any = {};
  closeResult: any = [];
  prevStage: any;
  currentTab = 'scn';
  activeTab: any;
  phase: any
  hiddenbtn = false;
  hidlabels = true;
  hidlabelsub = true;

  ocrMsgPop: any

  ocrLetterMap: OcrLetterMap = {}

  openCaseRef!: NgbModalRef;
  duplicateData: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvLetterModalRef!: NgbModalRef;
  rmvSubModalRef!: NgbModalRef;
  rmvDepartmentRef!: NgbModalRef;
  rmvConsultantModalRef!: NgbModalRef;
  rmvLiabilityModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;
  issueData: any;
  isSubmitted: boolean = false;
  isDisableIcons: boolean = false;
  isDataSaved: boolean = false;
  isUpdate: boolean = false;
  isCaseData: boolean = true;
  isCaseID: boolean = false;
  isPhase2: boolean = false;
  isP1Mulletter: boolean = false;
  isCreateBtn: boolean = false;
  isUpdateBtn: boolean = true;
  hideDiv: boolean = true;
  showFields: boolean = false;
  showClosedFields: boolean = false;
  authorityExpansion: boolean = false;
  corresInvAmtYes: boolean = false;
  corresInvAmtNo: boolean = false;
  consultantExpansion: boolean = false;
  liabilityExpansionPanel: boolean = false;
  hideOcrFiled: boolean = true;
  hideOcrFiledp2: boolean = true;
  hideRefreshIcon: boolean = false;


  sidemenu = true;

  viewIons: boolean = false;
  hideData: boolean = false;

  isUPLetterLoc: boolean = true;
  isDWLetterLoc: boolean = true;
  isletterLocFile: boolean = false;
  isletterLocText: boolean = true;

  isUPNoticeLetterLoc: boolean = true;
  isDWNoticeLetterLoc: boolean = true;
  isNoticeletterLocFile: boolean = false;
  isNoticeletterLocText: boolean = true;

  isUPotherAttac: boolean = true;
  isDWotherAttac: boolean = true;
  isotherAttacFile: boolean = false;
  isotherAttacText: boolean = true;

  //new
  isUPdocReqLoc: boolean = true;
  isDWdocReqLoc: boolean = true;
  isdocReqLocFile: boolean = false;
  isdocReqLocText: boolean = true;
  //new

  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLoc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttac2: boolean = true;
  isDWotherAttac2: boolean = true;
  isotherAttac2File: boolean = false;
  isotherAttac2Text: boolean = true;

  isUPdrc03CopyLoc: boolean = true;
  isDWdrc03CopyLoc: boolean = true;
  isdrc03CopyLocFile: boolean = false;
  isdrc03CopyLocText: boolean = true;

  //  departmentAttachmentsLocFile
  isdepartmentUPattachmentsLoc: boolean = true;
  isdepartmentDWattachmentsLoc: boolean = true;
  isdepartmentattachmentsLocFile: boolean = false;
  isdepartmentattachmentsLocText: boolean = true

  // Details of Consultant Charges
  isconsultantUPattachmentsLoc: boolean = true;
  isconsultantDWattachmentsLoc: boolean = true;
  isconsultantattachmentsLocFile: boolean = false;
  isconsultantattachmentsLocText: boolean = true;

  //  Details of Liability Admitted and Paid
  iscopyOfUPFormLoc: boolean = true;
  iscopyOfDWFormLoc: boolean = true;
  iscopyOfFormLocFile: boolean = false;
  iscopyOfFormLocText: boolean = true;

  iscopyOfBankUPChallanLoc: boolean = true;
  iscopyOfBankDWChallanLoc: boolean = true;
  iscopyOfBankChallanLocFile: boolean = false;
  iscopyOfBankChallanLocText: boolean = true;

  isUPasmt12Loc: boolean = true;
  isDWasmt12Loc: boolean = true;
  isasmt12LocFile: boolean = false;
  isasmt12LocText: boolean = true;
  isCaseCreated: boolean = false;

  uploadedfileName = '';
  uploadedFileUrlList: {
    name: string;
    url: any;
  }[] = [];
  isToggleDropdown = true;
  feesChargedTotal = 0;
  liabilityTotals = { igstTotal: 0, cgstTotal: 0, sgstTotal: 0, cessTotal: 0, interestTotal: 0, penaltyTotal: 0, allTotal: 0 };
  modeOfReceiptNotice = MODE_OF_RECEIPT;
  modeOfPH = MODE_OF_PH;
  modeOfTime = MODE_OF_TIME_PH;

  matches: any;
  roleName: any;
  autoIssueList:any;
  autoTagList:any;
  //dwddrc03CopyLoc: any;
  isViewMode: boolean = true;
  filePath: any;
  formData: FormData = new FormData();
  @Output() saveCaseId = new EventEmitter<boolean>();
  parentCaseId: any;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;
  adjPHAdjuorNotList: { label: string; value: boolean }[];
  adjAttend: { label: string; value: boolean }[];
  tagDataList: tagData[] = [] ;
  selectedCompanyId: any;
  
  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private litService: LitigationServiceService,
    private toaster: ToasterService,
    private datePipe: DatePipe,
    private activeSnapShot: ActivatedRoute,
    private commonServ: CommonService,
    private GS: GetterSetterService,
    private US: UserSettingsService,
    private modalService: NgbModal,
    private inrService: InrService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private excelService: ExcelService,
    private loaderService: LoaderService,
    private masterService : MasterService,
  ) {
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.adjAttend = ADJ_ATTEND;
    this.p1Gstin = LETTER_GSTIN;
    this.p1stateList = STATE_LIST;
    this.p1mulLetter = MULTIPLE_LETTER;
    this.tabType = TAB_TYPE;
    this.proceededToScn = PROCEED_TO_SCN;
    this.riskParam = ISSUE_RISK_PARAM;
    this.issueConclusion = ISSUE_CONCLUSION_NOTICE;
    this.corresInvAmt = CORRESPOND_INV_AMT;
    // Added label
    this.preLitIssuedPH1Preceded = ISSUED_US;
    this.preLitPH1Preceded = ISSUING_AUTHORITY_TYPE;
    this.typeOfDesignation = DESIGNATION
    this.typeOfFormNotice = TYPE_FORM_NOTICE;
    this.extensionOfTimeGranted = EXTENSION_Time_GRANTED;
    this.whetherWritApplication = WHETHER_WRIT_APPLICATION;
    this.replyFormType = REPLY_FORM_TYPE;
    this.modeOfAppealOptions = MODE_OF_RECEIPT;
    this.formPayment = PAYMENT;
    this.modeOfPayment = MODE_OF_PAYMENT;

    // *Added label
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.type = this.activeSnapShot.snapshot.params.type;
    this.activeTab = this.activeSnapShot.snapshot.params.type;

  }
  ngOnInit() {
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.navContext = this.commonServ.getNavContext();
    this.setStateAutoPop();
   
    this.litService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    this.personResponsibleData();
    this.consultantData();
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.formInitialize();
    this.setDate();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.initializeData();
    }
    if (this.action === 'create') {
      this.getCompanyTags(this.selectedGstin);
      this.createLetter(true);
      this.addConsultantInv(null);
      this.addLiabilityInv(null);
      this.createAdjDiv();
      this.isCreateBtn = true;
      this.isDisableIcons = true;
      this.isletterLocFile = true;
      this.isletterLocText = false;
      this.isUPLetterLoc = false;
      this.viewIons = false;

      this.isotherAttacFile = true;
      this.isotherAttacText = false;
      this.isUPotherAttac = false;

      //new
      this.isdocReqLocFile = true;
      this.isdocReqLocText = false;
      this.isUPdocReqLoc = false;
      //new

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isotherAttac2File = true;
      this.isotherAttac2Text = false;
      this.isUPotherAttac2 = false;

      this.isdrc03CopyLocFile = true;
      this.isdrc03CopyLocText = false;
      this.isUPdrc03CopyLoc = false;

      this.isdepartmentattachmentsLocFile = true;
      this.isdepartmentattachmentsLocText = false;
      this.isdepartmentUPattachmentsLoc = false;

      this.isconsultantattachmentsLocFile = true;
      this.isconsultantattachmentsLocText = false;
      this.isconsultantUPattachmentsLoc = false

      this.iscopyOfFormLocFile = true;
      this.iscopyOfFormLocText = false;
      this.iscopyOfUPFormLoc = false;


      this.iscopyOfBankChallanLocFile = true;
      this.iscopyOfBankChallanLocText = false;
      this.iscopyOfBankUPChallanLoc = false;

      this.isasmt12LocFile = true;
      this.isasmt12LocText = false;
      this.isUPasmt12Loc = false;


    } else if (this.action === 'update') {
      this.createAdjDiv();
      this.isDataSaved = true;
      this.isDisableIcons = true;
      this.viewIons = true;
      this.hideOcrFiled = false;

    } else if (this.action === 'view') {
      this.isDataSaved = true;
      this.isPhase2 = true;
      this.isViewMode = false;
      this.isUPLetterLoc = false;
      this.isDWLetterLoc = true;
      this.isDisableIcons = false;
      this.viewIons = false;
      this.hideOcrFiled = false;
      this.hideOcrFiledp2 = false;

      this.isUPotherAttac = false;
      this.isDWotherAttac = true;

      //new
      this.isUPdocReqLoc = false;
      this.isDWdocReqLoc = true;
      //new

      this.isUPcopyOfAckLoc = false;
      this.isDWcopyOfAckLoc = true;

      this.isUPotherAttac2 = false;
      this.isDWotherAttac2 = true;

      this.isUPdrc03CopyLoc = false;
      this.isDWdrc03CopyLoc = true;

      this.isdepartmentUPattachmentsLoc = false;
      this.isdepartmentDWattachmentsLoc = true;

      this.isconsultantUPattachmentsLoc = false;
      this.isconsultantDWattachmentsLoc = true;

      this.iscopyOfUPFormLoc = false;
      this.iscopyOfDWFormLoc = true;

      this.iscopyOfBankUPChallanLoc = false;
      this.iscopyOfBankDWChallanLoc = true;
    }
    this.issueDropdown();
    const userId: any = sessionStorage.getItem('user');
    this.letterP1Form.controls.personResponsible.patchValue(userId);

    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
      console.log('roleName 1', this.roleName)
    } else {
      this.roleName = localStorage.getItem('roleName');
      console.log('roleName 2', this.roleName)
    }
  }
  ngAfterViewInit() {
    if (this.action === 'update') {
      this.chkFrmValidated();
    }
  }

  onClickToggleDropdown() {
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  /* current year funtion */
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  /* Tab Cahabge function */
  tabChanged(tab: any, check?: any) {
    this.selectedTab = tab;
    this.letterP1Form.patchValue(this.letterP1Form.value);
    if (tab === 'tab2') {
      if (this.letterP1Form.value.status === 'MOVED') {
        this.letterP1Form.value.status = 'SCN TRIGGERED';
        this.letterP1Form.controls['status'].setValue('SCN TRIGGERED');
        this.showFields = true;
      }
      if (this.letterP1Form.value.status === 'CLOSED') {
        this.letterP1Form.controls['status'].setValue('CLOSED');
        this.showClosedFields = true;
      }
      //this.p2validation();
    }
  }
  /* Form initialization */
  formInitialize() {
    this.letterP1Form = this.fBuild.group({
      // gstin: [this.selectedGstin],
      gstin: [this.caseData?.gstin ? this.caseData?.gstin : this.selectedGstin],
      gstinDiv: [this.selectedGstinDiv],
      state: [this.caseData?.state ? this.caseData?.state : this.selectedStateName],
      // prevOrderLoc: [this.caseData?.prevOrderLoc],
      caseId: [this.caseData?.caseId],
      updatedBy: [this.caseData?.updatedBy],
      updatedOn: [this.caseData?.updatedOn],
      consultant: [this.caseData?.consultant, Validators.maxLength(50)],
      personResponsible: [this.caseData?.personResponsible, [Validators.required]],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      //keyword: [this.caseData?.keyword, [Validators.required]],// validation commented
      itemList: new UntypedFormArray([
        //this.initLetterArr(),
      ]),
      //phase 2
      proceededToScn: [this.caseData?.proceededToScn], //
      dateOfReceiptOfScn: [this.caseData?.dateOfReceiptOfScn], //
      asmt12RecepitDate: [this.caseData?.asmt12RecepitDate], //
      referenceNo: [this.caseData?.referenceNo],
      asmt12Loc: new UntypedFormArray([]),
      letterLoc: [null],
      scnNo: [this.caseData?.scnNo], //
      drc03CopyLoc: [this.caseData?.drc03CopyLoc],
      drc03Tax: [this.caseData?.drc03Tax, [Validators.pattern(/^[1-9]\d*$/)]],
      drc03Interest: [this.caseData?.drc03Interest, [Validators.pattern(/^[1-9]\d*$/)]],
      drc03Penalty: [this.caseData?.drc03Penalty, [Validators.pattern(/^[1-9]\d*$/)]],
      internalRemarks: [this.caseData?.internalRemarks],
      orderSummary: [this.caseData?.orderSummary],
      whetherWritAppealPrefHc: [this.caseData?.whetherWritAppealPrefHc ? this.caseData?.whetherWritAppealPrefHc : false],
      drc03CopyLocFname: [this.drc03CopyLocF],
      status: [this.caseData?.status ? this.caseData?.status : null],
      hearingDetails: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      liabilityDetails: new UntypedFormArray([]),

      taxTotal: new UntypedFormControl(this.caseData ? null : this.caseData?.taxTotal),
      cgstTotal: new UntypedFormControl(this.caseData ? null : this.caseData?.cgstTotal),
      sgstTotal: new UntypedFormControl(this.caseData ? null : this.caseData?.sgstTotal),
      cessTotal: new UntypedFormControl(this.caseData ? null : this.caseData?.cessTotal),
      interestTotal: new UntypedFormControl(this.caseData ? null : this.caseData?.interestTotal),
      penaltyTotal: new UntypedFormControl(this.caseData ? null : this.caseData?.penaltyTotal),
      totalOfTotal: new UntypedFormControl(this.caseData ? null : this.caseData?.totalOfTotal),
    });
  }
  get p1() { return this.letterP1Form.controls; }

  //authorityPanleExpansion
  authorityPanleExpansion() {
    this.authorityExpansion = !this.authorityExpansion;
  }

  p1IssueValidation() {
    var itemListArray = this.letterP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var itemListControl = itemListArray.controls[0] as UntypedFormGroup;
        if (itemListControl.controls.correspondenceAmtInvolved.value == 'true' || itemListControl.controls.correspondenceAmtInvolved.value == true) {
          var issuesArray = itemListArray.controls[i].get('issues') as UntypedFormArray
          for (var issue1Index = 0; issue1Index < issuesArray.controls.length; issue1Index++) {
            var issuesControl = issuesArray.controls[issue1Index] as UntypedFormGroup
            if (issuesControl.controls.issue.value === null || issuesControl.controls.issue.value === '') {
              issuesControl.controls.issue.setErrors({ 'required': true });
            } else {
              issuesControl.controls.issue.setErrors(null);
            }

            if (issuesControl.controls.riskParam.value === null || issuesControl.controls.riskParam.value === '') {
              issuesControl.controls.riskParam.setErrors({ 'required': true });
            } else {
              issuesControl.controls.riskParam.setErrors(null);
            }
          }
        }

      }
    }
  }
  p1validation() {
    this.issuingAuthChange();
    var itemListArray = (this.letterP1Form.get('itemList') as UntypedFormArray)
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var itemListControl = itemListArray.controls[i] as UntypedFormGroup;
        if ((itemListControl.controls.letterLoc.value == null || itemListControl.controls.letterLoc.value === '') && (itemListControl.value.isActive)) {
          itemListControl.controls.letterLoc.setErrors({ 'required': true });
        } else {
          itemListControl.controls.letterLoc.setErrors(null);
        }
      }
    }
  }
  liabilityValidation() {
    let liabilityArray = this.letterP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }
  p2validation() {
    // if (this.letterP1Form.value['status'] === null || this.letterP1Form.value['status'] === 'null') {
    //   this.letterP1Form.controls['status'].setErrors({ 'required': true });
    // }

    var itemListArray = this.letterP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var itemListIndex = 0; itemListIndex < itemListArray.controls.length; itemListIndex++) {
        var submissionsArray = itemListArray.controls[itemListIndex].get('submissions') as UntypedFormArray
        for (var submissionIndex = 0; submissionIndex < submissionsArray.controls.length; submissionIndex++) {
          var submissionContol = submissionsArray.controls[submissionIndex] as UntypedFormGroup
          if (submissionContol.controls.submissionDate.value === null || submissionContol.controls.submissionDate.value === '') {
            submissionContol.controls.submissionDate.setErrors({ 'required': true });
          } else {
            submissionContol.controls.submissionDate.setErrors(null);
          }
          if (submissionContol.controls.refNo.value === null || submissionContol.controls.refNo.value === '') {
            submissionContol.controls.refNo.setErrors({ 'required': true });
          } else {
            submissionContol.controls.refNo.setErrors(null);
          }
          if (submissionContol.controls.replyFormType.value === null || submissionContol.controls.replyFormType.value === "null" || submissionContol.controls.replyFormType.value === '') {
            submissionContol.controls.replyFormType.setErrors({ 'required': true });
          } else {
            submissionContol.controls.replyFormType.setErrors(null);
          }
        }
        var itemListControl = itemListArray.controls[0] as UntypedFormGroup;
        if (itemListControl.controls.correspondenceAmtInvolved.value == 'true' || itemListControl.controls.correspondenceAmtInvolved.value == true) {
          var issuesArray = itemListArray.controls[itemListIndex].get('issues') as UntypedFormArray
          for (var issue1Index = 0; issue1Index < issuesArray.controls.length; issue1Index++) {
            var issuesControl = issuesArray.controls[issue1Index] as UntypedFormGroup
            if (issuesControl.controls.status.value === null || issuesControl.controls.status.value === '') {
              issuesControl.controls.status.setErrors({ 'required': true });
            } else {
              issuesControl.controls.status.setErrors(null);
            }
          }
        }

      }
    }


  }
  p3validation() {
    if (this.letterP1Form.value['dateOfReceiptOfScn'] === null) {
      this.letterP1Form.controls['dateOfReceiptOfScn'].setErrors({ 'required': true });
    }
    if (this.letterP1Form.value['scnNo'] === null) {
      this.letterP1Form.controls['scnNo'].setErrors({ 'required': true });
    }
  }
  // Details of Meeting With Department
  departmentInvArr(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('meetingDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  // Details of Meeting With Department Table
  detailsOfDepartmentFrmGrp(meeting: any = null): UntypedFormGroup {
    return this.fBuild.group({
      dateOfMeeting: new UntypedFormControl('', []),
      personAttended: new UntypedFormControl('', []),
      internalRemarks: new UntypedFormControl('', []),
      copyOfSubmissionLoc: new UntypedFormArray([]),
    })

  }

  addDepartmentInv(j: any, event: any) {
    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let departmentFrmArray = itemListFrmArray.controls[j].get('meetingDetails') as UntypedFormArray;
    departmentFrmArray.push(this.detailsOfDepartmentFrmGrp());
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  rmDepartmentInv(i: number, k: number) {
    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let departmentFrmArray = itemListFrmArray.controls[i].get('meetingDetails') as UntypedFormArray;
    departmentFrmArray.removeAt(k);
    this.rmvDepartmentRef.close();
  }

  // Details of Consultant Charges
  consultantInvArr(): UntypedFormGroup[] {
    return (this.letterP1Form.get('consultantCharges') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  // Consultant Table
  consultantChargesList(consultant: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: new UntypedFormControl(consultant ? consultant.firm : null, []),
      consultant: new UntypedFormControl(consultant ? consultant.consultant : null, []),
      natureOfWork: new UntypedFormControl(consultant ? consultant.natureOfWork : null, []),
      fees: new UntypedFormControl(consultant ? consultant.fees : null, [Validators.pattern(/^-?[0-9,]+$/)]),
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [consultant ? consultant.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [consultant ? consultant.contact : null]
    })

  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  addConsultantInv(event: any) {
    let consutantFrmGrp = this.consultantChargesList();
    (this.letterP1Form.get('consultantCharges') as UntypedFormArray).push(consutantFrmGrp);
    event?.preventDefault();
    event?.stopPropagation();
    return false;

  }
  rmConsultantInv(i: number) {
    (this.letterP1Form.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.getConsultantChargesTotal()
    this.rmvConsultantModalRef.close();
  }

  uploadoConsultantAttachmentsLoc(i: any) {
    var consultantArray = this.letterP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] = true
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = false;
    this.isconsultantUPattachmentsLoc = false;
  }
  deleteConsultantFile(i: any) {
    let consultantArray = this.letterP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  consultantValidation() {
    let consultantArray = this.letterP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.fees.value != null && issuesControl.controls.fees.value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        } else {

        }
      }
    }
  }
  //consultantPanleExpansion
  consultantPanleExpansion() {
    this.consultantExpansion = !this.consultantExpansion;
  }

  liabilityPanelExpansion() {
    this.liabilityExpansionPanel = !this.liabilityExpansionPanel;

  }
  // Details of Liability Admitted and Paid
  getLiability(): UntypedFormGroup[] {
    return (this.letterP1Form.get('liabilityDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  // Liability Table
  detailsOfLiabilityFrmGrp(liability: any = null): UntypedFormGroup {
    return this.fBuild.group({
      issueName: new UntypedFormControl(liability ? liability.issueName : null, []),
      formOfPayment: new UntypedFormControl(liability ? liability.formOfPayment : null, []),
      tag: new UntypedFormControl(liability ? liability.tag : null, []),
      dateOfPayment: new UntypedFormControl(liability ? liability.dateOfPayment : null, []),
      challanNo: new UntypedFormControl(liability ? liability.challanNo : null, []),
      modeOfPayment: new UntypedFormControl(liability ? liability.modeOfPayment : null, []),
      accountingRef: new UntypedFormControl(liability ? liability.accountingRef : null, [Validators.maxLength(100)]),
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: new UntypedFormControl(liability ? liability.igst : null, [Validators.pattern(/^-?[0-9,]+$/)]),
      cgst: new UntypedFormControl(liability ? liability.cgst : null, [Validators.pattern(/^-?[0-9,]+$/)]),
      sgst: new UntypedFormControl(liability ? liability.sgst : null, [Validators.pattern(/^-?[0-9,]+$/)]),
      cess: new UntypedFormControl(liability ? liability.cess : null, [Validators.pattern(/^-?[0-9,]+$/)]),
      interest: new UntypedFormControl(liability ? liability.interest : null, [Validators.pattern(/^-?[0-9,]+$/)]),
      penalty: new UntypedFormControl(liability ? liability.penalty : null, [Validators.pattern(/^-?[0-9,]+$/)]),
      total: new UntypedFormControl(liability ? liability.total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
    })
  }
  addLiabilityInv(event: any) {
    let liabilityFrmGrp = this.detailsOfLiabilityFrmGrp();
    (this.letterP1Form.get('liabilityDetails') as UntypedFormArray).push(liabilityFrmGrp);
    event?.preventDefault();
    event?.stopPropagation();
    return false;

  }
  rmLiabilityInv(i: number) {
    (this.letterP1Form.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityModalRef.close();
    this.liabilityTotalCalculation();
  }

  uploadcopyOfForm(i: any) {
    var liabilityDetailsArray = this.letterP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.iscopyOfFormLocFile = true;
    this.iscopyOfFormLocText = false;
    this.iscopyOfUPFormLoc = false;
    this.iscopyOfDWFormLoc = false;
  }
  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.letterP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteliabilityFile(i: any) {
    let liabilityArray = this.letterP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  uploadcopyOfBankChallan(i: any) {
    var liabilityDetailsArray = this.letterP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.iscopyOfBankChallanLocFile = true;
    this.iscopyOfBankChallanLocText = false;
    this.iscopyOfBankDWChallanLoc = true;
    this.iscopyOfBankUPChallanLoc = false;
  }

  /*letter array */
  initLetterFrmGrp(letter: any = null) {
    return new UntypedFormGroup(
      {
        letterRefNo: new UntypedFormControl(letter ? letter.letterRefNo : null, [Validators.required]),
        dateOfLetter: new UntypedFormControl(letter ? letter.dateOfLetter : null, [Validators.required]),
        type: new UntypedFormControl(letter ? letter.type : null, []),
        periodFromDate: new UntypedFormControl(letter ? letter.periodFromDate : null, [Validators.required]),
        periodToDate: new UntypedFormControl(letter ? letter.periodToDate : null, [Validators.required]),
        internalDueDate: new UntypedFormControl(letter ? letter.internalDueDate : null, []),
        legalDueDate: new UntypedFormControl(letter ? letter.legalDueDate : null, [Validators.required]),
        correspondenceAmtInvolved: new UntypedFormControl(letter.correspondenceAmtInvolved ? letter.correspondenceAmtInvolved : false, [Validators.required]),
        summaryOfCorrespondence: new UntypedFormControl(this.caseData ? null : this.caseData?.caseSummary),
        // caseSummary: new FormControl(letter ? letter.letterRefNo : null, [Validators.required]),
        issue: new UntypedFormControl(null, []),
        keyword: new UntypedFormControl(null, []),
        tag: new UntypedFormControl(null, []),
        riskParam: new UntypedFormControl(null, []),
        status: new UntypedFormControl(null, []),
        issues: new UntypedFormArray([
          // this.newIssueInvFrmGrp(),
        ]),
        caseSummary: new UntypedFormControl(letter ? letter.caseSummary : null, []),
        docRequestedLoc: new UntypedFormControl(letter ? letter.docRequestedLoc : null, []),
        letterLoc: new UntypedFormArray([]),
        noticePdfLoc: new UntypedFormControl(letter ? letter.noticePdfLoc : null, []),
        uploadStatus: new UntypedFormControl(null),//
        uploadPdf: new UntypedFormControl(null),
        pdfFileUrl: new UntypedFormControl(null),
        otherAttachmentsLoc1: new UntypedFormArray([]),
        submissions: new UntypedFormArray([
          //this.newSubmissionFrmGrp(),
        ]),

        meetingDetails: new UntypedFormArray([
          //this.detailsOfDepartmentFrmGrp(),
        ]),
        typeOfAuthority: new UntypedFormControl(letter ? letter.typeOfAuthority : null, [Validators.required]),
        din: new UntypedFormControl(letter ? letter.din : null),
        letterReceiptDate: new UntypedFormControl(letter ? letter.letterReceiptDate : null, [Validators.required]),
        issuedUs: new UntypedFormControl(letter ? letter.issuedUs : null, []),
        orderReceiptMode: new UntypedFormControl(letter ? letter.orderReceiptMode : null, []),
        typeOfForm: new UntypedFormControl(letter ? letter.typeOfForm : null, []),
        extensionDays: new UntypedFormControl(letter ? letter.extensionDays : null, []),
        isExtensionGranted: new UntypedFormControl(letter ? letter.isExtensionGranted : null, []),
        // autharity filed
        officer: new UntypedFormControl(letter ? null : letter?.officer, []),
        designation: new UntypedFormControl(letter ? null : letter?.designation, []),
        jurisdiction: new UntypedFormControl(letter ? null : letter?.jurisdiction, []),
        address: new UntypedFormControl(letter ? null : letter?.address, []),
        contact: new UntypedFormControl(letter ? null : letter?.contact, []),
        email: new UntypedFormControl(letter ? null : letter?.email, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]),
        //  * autharity
        igst1Total: new UntypedFormControl(letter ? letter.igst1Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        cgst1Total: new UntypedFormControl(letter ? letter.cgst1Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        sgst1Total: new UntypedFormControl(letter ? letter.sgst1Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        cess1Total: new UntypedFormControl(letter ? letter.cess1Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        interest1Total: new UntypedFormControl(letter ? letter.interest1Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        penalty1Total: new UntypedFormControl(letter ? letter.penalty1Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        totOfTotal1: new UntypedFormControl(letter ? letter.totOfTotal1 : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        igst3Total: new UntypedFormControl(letter ? letter.igst3Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        cgst3Total: new UntypedFormControl(letter ? letter.cgst3Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        sgst3Total: new UntypedFormControl(letter ? letter.sgst3Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        cess3Total: new UntypedFormControl(letter ? letter.cess3Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        interest3Total: new UntypedFormControl(letter ? letter.interest3Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        penalty3Total: new UntypedFormControl(letter ? letter.penalty3Total : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        totOfTotal3: new UntypedFormControl(letter ? letter.totOfTotal3 : null, [Validators.pattern(/^-?[0-9,]+$/)]),
        isActive: new UntypedFormControl(false),
        iscorresInvAmt: new UntypedFormControl(false),
        iscorresInvAmtYes: new UntypedFormControl(false),
        iscorresInvAmtNo: new UntypedFormControl(false),

      }
    )
  }
  getletters(): UntypedFormGroup[] {
    return (this.letterP1Form.get('itemList') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getasmt12Loc(): UntypedFormGroup[] {
    return (this.letterP1Form.get('asmt12Loc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getletterLoc(letterLocLocGrp: UntypedFormGroup): any {
    return (letterLocLocGrp.get('letterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getotherAttachmentsLoc1(othAttachLocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (othAttachLocLocGrp.get('otherAttachmentsLoc1') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAckCOpyLoc(copyOfAckLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfAckLocGrp.get('copyOfAckLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getSubOthAttachLoc(ackCOpyLocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (ackCOpyLocLocGrp.get('otherAttachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getMeetingDtlsLoc(meetingDtlsLocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (meetingDtlsLocLocGrp.get('copyOfSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultotherAttachmentLoc(consultOtherAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultOtherAttachGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getLiablityCOpyFormLoc(copyOfFormLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfFormLocGrp.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getBankChallanLoc(copyOfBankChallanLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfBankChallanLocGrp.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLetterLocLength(j?: any) {
    let count = 0;
    let dtLettersFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let letterLocFrmArray = dtLettersFrmArray.controls[j]?.get('letterLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAsmt12LocLength() {
    let count = 0;
    let predepositFrmArray = this.letterP1Form.get('asmt12Loc') as UntypedFormArray;
    let asmt12Loccount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return asmt12Loccount.length;
  }
  getotherAttachmentsLoc1Length(j?: any) {
    let count = 0;
    let dtLettersFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let dtOtheAttachFrmArray = dtLettersFrmArray.controls[j]?.get('otherAttachmentsLoc1') as UntypedFormArray;
    let OtheAttachcount = dtOtheAttachFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return OtheAttachcount.length;
  }
  getCopyOfAckLocLength(noticeFormIndex: number, submissionsFormIndex: number): number {
    const dtLettersFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    const submiDetailsArray = dtLettersFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const ackCopyLocArray = submiDetailsArray.at(submissionsFormIndex).get('copyOfAckLoc') as UntypedFormArray;
    if (ackCopyLocArray && ackCopyLocArray.length > -1) {
      let countOfcopyOfSubmissionLoc = 0;
      for (let i = 0; i < ackCopyLocArray.length; i++) {
        const singleCopyOfSubmissionLoc = ackCopyLocArray.value[i];
        if (singleCopyOfSubmissionLoc.fileLoc != '' && singleCopyOfSubmissionLoc.fileLoc != null) {
          countOfcopyOfSubmissionLoc++;
        }
      }
      return countOfcopyOfSubmissionLoc;
    }
    return 0;
  }
  getOthCopyLocLength(noticeFormIndex: number, submissionsFormIndex: number): number {
    const dtLettersFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    const submiDetailsArray = dtLettersFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const otherAttachmentsLocArray = submiDetailsArray.at(submissionsFormIndex).get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachmentsLocArray && otherAttachmentsLocArray.length > -1) {
      let countOfcopyOfSubmissionLoc = 0;
      for (let i = 0; i < otherAttachmentsLocArray.length; i++) {
        const singleCopyOfSubmissionLoc = otherAttachmentsLocArray.value[i];
        if (singleCopyOfSubmissionLoc.fileLoc != '' && singleCopyOfSubmissionLoc.fileLoc != null) {
          countOfcopyOfSubmissionLoc++;
        }
      }
      return countOfcopyOfSubmissionLoc;
    }
    return 0;
  }
  getMeetingDetailsLength(noticeFormIndex: number, meetingDetailsFormIndex: number): number {
    const dtLettersFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    const submiDetailsArray = dtLettersFrmArray.at(noticeFormIndex).get('meetingDetails') as UntypedFormArray;
    const copyOfSubmissionLocArray = submiDetailsArray.at(meetingDetailsFormIndex).get('copyOfSubmissionLoc') as UntypedFormArray;
    if (copyOfSubmissionLocArray && copyOfSubmissionLocArray.length > -1) {
      let countOfcopyOfSubmissionLoc = 0;
      for (let i = 0; i < copyOfSubmissionLocArray.length; i++) {
        const singleCopyOfSubmissionLoc = copyOfSubmissionLocArray.value[i];
        if (singleCopyOfSubmissionLoc.fileLoc != '' && singleCopyOfSubmissionLoc.fileLoc != null) {
          countOfcopyOfSubmissionLoc++;
        }
      }
      return countOfcopyOfSubmissionLoc;
    }
    return 0;
  }
  getCOnsultOtherAttachLength(j?: any) {
    let count = 0;
    let dtLettersFrmArray = this.letterP1Form.get('consultantCharges') as UntypedFormArray;
    let dtOtheAttachFrmArray = dtLettersFrmArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let otherAttachmentLoccount = dtOtheAttachFrmArray.value.filter((x: any) =>x.fileLoc != '' && x.fileLoc != null);
    return otherAttachmentLoccount.length;
  }
  getCopyFormLength(j?: any) {
    let count = 0;
    let dtLettersFrmArray = this.letterP1Form.get('liabilityDetails') as UntypedFormArray;
    let dtOtheAttachFrmArray = dtLettersFrmArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let copyOfFormLocCount = dtOtheAttachFrmArray.value.filter((x: any) =>x.fileLoc != '' && x.fileLoc != null);
    return copyOfFormLocCount.length;
  }
  getBankChallanLocLength(j?: any) {
    let count = 0;
    let dtLettersFrmArray = this.letterP1Form.get('liabilityDetails') as UntypedFormArray;
    let dtOtheAttachFrmArray = dtLettersFrmArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let bankChallanLocCount = dtOtheAttachFrmArray.value.filter((x: any) =>x.fileLoc != '' && x.fileLoc != null);
    return bankChallanLocCount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get('phCopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get('additionalSubmissionLoc') as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  phDivArr(): UntypedFormArray {
    return this.letterP1Form.get('hearingDetails') as UntypedFormArray;
  }
  createasmt12LocLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: [not ? not?.remarks : ' ', [Validators.maxLength(100)]],
    })
  }
  createLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: [not ? not?.remarks : '', [Validators.maxLength(100)]],
    })
  }
  createOthAttachLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: [not ? not?.remarks : '', [Validators.maxLength(100)]],
    })
  }
  createAckCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: [not ? not?.remarks : null, [Validators.maxLength(100)]],
    })
  }
  createSubOtherAttachCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: [not ? not?.remarks : null, [Validators.maxLength(100)]],
    })
  }
  createConsultAttachLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: [not ? not?.remarks : null, [Validators.maxLength(100)]],
    })
  }
  createCopyFormLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: [not ? not?.remarks : null, [Validators.maxLength(100)]],
    })
  }
  createBankFormLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: [not ? not?.remarks : null, [Validators.maxLength(100)]],
    })
  }


  newphArray(item: any = null): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: ['', []],
      dateOfPhScheduled: ['', []],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: ['', []],
      mode: ['', []],
      phRefNo: ['', []],
      phTime: ['', []],
      consultant: [this.caseData?.consultant1, []],
      personResponsible: [this.caseData?.personResponsible1, []],
      dateOfsubmission: ['', []],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phDiscussionNotes: ['', []],
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      dateOfSubmission: ['', []],
    });
  }

  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
  }

  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdjDiv() {
    this.phDivArr().push(this.newphArray());
  }
  removeAdjDiv(i: number) {
    this.phDivArr().removeAt(i);
  }
  changeadjOrNot(event: any, i: any) {
    if (event.target.value === 'true') {
      this.createAdjDiv();
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get('phCopyLoc') as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }

  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }

  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }

  addAckCopy(j: any, i: any) {
    let itemFrmArray: any = this.letterP1Form.get('itemList') as UntypedFormArray;

    for (let index = 0; index < itemFrmArray.length; index++) {
      const element = itemFrmArray.controls[index];
      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.copyOfAckLoc;
        let actualLength = element1.controls.length;
        let remainingLength = actualLength != 0 ? 5 - actualLength : 0;
        if (actualLength == 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.copyOfAckLoc.push(this.createAckCopyLoc())
          }
        }
        while (remainingLength > 0) {
          submissionArray[i].controls.copyOfAckLoc.push(this.createAckCopyLoc());
          remainingLength--;
        }
      }
    }
  }
  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.letterP1Form.get('hearingDetails') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  addSubmmiOthAttachCopy(j: any, i: any) {
    let itemFrmArray: any = this.letterP1Form.get('itemList') as UntypedFormArray;
    for (let index = 0; index < itemFrmArray.length; index++) {
      const element = itemFrmArray.controls[index];

      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.otherAttachmentsLoc;
        let actualLength = element1.controls.length;
        let remainingLength = actualLength != 0 ? 5 - actualLength : 0;

        if (actualLength == 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.otherAttachmentsLoc.push(this.createSubOtherAttachCopyLoc());
          }
        }
        while (remainingLength > 0) {
          submissionArray[i].controls.otherAttachmentsLoc.push(this.createSubOtherAttachCopyLoc());
          remainingLength--;
        }

      }
    }
  }

  addastmt12Loc() {

    let orderCopyListFrmArray = this.letterP1Form.get('asmt12Loc') as UntypedFormArray;

    let i = 0;

    if (orderCopyListFrmArray.length > 0) {
      let length = orderCopyListFrmArray.length;

      while (i < 5 - length) {
        orderCopyListFrmArray.push(this.createasmt12LocLoc());

        i++;
      }
    } else {
      while (i < 5) {
        orderCopyListFrmArray.push(this.createasmt12LocLoc());

        i++;
      }
    }

  }
  addLetterLoc(j: any, i: any) {
    let dtLettersFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let letterLocFrmArray = dtLettersFrmArray.controls[j].get('letterLoc') as UntypedFormArray;
    if (letterLocFrmArray.length > 0) {
      let length = letterLocFrmArray.length
      while (i < 5 - length) {
        letterLocFrmArray.push(this.createLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        letterLocFrmArray.push(this.createLetterLoc());
        i++;
      }
    }


  }
  addOthAttachLoc(j: any, i: any) {
    let dtOtheAttachFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let othLocFrmArray = dtOtheAttachFrmArray.controls[j].get('otherAttachmentsLoc1') as UntypedFormArray;
    if (othLocFrmArray.length > 0) {
      let length = othLocFrmArray.length
      while (i < 5 - length) {
        othLocFrmArray.push(this.createOthAttachLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        othLocFrmArray.push(this.createOthAttachLoc());
        i++;
      }
    }
  }
  openNoticePdfPop(content: any, i: any) {
    this.addLetterLoc(i, 0);
    this.openCaseRef = this.modalService.open(content, { windowClass: 'ocrpop' });
  }
  openOCRopyLoc(content: any, i: any) {
    this.addAckCopy(i, 0);
    this.openCaseRef = this.modalService.open(content, { windowClass: 'ocrpop' });
  }
  openLetterLocPop(content: any, i: any) {
    this.addLetterLoc(i, 0);
    this.modalService.open(content);
  }
  openOtherAttachPop(content: any, i: any) {
    this.addOthAttachLoc(i, 0);
    this.modalService.open(content);

  }
  openAckCopyLoc(content: any, i: any) {
    this.addAckCopy(i, 0);
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openSubOthCopyLoc(content: any, i: any) {
    this.addSubmmiOthAttachCopy(i, 0);
    this.modalService.open(content);
  }
  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.addMeetingDtlsPop(i, 0)

    this.modalService.open(content);
  }
  openCOnsultOtherAttach(content: any, i: any) {
    this.addConsultAttach(i, 0);
    this.modalService.open(content);

  }
  openCopyOfForm(content: any, i: any) {
    this.addCopyFormAttach(i, 0);
    this.modalService.open(content);

  }
  openBankChallan(content: any, i: any) {
    this.addBankChallanAttach(i, 0);
    this.modalService.open(content);

  }
  openAstmtLoc(content: any) {
    this.addastmt12Loc();
    this.modalService.open(content);

  }
  createMeetingForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: [not ? not?.remarks : null, [Validators.maxLength(100)]],
    })
  }
  addMeetingDtlsPop(j: any, i: any) {
    let itemFrmArray: any = this.letterP1Form.get('itemList') as UntypedFormArray;
    for (let index = 0; index < itemFrmArray.length; index++) {
      const element = itemFrmArray.controls[index];

      var meetingnArray: any = element.controls.meetingDetails.controls;

      for (let i = 0; i < meetingnArray.length; i++) {
        const element1 = meetingnArray[i].controls.copyOfSubmissionLoc;

        let actualLength = element1.controls.length;
        let remainingLength = actualLength != 0 ? 5 - actualLength : 0;
        if (actualLength == 0) {
          for (let k = 0; k < 5; k++) {
            meetingnArray[i].controls.copyOfSubmissionLoc.push(this.createMeetingForm())
          }
        }
        while (remainingLength > 0) {
          meetingnArray[i].controls.copyOfSubmissionLoc.push(this.createMeetingForm())
          remainingLength--;
        }
      }
    }
  }
  addConsultAttach(j: any, i: any) {
    let dtConsultFrmArray = this.letterP1Form.get('consultantCharges') as UntypedFormArray;
    let otherAttachrLocFrmArray = dtConsultFrmArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;
    if (otherAttachrLocFrmArray.length > 0) {
      let length = otherAttachrLocFrmArray.length
      while (i < 5 - length) {
        otherAttachrLocFrmArray.push(this.createConsultAttachLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachrLocFrmArray.push(this.createConsultAttachLoc());
        i++;
      }
    }
  }

  addCopyFormAttach(j: any, i: any) {
    let dtLibilityFrmArray = this.letterP1Form.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = dtLibilityFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createCopyFormLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createCopyFormLoc());
        i++;
      }
    }
  }
  addBankChallanAttach(j: any, i: any) {
    let dtLibilityFrmArray = this.letterP1Form.get('liabilityDetails') as UntypedFormArray;
    let bankChallanFrmArray = dtLibilityFrmArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanFrmArray.length > 0) {
      let length = bankChallanFrmArray.length
      while (i < 5 - length) {
        bankChallanFrmArray.push(this.createBankFormLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        bankChallanFrmArray.push(this.createBankFormLoc());
        i++;
      }
    }
  }
  uploadLetterLocCopy(letterListIndex: any, letterLocIndex: any) {
    this.isletterLocFile = true;
    this.isletterLocText = false;
    this.isDWLetterLoc = false;
    this.isUPLetterLoc = false;

    let itemListsArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let lettereLocFrmArray = itemListsArray.controls[letterListIndex].get('letterLoc') as UntypedFormArray;
    if (lettereLocFrmArray.controls.length > 0) {
      for (let index = 0; index < lettereLocFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = lettereLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadastMtLocCopy(letterLocIndex: any) {
    this.isasmt12LocFile = true;
    this.isasmt12LocText = false;
    this.isDWasmt12Loc = false;
    this.isUPasmt12Loc = false;

    let itemListsastMtArray = this.letterP1Form.controls.asmt12Loc as UntypedFormArray;
    // let lettereLocFrmArray = itemListsArray.controls[letterListIndex].get('letterLoc') as FormArray;
    if (itemListsastMtArray.controls.length > 0) {
      for (let index = 0; index < itemListsastMtArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = itemListsastMtArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteUploadAmtFile(ItrIndex: any) {
    let liablityArray = this.letterP1Form.controls.asmt12Loc as UntypedFormArray;
    // let copyFormFrmArray = liablityArray.controls[itemListIndex].get('copyOfFormLoc') as FormArray;
    if (liablityArray.controls.length > 0) {
      for (let index = 0; index < liablityArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = liablityArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOtherAttach(letterListIndex: any, letterLocIndex: any) {
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = false;
    this.isconsultantUPattachmentsLoc = false;

    let consultArray = this.letterP1Form.controls.consultantCharges as UntypedFormGroup;
    let otherAttachLocFrmArray = consultArray.controls[letterListIndex].get('otherAttachmentLoc') as UntypedFormArray;
    if (otherAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = otherAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadCopyForm(letterListIndex: any, letterLocIndex: any) {
    this.iscopyOfFormLocFile = true;
    this.iscopyOfFormLocText = false;
    this.iscopyOfUPFormLoc = false;
    this.iscopyOfDWFormLoc = false;

    let liabilityDtlsArray = this.letterP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormLocFrmArray = liabilityDtlsArray.controls[letterListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteCopyForm(itemListIndex: any, ItrIndex: any) {
    let liablityArray = this.letterP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormFrmArray = liablityArray.controls[itemListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadBankChallanForm(letterListIndex: any, letterLocIndex: any) {
    this.iscopyOfBankChallanLocFile = true;
    this.iscopyOfBankChallanLocText = false;
    this.iscopyOfBankDWChallanLoc = true;
    this.iscopyOfBankUPChallanLoc = false;

    let liabilityDtlsArray = this.letterP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanFrmArray = liabilityDtlsArray.controls[letterListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = bankChallanFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteBankChallan(itemListIndex: any, ItrIndex: any) {
    let liablityArray = this.letterP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanFrmArray = liablityArray.controls[itemListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = bankChallanFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadAckCopy(itemListIndex: any, ackCopyLocIndex: any, submDetailsIndex: any) {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;

    let itemListFrmArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyLocArray = submiDetailsArray.controls[submDetailsIndex].get('copyOfAckLoc') as UntypedFormArray;

    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadMeetingCopy(itemListIndex: any, copyOfSubLocIndex: any, meetDetailsIndex: any) {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;


    let itemListFrmArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let meetingDetailsArray = itemListFrmArray.controls[itemListIndex].get('meetingDetails') as UntypedFormGroup;
    let copyOfSubLocArray = meetingDetailsArray.controls[meetDetailsIndex].get('copyOfSubmissionLoc') as UntypedFormArray;

    if (copyOfSubLocArray.controls.length > 0) {
      for (let index = 0; index < copyOfSubLocArray.controls.length; index++) {
        if (index === copyOfSubLocIndex) {
          let itemListuploadControl = copyOfSubLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteMeetingCopy(itemListIndex: any, copyOfSubLocIndex: any, meetDetailsIndex: any) {

    let itemListFrmArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let meetingDetailsArray = itemListFrmArray.controls[itemListIndex].get('meetingDetails') as UntypedFormGroup;
    let copyOfSubLocArray = meetingDetailsArray.controls[meetDetailsIndex].get('copyOfSubmissionLoc') as UntypedFormArray;

    if (copyOfSubLocArray.controls.length > 0) {
      for (let index = 0; index < copyOfSubLocArray.controls.length; index++) {
        if (index === copyOfSubLocIndex) {
          let itemListuploadControl = copyOfSubLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }

  }
  uploadSubOtherAttach(itemListIndex: any, othAttachCopyLocIndex: any, submDetailsIndex: any) {
    this.isotherAttac2File = true;
    this.isotherAttac2Text = false;
    this.isDWotherAttac2 = false;
    this.isUPotherAttac2 = false;

    let itemListFrmArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let othAttachLocArray = submiDetailsArray.controls[submDetailsIndex].get('otherAttachmentsLoc') as UntypedFormArray;

    if (othAttachLocArray.controls.length > 0) {
      for (let index = 0; index < othAttachLocArray.controls.length; index++) {
        if (index === othAttachCopyLocIndex) {
          let itemListuploadControl = othAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOthAttachLocCopy(otheAttachListIndex: any, othAttachLocIndex: any) {
    this.isotherAttacFile = true;
    this.isotherAttacText = false;
    this.isDWotherAttac = false;
    this.isUPotherAttac = false;

    let itemListsArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let othAttachLocFrmArray = itemListsArray.controls[otheAttachListIndex].get('otherAttachmentsLoc1') as UntypedFormArray;
    if (othAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < othAttachLocFrmArray.controls.length; index++) {
        if (index === othAttachLocIndex) {
          let itemListuploadControl = othAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteItrCopyFile(letterListIndex: any, letterLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let lettereLocFrmArray = itemListsArray.controls[letterListIndex].get('letterLoc') as UntypedFormArray;
    if (lettereLocFrmArray.controls.length > 0) {
      for (let index = 0; index < lettereLocFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = lettereLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  createLetter(addIssues = false) {
    let letterFrmGrp = this.initLetterFrmGrp(true);
    if (addIssues) {
      (letterFrmGrp.get('issues') as UntypedFormArray).push(this.newIssueInvFrmGrp());
    }
    (letterFrmGrp.get('submissions') as UntypedFormArray).push(this.newSubmissionFrmGrp());
    (this.letterP1Form.get('itemList') as UntypedFormArray).push(letterFrmGrp);
    (letterFrmGrp.get('meetingDetails') as UntypedFormArray).push(this.detailsOfDepartmentFrmGrp());
    this.hideOcrFiled = true;
  }
  removeLetter(i: number) {
    (this.letterP1Form.get('itemList') as UntypedFormArray).removeAt(i);
    this.rmvLetterModalRef.close();
  }
  /*letter array */
  /*Invoice array p1*/

  getIssues(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newIssueInvFrmGrp(issue: any = null) {
    return new UntypedFormGroup({
      issue: new UntypedFormControl(issue ? issue.issue : '', []),
      keyword: new UntypedFormControl(issue ? issue.keyword : '', []),
      riskParam: new UntypedFormControl(issue ? issue.riskParam : '', []),
      tag: new UntypedFormControl(issue ? issue.tag : '', []),
      remark1: new UntypedFormControl(issue ? issue.remark1 : '', [Validators.maxLength(500)]),
      igst1: new UntypedFormControl(issue ? issue.igst1 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      cgst1: new UntypedFormControl(issue ? issue.cgst1 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      sgst1: new UntypedFormControl(issue ? issue.sgst1 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      cess1: new UntypedFormControl(issue ? issue.cess1 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      interest1: new UntypedFormControl(issue ? issue.interest1 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      penalty1: new UntypedFormControl(issue ? issue.penalty1 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      total1: new UntypedFormControl(issue ? issue.total1 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      igst3: new UntypedFormControl(issue ? issue.igst3 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      cgst3: new UntypedFormControl(issue ? issue.cgst3 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      sgst3: new UntypedFormControl(issue ? issue.sgst3 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      cess3: new UntypedFormControl(issue ? issue.cess3 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      interest3: new UntypedFormControl(issue ? issue.interest3 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      penalty3: new UntypedFormControl(issue ? issue.penalty3 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      total3: new UntypedFormControl(issue ? issue.total3 : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      status: new UntypedFormControl(issue ? issue.status : '', []),
    })
  }
  addIssueInv(j: any) {
    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let issueFrmArray = itemListFrmArray.controls[j].get('issues') as UntypedFormArray;
    issueFrmArray.push(this.newIssueInvFrmGrp());
  }
  rmIssueInv(i: number, k: number) {
    // let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    // let issueFrmArray = itemListFrmArray.controls[i].get('issues') as UntypedFormArray;
    // if (k) {
    //   issueFrmArray.removeAt(k);
    // } else {
    //   for (let k = 0; k < issueFrmArray.controls.length; i++) {
    //     issueFrmArray.removeAt(k);
    //   }
    // }

    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let issueFrmArray = itemListFrmArray.controls[i].get('issues') as UntypedFormArray;
    issueFrmArray.removeAt(k);
    this.issueChangeEvent();
    this.rmvIssueModalRef?.close();
  }

  /*Invoice array p1*/
  /*submission array p2*/

  getSubmissions(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('submissions') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newSubmissionFrmGrp(submission: any = null) {
    return new UntypedFormGroup({
      submissionDate: new UntypedFormControl(submission?.submissionDate, []),
      // consultant: new FormControl('', []),
      // personResponsible: new FormControl('', []),
      copyOfAckLoc: new UntypedFormArray([]),
      otherAttachmentsLoc: new UntypedFormArray([]),
      internalRemarks: new UntypedFormControl(submission?.internalRemarks, []),
      refNo: new UntypedFormControl(submission?.refNo, []),
      replyFormType: new UntypedFormControl(submission?.replyFormType, []),
      submissionMode: new UntypedFormControl(submission?.submissionMode, []),
      isSubmission: new UntypedFormControl(submission?.isSubmission ? submission?.isSubmission : true),
    })
  }
  addSubmissionDiv(j: any) {
    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let submissionFrmArray = itemListFrmArray.controls[j].get('submissions') as UntypedFormArray;
    submissionFrmArray.push(this.newSubmissionFrmGrp());
    this.hideOcrFiledp2 =true
  }
  rmSubmissionDiv(i: number, k: number) {
    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let submissionFrmArray = itemListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    submissionFrmArray.removeAt(k);
    this.rmvSubModalRef.close();

  }
  /*submission array p1*/
  chCorresInv(event: any, i: any) {
    if (event.target.value === "true" || event.target.value === true) {
      var itemListIndex = (this.letterP1Form.controls.itemList as UntypedFormArray).controls[i] as UntypedFormGroup;
      if (itemListIndex.value.issues.length == 0) {
        this.addIssueInv(i);
      }
    }
  }
  showifcorresInvAmt(i: any) {
    var itemListIndex = (this.letterP1Form.controls.itemList as UntypedFormArray).controls[i] as UntypedFormGroup;
    if (itemListIndex) {
      if (itemListIndex.controls.correspondenceAmtInvolved.value === true || itemListIndex.controls.correspondenceAmtInvolved.value === "true") {
        return true;
      }
    }
    return false;
  }
  showifcorresInvAmtNo(i: any) {
    var itemListIndex = (this.letterP1Form.controls.itemList as UntypedFormArray).controls[i] as UntypedFormGroup;
    if (itemListIndex) {
      if (itemListIndex.controls.correspondenceAmtInvolved.value === false || itemListIndex.controls.correspondenceAmtInvolved.value === "false") {
        return true;
      }
    }
    return false;
  }
  showifUpdate(i: any, k: any) {
    var itemListIndex = (this.letterP1Form.controls.itemList as UntypedFormArray).controls[i] as UntypedFormGroup;
    var submissionIndex = (itemListIndex.controls.submissions as UntypedFormArray).controls[k];
    // if(submissionIndex.length > 0){
    //   element.submissions.forEach((s:any) =>{
    //     s.isSubmission = true;
    //   })
    // }

  }
  deleteLetterLocAi(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let letterLocFrmArray = itemListsArray.controls[itemListIndex].get('letterLoc') as UntypedFormArray;
    if (letterLocFrmArray.controls.length > 0) {
      for (let index = 0; index < letterLocFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = letterLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
          this.filePathDataArray.splice(index, 1);
          this.uploadedFileUrlList.splice(index, 1);
        }
      }
    }
  }

  deleteLetterLoc(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let letterLocFrmArray = itemListsArray.controls[itemListIndex].get('letterLoc') as UntypedFormArray;
    if (letterLocFrmArray.controls.length > 0) {
      for (let index = 0; index < letterLocFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = letterLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttach(itemListIndex: any, ItrIndex: any) {
    let consultArray = this.letterP1Form.controls.consultantCharges as UntypedFormGroup;
    let otherAttachFrmArray = consultArray.controls[itemListIndex].get('otherAttachmentLoc') as UntypedFormArray;
    if (otherAttachFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = otherAttachFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachmentsLoc(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let otherAttachLocFrmArray = itemListsArray.controls[itemListIndex].get('otherAttachmentsLoc1') as UntypedFormArray;
    if (otherAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = otherAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*on change of Does the correspondence involves amounts yes or no */
  checkSCNTrig(event: any) {
    if (event.target.value === "SCN TRIGGERED") {
      this.showFields = true;
      this.showClosedFields = false;
      let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
      this.openScnTrigFavPop(this.scnTrigPop);
      // if(itemListFrmArray.value.length > 0) {
      //   itemListFrmArray.value.forEach((issues:any) =>{
      //     if(issues.issues.length > 0) {
      //       issues.issues.forEach((status:any) =>{
      //         if(status.status === "Favorable"){
      //           this.openScnTrigFavPop(this.scnTrigPop);
      //         }
      //       })
      //     }
      //   })
      // }

    }
    else if (event.target.value === "CLOSED") {
      this.showFields = false;
      this.showClosedFields = true;
    }

    else {
      this.showFields = false;
      this.showClosedFields = false;



    }
  }
  openScnTrigFavPop(content: any) {
    this.modalService.open(content);
  }
  /*upload file selection */
  onFileSelect(event: any, type: any = null, submissionIndex: any = null) {
    this.selectedField = event.target.getAttribute('id');
    if (event.target.files[0] !== undefined) {
      if (
        this.checkExtensionFunction(event.target.files[0], [
          'csv',
          'zip',
          'pdf',
          'xlsx',
          'zip',
          'rar',
          'doc',
          'docx',
          'pptx',
          'xlsb',
          'png',
          'jpg',
          'jpeg',
          'msg',
        ])
      ) {
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, type, submissionIndex);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported');
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
    return null;
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  fileUrlData: any[] = [];
  filePathData: any[] = [];
  filePathDataArray: any[] = [];
  onUpload(selectedfield: any, type: any, submissionIndex: any, letterIndex?: number) {
    let urlData = {
      gstinOrPan: this.letterP1Form.value.gstin,
      type: this.tabType[0].key,
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.litService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrlData = response.response.url;
        this.filePathData = response.response.path;
        this.filePathDataArray.push(this.filePathData);        
        console.log(this.filePathDataArray,"this.filePathDataArray");
             
        this.getFileUrl(response.response.url, response.response.path, selectedfield, type, submissionIndex);

        if (letterIndex !== undefined && letterIndex > -1) {
          this.ocrLetterMap[letterIndex] = {
            uploaded: true,
            processed: false,
            filePath: response.response.path,
            response: null
          }
        }
      }
    })
  }
  getFileUrl(url: any, path: any, selectedfield: any, type: any = null, submissionIndex: any) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (this.selectedFile && type === 'meetingDetails' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
        let departmentFrmArray = itemListFrmArray.controls[submissionIndex].get('meetingDetails') as UntypedFormArray;

        departmentFrmArray.controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }

        });
      } else if (this.selectedFile && type === 'consultantCharges' && this.matches) {
        if (this.selectedFile && type === 'consultantCharges' && this.matches) {
          let letterIndex = this.matches[1];
          let attrFieldNm = this.matches.input.split('.');

          (this.letterP1Form.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          });
        }
      } else if (this.selectedFile && type === 'liabilityDetails' && this.matches) {
        if (this.selectedFile && type === 'liabilityDetails' && this.matches) {
          let letterIndex = this.matches[1];
          let attrFieldNm = this.matches.input.split('.');

          (this.letterP1Form.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          });
        }
      }
      else if (this.selectedFile && type === 'itemList' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.letterP1Form.get('itemList') as UntypedFormArray).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
            c.controls['isActive'].setValue(true)
          }
        });
      }
      if (selectedfield) {
        var toupdate = this.letterP1Form.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.letterP1Form.patchValue(this.letterP1Form.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    });
  }

  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
      // path.push(pathChunk.substr(0, indexOfBrc));
      // path.push(pathChunk.charAt(indexOfBrc + 1));
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  /*upload file selection */
  /*create case (submit function)*/
  saveLetter() {
    this.isSubmitted = true;
    let data: any = {};
    this.p1IssueValidation();
    if (this.letterP1Form.valid) {
      if (this.p1.itemList.value.length === 0) {
        this.toaster.showError('Letter data is required');
        return;
      }
      data = this.letterP1Form.value;
      var itemFrmArray = this.getletters();
      let letterLoc;
      for (let i = 0; i < itemFrmArray.length; i++) {
        letterLoc = (itemFrmArray[i].get('letterLoc') as UntypedFormArray).controls;
      }
      var count = letterLoc?.some(x =>
        x.get('fileLoc')?.value != null
      );
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
      data.itemList.forEach((c: any) => {
        c.issuingAuth = {
          officer: c.officer,
          designation: c.designation,
          jurisdiction: c.jurisdiction,
          address: c.address,
          contact: c.contact,
          email: c.email
        }
      })

      data.state = this.selectedStateName;
      let urlData = {
        typeVal: this.tabType[0].key,
        gstin: this.selectedGstin,
        companyId: this.companyId
      }


      this.inrFormattingRemoving();
      Object.assign(data, { action: 'CREATE' }, { phase: '1' }, { keyword: 'keyword1' })
      let letterLocArray: any[] = [];
      let otherAttachmentArray: any[] = [];
      for (let i = 0; i < this.letterP1Form.controls.itemList.value.length; i++) {
        letterLocArray = [];
        const letterArray = this.letterP1Form.controls.itemList.value[i].letterLoc;
        if (letterArray.length === 0) {
          this.letterP1Form.controls.itemList.value[i].letterLoc.status = 'INVALID';
        }
        for (let j = 0; j < letterArray.length; j++) {
          const element = letterArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            letterLocArray.push(element)
          }
        }

        this.letterP1Form.controls.itemList.value[i].letterLoc = letterLocArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.itemList.value.length; i++) {
        otherAttachmentArray = [];
        const otherAttachArray = this.letterP1Form.controls.itemList.value[i].otherAttachmentsLoc1;
        for (let j = 0; j < otherAttachArray.length; j++) {
          const element = otherAttachArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            otherAttachmentArray.push(element)
          }
        }
        this.letterP1Form.controls.itemList.value[i].otherAttachmentsLoc1 = otherAttachmentArray;
      }
      for (let j = 0; j < data.itemList.length; j++) {
        const element = data.itemList[j];

        if (element.noticePdfLoc) {
          delete element.noticePdfLoc;
        }

        if (element.uploadStatus) {
          delete element.uploadStatus;
        }

        if (element.uploadPdf) {
          delete element.uploadPdf;
        }

        if (element.pdfFileUrl) {
          delete element.pdfFileUrl;
        }
      }
      if (data.gstin !== this.selectedFilling.gstin) {
        this.toaster.showError("Please select appropriate state and try again");
        return
      }
      console.log("Data", data)
      this.litService.saveNoticeLetterData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {
          if (data.itemList.length > 0) {
            data.itemList.forEach((e: any) => {
              this.formatTableValues();
              e.isActive = true;
              this.isCaseCreated = true;
            })
          }
          this.toaster.showSuccess(response.message);
          this.isDataSaved = true;
          // this.isPhase2 = true;
          this.isCreateBtn = false;
          this.isUpdateBtn = true;
          this.savedCaseId = response.response;
          this.saveCaseId.emit(this.savedCaseId);
          this.initializeData();
          this.hideOcrFiled = false;
          this.sidemenu = true;
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  /*get prefilled data (View)*/
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        type: this.tabType[0].key,
        // caseId: this.caseId,
        caseId: this.caseId,
      }
      this.litService.getCaseNoticeData(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.caseData = response.response;
          // const itemlistData = this.caseData.itemList;

          //   this.autoIssueList = []; // List to store unique issues
          //   this.autoTagList = [];   // List to store unique tags

          //   const uniqueIssues = new Set(); // To track unique issues
          //   const uniqueTags = new Set();   // To track unique tags

          //   itemlistData.forEach((item: any) => {
          //     item.issues.forEach((issue: any) => {
          //       // Check and add unique issues
          //       if (!uniqueIssues.has(issue.issue)) {
          //         uniqueIssues.add(issue.issue);
          //         this.autoIssueList.push(issue.issue); // Add to issue list
          //       }

          //       // Check and add unique tags (if tag is not null/undefined)
          //       if (issue.tag !== null && issue.tag !== undefined && !uniqueTags.has(issue.tag)) {
          //         uniqueTags.add(issue.tag);
          //         this.autoTagList.push(issue.tag); // Add to tag list
          //       }
          //     });
          //   });
          //   console.log("list: ", this.autoIssueList, '+', this.autoTagList)

          this.getCompanyTags(this.caseData.gstin);
          if (this.caseData?.consultantCharges) {
            this.caseData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }
          if (this.caseData?.liabilityDetails) {
            this.caseData.liabilityDetails.forEach((liability: any) => {
              liability.total = this.inrService.formatCurrency(String(liability.total))
              liability.igst = this.inrService.formatCurrency(String(liability.igst))
              liability.cgst = this.inrService.formatCurrency(String(liability.cgst))
              liability.sgst = this.inrService.formatCurrency(String(liability.sgst))              
              liability.cess = this.inrService.formatCurrency(String(liability.cess))
              liability.interest = this.inrService.formatCurrency(String(liability.interest))
              liability.penalty = this.inrService.formatCurrency(String(liability.penalty))
            })
          }
          if (this.caseData?.itemList) {
            this.caseData.itemList.forEach((itemLists: any) => {
              if (itemLists?.issues) {
                itemLists.issues.forEach((issue: any) => {
                  issue.igst1 = this.inrService.formatCurrency(String(issue.igst1))
                  issue.cgst1 = this.inrService.formatCurrency(String(issue.cgst1))
                  issue.sgst1 = this.inrService.formatCurrency(String(issue.sgst1))
                  issue.cess1 = this.inrService.formatCurrency(String(issue.cess1))
                  issue.interest1 = this.inrService.formatCurrency(String(issue.interest1))
                  issue.penalty1 = this.inrService.formatCurrency(String(issue.penalty1))
                  issue.total1 = this.inrService.formatCurrency(String(issue.total1))
                  issue.igst3 = this.inrService.formatCurrency(String(issue.igst3))
                  issue.cgst3 = this.inrService.formatCurrency(String(issue.cgst3))
                  issue.sgst3 = this.inrService.formatCurrency(String(issue.sgst3))
                  issue.cess3 = this.inrService.formatCurrency(String(issue.cess3))
                  issue.interest3 = this.inrService.formatCurrency(String(issue.interest3))
                  issue.penalty3 = this.inrService.formatCurrency(String(issue.penalty3))
                  issue.total3 = this.inrService.formatCurrency(String(issue.total3))
                })
              }
            })
          }
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.formInitialize();
          // this.detailsOfDepartmentFrmGrp();
          this.initializeDetailsOfConsultantCharges();
          this.initializeAstmt12Copy();
          if (this.caseData.whetherWritAppealPrefHc === true || this.caseData.whetherWritAppealPrefHc == 'true') {
            this.isPhase2 = false;
          }
          // this.detailsOfLiabilityFrmGrp();
          if (this.letterP1Form.value.status === 'MOVED') {
            this.letterP1Form.value.status = 'SCN TRIGGERED';
            this.letterP1Form.controls['status'].setValue('SCN TRIGGERED');
            this.showFields = true;
          }
          if (this.letterP1Form.value.status === 'CLOSED') {
            this.letterP1Form.controls['status'].setValue('CLOSED');
            this.showClosedFields = true;
          }
          this.selectedStateName = response.response.state;

          // this.selectedStateCode = response.response.state;
          // this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
          // this.selectedStateName = this.commonServ.getStateName( this.selectedStateCode);
          // this.caseData.state = this.selectedStateName
          this.drc03CopyLocF = this.litService.getFileName(this.caseData.drc03CopyLoc);
          if (this.caseData.itemList.length > 0) {
            this.caseData.itemList.forEach((element: any) => {
              element.isActive = true;


              if (element.submissions) {
                if (element.submissions.length > 0) {
                  element.submissions.forEach((s: any) => {
                    s.isSubmission = true;
                  })
                }
              }

            });

            this.initializeItemList();
            if (this.caseData.hearingDetails && this.caseData.hearingDetails.length > 0) {
              this.initializeHearingDetails();
            } else {
              this.initializeHearingDetails();
              var list: UntypedFormGroup = this.newphArray();
              this.phDivArr().push(list);
            }

            if (this.caseData.liabilityDetails?.length > 0) {
              this.initializeLiabilityAdmittedAndPaid();
              this.liabilityTotalCalculation();
            } else {
              this.addLiabilityInv(null);
            }
            this.issueChangeEvent();
            this.getConsultantChargesTotal()
          }
          this.isUpdate = true;
          if (this.action === 'view') {
            this.isCaseData = false;
            this.isDataSaved = true;
          } else {
            this.isCaseData = true;
            this.isDataSaved = true;

          }
          if (this.caseData.proceededToScn == true) {
            this.showFields = true;
          }
          if (this.letterP1Form.value.status === 'CLOSED') {
            this.showClosedFields = true;
          }
        }
      });
    }

  }


  /*listItem data patch */
  initializeItemListData(letterIndex: number) {
    console.log("itemList", this.caseData.itemList)
    this.caseData.itemList.forEach((letter: any) => {
      let letterFrmGrp: UntypedFormGroup = this.initLetterFrmGrp(letter);
      if (letterIndex !== undefined && letterIndex > -1) {
        letterFrmGrp = (this.letterP1Form.get('itemList') as FormArray).at(letterIndex) as FormGroup
      } else {
        (this.letterP1Form.get('itemList') as UntypedFormArray).push(letterFrmGrp);
      }
      const issuesArray = letterFrmGrp.get('issues') as UntypedFormArray;
      issuesArray.clear();

      if (letter.issues) {
        letter.issues.forEach((issue: any) => {
          const issuesFrmGrp = this.newIssueInvFrmGrp(issue);
          issuesArray.push(issuesFrmGrp);
        });
      }

      letterFrmGrp.controls['officer'].setValue(letter.issuingAuth?.officer);
      letterFrmGrp.controls['designation'].setValue(letter.issuingAuth?.designation);
      letterFrmGrp.controls['jurisdiction'].setValue(letter.issuingAuth?.jurisdiction);
      letterFrmGrp.controls['address'].setValue(letter.issuingAuth?.address);
      letterFrmGrp.controls['contact'].setValue(letter.issuingAuth?.contact);
      letterFrmGrp.controls['email'].setValue(letter.issuingAuth?.email);
    });
    this.letterP1Form.patchValue(this.caseData);
    this.issueChangeEvent();
  }
  initializeItemListSub(letterIndex: number, subIndex?: number) {
    console.log("itemList", this.caseData.itemList);
    this.caseData.itemList.forEach((letter: any) => {
      let letterFrmGrp: UntypedFormGroup = this.initLetterFrmGrp(letter);
      if (letterIndex !== undefined && letterIndex > -1) {
        letterFrmGrp = (this.letterP1Form.get('itemList') as FormArray).at(letterIndex) as FormGroup
      } else {
        (this.letterP1Form.get('itemList') as UntypedFormArray).push(letterFrmGrp);
      }

      if (letter.submissions) {
        const submissionsArray = letterFrmGrp.get('submissions') as UntypedFormArray;
        if (subIndex !== undefined) {
          const submission = letter.submissions[0];
          if (submission) {
            const submissionFrmGrp: UntypedFormGroup = this.newSubmissionFrmGrp(submission);
            submissionsArray.at(subIndex).patchValue(submissionFrmGrp.value);
          }
        }
         else {
          letter.submissions.forEach((submission: any) => {
            const submissionFrmGrp: UntypedFormGroup = this.newSubmissionFrmGrp(submission);
            submissionsArray.push(submissionFrmGrp);
          });
        }
      }
    });
    this.issueChangeEvent();
  }

  initializeItemList() {
    if (this.caseData.itemList) {
      this.caseData.itemList.forEach((letter: any) => {
        let letterFrmGrp: UntypedFormGroup = this.initLetterFrmGrp(letter);
        (this.letterP1Form.get('itemList') as UntypedFormArray).push(letterFrmGrp);
        if (letter.issues) {
          letter.issues.forEach((issue: any) => {
            let issuesFrmGrp: UntypedFormGroup = this.newIssueInvFrmGrp(issue);
            (letterFrmGrp.get('issues') as UntypedFormArray).push(issuesFrmGrp);
          });
        }
        if (letter.letterLoc) {
          letter.letterLoc.forEach((not: any) => {
            let letterLocFrmGrp: UntypedFormGroup = this.createLetterLoc(not);
            (letterFrmGrp.get('letterLoc') as UntypedFormArray).push(letterLocFrmGrp);

          });
        }
        if (letter.otherAttachmentsLoc1) {
          letter.otherAttachmentsLoc1.forEach((not: any) => {
            let letterLocFrmGrp: UntypedFormGroup = this.createOthAttachLoc(not);
            (letterFrmGrp.get('otherAttachmentsLoc1') as UntypedFormArray).push(letterLocFrmGrp);
          });
        }
        if (letter.submissions) {
          letter.submissions.forEach((submission: any) => {
            let submissionFrmGrp: UntypedFormGroup = this.newSubmissionFrmGrp(submission);
            (letterFrmGrp.get('submissions') as UntypedFormArray).push(submissionFrmGrp);

            if (submission.copyOfAckLoc) {
              submission.copyOfAckLoc.forEach((not: any) => {
                let ackCopyLocFrmGrp: UntypedFormGroup = this.createAckCopyLoc(not);
                (submissionFrmGrp.get('copyOfAckLoc') as UntypedFormArray).push(ackCopyLocFrmGrp);
              });
            }

            if (submission.otherAttachmentsLoc) {
              submission.otherAttachmentsLoc.forEach((not: any) => {
                let otherAttachLocFrmGrp: UntypedFormGroup = this.createSubOtherAttachCopyLoc(not);
                (submissionFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).push(otherAttachLocFrmGrp);
              });
            }

          });
        }
        if (letter.meetingDetails) {
          letter.meetingDetails.forEach((meeting: any) => {
            let meetingFrmGrp: UntypedFormGroup = this.detailsOfDepartmentFrmGrp(meeting);
            (letterFrmGrp.get('meetingDetails') as UntypedFormArray).push(meetingFrmGrp);

            if (meeting.copyOfSubmissionLoc) {
              meeting.copyOfSubmissionLoc.forEach((not: any) => {
                let copyOfSubFrmGrp: UntypedFormGroup = this.createMeetingForm(not);
                (meetingFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).push(copyOfSubFrmGrp);
              });
            }

          });
        }



        letterFrmGrp.controls['officer'].setValue(letter.issuingAuth?.officer);
        letterFrmGrp.controls['designation'].setValue(letter.issuingAuth?.designation);
        letterFrmGrp.controls['jurisdiction'].setValue(letter.issuingAuth?.jurisdiction);
        letterFrmGrp.controls['address'].setValue(letter.issuingAuth?.address);
        letterFrmGrp.controls['contact'].setValue(letter.issuingAuth?.contact);
        letterFrmGrp.controls['email'].setValue(letter.issuingAuth?.email);
      });


      this.letterP1Form.patchValue(this.caseData);
    }
  }

  initializeHearingDetails() {
    if (this.caseData.hearingDetails) {
      this.caseData.hearingDetails.forEach((items: any) => {
        let hearingDetailsFrmGrp = this.newphArray(items);
        (this.letterP1Form.get('hearingDetails') as UntypedFormArray).push(hearingDetailsFrmGrp);
        if (items.phCopyLoc) {
          items.phCopyLoc.forEach((not: any) => {
            let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
            (hearingDetailsFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
          });
        }

        if (items.phRecordIfAnyLoc) {
          items.phRecordIfAnyLoc.forEach((not: any) => {
            let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
            (hearingDetailsFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(phRecordAnyGrp);
          });
        }

        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup = this.createAdditionalSubLoc(not);
            (hearingDetailsFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(additionalSubmissionFrmGrp);
          });
        }
        if (items.phAdjournmentLetterLoc) {
          items.phAdjournmentLetterLoc.forEach((not: any) => {
            let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
            (hearingDetailsFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
          });
        }
        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup = this.createAdditionalSubLoc(not);
            (hearingDetailsFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(additionalSubmissionFrmGrp);
          });
        }

      });
      this.letterP1Form.patchValue(this.caseData);
    }
  }



  /* check form validation */
  chkFrmValidated() {
    if (this.letterP1Form.valid) {
      this.isPhase2 = true;
    }
  }
  UpdateModelData() {
    if (this.caseData != null) {
      this.letterP1Form.value.letterLoc = this.caseData['letterLoc'];
    }
  }
  doValidation(phaseTab: any) {
    if (phaseTab === 'p1') {
      this.p1validation();
      // }else if (phaseTab === 'p2' && this.showFields == true && this.showClosedFields == true ) {
    } else if (phaseTab === 'p2' && this.showFields == true) {
      this.p2validation();
      this.p3validation();
    } else if (phaseTab === 'p2') {
      this.liabilityValidation();
      this.p2validation();
      this.consultantValidation();
    }
  }
  updateIssues() {
    (this.letterP1Form.get('itemList') as UntypedFormArray).controls.forEach((c) => {

      (c.get('issues') as UntypedFormArray).controls.forEach((c) => {
        c.patchValue({ igst3: c.value.igst1 })
        c.patchValue({ cgst3: c.value.cgst1 })
        c.patchValue({ sgst3: c.value.sgst1 })
        c.patchValue({ cess3: c.value.cess1 })
        c.patchValue({ interest3: c.value.interest1 })
        c.patchValue({ penalty3: c.value.penalty1 })
        c.patchValue({ total3: c.value.total1 })
      })
    })
  }
  /*update letter*/
  updateLetter(phaseTab: any) {
    let allLetterNotUpdated = false;

    this.letterP1Form.value.itemList.forEach((i: any) => {
      if (!i.letterLoc) {
        allLetterNotUpdated = true;
      }
    })
    this.isSubmitted = true;
    this.doValidation(phaseTab);

    if (phaseTab === 'p1') {
      this.updateIssues();
      this.p1IssueValidation();
      var itemFrmArray = this.getletters();
      let letterLoc;
      for (let i = 0; i < itemFrmArray.length; i++) {
        letterLoc = (itemFrmArray[i].get('letterLoc') as UntypedFormArray).controls;
      }
      var count = letterLoc?.some(x =>
        x.get('fileLoc')?.value != null
      );
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
    }

    if (phaseTab === 'p2') {
      let itemListFrmArray: any = this.letterP1Form.get('itemList') as UntypedFormArray;
      let element1;
      for (let index = 0; index < itemListFrmArray.length; index++) {
        const element = itemListFrmArray.controls[index];
        var submissionArray: any = element.controls.submissions.controls;
        for (let i = 0; i < submissionArray.length; i++) {
          element1 = (submissionArray[i].get('copyOfAckLoc') as UntypedFormArray).controls;
        }
      }
      var count = element1?.some((x: any) =>
        x.get('fileLoc')?.value != null
      );
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
      if (this.showClosedFields === true) {
        let asmt12Loc = (this.letterP1Form.get('asmt12Loc') as UntypedFormArray).controls;
        const count = asmt12Loc?.some((x) => x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
      }
    }
    if (allLetterNotUpdated && (phaseTab === 'p2')) {
      this.toaster.showError("Please add copy of Letter");
      return;
    }
    this.UpdateModelData();
    let data: any = {};
    for (const property in this.letterP1Form.controls) {
      if (this.letterP1Form.controls[property].status === "INVALID")
        console.log(`${property}: ${this.letterP1Form.controls[property].status}`)
    }

    if (this.letterP1Form.valid) {
      data = this.letterP1Form.value;
      data.state = this.selectedStateName;
      data.prevStage = this.caseData.prevStage;
      data.itemList.forEach((c: any) => {
        c.issuingAuth = {
          officer: c.officer,
          designation: c.designation,
          jurisdiction: c.jurisdiction,
          address: c.address,
          contact: c.contact,
          email: c.email
        }
      })
      if (this.savedCaseId) {
        data.caseId = this.savedCaseId;
      }
    
	    if(this.navContext.entityType == 'FILING'){
        if (data.gstin !== this.selectedFilling.gstin) {
          this.toaster.showError("Please select appropriate state and try again");
          return
        }
      }else{
        if (data.gstin !== this.letterP1Form.controls.gstin.value) {
          this.toaster.showError("Please select appropriate state and try again");
          return
        }
      }
      if (this.caseData?.nmFy) {
        data.noticeCaseId = this.caseData.noticeCaseId;
        data.nmFy = this.caseData.nmFy;
      }
      let urlData = {
        typeVal: this.tabType[0].key,
        gstin: this.selectedGstin,
      }

      this.inrFormattingRemoving();
      Object.assign(data, { action: 'UPDATE' }, { phase: '1' })
      let letterLocArray: any[] = [];
      let otherAttachmentArray: any[] = [];
      let subAckArray: any[] = [];
      let subOtherAttachArray: any[] = [];
      let meetingDocArray: any[] = [];
      let consultDocArray: any[] = [];
      let liabilityCopyArray: any[] = [];
      let liabilityBankChallanArray: any[] = [];
      let astmt12Array: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];
      for (let i = 0; i < this.letterP1Form.controls.itemList.value.length; i++) {
        letterLocArray = [];
        const letterArray = this.letterP1Form.controls.itemList.value[i].letterLoc;
        if (letterArray.length === 0) {
          this.letterP1Form.controls.itemList.value[i].letterLoc.status = 'INVALID';
        }
        for (let j = 0; j < letterArray.length; j++) {
          const element = letterArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            letterLocArray.push(element)
          }
        }

        this.letterP1Form.controls.itemList.value[i].letterLoc = letterLocArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.itemList.value.length; i++) {
        otherAttachmentArray = [];
        const otherAttachArray = this.letterP1Form.controls.itemList.value[i].otherAttachmentsLoc1;
        for (let j = 0; j < otherAttachArray.length; j++) {
          const element = otherAttachArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            otherAttachmentArray.push(element)
          }
        }
        this.letterP1Form.controls.itemList.value[i].otherAttachmentsLoc1 = otherAttachmentArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.itemList.value.length; i++) {
        const submmissionArray = this.letterP1Form.controls.itemList.value[i].submissions;
        for (let k = 0; k < submmissionArray.length; k++) {
          subAckArray = [];
          const elementArray = submmissionArray[k].copyOfAckLoc

          for (let j = 0; j < elementArray.length; j++) {
            const element = elementArray[j];
            if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
              if (element.fileName === '') {
                element.fileName = null;
              }

              if (element.remarks === '') {
                element.remarks = null;
              }

              subAckArray.push(element)
            }
          }
          this.letterP1Form.controls.itemList.value[i].submissions[k].copyOfAckLoc = subAckArray;
        }

      }
      for (let i = 0; i < this.letterP1Form.controls.itemList.value.length; i++) {
        const submmissionArray = this.letterP1Form.controls.itemList.value[i].submissions;
        for (let k = 0; k < submmissionArray.length; k++) {
          subOtherAttachArray = [];
          const elementArray = submmissionArray[k].otherAttachmentsLoc
          for (let j = 0; j < elementArray.length; j++) {
            const element = elementArray[j];
            if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
              if (element.fileName === '') {
                element.fileName = null;
              }
              if (element.remarks === '') {
                element.remarks = null;
              }
              subOtherAttachArray.push(element)
            }
          }
          this.letterP1Form.controls.itemList.value[i].submissions[k].otherAttachmentsLoc = subOtherAttachArray;
        }

      }
      for (let i = 0; i < this.letterP1Form.controls.itemList.value.length; i++) {
        const meetingsArray = this.letterP1Form.controls.itemList.value[i].meetingDetails;
        for (let k = 0; k < meetingsArray.length; k++) {
          meetingDocArray = [];
          const elementArray = meetingsArray[k].copyOfSubmissionLoc

          for (let j = 0; j < elementArray.length; j++) {
            const element = elementArray[j];
            if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
              if (element.fileName === '') {
                element.fileName = null;
              }

              if (element.remarks === '') {
                element.remarks = null;
              }

              meetingDocArray.push(element)
            }
          }
          this.letterP1Form.controls.itemList.value[i].meetingDetails[k].copyOfSubmissionLoc = meetingDocArray;
        }


      }
      for (let i = 0; i < this.letterP1Form.controls.hearingDetails.value.length; i++) {
        const phCopyLocArray = [];
        const elementPhCopyArray = this.letterP1Form.controls.hearingDetails.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element)
          }
        }
        this.letterP1Form.controls.hearingDetails.value[i].phCopyLoc = phCopyLocArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.hearingDetails.value.length; i++) {
        const additionalSubmissionLocArray = [];
        const elementAddSubArray = this.letterP1Form.controls.hearingDetails.value[i].additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element)
          }
        }
        this.letterP1Form.controls.hearingDetails.value[i].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.hearingDetails.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.letterP1Form.controls.hearingDetails.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.letterP1Form.controls.hearingDetails.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.hearingDetails.value.length; i++) {
        const phRecordIfAnyLocArray = [];
        const phRecordArray = this.letterP1Form.controls.hearingDetails.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element)
          }
        }
        this.letterP1Form.controls.hearingDetails.value[i].phRecordIfAnyLoc = phRecordIfAnyLocArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.consultantCharges.value.length; i++) {
        consultDocArray = [];
        const otherAttachesConsultArray = this.letterP1Form.controls.consultantCharges.value[i].otherAttachmentLoc;

        for (let j = 0; j < otherAttachesConsultArray.length; j++) {
          const element = otherAttachesConsultArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultDocArray.push(element)
          }
        }
        this.letterP1Form.controls.consultantCharges.value[i].otherAttachmentLoc = consultDocArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityCopyArray = [];
        const liabilitycopyFormArray = this.letterP1Form.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < liabilitycopyFormArray.length; j++) {
          const element = liabilitycopyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityCopyArray.push(element)
          }
        }
        this.letterP1Form.controls.liabilityDetails.value[i].copyOfFormLoc = liabilityCopyArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityBankChallanArray = [];
        const bankChallanArray = this.letterP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityBankChallanArray.push(element)
          }
        }
        this.letterP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc = liabilityBankChallanArray;
      }
      for (let i = 0; i < this.letterP1Form.controls.asmt12Loc.value.length; i++) {

        const element = this.letterP1Form.controls.asmt12Loc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          astmt12Array.push(element)
        }

      }
      this.letterP1Form.value.asmt12Loc = astmt12Array;
      // console.log("",data)
      this.litService.updateNoticeLetter(data, urlData).subscribe((response: any) => {
        this.isSubmitted = false;
        if (data.itemList.length > 0) {
          data.itemList.forEach((e: any) => {

            e.isActive = true;
            if (e.submissions.length > 0) {
              e.submissions.forEach((s: any) => {
                s.isSubmission = true;
              })
            }
          });
          this.letterP1Form.get('itemList')?.patchValue(data.itemList);
        }
        if (phaseTab === 'p1') {
          this.formatTableValues();
          // this.initializeData();
          if (this.letterP1Form.value.whetherWritAppealPrefHc === 'true' || this.letterP1Form.value.whetherWritAppealPrefHc === true) {
            this.litService.setAccessTabData({ scnFlag: true, ajaFlag: false, apaFlag: false, aptFlag: false, hcFlag: false, scFlag: false });
            this.moveToHC.emit('');
          }
        }
        if (this.letterP1Form.value.status == 'MOVED') {

          this.letterP1Form.value.status = 'SCN TRIGGERED';

          this.letterP1Form.controls['status'].setValue('SCN TRIGGERED');
        }
        if (phaseTab === 'p2') {
          if (this.letterP1Form.value.status === 'OPEN' || this.letterP1Form.value.status === 'DEEMED CLOSED' || this.letterP1Form.value.status === 'CLOSED') {
            this.router.navigate(['/litigationSummary']);
          }
          if (this.letterP1Form.value.status === 'SCN TRIGGERED') {
            if (this.letterP1Form.value.whetherWritAppealPrefHc === 'true' || this.letterP1Form.value.whetherWritAppealPrefHc === true) {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: false, apaFlag: false, aptFlag: false, hcFlag: false, scFlag: false });
              this.moveToHC.emit('');
            } else {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: false, apaFlag: false, aptFlag: false, hcFlag: false, scFlag: false });
              this.callTabFunction.emit('');
            }
          }


        }
        this.toaster.showSuccess(response.message);
        // this.p1validation();
        this.isPhase2 = true;
        this.isDataSaved = true;
        if (phaseTab === 'p2') {
          this.hideOcrFiledp2 = false;
          this.sidemenu = true;
          this.filePathDataArray =[];
          this.uploadedFileUrlList = [];
        } else {
          this.hideOcrFiled = false;
          this.hideOcrFiledp2 = true;
          this.sidemenu = true;
          this.filePathDataArray =[];
          this.uploadedFileUrlList = [];
        }
        this.prevStage = this.caseData.prevStage;

      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
    this.issueChangeEvent();
  }



  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.litService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.litService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }

  uploadletterLoc(index: any = null) {
    let itemListArr = this.letterP1Form.value.itemList;
    for (let i = 0; i < itemListArr.length; i++) {
      if (i === index) {
        itemListArr[i].isActive = true
      }
    }
    this.isNoticeletterLocFile = true;
    this.isNoticeletterLocText = false;
    this.isDWNoticeLetterLoc = false;
    this.isUPNoticeLetterLoc = false;
  }
  uploadotherAttac() {
    this.isotherAttacFile = true;
    this.isotherAttacText = false;
    this.isDWotherAttac = false;
    this.isUPotherAttac = false;
  }
  uploadAmtLoc() {
    this.isasmt12LocFile = true;
    this.isasmt12LocText = false;
    this.isDWasmt12Loc = false;
    this.isUPasmt12Loc = false;
  }
  //new
  uploaddocReqLoc() {
    this.isdocReqLocFile = true;
    this.isdocReqLocText = false;
    this.isDWdocReqLoc = false;
    this.isUPdocReqLoc = false;
  }
  //new
  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;
  }
  uploadotherAttac2() {
    this.isotherAttac2File = true;
    this.isotherAttac2Text = false;
    this.isDWotherAttac2 = false;
    this.isUPotherAttac2 = false;
  }
  uploaddrc03CopyLoc() {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;
  }
  uploadDepartmentAttachmentsLoc(meetingTableIndex: any, submissionIndex: any) {
    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let departmentFrmArray = itemListFrmArray.controls[submissionIndex].get('meetingDetails') as UntypedFormArray;
    if (departmentFrmArray.controls.length > 0) {
      for (var index = 0; index < departmentFrmArray.controls.length; index++) {
        if (index === meetingTableIndex) {
          var departmentControl = departmentFrmArray.controls[index] as UntypedFormGroup;
          departmentControl.value['isCopyOfSubmissionLocDocUploadedClicked'] = true
        }
      }
    }
    this.isdepartmentattachmentsLocFile = true;
    this.isdepartmentattachmentsLocText = false
    this.isdepartmentDWattachmentsLoc = true;
    this.isdepartmentUPattachmentsLoc = false;
  }

  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.letterP1Form.controls.hearingDetails as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteCopyOfSubmissionFile(meetingTableIndex: any, submissionIndex: any) {
    let itemListFrmArray = this.letterP1Form.get('itemList') as UntypedFormArray;
    let departmentFrmArray = itemListFrmArray.controls[submissionIndex].get('meetingDetails') as UntypedFormArray;
    if (departmentFrmArray.controls.length > 0) {
      for (var index = 0; index < departmentFrmArray.controls.length; index++) {
        if (index === meetingTableIndex) {
          var departmentControl = departmentFrmArray.controls[index] as UntypedFormGroup;
          departmentControl.controls.copyOfSubmissionLoc.setValue(null);
          departmentControl.value['isCopyOfSubmissionLocDocUploadedClicked'] = true
        }
      }
    }
  }
  deleteAckCopyFile(itemListIndex: any, ackCopyLocIndex: any, submDetailsIndex: any) {
    let itemListFrmArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyLocArray = submiDetailsArray.controls[submDetailsIndex].get('copyOfAckLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }

  deleteAckCopyFileAi(itemListIndex: any, ackCopyLocIndex: any, submDetailsIndex: any) {
    let itemListFrmArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyLocArray = submiDetailsArray.controls[submDetailsIndex].get('copyOfAckLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null;
          this.filePathDataArray.splice(index, 1);
          this.uploadedFileUrlList.splice(index, 1);
        }
      }
    }
  }

  deleteOtherAttachmentFile(itemListIndex: any, ackCopyLocIndex: any, submDetailsIndex: any) {
    let itemListFrmArray = this.letterP1Form.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let subOtheAttachCopyLocArray = submiDetailsArray.controls[submDetailsIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (subOtheAttachCopyLocArray.controls.length > 0) {
      for (let index = 0; index < subOtheAttachCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = subOtheAttachCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  //    //upload function inside table
  //  uploadotherattachmentsLoc() {
  //   this.isconsultantattachmentsLocFile = true;
  //   this.isconsultantattachmentsLocText = false;
  //   this.isconsultantDWattachmentsLoc = false;
  //   this.isconsultantUPattachmentsLoc = false;
  // }



  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
      this.selectedStateName = this.commonServ.getStateName(
        this.selectedStateCode
      );
    }
  }

  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );

        if (matchingConsultant) {
          this.letterP1Form.controls.consultant.patchValue(matchingConsultant.consultantName)
        }
      }
    });
  }
  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC'){
      this.isToggleConsultantDropdown3 = !this.isToggleConsultantDropdown3;
    }
  }
  onClickToggleSegmentDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleSegmentDropdown = !this.isToggleSegmentDropdown;
    } else if (phase === 'p2') {
      this.isToggleSegmentDropdown2 = !this.isToggleSegmentDropdown2;
    }
  }

  // toCurrency(){
  //   let igstTax1Curr = parseInt(this.igstTax1).toLocaleString();
  //   this.igstTax1 = igstTax1Curr;
  // }
  issueDropdown() {
    this.dataSource = ISSUE_GST;
  }

  openRmvLetterPop(content: any, i: any) {
    this.rmvLetterModalRef = this.modalService.open(content);
  }

  openRmvIssuePop(content: any, i: any, k: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openRmSubPop(content: any, i: any, k: any) {
    this.rmvSubModalRef = this.modalService.open(content);
  }
  openRmDepartmentPop(content: any, i: any, k: any) {
    this.rmvDepartmentRef = this.modalService.open(content);
  }
  openRmvconsultantPop(content: any, i: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }
  openRmvLiabilityPop(content: any, i: any) {
    this.rmvLiabilityModalRef = this.modalService.open(content);
  }
  getValidDate(date: any): any {
    if (date.value) {
      let d = date.value.split('-');
      const dateValidation = new Date(d[1] + '-' + d[0] + '-' + d[2]);
      return dateValidation;
    } else {
      return null;
    }
  }

  issueChangeEvent() {
    (this.letterP1Form.get('itemList') as UntypedFormArray).controls.forEach((item: any) => {
      let igst1Total: number = 0;
      let cgst1Total: number = 0;
      let sgst1Total: number = 0;
      let cess1Total: number = 0;
      let interest1Total: number = 0;
      let penalty1Total: number = 0;
      let cess3Total: number = 0;
      let cgst3Total: number = 0;
      let igst3Total: number = 0;
      let interest3Total: number = 0;
      let penalty3Total: number = 0;
      let sgst3Total: number = 0;

      (item.get('issues') as UntypedFormArray).controls.forEach((c) => {
        const igst1 = this.inrService.removeCommasAndParseNumber(c.value.igst1);
        const cgst1 = this.inrService.removeCommasAndParseNumber(c.value.cgst1);
        const sgst1 = this.inrService.removeCommasAndParseNumber(c.value.sgst1);
        const cess1 = this.inrService.removeCommasAndParseNumber(c.value.cess1);
        const interest1 = this.inrService.removeCommasAndParseNumber(c.value.interest1);
        const penalty1 = this.inrService.removeCommasAndParseNumber(c.value.penalty1);
        const igst3 = this.inrService.removeCommasAndParseNumber(c.value.igst3);
        const cgst3 = this.inrService.removeCommasAndParseNumber(c.value.cgst3);
        const sgst3 = this.inrService.removeCommasAndParseNumber(c.value.sgst3);
        const cess3 = this.inrService.removeCommasAndParseNumber(c.value.cess3);
        const interest3 = this.inrService.removeCommasAndParseNumber(c.value.interest3);
        const penalty3 = this.inrService.removeCommasAndParseNumber(c.value.penalty3);

        igst1Total += Number(igst1);
        cgst1Total += Number(cgst1);
        sgst1Total += Number(sgst1);
        cess1Total += Number(cess1);
        interest1Total += Number(interest1);
        penalty1Total += Number(penalty1);
        igst3Total += Number(igst3);
        cgst3Total += Number(cgst3);
        sgst3Total += Number(sgst3);
        cess3Total += Number(cess3);
        interest3Total += Number(interest3);
        penalty3Total += Number(penalty3);
        c.patchValue({
          total1: this.inrService.formatCurrency(String(Number(igst1) + Number(cgst1) + Number(sgst1) + Number(cess1) + Number(interest1) + Number(penalty1))),
          total3: this.inrService.formatCurrency(String(Number(igst3) + Number(cgst3) + Number(sgst3) + Number(cess3) + Number(interest3) + Number(penalty3)))
        });
      });

      item.controls['igst1Total'].setValue(this.inrService.formatCurrency(String(igst1Total)));
      item.controls['cgst1Total'].setValue(this.inrService.formatCurrency(String(cgst1Total)));
      item.controls['sgst1Total'].setValue(this.inrService.formatCurrency(String(sgst1Total)));
      item.controls['cess1Total'].setValue(this.inrService.formatCurrency(String(cess1Total)));
      item.controls['interest1Total'].setValue(this.inrService.formatCurrency(String(interest1Total)));
      item.controls['penalty1Total'].setValue(this.inrService.formatCurrency(String(penalty1Total)));
      item.controls['cess3Total'].setValue(this.inrService.formatCurrency(String(cess3Total)));
      item.controls['cgst3Total'].setValue(this.inrService.formatCurrency(String(cgst3Total)));
      item.controls['igst3Total'].setValue(this.inrService.formatCurrency(String(igst3Total)));
      item.controls['sgst3Total'].setValue(this.inrService.formatCurrency(String(sgst3Total)));
      item.controls['penalty3Total'].setValue(this.inrService.formatCurrency(String(penalty3Total)));
      item.controls['interest3Total'].setValue(this.inrService.formatCurrency(String(interest3Total)));
      item.controls['totOfTotal1'].setValue(this.inrService.formatCurrency(String(Number(igst1Total) + Number(cgst1Total) + Number(sgst1Total) + Number(cess1Total) + Number(interest1Total) + Number(penalty1Total))));
      item.controls['totOfTotal3'].setValue(this.inrService.formatCurrency(String(Number(cess3Total) + Number(cgst3Total) + Number(igst3Total) + Number(sgst3Total) + Number(penalty3Total) + Number(interest3Total))));
    });

  }

  issuingAuthChange() {
    (this.letterP1Form.get('itemList') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.controls.typeOfAuthority.value === 'CGST' && c.controls.din.value === "") {
        c.controls['din']?.setErrors({ 'required': true });
      } else {
        c.controls['din']?.setErrors(null);
      }
    })

  }

  selectedFileUrlUpdate(selectedValueUrl: string,) {
    this.selectedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(selectedValueUrl);
    console.log('chnaged Url', this.selectedFileUrl);
  }

  initializeDetailsOfConsultantCharges() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultant: any) => {
        let consultantDetails = this.consultantChargesList(consultant);
        (this.letterP1Form.get('consultantCharges') as UntypedFormArray).push(consultantDetails);

        if (consultant.otherAttachmentLoc) {
          consultant.otherAttachmentLoc.forEach((not: any) => {
            let otherAttachLocFrmGrp: UntypedFormGroup = this.createConsultAttachLoc(not);
            (consultantDetails.get('otherAttachmentLoc') as UntypedFormArray).push(otherAttachLocFrmGrp);

          });
        }

      })
    }
  }

  initializeAstmt12Copy() {
    if (this.caseData.asmt12Loc) {
      this.caseData.asmt12Loc.forEach((not: any) => {

        let astmtCopyFrmGrp = this.createasmt12LocLoc(not);

        (this.letterP1Form.get('asmt12Loc') as UntypedFormArray).push(astmtCopyFrmGrp);
      })
    }


  }
  initializeLiabilityAdmittedAndPaid() {
    if (this.caseData.liabilityDetails) {
      this.caseData?.liabilityDetails?.forEach((liability: any) => {
        let liabilityDetails = this.detailsOfLiabilityFrmGrp(liability);
        (this.letterP1Form.get('liabilityDetails') as UntypedFormArray).push(liabilityDetails);

        if (liability.copyOfFormLoc) {
          liability.copyOfFormLoc.forEach((not: any) => {
            let copyFormLocFrmGrp: UntypedFormGroup = this.createCopyFormLoc(not);
            (liabilityDetails.get('copyOfFormLoc') as UntypedFormArray).push(copyFormLocFrmGrp);

          });
        }

        if (liability.copyOfBankChallanLoc) {
          liability.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createBankFormLoc(not);
            (liabilityDetails.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);

          });
        }

      })
    }
  }

  getConsultantChargesTotal() {
    let feesChargedTotal: number = 0;
    (this.letterP1Form.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = this.inrService.removeCommasAndParseNumber(c.value.fees);
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  liabilityTotalCalculation() {
    let igstTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;
    (this.letterP1Form.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
      const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      igstTotal += Number(igst);
      cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + (Number(cgst)) + (Number(sgst)) + (Number(cess)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      liabilityPaidTotal += Number(total);
    });

    this.liabilityTotals.allTotal = liabilityPaidTotal;
    this.liabilityTotals.interestTotal = interestTotal;
    this.liabilityTotals.penaltyTotal = penaltyTotal;
    this.liabilityTotals.igstTotal = igstTotal;
    this.liabilityTotals.cgstTotal = cgstTotal;
    this.liabilityTotals.sgstTotal = sgstTotal;
    this.liabilityTotals.cessTotal = cessTotal;
  }
  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }
  inrFormattingRemoving() {
    const data = this.letterP1Form.value;
    //phase 2 form
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }
    //phase 3 form
    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(liability.igst)
        liability.cgst = this.inrService.removeCommasAndParseNumber(liability.cgst)
        liability.sgst = this.inrService.removeCommasAndParseNumber(liability.sgst)
        liability.cess = this.inrService.removeCommasAndParseNumber(liability.cess)
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }
    // //phase 1 and 3 form
    if (data.itemList && data.itemList.length) {
      data.itemList.forEach((itemList: any) => {

        if (itemList.issues && itemList.issues.length) {
          itemList.issues.forEach((issue: any) => {
            issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
            issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1)
            issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1)
            issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1)
            issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
            issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
            issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)
            issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
            issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3)
            issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3)
            issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3)
            issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
            issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
            issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
            if (issue.details && issue.details.length) {
              issue.details.forEach((details: any) => {
                details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)
                details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)
                details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)
                details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)
              })
            }
          })
        }
      })
    }
    if (data.itemList && data.itemList.length) {
      data.itemList.forEach((data: any) => {
        data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
        data.cgst1Total = this.inrService.removeCommasAndParseNumber(data.cgst1Total)
        data.sgst1Total = this.inrService.removeCommasAndParseNumber(data.sgst1Total)
        data.cess1Total = this.inrService.removeCommasAndParseNumber(data.cess1Total)
        data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
        data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
        data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)
        data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
        data.cgst3Total = this.inrService.removeCommasAndParseNumber(data.cgst3Total)
        data.sgst3Total = this.inrService.removeCommasAndParseNumber(data.sgst3Total)
        data.cess3Total = this.inrService.removeCommasAndParseNumber(data.cess3Total)
        data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
        data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
        data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)
      })
    }
  }
  formatTableValues() {

    const listItemArray = this.letterP1Form.controls.itemList as UntypedFormArray
    const liabilityDetailsArray = this.letterP1Form.controls.liabilityDetails as UntypedFormArray
    const predepositDetailsArray = this.letterP1Form.controls.predepositDetails as UntypedFormArray
    const consultantChargesArray = this.letterP1Form.controls.consultantCharges as UntypedFormArray

    if (listItemArray.controls && listItemArray.controls.length) {
      listItemArray.controls.forEach((control: any) => {
        if (control.value && control.value.issues && control.value.issues.length) {
          control.value.issues.forEach((issue: any) => {
            issue.igst1 = this.inrService.formatCurrency(issue.igst1)
            issue.cgst1 = this.inrService.formatCurrency(issue.cgst1)
            issue.sgst1 = this.inrService.formatCurrency(issue.sgst1)
            issue.cess1 = this.inrService.formatCurrency(issue.cess1)
            issue.interest1 = this.inrService.formatCurrency(issue.interest1)
            issue.penalty1 = this.inrService.formatCurrency(issue.penalty1)
            issue.total1 = this.inrService.formatCurrency(issue.total1)
            issue.igst3 = this.inrService.formatCurrency(issue.igst3)
            issue.cgst3 = this.inrService.formatCurrency(issue.cgst3)
            issue.sgst3 = this.inrService.formatCurrency(issue.sgst3)
            issue.cess3 = this.inrService.formatCurrency(issue.cess3)
            issue.interest3 = this.inrService.formatCurrency(issue.interest3)
            issue.penalty3 = this.inrService.formatCurrency(issue.penalty3)
            issue.total3 = this.inrService.formatCurrency(issue.total3)
          })
        }
      })
    }
    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }


    if (liabilityDetailsArray.controls.length > 0) {
      for (let index = 0; index < liabilityDetailsArray.controls.length; index++) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
        liabilityDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.igst))
        liabilityDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cgst))
        liabilityDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.sgst))
        liabilityDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cess))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest))
        liabilityDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.penalty))
        liabilityDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.total))
      }
    }
    this.issueChangeEvent()
  }



  //SMART UPLOAD CODE
  isUPpanCompLoc: boolean = true;
  ispanCompLocFile: boolean = false;
  ispanCompLocText: boolean = true;
  isDWpanCompLoc: boolean = true;
  alive: boolean = true;
  // progressStatus: '' | 'analysing' | 'in progress' | 'complete' = ''
  selectedFileUrl: any;
  selectedFileUrlPh2: any;
  intervalId: any;
  taskId: string = '';
  isRefreshVisible: boolean = false;
  isCheck: boolean = false;
  fetchLetterData: any;
  booleaArray: boolean[] = [];
  uploadpanCompLoc() {
    this.isUPpanCompLoc = false;
    this.ispanCompLocFile = true;
    this.ispanCompLocText = false;
    this.isDWpanCompLoc = false;

  }

  openModal(content: any) {
    this.modalService.open(content);
  }
  getFileNameSmartDta(fileUrl: any) {
    if (fileUrl && typeof (fileUrl) == 'string')
      return this.litService.getFileName(fileUrl);
  }
  get formControls() {
    return this.letterP1Form.controls;
  }

  private subscription: Subscription | any;

  onFileSelectSmart(event: any, index: number) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['pdf'])) {
        this.selectedFile = event.target.files[0];
        const fileUrl = URL.createObjectURL(this.selectedFile)
        this.selectedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);        
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And')
        this.selectedFile = new File([originalFile], modifiedFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.uploadedFileUrlList.push({
          name: originalFile.name,
          url: fileUrl,
        })
        this.onUpload(this.selectedField, null, null, index);
        this.hiddenbtn = true;
      } else {
        this.selectedFile = null;
        this.toaster.showError('Only PDF file type is supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }


  }


  onSmartUpload(phaseTab: any, letterIndex: number, subIndex: number) {
    this.loaderService.hide();
    if (phaseTab === 'p1') {
      this.phase = 'prelitigation_phase_1'
    } else {
      this.phase = 'prelitigation_phase_2'
    }
    let requestBody = {
      filePathData: this.filePathDataArray,
      phase: this.phase
    };
    this.litService.getNoticeProcessId(requestBody).subscribe((response: any) => {
      if (response.status == 1) {
        this.taskId = response.response.task_id;
        if (!this.ocrLetterMap[letterIndex])
          this.ocrLetterMap[letterIndex] = {
            uploaded: true,
            processed: false,
            response: null
          }
        this.ocrLetterMap[letterIndex].taskId = response.response.task_id
        this.checkStatus(phaseTab, letterIndex, subIndex);

      }
    })
  }

  statusData = '';
  checkStatus(phaseTab: any, letterIndex: number, subIndex: number) {
    this.loaderService.hide();
    if (phaseTab === 'p1') {
      this.phase = 'prelitigation_phase_1'
    } else if (phaseTab === 'p2') {
      this.phase = 'prelitigation_phase_2'
    }
    sessionStorage.setItem("Process_id", this.taskId)

    this.litService.checkNoticeStatus(this.taskId, this.phase).subscribe((response: any) => {
      switch (response.response.status) {
        case 'processing':
          this.toaster.showWarning(response.response.message);
          this.statusData = response.response.message;
          this.hideRefreshIcon = true;
          this.hiddenbtn = false;
          this.subscription = interval(30000)
            .pipe(switchMap(() => this.litService.checkNoticeStatus(this.taskId, this.phase)))
            .subscribe((response: any) => {
              this.statusData = response.response.message;
              this.hideRefreshIcon = true;
              if (phaseTab === 'p1') {
                
                if (response.response.status !== 'processing') {
                  const itemDetails = this.caseData?.itemList ?? [];
                  itemDetails[letterIndex] = response.response.itemList[0];
                  this.caseData = response.response;
                  this.caseData.itemList = itemDetails;
                  this.ocrLetterMap[letterIndex]!.response = response.response;
                  this.ocrLetterMap[letterIndex]!.processed = true;
                  this.initializeItemListData(letterIndex)
                  this.sidemenu = false;
                  this.hiddenbtn = false;
                  this.hidlabels = false;
                  this.hidlabelsub = false;
                  this.hideRefreshIcon = false;
                  if (response.response.status !== 'processing') {
                    this.subscription.unsubscribe();
                  }
                }
              } else {
                if (response.response.status !== 'processing') {
                  this.hideRefreshIcon = false;
                  const itemDetails = this.caseData?.itemList ?? [];
                  itemDetails[0].submissions[subIndex] = response.response.itemList[0].submissions[subIndex];
                  this.caseData = response.response;
                  this.caseData.itemList[0].submissions[subIndex];
                  this.ocrLetterMap[letterIndex]!.response = response.response;
                  this.ocrLetterMap[letterIndex]!.processed = true;
                  console.log(this.caseData, " this.caseData")
                  this.initializeItemListSub(letterIndex, subIndex)
                  this.sidemenu = false;
                  this.hiddenbtn = false;
                  this.hidlabels = false;
                  this.hidlabelsub = false;
                  if (response.response.status !== 'processing') {
                    this.subscription.unsubscribe();
                  }
                }
              }


            });
          break;

        default:
          if (phaseTab === 'p1') {
            const itemDetails = this.caseData?.itemList ?? [];
            itemDetails[letterIndex] = response.response.itemList[0];
            this.caseData = response.response;
            this.caseData.itemList = itemDetails;
            this.hideRefreshIcon = false;

            this.ocrLetterMap[letterIndex]!.response = response.response;
            this.ocrLetterMap[letterIndex]!.processed = true;
            this.sidemenu = false;
            this.hiddenbtn = false;
            this.hidlabels = false;
            this.hidlabelsub = false;
            this.initializeItemListData(letterIndex)
          } else {
            this.hideRefreshIcon = false;
            const itemDetails = this.caseData?.itemList ?? [];
            itemDetails[0].submissions[subIndex] = response.response.itemList[0].submissions[subIndex];
            this.caseData = response.response;
            this.caseData.itemList[0].submissions[subIndex];
            this.ocrLetterMap[letterIndex]!.response = response.response;
            this.ocrLetterMap[letterIndex]!.processed = true;
            console.log(this.caseData, " this.caseData")
            this.initializeItemListSub(letterIndex, subIndex)
            this.sidemenu = false;
            this.hiddenbtn = false;
            this.hidlabels = false;
            this.hidlabelsub = false;
            if (response.response.status !== 'processing') {
              this.subscription.unsubscribe();
            }
          }

          break;
      }
    });

  }

  getperiodToValue(content: any, i: any) {
    if (this.action === "create") {
      this.selectedPeriodFromDate = this.letterP1Form.value.itemList[i].periodFromDate;
      this.selectedPeriodToDate = this.letterP1Form.value.itemList[i].periodToDate;
      let model: any = {};
      model.page = 0,
        model.size = 10,
        model.sortfield = "createdOn",
        model.sortdir = "DESC",
        model.companyId = localStorage.getItem('selectedId'),
        model.module = "GST",
        model.gstin = this.selectedGstin,
        model.criterias = [
          {
            "p": "itemList.periodFromDate",
            "o": "between",
            "v1": this.selectedPeriodFromDate,
            "v2": this.selectedPeriodToDate,
          }, {
            "p": "itemList.periodToDate",
            "o": "between",
            "v1": this.selectedPeriodFromDate,
            "v2": this.selectedPeriodToDate,
          }
        ]
      this.litService.getDuplicateData(model).subscribe((response: any) => {
        console.log(response.response.length, "response.length");
        if (response.response.length === 0) {
          this.modalService.dismissAll();
        }
        else {
          this.duplicateData = response?.response;
          this.duplicateData?.forEach((obj: any, index: number) => {
            if (obj.forum === 'Appellate Authority')
              this.duplicateData[index].forum = 'Commissioner (A)'
          })
          this.modalService.open(content)
        }

      });
    }
  }
  selectedIssues: any
  getissueData(content: any, i: any, k: any) {
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.letterP1Form.value.itemList[i].issues[k].issue;
    let model: any = {};
    model.page = 0,
      model.size = 10,
      model.sortfield = "createdOn",
      model.sortdir = "DESC",
      model.companyId = "7437",
      model.module = "GST",
      model.role = "issueRef",
      model.criterias = [
        {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  this.litService.getIssueRefData(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.issueData = response?.response;
        if (this.issueData.length === 0) {
          this.hideData = false
        } else {
          this.hideData = true
        }
      }
    });
  }
  exportAsXLSX() {
    if (this.issueData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data = this.issueData.map((source: any) => ({
      'PAN Name': source.panName,
      'GSTIN': source.gstin,
      'GSTIN Name': source.gstinName,
      'Division': source.gstinDiv,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'Period From': source.periodFromDate,
      'Period to': source.periodToDate,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.STATE: {
        if (!this.sorting.state) {
          this.sorting.state = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'asc'
          );
        } else {
          this.sorting.state = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERIOD_FROM: {
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'asc'
          );
        } else {
          this.sorting.periodFromDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERIOD_TO: {
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'asc'
          );
        } else {
          this.sorting.periodToDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData, 'personResponsible', 'asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData, 'personResponsible', 'desc');
        }
        break;
      }
      case SORT_ISSUE.GSTIN: {
        if (!this.sorting.gstin) {
          this.sorting.gstin = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'asc'
          );
        } else {
          this.sorting.gstin = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.DIVISION: {
        if (!this.sorting.gstinDiv) {
          this.sorting.gstinDiv = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'asc'
          );
        } else {
          this.sorting.gstinDiv = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'desc'
          );
        }
        break;
      }
    }
  }

 
goToSummary(){
  this.router.navigate(['/litigationSummary']);
  this.modalService.dismissAll();
}

getCompanyTags(gstin:any) {
  const regNo = gstin;
  this.masterService.getEntityTagsCase(regNo).subscribe((response: any) => {
    if (response.status === 1) {
      const allTags = response.response.data;
      this.tagDataList = allTags
        .filter((tagItem: any) => tagItem.regNo === regNo)
        .flatMap((tagItem: any) =>
          tagItem.tags
            .filter((tag: any) => tag.active) // Filter tags with active: true
            .map((tag: any) => tag.tag)
        ); 

    } else {        
      this.toaster.showSuccess(response.message);
    }
  });
}


}

import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ADJ_PH1_ADJUNOT, ADJ_PH1_PRECEDED, TAB_TYPE, ADJ_ATTEND, ADJ_OUTCOME, ADJ_APPELPRE, ADJ_DEPTPRE, LETTER_GSTIN, STATE_LIST, ADJ_WRITPRE, ADJ_ORDER_PASSED_BY, ISSUE_CONCLUSION, ISSUE_RISK_PARAM, QUARTER, YES_NO, ISSUING_AUTHORITY_TYPE, MODE_OF_RECEIPT, DESIGNATION, MODE_OF_PAYMENT, PRE_ACT_TYPE, FORUM_APPEAL, PAYMENT, ISSUED_US, FORUM_APPEAL_GST, FAVOURABLE_OPTION, UNFAVOURABLE_OPTION, ADJ_ACT_TYPE, DESIGNATION_ADJ, ISSUE_GST, MODE_OF_BGSTATUS, MODE_OF_PH, MODE_OF_TIME_PH, APPEAL_DECISION, INT_RATE } from '../../shared/constant';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray, FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { LitigationServiceService } from '../services/litigation-service.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PreGstServiceService } from 'src/app/preGstLitigation/services/pre-gst-service.service';
import { reject, stubFalse } from 'lodash-es';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { InrService } from 'src/app/shared/services/inr.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { data } from 'jquery';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MasterService } from 'src/app/businessManagement/services/master.service';
import { forkJoin } from 'rxjs';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  GSTIN = 'gstin',
  DIVISION = 'gstinDiv',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}
interface PaymentDetails {
  issueName: any;
  refId: any;
  tag: any;
  stage: any;
  challanNo: any;
  markedForPayment: any;
}

interface OcrLetterMap {
  [key: string]: {
    uploaded: boolean;
    processed: boolean;
    taskId?: string;
    filePath?: string;
    response: any;
  }
}
interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
interface tagData {
  tags: string;
}

@Component({
  selector: 'app-adjudication-auth',
  templateUrl: './adjudication-auth.component.html',
  styleUrls: ['./adjudication-auth.component.css'],
})
export class AdjudicationAuthComponent implements OnInit {
  parentCaseId: any;

  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToAT: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToSC: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> =
    new EventEmitter<string[] | null>();
  issueData: any;

  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;

  intCalPopData: any;
  intCalIssueData: any;
  selectedTab: any;
  selectedPhase: any;
  isIssueDiv: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  rmvperiodToDateRef!: NgbModalRef;
  rmvTextModelRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;
  openCaseRef!: NgbModalRef;
  adjPhTablist: any[] = [];
  adjPhDivlist: any[] = [];
  issInvArr: any[] = [];
  itemList: any[] = [];
  obj0: any = {};
  adjornot: any;
  savedCaseId: any;
  caseId: any;
  action: any;
  caseData: any = {};
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  filePath: any;
  matches: any;
  uploadData: any;
  currentYear: any;
  someElement: any;
  dataSource: any;
  maxDate = new Date();
  adjPHAdjuorNotList: { label: string; value: boolean }[];
  adjPH1Preceded: { label: string; value: boolean }[];
  adjAttend: { label: string; value: boolean }[];
  tabType: { key: string; value: string }[];
  outcome: { key: string; value: string }[];
  issueConclusion: { key: string; value: string }[];
  orderPassedBy: { key: string; value: string }[];
  p1Gstin: { key: string; value: string }[];
  riskParam: { key: string; value: string }[];
  whetherAppealPreferred: { label: string; value: boolean }[];
  furtherAppealDecision: { label: string; value: boolean }[];
  whetherDptAppealPreferred: { label: string; value: boolean }[];
  writAppPreferred: { label: string; value: boolean }[];
  p1stateList: { stateName: string; stateCode: string; id: number }[];
  quarterList: { key: string; value: string; disabled: string }[];
  yesNoList: { label: string; value: boolean }[];
  intRate: { label: string; value: string }[];
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    gstin: boolean;
    caseLabel: boolean;
    gstinDiv: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      gstin: false,
      gstinDiv: false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
    };
  issueSorting = SORT_ISSUE;
  isadjorNot0: boolean = false;
  isSubmitted: boolean = false;
  isDataSaved: boolean = false;
  isUpdate: boolean = false;
  isCaseData: boolean = true;
  isCaseID: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isIssueAdd: boolean = false;
  isProvAdd: boolean = false;
  isCreateBtn: boolean = false;
  isUpdateBtn: boolean = false;
  hideDiv: boolean = true;
  isDisableIcons: boolean = false;
  callBookDisabled = false;

  disabledWeatherAppl: boolean = false;
  disabledWeatherWrit: boolean = false;
  disabledWeatherDept: boolean = false;

  isonupCreate: boolean = true;
  isondwCreate: boolean = true;
  adjP1Form!: UntypedFormGroup;
  adjP2Form!: UntypedFormGroup;
  adjP3Form!: UntypedFormGroup;
  scnLocFile: any;
  attachmentsLocFile: any;
  copyOfAckLocF: any;
  phCopyLocF: any;
  phRecordIfAnyLocF: any;
  oioLocF: any;
  paymentChallanLocF: any;
  otherAttachmentsLocF: any;
  selectedFilling: any;
  selectedGstin: any;
  selectedGstinDiv: any;
  selectedState: any;
  selectedStateCode: any;
  selectedStateName: any;
  arrayBuffer: ArrayBuffer = new ArrayBuffer(10);
  sidemenu = true;
  hideOcrFiled: boolean = false;
  hideOcrFilePh2: boolean = false;
  hideOcrFilePh3: boolean = false;

  isUPscnLoc: boolean = true;
  isDWscnLoc: boolean = true;
  isscnLocFile: boolean = false;
  isscnLocText: boolean = true;

  isUPattachmentsLoc: boolean = true;
  isDWattachmentsLoc: boolean = true;
  isattachmentsLocFile: boolean = false;
  isattachmentsLocText: boolean = true;

  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLoc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttachmentsLoc: boolean = true;
  isDWotherAttachmentsLoc: boolean = true;
  isotherAttachmentsLocFile: boolean = false;
  isotherAttachmentsLocText: boolean = true;

  isUPphCopyLoc: boolean = true;
  isDWphCopyLoc: boolean = true;
  isphCopyLocFile: boolean = false;
  isphCopyLocText: boolean = true;

  isUPadditionalSubmissionLoc: boolean = true;
  isDWadditionalSubmissionLoc: boolean = true;
  isUadditionalSubmissionLocFile: boolean = false;
  isadditionalSubmissionLocText: boolean = true;

  isUPphRecordIfAnyLoc: boolean = true;
  isDWphRecordIfAnyLoc: boolean = true;
  isphRecordIfAnyLocFile: boolean = false;
  isphRecordIfAnyLocText: boolean = true;

  isUPoioLoc: boolean = true;
  isDWoioLoc: boolean = true;
  isoioLocFile: boolean = false;
  isoioLocText: boolean = true;

  isUPpaymentChallanLoc: boolean = true;
  isDWpaymentChallanLoc: boolean = true;
  ispaymentChallanLocFile: boolean = false;
  ispaymentChallanLocText: boolean = true;

  copyOfAckLocReq: boolean = false;
  scnLocReq: boolean = false;
  oioLocReq: boolean = false;
  selectedPhaseId: any;
  getAccessTabData: any;
  navContext: any;
  userRoleLs: any;
  autoIssueList: any;
  autoTagList: any;
  consultantList: ConsultantList[] = [];
  //dwdscnLoc: any;
  // dwdattachmentsLoc: any;
  // dwdcopyOfAckLoc: any;
  // dwdotherAttachmentsLoc: any;
  // dwdoioLoc: any;
  // dwdpaymentChallanLoc: any;
  finyearData: any[] = [];

  caseItemList: any = [];
  isRemandedBack = false;
  isToggleDropdown = true;
  phase: any
  hiddenbtn = false;
  ShowErrormsg: boolean = false
  ocrMsgPop: any
  taskId: string = '';
  statusData = '';
  selectedFileUrl: any;
  private subscription: Subscription | any;
  uploadedfileName = '';
  uploadedFileUrlList: {
    name: string;
    url: string;
  }[] = [];
  hidlabels = true;
  hidlabelsOio = true;
  hidlabelsub = true;

  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdown3 = true;
  isToggleSegmentDropdown = true;
  isToggleSegmentDropdown2 = true;

  selectedRemandedBackCaseDetail: any = null;
  remandedBackCumulative: any = {};
  remandedCaseObj: any = [];
  cumulativeCount = [
    {
      label: 'Cumulative payment made under protest',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative payment made for admitted liability',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative Consultant Charges',
      tax: null,
      interest: null,
      penalty: null,
      total: 0,
    },
  ];
  typeOfAuthorityDropdown = ISSUING_AUTHORITY_TYPE;
  modeOfReceiptNotice = MODE_OF_RECEIPT;
  yesNoDropdown = YES_NO;
  detailOfIssuingAuthorityExpansion = false;
  designation = DESIGNATION_ADJ;
  protestDetailsExpansion = false;
  modeOfPH = MODE_OF_PH
  modeOfTime = MODE_OF_TIME_PH
  rmvDetailsOfPaymentRef: any;

  protestInterestTotal = 0;
  protestIgstTotal = 0;
  protestCGSTTotal = 0;
  protestSGSTTotal = 0;
  protestCESSTotal = 0;

  protestPenaltyTotal = 0;
  protestTotal = 0;
  modeOfPayment = MODE_OF_PAYMENT;
  detailsOfSubmissionsExpansion = false;
  rmvSubmissionDetailsRef: any;
  feesChargedTotal = 0;
  rmvConsultantModalRef: any;
  consultantPanleExpansion = false;
  remandedPaymentMade = null;

  isCopyOfAckFile = false;
  isCopyOfAckText = true;
  isCopyOfAckDWText = true;
  isCopyOfAckUPText = true;

  isOtherAttachmentFile = false;
  isOtherAttachmentText = true;
  isOtherAttachmentDWText = true;
  isOtherAttachmentUPText = true;

  isSupportingDocFile = false;
  isSupportingDocText = true;
  isSupportingDocDWText = true;
  isSupportingDocUPText = true;

  isconsultantattachmentsLocFile = false;
  isconsultantattachmentsLocText = true;
  isconsultantDWattachmentsLocText = true;
  isconsultantUPattachmentsLocText = true;

  paymentUnderRemandedBackExpansion = false;
  preAdjType = ADJ_ACT_TYPE;
  detailsOfAllPaymentMadeExpansion = false;
  modeOfAppealOptions = MODE_OF_RECEIPT;
  detailsOfLiabilityAddmittedAndPaidExpanded = false;

  liabilityTotals = {
    igstTotal: 0,
    cgstTotal: 0,
    sgstTotal: 0,
    cessTotal: 0,
    interestTotal: 0,
    penaltyTotal: 0,
    allTotal: 0,
  };
  liabilityExpansionPanel = false;
  rmvLiabilityModalRef: any;
  forumAppeal = FORUM_APPEAL_GST;
  proceedWithPreferredForumAppeal: any;

  //  Details of Liability Admitted and Paid
  iscopyOfUPFormLoc: boolean = true;
  iscopyOfDWFormLoc: boolean = true;
  iscopyOfFormLocFile: boolean = false;
  iscopyOfFormLocText: boolean = true;

  iscopyOfBankUPChallanLoc: boolean = true;
  iscopyOfBankDWChallanLoc: boolean = true;
  iscopyOfBankChallanLocFile: boolean = false;
  iscopyOfBankChallanLocText: boolean = true;
  viewIons: boolean = false;
  hideData: boolean = false;
  hideRefreshIcon: boolean = false;

  formPayment = PAYMENT;
  detailOfIssuingAuthorityExpansion1 = false;
  detailsOfAllPaymentMadeExpansion1 = false;
  preLitIssuedPH1Preceded = ISSUED_US;
  disablePhase3 = false;
  disablePhase2 = false;
  markedForPaymentFlag = false;
  remandBackCaseList = [];
  duplicateData: any
  oldProtestDetailsValues: any = [];
  oldLiabilityDetailValues: any = [];
  oldConsulatntValues: any = [];
  prevStage: any;
  currentTab = 'aja';
  activeTab: any;
  attachmentsLength: any;
  ocrLetterMap: OcrLetterMap = {}
  roleName: any;
  tagDataList: tagData[] = [];
  selectedCompanyId: any;
  navData: any;
  currentDate: any;
  //  isNotEmpty!: boolean ;
  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private litService: LitigationServiceService,
    private toaster: ToasterService,
    private activeSnapShot: ActivatedRoute,
    private commonServ: CommonService,
    private modalService: NgbModal,
    private inrService: InrService,
    private preLitService: PreGstServiceService,
    private excelService: ExcelService,
    private loaderService: LoaderService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private masterService: MasterService,
    private GS: GetterSetterService,
    private US: UserSettingsService,
  ) {
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.p1Gstin = LETTER_GSTIN;
    this.p1stateList = STATE_LIST;
    this.adjPH1Preceded = ADJ_PH1_PRECEDED;
    this.outcome = ADJ_OUTCOME;
    this.issueConclusion = ISSUE_CONCLUSION;
    this.orderPassedBy = DESIGNATION_ADJ; //DESIGNATION
    this.riskParam = ISSUE_RISK_PARAM;
    this.adjAttend = ADJ_ATTEND;
    this.whetherAppealPreferred = ADJ_APPELPRE;
    this.furtherAppealDecision = APPEAL_DECISION;
    this.whetherDptAppealPreferred = ADJ_DEPTPRE;
    this.writAppPreferred = ADJ_WRITPRE;
    this.tabType = TAB_TYPE;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.quarterList = QUARTER;
    this.yesNoList = YES_NO;
    this.intRate = INT_RATE;
    this.isRemandedBack = this.activeSnapShot.snapshot.params.caseId?.includes(
      'R-'
    )
      ? true
      : this.activeSnapShot.snapshot.params.remanded;
  }

  ngOnInit() {
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    window.scrollTo(0, 0);
    this.setStateAutoPop();
    //this.getCompanyTags();
    this.navContext = this.commonServ.getNavContext();
    this.litService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    this.setDate();
    this.personResponsibleData();
    this.consultantData();
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.isIssueDiv = 'issueDiv0';
    this.currentDate = new Date();
    // this.createAdjTab();
    // this.createAdjDiv();
    this.formInitialize();
    this.getYears();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
    }
    if (this.action === 'create') {
      this.getCompanyTags(this.selectedGstin);
      this.createAdjDiv();
      this.addIssueInv();
      this.addDetailOfPayment();
      this.addSubmissionDetailsArr();
      this.addConsultantInv();
      this.addLiabilityInv(null);

      //this.addIssue3Inv();
      //this.addProv();
      this.viewIons = false;
      this.isCreateBtn = true;
      this.isonupCreate = false;
      this.isondwCreate = false;
      this.hideOcrFiled = true;
      this.isDisableIcons = true;
      this.hideOcrFilePh2 = true;
      this.hideOcrFilePh3 = true;

      this.isscnLocFile = true;
      this.isscnLocText = false;
      this.isUPscnLoc = false;

      this.isattachmentsLocFile = true;
      this.isattachmentsLocText = false;
      this.isUPattachmentsLoc = false;

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isotherAttachmentsLocFile = true;
      this.isotherAttachmentsLocText = false;
      this.isUPotherAttachmentsLoc = false;

      this.isphCopyLocFile = true;
      this.isphCopyLocText = false;
      this.isUPphCopyLoc = false;

      this.isUadditionalSubmissionLocFile = true;
      this.isadditionalSubmissionLocText = false;
      this.isUPadditionalSubmissionLoc = false;

      this.isphRecordIfAnyLocFile = true;
      this.isphRecordIfAnyLocText = false;
      this.isUPphRecordIfAnyLoc = false;

      this.isoioLocFile = true;
      this.isoioLocText = false;
      this.isUPoioLoc = false;

      this.ispaymentChallanLocFile = true;
      this.ispaymentChallanLocText = false;
      this.isUPpaymentChallanLoc = false;
    } else if (this.action === 'update') {
      this.createAdjDiv();
      this.addIssueInv();
      //this.addIssue3Inv();
      //this.addProv();
      this.viewIons = true;
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.isDisableIcons = true;
      this.hideOcrFiled = true;


    } else if (this.action === 'view') {
      this.isDataSaved = true;
      this.viewIons = false;
      this.isonupCreate = false;
      this.hideOcrFiled = false;

      this.isPhase2 = true;
      this.isPhase3 = true;
      this.isDisableIcons = false;

      this.isUPscnLoc = false;
      this.isDWscnLoc = true;

      this.isUPattachmentsLoc = false;
      this.isDWattachmentsLoc = true;

      this.isUPcopyOfAckLoc = false;
      this.isDWcopyOfAckLoc = true;

      this.isUPotherAttachmentsLoc = false;
      this.isDWotherAttachmentsLoc = true;

      this.isUPphCopyLoc = false;
      this.isDWphCopyLoc = true;

      this.isUPadditionalSubmissionLoc = false;
      this.isDWadditionalSubmissionLoc = true;

      this.isUPphRecordIfAnyLoc = false;
      this.isDWphRecordIfAnyLoc = true;

      this.isUPoioLoc = false;
      this.isDWoioLoc = true;

      this.isUPpaymentChallanLoc = false;
      this.isDWpaymentChallanLoc = true;
    }
    this.issueDropdown();
    if (this.caseId != ' ' && this.caseId != undefined) {
      this.isCreateBtn = false;
      // this.hideOcrFiled = false;
      this.isUpdateBtn = true;
    } else {
      this.isCreateBtn = true;
    }
    const userId: any = sessionStorage.getItem('user');
    this.adjP1Form.controls.personResponsible1.patchValue(userId);

    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
    } else {
      this.roleName = localStorage.getItem('roleName');
    }
  }
  getScnLoc(): UntypedFormGroup[] {
    return (this.adjP1Form.get('scnLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  ngAfterViewInit() {
    if (this.action === 'update') {
      this.chkFrmValidated();
    }
  }
  onClickToggleDropdown() {
    this.isToggleDropdown = !this.isToggleDropdown;
  }


  /* current year funtion */

  tabChanged(tab: any, check?: any) {
    this.adjP1Form.patchValue(this.adjP1Form.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    } else if (tab === 'tab2') {
      //this.p2validation();
    } else if (tab === 'tab3') {
      this.setIssuingAuthorityData();
      //this.p3validation();
    }
  }

  getOtherAttachmentLoc(): UntypedFormGroup[] {
    return (this.adjP1Form.get('attachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getCopyOfAck(ackCOpyLocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (ackCOpyLocLocGrp.get('copyOfAckLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getOtherAttachment(otherAttachmentsLocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachmentsLocLocGrp.get('otherAttachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultOtherAttachment(consultAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultAttachGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getOioLoc(): UntypedFormGroup[] {
    return (this.adjP1Form.get('oioLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getProtestCopyForm(copyFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (copyFormLoc.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getProtestBankChallan(bankChallanFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (bankChallanFormLoc.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityCopyForm(liabilitycopyFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitycopyFormLoc.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityBankChallan(liabilitybankChallanFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitybankChallanFormLoc.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getScnCopyLocLength() {
    let count = 0;
    let predepositFrmArray = this.adjP1Form.get('scnLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }

  getAttachmentsLocLength() {
    let count = 0;
    let predepositFrmArray = this.adjP1Form.get('attachmentsLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getProtestCopyLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.adjP1Form.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getProtestBankChallanLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.adjP1Form.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getCopyOfAckLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.adjP1Form.get('submissions') as UntypedFormArray;
    let letterLocFrmArray = submissionsFrmArray.controls[j]?.get('copyOfAckLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getOtherAttachmentsLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.adjP1Form.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionsFrmArray.controls[j]?.get('otherAttachmentsLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get('phCopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get('additionalSubmissionLoc') as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.adjP1Form.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getOioLocLength() {
    let count = 0;
    let predepositFrmArray = this.adjP1Form.get('oioLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getLiabilityCopyFormLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.adjP1Form.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getLiabilityBankChallanLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.adjP1Form.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }

  openPdfPopModel(content: any) {
    this.addScnLoc();
    this.openCaseRef = this.modalService.open(content, { windowClass: 'ocrpop' });
  }
  openSubmissionPdfPopModel(content: any, i: any) {
    this.addCopyOfAck(i, 0);
    this.openCaseRef = this.modalService.open(content, { windowClass: 'ocrpop' });
  }
  openOioPdfPopModel(content: any) {
    this.addOioLoc();
    this.openCaseRef = this.modalService.open(content, { windowClass: 'ocrpop' });
  }

  openScnLocModel(content: any) {
    this.addScnLoc();
    this.modalService.open(content);
  }
  openAttachLocModel(content: any) {
    this.addOtherAttachment();
    this.modalService.open(content);
  }
  openCopyOfAck(content: any, i: any) {
    this.addCopyOfAck(i, 0);
    this.modalService.open(content);
  }
  openOtherAttach2(content: any, i: any) {
    this.addOtherAttachment2(i, 0);
    this.modalService.open(content);
  }
  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openConsultAttach(content: any, i: any) {
    this.addConsultAttach(i, 0);
    this.modalService.open(content);
  }
  openOioLocModel(content: any) {
    this.addOioLoc();
    this.modalService.open(content);
  }
  openCopyOfForm(content: any, i: any) {
    this.addCopyOfForm(i, 0);
    this.modalService.open(content);
  }
  openProCopyOfBankChallan(content: any, i: any) {
    this.addCopyOfProBankChalan(i, 0);
    this.modalService.open(content);
  }
  openLiabilityCopyOfForm(content: any, i: any) {
    this.addLiabilityCopyOfForm(i, 0);
    this.modalService.open(content);
  }
  openLiabilityBankChallan(content: any, i: any) {
    this.addLiabilityCopyOfProBankChalan(i, 0);
    this.modalService.open(content);
  }
  createScnLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachment(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAckCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachment2Loc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createConsultAttach(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOioLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProBankChallan(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityBankChallan(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addScnLoc() {
    let scnCopyArray = this.adjP1Form.get('scnLoc') as UntypedFormArray;
    let i = 0;
    if (scnCopyArray.length > 0) {
      let length = scnCopyArray.length;

      while (i < 5 - length) {
        scnCopyArray.push(this.createScnLoc());

        i++;
      }
    } else {
      while (i < 5) {
        scnCopyArray.push(this.createScnLoc());
        i++;
      }
    }
    this.adjP1Form.updateValueAndValidity();
  }
  addOtherAttachment() {
    let attachmentsArray = this.adjP1Form.get('attachmentsLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createOtherAttachment());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createOtherAttachment());

        i++;
      }
    }
  }
  uploadScnCopy(scnLocIndex: any) {
    this.isscnLocFile = true;
    this.isscnLocText = false;
    this.isDWscnLoc = false;
    this.isUPscnLoc = false;

    let scnLocArray = this.adjP1Form.controls.scnLoc as UntypedFormArray;
    if (scnLocArray.controls.length > 0) {
      for (let index = 0; index < scnLocArray.controls.length; index++) {
        if (index === scnLocIndex) {
          let itemListuploadControl = scnLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteScnCopy(scnCopyIndex: any) {
    let scnLocArray = this.adjP1Form.controls.scnLoc as UntypedFormArray;
    if (scnLocArray.controls.length > 0) {
      for (let index = 0; index < scnLocArray.controls.length; index++) {
        if (index === scnCopyIndex) {
          let itemListuploadControl = scnLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteScnCopyAi(scnCopyIndex: any) {
    let scnLocArray = this.adjP1Form.controls.scnLoc as UntypedFormArray;
    if (scnLocArray.controls.length > 0) {
      for (let index = 0; index < scnLocArray.controls.length; index++) {
        if (index === scnCopyIndex) {
          let itemListuploadControl = scnLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
          this.filePathDataArray.splice(index, 1);
          this.uploadedFileUrlList.splice(index, 1);
        }
      }
    }
  }
  uploadOtherAttach(otherAttachLocIndex: any) {
    this.isattachmentsLocFile = true;
    this.isattachmentsLocText = false;
    this.isDWattachmentsLoc = false;
    this.isUPattachmentsLoc = false;

    let attachmentLocArray = this.adjP1Form.controls.attachmentsLoc as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttach(otherAttachLocIndex: any) {
    let attachmentLocArray = this.adjP1Form.controls.attachmentsLoc as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addCopyOfAck(j: any, i: any) {
    let dtSubmmissionFrmArray = this.adjP1Form.get('submissions') as UntypedFormArray;
    let copyFormLocFrmArray = dtSubmmissionFrmArray.controls[j].get('copyOfAckLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createAckCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createAckCopyLoc());
        i++;
      }
    }
  }

  uploadCopyAckForm(copyListIndex: any, copyAckLocIndex: any) {
    this.isCopyOfAckFile = true;
    this.isCopyOfAckText = false;
    this.isCopyOfAckDWText = false;
    this.isCopyOfAckUPText = false;

    let submmissionDtlsArray = this.adjP1Form.controls.submissions as UntypedFormGroup;
    let copyFormLocFrmArray = submmissionDtlsArray.controls[copyListIndex].get('copyOfAckLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteCopyAckForm(copyListIndex: any, copyAckLocIndex: any) {
    let liablityArray = this.adjP1Form.controls.submissions as UntypedFormGroup;
    let copyFormFrmArray = liablityArray.controls[copyListIndex].get('copyOfAckLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteCopyAckFormAi(copyListIndex: any, copyAckLocIndex: any) {
    let liablityArray = this.adjP1Form.controls.submissions as UntypedFormGroup;
    let copyFormFrmArray = liablityArray.controls[copyListIndex].get('copyOfAckLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
          this.filePathDataArray.splice(index, 1);
          this.uploadedFileUrlList.splice(index, 1);
        }
      }
    }
  }
  addOtherAttachment2(j: any, i: any) {
    let dtSubmmissionFrmArray = this.adjP1Form.get('submissions') as UntypedFormArray;
    let copyFormLocFrmArray = dtSubmmissionFrmArray.controls[j].get('otherAttachmentsLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createOtherAttachment2Loc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createOtherAttachment2Loc());
        i++;
      }
    }
  }
  uploadOtherAttachment2(othAttachListIndex: any, othAttach2LocIndex: any) {
    this.isOtherAttachmentFile = true;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentDWText = false;
    this.isOtherAttachmentUPText = false;

    let submmissionDtlsArray = this.adjP1Form.controls.submissions as UntypedFormGroup;
    let otherAttach2 = submmissionDtlsArray.controls[othAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttach2.controls.length > 0) {
      for (let index = 0; index < otherAttach2.controls.length; index++) {
        if (index === othAttach2LocIndex) {
          let itemListuploadControl = otherAttach2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachment2(othAttachListIndex: any, othAttach2LocIndex: any) {
    let submmissionArray = this.adjP1Form.controls.submissions as UntypedFormGroup;
    let otherAttach2 = submmissionArray.controls[othAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttach2.controls.length > 0) {
      for (let index = 0; index < otherAttach2.controls.length; index++) {
        if (index === othAttach2LocIndex) {
          let itemListuploadControl = otherAttach2.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get('phCopyLoc') as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;

    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }
  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }
  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.adjP1Form.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.adjP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addConsultAttach(j: any, i: any) {
    let consultFrmArray = this.adjP1Form.get('consultantCharges') as UntypedFormArray;
    let consultAttachFrmArray = consultFrmArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachFrmArray.length > 0) {
      let length = consultAttachFrmArray.length
      while (i < 5 - length) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
    else {
      while (i < 5) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
  }
  uploadConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.adjP1Form.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.adjP1Form.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;
    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addOioLoc() {
    let oioArray = this.adjP1Form.get('oioLoc') as UntypedFormArray;
    let i = 0;

    if (oioArray.length > 0) {
      let length = oioArray.length;

      while (i < 5 - length) {
        oioArray.push(this.createOioLoc());

        i++;
      }
    } else {
      while (i < 5) {
        oioArray.push(this.createOioLoc());

        i++;
      }
    }
    this.adjP1Form.updateValueAndValidity();
  }
  uploadOioCopy(oioLocIndex: any) {

    let oioLocArray = this.adjP1Form.controls.oioLoc as UntypedFormArray;
    if (oioLocArray.controls.length > 0) {
      for (let index = 0; index < oioLocArray.controls.length; index++) {
        if (index === oioLocIndex) {
          let itemListuploadControl = oioLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
    // for (let index = 0; index < this.adjP1Form.value.oioLoc.length; index++) {
    //   const element = this.adjP1Form.value.oioLoc[index];
    //   if (element.fileLoc == '') {
    //     this.isNotEmpty = false;
    //   } else {
    //     this.isNotEmpty = true;
    //     break;
    //   }
    // }
  }
  deleteOioCopy(oioLocIndex: any) {
    let oioLocArray = this.adjP1Form.controls.oioLoc as UntypedFormArray;
    if (oioLocArray.controls.length > 0) {
      for (let index = 0; index < oioLocArray.controls.length; index++) {
        if (index === oioLocIndex) {
          let itemListuploadControl = oioLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOioCopyAi(oioLocIndex: any) {
    let oioLocArray = this.adjP1Form.controls.oioLoc as UntypedFormArray;
    if (oioLocArray.controls.length > 0) {
      for (let index = 0; index < oioLocArray.controls.length; index++) {
        if (index === oioLocIndex) {
          let itemListuploadControl = oioLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null;
          this.filePathDataArray.splice(index, 1);
          this.uploadedFileUrlList.splice(index, 1);
        }
      }
    }
  }
  addCopyOfForm(j: any, i: any) {
    let dtSubmmissionFrmArray = this.adjP1Form.get('protestDetails') as UntypedFormArray;
    let copyFormLocFrmArray = dtSubmmissionFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createProCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createProCopyForm());
        i++;
      }
    }
  }
  addCopyOfProBankChalan(j: any, i: any) {
    let dtSubmmissionFrmArray = this.adjP1Form.get('protestDetails') as UntypedFormArray;
    let proBankChallanLocFrmArray = dtSubmmissionFrmArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (proBankChallanLocFrmArray.length > 0) {
      let length = proBankChallanLocFrmArray.length
      while (i < 5 - length) {
        proBankChallanLocFrmArray.push(this.createProBankChallan());
        i++;
      }
    }
    else {
      while (i < 5) {
        proBankChallanLocFrmArray.push(this.createProBankChallan());
        i++;
      }
    }
  }
  uploadProCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let protedtDtlsArray = this.adjP1Form.controls.protestDetails as UntypedFormGroup;
    let copyFormLocFrmArray = protedtDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.adjP1Form.controls.protestDetails as UntypedFormGroup;
    let copyFormFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadProBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {

    let protedtDtlsArray = this.adjP1Form.controls.protestDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = protedtDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.adjP1Form.controls.protestDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addLiabilityCopyOfForm(j: any, i: any) {
    let liabilityFrmArray = this.adjP1Form.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = liabilityFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
  }
  addLiabilityCopyOfProBankChalan(j: any, i: any) {
    let liabilityFrmArray = this.adjP1Form.get('liabilityDetails') as UntypedFormArray;
    let proBankChallanLocFrmArray = liabilityFrmArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (proBankChallanLocFrmArray.length > 0) {
      let length = proBankChallanLocFrmArray.length
      while (i < 5 - length) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
    else {
      while (i < 5) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
  }
  uploadLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.adjP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.adjP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.adjP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.adjP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  setIssuingAuthorityData() {
    this.adjP1Form
      .get('officer3')
      ?.setValue(
        this.adjP1Form.value.officer3
          ? this.adjP1Form.value.officer3
          : this.adjP1Form.value.officer
      );
    this.adjP1Form
      .get('jurisdiction3')
      ?.setValue(
        this.adjP1Form.value.jurisdiction3
          ? this.adjP1Form.value.jurisdiction3
          : this.adjP1Form.value.jurisdiction
      );
    this.adjP1Form
      .get('contact3')
      ?.setValue(
        this.adjP1Form.value.contact3
          ? this.adjP1Form.value.contact3
          : this.adjP1Form.value.contact
      );
    this.adjP1Form
      .get('designation3')
      ?.setValue(
        this.adjP1Form.value.designation3
          ? this.adjP1Form.value.designation3
          : this.adjP1Form.value.designation
      );
    this.adjP1Form
      .get('address3')
      ?.setValue(
        this.adjP1Form.value.address3
          ? this.adjP1Form.value.address3
          : this.adjP1Form.value.address
      );
    this.adjP1Form
      .get('email3')
      ?.setValue(
        this.adjP1Form.value.email3
          ? this.adjP1Form.value.email3
          : this.adjP1Form.value.email
      );
  }

  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
    // if (phase === ('phase' + i)) {

    // }
  }

  /* Form initialization */
  formInitialize() {
    this.adjP1Form = this.fBuild.group({
      caseId: [this.caseData?.caseId],
      auditNoticeRefNo: [this.caseData?.auditNoticeRefNo],
      // precededByNotice: [this.caseData?.precededByNotice, [Validators.required]],
      // gstin: [this.selectedGstin],    
      gstin: [this.caseData?.gstin ? this.caseData?.gstin : this.selectedGstin],

      gstinDiv: [this.selectedGstinDiv],
      state: [this.selectedStateName],
      prevOrderLoc: [null, this.caseData?.prevOrderLoc],
      scnNo: [this.caseData?.scnNo, [Validators.required]],
      din1: [this.caseData?.din1],
      dateOfScn: [this.caseData?.dateOfScn, [Validators.required]],
      dateOfReceiptOfScn: [
        this.caseData?.dateOfReceiptOfScn,
        [Validators.required],
      ],
      dueDateOfReply: [this.caseData?.dueDateOfReply, [Validators.required]],
      internalDueDate: [this.caseData?.internalDueDate],
      issuedUs: [this.caseData?.issuedUs],
      updatedBy: [this.caseData?.updatedBy],
      updatedOn: [this.caseData?.updatedOn],
      extPeriodOfLimitationInvoked: [
        this.caseData?.extPeriodOfLimitationInvoked,
      ],
      periodFromDate: [this.caseData?.periodFromDate, [Validators.required]],
      periodToDate: [this.caseData?.periodToDate, [Validators.required]],
      consultant1: [this.caseData?.consultant1],
      personResponsible1: [
        this.caseData?.personResponsible1,
        [Validators.required],
      ],
      issues: new UntypedFormArray([]),
      scnLoc: new UntypedFormArray([]), //
      attachmentsLoc: new UntypedFormArray([]),
      caseSummary: [this.caseData?.caseSummary],
      internalRemarks: [this.caseData?.internalRemarks],
      // dateOfSubmission: [this.caseData?.dateOfSubmission],//
      // consultant2: [this.caseData?.consultant2],//
      // personResponsible2: [this.caseData?.personResponsible2],//
      // copyOfAckLoc: [this.caseData?.copyOfAckLoc],//
      // otherAttachmentsLoc: [this.caseData?.otherAttachmentsLoc],
      phRecord: [this.caseData?.phRecord],
      oioNo: [this.caseData?.oioNo], //
      dateOfOio: [this.caseData?.dateOfOio], //
      dateOfReceiptOfOio: [this.caseData?.dateOfReceiptOfOio], //
      oioLoc: new UntypedFormArray([]), //
      outcome: [this.caseData?.outcome], //
      orderPassedBy: [this.caseData?.orderPassedBy], //
      whetherAppealPreferred: [this.caseData?.whetherAppealPreferred], //
      furtherAppealDecision: [this.caseData?.furtherAppealDecision], //
      writAppPreferred: [this.caseData?.writAppPreferred], //
      whetherDptAppealPreferred: [this.caseData?.whetherDptAppealPreferred],
      whetherCaseCallBook: [this.caseData?.whetherCaseCallBook],//
      // paymentChallanLoc: [this.caseData?.paymentChallanLoc],
      // drc03Tax: [this.caseData?.drc03Tax, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest: [this.caseData?.drc03Interest, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty: [this.caseData?.drc03Penalty, [Validators.pattern(/^[1-9]\d*$/)]],
      orderSummery: [this.caseData?.orderSummery],
      attachmentsLocFileName: [this.attachmentsLocFile],
      copyOfAckLocFname: [this.copyOfAckLocF],
      otherAttachmentsLocFname: [this.otherAttachmentsLocF],
      phCopyLocFname: [this.phCopyLocF],
      phRecordIfAnyLocFname: [this.phRecordIfAnyLocF],
      oioLocFname: [this.oioLocF],
      paymentChallanLocFname: [this.paymentChallanLocF],
      itemList: this.fBuild.array([]),
      igst1Total: [this.caseData?.igst1Total],
      cgst1Total: [this.caseData?.cgst1Total],
      sgst1Total: [this.caseData?.sgst1Total],
      cess1Total: [this.caseData?.cess1Total],
      interest1Total: [this.caseData?.interest1Total],
      penalty1Total: [this.caseData?.penalty1Total],
      totOfTotal1: [this.caseData?.totOfTotal1],
      igst3Total: [this.caseData?.igst3Total],
      cgst3Total: [this.caseData?.cgst3Total],
      sgst3Total: [this.caseData?.sgst3Total],
      cess3Total: [this.caseData?.cess3Total],
      interest3Total: [this.caseData?.interest3Total],
      penalty3Total: [this.caseData?.penalty3Total],
      totOfTotal3: [this.caseData?.totOfTotal3],
      status: [this.caseData?.status],
      remandedCaseId: [null],
      predeposit: [null],
      paymentUnderProtest: [null],
      typeOfAuthority1: [
        this.caseData?.typeOfAuthority1,
        [Validators.required],
      ],
      remandedBackForum: [this.caseData?.remandedBackForum],
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      extensionDays: [this.caseData?.extensionDays],
      isExtensionGranted: [this.caseData?.isExtensionGranted],
      caseLabel: [
        this.caseData?.caseLabel,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100),
        ],
      ],
      whetherWritPreferredHc: [false],
      officer: [this.caseData?.issuingAuth1?.officer],
      jurisdiction: [this.caseData?.issuingAuth1?.jurisdiction],
      contact: [this.caseData?.issuingAuth1?.contact],
      designation: [this.caseData?.issuingAuth1?.designation],
      address: [this.caseData?.issuingAuth1?.address],
      email: [
        this.caseData?.issuingAuth1?.email,
        [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)],
      ],
      protestDetails: new UntypedFormArray([]),
      submissions: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      typeOfAuthority3: [this.caseData?.typeOfAuthority3],
      din3: [this.caseData?.din3],
      orderReceiptMode3: [this.caseData?.orderReceiptMode3],
      forumOfAppeal: [this.caseData?.forumOfAppeal],
      liabilityDetails: new UntypedFormArray([]),
      officer3: [this.caseData?.issuingAuth3?.officer],
      jurisdiction3: [this.caseData?.issuingAuth3?.jurisdiction],
      contact3: [this.caseData?.issuingAuth3?.contact],
      designation3: [this.caseData?.issuingAuth3?.designation],
      address3: [this.caseData?.issuingAuth3?.address],
      // email3:[ this.caseData?.email3],
      email3: [
        this.caseData?.issuingAuth3?.email,
        [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)],
      ],
      remandedPaymentMade: new UntypedFormArray([]),
    });
    this.adjP1Form.controls['protestDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldProtestDetailsValues = this.adjP1Form.value['protestDetails'];
        this.setCummulativeCount(this.oldProtestDetailsValues, selectedValue, 'protestDetails');
      });

    // this.adjP1Form.controls['protestDetails'].valueChanges.subscribe(
    //   (selectedValue) => {
    //     this.oldProtestDetailsValues = this.adjP1Form.value['protestDetails'];
    //     this.setCummulativeCount(
    //       this.oldProtestDetailsValues,
    //       selectedValue,
    //       'protestDetails'
    //     );
    //   }
    // );

    this.adjP1Form.controls['consultantCharges'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldConsulatntValues = this.adjP1Form.value['consultantCharges'];
        this.setCummulativeCount(
          this.oldConsulatntValues,
          selectedValue,
          'consultantCharges'
        );
      }
    );

    this.adjP1Form.controls['liabilityDetails'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldLiabilityDetailValues =
          this.adjP1Form.value['liabilityDetails'];
        this.setCummulativeCount(
          this.oldLiabilityDetailValues,
          selectedValue,
          'liabilityDetails'
        );
      }
    );
  }
  getscnLocData(): UntypedFormGroup[] {
    return (this.adjP1Form.get('scnLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  get p1() {
    return this.adjP1Form.controls;
  }
  p1validation() {
    if (
      this.adjP1Form.value['scnLoc'] == null ||
      this.adjP1Form.value['scnLoc'] === ''
    ) {
      this.scnLocReq = true;
    } else {
      this.scnLocReq = false;
    }
    return !this.scnLocReq;
  }
  p2validation() {
    (this.adjP1Form.get('submissions') as UntypedFormArray).controls.forEach(
      (c: any) => {
        if (c.controls.refNo.value === '' || c.controls.refNo.value === null || c.controls.refNo.value === undefined
        ) {
          c.controls.refNo.setErrors({ required: true });
        } else {
          c.controls.refNo.setErrors(null);
        }
        if (c.controls.submissionDate.value === '' || c.controls.submissionDate.value === null || c.controls.submissionDate.value === undefined
        ) {
          c.controls.submissionDate.setErrors({ required: true });
        } else {
          c.controls.submissionDate.setErrors(null);
        }
      }
    );
    return true;
  }
  p3validation() {
    var issues1Array = this.adjP1Form.controls.issues as UntypedFormArray;
    if (issues1Array.controls.length > 0) {
      for (var i = 0; i < issues1Array.controls.length; i++) {
        var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        if (
          issues1Control.controls.status.value === null || issues1Control.controls.status.value === "null" ||
          issues1Control.controls.status.value === ''
        ) {
          issues1Control.controls.status.setErrors({ required: true });
        } else {
          issues1Control.controls.status.setErrors(null);
        }
      }
    }

    if (
      this.adjP1Form.value['oioNo'] === null ||
      this.adjP1Form.value['oioNo'] === ''
    ) {
      this.adjP1Form.controls['oioNo'].setErrors({ required: true });
    }
    if (
      this.adjP1Form.value['forumOfAppeal'] === null ||
      this.adjP1Form.value['forumOfAppeal'] === "null" ||
      this.adjP1Form.value['forumOfAppeal'] === undefined
    ) {
      this.adjP1Form.controls['forumOfAppeal'].setErrors({ required: true });
    }
    if (this.adjP1Form.value['dateOfOio'] === null) {
      this.adjP1Form.controls['dateOfOio'].setErrors({ required: true });
    }
    if (this.adjP1Form.value['dateOfReceiptOfOio'] === null) {
      this.adjP1Form.controls['dateOfReceiptOfOio'].setErrors({
        required: true,
      });
    }
    if (
      this.adjP1Form.value['outcome'] === null ||
      this.adjP1Form.value['outcome'] === 'null' ||
      this.adjP1Form.value['outcome'] === '' ||
      this.adjP1Form.value['outcome'] === undefined
    ) {
      this.adjP1Form.controls['outcome'].setErrors({ required: true });
    }


    if (
      this.adjP1Form.value['furtherAppealDecision'] === null ||
      this.adjP1Form.value['furtherAppealDecision'] === 'null' ||
      this.adjP1Form.value['furtherAppealDecision'] === '' ||
      this.adjP1Form.value['furtherAppealDecision'] === undefined
    ) {
      this.adjP1Form.controls['furtherAppealDecision'].setErrors({ required: true });
    }
    if (this.adjP1Form.value['orderPassedBy'] === null) {
      this.adjP1Form.controls['orderPassedBy'].setErrors({ required: true });
    }
    if (this.adjP1Form.value['typeOfAuthority3'] === null) {
      this.adjP1Form.controls['typeOfAuthority3'].setErrors({ required: true });
    }
    if (
      this.adjP1Form.value['oioLoc'] == null ||
      this.adjP1Form.value['oioLoc'] === ''
    ) {
      this.oioLocReq = true;
      //this.adjP1Form.controls['oioLoc'].setErrors({'required': true });
    } else {
      this.oioLocReq = false;
      //this.adjP1Form.controls['oioLoc'].setErrors(null);
    }
    return this.adjP1Form.valid;
  }

  liabilityValidation() {
    let liabilityArray = this.adjP1Form.controls.liabilityDetails as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
        if (
          issuesControl.controls.challanNo.value != null &&
          issuesControl.controls.challanNo.value
        ) {
          if (
            issuesControl.controls.copyOfFormLoc.value === null ||
            issuesControl.controls.copyOfFormLoc.value === ''
          ) {
            issuesControl.controls.copyOfFormLoc.setErrors({ required: true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }

  deleteAttachmentFile() {
    if (this.adjP1Form.get('attachmentsLoc')?.value) {
      this.adjP1Form.get('attachmentsLoc')?.setValue(null);
      this.caseData['attachmentsLoc'] = null;
      this.isattachmentsLocFile = false;
      this.isattachmentsLocText = true;
      this.isDWattachmentsLoc = true;
      this.isUPattachmentsLoc = true;
    }
  }

  deleteOioLoc() {
    if (this.adjP1Form.get('oioLoc')?.value) {
      this.adjP1Form.get('oioLoc')?.setValue(null);
      this.caseData['oioLoc'] = null;
      this.isoioLocFile = false;
      this.isoioLocText = true;
      this.isDWoioLoc = true;
      this.isUPoioLoc = true;
    }
  }
  phDivArr(): UntypedFormArray {
    return this.adjP1Form.get('itemList') as UntypedFormArray;
  }

  newphArray(item: any = null): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: ['', []],
      dateOfPhScheduled: ['', []],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: ['', []],
      mode: ['', []],
      phRefNo: ['', []],
      phTime: ['', []],
      consultant: [this.caseData?.consultant1, []],
      personResponsible: [this.caseData?.personResponsible1, []],
      dateOfsubmission: ['', []],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phDiscussionNotes: ['', []],
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phAdjournmentLetterLoc: new UntypedFormArray([]),
    });
  }

  deleteitemListFile() {
    var itemListArray = this.adjP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phRecordIfAnyLoc.setValue(null);
      }
    }
  }
  deletePHNoticeFile(index: any) {
    var itemListArray = this.adjP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.controls.phCopyLoc.setValue(null);
          if (this.caseItemList.length)
            this.caseItemList[index].phCopyLoc = null;
        }
      }
    }
  }
  deleteSubmissionFile() {
    var itemListArray = this.adjP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.additionalSubmissionLoc.setValue(null);
      }
    }
  }

  newRemandedPaymentMade(data: any): UntypedFormGroup {
    return this.fBuild.group({
      label: [data ? data?.label : ''],
      tax: [
        data ? data?.tax : 0,
        // [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
      interest: [
        data ? data?.interest : 0,
        // [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
      penalty: [
        data ? data?.penalty : 0,
        // [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
      total: [
        data ? data?.total : 0,
        // [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
    });
  }

  initializeRemandedBackPaymentData(data: any) {
    data.forEach((cumulative: any) => {
      let detailOfCumulative = this.newRemandedPaymentMade(cumulative);
      (this.adjP1Form.get('remandedPaymentMade') as UntypedFormArray).push(
        detailOfCumulative
      );
    });
  }

  getREmandedBackData() {
    return (this.adjP1Form.get('remandedPaymentMade') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  remandedBackPaymentValueChange() {
    (this.adjP1Form.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue({ total: (+c.value.tax) + (+c.value.interest) + (+c.value.penalty) }, { emitEvent: false, onlySelf: true });
      } else {
        c.patchValue({ total: +(c.value.total) })
      }
    });
  }


  isConsultantCharges(payment: any) {
    if (payment.value.label === 'Cumulative Consultant Charges') {
      return false;
    } else return true;
  }

  createAdjDiv() {
    this.phDivArr().push(this.newphArray());
  }
  removeLetter(i: number) {
    this.phDivArr().removeAt(i);
  }
  changeadjOrNot(event: any, i: any) {
    //let formVal = this.adjP1Form.value.itemList;
    if (event.target.value === 'true') {
      this.createAdjDiv();
    }
  }

  /*Issue P1 div itration */

  issuInvArr(): UntypedFormGroup[] {
    return (this.adjP1Form.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  newIssueInvArray(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      keyword: [null, []],
      riskParam: [null, [Validators.required]],
      tag: [null, []],
      periodFromDate: [null, []],
      periodToDate: [null, []],
      interestRate: [null, []],
      remark1: [null, [Validators.maxLength(500)]],
      igst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      details: new UntypedFormArray([]),
      status: [null, []],
    });
  }
  addIssueInv() {
    let issueFrmGrp = this.newIssueInvArray();
    (issueFrmGrp.get('details') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.adjP1Form.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number, isRemandedBack: boolean = false) {
    (this.adjP1Form.get('issues') as UntypedFormArray).removeAt(i);
    this.issueValueChange();
    if (!isRemandedBack) this.rmvIssueModalRef.close();
  }

  /*remove letter function*/
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp() {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(''),
      quarter: new UntypedFormControl(''),
      provisionAmt1: new UntypedFormControl(''),
      contLiabilityAmt1: new UntypedFormControl(''),
      otherLiability1: new UntypedFormControl(''),
      provisionChangeReason1: new UntypedFormControl(''),
      provisionNotMadeReason1: new UntypedFormControl(''),
      contLiabReason1: new UntypedFormControl(''),
      otherLiabilityReason1: new UntypedFormControl(''),
      considerInCaroReport1: new UntypedFormControl(''),
      caroAmt1: new UntypedFormControl(''),
    });
  }
  addDetail(j: any) {
    let issuesFrmArray = this.adjP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get(
      'details'
    ) as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.adjP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();
  }

  /*upload file selection */

  onFileSelect(event: any, type: any = null) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
          (currentDate.getMonth() + 1).toString().padStart(2, '0') +
          currentDate.getDate().toString().padStart(2, '0') + '_' +
          currentDate.getHours().toString().padStart(2, '0') +
          currentDate.getMinutes().toString().padStart(2, '0') +
          currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, type);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  // onUpload(selectedfield: any, type: any) {
  //   let urlData = {
  //     gstinOrPan: this.adjP1Form.controls.gstin.value,
  //     type: this.tabType[1].key,
  //     field: selectedfield,//this.selectedField,
  //     contentType: this.selectedFile.type
  //   }
  //   this.litService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
  //     if (response != null) {
  //       this.getFileUrl(response.response.url, response.response.path, selectedfield, type);
  //     } else {
  //     }
  //   })
  // }
  fileUrlData: any[] = [];
  filePathData: any[] = [];

  filePathDataArray: any[] = [];
  onUpload(selectedfield: any, type: any, letterIndex?: number) {
    let urlData = {
      gstinOrPan: this.adjP1Form.controls.gstin.value,
      type: this.tabType[1].key,
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.litService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrlData = response.response.url;
        this.filePathData = response.response.path;
        this.filePathDataArray.push(this.filePathData);
        this.getFileUrl(response.response.url, response.response.path, selectedfield, type);
        if (letterIndex !== undefined && letterIndex > -1) {
          this.ocrLetterMap[letterIndex] = {
            uploaded: true,
            processed: false,
            filePath: response.response.path,
            response: null
          }
        }
        console.log(this.uploadedFileUrlList);
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any, type: any = null) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (this.selectedFile && type === 'Submissions' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');

        (this.adjP1Form.get('submissions') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (
        this.selectedFile &&
        type === 'consultantCharges' &&
        this.matches
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.adjP1Form.get('consultantCharges') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (
        this.selectedFile &&
        type === 'liabilityDetails' &&
        this.matches
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.adjP1Form.get('liabilityDetails') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (
        this.selectedFile &&
        type === 'protestDetails' &&
        this.matches
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.adjP1Form.get('protestDetails') as UntypedFormArray).controls.forEach(
          (c: any, index) => {
            if (letterIndex == index) {
              c.patchValue(attrFieldNm[1], path);
              c.controls[attrFieldNm[1]].setValue(path);
            }
          }
        );
      } else if (this.selectedFile && this.p1.itemList.value && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.itemList.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path; //this.filePath;
              this.savecaseItemList();
            }
          }
        });
      }
      if (selectedfield) {
        var toupdate = this.adjP1Form.value;
        let pathToVeriabl = this.litService.commonCreatePath(selectedfield);

        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.adjP1Form.patchValue(this.adjP1Form.value);
      }
      else {
        let field = selectedfield; //this.selectedField;
        let adjForm = this.adjP1Form.value;
        adjForm[field] = path; //this.filePath;
        if (this.caseData == null) {
          this.caseData = {};
        }
        this.caseData[field] = path;
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    });
  }

  /*create phase 1 (submit function)*/
  createP1() {
    this.isSubmitted = true;
    let data: any = {};
    if (this.adjP1Form.valid) {
      data.issuingAuth3 = {
        officer: this.adjP1Form.value.officer3,
        designation: this.adjP1Form.value.designation3,
        jurisdiction: this.adjP1Form.value.jurisdiction3,
        address: this.adjP1Form.value.address3,
        contact: this.adjP1Form.value.contact3,
        email: this.adjP1Form.value.email3,
      };
      data.state = this.selectedStateName;
      this.calculateRemandBackTotal();
      data.remandedPaymentMade = this.adjP1Form.value.remandedPaymentMade;
      let dataScnLoc = (this.adjP1Form.get('scnLoc') as UntypedFormArray).controls;
      var count = dataScnLoc?.some((x) => x.get('fileLoc')?.value != null);
      if (!count) {
        this.toaster.showError('Required Fields Are Empty');
        return;
      }

      let protestArray = this.adjP1Form.controls.protestDetails as UntypedFormArray
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;

          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
          let tagControls = issuesControl.controls.tag.value;
          let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
          let challanNoControls = issuesControl.controls.challanNo.value ;
          //let hasissueName = issueNameControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty in Payment Table.");
            return;
          }
        }
       
      
      }
      let urlData = {
        typeVal: this.tabType[1].key,
        gstin: this.selectedGstin,
      };
      if (this.adjP1Form.controls.gstin.value !== this.selectedFilling.gstin) {
        this.toaster.showError("Please select appropriate state and try again");
        return
      }
      this.inrFormattingRemoving();
      Object.assign(data, { action: 'CREATE' }, { phase: '1' }, { keyword: 'keyword1' });
      let scnLocArray: any[] = [];
      let otherAttachLocArray: any[] = [];
      let proCopyFormArray: any[] = [];
      let proBankChallanArray: any[] = [];
      for (let i = 0; i < this.adjP1Form.controls.scnLoc.value.length; i++) {
        const element = this.adjP1Form.controls.scnLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          scnLocArray.push(element)
        }

      }
      for (let i = 0; i < this.adjP1Form.controls.attachmentsLoc.value.length; i++) {
        const element = this.adjP1Form.controls.attachmentsLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachLocArray.push(element)
        }

      }
      for (let i = 0; i < this.adjP1Form.controls.protestDetails.value.length; i++) {
        const copyFormArray = this.adjP1Form.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            proCopyFormArray.push(element)
          }
        }
        this.adjP1Form.controls.protestDetails.value[i].copyOfFormLoc = proCopyFormArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.protestDetails.value.length; i++) {
        const bankChallanArray = this.adjP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            proCopyFormArray.push(element)
          }
        }
        this.adjP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc = proBankChallanArray;
      }
      data = this.adjP1Form.value;
      data.issuingAuth1 = {
        officer: this.adjP1Form.value.officer,
        designation: this.adjP1Form.value.designation,
        jurisdiction: this.adjP1Form.value.jurisdiction,
        address: this.adjP1Form.value.address,
        contact: this.adjP1Form.value.contact,
        email: this.adjP1Form.value.email,
      };
      this.adjP1Form.value.scnLoc = scnLocArray;
      this.adjP1Form.value.attachmentsLoc = otherAttachLocArray
      // this.letterP1Form.value.dateOfLetter = this.datePipe.transform(this.letterP1Form.value.dateOfLetter, 'yyyy-MM-dd');
      this.litService.createAdjudicationCase(data).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {
          if (this.isDataSaved === true) {
            this.p1validation();
          }
          this.formatTableValues();
          this.toaster.showSuccess(response.message);
          this.savedCaseId = response.response;
          this.isDataSaved = true;
          this.isPhase2 = true;
          this.isCreateBtn = false;
          this.isUpdateBtn = true;
          this.saveCaseId.emit(this.savedCaseId);
          this.initializeData();
          this.hideOcrFiled = false;
          this.sidemenu = true;
        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
    console.log(this.adjP1Form.value)
    this.issueValueChange();
  }

  openProceedWithPreferredForumAppealPop(content: any) {
    this.proceedWithPreferredForumAppeal = this.modalService.open(content);
  }

  rmProceedWithPreferredForumAppeal(removeSelectedValue: any = false) {
    if (removeSelectedValue) this.adjP1Form.get('forumOfAppeal')?.setValue('');
    this.proceedWithPreferredForumAppeal.close();
  }

  getperiodToValue(content: any) {
    if (this.action === "create") {
      let model: any = {};
      model.page = 0,
        model.size = 10,
        model.sortfield = "createdOn",
        model.sortdir = "DESC",
        model.companyId = localStorage.getItem('selectedId'),
        model.module = "GST",
        model.gstin = this.selectedGstin,
        model.criterias = [
          {
            "p": "periodFromDate",
            "o": "between",
            "v1": this.adjP1Form.value.periodFromDate,
            "v2": this.adjP1Form.value.periodToDate,
          }, {
            "p": "periodToDate",
            "o": "between",
            "v1": this.adjP1Form.value.periodFromDate,
            "v2": this.adjP1Form.value.periodToDate,
          }
        ]
      this.litService.getDuplicateData(model).subscribe((response: any) => {
        if (response.response.length === 0) {
          this.modalService.dismissAll();
        }
        else {
          this.duplicateData = response?.response;
          this.duplicateData?.forEach((obj: any, index: number) => {
            if (obj.forum === 'Appellate Authority')
              this.duplicateData[index].forum = 'Commissioner (A)'
          })
          this.modalService.open(content)
        }

      });
    }
  }

  goToSummary() {
    this.router.navigate(['/litigationSummary']);
    this.modalService.dismissAll();
  }

  getForumAppealValue(value: any) {
    let [findValue] = this.forumAppeal.filter((forum) => {
      return forum.key === value;
    });
    if (findValue) return findValue.value;
    return;
  }

  rmWeatherApplErrorBorder() {
    this.someElement = document.getElementById('whetApplPref');
    this.someElement.classList.remove('errorBorder');
  }
  rmWeatherWritErrorBorder() {
    this.someElement = document.getElementById('whetWritPref');
    this.someElement.classList.remove('errorBorder');
  }
  checkwritAppYes(event: any = null) {
    let data = null;
    if (!event) {
      data = this.adjP1Form.value.writAppPreferred;
    } else {
      data = event.target.value;
    }
    if (data === 'true' || data === true) {
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.adjP1Form.get('whetherAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherAppealPreferred')?.setValue('false');
      this.adjP1Form.get('whetherDptAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherDptAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherDptAppealPreferred')?.setValue('false');
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    }
    this.setForumOfAppeal();
    this.adjP1Form.controls['forumOfAppeal'].setValue(
      this.caseData?.forumOfAppeal
    );
  }
  checkfurAppDeciYes(event: any = null) {
    let data = null;
    if (!event) {
      data = this.adjP1Form.value.furtherAppealDecision;
    } else {
      data = event.target.value;
    }
    if (data === 'true' || data === true) {
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.disabledWeatherWrit = true;
      this.adjP1Form.get('whetherAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherAppealPreferred')?.setValue('false');

      this.adjP1Form.get('whetherDptAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherDptAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherDptAppealPreferred')?.setValue('false');

      this.adjP1Form.get('writAppPreferred')?.clearValidators();
      this.adjP1Form.get('writAppPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('writAppPreferred')?.setValue('false');
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
      this.disabledWeatherWrit = false;
    }
    this.setForumOfAppeal();
    this.adjP1Form.controls['forumOfAppeal'].setValue(
      this.caseData?.forumOfAppeal
    );
  }
  changeOutcome(event: any = null) {
    let data;
    if (!event) {
      data = this.adjP1Form.value.outcome;
    } else {
      data = event.target.value;
    }
    if (data === 'Favorable' || data === 'Partly Favorable') {
      this.disabledWeatherWrit = true;
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = false;
      this.adjP1Form.get('whetherAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherAppealPreferred')?.setValue(false);
      this.adjP1Form.get('writAppPreferred')?.clearValidators();
      this.adjP1Form.get('writAppPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('writAppPreferred')?.setValue(false);
      this.adjP1Form.get('whetherDptAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherDptAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherDptAppealPreferred')?.setValue(true);
    } else if (data === 'Unfavorable' || data === 'Partly Unfavorable') {
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = true;
      this.adjP1Form.get('whetherAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherAppealPreferred')?.setValue(true);
      this.adjP1Form.get('writAppPreferred')?.clearValidators();
      this.adjP1Form.get('writAppPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('writAppPreferred')?.setValue(false);
      this.adjP1Form.get('whetherDptAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherDptAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherDptAppealPreferred')?.setValue(false);
    } else if (data === 'Remanded Back') {
      this.disabledWeatherWrit = true;
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
    } else {
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
    }
    this.changeIssueConclusionValue();
    this.setForumOfAppeal();
    this.adjP1Form.controls['forumOfAppeal'].setValue(
      this.caseData?.forumOfAppeal
    );
  }

  changeIssueConclusionValue() {
    var issues1Array = this.adjP1Form.controls.issues as UntypedFormArray;
    switch (this.adjP1Form.value?.outcome) {
      case 'Favorable':
        this.issueConclusion = FAVOURABLE_OPTION;
        if (issues1Array.controls.length > 0) {
          for (var i = 0; i < issues1Array.controls.length; i++) {
            var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
            issues1Control.controls.status.setValue('Favorable');
          }
        }
        break;
      case 'Unfavorable':
        this.issueConclusion = UNFAVOURABLE_OPTION;
        // if(issues1Array.controls.length > 0) {
        //   for(var i = 0; i < issues1Array.controls.length; i++){
        //       var issues1Control = issues1Array.controls[i] as FormGroup;
        //       issues1Control.controls.status.setValue('Unfavorable')
        //       // issues1Control.controls.status.setValue(issues1Control.controls.status.value ? issues1Control.controls.status.value :'');
        //     }
        // }
        break;

      default:
        this.issueConclusion = ISSUE_CONCLUSION;
        if (issues1Array.controls.length > 0) {
          for (var i = 0; i < issues1Array.controls.length; i++) {
            var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
            issues1Control.controls.status.setValue(
              issues1Control.controls.status.value
                ? issues1Control.controls.status.value
                : ''
            );
          }
        }
        break;
    }
  }

  checkAppYes(event: any = null) {
    let data;
    if (!event) {
      data = this.adjP1Form.value.whetherAppealPreferred;
    } else {
      data = event.target.value;
    }
    if (data == 'true') {
      this.disabledWeatherWrit = true;
      this.disabledWeatherDept = true;
      this.adjP1Form.get('writAppPreferred')?.clearValidators();
      this.adjP1Form.get('writAppPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('writAppPreferred')?.setValue('false');
      this.adjP1Form.get('whetherDptAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherDptAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherDptAppealPreferred')?.setValue('false');
      // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherWrit = false;
      this.disabledWeatherDept = false;
    }
    this.setForumOfAppeal();
    this.adjP1Form.controls['forumOfAppeal'].setValue(
      this.caseData?.forumOfAppeal
    );
  }

  checkDeptYes(event: any = null) {
    let data;
    if (!event) {
      data = this.adjP1Form.value.whetherDptAppealPreferred;
    } else {
      data = event.target.value;
    }
    if (data === 'true') {
      this.disabledWeatherAppl = true;
      this.disabledWeatherWrit = true;
      this.adjP1Form.get('writAppPreferred')?.clearValidators();
      this.adjP1Form.get('writAppPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('writAppPreferred')?.setValue('false');
      this.adjP1Form.get('whetherAppealPreferred')?.clearValidators();
      this.adjP1Form.get('whetherAppealPreferred')?.updateValueAndValidity();
      this.adjP1Form.get('whetherAppealPreferred')?.setValue('false');
      // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherWrit = false;
    }
    this.setForumOfAppeal();
    this.adjP1Form.controls['forumOfAppeal'].setValue(
      this.caseData?.forumOfAppeal
    );
  }
  /*get prefilled data (View)*/
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.caseData?.auditNoticeRefNo !== null) {
      this.hideAiOptionSub();
      this.hidePh3Ai();
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        type: this.tabType[1].key,
        // caseId: this.caseId,
        caseId: this.caseId,
      };
      this.litService.getAdjudicationData(model).subscribe((response: any) => {
        if (response.status === 1) {
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.caseData = response.response;
          this.getCompanyTags(this.caseData.gstin);
          this.selectedStateName = response.response.state;
          if (this.caseData?.consultantCharges) {
            this.caseData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees));
            });
          }
          if (this.caseData?.issues) {
            this.caseData.issues.forEach((issue: any) => {
              issue.igst1 = this.inrService.formatCurrency(String(issue.igst1));
              issue.cgst1 = this.inrService.formatCurrency(String(issue.cgst1));
              issue.sgst1 = this.inrService.formatCurrency(String(issue.sgst1));
              issue.cess1 = this.inrService.formatCurrency(String(issue.cess1));
              issue.interest1 = this.inrService.formatCurrency(
                String(issue.interest1)
              );
              issue.penalty1 = this.inrService.formatCurrency(
                String(issue.penalty1)
              );
              issue.total1 = this.inrService.formatCurrency(
                String(issue.total1)
              );
              issue.igst3 = this.inrService.formatCurrency(String(issue.igst3));
              issue.cgst3 = this.inrService.formatCurrency(String(issue.cgst3));
              issue.sgst3 = this.inrService.formatCurrency(String(issue.sgst3));
              issue.cess3 = this.inrService.formatCurrency(String(issue.cess3));
              issue.interest3 = this.inrService.formatCurrency(
                String(issue.interest3)
              );
              issue.penalty3 = this.inrService.formatCurrency(
                String(issue.penalty3)
              );
              issue.total3 = this.inrService.formatCurrency(
                String(issue.total3)
              );
              if (issue?.details) {
                issue.details.forEach((details: any) => {
                  details.provisionAmt1 = this.inrService.formatCurrency(
                    details.provisionAmt1
                  );
                  details.contLiabilityAmt1 = this.inrService.formatCurrency(
                    details.contLiabilityAmt1
                  );
                  details.otherLiability1 = this.inrService.formatCurrency(
                    details.otherLiability1
                  );
                  details.caroAmt1 = this.inrService.formatCurrency(
                    details.caroAmt1
                  );
                });
              }
            });
          }
          if (this.caseData?.protestDetails) {
            this.caseData.protestDetails.forEach((protest: any) => {
              protest.total = this.inrService.formatCurrency(
                String(protest.total)
              );
              protest.igst = this.inrService.formatCurrency(String(protest.igst));
              protest.cgst = this.inrService.formatCurrency(String(protest.cgst));
              protest.sgst = this.inrService.formatCurrency(String(protest.sgst));
              protest.cess = this.inrService.formatCurrency(String(protest.cess));

              protest.interest = this.inrService.formatCurrency(
                String(protest.interest)
              );
              protest.penalty = this.inrService.formatCurrency(
                String(protest.penalty)
              );
            });
          }
          if (this.caseData?.liabilityDetails) {
            this.caseData.liabilityDetails.forEach((liability: any) => {
              if (liability.markedForPayment === true) {
                this.markedForPaymentFlag = true;
              } else {
                this.markedForPaymentFlag = false;
              }
              liability.total = this.inrService.formatCurrency(
                String(liability.total)
              );
              liability.igst = this.inrService.formatCurrency(
                String(liability.igst)
              );
              liability.cgst = this.inrService.formatCurrency(String(liability.cgst))
              liability.sgst = this.inrService.formatCurrency(String(liability.sgst))
              liability.cess = this.inrService.formatCurrency(String(liability.cess))
              liability.interest = this.inrService.formatCurrency(
                String(liability.interest)
              );
              liability.penalty = this.inrService.formatCurrency(
                String(liability.penalty)
              );
            });
          }
          if (this.caseData?.remandedPaymentMade) {
            this.caseData.remandedPaymentMade.forEach((remanded: any) => {
              remanded.total = this.inrService.formatCurrency(
                String(remanded.total)
              );
              remanded.tax = this.inrService.formatCurrency(
                String(remanded.tax)
              );
              remanded.interest = this.inrService.formatCurrency(
                String(remanded.interest)
              );
              remanded.penalty = this.inrService.formatCurrency(
                String(remanded.penalty)
              );
            });
          }

          if (this.caseData.status === "MOVED") {
            this.callBookDisabled = true;
          }

          this.copyOfAckLocF = this.litService.getFileName(
            this.caseData.copyOfAckLoc
          );
          this.otherAttachmentsLocF = this.litService.getFileName(
            this.caseData.otherAttachmentsLoc
          );
          this.phCopyLocF = this.litService.getFileName(
            this.caseData.phCopyLoc
          );
          this.phRecordIfAnyLocF = this.litService.getFileName(
            this.caseData.phRecordIfAnyLoc
          );
          // this.oioLocF = this.litService.getFileName(this.caseData.oioLoc);
          this.paymentChallanLocF = this.litService.getFileName(
            this.caseData.paymentChallanLoc
          );
          if (this.caseData.prevStage === 'scn') {
            this.hideOcrFilePh2 = true;
            this.hideOcrFilePh3 = true;
          }

          this.formInitialize();
          this.whetherCallBookChanged();
          this.remandedPaymentMade = this.caseData.remandedPaymentMade;
          if (this.caseData.itemList && this.caseData.itemList.length > 0) {
            this.initializeItemList();
          } else {
            this.initializeItemList();
            var list: UntypedFormGroup = this.newphArray();
            this.phDivArr().push(list);
          }

          if (
            this.caseData.consultantCharges &&
            this.caseData.consultantCharges.length > 0
          ) {
            this.initializeConsultantCharges();
          } else {
            this.addConsultantInv();
          }
          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList(this.caseData);
          } else {
            this.addIssueInv();
          }
          if (
            this.caseData.liabilityDetails &&
            this.caseData.liabilityDetails.length > 0
          ) {
            this.initializeliabilityDetailsAndPaid();
            this.liabilityTotalCalculation();
          } else {
            this.addLiabilityInv(null);
          }
          if (this.caseData.whetherAppealPreferred == true) {
            this.disabledWeatherWrit = true;
            this.disabledWeatherDept = true;
          } else if (this.caseData.whetherDptAppealPreferred == true) {
            this.disabledWeatherAppl = true;
            this.disabledWeatherWrit = true;
          } else if (this.caseData.writAppPreferred == true) {
            this.disabledWeatherDept = true;
            this.disabledWeatherAppl = true;
          }

          if (
            this.caseData.protestDetails &&
            this.caseData.protestDetails.length > 0
          ) {
            this.initializeProtestDetails();
          } else {
            this.addDetailOfPayment();
          }
          if (
            this.caseData.submissions &&
            this.caseData.submissions.length > 0
          ) {
            this.initializeDetailOfSubmission();
          } else {
            this.addSubmissionDetailsArr();
          }
          if (this.caseData.scnLoc && this.caseData.scnLoc.length > 0) {
            this.initializeScnCopy();
          } else {
            this.addScnLoc();
          }
          if (this.caseData.attachmentsLoc && this.caseData.attachmentsLoc.length > 0) {
            this.initializeOtherAttachmentsCopy();
          } else {
            this.addOtherAttachment();
          }
          if (this.caseData.oioLoc && this.caseData.oioLoc.length > 0) {
            this.initializeOioCopy();
          } else {
            this.addOioLoc();
          }
          if (this.caseData.remandedCaseId) {
            if (this.selectedRemandedBackCaseDetail) {
              this.selectedRemandedBackCaseDetail.cumulative =
                this.caseData.cumulative;
            } else {
              this.selectedRemandedBackCaseDetail = {
                cumulative: this.caseData.cumulative,
              };
            }
            this.initializeRemandedBackPaymentData(this.caseData.remandedPaymentMade ? this.caseData.remandedPaymentMade : this.caseData.cumulative);
          }
          this.isUpdate = true;
          //this.isadjorNot = true;
          this.cumulativeCount = this.caseData.cumulative
            ? this.caseData.cumulative
            : this.cumulativeCount;
          this.prevStage = this.caseData.prevStage;
        }
        this.paymentUnderProtestp1Changed();
        this.setCummulativeCount();
        this.checkDeptYes();
        this.checkAppYes();
        this.checkwritAppYes();
        // this.changeOutcome();
        this.changeIssueConclusionValue();
        this.issueValueChange();
        // this.setForumOfAppeal();
        this.adjP1Form.controls['forumOfAppeal'].setValue(
          this.caseData?.forumOfAppeal
        );
      });
    }
  }
  initializeItemList() {
    if (this.caseData.itemList) {
      if (this.caseData.itemList) {
        this.caseData.itemList.forEach((items: any) => {
          let itemListFrmGrp = this.newphArray(items);
          (this.adjP1Form.get('itemList') as UntypedFormArray).push(itemListFrmGrp);
          if (items.phCopyLoc) {
            items.phCopyLoc.forEach((not: any) => {
              let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
              (itemListFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
            });
          }

          if (items.phRecordIfAnyLoc) {
            items.phRecordIfAnyLoc.forEach((not: any) => {
              let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
              (itemListFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(phRecordAnyGrp);
            });
          }

          if (items.phAdjournmentLetterLoc) {
            items.phAdjournmentLetterLoc.forEach((not: any) => {
              let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
              (itemListFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
            });
          }

          if (items.additionalSubmissionLoc) {
            items.additionalSubmissionLoc.forEach((not: any) => {
              let additionalSubmissionFrmGrp: UntypedFormGroup = this.createAdditionalSubLoc(not);
              (itemListFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(additionalSubmissionFrmGrp);
            });
          }

        });
        this.adjP1Form.patchValue(this.caseData);
      }
    }
  }
  initializeIssueList(data: any, isRemandedBackData: any = false) {
    if (isRemandedBackData) {
      data['issues'] = data.gstIssues;
    }
    if (data.issues) {
      data.issues.forEach((issue: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.newIssueInvArray();
        (this.adjP1Form.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        if (issue.details) {
          issue.details.forEach((detail: any) => {
            let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp();
            (issuesFrmGrp.get('details') as UntypedFormArray).push(detailFrmGrp);
          });
        }
      });
      this.adjP1Form.controls['officer'].setValue(data.issuingAuth1?.officer);
      this.adjP1Form.controls['designation'].setValue(data.issuingAuth1?.designation);
      this.adjP1Form.controls['jurisdiction'].setValue(data.issuingAuth1?.jurisdiction);
      this.adjP1Form.controls['address'].setValue(data.issuingAuth1?.address);
      this.adjP1Form.controls['contact'].setValue(data.issuingAuth1?.contact);
      this.adjP1Form.controls['email'].setValue(data.issuingAuth1?.email);
      this.adjP1Form.patchValue(data);
    }
    this.adjP1Form.patchValue(this.caseData);
    this.issueValueChange();
  }

  /* check form validation */
  chkFrmValidated() {
    if (this.adjP1Form.valid) {
      this.isPhase2 = true;
      this.isPhase3 = true;
    }
  }
  initializeScnCopy() {
    if (this.caseData.scnLoc) {

      this.caseData.scnLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createScnLoc(not);

        (this.adjP1Form.get('scnLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.adjP1Form.patchValue(this.caseData);
    }
  }
  initializeOtherAttachmentsCopy() {
    if (this.caseData.attachmentsLoc) {
      this.attachmentsLength = this.caseData.attachmentsLoc.length;
      this.caseData.attachmentsLoc.forEach((not: any) => {

        let otherAttachCopyFrmGrp = this.createOtherAttachment(not);

        (this.adjP1Form.get('attachmentsLoc') as UntypedFormArray).push(otherAttachCopyFrmGrp);

      })
      this.adjP1Form.patchValue(this.caseData);
    }
  }
  initializeOioCopy() {
    if (this.caseData.oioLoc) {
      this.caseData.oioLoc.forEach((not: any) => {

        let oioLocFrmGrp = this.createOioLoc(not);

        (this.adjP1Form.get('oioLoc') as UntypedFormArray).push(oioLocFrmGrp);

      })
      this.adjP1Form.patchValue(this.caseData);
    }
    this.hidePh3Ai();
  }

  inrFormattingRemoving() {
    const data = this.adjP1Form.value;
    //phase 2 form
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
      });
    }
    //phase 1 form
    if (data.protestDetails && data.protestDetails.length) {
      data.protestDetails.forEach((protestDetail: any) => {
        protestDetail.igst = this.inrService.removeCommasAndParseNumber(
          protestDetail.igst
        );
        protestDetail.cgst = this.inrService.removeCommasAndParseNumber(protestDetail.cgst)
        protestDetail.sgst = this.inrService.removeCommasAndParseNumber(protestDetail.sgst)
        protestDetail.cess = this.inrService.removeCommasAndParseNumber(protestDetail.cess)
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(
          protestDetail.interest
        );
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(
          protestDetail.penalty
        );
        protestDetail.total = this.inrService.removeCommasAndParseNumber(
          protestDetail.total
        );
      });
    }
    //phase 3 form
    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(
          liability.igst
        );
        liability.cgst = this.inrService.removeCommasAndParseNumber(liability.cgst)
        liability.sgst = this.inrService.removeCommasAndParseNumber(liability.sgst)
        liability.cess = this.inrService.removeCommasAndParseNumber(liability.cess)
        liability.interest = this.inrService.removeCommasAndParseNumber(
          liability.interest
        );
        liability.penalty = this.inrService.removeCommasAndParseNumber(
          liability.penalty
        );
        liability.total = this.inrService.removeCommasAndParseNumber(
          liability.total
        );
      });
    }
    //phase 1 and 3 form
    if (data.issues && data.issues.length) {
      data.issues.forEach((issue: any) => {
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1);
        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1);
        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1);
        issue.interest1 = this.inrService.removeCommasAndParseNumber(
          issue.interest1
        );
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(
          issue.penalty1
        );
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3);
        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3);
        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3);
        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3);
        issue.interest3 = this.inrService.removeCommasAndParseNumber(
          issue.interest3
        );
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(
          issue.penalty3
        );
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3);
        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(
              details.provisionAmt1
            );
            details.contLiabilityAmt1 =
              this.inrService.removeCommasAndParseNumber(
                details.contLiabilityAmt1
              );
            details.otherLiability1 =
              this.inrService.removeCommasAndParseNumber(
                details.otherLiability1
              );
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(
              details.caroAmt1
            );
          });
        }
      });
    }
    // //phase 1 and 3 form
    data.igst1Total = this.inrService.removeCommasAndParseNumber(
      data.igst1Total);
    data.cgst1Total = this.inrService.removeCommasAndParseNumber(
      data.cgst1Total
    );
    data.sgst1Total = this.inrService.removeCommasAndParseNumber(
      data.sgst1Total
    );
    data.cess1Total = this.inrService.removeCommasAndParseNumber(
      data.cess1Total
    );
    data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total);
    data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total);
    data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1);
    data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total);
    data.cgst3Total = this.inrService.removeCommasAndParseNumber(data.cgst3Total);
    data.sgst3Total = this.inrService.removeCommasAndParseNumber(data.sgst3Total);
    data.cess3Total = this.inrService.removeCommasAndParseNumber(data.cess3Total);
    data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total);
    data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total);
    data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3);
  }
  updateIssues() {
    (this.adjP1Form.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ igst3: c.value.igst1 });
      c.patchValue({ cgst3: c.value.cgst1 });
      c.patchValue({ sgst3: c.value.sgst1 });
      c.patchValue({ cess3: c.value.cess1 });
      c.patchValue({ interest3: c.value.interest1 });
      c.patchValue({ penalty3: c.value.penalty1 });
      c.patchValue({ total3: c.value.total1 });
    });
  }
  UpdateModelData() {
    if (this.caseData != null) {
      this.adjP1Form.value.scnLoc = this.caseData['scnLoc'];
      this.adjP1Form.value.attachmentsLoc = this.caseData['attachmentsLoc'];
      this.adjP1Form.value.copyOfAckLoc = this.caseData['copyOfAckLoc'];
      if (this.caseData.otherAttachmentsLoc != null) {
        this.adjP1Form.value.otherAttachmentsLoc =
          this.caseData['otherAttachmentsLoc'];
      } else {
        this.adjP1Form.value.otherAttachmentsLoc = null;
      }
      if (this.caseData.oioLoc != null) {
        this.adjP1Form.value.oioLoc = this.caseData['oioLoc'];
      } else {
        this.adjP1Form.value.oioLoc = null;
      }
      if (this.caseData.paymentChallanLoc != null) {
        this.adjP1Form.value.paymentChallanLoc =
          this.caseData['paymentChallanLoc'];
      } else {
        this.adjP1Form.value.paymentChallanLoc = null;
      }

      // var itemListArray = this.adjP1Form.controls.itemList as FormArray
    }
  }

  doValidation(phaseTab: any) {
    this.UpdateModelData();
    if (phaseTab === 'p2') {
      this.consultantValidation();
      return this.p2validation();
    } else if (phaseTab === 'p3') {
      this.liabilityValidation();
      return this.p3validation();
    } else {
      return true;
    }
  }
  /*Update function*/
  updateLetter(phaseTab: any) {
    console.log(this.adjP1Form.value);
    let allLetterNotUpdated = false;
    this.issueValueChange();

    if (!this.adjP1Form.value.scnLoc) {
      allLetterNotUpdated = true;
    }
    this.setCaseItemList();
    this.isSubmitted = true;

    if (phaseTab === 'p1') {
      this.updateIssues();
      let protestArray = this.adjP1Form.controls.protestDetails as UntypedFormArray
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;
         
          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
          let tagControls = issuesControl.controls.tag.value;
          let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;
          let challanNoControls = issuesControl.controls.challanNo.value ;
          //let hasissueName = issueNam eControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty in Payment Table.");
            return;
          }
        }      
      
       
      }
    } else {
      this.p2validation();
    }
    let data: any = {};
    let isTabValid = this.doValidation(phaseTab);
    if (this.adjP1Form.valid && isTabValid) {
      if (phaseTab === 'p2') {
        let submissionsFrmArray = (this.adjP1Form.get('submissions') as UntypedFormArray).controls;
        let element1;
        for (let i = 0; i < submissionsFrmArray.length; i++) {
          element1 = (submissionsFrmArray[i].get('copyOfAckLoc') as UntypedFormArray).controls;
        }
        let count = element1?.some((x: any) =>
          x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }

      if (phaseTab === 'p3') {
        let dataOioLoc = (this.adjP1Form.get('oioLoc') as UntypedFormArray).controls;
        var count = dataOioLoc?.some((x) => x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
        let alArray = this.adjP1Form.controls.liabilityDetails as UntypedFormArray
      for (let index = 0; index < alArray.controls.length; index++) {
        let alControl = alArray.controls[index] as UntypedFormGroup;

        if (alControl.controls.challanNo.value || alControl.controls.total.value) {
          let copyOfFormLocControls = (alControl.get('copyOfFormLoc') as UntypedFormArray).controls;
         
          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = alControl.controls.issueName.value;
          let tagControls = alControl.controls.tag.value;
          let dateOfPaymentControls = alControl.controls.dateOfPayment.value ;
          let challanNoControls = alControl.controls.challanNo.value ;
          //let hasissueName = issueNam eControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty in Payment Table.");
            return;
          }
        }      
       
       
      }
      }
      data.cumulative = this.cumulativeCount;
      data.prevStage = this.caseData.prevStage;
      this.calculateRemandBackTotal();
      // this.inrFormattingRemoving();

      data.remandedPaymentMade = this.adjP1Form.value.remandedPaymentMade;






      data.state = this.selectedStateName;
      if (this.savedCaseId) {
        data.caseId = this.savedCaseId;
      }
      if (this.navContext.entityType == 'FILING') {
        if (this.adjP1Form.controls.gstin.value !== this.selectedFilling.gstin) {
          this.toaster.showError("Please select appropriate state and try again");
          return
        }
      }
      let urlData = {
        typeVal: this.tabType[1].key,
        gstin: this.selectedGstin,
      };
      Object.assign(data, { action: 'UPDATE' }, { phase: '1' }, { keyword: 'keyword1' })
      if (data.issues && data.issues.length) {
        data.issues.forEach((issue: any) => {
          issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
          issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1);
          issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1);
          issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1);
          issue.interest1 = this.inrService.removeCommasAndParseNumber(
            issue.interest1
          );
          issue.penalty1 = this.inrService.removeCommasAndParseNumber(
            issue.penalty1
          );
          issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);
          issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3);
          issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3);
          issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3);
          issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3);
          issue.interest3 = this.inrService.removeCommasAndParseNumber(
            issue.interest3
          );
          issue.penalty3 = this.inrService.removeCommasAndParseNumber(
            issue.penalty3
          );
          issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3);
          if (issue.details && issue.details.length) {
            issue.details.forEach((details: any) => {
              details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(
                details.provisionAmt1
              );
              details.contLiabilityAmt1 =
                this.inrService.removeCommasAndParseNumber(
                  details.contLiabilityAmt1
                );
              details.otherLiability1 =
                this.inrService.removeCommasAndParseNumber(
                  details.otherLiability1
                );
              details.caroAmt1 = this.inrService.removeCommasAndParseNumber(
                details.caroAmt1
              );
            });
          }
        });
      }
      // //phase 1 and 3 form
      data.igst1Total = this.inrService.removeCommasAndParseNumber(
        data.igst1Total);
      data.cgst1Total = this.inrService.removeCommasAndParseNumber(
        data.cgst1Total
      );
      data.sgst1Total = this.inrService.removeCommasAndParseNumber(
        data.sgst1Total
      );
      data.cess1Total = this.inrService.removeCommasAndParseNumber(
        data.cess1Total
      );
      data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total);
      data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total);
      data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1);
      data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total);
      data.cgst3Total = this.inrService.removeCommasAndParseNumber(data.cgst3Total);
      data.sgst3Total = this.inrService.removeCommasAndParseNumber(data.sgst3Total);
      data.cess3Total = this.inrService.removeCommasAndParseNumber(data.cess3Total);
      data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total);
      data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total);
      data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3);

      if (data.remandedPaymentMade) {
        data.remandedPaymentMade.forEach((remanded: any) => {
          remanded.tax = this.inrService.removeCommasAndParseNumber(
            remanded.tax
          );
          remanded.interest = this.inrService.removeCommasAndParseNumber(
            remanded.interest
          );
          remanded.penalty = this.inrService.removeCommasAndParseNumber(
            remanded.penalty
          );
          remanded.total = this.inrService.removeCommasAndParseNumber(
            remanded.total
          );
        });
      }

      let scnLocArray: any[] = [];
      let otherAttachLocArray: any[] = [];
      let phCopyLocArray: any[] = [];
      let additionalSubmissionLocArray: any[] = [];
      let phRecordIfAnyLocArray: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];
      let copyOfAckLocArray: any[] = [];
      let SubOtherAttachmentsLocArray: any[] = [];
      let consultOtherAttachmentLocArray: any[] = [];
      let oioLocArray: any[] = [];
      let proCopyFormArray: any[] = [];
      let proBankChallanArray: any[] = [];
      let liabilityCopyFormArray: any[] = [];
      let liabilityBankChallanArray: any[] = [];
      for (let i = 0; i < this.adjP1Form.controls.scnLoc.value.length; i++) {

        const element = this.adjP1Form.controls.scnLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          scnLocArray.push(element)
        }

      }
      for (let i = 0; i < this.adjP1Form.controls.attachmentsLoc.value.length; i++) {
        const element = this.adjP1Form.controls.attachmentsLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachLocArray.push(element)
        }

      }
      for (let i = 0; i < this.adjP1Form.controls.itemList.value.length; i++) {
        phCopyLocArray = [];
        const elementPhCopyArray = this.adjP1Form.controls.itemList.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element)
          }
        }
        this.adjP1Form.controls.itemList.value[i].phCopyLoc = phCopyLocArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.itemList.value.length; i++) {
        additionalSubmissionLocArray = [];
        const elementAddSubArray = this.adjP1Form.controls.itemList.value[i].additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element)
          }
        }
        this.adjP1Form.controls.itemList.value[i].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.itemList.value.length; i++) {
        phRecordIfAnyLocArray = [];
        const phRecordArray = this.adjP1Form.controls.itemList.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element)
          }
        }
        this.adjP1Form.controls.itemList.value[i].phRecordIfAnyLoc = phRecordIfAnyLocArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.itemList.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.adjP1Form.controls.itemList.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.adjP1Form.controls.itemList.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.submissions.value.length; i++) {
        copyOfAckLocArray = [];
        const copyOfAckArray = this.adjP1Form.controls.submissions.value[i].copyOfAckLoc;

        for (let j = 0; j < copyOfAckArray.length; j++) {
          const element = copyOfAckArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            copyOfAckLocArray.push(element)
          }
        }
        this.adjP1Form.controls.submissions.value[i].copyOfAckLoc = copyOfAckLocArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.submissions.value.length; i++) {
        SubOtherAttachmentsLocArray = [];
        const otherAttachesArray = this.adjP1Form.controls.submissions.value[i].otherAttachmentsLoc;

        for (let j = 0; j < otherAttachesArray.length; j++) {
          const element = otherAttachesArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            SubOtherAttachmentsLocArray.push(element)
          }
        }
        this.adjP1Form.controls.submissions.value[i].otherAttachmentsLoc = SubOtherAttachmentsLocArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.consultantCharges.value.length; i++) {
        consultOtherAttachmentLocArray = [];
        const otherAttachesConsultArray = this.adjP1Form.controls.consultantCharges.value[i].otherAttachmentLoc;

        for (let j = 0; j < otherAttachesConsultArray.length; j++) {
          const element = otherAttachesConsultArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultOtherAttachmentLocArray.push(element)
          }
        }
        this.adjP1Form.controls.consultantCharges.value[i].otherAttachmentLoc = consultOtherAttachmentLocArray;
      }

      for (let i = 0; i < this.adjP1Form.controls.oioLoc.value.length; i++) {
        const element = this.adjP1Form.controls.oioLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          oioLocArray.push(element)
        }

      }


      for (let i = 0; i < this.adjP1Form.controls.protestDetails.value.length; i++) {
        proCopyFormArray = [];
        const copyFormArray = this.adjP1Form.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            proCopyFormArray.push(element)
          }
        }
        this.adjP1Form.controls.protestDetails.value[i].copyOfFormLoc = proCopyFormArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.protestDetails.value.length; i++) {
        proBankChallanArray = [];
        const bankChallanArray = this.adjP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            proBankChallanArray.push(element)
          }
        }
        this.adjP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc = proBankChallanArray;
      }

      for (let i = 0; i < this.adjP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityCopyFormArray = [];
        const liabilitycopyFormArray = this.adjP1Form.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < liabilitycopyFormArray.length; j++) {
          const element = liabilitycopyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityCopyFormArray.push(element)
          }
        }
        this.adjP1Form.controls.liabilityDetails.value[i].copyOfFormLoc = liabilityCopyFormArray;
      }
      for (let i = 0; i < this.adjP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityBankChallanArray = [];
        const bankChallanArray = this.adjP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityBankChallanArray.push(element)
          }
        }
        this.adjP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc = liabilityBankChallanArray;
      }
      this.inrFormattingRemoving();
      this.adjP1Form.value.scnLoc = scnLocArray;
      this.adjP1Form.value.attachmentsLoc = otherAttachLocArray;
      this.adjP1Form.value.oioLoc = oioLocArray;
      data = this.adjP1Form.value;
      if (this.caseData?.issues !== undefined) {
        if (this.caseData?.issues[0]?.refId !== null) {
          for (let i = 0; i < data.issues.length; i++) {
            const responseIssues = this.caseData.issues[i];

            if (responseIssues && responseIssues.refId) {
              data.issues[i].refId = responseIssues.refId;
            }
          }
        }
      }
      if (this.caseData?.nmFy) {
        data.noticeCaseId = this.caseData.noticeCaseId;
        data.nmFy = this.caseData.nmFy;
      }


      data.issuingAuth1 = {
        officer: this.adjP1Form.value.officer,
        designation: this.adjP1Form.value.designation,
        jurisdiction: this.adjP1Form.value.jurisdiction,
        address: this.adjP1Form.value.address,
        contact: this.adjP1Form.value.contact,
        email: this.adjP1Form.value.email,
      };
      data.issuingAuth3 = {
        officer: this.adjP1Form.value.officer3,
        designation: this.adjP1Form.value.designation3,
        jurisdiction: this.adjP1Form.value.jurisdiction3,
        address: this.adjP1Form.value.address3,
        contact: this.adjP1Form.value.contact3,
        email: this.adjP1Form.value.email3,
      };

      this.litService.updateAdjudicationCase(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (this.isDataSaved === true) {
            //this.p1validation();
          }
          if (phaseTab === 'p1') {
            if (
              this.adjP1Form.value.whetherWritPreferredHc === true ||
              this.adjP1Form.value.whetherWritPreferredHc === 'true'
            ) {
              this.moveToHC.emit('');
            } else {
              this.isPhase2 = true;
            }
          } else if (phaseTab === 'p2') {
            this.isPhase3 = true;
          } else if (phaseTab === 'p3') {
            if (this.adjP1Form.value.forumOfAppeal === 'Appeal/Writ') {
              this.router.navigate(['/litigationSummary']);
            } else if (
              this.adjP1Form.value.forumOfAppeal === 'apt' &&
              (this.adjP1Form.value.whetherAppealPreferred === true ||
                this.adjP1Form.value.whetherAppealPreferred === 'true' ||
                this.adjP1Form.value.whetherDptAppealPreferred === true ||
                this.adjP1Form.value.whetherDptAppealPreferred === 'true')
            ) {
              this.moveToAT.emit('');
            } else if (
              this.adjP1Form.value.forumOfAppeal === 'apa' &&
              (this.adjP1Form.value.whetherAppealPreferred === true ||
                this.adjP1Form.value.whetherAppealPreferred === 'true' ||
                this.adjP1Form.value.whetherDptAppealPreferred === true ||
                this.adjP1Form.value.whetherDptAppealPreferred === 'true')
            ) {
              this.litService.setAccessTabData({
                scnFlag: true,
                ajaFlag: true,
                apaFlag: false,
                aptFlag: false,
                hcFlag: false,
                scFlag: false,
              });
              this.callTabFunction.emit('');
            } else if (
              (this.adjP1Form.value.writAppPreferred === true ||
                this.adjP1Form.value.writAppPreferred === 'true') &&
              this.adjP1Form.value.forumOfAppeal === 'hc' &&
              (this.adjP1Form.value.outcome === 'Unfavorable' ||
                this.adjP1Form.value.outcome === 'Partly favorable')
            ) {
              // this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: false, aptFlag: false, hcFlag: false, scFlag: false });
              this.moveToHC.emit('');
            } else if (
              (this.adjP1Form.value.writAppPreferred === true ||
                this.adjP1Form.value.writAppPreferred === 'true') &&
              this.adjP1Form.value.forumOfAppeal === 'sc' &&
              (this.adjP1Form.value.outcome === 'Unfavorable' ||
                this.adjP1Form.value.outcome === 'Partly favorable')
            ) {
              this.moveToSC.emit('');
            }
            else {
              this.router.navigate(['/litigationSummary']);
            }
          }
          this.isDataSaved = true;
          if (phaseTab === 'p2') {
            this.hideOcrFilePh2 = false;
            this.sidemenu = true;
            this.filePathDataArray = [];
            this.uploadedFileUrlList = [];
          } else if (phaseTab === 'p3') {
            this.hideOcrFilePh3 = false;
            this.sidemenu = true;
            this.filePathDataArray = [];
            this.uploadedFileUrlList = [];
          }
          this.toaster.showSuccess(response.message);
          if (this.isDataSaved === true) {
            //this.p1validation();
          }
        } else {
          this.toaster.showError(response.message);
        }

      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof fileUrl == 'string')
      return this.litService.getFileName(fileUrl);
  }
  setDate() {
    this.currentYear = new Date().getFullYear() + 1;
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = startYear + '-' + (startYear + 1);
      this.finyearData.push({ year: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl,
    };
    this.litService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    });
  }

  uploadscnLoc() {
    this.isscnLocFile = true;
    this.isscnLocText = false;
    this.isDWscnLoc = false;
    this.isUPscnLoc = false;
  }

  uploadattachmentsLoc() {
    this.isattachmentsLocFile = true;
    this.isattachmentsLocText = false;
    this.isDWattachmentsLoc = false;
    this.isUPattachmentsLoc = false;
  }

  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;
  }

  uploadotherAttachmentsLoc() {
    this.isotherAttachmentsLocFile = true;
    this.isotherAttachmentsLocText = false;
    this.isDWotherAttachmentsLoc = false;
    this.isUPotherAttachmentsLoc = false;
  }

  uploadphCopyLoc(index: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;
    var itemListArray = this.adjP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhCopyLocUploaded'] = true;
        }
      }
    }
  }

  uploadadditionalSubmissionLoc(index: any) {
    this.isUadditionalSubmissionLocFile = true;
    this.isadditionalSubmissionLocText = false;
    this.isDWadditionalSubmissionLoc = false;
    this.isUPadditionalSubmissionLoc = false;
    var itemListArray = this.adjP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isAdditionalSubmissionLocUploaded'] = true;
        }
      }
    }
  }

  uploadphRecordIfAnyLoc(index: any) {
    this.isphRecordIfAnyLocFile = true;
    this.isphRecordIfAnyLocText = false;
    this.isDWphRecordIfAnyLoc = false;
    this.isUPphRecordIfAnyLoc = false;
    var itemListArray = this.adjP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhRecordIfAnyLocUploaded'] = true;
        }
      }
    }
  }

  uploadoioLoc() {
    this.isoioLocFile = true;
    this.isoioLocText = false;
    this.isDWoioLoc = false;
    this.isUPoioLoc = false;
  }

  uploadpaymentChallanLoc() {
    this.ispaymentChallanLocFile = true;
    this.ispaymentChallanLocText = false;
    this.isDWpaymentChallanLoc = false;
    this.isUPpaymentChallanLoc = false;
  }

  deleteUploadNoticeFile() {
    if (this.adjP1Form.get('scnLoc')?.value) {
      this.adjP1Form.get('scnLoc')?.setValue(null);
      this.caseData['scnLoc'] = null;
      this.isscnLocFile = false;
      this.isscnLocText = true;
      this.isDWscnLoc = true;
      this.isUPscnLoc = true;
    }
  }

  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
      this.selectedStateName = this.commonServ.getStateName(this.selectedStateCode);
    }
  }
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  consultantData() {
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;

        const userMailID: any = sessionStorage.getItem('UserId');
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );
        if (matchingConsultant) {
          this.adjP1Form.controls.consultant1.patchValue(matchingConsultant.consultantName)
        }
      }
    });
  }

  onClickToggleConsultantDropdown(phase: string) {
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC') {
      this.isToggleConsultantDropdown3 = !this.isToggleConsultantDropdown3;
    }
  }
  onClickToggleSegmentDropdown(phase: string) {
    if (phase === 'p1') {
      this.isToggleSegmentDropdown = !this.isToggleSegmentDropdown;
    } else if (phase === 'p2') {
      this.isToggleSegmentDropdown2 = !this.isToggleSegmentDropdown2;
    }
  }
  toCurrency() {
    let igstTax1Curr = parseInt(this.adjP1Form.value.igstTax1).toLocaleString();
    this.adjP1Form.value.igstTax1 = igstTax1Curr;
  }
  issueDropdown() {
    this.dataSource = ISSUE_GST;
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
    this.finYearSelelctEvent(i);
    this.quarterSelectEvent(i);
  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }

  savecaseItemList() {
    const itemList = this.adjP1Form.value.itemList;
    if (itemList.length > 0) {
      itemList.forEach((item: any, i: number) => {
        if (!this.caseItemList[i]) {
          this.caseItemList.push({});
        }
        if (item.phCopyLoc) {
          this.caseItemList[i].phCopyLoc = item.phCopyLoc;
        }
        if (item.additionalSubmissionLoc) {
          this.caseItemList[i].additionalSubmissionLoc =
            item.additionalSubmissionLoc;
        }
        if (item.phRecordIfAnyLoc) {
          this.caseItemList[i].phRecordIfAnyLoc = item.phRecordIfAnyLoc;
        }
        if (item.phAdjournmentLetterLoc) {
          this.caseItemList[i].phAdjournmentLetterLoc = item.phAdjournmentLetterLoc;
        }
      });

      // this.caseItemList.push()
    }
  }

  setCaseItemList() {
    this.adjP1Form.value.itemList.forEach((data: any, i: number) => {
      if (!data.phCopyLoc) {
        data.phCopyLoc = this.caseItemList[i]?.phCopyLoc;
      }
      if (!data.additionalSubmissionLoc) {
        data.additionalSubmissionLoc =
          this.caseItemList[i]?.additionalSubmissionLoc;
      }
      if (!data.phRecordIfAnyLoc) {
        data.phRecordIfAnyLoc = this.caseItemList[i]?.phRecordIfAnyLoc;
      }
      if (!data.phAdjournmentLetterLoc) {
        data.phAdjournmentLetterLoc = this.caseItemList[i]?.phAdjournmentLetterLoc;
      }
    });
  }
  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.adjP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map(
      (data: any) => data.finYear
    );
    this.finyearData.forEach((f) => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.adjP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map(
      (data: any) => data.quarter
    );
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  getValidDate(date: any): any {
    if (date.value) {
      let d = date.value.split('-');
      const dateValidation = new Date(d[1] + '-' + d[0] + '-' + d[2]);
      return dateValidation;
    } else {
      return new Date();
    }
  }

  issueValueChange() {
    let igst1Total = 0;
    let cgst1Total = 0;
    let sgst1Total = 0;
    let cess1Total = 0;
    let interest1Total = 0;
    let penalty1Total = 0;

    let cess3Total = 0;
    let cgst3Total = 0;
    let igst3Total = 0;
    let interest3Total = 0;
    let penalty3Total = 0;
    let sgst3Total = 0;

    (this.adjP1Form.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const igst1 = this.inrService.removeCommasAndParseNumber(c.value.igst1);
      const cgst1 = this.inrService.removeCommasAndParseNumber(c.value.cgst1);
      const sgst1 = this.inrService.removeCommasAndParseNumber(c.value.sgst1);
      const cess1 = this.inrService.removeCommasAndParseNumber(c.value.cess1);
      const interest1 = this.inrService.removeCommasAndParseNumber(
        c.value.interest1
      );
      const penalty1 = this.inrService.removeCommasAndParseNumber(
        c.value.penalty1
      );
      const igst3 = this.inrService.removeCommasAndParseNumber(c.value.igst3);
      const cgst3 = this.inrService.removeCommasAndParseNumber(c.value.cgst3);
      const sgst3 = this.inrService.removeCommasAndParseNumber(c.value.sgst3);
      const cess3 = this.inrService.removeCommasAndParseNumber(c.value.cess3);
      const interest3 = this.inrService.removeCommasAndParseNumber(
        c.value.interest3
      );
      const penalty3 = this.inrService.removeCommasAndParseNumber(
        c.value.penalty3
      );

      igst1Total += Number(igst1);
      cgst1Total += Number(cgst1);
      sgst1Total += Number(sgst1);
      cess1Total += Number(cess1);
      interest1Total += Number(interest1);
      penalty1Total += Number(penalty1);
      igst3Total += Number(igst3);
      cgst3Total += Number(cgst3);
      sgst3Total += Number(sgst3);
      cess3Total += Number(cess3);
      interest3Total += Number(interest3);
      penalty3Total += Number(penalty3);

      c.patchValue({
        total1: this.inrService.formatCurrency(
          String(
            Number(igst1) +
            Number(cgst1) +
            Number(sgst1) +
            Number(cess1) +
            Number(interest1) +
            Number(penalty1)
          )
        ),
      });
      c.patchValue({
        total3: this.inrService.formatCurrency(
          String(
            Number(igst3) +
            Number(cgst3) +
            Number(sgst3) +
            Number(cess3) +
            Number(interest3) +
            Number(penalty3)
          )
        ),
      });
    });

    this.adjP1Form.controls['igst1Total'].setValue(
      this.inrService.formatCurrency(String(igst1Total))
    );
    this.adjP1Form.controls['cgst1Total'].setValue(
      this.inrService.formatCurrency(String(cgst1Total))
    );
    this.adjP1Form.controls['sgst1Total'].setValue(
      this.inrService.formatCurrency(String(sgst1Total))
    );
    this.adjP1Form.controls['cess1Total'].setValue(
      this.inrService.formatCurrency(String(cess1Total))
    );
    this.adjP1Form.controls['interest1Total'].setValue(
      this.inrService.formatCurrency(String(interest1Total))
    );
    this.adjP1Form.controls['penalty1Total'].setValue(
      this.inrService.formatCurrency(String(penalty1Total))
    );
    this.adjP1Form.controls['cess3Total'].setValue(
      this.inrService.formatCurrency(String(cess3Total))
    );
    this.adjP1Form.controls['cgst3Total'].setValue(
      this.inrService.formatCurrency(String(cgst3Total))
    );
    this.adjP1Form.controls['igst3Total'].setValue(
      this.inrService.formatCurrency(String(igst3Total))
    );
    this.adjP1Form.controls['sgst3Total'].setValue(
      this.inrService.formatCurrency(String(sgst3Total))
    );
    this.adjP1Form.controls['penalty3Total'].setValue(
      this.inrService.formatCurrency(String(penalty3Total))
    );
    this.adjP1Form.controls['interest3Total'].setValue(
      this.inrService.formatCurrency(String(interest3Total))
    );

    this.adjP1Form.controls['totOfTotal1'].setValue(
      this.inrService.formatCurrency(
        String(
          Number(
            +igst1Total +
            +cgst1Total +
            +sgst1Total +
            +cess1Total +
            +interest1Total +
            +penalty1Total
          )
        )
      )
    );
    this.adjP1Form.controls['totOfTotal3'].setValue(
      this.inrService.formatCurrency(
        String(
          Number(
            +cess3Total +
            +cgst3Total +
            +igst3Total +
            +sgst3Total +
            +penalty3Total +
            +interest3Total
          )
        )
      )
    );
  }
  selectedRemandedBackCaseId(value: any) {
    if (value) {
      this.selectedRemandedBackCaseDetail = this.remandedCaseObj.find((c: any) => c.value === value.target.value);
      this.initializeRemandedBackPaymentData(this.selectedRemandedBackCaseDetail.cumulative)
      this.setCummulativeCount();
    }
  }
  setCummulativeCount(
    oldArr: any = null,
    newArr: any = null,
    tableName: any = null
  ) {
  }
  changeRemandedBackForum(event: any) {
    this.remandedCaseObj = [];
    let model: any = {};

    const criteriaArray: any = [{
      "p": "type",
      "o": "eq",
      "v": event.target.value
    }]
    model.criterias = criteriaArray;
    model.companyId = localStorage.getItem('selectedId'),
      this.litService.getRemandedBackForumGSTOptions(model).subscribe((response: any) => {
        if (response) {
          this.remandBackCaseList = response?.response;
          response?.response.forEach((res: any) => {
            let obj = {
              key: res.caseId,
              value: res.caseId,
              cumulative: res.cumulative,
              issues: res.issues,
            };
            this.remandedCaseObj.push(obj);
          });
        }
      });
  }

  setRemandBackData() {
    // this.disabledOnload = true;
    let remandBackCaseData: any = this.remandBackCaseList.find(
      (data: any) => data.caseId == this.adjP1Form.value.remandedCaseId
    );
    this.adjP1Form.controls['caseId'].setValue(remandBackCaseData?.caseId);
    // this.adjP1Form.controls['actType'].setValue(remandBackCaseData?.actType );
    // this.changeTax(null);
    this.adjP1Form.controls['state'].setValue(remandBackCaseData?.state);
    // this.changeState(null);
    // this.adjP1Form.controls['regNo'].setValue(remandBackCaseData?.regNo);
    if (remandBackCaseData?.gstIssues?.length) this.rmIssueInv(0, true);
    this.initializeIssueList(remandBackCaseData, true);
  }

  detailOfprotestDetails(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      challanNo: [data ? data?.challanNo : null],
      markedForPayment: [data ? data?.markedForPayment : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],
      formOfPayment: [data ? data?.formOfPayment : null],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst: [data ? data?.cgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst: [data ? data?.sgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess: [data ? data?.cess : 0, [Validators.pattern(/^-?[0-9,]+$/)]],


      interest: [
        data ? data?.interest : 0,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      accountingRef: [data ? data?.accountingRef : null, [Validators.maxLength(100)]],
      supportingDocLoc: [data?.supportingDocLoc ? data.supportingDocLoc : null],
    });
  }

  //  initialise Protest array
  initializeProtestDetails() {
    if (this.caseData.protestDetails) {
      this.caseData.protestDetails.forEach((protest: any) => {
        let depositDetailsFrmGrp = this.detailOfprotestDetails(protest);
        (this.adjP1Form.get('protestDetails') as UntypedFormArray).push(
          depositDetailsFrmGrp
        );
        if (protest.copyOfFormLoc) {
          protest.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createProCopyForm(not);
            (depositDetailsFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }

        if (protest.copyOfBankChallanLoc) {
          protest.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createProBankChallan(not);
            (depositDetailsFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);
          });
        }

      });
    }
  }

  addDetailOfPayment() {
    let depositDetailsFrmGrp = this.detailOfprotestDetails();
    (this.adjP1Form.get('protestDetails') as UntypedFormArray).push(
      depositDetailsFrmGrp
    );
  }

  getDetailOfPaymentArr(): UntypedFormGroup[] {
    return (this.adjP1Form.get('protestDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  rmPaymentUnderProtest(i: number) {
    (this.adjP1Form.get('protestDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPaymentRef.close();
    this.paymentUnderProtestp1Changed();
  }

  paymentUnderProtestp1Changed() {
    let igstTotal = 0;
    let CGSTTotal = 0;
    let SGSTTotal = 0;
    let CESSTotal = 0;

    let interestTotal = 0;
    let penaltyTotal = 0;
    let protestTotal = 0;

    (this.adjP1Form.get('protestDetails') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
        const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
        const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
        const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
        const interest = this.inrService.removeCommasAndParseNumber(
          c.value.interest
        );
        const penalty = this.inrService.removeCommasAndParseNumber(
          c.value.penalty
        );
        igstTotal += Number(igst);
        CGSTTotal += Number(cgst);
        SGSTTotal += Number(sgst);
        CESSTotal += Number(cess);

        interestTotal += Number(interest);
        penaltyTotal += Number(penalty);
        c.patchValue(
          {
            total: this.inrService.formatCurrency(
              String(+Number(igst) + Number(cgst) + Number(sgst) + Number(cess) + Number(interest) + +Number(penalty))
            ),
          },
          { emitEvent: false, onlySelf: true }
        );
        const total = this.inrService.removeCommasAndParseNumber(c.value.total);
        protestTotal += Number(total);
      }
    );

    this.protestInterestTotal = interestTotal;
    this.protestIgstTotal = igstTotal;
    this.protestCGSTTotal = CGSTTotal;
    this.protestSGSTTotal = SGSTTotal;
    this.protestCESSTotal = CESSTotal;
    this.protestPenaltyTotal = penaltyTotal;
    this.protestTotal = protestTotal;
  }

  getSubmissionDetailsArr(): UntypedFormGroup[] {
    return (this.adjP1Form.get('submissions') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  addSubmissionDetailsArr() {
    let issueFrmGrp = this.detailOfSubmissionsDetails();
    (this.adjP1Form.get('submissions') as UntypedFormArray).push(issueFrmGrp);
  }

  rmSubmissionDetailProtest(i: number) {
    (this.adjP1Form.get('submissions') as UntypedFormArray).removeAt(i);
    this.rmvSubmissionDetailsRef.close();
  }

  // initialise submissions array
  initializeDetailOfSubmission(letterIndex?: number) {
    if (this.caseData.submissions) {
      this.caseData.submissions.forEach((submissions: any) => {
        let detailOfSubmission = this.detailOfSubmissionsDetails(submissions);
        if (letterIndex !== undefined && letterIndex > -1) {
          detailOfSubmission = (this.adjP1Form.get('submissions') as FormArray).at(letterIndex) as FormGroup
        } else {
          (this.adjP1Form.get('submissions') as UntypedFormArray).push(detailOfSubmission);
        }
        if (submissions.copyOfAckLoc) {
          submissions.copyOfAckLoc.forEach((not: any) => {
            let ackCopyLocFrmGrp: UntypedFormGroup = this.createAckCopyLoc(not);
            (detailOfSubmission.get('copyOfAckLoc') as UntypedFormArray).push(ackCopyLocFrmGrp);
          });
        }
        if (submissions.otherAttachmentsLoc) {
          submissions.otherAttachmentsLoc.forEach((not: any) => {
            let otherAttachLocFrmGrp: UntypedFormGroup = this.createOtherAttachment2Loc(not);
            (detailOfSubmission.get('otherAttachmentsLoc') as UntypedFormArray).push(otherAttachLocFrmGrp);
          });
        }
      });
      this.adjP1Form.controls.submissions.patchValue(this.caseData?.submissions);
    }
    this.hideAiOptionSub()
  }
  hideAiOptionSub() {
    let submissionsFrmArray = (this.adjP1Form.get('submissions') as UntypedFormArray).controls;
    let element1;
    for (let i = 0; i < submissionsFrmArray.length; i++) {
      element1 = (submissionsFrmArray[i].get('copyOfAckLoc') as UntypedFormArray).controls;
    }
    let count = element1?.some((x: any) =>
      x.get('fileLoc')?.value != null);
    if (!count) {
      this.hideOcrFilePh2 = true;
    } else {
      this.hideOcrFilePh2 = false;
    }
  }

  hidePh3Ai() {
    let dataOioLoc = (this.adjP1Form.get('oioLoc') as UntypedFormArray).controls;
    var count = dataOioLoc?.some((x) => x.get('fileLoc')?.value != '');
    if (!count) {
      this.hideOcrFilePh3 = true;
    } else {
      this.hideOcrFilePh3 = false;
    }
  }

  // Details of submissions
  detailOfSubmissionsDetails(submission: any = null): UntypedFormGroup {
    return this.fBuild.group({
      refNo: [submission ? submission.refNo : null],
      copyOfAckLoc: new UntypedFormArray([]),
      submissionMode: [submission ? submission.submissionMode : null],
      submissionDate: [submission ? submission.submissionDate : null],
      otherAttachmentsLoc: new UntypedFormArray([]),
    });
  }
  openRemoveSubmissionDetailsPopup(content: any) {
    this.rmvSubmissionDetailsRef = this.modalService.open(content);
  }

  // consultant change functions
  initializeConsultantCharges() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultantCharges: any) => {
        let detailOfConsultant = this.ConsultantCharges(consultantCharges);
        (this.adjP1Form.get('consultantCharges') as UntypedFormArray).push(
          detailOfConsultant
        );
        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let consultAttachLocFrmGrp: UntypedFormGroup = this.createConsultAttach(not);
            (detailOfConsultant.get('otherAttachmentLoc') as UntypedFormArray).push(consultAttachLocFrmGrp);
          });
        }


      });
      this.feesChargedChanged();
    }
  }
  ConsultantCharges(consultantCharges: any = null): UntypedFormGroup {
    return this.fBuild.group({
      srNo: [consultantCharges ? consultantCharges.srNo : null],
      firm: [consultantCharges ? consultantCharges.firm : null],
      consultant: [consultantCharges ? consultantCharges.consultant : null],
      natureOfWork: [consultantCharges ? consultantCharges.natureOfWork : null],
      fees: [consultantCharges ? consultantCharges.fees : 0],
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [consultantCharges ? consultantCharges.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [consultantCharges ? consultantCharges.contact : null]
    });
  }

  consultantInvArr(): UntypedFormGroup[] {
    return (this.adjP1Form.get('consultantCharges') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  consultantDataAutofil(index: number) {
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

  }

  addConsultantInv() {
    let detailOfConsultant = this.ConsultantCharges();
    (this.adjP1Form.get('consultantCharges') as UntypedFormArray).push(
      detailOfConsultant
    );
  }

  rmConsultantInv(i: number) {
    (this.adjP1Form.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.feesChargedChanged();
    this.rmvConsultantModalRef.close();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.adjP1Form.get('consultantCharges') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const fees = this.inrService.removeCommasAndParseNumber(c.value.fees);
        feesChargedTotal += Number(fees);
      }
    );

    this.feesChargedTotal = feesChargedTotal;
  }
  openRmvconsultantPop(content: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }
  uploadCopyOfAck(i: any) {
    var submissionArray = this.adjP1Form.controls.submissions as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (var index = 0; index < submissionArray.controls.length; index++) {
        if (index === i) {
          var submissionControl = submissionArray.controls[index] as UntypedFormGroup;
          submissionControl.value['isCopyOfAckLocDocUploadedClicked'] = true;
        }
      }
    }
    this.isCopyOfAckFile = true;
    this.isCopyOfAckText = false;
    this.isCopyOfAckDWText = false;
    this.isCopyOfAckUPText = false;
  }

  uploadotherAttachments(i: any) {
    var submissionArray = this.adjP1Form.controls.submissions as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (var index = 0; index < submissionArray.controls.length; index++) {
        if (index === i) {
          var submissionControl = submissionArray.controls[index] as UntypedFormGroup;
          submissionControl.value['isOtherAttachmentsLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isOtherAttachmentFile = true;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentDWText = false;
    this.isOtherAttachmentUPText = false;
  }
  deleteSubmissions2UploadFile(i: any) {
    let submissionArray = this.adjP1Form.controls.submissions as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentsLoc.setValue(null);
          issuesControl.value['isOtherAttachmentsLocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteSubmissionsUploadFile(i: any) {
    let submissionArray = this.adjP1Form.controls.submissions as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfAckLoc.setValue(null);
          issuesControl.value['iscopyOfAckLocUploadedClicked'] = true;
        }
      }
    }
  }
  uploadSupportingDoc(i: any) {
    var protestDetailArray = this.adjP1Form.controls
      .protestDetails as UntypedFormArray;
    if (protestDetailArray.controls.length > 0) {
      for (var index = 0; index < protestDetailArray.controls.length; index++) {
        if (index === i) {
          var protestDetailControl = protestDetailArray.controls[
            index
          ] as UntypedFormGroup;
          protestDetailControl.value['isSupportingDocUploadedClicked'] = true;
        }
      }
    }
    this.isSupportingDocFile = true;
    this.isSupportingDocText = false;
    this.isSupportingDocDWText = true;
    this.isSupportingDocUPText = false;
  }

  consultantValidation() {
    let consultantArray = this.adjP1Form.controls
      .consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(
          issuesControl.controls.fees.value
        );
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (
            issuesControl.controls.firm.value === null ||
            issuesControl.controls.firm.value === ''
          ) {
            issuesControl.controls.firm.setErrors({ required: true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvDetailsOfPaymentRef = this.modalService.open(content);
  }

  uploadoConsultantAttachmentsLoc(i: any) {
    var consultantArray = this.adjP1Form.controls
      .consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLocText = true;
    this.isconsultantUPattachmentsLocText = false;
  }
  deleteConsultantFile(i: any) {
    let consultantArray = this.adjP1Form.controls
      .consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  saveSubmissionDocuments() {
    // const appealDetailList = this.adjP1Form.value.submissions;
    //   if (appealDetailList.length > 0) {
    //     appealDetailList.forEach((item: any,i: number) => {
    //       if (item.copyOfAckLoc) {
    //         item[i].copyOfAckLoc = item.copyOfAckLoc;
    //       }
    //       if (item.otherAttachmentsLoc) {
    //         item[i].otherAttachmentsLoc = item.otherAttachmentsLoc;
    //       }
    //     })
    //   }
    //   this.adjP1Form.controls.submissions.patchValue(appealDetailList);
  }

  initializeliabilityDetailsAndPaid() {
    if (this.caseData.liabilityDetails) {
      this.caseData.liabilityDetails.forEach((liability: any) => {
        let liabilityDetails = this.detailsOfLiabilityFrmGrp(liability);
        (this.adjP1Form.get('liabilityDetails') as UntypedFormArray).push(
          liabilityDetails
        );

        if (liability.copyOfFormLoc) {
          liability.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createLiabilityCopyForm(not);
            (liabilityDetails.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }

        if (liability.copyOfBankChallanLoc) {
          liability.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createLiabilityBankChallan(not);
            (liabilityDetails.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);
          });
        }

      });
    }
  }

  liabilityPanelExpansion() {
    this.liabilityExpansionPanel = !this.liabilityExpansionPanel;
  }
  // Details of Liability Admitted and Paid
  getLiability(): UntypedFormGroup[] {
    return (this.adjP1Form.get('liabilityDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  addLiabilityInv(event: any = null) {
    let liabilityFrmGrp = this.detailsOfLiabilityFrmGrp();
    (this.adjP1Form.get('liabilityDetails') as UntypedFormArray).push(liabilityFrmGrp);

    return false;
  }
  rmLiabilityInv(i: number) {
    (this.adjP1Form.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityModalRef.close();
    this.liabilityTotalCalculation();
  }
  liabilityTotalCalculation() {
    let igstTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;

    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;
    (this.adjP1Form.get('liabilityDetails') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
        const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
        const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
        const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
        const interest = this.inrService.removeCommasAndParseNumber(
          c.value.interest
        );
        const penalty = this.inrService.removeCommasAndParseNumber(
          c.value.penalty
        );
        igstTotal += Number(igst);
        cgstTotal += Number(cgst);
        sgstTotal += Number(sgst);
        cessTotal += Number(cess);
        interestTotal += Number(interest);
        penaltyTotal += Number(penalty);
        c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + (Number(cgst)) + (Number(sgst)) + (Number(cess)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
        const total = this.inrService.removeCommasAndParseNumber(c.value.total);
        liabilityPaidTotal += Number(total);
      }
    );

    this.liabilityTotals.allTotal = liabilityPaidTotal;
    this.liabilityTotals.interestTotal = interestTotal;
    this.liabilityTotals.penaltyTotal = penaltyTotal;
    this.liabilityTotals.igstTotal = igstTotal;
    this.liabilityTotals.cgstTotal = cgstTotal;
    this.liabilityTotals.sgstTotal = sgstTotal;
    this.liabilityTotals.cessTotal = cessTotal;
  }
  // Liability Table
  detailsOfLiabilityFrmGrp(liability: any = null): UntypedFormGroup {
    return this.fBuild.group({
      issueName: new UntypedFormControl(liability ? liability.issueName : null, []),
      tag: new UntypedFormControl(liability ? liability.tag : null, []),
      dateOfPayment: new UntypedFormControl(liability ? liability.dateOfPayment : null, []),
      markedForPayment: new UntypedFormControl(liability ? liability.markedForPayment : null, []),
      formOfPayment: new UntypedFormControl(liability ? liability.formOfPayment : null, []),
      challanNo: new UntypedFormControl(liability ? liability.challanNo : null, []),
      modeOfPayment: new UntypedFormControl(
        liability ? liability.modeOfPayment : null,
        []
      ),
      accountingRef: [liability ? liability?.accountingRef : null, [Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: new UntypedFormControl(liability ? liability.igst : 0, []),
      cgst: new UntypedFormControl(liability ? liability.cgst : 0, []),
      sgst: new UntypedFormControl(liability ? liability.sgst : 0, []),
      cess: new UntypedFormControl(liability ? liability.cess : 0, []),
      interest: new UntypedFormControl(liability ? liability.interest : 0, []),
      penalty: new UntypedFormControl(liability ? liability.penalty : 0, []),
      total: new UntypedFormControl(liability ? liability.total : 0, []),
    });
  }

  //  Details of Liability Admitted and Paid0,
  uploadcopyOfForm(i: any) {
    var liabilityDetailsArray = this.adjP1Form.controls
      .liabilityDetails as UntypedFormArray;
    if (liabilityDetailsArray.controls.length > 0) {
      for (
        var index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.iscopyOfFormLocFile = true;
    this.iscopyOfFormLocText = false;
    this.iscopyOfUPFormLoc = false;
    this.iscopyOfDWFormLoc = false;
  }

  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.adjP1Form.controls.liabilityDetails as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }
  uploadcopyOfBankChallan(i: any) {
    var liabilityDetailsArray = this.adjP1Form.controls
      .liabilityDetails as UntypedFormArray;
    if (liabilityDetailsArray.controls.length > 0) {
      for (
        var index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          liabilityDetailsControl.value[
            'isCopyOfBankChallanLocDocUploadedClicked'
          ] = true;
        }
      }
    }
    this.iscopyOfBankChallanLocFile = true;
    this.iscopyOfBankChallanLocText = false;
    this.iscopyOfBankDWChallanLoc = false;
    this.iscopyOfBankUPChallanLoc = false;
  }
  deleteliabilityFile(i: any) {
    let liabilityArray = this.adjP1Form.controls.liabilityDetails as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }
  openRmvLiabilityPop(content: any, i: any) {
    this.rmvLiabilityModalRef = this.modalService.open(content);
  }

  issuingAuthChange1() {
    if (this.adjP1Form.value.typeOfAuthority1 === 'CGST') {
      this.adjP1Form.controls['din1']?.setErrors({ required: true });
    } else {
      this.adjP1Form.controls['din1']?.setErrors(null);
    }
  }

  issuingAuthChange3() {
    if (this.adjP1Form.value.typeOfAuthority3 === 'CGST') {
      this.adjP1Form.controls['din3']?.setErrors({ required: true });
    } else {
      this.adjP1Form.controls['din3']?.setErrors(null);
    }
  }

  whetherCallBookChanged() {

    this.disablePhase3 =
      (this.adjP1Form.value.whetherCaseCallBook === 'true' || this.adjP1Form.value.whetherCaseCallBook === true) ? true
        : false;
  }

  setForumOfAppeal() {
    this.forumAppeal = FORUM_APPEAL_GST;
    if (
      (this.adjP1Form.value.whetherAppealPreferred === true ||
        this.adjP1Form.value.whetherAppealPreferred === 'true') &&
      (this.adjP1Form.value.whetherDptAppealPreferred === false ||
        this.adjP1Form.value.whetherDptAppealPreferred === 'false') &&
      (this.adjP1Form.value.writAppPreferred === false ||
        this.adjP1Form.value.writAppPreferred === 'false')
    ) {
      this.forumAppeal = this.forumAppeal.filter(
        (c) => c.key === 'apa' || c.key === 'apt'
      );
      // [
      //   {key:"apa", value:" Commissioner Appeal"},
      //   {key:"apt", value:"Appellate Tribunal"}
      // ];
      // this.adjP1Form.controls['forumOfAppeal'].setValue(this.caseData?.forumOfAppeal);

      return;
    }
    if (
      (this.adjP1Form.value.whetherAppealPreferred === false ||
        this.adjP1Form.value.whetherAppealPreferred === 'false') &&
      (this.adjP1Form.value.whetherDptAppealPreferred === false ||
        this.adjP1Form.value.whetherDptAppealPreferred === 'false') &&
      (this.adjP1Form.value.writAppPreferred === false ||
        this.adjP1Form.value.writAppPreferred === 'false')
    ) {
      this.forumAppeal = this.forumAppeal.filter(
        (c) => c.key === 'Appeal/Writ'
      );
      // this.forumAppeal = [
      //   {key:"Appeal/Writ", value:"Appeal/Writ not Filed"}
      // ];
      // this.adjP1Form.controls['forumOfAppeal'].setValue(this.caseData?.forumOfAppeal);

      // this.adjP1Form.get('forumOfAppeal')?.setValue('apt')
      return;
    }
    if (
      (this.adjP1Form.value.whetherAppealPreferred === false ||
        this.adjP1Form.value.whetherAppealPreferred === 'false') &&
      (this.adjP1Form.value.whetherDptAppealPreferred === true ||
        this.adjP1Form.value.whetherDptAppealPreferred === 'true') &&
      (this.adjP1Form.value.writAppPreferred === false ||
        this.adjP1Form.value.writAppPreferred === 'false')
    ) {
      this.forumAppeal = this.forumAppeal.filter(
        (c) => c.key === 'apa' || c.key === 'apt'
      );

      // this.forumAppeal = [
      //   {key:"apa", value:" Commissioner Appeal"},
      //   {key:"apt", value:"Appellate Tribunal"}
      // ];
      return;
    }

    if (
      (this.adjP1Form.value.whetherAppealPreferred === false ||
        this.adjP1Form.value.whetherAppealPreferred === 'false') &&
      (this.adjP1Form.value.whetherDptAppealPreferred === false ||
        this.adjP1Form.value.whetherDptAppealPreferred === 'false') &&
      (this.adjP1Form.value.writAppPreferred === true ||
        this.adjP1Form.value.writAppPreferred === 'true')
    ) {
      this.forumAppeal = this.forumAppeal.filter(
        (c) => c.key === 'hc' || c.key === 'sc'
      );

      return;
    }
    this.forumAppeal = FORUM_APPEAL_GST;
  }

  whetherWritToHCChange() {
    if (this.adjP1Form.value.whetherWritPreferredHc === 'true') {
      this.disablePhase3 = true;
      this.disablePhase2 = true;
    } else {
      this.disablePhase3 = false;
      this.disablePhase2 = false;
    }
  }

  uploadProtestDetailsCopyOfFormLoc(i: any) {
    let protestDetailsArray = this.adjP1Form.controls
      .protestDetails as UntypedFormArray;
    if (protestDetailsArray.controls.length > 0) {
      for (
        let index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          let protestDetailsControl = protestDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          protestDetailsControl.controls.copyOfFormLoc.setValue(
            this.uploadData
          );
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] =
            true;
        }
      }
    }
    // this.isProtestDetailCopyOfFormLocFile = true;
    // this.isProtestDetailCopyOfFormLocText = false;
    // this.isProtestDetailCopyOfFormLocDWText = true;
    // this.isProtestDetailCopyOfFormLocUPText = false;
  }

  uploadProtestDetailsCopyOfBankChallanLoc(i: any) {
    let protestDetailsArray = this.adjP1Form.controls
      .protestDetails as UntypedFormArray;
    if (protestDetailsArray.controls.length > 0) {
      for (
        let index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          let protestDetailsControl = protestDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          protestDetailsControl.value[
            'isCopyOfBankChallanLocDocUploadedClicked'
          ] = true;
        }
      }
    }
    // this.isProtestDetailCopyOfBankChallanLocFile = true;
    // this.isProtestDetailCopyOfBankChallanLocText = false;
    // this.isProtestDetailCopyOfBankChallanLocDWText= true;
    // this.isProtestDetailCopyOfBankChallanLocUPText = false;
  }

  deleteUploadFile(i: any) {
    let protestArray = this.adjP1Form.controls.protestDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  deleteProtestFile(i: any) {
    let protestArray = this.adjP1Form.controls.protestDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['isSupportingDocDocUploadedClicked'] = true;
        }
      }
    }
  }

  getFormName() {
    return this.preAdjType.find(
      (type) => type.key === this.adjP1Form?.get('remandedBackForum')?.value
    )?.value;
  }
  calculateRemandBackTotal() {
    (this.adjP1Form.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue({ total: (+c.value.tax) + (+c.value.interest) + (+c.value.penalty) });
      } else {
        c.patchValue({ total: +(c.value.total) })
      }
    })
  }

  refreshCumulativeTable(event: any) {
    event.stopPropagation();
    let refreshValue = this.adjP1Form.value;
    refreshValue['cumulative'] = this.caseData.cumulative;
    refreshValue['prevStage'] = this.prevStage;

    if (
      refreshValue.consultantCharges &&
      refreshValue.consultantCharges.length
    ) {
      refreshValue.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
      });
    }


    if (refreshValue.protestDetails && refreshValue.protestDetails.length) {
      refreshValue.protestDetails.forEach((protestDetail: any) => {
        protestDetail.igst = this.inrService.removeCommasAndParseNumber(
          protestDetail.igst
        );
        protestDetail.cgst = this.inrService.removeCommasAndParseNumber(
          protestDetail.cgst
        );
        protestDetail.sgst = this.inrService.removeCommasAndParseNumber(
          protestDetail.sgst
        );
        protestDetail.cess = this.inrService.removeCommasAndParseNumber(
          protestDetail.cess
        );
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(
          protestDetail.interest
        );
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(
          protestDetail.penalty
        );
        protestDetail.total = this.inrService.removeCommasAndParseNumber(
          protestDetail.total
        );
      });
    }

    if (refreshValue.liabilityDetails && refreshValue.liabilityDetails.length) {
      refreshValue.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(
          liability.igst
        );
        liability.cgst = this.inrService.removeCommasAndParseNumber(
          liability.cgst
        );
        liability.sgst = this.inrService.removeCommasAndParseNumber(
          liability.sgst
        );
        liability.cess = this.inrService.removeCommasAndParseNumber(
          liability.cess
        );
        liability.interest = this.inrService.removeCommasAndParseNumber(
          liability.interest
        );
        liability.penalty = this.inrService.removeCommasAndParseNumber(
          liability.penalty
        );
        liability.total = this.inrService.removeCommasAndParseNumber(
          liability.total
        );
      });
    }

    if (refreshValue.issues && refreshValue.issues.length) {
      refreshValue.issues.forEach((issue: any) => {
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1);
        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1);
        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1);
        issue.interest1 = this.inrService.removeCommasAndParseNumber(
          issue.interest1
        );
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(
          issue.penalty1
        );
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3);
        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3);
        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3);
        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3);
        issue.interest3 = this.inrService.removeCommasAndParseNumber(
          issue.interest3
        );
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(
          issue.penalty3
        );
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3);
      });
    }

    refreshValue.igst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst1Total);
    refreshValue.cgst1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cgst1Total
    );
    refreshValue.sgst1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.sgst1Total
    );
    refreshValue.cess1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cess1Total
    );
    refreshValue.interest1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.interest1Total
    );
    refreshValue.penalty1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.penalty1Total
    );
    refreshValue.totOfTotal1 = this.inrService.removeCommasAndParseNumber(
      refreshValue.totOfTotal1
    );
    refreshValue.igst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.igst3Total
    );
    refreshValue.cgst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cgst3Total
    );
    refreshValue.sgst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.sgst3Total
    );
    refreshValue.cess3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cess3Total
    );
    refreshValue.interest3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.interest3Total
    );
    refreshValue.penalty3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.penalty3Total
    );
    refreshValue.totOfTotal3 = this.inrService.removeCommasAndParseNumber(
      refreshValue.totOfTotal3
    );
    this.litService.getCumulativeCount(this.tabType[1].key, refreshValue).subscribe((response: any) => {
      this.formatTableValues();
      this.caseData.cumulative = response.response;
      this.cumulativeCount = response.response;
    });
  }

  setRemandBackTableData(label: any, id: any, value: any) {
    (this.adjP1Form.get('remandedPaymentMade') as UntypedFormArray).controls.forEach(
      (c: any) => {
        if (c.value.label === label) {
          c.value[id] = value;
        }
      }
    );
  }
  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('details') as UntypedFormArray)
      .controls as UntypedFormGroup[];

    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue =
      detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    });
  }
  handlePaste(event: any) {
    this.inrService.handlePaste(event);
  }
  getCompanyTags(gstin: any) {
    const regNo = gstin; // Get selected company ID
    this.masterService.getEntityTagsCase(regNo).subscribe((response: any) => {
      if (response.status === 1) {
        const allTags = response.response.data;

        // Filter tags based on the selected company ID
        this.tagDataList = allTags
          .filter((tagItem: any) => tagItem.regNo === regNo)
          .flatMap((tagItem: any) =>
            tagItem.tags
              .filter((tag: any) => tag.active) // Filter tags with active: true
              .map((tag: any) => tag.tag)
          );

      } else {
        this.toaster.showSuccess(response.message);
      }
    });
  }
  formatTableValues() {
    const issues1Array = this.adjP1Form.controls.issues as UntypedFormArray;
    const liabilityDetailsArray = this.adjP1Form.controls
      .liabilityDetails as UntypedFormArray;
    const protestDetailsArray = this.adjP1Form.controls
      .protestDetails as UntypedFormArray;
    const consultantChargesArray = this.adjP1Form.controls
      .consultantCharges as UntypedFormArray;

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (
        var index = 0;
        index < consultantChargesArray.controls.length;
        index++
      ) {
        const consultantChargesControl = consultantChargesArray.controls[
          index
        ] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(
          this.inrService.formatCurrency(consultantChargesControl.value.fees)
        );
      }
    }

    if (protestDetailsArray && protestDetailsArray.controls.length) {
      for (
        var index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        const protestDetailsControl = protestDetailsArray.controls[
          index
        ] as UntypedFormGroup;
        protestDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.igst));
        protestDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cgst))
        protestDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.sgst))
        protestDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cess))
        protestDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.interest));
        protestDetailsControl.controls['penalty'].setValue(
          this.inrService.formatCurrency(protestDetailsControl.value.penalty)
        );
        protestDetailsControl.controls['total'].setValue(
          this.inrService.formatCurrency(protestDetailsControl.value.total)
        );
      }
    }
    if (liabilityDetailsArray.controls.length > 0) {
      for (
        let index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[
          index
        ] as UntypedFormGroup;
        liabilityDetailsControl.controls['igst'].setValue(
          this.inrService.formatCurrency(liabilityDetailsControl.value.igst)
        );
        liabilityDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cgst))
        liabilityDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.sgst))
        liabilityDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cess))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest));
        liabilityDetailsControl.controls['penalty'].setValue(
          this.inrService.formatCurrency(liabilityDetailsControl.value.penalty)
        );
        liabilityDetailsControl.controls['total'].setValue(
          this.inrService.formatCurrency(liabilityDetailsControl.value.total)
        );
      }
    }

    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['igst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.igst1)
        );
        issues1Control.controls['cgst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cgst1)
        );
        issues1Control.controls['sgst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.sgst1)
        );
        issues1Control.controls['cess1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cess1)
        );
        issues1Control.controls['interest1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interest1)
        );
        issues1Control.controls['penalty1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penalty1)
        );
        issues1Control.controls['total1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.total1)
        );
        issues1Control.controls['igst3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.igst3)
        );
        issues1Control.controls['cgst3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cgst3)
        );
        issues1Control.controls['sgst3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.sgst3)
        );
        issues1Control.controls['cess3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cess3)
        );
        issues1Control.controls['interest3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interest3)
        );
        issues1Control.controls['penalty3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penalty3)
        );
        issues1Control.controls['total3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.total3)
        );
      }
    }
    this.issueValueChange();
  }
  // selectElement:any
  // getCurrentTime(){
  //   const currentTime = new Date();
  //   const currentHour = currentTime.getHours();
  //   const currentMinute = currentTime.getMinutes();

  //   // Round minutes to nearest half-hour
  //   const roundedMinute = Math.round(currentMinute / 30) * 30;

  //   // Adjust current hour for AM/PM format
  //   const timeFormat = currentHour >= 12 ? 'PM' : 'AM';
  //   const hour12 = (currentHour % 12) || 12; // Convert hour to 12-hour format

  //   // Construct current selected time
  //   const currentSelectedTime = `${hour12.toString().padStart(2, '0')}:${roundedMinute.toString().padStart(2, '0')} ${timeFormat}`;
  //   var itemListArray = this.adjP1Form.controls.itemList as UntypedFormArray;
  //   if (itemListArray.controls.length > 0) {
  //     for (var i = 0; i < itemListArray.controls.length; i++) {
  //       var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
  //       issues1Control.controls.phTime.setValue(currentSelectedTime);

  //     }
  //   } 
  //  }


  selectedIssues: any
  getissueData(content: any, i: any) {
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.adjP1Form.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
      model.size = 10,
      model.sortfield = "createdOn",
      model.sortdir = "DESC",
      model.companyId = localStorage.getItem('selectedId'),
      model.module = "GST",
      model.role = "issueRef",
      model.criterias = [
        {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
        }
      ]
    this.litService.getIssueRefData(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.issueData = response?.response;
        if (this.issueData.length === 0) {
          this.hideData = false
        } else {
          this.hideData = true
        }
      }
    });
  }
  exportAsXLSX() {
    if (this.issueData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data = this.issueData.map((source: any) => ({
      'PAN Name': source.panName,
      'GSTIN': source.gstin,
      'GSTIN Name': source.gstinName,
      'Division': source.gstinDiv,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'Period From': source.periodFromDate,
      'Period to': source.periodToDate,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.STATE: {
        if (!this.sorting.state) {
          this.sorting.state = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'asc'
          );
        } else {
          this.sorting.state = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERIOD_FROM: {
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'asc'
          );
        } else {
          this.sorting.periodFromDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERIOD_TO: {
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'asc'
          );
        } else {
          this.sorting.periodToDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData, 'personResponsible', 'asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData, 'personResponsible', 'desc');
        }
        break;
      }
      case SORT_ISSUE.GSTIN: {
        if (!this.sorting.gstin) {
          this.sorting.gstin = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'asc'
          );
        } else {
          this.sorting.gstin = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.DIVISION: {
        if (!this.sorting.gstinDiv) {
          this.sorting.gstinDiv = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'asc'
          );
        } else {
          this.sorting.gstinDiv = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'desc'
          );
        }
        break;
      }
    }
  }

  selectedFileUrlUpdate(selectedValueUrl: string) {
    this.selectedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(selectedValueUrl);
  }

  onFileSelectSmart(event: any, index: number) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['pdf'])) {
        this.selectedFile = event.target.files[0];
        const fileUrl = URL.createObjectURL(this.selectedFile)
        this.selectedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And')
        this.selectedFile = new File([originalFile], modifiedFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.uploadedFileUrlList.push({
          name: originalFile.name,
          url: fileUrl,
        })
        this.onUpload(this.selectedField, null, index);
        this.hiddenbtn = true;
      } else {
        this.selectedFile = null;
        this.toaster.showError('Only PDF file type is supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }


  }


  onSmartUpload(phaseTab: any, letterIndex: number) {
    this.loaderService.hide();
    if (phaseTab === 'p1') {
      this.phase = 'adjudication_phase_1'
    } else if (phaseTab === 'p2') {
      this.phase = 'adjudication_phase_2'
    }
    else if (phaseTab === 'p3') {
      this.phase = 'adjudication_phase_3'
    }
    let requestBody = {
      filePathData: this.filePathDataArray,
      phase: this.phase
    };
    this.litService.getNoticeProcessId(requestBody).subscribe((response: any) => {
      if (response.status == 1) {
        this.taskId = response.response.task_id;
        if (!this.ocrLetterMap[letterIndex])
          this.ocrLetterMap[letterIndex] = {
            uploaded: true,
            processed: false,
            response: null
          }
        this.ocrLetterMap[letterIndex].taskId = response.response.task_id
        this.checkStatus(phaseTab, letterIndex);

      }
    })
  }

  formInit() {


  }
  checkStatus(phaseTab: any, letterIndex: number) {
    this.loaderService.hide();
    if (phaseTab === 'p1') {
      this.phase = 'adjudication_phase_1'
    } else if (phaseTab === 'p2') {
      this.phase = 'adjudication_phase_2'
    }
    else if (phaseTab === 'p3') {
      this.phase = 'adjudication_phase_3'
    }
    sessionStorage.setItem("Process_id", this.taskId)
    this.litService.checkNoticeStatus(this.taskId, this.phase).subscribe((response: any) => {
      switch (response.response.status) {
        case 'processing':
          this.toaster.showWarning(response.response.message);
          this.statusData = response.response.message;
          this.hideRefreshIcon = true;
          this.hiddenbtn = false;
          this.subscription = interval(30000).pipe(switchMap(() => this.litService.checkNoticeStatus(this.taskId, this.phase)))
            .subscribe((response: any) => {
              if (response.status === 1) {
                this.statusData = response.response.message;
                this.hideRefreshIcon = true;
                if (phaseTab === 'p1') {
                  if (response.response.status !== 'processing') {
                    this.caseData = response.response;
                    (this.adjP1Form.get('issues') as UntypedFormArray).removeAt(0);
                    this.initializeIssueList(this.caseData);
                    this.sidemenu = false;
                    this.hiddenbtn = false;
                    this.hidlabels = false;
                    this.hidlabelsub = true;
                    this.hidlabelsOio = true;
                    this.hideRefreshIcon = false;
                    if (response.response.status !== 'processing') {
                      this.subscription.unsubscribe();
                    }
                  }
                } if (phaseTab === 'p2') {
                  if (response.response.status !== 'processing') {
                    this.caseData = response.response;
                    const itemDetails = this.caseData?.submissions ?? [];
                    itemDetails[letterIndex] = response.response.submissions[0];
                    this.caseData = response.response;
                    this.caseData.submissions = itemDetails;
                    this.ocrLetterMap[letterIndex]!.response = response.response;
                    this.ocrLetterMap[letterIndex]!.processed = true;
                    this.initializeDetailOfSubmission(letterIndex);
                    this.sidemenu = false;
                    this.hiddenbtn = false;
                    this.hideRefreshIcon = false;
                    this.hidlabels = false;
                    this.hidlabelsub = true;
                    this.hidlabelsOio = true;
                    if (response.response.status !== 'processing') {
                      this.subscription.unsubscribe();
                    }
                  }
                } if (phaseTab === 'p3') {
                  if (response.response.status !== 'processing') {
                  this.caseData = response.response;
                    let caseDataCopy = { ...this.caseData };
                    delete caseDataCopy.issues;
                    this.adjP1Form.patchValue(caseDataCopy);
                    this.adjP1Form.controls.officer3.setValue(this.caseData?.issuingAuth3?.officer)
                    this.adjP1Form.controls.jurisdiction3.setValue(this.caseData?.issuingAuth3?.jurisdiction)
                    this.adjP1Form.controls.contact3.setValue(this.caseData?.issuingAuth3?.contact);
                    this.adjP1Form.controls.designation3.setValue(this.caseData?.issuingAuth3?.designation)
                    this.adjP1Form.controls.address3.setValue(this.caseData?.issuingAuth3?.address);
                    this.adjP1Form.controls.email3.setValue(this.caseData?.issuingAuth3?.email);
                    this.issueValueChange();
                    this.setIssuingAuthorityData();
                    this.onDesignationChange();
                    this.sidemenu = false;
                    this.hiddenbtn = false;
                    this.hideRefreshIcon = false;
                    this.hidlabels = false;
                    this.hidlabelsub = true;
                    this.hidlabelsOio = false;
                    if (response.response.status !== 'processing') {
                      this.subscription.unsubscribe();
                    }
                  }
                }
              } else {
                this.toaster.showError(response.response);
                this.hideRefreshIcon = false;
              }

            });
          break;

        default:
          if (response.status === 1) {
            if (phaseTab === 'p1') {
              if (response.response.status !== 'processing') {
                this.caseData = response.response;
                this.initializeIssueList(this.caseData);
                this.sidemenu = false;
                this.hiddenbtn = false;
                this.hideRefreshIcon = false;
                this.hidlabels = false;
                this.hidlabelsub = true;
                this.hidlabelsOio = true;
                if (response.response.status !== 'processing') {
                  this.subscription.unsubscribe();
                }
              }
            } if (phaseTab === 'p2') {
              if (response.response.status !== 'processing') {
                this.caseData = response.response;
                const itemDetails = this.caseData?.submissions ?? [];
                itemDetails[letterIndex] = response.response.submissions[0];
                this.caseData = response.response;
                this.caseData.submissions = itemDetails;
                this.ocrLetterMap[letterIndex]!.response = response.response;
                this.ocrLetterMap[letterIndex]!.processed = true;
                this.initializeDetailOfSubmission(letterIndex);
                this.sidemenu = false;
                this.hiddenbtn = false;
                this.hideRefreshIcon = false;
                this.hidlabels = false;
                this.hidlabelsub = true;
                this.hidlabelsOio = true;
                if (response.response.status !== 'processing') {
                  this.subscription.unsubscribe();
                }
              }
            } if (phaseTab === 'p3') {
              if (response.response.status !== 'processing') {
                this.caseData = response.response;
                let caseDataCopy = { ...this.caseData };
                delete caseDataCopy.issues;
                this.adjP1Form.patchValue(caseDataCopy);
                this.adjP1Form.patchValue(caseDataCopy);
                this.adjP1Form.controls.officer3.setValue(this.caseData?.issuingAuth3?.officer)
                this.adjP1Form.controls.jurisdiction3.setValue(this.caseData?.issuingAuth3?.jurisdiction)
                this.adjP1Form.controls.contact3.setValue(this.caseData?.issuingAuth3?.contact);
                this.adjP1Form.controls.designation3.setValue(this.caseData?.issuingAuth3?.designation)
                this.adjP1Form.controls.address3.setValue(this.caseData?.issuingAuth3?.address);
                this.adjP1Form.controls.email3.setValue(this.caseData?.issuingAuth3?.email);
                this.issueValueChange();
                this.setIssuingAuthorityData();
                this.onDesignationChange();
                this.sidemenu = false;
                this.hiddenbtn = false;
                this.hideRefreshIcon = false;
                this.hidlabels = false;
                this.hidlabelsub = true;
                this.hidlabelsOio = false;
                if (response.response.status !== 'processing') {
                  this.subscription.unsubscribe();
                }
              }
            }
          } else {
            this.toaster.showError(response.response);
            this.hideRefreshIcon = false;
          }

          break;
      }
    });

  }

  onDesignationChange() {
    const selectedDesignation = this.adjP1Form.get('designation3')?.value;
    if (selectedDesignation) {
      this.adjP1Form.get('orderPassedBy')?.setValue(selectedDesignation);
    }
  }


  openIntCalPop(content: any) {
    this.modalService.open(content, { windowClass: 'preanalytics-pop' });
    this.initIntCalPopData();
  }
  closeIntCalPop() {
    this.initializeData();
    this.modalService.dismissAll();
  }

  initIntCalPopData() {
    let model = {
      type: this.tabType[1].key,
      phase: this.selectedTab == 'tab1' ? 'PHASE-1' : 'PHASE-3',
      caseId: this.caseId,
      companyId: localStorage.getItem('selectedId'),
    };
    this.litService.getIntCalPopData(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.intCalPopData = response.response.issueDetails;
        if (this.intCalPopData == null) return;
        const keys = Object.keys(this.intCalPopData);
        this.intCalIssueData = [];
        keys.forEach((key: any) => {
          const valuesArray = this.intCalPopData[key];
          valuesArray.forEach((values: any) => {
            this.intCalIssueData.push({
              issue: values.issue,
              tag: values.tag,
              periodFromDate: values.periodFromDate,
              periodToDate: values.periodToDate,
              interestRate: values.interestRate,
              totalTax: values.totalTax,
              refId: values.refId,
              taxValueAdjusted: values.taxValueAdjusted,
              paymentDetails: values.paymentDetails,
              expanded: false,
            });
          });

        })
      }
    });
  }
  saveIntCalData() {
    const payload = {
      type: this.tabType[1].key,
      phase: this.selectedTab === "tab1" ? "PHASE-1" : "PHASE-3",
      caseId: this.caseId,
      companyId: localStorage.getItem('selectedId'),
      issues: [],
      protestDetails: [] as PaymentDetails[],
      predepositDetails: [] as PaymentDetails[],
      admittedLiabDetails: [] as PaymentDetails[]
    };

    payload.issues = this.intCalIssueData.map((issue: any) => ({
      issue: issue.issue,
      refId: issue.refId || null,
      interestRate: issue.interestRate,
      periodFromDate: issue.periodFromDate,
      periodToDate: issue.periodToDate,
    }));
    this.intCalIssueData.forEach((issue: any) => {
      issue.paymentDetails.forEach((payment: any) => {
        const baseDetails = {
          issueName: issue.issue,
          // refId: issue.refId,
          refId: issue.refId,
          tag: payment.tag,
          stage: payment.stage,
          challanNo: payment.challanNo,
          markedForPayment: payment.markedForPayment
        };

        if (payment.type === 'PROTEST_DETAILS') {
          payload.protestDetails.push(baseDetails);
        } else if (payment.type === 'PREDEPOSIT_DETAILS') {
          payload.predepositDetails.push(baseDetails);
        } else if (payment.type === 'ADMITTED_LIABILITY') {
          payload.admittedLiabDetails.push(baseDetails);
        }
      });
    });

    this.litService.saveIntCalPopData(payload).subscribe((response: any) => {
      if (response.status === 1) {
        this.toaster.showSuccess(response.message);
        this.initIntCalPopData();
      } else {
        this.toaster.showError(response.message);
      }
    });
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CaseMasterComponent } from './litigation/case-master/case-master.component';
import { LitigationSummaryComponent } from './litigation/litigation-summary/litigation-summary.component';
import { TokenVerifyComponent } from './auth/components/token-verify/token-verify.component';
import { PreventGuard } from './auth/guards/prevent.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { SessionClearComponent } from './auth/components/session-clear/session-clear.component';
import { AdjudicationAuthComponent } from './litigation/adjudication-auth/adjudication-auth.component';
import { AppellateAuthComponent } from './litigation/appellate-auth/appellate-auth.component';
import { AppellateTribComponent } from './litigation/appellate-trib/appellate-trib.component';
import { AuditDashboardComponent } from './audit/audit-dashboard/audit-dashboard.component';
import { AuditCompanyGSTComponent } from './audit/audit-company-gst/audit-company-gst.component';
import { AuditTaxPositionComponent } from './audit/audit-tax-position/audit-tax-position.component';
import { AuditReconComponent } from './audit/audit-recon/audit-recon.component';
import { AuditSummaryComponent } from './audit/audit-summary/audit-summary.component';
import { AuditCaseMasterComponent } from './audit/audit-case-master/audit-case-master.component';
import { AnalyticsComponent } from './litigation/analytics/analytics.component';
import { SmartUploadComponent } from './litigation/smart-upload/smart-upload.component';
import { SummaryComponent } from './preGstLitigation/pre-gst-summary/summary.component';
import { PreCaseMasterComponent } from './preGstLitigation/pre-case-master/pre-case-master.component';
import { PreAnalyticsComponent } from './preGstLitigation/pre-analytics/pre-analytics.component';
import { DirectTaxSummaryComponent } from './directTax/direct-tax-summary/direct-tax-summary.component';
import { EnquiryProceedingComponent } from './directTax/enquiry-proceeding/enquiry-proceeding.component';
import { TaxCaseMasterComponent } from './directTax/tax-case-master/tax-case-master.component';
import { TaxPositionsComponent } from './directTax/tax-positions/tax-positions.component';
import { FinancialInformationComponent } from './directTax/financial-information/financial-information.component';
import { EmailSettingComponent } from './businessManagement/components/email-setting/email-setting.component';
import { GstSummaryComponent } from './noticeManagement/gst/gst-summary/gst-summary.component';
import { GstNoticeOrderComponent } from './noticeManagement/gst/gst-notice-order/gst-notice-order.component';
import { GstNoticeDetailComponent } from './noticeManagement/gst/gst-notice-detail/gst-notice-detail.component';
import { GstSubmissionComponent } from './noticeManagement/gst/gst-submission/gst-submission.component';
import { AdditionalNoticeComponent } from './noticeManagement/gst/additional-notice/additional-notice.component';
import { AdditionalNoticeDetailComponent } from './noticeManagement/gst/additional-notice-detail/additional-notice-detail.component';
import { GstSubmissionDetailComponent } from './noticeManagement/gst/gst-submission-detail/gst-submission-detail.component';
import { DtAnalyticsComponent } from './directTax/dt-analytics/dt-analytics.component';
import { ProceedingsComponent } from './noticeManagement/directTax/proceedings/proceedings.component';
import { NoticesComponent } from './noticeManagement/directTax/notices/notices.component';
import { AdjournmentComponent } from './noticeManagement/directTax/adjournment/adjournment.component';
import { VideoConfComponent } from './noticeManagement/directTax/video-conf/video-conf.component';
import { DetailViewComponent } from './noticeManagement/directTax/detail-view/detail-view.component';
import { VcDetailViewComponent } from './noticeManagement/directTax/vc-detail-view/vc-detail-view.component';
import { NoticeDetailViewComponent } from './noticeManagement/directTax/notice-detail-view/notice-detail-view.component';
import { AdjDetailViewComponent } from './noticeManagement/directTax/adj-detail-view/adj-detail-view.component';
import { LitigationDownloadComponent } from './litigation/litigation-download/litigation-download.component';
import { GstVoluntaryPaymentComponent } from './noticeManagement/gst/gst-voluntary-payment/gst-voluntary-payment.component';
import { GstLetterOfUndertakingComponent } from './noticeManagement/gst/gst-letter-of-undertaking/gst-letter-of-undertaking.component';
import { DtSummaryComponent } from './noticeManagement/directTax/dt-summary/dt-summary.component';
import { ProcdDetailViewComponent } from './noticeManagement/directTax/procd-detail-view/procd-detail-view.component';
import { InterestCalculationComponent } from './businessManagement/components/interest-calculation/interest-calculation.component';
//import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  {
    path: 'litigationSummary',
    canActivate: [AuthGuard],
    component: LitigationSummaryComponent
  },
  {
    path: 'adjudication',
    canActivate: [AuthGuard],
    component: AdjudicationAuthComponent
  },
  {
    path: 'emailSetting',
    canActivate: [AuthGuard],
    component: EmailSettingComponent
  },
  {
    path: 'intCalSetting',
    canActivate: [AuthGuard],
    component: InterestCalculationComponent
  },
  {
    path: 'litigationAnalytics',
    canActivate: [AuthGuard],
    component: AnalyticsComponent
  }, {
    path: 'litigationSmartUpload',
    canActivate: [AuthGuard],
    component: SmartUploadComponent
  },
  {
    path : "litigationDownload/:module/:caseId",
    canActivate: [AuthGuard],
    component : LitigationDownloadComponent
  }, {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent
  }, {
    path: 'notice/:caseId',
    canActivate: [AuthGuard],
    component: CaseMasterComponent
  }, {
    path: 'notice',
    canActivate: [AuthGuard],
    component: CaseMasterComponent
  }, {
    path: 'auditDashboard',
    canActivate: [AuthGuard],
    component: AuditDashboardComponent
  }, {
    path: 'auditSummary',
    canActivate: [AuthGuard],
    component: AuditSummaryComponent
  }, {
    path: 'createAudit/:auditId',
    canActivate: [AuthGuard],
    component: AuditCaseMasterComponent
  }, {
    path: 'createAudit',
    canActivate: [AuthGuard],
    component: AuditCaseMasterComponent
  }, {
    path: 'auditCompGstDetail',
    canActivate: [AuthGuard],
    component: AuditCompanyGSTComponent
  }, {
    path: 'auditTaxPosition',
    canActivate: [AuthGuard],
    component: AuditTaxPositionComponent
  }, {
    path: 'auditRecon',
    canActivate: [AuthGuard],
    component: AuditReconComponent
  }, {
    path: 'preGstSummary',
    canActivate: [AuthGuard],
    component: SummaryComponent
  }, {
    path: 'preGstAnalytics',
    canActivate: [AuthGuard],
    component: PreAnalyticsComponent
  }, {
    path: 'createPreGstCase/:caseId',
    canActivate: [AuthGuard],
    component: PreCaseMasterComponent
  }, {
    path: 'createPreGstCase',
    canActivate: [AuthGuard],
    component: PreCaseMasterComponent
  }, {
    path: 'createDirectTaxCase/:caseId',
    canActivate: [AuthGuard],
    component: TaxCaseMasterComponent
  },
  {
    path: 'createDirectTaxCase',
    canActivate: [AuthGuard],
    component: TaxCaseMasterComponent
  }, {
    path: 'token-verify',
    canActivate: [PreventGuard],
    component: TokenVerifyComponent
  }, {
    path: 'directTaxSummary',
    canActivate: [AuthGuard],
    component: DirectTaxSummaryComponent
  }, {
    path: 'directTaxAnalytics',
    canActivate: [AuthGuard],
    component: DtAnalyticsComponent
  }, {
    path: 'directTaxPositions',
    canActivate: [AuthGuard],
    component: TaxPositionsComponent
  }, {
    path: 'financialInformation',
    canActivate: [AuthGuard],
    component: FinancialInformationComponent
  }, {
    path: 'directTaxEnquiry',
    canActivate: [AuthGuard],
    component: EnquiryProceedingComponent
  }, {
    path: 'gstNoticetMSummary',
    canActivate: [AuthGuard],
    component: GstSummaryComponent
  }
  , {
    path: 'gstNoticetOrder',
    canActivate: [AuthGuard],
    component: GstNoticeOrderComponent
  }, {
    path: 'gstNoticetDetail/:applnId',
    canActivate: [AuthGuard],
    component: GstNoticeDetailComponent
  },
  {
    path: 'gstSubmission',
    canActivate: [AuthGuard],
    component: GstSubmissionComponent
  },
  {
    path: 'gstAdditionalOrder',
    canActivate: [AuthGuard],
    component: AdditionalNoticeComponent
  },
  {
    path: 'gstVoluntaryPayment',
    canActivate: [AuthGuard],
    component: GstVoluntaryPaymentComponent
  },{
    path: 'gstLetterOfUndertaking',
    canActivate: [AuthGuard],
    component: GstLetterOfUndertakingComponent
  },
  {
    path: 'gstSubmissionDetail/:applnId',
    canActivate: [AuthGuard],
    component: GstSubmissionDetailComponent
  }, {
    path: 'gstAdditionalDetail/:applnId/:type/:gstinName/:gstin',
    canActivate: [AuthGuard],
    component: AdditionalNoticeDetailComponent
  }, 
  {
    path: 'dtNmSummary',
    canActivate: [AuthGuard],
    component: DtSummaryComponent 
  },
  {
    path: 'dtNmProceeding',
    canActivate: [AuthGuard],
    component: ProceedingsComponent
  },
  {
    path: 'dtNmNotices',
    canActivate: [AuthGuard],
    component: NoticesComponent
  },
  {
    path: 'dtNmAdj',
    canActivate: [AuthGuard],
    component: AdjournmentComponent
  },
  {
    path: 'dtNmVc',
    canActivate: [AuthGuard],
    component: VideoConfComponent
  },
  {
    path: 'dtNmProcDetail/:proceedingReqId',
    canActivate: [AuthGuard],
    component: DetailViewComponent
  },
  {
    path: 'dtNmProcdNoticeDetail/:noticeId/:parentNoticeheaderSeqNo',
    canActivate: [AuthGuard],
    component: ProcdDetailViewComponent 
  },
  {
    path: 'dtNmNoticeDetail/:noticeId/:parentNoticeheaderSeqNo',
    canActivate: [AuthGuard],
    component: NoticeDetailViewComponent
  },

  {
    path: 'dtNmAdjDetail/:adjVcReqstId',
    canActivate: [AuthGuard],
    component: AdjDetailViewComponent
  },
  {
    path: 'dtNmVcDetail/:parentNoticeheaderSeqNo',
    canActivate: [AuthGuard],
    component: VcDetailViewComponent
  },

  {
    path: 'logout-all-session',
    canActivate: [PreventGuard],
    component: SessionClearComponent
  }, 
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./businessManagement/components/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'masters',
    canActivate: [AuthGuard],
    loadChildren: () => import('./businessManagement/components/master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./businessManagement/components/profile/profile.module').then(m => m.ProfileModule)
  }
  // ,{
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
